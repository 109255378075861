import CopyLeft from '../../../../../../shared/components/table/cell/CopyLeft';
import CopyLeftLink from '../../../../../../shared/components/table/cell/CopyLeftLink';
import Status from './Status';

export default {
  list: {
    contactEmail: CopyLeft,
    website: CopyLeftLink,
    status: Status,
  },
};
