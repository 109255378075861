// import { unflatten } from 'flat';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import _ from 'lodash';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import RefreshIcon from 'mdi-react/RefreshIcon';
import { useAsyncDebounce } from 'react-table';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
  Label,
  FormGroup,
} from 'reactstrap';
import MoneyIcon from 'mdi-react/MoneyIcon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import Hook from '../../../../../shared/hooks';
import CheckBoxField from '../../../../../shared/components/CheckBox';
import { parseUrl, setCookie, getCookie } from '../../../../../shared/helpers/WVUtilities';
import ChartOfAccount from './ChartOfAccountModal';
import CalculateModal from './CalculateModal';
import StripeReportModal from './StripeReportModal';
import {
  calculatePayables, sendStripePayouts,
} from '../../../../../redux/reducers/admin/AccountsPayableReducer';
import {
  diselectAll,
} from '../../../../../redux/reducers/TableReducer';
import { getConstant } from '../../../../../shared/helpers/WVConstants';
import SelectFilter from '../../../../../shared/components/SelectFilter';
import DealValidationModal from './DealValidationModal';
import AccessComponent from '../../../../../shared/components/auth/AccessComponent';

const getDealTypeInitial = () => {
  const filters = parseUrl();
  const initialFilters = {
    'CPA/Ambassador': true,
    'Upfront Only': true,
    Hybrid: true,
    Bonus: true,
    'Upfront CPM': true,
    Unassigned: true,
  };
  if (filters.typeOfDeal) {
    const f = Array.isArray(filters.typeOfDeal) ? filters.typeOfDeal : [filters.typeOfDeal];
    Object.keys(initialFilters).forEach((key) => {
      initialFilters[key] = f.includes(key);
    });
  }
  return initialFilters;
};

const getStatusInitial = () => {
  const filters = parseUrl();
  const initialFilters = {
    pending: true,
    approved: true,
    complete: true,
    failed: true,
  };
  if (filters.status) {
    const f = Array.isArray(filters.status) ? filters.status : [filters.status];
    Object.keys(initialFilters).forEach((key) => {
      initialFilters[key] = f.includes(key);
    });
  }
  return initialFilters;
};
const getDealExistsInitial = () => {
  const filters = parseUrl();
  const initialFilters = {
    Exists: true,
    Missing: true,
  };
  if (filters.dealExists) {
    const f = Array.isArray(filters.dealExists) ? filters.dealExists : [filters.dealExists];
    Object.keys(initialFilters).forEach((key) => {
      initialFilters[key] = f.includes(key);
    });
  }
  return initialFilters;
};

const getPaymentStatusInitial = () => {
  const filters = parseUrl();
  const initialFilters = {
    Stripe: true,
    'Bill.com': true,
    'Not Connected': true,
  };
  if (filters.paymentStatus) {
    const f = Array.isArray(filters.paymentStatus) ? filters.paymentStatus : [filters.paymentStatus];
    Object.keys(initialFilters).forEach((key) => {
      initialFilters[key] = f.includes(key);
    });
  }
  return initialFilters;
};

const getAuthorizedInitial = () => {
  const filters = parseUrl();
  const initialFilters = {
    Authorized: true,
    'Not Authorized': true,
  };
  if (filters.authorized) {
    const f = Array.isArray(filters.authorized) ? filters.authorized : [filters.authorized];
    Object.keys(initialFilters).forEach((key) => {
      initialFilters[key] = f.includes(key);
    });
  }
  return initialFilters;
};
const getDealstages = () => {
  const pipelines = getConstant('deal', 'pipelines', []);
  const allStages = {};
  pipelines?.forEach((p) => {
    const stages = Object.keys(p.stages).map((k) => {
      if (p.label.toLowerCase() === 'Main Pipeline'.toLowerCase()) allStages[k] = p.stages[k];
      return { value: k, label: p.stages[k] };
    });
    return {
      id: p.id,
      stages,
    };
  });
  return allStages;
}


const getDealstageInitial = () => {
  const allStages = getDealstages();
  const filters = parseUrl();
  if (Object.keys(allStages).length > 0) {
    const stages = {};
    stages['Not Assigned'] = false;
    Object.values(allStages).forEach((stage) => {
      stages[stage] = false;
    });
    const initialFilters = stages;
    // console.log(initialFilters, 'INIT STAGES');
    if (filters.dealstages) {
      Object.keys(initialFilters).forEach((key) => {
        // console.log(key, 'INIT STAGES2');
        const rightKey = Object.keys(allStages).find(a => allStages[a] === key);
        // console.log(rightKey, 'INIT STAGES3');
        // console.log(filters.dealstages, filters.dealstages.includes(rightKey || 'na'));
        initialFilters[key] = filters.dealstages.includes(rightKey || 'na');
      });
    }
    // console.log(initialFilters, 'INIT STAGES2');
    return initialFilters;
  }
  return {};
};


const Actions = React.memo(({
  searchValue,
  history,
  selectedItems,
  loadingMFA,
  loading,
  setIssue,
  // sendStripePayouts,
  // showStripeModal,
  // availableBalance,
  challengeMFAReauthentication,
  selectedRecordsObject,
  getStripeSelectedAmount,
  getTotalSelectedAmount,
  setLoadingMFA,
  selectedReport,
  onSendIndividualPayoutButton,
  refreshUnpaidTable,
  refreshTable,
  showOfflinePayout,
  dispatch,
  stripeLog,
  stripe,
  downloadCSV,
  refresh,
}) => {
  const filtersUrl = parseUrl();
  const [search, setSearch] = useState(filtersUrl.searchFullName || '');
  const [showFilters, setShowFilters] = useState(getCookie('acountPayablesShowFilter') === 'true');
  const [dateSearch, setDateSearch] = useState(null);
  const [filters, setFilters] = useState(getStatusInitial());
  const [stripeStatusFilters, setStripeStatusFilters] = useState(getPaymentStatusInitial());
  const [authorized, setAuthorizedFilters] = useState(getAuthorizedInitial());
  const [dealFilters, setDealFilters] = useState(getDealExistsInitial());
  const [dealTypeFilters, setDealTypeFilters] = useState(getDealTypeInitial());
  const [billComChartOfAccount, setBillComChartOfAccounts] = useState([]);
  const [dealStageFilter, setDealStageFilter] = useState(getDealstageInitial());
  const [version, setVersion] = useState((new Date()).toString());
  const [showConfirmModal] = Hook.useShowModalHook('ConfirmModalDefault');
  const  { showError } = Hook.useNoticeHook()
  const [modals, setModals] = useState({
    chartOfAccount: false,
    calculate: false,
    stripe: false,
    dealValidation: false,
  });
  const [dealValidationErrors, setDealValidationErrors] = useState(null);
  console.log(dealTypeFilters);
  const allStages = getDealstages();
  console.log('authorized', authorized)
  const isBillComMFASessionTrusted = async () => {
    const response = await axios.get('/api/portal/billCom/mfa-status');
    if (response && response.data.success) {
      const { isTrusted } = response.data;

      return isTrusted;
    }

    return true;
  };

  /* const getDealStages = () => {
    if (Object.keys(allStages).length > 0) {
      const stages = {};
      stages['Not Assigned'] = false;
      Object.values(allStages).forEach((stage) => {
        stages[stage] = false;
      });
      setDealStageFilter(stages);
    }
  }; */

  const getUrlSearch = () => {
    const searchObj = new URLSearchParams(window.location.search);
    if (searchValue) {
      searchObj.set('filters.fullSearchName', searchValue);
    } else {
      searchObj.delete('filters.fullSearchName');
    }
    if (dealTypeFilters && Object.keys(dealTypeFilters).length > 0) {
      searchObj.delete('filters.typeOfDeal');
      Object.keys(dealTypeFilters).forEach((key) => {
        if (!dealTypeFilters[key]) return false;
        searchObj.append('filters.typeOfDeal', key);
        return true;
      });
    }
    if (filters && Object.keys(filters).length > 0) {
      searchObj.delete('filters.status');
      Object.keys(filters).forEach((key) => {
        if (!filters[key]) return false;
        searchObj.append('filters.status', key);
        return true;
      });
    }
    if (dealFilters && Object.keys(dealFilters).length > 0) {
      searchObj.delete('filters.dealExists');
      Object.keys(dealFilters).forEach((key) => {
        if (!dealFilters[key]) return false;
        searchObj.append('filters.dealExists', key);
        return true;
      });
    }
    if (stripeStatusFilters && Object.keys(stripeStatusFilters).length > 0) {
      searchObj.delete('filters.paymentStatus');
      Object.keys(stripeStatusFilters).forEach((key) => {
        if (!stripeStatusFilters[key]) return false;
        searchObj.append('filters.paymentStatus', key);
        return true;
      });
    }
    if (authorized && Object.keys(authorized).length > 0) {
      searchObj.delete('filters.authorized');
      Object.keys(authorized).forEach((key) => {
        if (!authorized[key]) return false;
        searchObj.append('filters.authorized', key);
        return true;
      });
    }

    searchObj.delete('filters.dealstages');
    if (Object.values(dealStageFilter).find(v => v === true)) {
      Object.keys(dealStageFilter).forEach((key) => {
        if (!dealStageFilter[key] || Object.keys(dealStageFilter).length === Object.keys(allStages) + 1) return false;
        const rightKey = Object.keys(allStages).find(a => allStages[a] === key);
        searchObj.append('filters.dealstages', rightKey || 'na');
        return true;
      });
      // filteredDeals = filteredDeals.filter(deal => (deal.params?.dealstage && dealStageFilter[allStages[deal.params.dealstage]]) || (!deal.params?.dealstage && dealStageFilter['Not Assigned']));
    }

    if (search) {
      searchObj.set('filters.searchFullName', search);
    } else {
      searchObj.delete('filters.searchFullName');
    }
    // searchObj.append('filters.searchFullName', search);

    // setSearchString(search.toString());
    return searchObj;
  };

  const setUrl = () => {
    const searchString = getUrlSearch();
    searchString.set('page', '1');
    dispatch(diselectAll());
    history.push(`${history.location.pathname}?${searchString.toString()}`);
  };

  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 800);
  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      setUrl();
    }, 1000));
  }, [search]);

  useEffect(() => {
    setCookie('acountPayablesShowFilter', showFilters.toString(), 35);
  }, [showFilters]);

  const resetFilters = () => {
    setSearch('');
    setStripeStatusFilters({Stripe: true,
      'Bill.com': true,
      'Not Connected': true})
    setFilters({
      pending: true,
      approved: true,
      complete: true,
      failed: true,
    });
    setAuthorizedFilters({
      Authorized: true,
      'Not Authorized': true,
    });
    setDealFilters({
      Exists: true,
      Missing: true,
    })
    setDealTypeFilters({
      'CPA/Ambassador': true,
      'Upfront Only': true,
      Hybrid: true,
      Bonus: true,
      'Upfront CPM': true,
      Unassigned: true,
    })
    const dealstages = getDealstageInitial();
    console.log(dealstages, 'DDD');
    Object.keys(dealstages).forEach(a => dealstages[a] = false);
    console.log(dealstages, 'DDD');
    setDealStageFilter(dealstages)
    setUrl('');
    setVersion((new Date()).toString());
  };
  useEffect(() => {
    setUrlDebounced();
  }, [
    searchValue,
    dealTypeFilters,
    filters,
    dealFilters,
    stripeStatusFilters,
    dealStageFilter,
    authorized,
  ]);
  /* useEffect(() => {
    getDealStages();
  }, []); */

  const isBillComAgentSelected = () => {
    // eslint-disable-next-line no-unused-vars,no-restricted-syntax
    for (const id of selectedItems) {
      const payable = selectedRecordsObject.find(_payable => _payable.id?.toString() === id.toString());

      if (payable && payable.populated && payable.populated.influencer) {
        const { agent, influencer } = payable.populated;

        if (!influencer?.params?.payment?.enablePayments && agent
          && agent?.params?.payment?.billCom.isDefault
          && agent?.params?.payment?.billCom.accountId
          // && !WVValidator.isEmpty(agent.params?.payment.billCom.accountId)
        ) {
          return true;
        }
        if (influencer?.params?.payment?.enablePayments
          && influencer?.params?.payment?.billCom.isDefault
          && influencer?.params?.payment?.billCom.accountId
          // && !WVValidator.isEmpty(agent.params?.payment.billCom.accountId)
        ) {
          return true;
        }
      }
    }

    return false;
  };

  const validateDeals = async () => {
    // Deal Validation
    let dealValidationResults = [];
    // let isValid = true;
    if (selectedRecordsObject.length > 0) {
      const promises = selectedRecordsObject.map(async (record) => {
        try {
          const response = await axios.get(`/api/portal/finance/get-deal-validation-check?id=${record.params.deal || ''}`);
          return { record, validation: response.data.data };
        } catch (e) {
          console.log(e);
          return { record, validation: { isValid: false, validationData: [e.message] } };
        }
      });
      dealValidationResults = await Promise.all(promises);
      // console.log('dealValidationResults', dealValidationResults);
      const isValid = !(dealValidationResults.find(r => !r.validation?.isValid));
      setDealValidationErrors(dealValidationResults);
      setModals({ ...modals, dealValidation: !isValid });
    }
    return dealValidationResults;
  };

  const onSendPayoutsButton = async () => {
    // const { selectedIds, modals: { chartOfAccount }, billComChartOfAccount } = this.state;
    const stripeSelectedAmount = getStripeSelectedAmount(); // just stripe
    if (stripe.availableBalance < (stripeSelectedAmount * 100)) {
      // return this.setState({ issue: { invalidBalance: true } });
      return setIssue({ invalidBalance: true });
    }
    const totalSelectedAmount = getTotalSelectedAmount(); // total
    if (totalSelectedAmount === 0 && selectedItems.length > 0) {
      return setIssue({ invalidCount: true });
      // return this.setState({ issue: { invalidCount: true } });
    }

    const validationResults = await validateDeals();
    const isValid = !(validationResults.find(r => !r.validation?.isValid));

    const validPayoutIds = [];
    selectedItems.forEach((item) => {
      validationResults.forEach((result) => {
        if (result.record.id === item && result.validation?.isValid) {
          validPayoutIds.push(item);
        }
      });
    });
    console.log(validationResults, validPayoutIds, 'VALID INFOR');
    if (isValid) {
      // Z: check BillCom MFA Session status
      if (isBillComAgentSelected()) {
        setLoadingMFA(true);
        const isTrusted = await isBillComMFASessionTrusted();
        setLoadingMFA(false);
        if (!isTrusted) {
          return challengeMFAReauthentication();
        }
      }
      if (!modals.chartOfAccount) {
        setModals({ ...modals, chartOfAccount: true });
        return false;
      }
      // END Z;
      if (validPayoutIds.length > 0) {
        dispatch(sendStripePayouts(
          selectedReport.id,
          validPayoutIds,
          billComChartOfAccount,
          () => {
            refresh();
          },
        ));
      }
      // sendStripePayouts(selectedReport.id, selectedItems, billComChartOfAccount);
      setModals({ ...modals, chartOfAccount: false, stripe: true });
      setIssue({});
    }
    // this.setState(state => ({ modals: { ...state.modals, stripe: true, chartOfAccount: false }, selectedIds: [], issue: { invalidBalance: false, invalidCount: false } }));
    return true;
  };
  useEffect(async () => {
    if (Object.keys(billComChartOfAccount).length > 0) {
      await onSendPayoutsButton();
    }
  }, [billComChartOfAccount]);
  console.log([selectedItems, selectedRecordsObject], 'selectedItems');

  return (
    <div className="prospects-actions">
      <Row className="mt-3">
        <Col sm={6}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search by Creator Name, Email or Deal name"
              value={search}
              onChange={p => setSearch(p.target?.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '38px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '38px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <Button
              size="sm"
              color="primary"
              style={{
                width: '100%',
                padding: '4px 8px',
                height: '40px',
              }}
              onClick={() => showConfirmModal({
                title: 'Send Payout',
                message: 'Are you sure you want to send payout?',
                processRecord: onSendPayoutsButton,
                confirmButtonCaption: 'Send',
                errorCatch: (e) => {
                  showError(e.message);
                }
              })}
              // loading={loading.stripePayouts || loadingMFA || false}
              disabled={selectedItems.length === 0 || loadingMFA || loading.stripePayouts}
            >
              <MoneyIcon size={16} style={{ marginTop: '-3px' }} /> Send Payouts
          </Button>
          <AccessComponent showWhenRole={['owner', 'superAdmin']} showWhenTeam={['super_powers', 'engineering']}>
            <Button
              size="sm"
              color="primary"
              style={{
                width: '100%',
                padding: '4px 8px',
                height: '40px',
              }}
              onClick={showOfflinePayout}
              disabled={selectedItems.length > 1 || selectedItems.length === 0 }
            >
              <MoneyIcon size={16} style={{ marginTop: '-3px' }} /> Offline Payout
            </Button>
          </AccessComponent>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => refreshTable(false)} disabled={!!loading.fillPayables || !!loading.calculate || selectedReport.params.status !== 'new'} ><RefreshIcon size={16} /> Refresh Report</DropdownItem>
              <DropdownItem onClick={() => refreshUnpaidTable()} disabled={!!loading.fillPayables || !!loading.calculate} ><RefreshIcon size={16} /> All Unpaid Payables</DropdownItem>
              <DropdownItem
                onClick={() => {
                  setModals(m => ({ ...m, calculate: true }));
                  // dispatch(calculatePayables(selectedReport.id));
                }}
                disabled={!!loading.fillCalculate || !!loading.calculate}
              >
                <RefreshIcon size={16} />
                Recalculate Payouts
              </DropdownItem>

              <DropdownItem
                onClick={() => history.push(`/finance/employee-commissions-report/${selectedReport.id}`)}
              >
                <i className="fa fa-credit-card" />{' '}Commissions
              </DropdownItem>
              <DropdownItem
                onClick={() => history.push(`/resources/PayableJournal/actions/list?filters.accountPayable=${selectedReport.id}`)}
              >
                <i className="fa fa-history" />{' '}View Payout Journal
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  const searchString = getUrlSearch();
                  searchString.set('filters.accountsPayable', selectedReport.id);
                  const win = window.open(`/api/portal/finance/get-payable-csv?${searchString.toString()}`, '_blank');
                  win.focus();
                }}
              >
                <i className="fa fa-download" />{' '}Download AP Report
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  const win = window.open(`/api/portal/finance/get-download-csv-accounts-payable?accountsPayableId=${selectedReport.id}`, '_blank');
                  win.focus();
                }}
              >
                <i className="fa fa-download" />{' '}Download GP Report
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      {showFilters && (
        <>
          <Row className="mt-4">
            <Col sm={6}>
              <Row>
                <Col sm={3}>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Status
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="pending"
                      label="Pending"
                      value={filters.pending}
                      onChange={event => event.target && setFilters(f => ({ ...f, pending: event.target?.checked }))}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="approved"
                      label="Aproved"
                      value={filters.approved}
                      onChange={event => event.target && setFilters(f => ({ ...f, approved: event.target?.checked || false }))}
                    />
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <CheckBoxField
                      name="complete"
                      label="Complete"
                      value={filters.complete}
                      onChange={event => event.target && setFilters(f => ({ ...f, complete: event.target?.checked || false }))}
                    />
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <CheckBoxField
                      name="failed"
                      label="Failed"
                      value={filters.failed}
                      onChange={event => event.target && setFilters(f => ({ ...f, failed: event.target?.checked || false }))}
                    />
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Deal
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="Exists"
                      label="Exists"
                      value={dealFilters.Exists}
                      onChange={event => event.target && setDealFilters(f => ({ ...f, Exists: event.target?.checked || false }))}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="missing"
                      label="Missing"
                      value={dealFilters.Missing}
                      onChange={event => event.target && setDealFilters(f => ({ ...f, Missing: event.target?.checked || false }))}
                    />
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Deal Type
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="cpa_mbassador"
                      label="CPA/Ambassador"
                      value={dealTypeFilters['CPA/Ambassador']}
                      onChange={event => event.target && setDealTypeFilters(f => ({ ...f, 'CPA/Ambassador': event.target?.checked || false }))}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="Hybrid"
                      label="Hybrid"
                      value={dealTypeFilters.Hybrid}
                      onChange={event => event.target && setDealTypeFilters(f => ({ ...f, Hybrid: event.target?.checked || false }))}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="upfrontonly"
                      label="Upfront Only"
                      value={dealTypeFilters['Upfront Only']}
                      onChange={event => event.target && setDealTypeFilters(f => ({ ...f, 'Upfront Only': event.target?.checked || false }))}
                    />
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <CheckBoxField
                      name="upfrontcpm"
                      label="Upfront CPM"
                      value={dealTypeFilters['Upfront CPM']}
                      onChange={event => event.target && setDealTypeFilters(f => ({ ...f, 'Upfront CPM': event.target?.checked || false }))}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="Bonus"
                      label="Bonus"
                      value={dealTypeFilters.Bonus}
                      onChange={event => event.target && setDealTypeFilters(f => ({ ...f, Bonus: event.target?.checked || false }))}
                    />
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <Label className="bold-text" style={{ marginBottom: '14px' }}>
                    Payment Provider
                  </Label>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="Stripe"
                      label="Stripe"
                      value={stripeStatusFilters.Stripe}
                      onChange={event => event.target && setStripeStatusFilters(f => ({ ...f, Stripe: event.target?.checked }))}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="Billcom"
                      label="Bill.com"
                      value={stripeStatusFilters['Bill.com']}
                      onChange={event => event.target && setStripeStatusFilters(f => ({ ...f, 'Bill.com': event.target?.checked }))}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="NotConnected"
                      label="Not Connected"
                      value={stripeStatusFilters['Not Connected']}
                      onChange={event => event.target && setStripeStatusFilters(f => ({ ...f, 'Not Connected': event.target?.checked }))}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Deal Stages
              </Label>
              <SelectFilter
                key="345"
                title="Deal Stages"
                filterProp={dealStageFilter}
                filterAction={v => setDealStageFilter(v)}
                styles={{ marginTop: '8px' }}
                version={version}
                defaultValue={Object.keys(dealStageFilter).filter(a => dealStageFilter[a]).map(k => ({
                  value: k,
                  label: k,
                }))}
              />
            </Col>
            <Col md={2}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Payment Authorization
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="Authorized"
                  label="Authorized"
                  value={authorized.Authorized}
                  onChange={event => event.target && setAuthorizedFilters(f => ({ ...f, Authorized: event.target?.checked }))}
                />
              </FormGroup>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="NotAuthorized"
                  label="Not Authorized"
                  value={authorized['Not Authorized']}
                  onChange={event => event.target && setAuthorizedFilters(f => ({ ...f, 'Not Authorized': event.target?.checked }))}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
      {modals.chartOfAccount ? (
        <ChartOfAccount
          type="creator"
          modal={modals.chartOfAccount}
          closeDialog={() => setModals({ ...modals, chartOfAccount: false })}
          submitDialog={(returneditems) => {
            setBillComChartOfAccounts(returneditems);
          }}
          billComPayables={selectedRecordsObject.filter(payable =>
            selectedItems.includes(payable.id) /* &&
            (
              (payable.populated?.influencer?.params?.payment?.billCom?.isDefault && payable.populated?.influencer?.params?.payment.billCom.accountId) ||
              (payable.populated?.agent?.params?.payment?.billCom?.isDefault && payable.populated?.agent?.params?.payment.billCom.accountId)
            ) */)}
        />) : null}
      <CalculateModal
        modal={modals.calculate}
        closeDialog={() => setModals(m => ({ ...m, calculate: false }))}
        recalculate={(status) => {
          dispatch(calculatePayables(selectedReport.id, status));
        }}
        title="Calculation"
      />
      <StripeReportModal
        type="stripeLog"
        title="Scheduling Payouts"
        modal={modals.stripe}
        closeDialog={() => setModals(m => ({ ...m, stripe: false }))}
        log={stripeLog.logs}
        loading={loading.stripePayouts}
        downloadCSV={downloadCSV}
        // selectedIds={selectedIds}
      />
      <DealValidationModal
        closeDialog={() => { setModals(m => ({ ...m, dealValidation: false })); setDealValidationErrors(null); }}
        modal={modals.dealValidation}
        failedPayouts={dealValidationErrors}
      />
    </div>
  );
});

Actions.propTypes = {
  onSendIndividualPayoutButton: PropTypes.func.isRequired,
  selectedReport: PropTypes.objectOf(PropTypes.any).isRequired,
  // onSearchChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  // setShowFetchModalLoading: PropTypes.func.isRequired,
  // setErrors: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  loadingMFA: PropTypes.bool.isRequired,
  loading: PropTypes.objectOf(PropTypes.any).isRequired,
  refreshUnpaidTable: PropTypes.func.isRequired,
  selectedRecordsObject: PropTypes.arrayOf(PropTypes.any).isRequired,
  showOfflinePayout: PropTypes.func.isRequired,
  refreshTable: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  setIssue: PropTypes.func.isRequired,
  stripeLog: PropTypes.objectOf(PropTypes.any).isRequired,
  stripe: PropTypes.objectOf(PropTypes.any).isRequired,
  getStripeSelectedAmount: PropTypes.func.isRequired,
  challengeMFAReauthentication: PropTypes.func.isRequired,
  setLoadingMFA: PropTypes.func.isRequired,
  getTotalSelectedAmount: PropTypes.func.isRequired,
  downloadCSV: PropTypes.func.isRequired,
};
Actions.defaultProps = {
  searchValue: '',
};

const mapStateToProps = state => ({
  
  resources: state.resources,
  selectedItems: state.table.selectedRecordsIdsMultiselect,
  selectedRecordsObject: state.table.selectedRecordsObject,
  stripeLog: state.accountPayable.logs.stripePayouts,
  stripe: state.accountPayable.stripe,
});

export default (connect(mapStateToProps)(Actions));
