import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Form, FormGroup, Container, Row, Spinner, Label, Input } from 'reactstrap';
import ApiClient from 'ApiClient';
import { Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CKEditor } from 'ckeditor4-react';
import { formatCheckedOption } from '../../../../../shared/helpers/WVFormatter';
import StyledButton from '../../../../../shared/components/components/ui/styled-button';
import { saveScorecardAnswersSheet } from '../../../../../redux/reducers/admin/ScoringReducer';
import QuestionItem from './custom/QuestionItem';
import FTCCompliant from './custom/FTCCompliant';
import PaidPromotion from './custom/PaidPromotion';
import OscarWorthy from './custom/OscarWorthy';

const useStyles = makeStyles(() => ({
  divider: {
    marginBottom: '10px',
  },
  lastDivider: {
    marginBottom: '10px',
    marginTop: '20px',
  },
  tableTitle: {
    marginTop: '25px',
  },
}));

const ScorecardsAnswersForm = ({
  answers,
  questions,
  mediaContentId,
  action,
  view,
  dispatch,
  adminUser,
  loading,
  handleCancelAction,
  isFTCCompliant,
  handleChangeFTPCompliant,
  includesPaidPromotion,
  handleChangePaidPromotion,
  isOscar,
  handleChangeOscarWorthy,
  type,
  mediaContentText,
  disableNotes,
  allowChangeStatus,
}) => {
  const [disable, setDisable] = useState(false);
  const [answersScore, setAnswersScore] = useState({ totalScore: 0 });
  const [notes, setNotes] = useState('');
  const [transcriptLoaded, setTranscriptLoaded] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [contentTextLabel, setContentTextLabel] = useState('Content Text');
  const [centerForm, setCenterForm] = useState(false);
  const [pendingEdits, setPendingEdits] = useState(false);
  const classes = useStyles();

  const getTranscript = async () => {
    if (mediaContentId) {
      const api = new ApiClient();
      const response = await api.client.get(`/api/portal/scorecard/media-content/get-transcription?mediaContentId=${mediaContentId}`);
      setTranscript(response.data.transcription || '');
      setTranscriptLoaded(true);
    } else {
      setTranscriptLoaded(true);
    }
  };

  useEffect(() => {
    switch (action) {
      case 'edit':
        setDisable(false);
        break;
      case 'view':
        setDisable(true);
        break;
      default:
        setDisable(true);
    }
    switch (view) {
      case 'window':
        setCenterForm(false);
        break;
      case 'modal':
        setCenterForm(true);
        break;
      default:
        setCenterForm(false);
    }
    switch (type) {
      case 'YouTube Description Text':
        setContentTextLabel('YouTube Description Text');
        break;
      case 'Podcast Script Text':
        setContentTextLabel('Podcast Script Text');
        break;
      case 'Twitter Post':
        setContentTextLabel('Tweet Text');
        break;
      case 'LinkTree':
        setContentTextLabel('LinkTree Text');
        break;
      default:
        break;
    }
    getTranscript();
  }, []);

  const sumScore = () => {
    const scores = {};
    questions?.questions?.map(questionGroups => questionGroups.questions.map((item) => {
      if (answers !== null && answers.data !== undefined && answers.data !== null) {
        const result = answers.data.answers.filter(answer => (answer.question === item._id));
        if (item.questionType === 'Binary') {
          scores[item._id] = result[0].binaryScore || false;
        } else {
          scores[item._id] = result[0].score ? result[0].score : 0;
        }
      } else if (item.questionType === 'Binary') {
        scores[item._id] = null;
      } else {
        scores[item._id] = 0;
      }
      return scores;
    }));
    if (answers !== null && answers.data.totalScore !== undefined && answers.data.totalScore !== null) {
      scores.totalScore = answers.data.totalScore;
    } else {
      scores.totalScore = 0;
    }
    setAnswersScore(scores);
    if (answers !== null && answers.data.notes !== undefined && answers.data.notes !== null) {
      setNotes(answers.data.notes);
    }
  };

  useEffect(() => {
    sumScore();
  }, [answers]);

  const handleOnChangeValue = (event) => {
    event.persist();
    const numValue = parseInt(event.target.value, 10);
    const numMax = parseInt(event.target.max, 10);
    const numMin = parseInt(event.target.min, 10);
    const numStep = parseInt(event.target.step, 10);
    const keyName = event.target.name;
    if (numValue <= numMax && numValue >= numMin && (numValue % numStep === 0)) {
      const newTotalScore = (answersScore.totalScore - answersScore[keyName]) + parseInt(event.target.value, 10);
      setAnswersScore(prevState => ({
        ...prevState,
        [keyName]: numValue,
        totalScore: newTotalScore,
      }));
    }
  };

  const handleChangeBinaryValue = (name, value) => {
    setAnswersScore({
      ...answersScore,
      [name]: value,
    });
  };

  const handleChangeNotes = (event) => {
    const { editor } = event;
    setNotes(editor.getData());
  };

  const handleChangeTranscript = (event) => {
    const { editor } = event;
    setTranscript(editor.getData());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formatedAnswers = [];
    Object.entries(answersScore).map((item) => {
      if (item[0] !== 'totalScore') {
        if (item[1] === true || item[1] === false) {
          formatedAnswers.push({ question: item[0], binaryScore: item[1] });
        } else {
          formatedAnswers.push({ question: item[0], score: item[1] });
        }
      }
      return formatedAnswers;
    });
    const params = {
      scorecard: questions._id,
      mediaContent: mediaContentId,
      scoredAdminUser: adminUser._id,
      notes,
      isFTCCompliant,
      includesPaidPromotion,
      isOscar,
      totalScore: answersScore.totalScore,
      answers: formatedAnswers,
      pendingEdits,
      transcript,
    };
    if (answers !== null && answers.data._id !== undefined && answers.data._id !== null) {
      params.id = answers.data._id;
    }
    setAnswersScore({ totalScore: 0 });
    setNotes('');
    setTranscript('');
    setPendingEdits(false);
    dispatch(saveScorecardAnswersSheet(params)).then(() => {
      while (loading) {
        setTimeout(() => {}, 1000);
      }
      window.location.href = `/scorecard/scorecard-answers-dashboard/${mediaContentId}`;
    });
  };

  const handleCancelClick = () => {
    if (handleCancelAction) {
      handleCancelAction();
    }
  };

  return (
    <Form>
      {loading && (
        <div className="text-center"><Spinner color="primary" size="lg" /></div>
      )}
      {!loading && (
        <Container>
          <Row className="justify-content-center">
            <Col sm={centerForm ? 11 : 7} style={{ padding: 0 }}>
              <Row style={{ marginTop: 20 }}>
                {!disable && (
                  <Col xs={3}><Typography gutterBottom variant="subtitle2" style={{ marginBottom: '20px' }}>{contentTextLabel}</Typography></Col>
                )}
                {!disable && (
                  <Col xs={9}><Typography gutterBottom variant="body2" style={{ marginBottom: '20px' }}>{mediaContentText}</Typography></Col>
                )}
                {disable && mediaContentText && (
                  <Col xs={3}><Typography gutterBottom variant="subtitle2" style={{ marginBottom: '20px' }}>{contentTextLabel}</Typography></Col>
                )}
                {disable && mediaContentText && (
                  <Col xs={9}><Typography gutterBottom variant="body2" style={{ marginBottom: '20px' }}>{mediaContentText}</Typography></Col>
                )}
                { (action === 'edit' || [true, false].indexOf(isFTCCompliant) > -1) && (
                  <>
                    <Col xs={12}>
                      <Typography gutterBottom variant="subtitle2">
                        Is the content Brand Compliant?
                        { action !== 'edit' && (
                          <b>&nbsp;&nbsp;&nbsp;&nbsp;{formatCheckedOption(isFTCCompliant)}</b>
                        )}
                      </Typography>
                    </Col>
                    <Col xs="12">
                      {action === 'edit' && (
                        <FTCCompliant
                          isFTCCompliant={isFTCCompliant}
                          disable={disable}
                          handleChangeFTPCompliant={handleChangeFTPCompliant}
                        />
                      )}
                    </Col>
                  </>
                )}
                {type === 'YouTube' && (action === 'edit' || [true, false].indexOf(includesPaidPromotion) > -1) && (
                  <>
                    <Col xs={12}>
                      <Typography gutterBottom variant="subtitle2">
                        Includes Paid Promotion Feature?
                        { action !== 'edit' && (
                          <b>&nbsp;&nbsp;&nbsp;&nbsp;{formatCheckedOption(includesPaidPromotion)}</b>
                        )}
                      </Typography>
                    </Col>
                    <Col xs={12}>
                      {action === 'edit' && (
                        <PaidPromotion
                          includesPaidPromotion={includesPaidPromotion}
                          disable={disable}
                          handleChangePaidPromotion={handleChangePaidPromotion}
                        />
                      )}
                    </Col>
                  </>
                )}
                {type === 'YouTube' && (action === 'edit' || [true, false].indexOf(isOscar) > -1) && (
                  <>
                    <Col xs={12}>
                      <Typography gutterBottom variant="subtitle2">
                        Is this video &#34;Oscar Worthy&#34;?
                        { action !== 'edit' && (
                          <b>&nbsp;&nbsp;&nbsp;&nbsp;{formatCheckedOption(isOscar)}</b>
                        )}
                      </Typography>
                    </Col>
                    <Col xs={12}>
                      {action === 'edit' && (
                        <OscarWorthy
                          isOscar={isOscar}
                          disable={disable}
                          handleChangeOscarWorthy={handleChangeOscarWorthy}
                        />
                      )}
                    </Col>
                  </>
                )}
              </Row>
              {(questions?.questions?.length > 0) && (
                questions.questions.map(questionCategory => (
                  <div key={questionCategory._id} className={classes.tableTitle}>
                    <Row>
                      <Col sm={10}>
                        <h4>{questionCategory.categoryName}</h4>
                      </Col>
                      <Col sm={2}>
                        <h4>Score</h4>
                      </Col>
                    </Row>
                    <Divider className={classes.divider} />
                    {questionCategory.questions.map(item => (
                      <QuestionItem
                        key={item._id}
                        item={item}
                        disable={disable}
                        answersScore={answersScore}
                        handleOnChangeValue={handleOnChangeValue}
                        handleChangeBinaryValue={handleChangeBinaryValue}
                      />
                    ))}
                  </div>
                ))
              )}
              {type !== 'Text' && (
                <>
                  <Divider className={classes.lastDivider} />
                  <Row style={{ marginTop: 25 }}>
                    <Col sm={10} style={{ paddingLeft: 8 }}><h4>Total Score</h4></Col>
                    <Col sm={2} style={{ paddingLeft: 30 }}>
                      <h4>
                        {answersScore.totalScore}
                      </h4>
                    </Col>
                  </Row>
                  <Divider className={classes.lastDivider} />
                </>
              )}
              {!disableNotes && (
                <Row style={{ marginTop: 25 }}>
                  <Col sm={2} style={{ paddingLeft: 8, paddingRight: 0, maxWidth: '110px' }}>
                    <h4>Notes</h4>
                  </Col>
                  <Col sm={10} style={{ paddingLeft: 0 }}>
                    <CKEditor
                      id="edit-notes"
                      name="notes"
                      initData={answers && typeof answers.data !== 'undefined' && typeof answers.data.notes !== 'undefined' && answers.data.notes !== null ? answers.data.notes : ''}
                      onChange={handleChangeNotes}
                      config={{
                        height: '150px',
                        versionCheck: false
                      }}
                    />
                  </Col>
                </Row>
              )}
              {(!disableNotes && transcriptLoaded) && (
                <Row style={{ marginTop: 25 }}>
                  <Col sm={2} style={{ paddingLeft: 8, paddingRight: 0, maxWidth: '110px' }}>
                    <h4>Transcript</h4>
                  </Col>
                  <Col sm={10} style={{ paddingLeft: 0 }}>
                    <CKEditor
                      config={{versionCheck: false}}
                      id="edit-transcript"
                      name="transcript"
                      initData={transcript}
                      onChange={handleChangeTranscript}
                      // config={{
                      //   height: '150px',
                      // }}
                    />
                  </Col>
                </Row>
              )}
              {allowChangeStatus && (
                <FormGroup style={{ marginTop: 15 }}>
                  <Col md="12" xs="12">
                    <Label check>
                      <Input
                        type="checkbox"
                        onChange={() => { setPendingEdits(!pendingEdits); }}
                        value={pendingEdits}
                        checked={pendingEdits}
                        name="pendingEdits"
                        className="checkbox-input-static"
                      />
                      <h4>Awaiting pending edits to the content before scoring?</h4>
                    </Label>
                  </Col>
                </FormGroup>
              )}
            </Col>
          </Row>
          {!disable && (
            <FormGroup style={{ textAlign: 'center', marginBottom: 0 }}>
              <StyledButton
                type="button"
                className="is-primary"
                style={{ marginTop: 20 }}
                onClick={handleSubmit}
              >
                <i className="icomoon-save" />
                <span className="btn-text">Save</span>
              </StyledButton>
              <StyledButton
                type="button"
                className="btn-danger"
                onClick={handleCancelClick}
                style={{ marginTop: 20 }}
              >
                <i className="icomoon-save" />
                <span className="btn-text">Cancel</span>
              </StyledButton>
            </FormGroup>
          )}
        </Container>
      )}
    </Form>
  );
};

ScorecardsAnswersForm.propTypes = {
  answers: PropTypes.objectOf(PropTypes.any),
  questions: PropTypes.objectOf(PropTypes.any).isRequired,
  mediaContentId: PropTypes.string,
  action: PropTypes.string,
  view: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  handleCancelAction: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  adminUser: PropTypes.objectOf(PropTypes.any).isRequired,
  isFTCCompliant: PropTypes.bool,
  handleChangeFTPCompliant: PropTypes.func,
  includesPaidPromotion: PropTypes.bool,
  handleChangePaidPromotion: PropTypes.func,
  isOscar: PropTypes.bool,
  handleChangeOscarWorthy: PropTypes.func,
  type: PropTypes.string.isRequired,
  mediaContentText: PropTypes.string,
  disableNotes: PropTypes.bool,
  allowChangeStatus: PropTypes.bool,
  mediaContentTranscript: PropTypes.string,
};

ScorecardsAnswersForm.defaultProps = {
  action: 'view',
  view: 'window',
  answers: null,
  handleCancelAction: null,
  mediaContentId: null,
  isFTCCompliant: null,
  handleChangeFTPCompliant: () => {},
  includesPaidPromotion: null,
  handleChangePaidPromotion: () => {},
  isOscar: null,
  handleChangeOscarWorthy: () => {},
  mediaContentText: null,
  mediaContentTranscript: '',
  disableNotes: false,
  allowChangeStatus: false,
};

const mapStateToProps = state => ({
  loading: state.score.loading.saveScorecardAnswersSheetContent,
  adminUser: state.session,
});

export default connect(mapStateToProps)(ScorecardsAnswersForm);
