import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactTags from 'react-tag-autocomplete';
import { CustomInput } from 'reactstrap';
// import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import './tableFormat.css';

const PermissionsDialog = ({
  adminUsers,
  availablePermissions,
  setFinalPermissions,
  setFinalAdminUsers,
  finalAdminUsers,
  finalPermissions,
}) => {
  const [showUserSelectionBar, setShowUserSelectionBar] = useState(false);

  useEffect(() => {
    const holder = [];
    if (availablePermissions && availablePermissions.length > 0) {
      for (let i = 0; i < availablePermissions.length; i += 1) {
        const data = {
          index: i,
          label: availablePermissions[i].label,
          value: availablePermissions[i].value,
          on: false,
          shouldUpdate: false,
        };
        holder.push(data);
      }

      setFinalPermissions(holder);
    }
  }, [availablePermissions]);

  const handleSwitchChangeAllow = (event) => {
    const { name, checked } = event.target;

    // selected permission is to be allowed, take a note of it
    const permissonsCopy = [...finalPermissions];
    for (let j = 0; j < permissonsCopy.length; j += 1) {
      if (permissonsCopy[j].value === name) {
        permissonsCopy[j].on = checked;
      }
    }

    setFinalPermissions(permissonsCopy);
  };

  const handleSwitchChangeUpdate = (event) => {
    const { name, checked } = event.target;

    // selected permission is to be updated, take a note of it
    const permissonsCopy = [...finalPermissions];
    for (let j = 0; j < permissonsCopy.length; j += 1) {
      if (permissonsCopy[j].value === name) {
        permissonsCopy[j].shouldUpdate = checked;
      }
    }
    setFinalPermissions(permissonsCopy);
  };

  const resetAllPermissions = () => {
    const permissonsCopy = [...finalPermissions];
    for (let j = 0; j < permissonsCopy.length; j += 1) {
      permissonsCopy[j].on = false;
    }
    setFinalPermissions(permissonsCopy);
  };

  const handleSelectAllAdminUsers = (event) => {
    const { checked } = event.target;

    if (checked) {
      setShowUserSelectionBar(true);
      setFinalAdminUsers(adminUsers);
    } else {
      setShowUserSelectionBar(false);
      setFinalAdminUsers([]);
      resetAllPermissions();
    }
  };

  const onDelete = (i) => {
    const adminSelected = finalAdminUsers.slice(0);
    adminSelected.splice(i, 1);
    setFinalAdminUsers(adminSelected);

    // reset all permissions if no more users are selected
    if (adminSelected.length === 0) {
      resetAllPermissions();
    }
  };

  const onAddition = (newlyAddedUser) => {
    const users = [].concat(finalAdminUsers, newlyAddedUser);
    setFinalAdminUsers(users);

    // update permissions list table with permissions of the selected user
    const theAdminUser = adminUsers.find(u => u.id === newlyAddedUser.id);
    const permissonsCopy = [...finalPermissions];
    for (let i = 0; i < theAdminUser.permissions.length; i += 1) {
      const theUserPermission = theAdminUser.permissions[i];
      for (let j = 0; j < permissonsCopy.length; j += 1) {
        if (permissonsCopy[j].value === theUserPermission) {
          permissonsCopy[j].on = true;
          j = permissonsCopy.length; // terminate inner loop, move on to the next permission
        }
      }
    }
    setFinalAdminUsers(users);
    setFinalPermissions(permissonsCopy);
  };

  const columnTransform = {
    shouldUpdate: {
      title: 'Update?',
      valueFunc: record => (
        <div className="d-flex align-items-center justify-content-center">
          <CustomInput id={`${record.index}-update`} type="checkbox" name={record.value} onChange={handleSwitchChangeUpdate} checked={record.shouldUpdate} />
        </div>
      ),
      sort: () => {},
      width: 80,
      disableSortBy: true,
    },
    on: {
      title: 'Allow access?',
      valueFunc: record => (
        <div className="d-flex align-items-center justify-content-center">
          <CustomInput id={`${record.index}-allow`} type="switch" name={record.value} onChange={handleSwitchChangeAllow} checked={record.on} />
        </div>
      ),
      sort: () => {},
      width: 100,
      disableSortBy: true,
    },
    label: {
      title: 'Module Name',
      sort: record => record.label,
      valueFunc: (record) => {
        if (record.value.indexOf('---') > 0) {
          return <> &emsp; <b>{record.label}</b> &emsp; ({record.value.replaceAll('----', '-')})</>;
        }
        return <><b>{record.label}</b> &emsp; ({record.value})</>;
      },
      searchFunc: (record, search) => (record.value ? record.value.toLowerCase().indexOf(search.toLowerCase()) !== -1 : false),
      width: 500,
      disableSortBy: true,
    },
  };

  if (finalPermissions.length === 0) return null;

  return (
    <div className='ltr-support theme-light'>
      <h3>Admin Users</h3>
      <div>
        <p>Select the Admin User(s) to update permissions for:</p>
        <ReactTags
          tags={finalAdminUsers}
          suggestions={adminUsers}
          onDelete={onDelete}
          onAddition={onAddition}
          delimiters={['Enter', ',', 'Tab']}
          inputDisabled={showUserSelectionBar}
          placeholderText="Add by name(s)"
          autoresize={false}
        />
      </div>
      <div id="selectAllAdminUsersCheckBox">
        <CustomInput
          id="selectAllAdminUsers-1"
          type="checkbox"
          name="selectAllAdminUsers"
          onChange={handleSelectAllAdminUsers}
          checked={showUserSelectionBar}
        />
        &emsp;
        <p>Select all active admin users</p>
      </div>
      <br />
      <h3>Permissions</h3>
      <div id="adminUserPermissionsTable">
        <MatTableForRedux
          isResizable
          dataArray={finalPermissions}
          selectable={false}
          columns={columnTransform}
          version="1"
          defaultOrder={{
          order: 'asc',
          orderBy: 'index',
        }}
          rowsPerPage={10}
        />
      </div>
    </div>
  );
};

PermissionsDialog.propTypes = {
  adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  availablePermissions: PropTypes.arrayOf(PropTypes.any).isRequired,
  setFinalPermissions: PropTypes.func.isRequired,
  setFinalAdminUsers: PropTypes.func.isRequired,
  finalPermissions: PropTypes.arrayOf(PropTypes.any).isRequired,
  finalAdminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default PermissionsDialog;
