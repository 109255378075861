import React, { useState, useEffect } from 'react';
import { unflatten } from 'flat';
import Select from 'react-select';
import axios from 'axios';

const options = [
  { value: '', label: '-- Not Assigned --' },
  { value: 'CPA/Ambassador', label: 'CPA/Ambassador' },
  { value: 'Upfront CPM', label: 'Upfront CPM' },
  { value: 'Upfront Only', label: 'Upfront Only' }
];

const DealType = ({ record, updateRecordFunction}) => {
  const valueFromRecord = record?.params?.opportunity?.dealType ? options.find(a => String(a.value).toLowerCase() === String(record?.params?.opportunity?.dealType).toLowerCase()): { value: '', label: '-- Not Assigned --' };
  const [selectedOption, setSelectedOption] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  useEffect(() => {
    setSelectedOption(valueFromRecord);
  }, [record?.params?.opportunity?.dealType]);

  const handleChange = (option) => {
    setSelectedOption(option);
    saveDealType(option.value);
  };

  const saveDealType = async (dealType) => {
    setIsSaving(true);
    try {
      const response = await axios.post('/api/portal/prospects/post-update-many', {
        params: { 'opportunity.dealType': dealType},
        ids: [record.id ],
      });
      if (response.data.error) {
        throw new Error(response.data.error);
      }
      updateRecordFunction(unflatten(response.data.record));
      console.log('Deal type saved:', response.data);
    } catch (error) {
      console.error('Error saving deal type:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div style={{ width: '100%' }}>
        <Select
          className='w-100'
          id="dealTypeSelect"
          value={selectedOption}
          onChange={handleChange}
          options={options}
          menuPlacement="auto"
          menuPortalTarget={document.body}
          isDisabled={isSaving}
        />
    </div>
  );
};

export default DealType;
