import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardTitle,
  Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useAsyncDebounce } from 'react-table';
import { axios } from 'ApiClient';
import BrandSafetySearchTermsModal from './custom/modals/BrandSafetySearchTermsModal';
import UpdateStatusOfSelectedProspectsModal from './custom/modals/UpdateStatusOfSelectedProspectsModal';
import { uploadProspects, getBrandSafetySearchTerms, getAdminUsers } from '../../../../../../redux/reducers/admin/ResearchReducer';
import RefreshStatisticsOptionsModal from './custom/modals/RefreshStatisticsOptionsModal';
import UploadProspectsModal from './custom/modals/UploadProspectsModal';
import Table from './custom/Table';
import Actions from './custom/Actions';
import Breadcrumb from '../../../../../../shared/components/BreadCrumbs';
import RefreshModal from './custom/modals/UpdateAllModal';
import DeleteProspectModal from './custom/modals/DeleteProspectModal';
import ManageTagsModal from './custom/modals/ManageTagsModal';
import RelatedPodcastsModal from '../../Search/components/custom/RelatedPodcastsModal';
import NotesModal from './custom/modals/NotesModal';
import withNotice from '../../../../../App/store/with-notice';
import AssignAnalystModal from './custom/modals/AssignAnalystModal';
import AssignProgramModal from './custom/modals/AssignProgramModal';
import MissingInfoModal from './custom/modals/MissingInfoModal';
import { parseUrl } from '../../../../../../shared/helpers/WVUtilities';
import { fetchDictionary } from '../../../../../../shared/helpers/WVRequest';
import ModalInfoBase from '../../../../../../shared/components/modals/ModalInfoBase';
import AssignModal from '../../../../../../shared/components/modals/AssignModal';
import CloneProspectModal from './custom/modals/CloneProspectModal';
import DetailsModal from './custom/modals/DetailsModal';
import StatusHistoryModal from './custom/modals/StatusHistoryModal';
import TabsOrModal from './custom/modals/TabsOrModal';
import AddOrRemoveBlackListModal from './custom/modals/AddOrRemoveBlackListModal';
import AllTagsModal from './custom/modals/AllTagsModal';
import useDidMountEffect from '../../../../../../shared/hooks/useDidMountEffect';
import ShowRejectedApprovedBrands from '../../Search/components/modals/ShowRejectedApprovedBrands';
import {countriesBH} from "../../../../../../shared/helpers/WVConstants";

const compare = {
  Match: 'info',
  'Possible Match': 'warning',
  'No Match': 'noMatch',
};

const goToEditProspectPage = (prospectId) => {
  document.location = `/research/prospects/edit/${prospectId}`;
};

const getIlInitial = () => {
  const filters = parseUrl();
  const initialILFilters = {
    Approved: true,
    Hold: true,
    'In Review': true,
    Mining: true,
    Rejected: true,
    Resubmit: true,
  };
  if (filters?.approvalStatusinfluencelogicstatus) {
    Object.keys(initialILFilters).forEach((key) => {
      initialILFilters[key] = filters?.approvalStatusinfluencelogicstatus.includes(key);
    });
  }
  return initialILFilters;
};

const getBrandInitial = () => {
  const filters = parseUrl();
  const initialBrandFilters = {
    Approved: true,
    'In Review': true,
    Hold: true,
    Rejected: true,
    'In Appeals': true,
  };
  if (filters?.approvalStatusbrandstatus) {
    Object.keys(initialBrandFilters).forEach((key) => {
      initialBrandFilters[key] = filters?.approvalStatusbrandstatus.includes(key);
    });
  }
  return initialBrandFilters;
};

const getMatchInitial = () => {
  const filters = parseUrl();
  const initialMatchFilters = {
    Match: true,
    'Possible Match': true,
    'No Match': true,
  };
  if (filters?.existStatus) {
    Object.keys(initialMatchFilters).forEach((key) => {
      const rightKey = Object.keys(compare).find(keyInState => keyInState === key);
      initialMatchFilters[key] = filters?.existStatus.includes(compare[rightKey]);
    });
  }
  return initialMatchFilters;
};

const getOppInitial = () => {
  const filters = parseUrl();
  const initialMatchFilters = {
    yes: true,
    no: true,
  };
  if (filters?.opps) {
    Object.keys(initialMatchFilters).forEach((key) => {
      initialMatchFilters[key] = filters?.opps.includes(key);
    });
  }
  console.log('initialMatchFilters', initialMatchFilters)
  return initialMatchFilters;
};

const getAnalystInitial = () => {
  const filters = parseUrl();
  const initialAnalystFilters = {
    Assigned: true,
    'Not Assigned': true,
  };
  if (filters?.analyst) {
    Object.keys(initialAnalystFilters).forEach((key) => {
      initialAnalystFilters[key] = filters?.analyst.includes(key);
    });
  }
  return initialAnalystFilters;
};

const getCategoryInitial = () => {
  const filters = parseUrl();
  const initialCategoryFilters = {
    Assigned: true,
    'Not Assigned': true,
  };
  if (filters?.category) {
    Object.keys(initialCategoryFilters).forEach((key) => {
      initialCategoryFilters[key] = filters?.category.includes(key);
    });
  }
  return initialCategoryFilters;
};

const getProgramInitial = () => {
  const filters = parseUrl();
  const initialFilters = {
    'Not Assigned': true,
    CPA: true,
    CPM: true,
  };
  if (filters?.program) {
    Object.keys(initialFilters).forEach((key) => {
      initialFilters[key] = filters?.program.includes(key);
    });
  }
  return initialFilters;
};

const getMissingInfoInitial = () => {
  const filters = parseUrl();
  const initialFilters = {
    'First Name': false,
    'Last Name': false,
    Email: false,
  };
  if (filters?.missingInfo) {
    Object.keys(initialFilters).forEach((key) => {
      initialFilters[key] = filters?.missingInfo.includes(key);
    });
  }
  return initialFilters;
};

const getDateInitial = (type) => {
  let date = null;
  const filters = parseUrl();
  // eslint-disable-next-line prefer-template
  const name = 'createdAt.' + type;
  if (filters?.[name]) {
    date = new Date(filters[name]);
  }
  return date;
};

const getIsInternalInitial = () => {
  const filters = parseUrl();
  const initialIsInternalFilters = {
    Standard: true,
    Internal: true,
  };
  if (filters?.prospectType) {
    Object.keys(initialIsInternalFilters).forEach((key) => {
      initialIsInternalFilters[key] = filters?.prospectType.includes(key);
    });
  }
  return initialIsInternalFilters;
};

const getInBlacklistInitial = () => {
  const filters = parseUrl();
  const initialInBlacklistFilters = {
    Yes: true,
    No: true,
  };
  if (filters?.inBlackList) {
    Object.keys(initialInBlacklistFilters).forEach((key) => {
      initialInBlacklistFilters[key] = filters?.inBlackList.includes(key);
    });
  }
  return initialInBlacklistFilters;
};

const ProspectsListContainer = ({
  addNotice,
  dispatch,
  loadingUploadProspects,
  uploadProspectsResults,
  uploadProspectsError,
  history,
  action,
  resource,
  brandSafetySearchTerms,
  adminUsers,
  currentAdminUser,
}) => {
  const [loadingFilters, setLoadingFilters] = useState(null);
  const [analystsSuggestions, setAnalystsSuggestions] = useState([]);
  const [adminUserSuggestions, setAdminUserSuggestions] = useState([]);
  const [prospectOwnerSuggestions, setProspectOwnerSuggestions] = useState([]);
  const [categorySuggestions, setCategorySuggestions] = useState([]);
  const [rejectionReasonSuggestions, setRejectionReasonSuggestions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [rejectionReasons, setRejectionReasons] = useState([]);
  const [countrySuggestions, setCountrySuggestions] = useState([]);
  const getTagsInitial = (tagType, returnObject) => {
    const determineName = (tag) => {
      let result = tag;
      if (tagType === 'analystUsers' && analystsSuggestions.length > 0) {
        analystsSuggestions.forEach((suggestion) => {
          if (tag === suggestion.id) {
            result = suggestion.name;
          }
        });
      }
      if (tagType === 'adminUsers' && adminUserSuggestions.length > 0) {
        adminUserSuggestions.forEach((suggestion) => {
          if (tag === suggestion.id) {
            result = suggestion.name;
          }
        });
      }
      if (tagType === 'prospectOwners' && prospectOwnerSuggestions.length > 0) {
        prospectOwnerSuggestions.forEach((suggestion) => {
          if (tag === suggestion.id) {
            result = suggestion.name;
          }
        });
      }
      if (tagType === 'categories' && categorySuggestions.length > 0) {
        categorySuggestions.forEach((suggestion) => {
          if (tag === suggestion.id) {
            result = suggestion.name;
          }
        });
      }
      if (tagType === 'rejectionReasons' && rejectionReasonSuggestions.length > 0) {
        rejectionReasonSuggestions.forEach((suggestion) => {
          if (tag === suggestion.id) {
            result = suggestion.name;
          }
        });
      }
      if (tagType === 'countryOfResidence' && countrySuggestions.length > 0) {
        countrySuggestions.forEach((suggestion) => {
          if (tag === suggestion.id) {
            result = suggestion.name;
          }
        });
      }
      return result;
    };
    const determineObject = (tag) => {
      let result = { name: determineName(tag) };
      if (tagType === 'analystUsers' && analystsSuggestions.length > 0) {
        analystsSuggestions.forEach((ownerSuggestion) => {
          if (tag === ownerSuggestion.id) {
            result = { name: ownerSuggestion.name, id: ownerSuggestion.id };
          }
        });
      }
      if (tagType === 'adminUsers' && adminUserSuggestions.length > 0) {
        adminUserSuggestions.forEach((suggestion) => {
          if (tag === suggestion.id) {
            result = { name: suggestion.name, id: suggestion.id };
          }
        });
      }
      if (tagType === 'prospectOwners' && prospectOwnerSuggestions.length > 0) {
        prospectOwnerSuggestions.forEach((suggestion) => {
          if (tag === suggestion.id) {
            result = { name: suggestion.name, id: suggestion.id };
          }
        });
      }
      if (tagType === 'categories' && categorySuggestions.length > 0) {
        categorySuggestions.forEach((suggestion) => {
          if (tag === suggestion.id) {
            result = { name: suggestion.name, id: suggestion.id };
          }
        });
      }
      if (tagType === 'rejectionReasons' && rejectionReasonSuggestions.length > 0) {
        rejectionReasonSuggestions.forEach((suggestion) => {
          if (tag === suggestion.id) {
            result = { name: suggestion.name, id: suggestion.id };
          }
        });
      }
      if (tagType === 'countryOfResidence' && countrySuggestions.length > 0) {
        countrySuggestions.forEach((suggestion) => {
          if (tag === suggestion.id) {
            result = { name: suggestion.name, id: suggestion.id };
          }
        });
      }
      return result;
    };
    const filters = parseUrl();
    const initialTagFilters = returnObject ? {} : [];
    let filtersProspectTags = [];
    if (filters[tagType] && Array.isArray(filters[tagType])) {
      filtersProspectTags = filters[tagType];
    } else if (filters[tagType]) {
      filtersProspectTags = [filters[tagType]];
    }
    filtersProspectTags.forEach((tag) => {
      if (returnObject) {
        initialTagFilters[tag] = true;
      } else if (tagType === 'brands' || tagType === 'excludeBrands') {
        initialTagFilters.push({ id: tag });
      } else {
        initialTagFilters.push(determineObject(tag));
      }
    });
    return initialTagFilters;
  };
  const filters = parseUrl();
  const [version, setVersion] = useState((new Date()).toString());
  const [ilFilters, setILFilters] = useState(getIlInitial());
  const [brandFilters, setBrandFilters] = useState(getBrandInitial());
  const [matchFilters, setMatchFilters] = useState(getMatchInitial());
  const [oppFilters, setOppFilters] = useState(getOppInitial());
  const [analystFilters, setAnalystFilters] = useState(getAnalystInitial());
  const [categoryFilters, setCategoryFilters] = useState(getCategoryInitial());
  const [programFilters, setProgramFilters] = useState(getProgramInitial());
  const [missingInfoFilters, setMissingInfoFilters] = useState(getMissingInfoInitial());
  const [isInternalFilters, setIsInternalFilters] = useState(getIsInternalInitial());
  const [inBlacklistFilters, setInBlacklistFilters] = useState(getInBlacklistInitial());
  const [tagsFilter, setTagsFilter] = useState(getTagsInitial('tags', true));
  const [tagsIncludeBrandsFilter, setTagsIncludeBrandsFilter] = useState(getTagsInitial('brands', true));
  const [tagsExcludeBrandsFilter, setTagsExcludeBrandsFilter] = useState(getTagsInitial('excludeBrands', true));
  const [searchValue, onSearchChange] = useState(filters.fullSearchName || '');
  const [showRefreshModal, setShowRefreshModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAssignAnalystModal, setShowAssignAnalystModal] = useState(false);
  const [showAssignProgramModal, setShowAssignProgramModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [assignModalParams, setAssignModalParams] = useState({});
  const [showMissingInfoModal, setShowMissingInfoModal] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showManageTagsModal, setShowManageTagsModal] = useState(false);
  const [showDuplicateTargetBrandModal, setShowDuplicateTargetBrandModal] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showBlackListModal, setShowBlackListModal] = useState(false);
  const [showAllTagsModal, setShowAllTagsModal] = useState(false);
  const [actionBlackListModal, setActionBlackListModal] = useState('add');
  const [newBrand, setNewBrand] = useState('');
  const [disableNote, setDisableNote] = useState(false);
  const [portal, setPortal] = useState('influenceLogic');
  const [currentProspect, setCurrentProspect] = useState({});
  const [uploadCsvModalVisible, setUploadCsvModalVisible] = useState(false);
  const [brandSafetySearchTermsModalVisible, setBrandSafetySearchTermsModalVisible] = useState(false);
  const [selectedProspectIds, setSelectedProspectIds] = useState([]);
  const [singleSelectedProspectId, setSingleSelectedProspectId] = useState('');
  const [singleSelectedProspectPodcastId, setSingleSelectedProspectPodcastId] = useState('');
  const [singleSelectedProspectCurrentEmail, setSingleSelectedProspectCurrentEmail] = useState('');
  const [tags, setTags] = useState(getTagsInitial('tags', false));
  const [tagsSuggestions, setTagsSuggestions] = useState([]);
  const [tagsIncludeBrands, setTagsIncludeBrands] = useState(getTagsInitial('brands', false));
  const [tagsExcludeBrands, setTagsExcludeBrands] = useState(getTagsInitial('excludeBrands', false));
  const [tagsBrandsSuggestions, setTagsBrandsSuggestions] = useState([]);
  const [tagsAnalystsFilter, setTagsAnalystsFilter] = useState(getTagsInitial('analystUsers', true));
  const [tagsCategoriesFilter, setTagsCategoriesFilter] = useState(getTagsInitial('categories', true));
  const [tagsCategories, setTagsCategories] = useState(getTagsInitial('categories', false));
  const [tagsRejectionReasonsFilter, setTagsRejectionReasonsFilter] = useState(getTagsInitial('rejectionReasons', true));
  const [tagsRejectionReasons, setTagsRejectionReasons] = useState(getTagsInitial('rejectionReasons', false));
  const [tagsCountriesFilter, setTagsCountriesFilter] = useState(getTagsInitial('countryOfResidence', true));
  const [tagsCountries, setTagsCountries] = useState(getTagsInitial('countryOfResidence', false));
  const [analysts, setAnalysts] = useState(getTagsInitial('analystUsers', false));
  const [tagsProspectCreatorsFilter, setTagsProspectCreatorsFilter] = useState(getTagsInitial('adminUsers', true));
  const [prospectCreators, setProspectCreators] = useState(getTagsInitial('adminUsers', false));
  const [tagsProspectOwnersFilter, setTagsProspectOwnersFilter] = useState(getTagsInitial('prospectOwners', true));
  const [prospectOwners, setProspectOwners] = useState(getTagsInitial('prospectOwners', false));
  const [updateStatusModalVisible, setUpdateStatusModalVisible] = useState(false);
  const [updatingStatusInProgress, setUpdatingStatusInProgress] = useState(false);
  const [refreshStatisticsOptionsModalVisible, setRefreshStatisticsOptionsModalVisible] = useState(false);
  const [brands, setBrands] = useState([]);
  const [openRelatedPodcastModal, setOpenRelatedPodcastModal] = useState({ isOpen: false, title: '' });
  const [prospectIdsToDelete, setProspectIdsToDelete] = useState([]);
  const [metaTotal, setMetaTotal] = useState(0);
  const [createDateFrom, setCreateDateFrom] = useState(getDateInitial('from'));
  const [createDateTo, setCreateDateTo] = useState(getDateInitial('to'));
  const [missingInfoProspect, setMissingInfoProspect] = useState({});
  const [prospectGroupUploadLog, setProspectGroupUploadLog] = useState(null);
  const [openTabs, setOpenTabs] = useState(false);
  const recordsRef = useRef([]);
  const [disableBlacklistActions, setDisableBlacklistActions] = useState(false);
  const [countryTableOptions, setCountryTableOptions] = useState([]);

  const mapCountriesOptions = () => {
    const countries = countriesBH();
    const co =  Object.entries(countries).map(([countryCode, countries]) => ({ id: countryCode, name: countries }));
    const coTable =  Object.entries(countries).map(([countryCode, countries]) => ({ value: countryCode, label: countries }));
    co.unshift({ id: 'null', name: '-- Not Assigned --' });
    setCountrySuggestions(co);
    setCountryTableOptions(coTable);
  };

  const prospectContainerRefresh = async (id, overwriteChannelNameAndURL) => {
    try {
      await axios.post('/api/portal/prospects/post-fetch-data', {
        prospectsIds: [id],
        overwriteChannelNameAndURL,
        identity: Math.random(),
      }).then((response) => {
        // console.log(response);
        if (response.data.success) {
          addNotice({
            message: `Successfully refreshed ${response.data.numberOfProspects} of ${response.data.numberOfProspects} items.`,
            type: 'success',
          });
        } else {
          addNotice({
            message: `Successfully refreshed ${response.data.successfulRefreshed} of ${response.data.numberOfProspects} items.`,
            type: 'error',
            duration: 10,
          });
        }
      });
      setVersion((new Date()).toString());
    } catch (e) {
      console.log(e);
    }
    return true;
  };

  const assignPodcastToProspect = async (podcastId, overwriteEmail) => {
    try {
      await axios.post('/api/portal/prospects/post-assign-podcast', {
        prospectId: singleSelectedProspectId,
        podcastId,
        overwriteEmail,
      }).then((response) => {
        if (response.data.success) {
          dispatch(addNotice({
            message: 'Successfully assigned the podcast to the creator.',
            type: 'success',
          }));
          setVersion((new Date()).toString());
        } else {
          dispatch(addNotice({
            message: response.data.error || 'Something went wrong...',
            type: 'error',
          }));
        }
      }).catch((error) => {
        console.log(error);
        dispatch(addNotice({
          message: error.message || error,
          type: 'error',
        }));
      });
    } catch (e) {
      console.log(e);
    }
    return true;
  };

  const fetchOwnersSuggestions = () => {
    axios({
      method: 'get',
      url: '/api/portal/prospects/get-prospect-owners',
    }).then((response) => {
      if (response.data.success === true) {
        setAnalystsSuggestions(response.data.suggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const fetchProspectCreatorSuggestions = () => {
    axios({
      method: 'get',
      url: '/api/portal/prospects/get-all-admin-users',
    }).then((response) => {
      if (response.data.success === true) {
        const suggestions = [];
        response.data.data.forEach((user) => {
          suggestions.push({ id: user.value, name: user.label });
        });
        setAdminUserSuggestions(suggestions);
        setProspectOwnerSuggestions(suggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const fetchDictionaryData = async () => {
    await fetchDictionary('PROSPECT_').then((data) => {
      const { success, records } = data;
      if (success === true) {
        const _categories = records.PROSPECT_CATEGORIES ? JSON.parse(records.PROSPECT_CATEGORIES) : [];
        const _rejectionReasons = records.PROSPECT_REJECTION_REASONS ? JSON.parse(records.PROSPECT_REJECTION_REASONS) : [];
        setCategories(_categories);
        setRejectionReasons(_rejectionReasons);
        const _categorySuggestions = _categories.map(row => ({ id: row, name: row }));
        _categorySuggestions.unshift({ id: 'null', name: '-- Not Assigned --' });
        const _rejectionReasonSuggestions = _rejectionReasons.map(row => ({ id: row, name: row }));
        _rejectionReasonSuggestions.unshift({ id: 'null', name: '-- Not Assigned --' });
        setCategorySuggestions(_categorySuggestions);
        setRejectionReasonSuggestions(_rejectionReasonSuggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const getUrlSearch = () => {
    const search = new URLSearchParams(window.location.search);
    if (searchValue) {
      search.set('filters.fullSearchName', searchValue);
    } else {
      search.delete('filters.fullSearchName');
    }
    if (ilFilters && Object.keys(ilFilters).length > 0) {
      search.delete('filters.approvalStatusinfluencelogicstatus');
      let allIlSwitchedOff = true;
      Object.keys(ilFilters).forEach((key) => {
        if (!ilFilters[key]) return false;
        search.append('filters.approvalStatusinfluencelogicstatus', key);
        allIlSwitchedOff = false;
        return true;
      });
      if (allIlSwitchedOff) {
        search.append('filters.approvalStatusinfluencelogicstatus', 'Not Exist');
      }
    }
    if (brandFilters && Object.keys(brandFilters).length > 0) {
      search.delete('filters.approvalStatusbrandstatus');
      let allBrandSwitchedOff = true;
      Object.keys(brandFilters).forEach((key) => {
        if (!brandFilters[key]) return false;
        search.append('filters.approvalStatusbrandstatus', key);
        allBrandSwitchedOff = false;
        return true;
      });
      if (allBrandSwitchedOff) {
        search.append('filters.approvalStatusbrandstatus', 'Not Exist');
      }
    }
    if (matchFilters && Object.keys(matchFilters).length > 0) {
      search.delete('filters.existStatus');
      let allMatchSwitchedOff = true;
      Object.keys(matchFilters).forEach((key) => {
        if (!matchFilters[key]) return false;
        search.append('filters.existStatus', compare[key]);
        allMatchSwitchedOff = false;
        return true;
      });
      if (allMatchSwitchedOff) {
        search.append('filters.existStatus', 'Not Exist');
      }
    }
    if (oppFilters && Object.keys(oppFilters).length > 0) {
      search.delete('filters.opp');
      Object.keys(oppFilters).forEach((key) => {
        if (!oppFilters[key]) return false;
        search.append('filters.opp', key);
        return true;
      });
    }
    if (analystFilters && Object.keys(analystFilters).length > 0) {
      search.delete('filters.analyst');
      let allAnalystSwitchedOff = true;
      Object.keys(analystFilters).forEach((key) => {
        if (!analystFilters[key]) return false;
        search.append('filters.analyst', key);
        allAnalystSwitchedOff = false;
        return true;
      });
      if (allAnalystSwitchedOff) {
        search.append('filters.analyst', 'Not Exist');
      }
    }
    if (categoryFilters && Object.keys(categoryFilters).length > 0) {
      search.delete('filters.category');
      let allCategorySwitchedOff = true;
      Object.keys(categoryFilters).forEach((key) => {
        if (!categoryFilters[key]) return false;
        search.append('filters.category', key);
        allCategorySwitchedOff = false;
        return true;
      });
      if (allCategorySwitchedOff) {
        search.append('filters.category', 'Not Exist');
      }
    }
    if (programFilters && Object.keys(programFilters).length > 0) {
      search.delete('filters.program');
      let allProgramSwitchedOff = true;
      Object.keys(programFilters).forEach((key) => {
        if (!programFilters[key]) return false;
        search.append('filters.program', key);
        allProgramSwitchedOff = false;
        return true;
      });
      if (allProgramSwitchedOff) {
        search.append('filters.program', 'Not Exist');
      }
    }
    if (isInternalFilters && Object.keys(isInternalFilters).length > 0) {
      search.delete('filters.prospectType');
      Object.keys(isInternalFilters).forEach((key) => {
        if (!isInternalFilters[key]) return false;
        search.append('filters.prospectType', key);
        return true;
      });
    }
    if (inBlacklistFilters && Object.keys(inBlacklistFilters).length > 0) {
      search.delete('filters.inBlackList');
      Object.keys(inBlacklistFilters).forEach((key) => {
        if (!inBlacklistFilters[key]) return false;
        search.append('filters.inBlackList', key);
        return true;
      });
    }
    if (missingInfoFilters && Object.keys(missingInfoFilters).length > 0) {
      search.delete('filters.missingInfo');
      Object.keys(missingInfoFilters).forEach((key) => {
        if (!missingInfoFilters[key]) return false;
        search.append('filters.missingInfo', key);
        return true;
      });
    }
    search.delete('filters.tags');
    if (tagsFilter && Object.keys(tagsFilter).length > 0) {
      Object.keys(tagsFilter).forEach((key) => {
        if (!tagsFilter[key]) return false;
        search.append('filters.tags', key);
        return true;
      });
    }

    search.delete('filters.brands');
    if (tagsIncludeBrandsFilter && Object.keys(tagsIncludeBrandsFilter).length > 0) {
      Object.keys(tagsIncludeBrandsFilter).forEach((key) => {
        if (!tagsIncludeBrandsFilter[key]) return false;
        search.append('filters.brands', key);
        return true;
      });
    }
    search.delete('filters.excludeBrands');
    if (tagsExcludeBrandsFilter && Object.keys(tagsExcludeBrandsFilter).length > 0) {
      Object.keys(tagsExcludeBrandsFilter).forEach((key) => {
        if (!tagsExcludeBrandsFilter[key]) return false;
        search.append('filters.excludeBrands', key);
        return true;
      });
    }
    search.delete('filters.analystUsers');
    if (tagsAnalystsFilter && Object.keys(tagsAnalystsFilter).length > 0) {
      Object.keys(tagsAnalystsFilter).forEach((key) => {
        if (!tagsAnalystsFilter[key]) return false;
        search.append('filters.analystUsers', key);
        return true;
      });
    }
    search.delete('filters.adminUsers');
    if (tagsProspectCreatorsFilter && Object.keys(tagsProspectCreatorsFilter).length > 0) {
      Object.keys(tagsProspectCreatorsFilter).forEach((key) => {
        if (!tagsProspectCreatorsFilter[key]) return false;
        search.append('filters.adminUsers', key);
        return true;
      });
    }
    search.delete('filters.prospectOwners');
    if (tagsProspectOwnersFilter && Object.keys(tagsProspectOwnersFilter).length > 0) {
      Object.keys(tagsProspectOwnersFilter).forEach((key) => {
        if (!tagsProspectOwnersFilter[key]) return false;
        search.append('filters.prospectOwners', key);
        return true;
      });
    }
    search.delete('filters.categories');
    if (tagsCategoriesFilter && Object.keys(tagsCategoriesFilter).length > 0) {
      Object.keys(tagsCategoriesFilter).forEach((key) => {
        if (!tagsCategoriesFilter[key]) return false;
        search.append('filters.categories', key);
        return true;
      });
    }
    search.delete('filters.rejectionReasons');
    if (tagsRejectionReasonsFilter && Object.keys(tagsRejectionReasonsFilter).length > 0) {
      Object.keys(tagsRejectionReasonsFilter).forEach((key) => {
        if (!tagsRejectionReasonsFilter[key]) return false;
        search.append('filters.rejectionReasons', key);
        return true;
      });
    }
    search.delete('filters.countryOfResidence');
    if (tagsCountriesFilter && Object.keys(tagsCountriesFilter).length > 0) {
      Object.keys(tagsCountriesFilter).forEach((key) => {
        if (!tagsCountriesFilter[key]) return false;
        search.append('filters.countryOfResidence', key);
        return true;
      });
    }
    search.delete('filters.prospectGroupUploadLog');
    if (prospectGroupUploadLog && prospectGroupUploadLog.value) {
      search.append('filters.prospectGroupUploadLog', prospectGroupUploadLog.value.toString());
    }
    search.delete('filters.createdAt.from');
    search.delete('filters.createdAt.to');
    if (createDateFrom && createDateFrom !== '') {
      search.append('filters.createdAt.from', createDateFrom.toISOString());
    }
    if (createDateTo && createDateTo !== '') {
      search.append('filters.createdAt.to', createDateTo.toISOString());
    }
    return search;
  };

  const firstUpdate = useRef(true);
  const setUrl = () => {
    const search = getUrlSearch();
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      search.set('page', '1');
    }
    history.push(`${history.location.pathname}?${search.toString()}`);
  };

  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 800);

  useDidMountEffect(() => {
    setUrlDebounced();
  }, [
    searchValue,
    ilFilters,
    brandFilters,
    matchFilters,
    oppFilters,
    analystFilters,
    categoryFilters,
    programFilters,
    missingInfoFilters,
    tagsFilter,
    tagsIncludeBrandsFilter,
    tagsExcludeBrandsFilter,
    tagsAnalystsFilter,
    tagsProspectCreatorsFilter,
    tagsProspectOwnersFilter,
    tagsCategoriesFilter,
    tagsRejectionReasonsFilter,
    prospectGroupUploadLog,
    createDateFrom,
    createDateTo,
    isInternalFilters,
    inBlacklistFilters,
    tagsCountriesFilter,
  ]);

  useEffect(() => {
    if (analystsSuggestions?.length > 0 && filters.analystUsers) setAnalysts(getTagsInitial('analystUsers', false));
  }, [
    analystsSuggestions,
  ]);

  useEffect(() => {
    if (adminUserSuggestions?.length > 0 && filters.adminUsers) setProspectCreators(getTagsInitial('adminUsers', false));
  }, [
    adminUserSuggestions,
  ]);
  useEffect(() => {
    if (prospectOwnerSuggestions?.length > 0 && filters.prospectOwners) setProspectOwners(getTagsInitial('prospectOwners', false));
  }, [prospectOwnerSuggestions]);

  useEffect(() => {
    if (categorySuggestions?.length > 0 && filters.categories) setTagsCategories(getTagsInitial('categories', false));
  }, [
    categorySuggestions,
  ]);

  useEffect(() => {
    if (rejectionReasonSuggestions?.length > 0 && filters.rejectionReasons) setTagsRejectionReasons(getTagsInitial('rejectionReasons', false));
  }, [
    rejectionReasonSuggestions,
  ]);

  useEffect(() => {
    // detects if we came back from editing prospect page
    setVersion((new Date()).toString());
  }, [document.location]);

  useEffect(() => {
    setVersion(Math.random().toString().substring(2, 8));
  }, [categories, rejectionReasons]);

  useEffect(() => {
    if (countrySuggestions?.length > 0 && filters.countryOfResidence) setTagsCountries(getTagsInitial('countryOfResidence', false));
  }, [
    countrySuggestions,
  ]);

  const fetchFilters = async () => {
    setLoadingFilters(true);
    const promises = [
      new Promise(resolve => fetchDictionaryData().then(() => resolve('finished'))),
      new Promise(resolve => fetchOwnersSuggestions().then(() => resolve('finished'))),
      new Promise(resolve => fetchProspectCreatorSuggestions().then(() => resolve('finished'))),
    ];
    if (adminUsers.length < 1) {
      promises.push(new Promise((resolve) => {
        dispatch(getAdminUsers(true));
        resolve('finished');
      }));
    }
    Promise.allSettled(promises).then(() => setLoadingFilters(false));
  };

  useEffect(async () => {
    dispatch(getBrandSafetySearchTerms());
    fetchFilters();
    mapCountriesOptions();
  }, []);

  const handleSelectCheckbox = (selectedIds) => {
    let shouldDisableBlacklistActions = false;
    recordsRef.current.forEach((record) => {
      if (selectedIds.includes(record.id)) {
        if (record.params?.inBlackList) {
          shouldDisableBlacklistActions = true;
        }
      }
    });
    setDisableBlacklistActions(shouldDisableBlacklistActions);
    setSelectedProspectIds(selectedIds);
  };

  const updateSelectedProspectsStatuses = async (updateILStatus = false, ilStatus, updateBrandStatus = false, brandStatus) => {
    try {
      setUpdatingStatusInProgress(true);
      axios.post('/api/portal/prospects/post-update-statuses', {
        prospectIds: selectedProspectIds,
        updateILStatus,
        ilStatus,
        updateBrandStatus,
        brandStatus,
        dontCheckMissingInfo: true,
      }).then((response) => {
        if (response && response.data && response.data.success) {
          setUpdatingStatusInProgress(false);
          setUpdateStatusModalVisible(false);
          setVersion((new Date()).toString());
        } else {
          if (response && response.data && response.data.error) {
            console.log(response.data.error);
          }
          dispatch(addNotice({
            message: 'Failed to update selected prospects. Please refresh the page and try again',
            type: 'error',
            duration: 15,
          }));
          setUpdatingStatusInProgress(false);
          setUpdateStatusModalVisible(false);
        }
      }).catch((error) => {
        console.log(error.message);
        dispatch(addNotice({
          message: 'Failed to update selected prospects. Please refresh the page and try again',
          type: 'error',
          duration: 15,
        }));
        setUpdatingStatusInProgress(false);
        setUpdateStatusModalVisible(false);
      });
    } catch (error) {
      console.log(error);
      dispatch(addNotice({
        message: 'Failed to update selected prospects. Please refresh the page and try again',
        type: 'error',
        duration: 15,
      }));
      setUpdatingStatusInProgress(false);
      setUpdateStatusModalVisible(false);
    }
  };

  const closeCsvUploadModal = (isVisible) => {
    setUploadCsvModalVisible(isVisible);
    if (isVisible === false) {
      setVersion((new Date()).toString());
    }
  };

  const importRecords = (files, tagsArr, advertiserId, analystId, queue, identity, prospectGroupsData, scheduledDate, ilStatus, brandStatus) => {
    const file = files.length ? files[0] : false;
    if (file) {
      dispatch(uploadProspects(tagsArr, advertiserId, analystId, file, true, identity, prospectGroupsData, scheduledDate, ilStatus, brandStatus));
    }
    return true;
  };

  const fetchSuggestions = () => {
    axios({
      method: 'get',
      url: '/api/portal/prospects/get-prospects-tags?suggestionsOnly=true',
    }).then((response) => {
      if (response.data.success === true) {
        // console.log('TAGS: ', response.data.suggestions);
        setTagsSuggestions(response.data.suggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const fetchSuggestionsDebounced = useAsyncDebounce(() => {
    fetchSuggestions();
  }, 1500);

  const loadBrandsOptions = async () => {
    const res = [
      { value: null, label: '-- Not Assigned --' },
    ];
    const resTagsBrands = [
      { id: 'Not Assigned', name: '-- Not Assigned --' },
    ];
    const response = await axios({
      method: 'get',
      url: '/api/portal/scorecard/media-content/get-brands-data?term=',
    });
    if (response.data.success) {
      response.data.brands.forEach((brandRecord) => {
        res.push({
          value: brandRecord._id,
          label: brandRecord.companyName,
          isDisabled: !brandRecord.displayInUi || (brandRecord.displayInUi && brandRecord.displayInUi === 'No'),
        });
        resTagsBrands.push({
          id: brandRecord._id,
          name: brandRecord.companyName,
        });
      });
    }
    setBrands(res);
    setTagsBrandsSuggestions(resTagsBrands);
  };

  const showMissingDataModal = (prospect) => {
    setShowMissingInfoModal(true);
    setMissingInfoProspect(prospect);
  };

  useEffect(() => {
    fetchSuggestionsDebounced();
    loadBrandsOptions();
  }, []);

  useEffect(() => {
    fetchSuggestionsDebounced();
    fetchOwnersSuggestions();
    fetchProspectCreatorSuggestions();
  }, [version]);

  useEffect(() => {
    if (tagsBrandsSuggestions.length > 0) {
      const tagsBrandsNew = [];
      tagsIncludeBrands.forEach((t) => {
        const withName = tagsBrandsSuggestions.find(t1 => t1.id === t.id);
        tagsBrandsNew.push({ ...withName });
      });
      setTagsIncludeBrands(tagsBrandsNew);

      const tagsExcludeBrandsNew = [];
      tagsExcludeBrands.forEach((t) => {
        const withName = tagsBrandsSuggestions.find(t1 => t1.id === t.id);
        tagsExcludeBrandsNew.push({ ...withName });
      });
      setTagsExcludeBrands(tagsExcludeBrandsNew);
    }
  }, [tagsBrandsSuggestions]);

  return (
    <Container className="dashboard full-width">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'Research', path: null },
          { title: 'Prospects', path: null },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>Prospects</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Actions
            ilFilters={ilFilters}
            setILFilters={setILFilters}
            brandFilters={brandFilters}
            setBrandFilters={setBrandFilters}
            matchFilters={matchFilters}
            setMatchFilters={setMatchFilters}
            oppFilters={oppFilters}
            setOppFilters={setOppFilters}
            analystFilters={analystFilters}
            setAnalystFilters={setAnalystFilters}
            categoryFilters={categoryFilters}
            setCategoryFilters={setCategoryFilters}
            programFilters={programFilters}
            setProgramFilters={setProgramFilters}
            missingInfoFilters={missingInfoFilters}
            setMissingInfoFilters={setMissingInfoFilters}
            isInternalFilters={isInternalFilters}
            setIsInternalFilters={setIsInternalFilters}
            inBlacklistFilters={inBlacklistFilters}
            setInBlacklistFilters={setInBlacklistFilters}
            onSearchChange={onSearchChange}
            tagsBrandsSuggestions={tagsBrandsSuggestions}
            tagsSuggestions={tagsSuggestions}
            setTagsFilter={setTagsFilter}
            setTagsIncludeBrandsFilter={setTagsIncludeBrandsFilter}
            setTagsExcludeBrandsFilter={setTagsExcludeBrandsFilter}
            setTags={setTags}
            tags={tags}
            tagsIncludeBrands={tagsIncludeBrands}
            setTagsIncludeBrands={setTagsIncludeBrands}
            tagsExcludeBrands={tagsExcludeBrands}
            setTagsExcludeBrands={setTagsExcludeBrands}
            tagsCategories={tagsCategories}
            setTagsCategories={setTagsCategories}
            tagsRejectionReasons={tagsRejectionReasons}
            setTagsRejectionReasons={setTagsRejectionReasons}
            setUpdateStatusModalVisible={setUpdateStatusModalVisible}
            setProspectIdsToDelete={setProspectIdsToDelete}
            setShowDeleteModal={setShowDeleteModal}
            setShowRefreshModal={setShowRefreshModal}
            getUrlSearch={getUrlSearch}
            searchValue={searchValue}
            setUploadCsvModalVisible={setUploadCsvModalVisible}
            setShowManageTagsModal={setShowManageTagsModal}
            selectedProspectIds={selectedProspectIds}
            setBrandSafetySearchTermsModalVisible={setBrandSafetySearchTermsModalVisible}
            setShowAssignAnalystModal={setShowAssignAnalystModal}
            setShowAssignProgramModal={setShowAssignProgramModal}
            setShowAssignModal={setShowAssignModal}
            setAssignModalParams={setAssignModalParams}
            setTagsAnalystsFilter={setTagsAnalystsFilter}
            setAnalysts={setAnalysts}
            analysts={analysts}
            analystsSuggestions={analystsSuggestions}
            prospectCreatorsSuggestions={adminUserSuggestions}
            setTagsCategoriesFilter={setTagsCategoriesFilter}
            categoriesSuggestions={categorySuggestions}
            setTagsRejectionReasonsFilter={setTagsRejectionReasonsFilter}
            rejectionReasonsSuggestions={rejectionReasonSuggestions}
            setTagsProspectCreatorsFilter={setTagsProspectCreatorsFilter}
            setProspectCreators={setProspectCreators}
            prospectCreators={prospectCreators}
            prospectOwnerSuggestions={prospectOwnerSuggestions}
            categories={categories}
            rejectionReasons={rejectionReasons}
            setTagsProspectOwnersFilter={setTagsProspectOwnersFilter}
            setProspectOwners={setProspectOwners}
            prospectOwners={prospectOwners}
            prospectGroupUploadLog={prospectGroupUploadLog}
            setProspectGroupUploadLog={setProspectGroupUploadLog}
            createDateFrom={createDateFrom}
            setCreateDateFrom={setCreateDateFrom}
            createDateTo={createDateTo}
            setCreateDateTo={setCreateDateTo}
            disableBlacklistActions={disableBlacklistActions}
            setShowCloneModal={setShowCloneModal}
            setShowAllTagsModal={setShowAllTagsModal}
            countrySuggestions={countrySuggestions}
            tagsCountries={tagsCountries}
            setTagsCountries={setTagsCountries}
            setTagsCountriesFilter={setTagsCountriesFilter}
          />
          <Row className="">
            {loadingFilters && (<div className="w-100 text-center"><Spinner color="primary" size="lg" /></div>)}
            {loadingFilters === false && (
              <Table
                handleSelectCheckbox={handleSelectCheckbox}
                action={action}
                resource={resource}
                brandSafetySearchTerms={brandSafetySearchTerms}
                setSingleSelectedProspectId={setSingleSelectedProspectId}
                setSingleSelectedProspectPodcastId={setSingleSelectedProspectPodcastId}
                setSingleSelectedProspectCurrentEmail={setSingleSelectedProspectCurrentEmail}
                setOpenRelatedPodcastModal={setOpenRelatedPodcastModal}
                dispatch={dispatch}
                addNotice={addNotice}
                brands={brands}
                setRefreshStatisticsOptionsModalVisible={setRefreshStatisticsOptionsModalVisible}
                setProspectIdsToDelete={setProspectIdsToDelete}
                setShowDeleteModal={setShowDeleteModal}
                goToEditProspectPage={goToEditProspectPage}
                version={version}
                setVersion={setVersion}
                setDisableNote={setDisableNote}
                setCurrentProspect={setCurrentProspect}
                setShowNotesModal={setShowNotesModal}
                setPortal={setPortal}
                analysts={adminUsers}
                setMetaTotal={setMetaTotal}
                showMissingDataModal={showMissingDataModal}
                setShowDuplicateTargetBrandModal={setShowDuplicateTargetBrandModal}
                setNewBrand={setNewBrand}
                setShowCloneModal={setShowCloneModal}
                setShowDetailsModal={setShowDetailsModal}
                setShowHistoryModal={setShowHistoryModal}
                setOpenTabs={setOpenTabs}
                setActionBlackListModal={setActionBlackListModal}
                setShowBlackListModal={setShowBlackListModal}
                recordsRef={recordsRef}
                categories={categories}
                rejectionReasons={rejectionReasons}
                countryOptions={countryTableOptions}
              />
            )}
          </Row>
        </CardBody>
      </Card>
      <UploadProspectsModal
        title="Upload Prospects"
        modal={uploadCsvModalVisible}
        setModalVisible={closeCsvUploadModal}
        importRecords={importRecords}
        loading={loadingUploadProspects}
        uploadResults={uploadProspectsResults}
        errorMessage={uploadProspectsError}
        analysts={adminUsers}
        currentAdminUser={currentAdminUser}
      />
      <BrandSafetySearchTermsModal
        isOpen={brandSafetySearchTermsModalVisible}
        toggleModal={() => setBrandSafetySearchTermsModalVisible(false)}
      />
      <UpdateStatusOfSelectedProspectsModal
        isOpen={updateStatusModalVisible}
        toggleModal={() => setUpdateStatusModalVisible(false)}
        loading={updatingStatusInProgress}
        onSaveFunc={updateSelectedProspectsStatuses}
      />
      <RefreshStatisticsOptionsModal
        isOpen={refreshStatisticsOptionsModalVisible}
        toggleModal={() => {
          setSingleSelectedProspectId('');
          setRefreshStatisticsOptionsModalVisible(false);
        }}
        prospectId={singleSelectedProspectId}
        onRefreshFunc={(prospectId, overwriteChannelNameAndURL) => {
          prospectContainerRefresh(prospectId, overwriteChannelNameAndURL);
          setSingleSelectedProspectId('');
          setRefreshStatisticsOptionsModalVisible(false);
        }}
      />
      <RefreshModal
        prospectsIds={selectedProspectIds}
        isOpen={showRefreshModal}
        closeFunc={() => setShowRefreshModal(false)}
        setVersion={setVersion}
      />
      <DeleteProspectModal
        isOpen={showDeleteModal}
        closeFunc={() => setShowDeleteModal(false)}
        setVersion={setVersion}
        prospectIds={prospectIdsToDelete}
      />
      <ManageTagsModal
        isOpen={showManageTagsModal}
        closeFunc={() => setShowManageTagsModal(false)}
        setVersion={setVersion}
        prospectIds={selectedProspectIds}
        total={metaTotal}
      />
      <RelatedPodcastsModal
        isOpen={!!openRelatedPodcastModal.isOpen}
        creatorId={singleSelectedProspectId}
        assignedPodcastId={singleSelectedProspectPodcastId}
        currentEmail={singleSelectedProspectCurrentEmail}
        title={openRelatedPodcastModal.title}
        toggleModal={() => {
          setSingleSelectedProspectId('');
          setSingleSelectedProspectPodcastId('');
          setSingleSelectedProspectCurrentEmail('');
          setOpenRelatedPodcastModal({ isOpen: false, title: '' });
        }}
        mode="prospect"
        onAssignFunc={assignPodcastToProspect}
      />
      <NotesModal
        isOpen={showNotesModal}
        closeFunc={() => setShowNotesModal(false)}
        setVersion={setVersion}
        prospect={currentProspect}
        disabled={disableNote}
        portal={portal}
      />
      <AssignAnalystModal
        isOpen={showAssignAnalystModal}
        closeFunc={() => setShowAssignAnalystModal(false)}
        setVersion={setVersion}
        prospectIds={selectedProspectIds}
        analysts={adminUsers}
        total={metaTotal}
      />
      <AssignProgramModal
        isOpen={showAssignProgramModal}
        closeFunc={() => { setShowAssignProgramModal(false); }}
        setVersion={setVersion}
        prospectIds={selectedProspectIds}
        total={metaTotal}
      />
      <AssignModal
        isOpen={showAssignModal}
        params={assignModalParams}
        collection={{ name: 'prospects', label: 'Prospect' }}
        closeFunc={() => { setShowAssignModal(false); }}
        setVersion={setVersion}
        ids={selectedProspectIds}
        total={metaTotal}
      />
      <MissingInfoModal
        isOpen={showMissingInfoModal}
        closeFunc={() => { setShowMissingInfoModal(false); }}
        setVersion={setVersion}
        prospect={missingInfoProspect}
      />
      <ModalInfoBase
        isOpen={showDuplicateTargetBrandModal}
        closeFunc={() => { setShowDuplicateTargetBrandModal(false); }}
        title="Invalid Target Brand"
        bodyText={[
          { text: 'There already exists a prospect named ' },
          { text: currentProspect.params?.title ? currentProspect.params.title : `${currentProspect.params?.firstname} ${currentProspect.params?.lastname}`, style: { fontWeight: 'bold' } },
          { text: ' with a Target Brand set to ' },
          { text: `${newBrand}.`, style: { fontWeight: 'bold' } },
          { text: 'Target Brands must be unique when multiple instances of the same prospect exist.', newLine: true },
        ]}
      />
      <CloneProspectModal
        closeFunc={() => { setShowCloneModal(false); setCurrentProspect({}); }}
        isOpen={showCloneModal}
        analysts={adminUsers}
        prospect={currentProspect}
        addNotice={addNotice}
        setVersion={setVersion}
        prospectIds={selectedProspectIds}
        total={metaTotal}
      />
      <DetailsModal record={currentProspect} setModalVisible={setShowDetailsModal} modal={showDetailsModal} />
      <StatusHistoryModal record={currentProspect} modal={showHistoryModal} setModalVisible={setShowHistoryModal} editableDates />
      <TabsOrModal record={currentProspect} searchTerms={brandSafetySearchTerms} openTabs={openTabs} setOpenTabs={setOpenTabs} />
      <AddOrRemoveBlackListModal
        record={currentProspect}
        isOpen={showBlackListModal}
        closeFunc={() => { setShowBlackListModal(false); }}
        action={actionBlackListModal}
        setVersion={setVersion}
        brands={brands}
      />
      <AllTagsModal
        isOpen={showAllTagsModal}
        closeFunc={() => { setShowAllTagsModal(false); }}
        allTags={tagsSuggestions.map(item => ({ value: item.id, label: item.name }))}
        setTags={setTags}
        setTagsFilter={setTagsFilter}
        tags={tags}
      />
      <ShowRejectedApprovedBrands />
    </Container>
  );
};
ProspectsListContainer.propTypes = {
  addNotice: PropTypes.func.isRequired,
  loadingUploadProspects: PropTypes.bool.isRequired,
  uploadProspectsResults: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
  brandSafetySearchTerms: PropTypes.arrayOf(PropTypes.string).isRequired,
  uploadProspectsError: PropTypes.string.isRequired,
  adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  currentAdminUser: PropTypes.objectOf(PropTypes.any).isRequired,
};
const mapStateToProps = state => ({
  loadingUploadProspects: state.ResearchReducer.loading.uploadProspects,
  uploadProspectsResults: state.ResearchReducer.uploadProspectsResults,
  uploadProspectsError: state.ResearchReducer.uploadProspectsError,
  brandSafetySearchTerms: state.ResearchReducer.brandSafetySearchTerms,
  adminUsers: state.ResearchReducer.adminUsers,
  currentAdminUser: state.session,
});

export default withNotice(connect(mapStateToProps)(withRouter(ProspectsListContainer)));
