/* eslint react/prop-types: 0 */
import React, { useMemo, useState, useEffect } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { checkImagePromise } from '../../../../../shared/helpers/WVUtilities';
import CopyToClipboard from '../../../../../shared/components/CopyToClipboard';


export default React.memo(({ record, prospects }) => {
  let prospect = null;
  if (!prospect) prospect = prospects && prospects.length > 0 && prospects ? prospects[0] : null;
  const [image, setImage] = useState('/images/user/defaultAvatar.png');
  const recordY = prospect?.tubularDetails?.accounts.find(a => a.platform === 'youtube');
  const recordI = prospect?.tubularDetails?.accounts.find(a => a.platform === 'instagram');
  const recordF = prospect?.tubularDetails?.accounts.find(a => a.platform === 'facebook');
  const recordT = prospect?.tubularDetails?.accounts.find(a => a.platform === 'twitter');
  const title = prospect?.title || '';

  useEffect(async () => {
    setImage('/images/user/defaultAvatar.png');
    if (recordY && await checkImagePromise(recordY.thumbnailUrl)) {
      console.log('youtube Image');
      setImage(recordY.thumbnailUrl);
    } else if (recordI && await checkImagePromise(recordI.thumbnailUrl)) {
      console.log('Instagram Image');
      setImage(recordI.thumbnailUrl);
    } else if (recordF && await checkImagePromise(recordF.thumbnailUrl)) {
      console.log('Facebook Image');
      setImage(recordF.thumbnailUrl);
    } else if (recordT && await checkImagePromise(recordT.thumbnailUrl)) {
      console.log('X Image');
      setImage(recordT.thumbnailUrl);
    }
  }, [record?._id]);

  const name = `${record?.first_name} ${record?.last_name}`;
  const { mediaChannels: hsMedia } = record || {};
  const mediaChannels = useMemo(() => {
    if (!record) return false;
    const returnObj = [];
    const m = hsMedia || {};
    const getoObj = t => (
      <a
        href={t.url}
        target="_blank"
        rel="noreferrer"
        key={t.platform}
        style={{ marginRight: '4px' }}
      >
        <img
          src={`/images/mediacontent/${t.platform}.png`}
          alt={t.platform}
          title={t.title}
          style={{ width: '20px' }}
        />
      </a>);
    // eslint-disable-next-line no-useless-escape
    const addhttp = url => `${!/^(?:f|ht)tps?:\/\//.test(url) ? 'https://' : ''}${url}`;

    if (m.youtube_channel_url) {
      const url = addhttp(m.youtube_channel_url);
      returnObj.push(getoObj({ platform: 'youtube', url, title: 'youtube' }));
    }
    if (m.facebook_channel_url) {
      const url = addhttp(m.facebook_channel_url);
      returnObj.push(getoObj({ platform: 'facebook', url, title: 'facebook' }));
    }
    if (m.instagram_channel) {
      const url = addhttp(m.instagram_channel);
      returnObj.push(getoObj({ platform: 'instagram', url, title: 'instagram' }));
    }
    return returnObj;
  }, [record]);
  if (!record) return null;
  return (
    <div
      className="d-flex"
    >
      <Link
        to={`/influencer/profile/${record._id}`}
        rel="noreferrer"
        target="_blank"
      >
        <img
          src={image}
          alt={name}
          style={{ width: '50px', height: 'auto' }}
          className="align-self-center"
        />
      </Link>
      <div
        className="align-self-center flex-column"
        style={{ marginLeft: '8px' }}
      >
        <div
          className=""
        >
          <Link
            className={title ? '' : 'bold-text'}
            to={`/influencer/profile/${record._id}`}
            rel="noreferrer"
            target="_blank"
          >
            {name}
          </Link>
          {record.hsContactVid ? (
            <a href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${record.hsContactVid}/`} target="_blank" rel="noreferrer">
              <Tooltip title="Open Hubspot Contact">
                <IconButton size="small">
                  <i className="fab fa-hubspot text-primary" style={{ fontSize: '0.8em' }} />
                </IconButton>
              </Tooltip>
            </a>) : null}
        </div>
        <div
          className=""
        >
          <Link
            className={title ? '' : 'bold-text'}
            to={`/influencer/profile/${record._id}`}
            rel="noreferrer"
            target="_blank"
          >
            <span
              className="bold-text"
            >
              {record.affiliateDataObject?.company || ''}
            </span>
          </Link>
        </div>
        <CopyToClipboard
          value={record.email}
        >
          {record.email.length > 25 ? `${record.email.slice(0, 25)}...` : record.email}
        </CopyToClipboard>
        {prospect ? (
          <div className="" >
            {prospect.tubularDetails.accounts.map(t => (
              <a
                href={t.url}
                target="_blank"
                rel="noreferrer"
                key={t.platform}
                style={{ marginRight: '4px' }}
              >
                <img
                  src={`/images/mediacontent/${t.platform}.png`}
                  alt={t.platform}
                  title={t.title}
                  style={{ width: '20px' }}
                />
              </a>))}
            {prospect.listenNotesDetails?.url && (
              <a
                href={prospect.listenNotesDetails.url}
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: '4px' }}
              >
                <img
                  src="/images/mediacontent/podcast_128.png"
                  alt="podcast"
                  title={prospect.listenNotesDetails.title}
                  style={{ width: '20px' }}
                />
              </a>
            )}
          </div>) : (<div className="" >{mediaChannels}</div>)}
      </div>
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));
