import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import SortIcon from 'mdi-react/SortIcon';
import SortAscendingIcon from 'mdi-react/SortAscendingIcon';
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';

const Header = ({ column, isSortable, onClick }) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
  <span
    className="react-table__column-header"
    onClick={onClick}
  >
    <span id="selectAllBox" className={isSortable ? 'react-table__column-header sortable' : ''}>
      {column.render('Header')}
    </span>
    {isSortable && column.canSort && <Sorting column={column} />}
  </span>
);

Header.propTypes = {
  column: PropTypes.shape({
    Header: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.node,
    ]),
    disableGlobalFilter: PropTypes.bool,
    accessor: PropTypes.func,
    render: PropTypes.func,
    canSort: PropTypes.bool,
  }).isRequired,
  isSortable: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  onClick: PropTypes.func,
};

const Sorting = ({ column }) => (
  <span className="react-table__column-header sortable">
    {column.isSortedDesc === undefined ? (
      <SortIcon />
    ) : (
      <span>
        {column.isSortedDesc
          ? <SortDescendingIcon />
          : <SortAscendingIcon />}
      </span>
    )}
  </span>
);

Sorting.propTypes = {
  column: PropTypes.shape({
    Header: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.node,
    ]),
    disableGlobalFilter: PropTypes.bool,
    accessor: PropTypes.func,
    isSorted: PropTypes.bool,
    isSortedDesc: PropTypes.bool,
  }).isRequired,
};

const getStylesResizable = (props, align = 'left', column) => [
  props,
  {
    style: {
      justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'flex-start',
      display: 'flex',
    },
  },
];

const ReactTableHeader = ({ headerGroups, isResizable, isSortable, isFreeze, tableRef }) => {
  const headerPropsSortable = (props, { column }) => {
    if (column.getSortByToggleProps && isSortable) {
      return column.getSortByToggleProps;
    }
    return { column };
  };
  const headerPropsResize = (props, { column }) => {
    if (column.getResizerProps) {
      return getStylesResizable(props, column.align, column);
    }
    return { column };
  };
  const thead = useRef();
    useEffect(() => {
      console.log('isFreeze', isFreeze)
      if(!isFreeze) return false;
      if(thead.current) {
        const rowHeight = thead.current.getBoundingClientRect();
        tableRef.current.querySelector('.freeze-header').style.height =  `${rowHeight.height}px`;
      }
      const handleScroll = () => {
        
        // console.log(rowHeight, 'rowHeight');
        
        const frozenRow = thead.current;
        // const offsetTop = tableRef.current.offsetTop || 0;
        let rect = tableRef.current.getBoundingClientRect();
        // console.log(topScroll, rect)
        // console.log(rect.y * (-1), 'TOPTPT')
        if (frozenRow  && rect.y <= 60) {
          //mconsole.log(rect.y * (-1), 'TOPTPT')
          frozenRow.style.top = `${(rect.y * (-1)) + 60}px`;
          frozenRow.style.position = `absolute`;
          frozenRow.style.width = `100%`;
          frozenRow.style.left = `0px`;
          frozenRow.style.zIndex = `100000`;

          /* frozenRow.style.top = `60px`;
          frozenRow.style.position = `fixed`;
          frozenRow.style.left = `${rect.x}px`*/
        }
        else {
          frozenRow.style.position = `relative`;
          frozenRow.style.top = `0px`;
          frozenRow.style.zIndex = '0'
          frozenRow.style.left = `0px`
        }
      };
      const table = document;
      table.addEventListener('scroll', handleScroll);
      table.addEventListener('resize', handleScroll);
      return () => {
        table.removeEventListener('scroll', handleScroll);
        table.removeEventListener('resize', handleScroll);
      };
    }, [thead.current]);

  return (
    <thead className="thead th freeze-header"  >
      {headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()} className="react-table thead tr"  ref={thead}>
          {headerGroup.headers.map((column) => {
            const propsSortable = _.omit(column.getHeaderProps(headerPropsSortable), 'onClick');
            const { onClick } = column.getHeaderProps(headerPropsSortable);
            return (
              <th
                {...propsSortable}
                {...column.getHeaderProps(headerPropsResize)}
                {...column.getHeaderProps({...column.isFreeze ? {style: { position: 'sticky', left: column.left || 0, zIndex: 1, backgroundColor: column.backgroundColor || '#fff' }} : {}})}
                
                className={column.className}
                title=""
                
              >
                <Header
                  column={column}
                  isSortable={isSortable}
                  onClick={onClick}
                />
                {isResizable && column.id !== 'select' && (
                  <div {...column.getResizerProps()} className={`resizer ${column.isResizing && 'isResizing'}`} />
                )}
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
};

ReactTableHeader.propTypes = {
  headerGroups: PropTypes.arrayOf(PropTypes.shape({
    headers: PropTypes.arrayOf(PropTypes.shape()),
    getHeaderGroupProps: PropTypes.func,
    getFooterGroupProps: PropTypes.func,
  })).isRequired,
  isResizable: PropTypes.bool.isRequired,
  isSortable: PropTypes.bool.isRequired,
  isFreeze: PropTypes.bool,
};

ReactTableHeader.defaultProps = {
  isFreeze: false,
};

export default ReactTableHeader;
