import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { CKEditor } from 'ckeditor4-react';
import {
  Container, Row, Col,
  Card, CardTitle, CardBody,
  FormGroup, Label, Input, Spinner,
  DropdownToggle, DropdownMenu, DropdownItem, UncontrolledButtonDropdown,
} from 'reactstrap';
import ApiClient from 'ApiClient';
import SelectAsync from 'react-select/async';
import PropTypes from 'prop-types';
import moment from 'moment';
import StyledButton from '../../../../shared/components/components/ui/styled-button';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import {
  fetchMediaContent,
  saveMediaContent,
  fetchMediaContentThumbnail,
  fetchMediaContentDuplicate,
} from '../../../../redux/reducers/admin/ScoringReducer';
import { addNotice } from '../../../App/store/store';
import WVValidator from '../../../../shared/helpers/WVValidator';
import WVFormatter from '../../../../shared/helpers/WVFormatter';
import Alert from '../../../../shared/easydev2/shared/components/Alert';
import { getAdminUsers } from '../../../../redux/reducers/admin/ResearchReducer';

const mapTypeArray = {
  YouTube: 'YouTube',
  'YouTube Description Text': 'YouTube Description Text',
  Podcast: 'Podcast',
  'Podcast Script Text': 'Podcast Script Text',
  'Instagram Story': 'Instagram Story',
  'Instagram Post': 'Instagram Post',
  'Instagram TV Video': 'Instagram TV Video',
  TikTok: 'TikTok',
  'Facebook Post': 'Facebook Post',
  'Facebook Watch Video': 'Facebook Watch Video',
  'Twitter Post': 'X Post',
  LinkTree: 'LinkTree',
  Website: 'Website',
  Email: 'Email',
  Text: 'Text',
};

const mapType = Object.keys(mapTypeArray).map(key => ({ value: key, label: mapTypeArray[key] }));
const styles = {
  label: {
    maxWidth: '150px',
    whiteSpace: 'nowrap',
  },
};

class MediaContentNew extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiClient();
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeBrand = this.handleChangeBrand.bind(this);
    this.handleChangeInfluencer = this.handleChangeInfluencer.bind(this);
    this.handleChangeDeal = this.handleChangeDeal.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    this.handleChangeNotes = this.handleChangeNotes.bind(this);
    this.handleChangeTranscript = this.handleChangeTranscript.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.loadInfluencersOptions = this.loadInfluencersOptions.bind(this);
    this.loadBrandOptions = this.loadBrandOptions.bind(this);
    this.loadTranscription = this.loadTranscription.bind(this);
    this.loadDealOptions = this.loadDealOptions.bind(this);
    this.updateRequiredFields = this.updateRequiredFields.bind(this);
    this.handleUrlInputBlur = this.handleUrlInputBlur.bind(this);
    this.updateTranscriptionText = this.updateTranscriptionText.bind(this);
    this.updateNotesText = this.updateNotesText.bind(this);
    this.handleChangeAM = this.handleChangeAM.bind(this);
    this.selected = null;
    this.state = {
      edit: false,
      selectedBrand: null,
      selectedInfluencer: null,
      selectedAccountManager: null,
      defaultInfluencerOptions: true,
      selectedDeal: null,
      defaultDealOptions: true,
      selectedType: null,
      contentTextLabel: 'Content Text',
      record: {
        params: {},
        errors: {},
        populated: {},
      },
      allBrands: [],
      stayOnPage: false,
      leaveData: false,
      notesEditor: Math.random().toString(),
      transcriptEditor: Math.random().toString(),
      transcriptionLoaded: false,
      hsInBlacklist: false,
    };
  }

  async loadTranscription(id) {
    const api = new ApiClient();
    const response = await api.client.get(`/api/portal/scorecard/media-content/get-transcription?mediaContentId=${id}`);
    this.setState(state => ({
      record: {
        ...state.record,
        params: {
          ...state.record.params,
          transcriptionText: response.data.transcription || '',
        },
      },
      transcriptionLoaded: true,
    }));
  }

  componentDidMount() {
    const { match, dispatch, adminUsers } = this.props;
    if (adminUsers.length < 1) {
      dispatch(getAdminUsers(false));
    }
    if (match && match.params && match.params.mediaContentId) {
      dispatch(fetchMediaContent(match.params.mediaContentId)).then(() => {
        this.fillData();
        this.loadTranscription(match.params.mediaContentId);
      });
    } else {
      this.setState(state => ({ transcriptionLoaded: true }));
    }
    setTimeout(() => {
      const { record } = this.state;
      if (typeof record.params !== 'undefined' && typeof record.params.mediaUrl !== 'undefined' && record.params.mediaUrl !== null) {
        this.handleUrlInputBlur({ target: { value: record.params.mediaUrl } });
      }
    }, 1500);
  }

  componentDidUpdate(prevProps) {
    const { newMediaContentData } = this.props;
    if (newMediaContentData && newMediaContentData !== prevProps.newMediaContentData) {
      this.handleChange('thumbnail', newMediaContentData.thumbnail);
      this.handleChange('title', newMediaContentData.title);
      this.handleChange('contentText', newMediaContentData.contentText);
    }
  }
  notesRef = React.createRef();
  transcriptRef = React.createRef();
  static requiredData = [
    'advertiser', 'influencer', 'type', 'deal', 'mediaUrl', 'tikTokProfileUrl',
  ];
  fillData() {
    const { mediaContent } = this.props;
    const { data } = mediaContent;
    this.setState(state => ({
      record: {
        ...state.record,
        params: {
          ...state.record.params,
          advertiser: data.advertiser,
          id: data._id,
          influencer: data.influencer,
          accountManagerId: data.accountManagerId || null,
          deal: typeof data.deal !== 'undefined' && data.deal !== null ? data.deal._id : null,
          contentName: data.name,
          mediaUrl: data.mediaUrl,
          tikTokProfileUrl: data.tikTokProfileUrl,
          notes: data.notes,
          transcriptionText: data.transcriptionText,
          type: data.type,
          contentText: data.contentText,
          thumbnail: data.thumbnail,
          title: data.title,
          status: data.status,
        },
        errors: {
          ...state.record.errors,
        },
      },
    }));
    let selectedAM = null;
    if (this.props.adminUsers.length && data.accountManagerId) {
      selectedAM = this.props.adminUsers.filter(adminUser => (adminUser.value && adminUser.affiliateManagerId)).find(am => data.accountManagerId.toString() === am.value.toString());
    }
    this.setState({
      selectedBrand: {
        _id: data.advertiser._id,
        companyName: data.advertiser.companyName,
      },
      selectedInfluencer: {
        id: data.influencer._id,
        email: data.influencer.email,
      },
      selectedDeal: typeof data.deal !== 'undefined' && data.deal !== null ? {
        // _id: data.deal._id,
        // dealname: data.deal.dealname,
        ...data.deal,
      } : null,
      selectedType: {
        value: data.type,
        label: data.type,
      },
      selectedAccountManager: selectedAM,
      edit: true,
    });
    this.handleChangeType({ value: data.type, label: data.type });
    setTimeout(() => {
      if (window.location.hash === '#notes') {
        this.notesRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      if (window.location.hash === '#transcriptionText') {
        this.transcriptRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 1000);
  }

  async loadBrandOptions(inputValue) {
    const api = new ApiClient();
    const response = await api.client.get(`/api/portal/scorecard/media-content/get-brands-data?term=${inputValue}`);

    const brands = response.data.brands.filter(b => b.displayInUi === 'Yes').map(record => ({
      value: record._id,
      label: record.companyName,
      record,
    }));
    this.setState({ allBrands: brands });
    return brands;
  }

  async loadInfluencersOptions(inputValue) {
    const api = new ApiClient();
    const { selectedDeal } = this.state;
    const id = selectedDeal?.ilDealData?.influencer || '';

    const response = await api.client.get(`/api/portal/scorecard/media-content/get-creator-data?id=${id}&term=${encodeURIComponent(inputValue)}`);
    return response.data.creators.map(creator => ({
      value: creator._id,
      label: creator.email,
      record: creator,
    }));
  }

  async loadDealOptions(inputValue) {
    const api = new ApiClient();
    const { selectedInfluencer } = this.state;
    const id = selectedInfluencer ? selectedInfluencer.id : '';
    const response = await api.client.get(`/api/portal/scorecard/media-content/get-deal-data?id=${id}&term=${inputValue}&destination=content`);
    return response.data.deals.map(deal => ({
      value: deal._id,
      label: `${deal.dealname} (${WVFormatter.getFormatedDate(deal.hs_createdate)})`,
      deal,
    }));
  }

  clearCustomFieldError(propertiesArray) {
    const fields = {};
    propertiesArray.map((field) => {
      fields[field] = undefined;
      return true;
    });
    this.setState(state => ({
      record: {
        ...state.record,
        params: {
          ...state.record.params,
        },
        errors: {
          ...state.record.errors,
          ...fields,
        },
      },
    }));
  }

  handleChange(propertyOrRecord, value, props) {
    if (typeof value === 'undefined' && (propertyOrRecord).params) {
      this.setState({
        record: propertyOrRecord,
      });
    } else {
      this.setState((state) => {
        const params = {
          ...state.record.params,
          [propertyOrRecord]: value,
        };
        if (props) {
          Object.keys(props).forEach((key) => {
            params[key] = props[key];
          });
        }
        return {
          record: {
            ...state.record,
            params,
            errors: {
              ...state.record.errors,
              [propertyOrRecord]: undefined,
            },
          },
        };
      });
    }
  }
  handleInputChange(name, event) {
    this.handleChange(name, event.target.value);
    if (name === 'mediaUrl' && !event.target.value && this.state.selectedType.value === 'YouTube') {
      // console.log(event.target.value);
      this.setState(state => ({
        record: {
          ...state.record,
          params: {
            ...state.record.params,
            title: null,
            thumbnail: null,
            contentText: null,
            notFound: false,
          },
        },
      }));
    }
  }
  handleUrlInputBlur(event) {
    const { dispatch, match } = this.props;
    const id = match?.params?.mediaContentId || '';
    let finalUrl = event.target.value

    if (event.target.value !== '' && (this.state.selectedType.value === 'YouTube' || this.state.selectedType.value === 'YouTube Description Text')) {
      // https://www.youtube.com/live/-WiNqCvvQSw?si=-BPuF-WQG9mYOQS1&t=792
      // https://youtube.com/watch?v=-WiNqCvvQSw&t=792s
      if (event.target.value && event.target.value.includes('/live/')) {
        const urlArr = event.target.value.split('?');
        if (urlArr.length > 0) {
          finalUrl = urlArr[0].replace('/live/', '/watch?v=');
        }
        if (event.target.value.includes('&t=')) {
          const timeRange = event.target.value.split('&t=');
          finalUrl = finalUrl.concat(`&t=${timeRange[1]}`);
        } else if (event.target.value.includes('?t=')) {
          const timeRange = event.target.value.split('?t=');
          finalUrl = finalUrl.concat(`&t=${timeRange[1]}`);
        }
        this.handleChange('mediaUrl', finalUrl);
      }

      dispatch(fetchMediaContentThumbnail('youtube', finalUrl)).then(() => {
        const { newMediaContentData } = this.props;
        this.setState(state => ({
          record: {
            ...state.record,
            params: {
              ...state.record.params,
              ...newMediaContentData,
            },
          },
        }));
      });
    }
    dispatch(fetchMediaContentDuplicate(id, finalUrl, this.state.selectedType.value));
  }
  handleChangeBrand(selected) {
    if (selected) {
      this.setState({
        selectedBrand: selected.record,
      });
      this.handleChange('advertiser', selected.record._id);
    } else {
      this.setState({
        selectedBrand: null,
      });
      this.handleChange('advertiser', null);
    }
  }
  handleChangeInfluencer(selected) {
    if (selected) {
      this.setState({
        selectedInfluencer: selected.record,
      });
      this.handleChange('influencer', selected.record._id);
    } else {
      this.setState({
        selectedInfluencer: null,
        // defaultDealOptions: true,
        // selectedDeal: null,
      });
      this.handleChange('influencer', null);
      this.handleChange('contentName', null);
    }
  }
  handleChangeDeal(selected) {
    if (selected) {
      // console.log(selected.deal);
      this.setState({
        selectedDeal: selected.deal,
      }, () => {
        if (selected.deal.contact?.blacklist_) {
          this.setState({ hsInBlacklist: true });
        } else {
          this.setState({ hsInBlacklist: false });
        }
        // pre-fill Creator field
        this.loadInfluencersOptions('').then((response) => {
          this.setState({ defaultInfluencerOptions: response });
          // console.log(response);
          if (response.length === 1) {
            this.setState({ selectedInfluencer: response[0].record });
            this.handleChange('influencer', response[0].record._id);
            if (response[0].record.affiliateDataObject && response[0].record.affiliateDataObject.account_manager_id && this.props.adminUsers.length) {
              const accountManagerOptions = this.props.adminUsers.filter(adminUser => (adminUser.value && adminUser.affiliateManagerId));
              const selectedAccountManagerOption = accountManagerOptions.find(am => response[0].record.affiliateDataObject.account_manager_id.toString() === am.affiliateManagerId);
              this.setState({ selectedAccountManager: selectedAccountManagerOption || null});
              this.handleChange('accountManagerId', selectedAccountManagerOption?.value || null);
            }
          } else {
            this.setState({ selectedInfluencer: null });
            this.handleChange('influencer', null);
            this.setState({ selectedAccountManager: null});
            this.handleChange('accountManagerId', null);
          }
        });
        // pre-fill Brand field
        if (selected.deal?.dealTerms?.brand) {
          const currentBrand = this.state.allBrands.find(brand => brand.label.toLowerCase() === selected.deal.dealTerms.brand.toLowerCase());
          if (currentBrand?.record) {
            this.setState({ selectedBrand: currentBrand.record });
            this.handleChange('advertiser', currentBrand.record._id);
          }
        }
        // pre-fill Content Type field
        if (selected.deal?.dealTerms?.content_type) {
          const currentContentType = mapType.find(type => type.label.toLowerCase() === selected.deal.dealTerms.content_type.toLowerCase());
          if (currentContentType?.label) {
            this.setState({ selectedType: currentContentType });
            this.handleChangeType(currentContentType);
          }
        }
      });
      this.handleChange('deal', selected.deal._id, { tikTokProfileUrl: selected.deal.contact?.mediaChannels?.tiktok_channel_url });
      this.handleChange('contentName', selected.deal.dealname);
    } else {
      this.setState({
        selectedDeal: null,
        hsInBlacklist: false,
      });
      this.handleChange('deal', null);
      this.handleChange('contentName', null);
    }
  }

  handleChangeAM(selected) {
    if (selected) {
      this.setState({
        selectedAccountManager: selected,
      });
      this.handleChange('accountManagerId', selected.value);
    } else {
      this.setState({
        selectedAccountManager: null,
      });
      this.handleChange('accountManagerId', null);
    }
  }

  updateRequiredFields(fieldName, removeField = true) {
    const index = this.constructor.requiredData.indexOf(fieldName);
    if (removeField && index !== -1) {
      this.constructor.requiredData.splice(index, 1);
      // this.handleChange(fieldName, '');
    }
    if (!removeField && index === -1) {
      this.constructor.requiredData.push(fieldName);
    }
  }
  handleChangeType(selected) {
    const { dispatch, match } = this.props;
    const id = match?.params?.mediaContentId || '';
    const { record } = this.state;
    let contentLabelName = 'Content Text';
    switch (selected?.value) {
      case 'Text':
        this.updateRequiredFields('mediaUrl');
        this.updateRequiredFields('contentText', false);
        this.updateRequiredFields('tikTokProfileUrl');
        break;
      case 'YouTube Description Text':
        this.updateRequiredFields('mediaUrl');
        this.updateRequiredFields('contentText', false);
        this.updateRequiredFields('tikTokProfileUrl');
        contentLabelName = 'YouTube Description Text';
        break;
      case 'Podcast Script Text':
        this.updateRequiredFields('mediaUrl');
        this.updateRequiredFields('contentText', false);
        this.updateRequiredFields('tikTokProfileUrl');
        contentLabelName = 'Podcast Script Text';
        break;
      case 'Twitter Post':
        this.updateRequiredFields('mediaUrl');
        this.updateRequiredFields('contentText', false);
        this.updateRequiredFields('tikTokProfileUrl');
        contentLabelName = 'Tweet Text';
        break;
      case 'LinkTree':
        this.updateRequiredFields('mediaUrl');
        this.updateRequiredFields('contentText', false);
        this.updateRequiredFields('tikTokProfileUrl');
        contentLabelName = 'LinkTree Text';
        break;
      case 'TikTok':
        this.updateRequiredFields('tikTokProfileUrl', false);
        break;
      default:
        this.updateRequiredFields('mediaUrl', false);
        this.updateRequiredFields('contentText', true);
        this.updateRequiredFields('tikTokProfileUrl');
        break;
    }
    if (selected?.value !== 'YouTube') {
      this.setState(state => ({
        record: {
          ...state.record,
          params: {
            ...state.record.params,
            title: null,
            thumbnail: null,
            // mediaUrl: null,
          },
        },
      }));
    }
    this.setState({
      selectedType: selected,
      contentTextLabel: contentLabelName,
    });
    this.clearCustomFieldError(['contentText', 'mediaUrl']);
    dispatch(fetchMediaContentDuplicate(id, record?.params?.mediaUrl || '', selected.value));
    this.handleChange('type', selected?.value);
  }
  handleChangeNotes(event) {
    const { editor } = event;
    this.handleChange('notes', editor.getData());
  }
  handleChangeTranscript(event) {
    const { editor } = event;
    this.handleChange('transcriptionText', editor.getData());
  }

  updateTranscriptionText(editor) {
    setTimeout(() => {
      const { match } = this.props;
      const { record } = this.state;
      if (match && match.params && match.params.mediaContentId && record?.params?.transcriptionText) {
        editor.setData(record.params.transcriptionText);
      }
    }, 1000);
  }

  updateNotesText(editor) {
    setTimeout(() => {
      const { match } = this.props;
      const { record } = this.state;
      if (match && match.params && match.params.mediaContentId && record?.params?.notes) {
        editor.setData(record.params.notes);
      }
    }, 1000);
  }

  // handleChangePublishDate(value) {
  //   this.handleChange('publishDate', value);
  // }
  handleSubmit(event) {
    event?.preventDefault();
    const { record, edit } = this.state;
    const { dispatch } = this.props;
    let isValid = true;
    this.constructor.requiredData.forEach((item) => {
      if (!record.params[item]) {
        isValid = false;
        this.setState(state => ({
          record: {
            ...state.record,
            errors: {
              ...state.record.errors,
              [item]: 'This field is required',
            },
          },
        }));
      }
      if (item === 'mediaUrl' && !WVValidator.isUrl(record.params[item])) {
        this.setState(state => ({
          record: {
            ...state.record,
            errors: {
              ...state.record.errors,
              [item]: 'Please check your url format',
            },
          },
        }));
      }
    });
    if (!isValid) {
      dispatch(addNotice({
        message: 'Please Check form. Seems you did not fill some fields',
        type: 'warning',
      }));
      return true;
    }
    const params = Object.assign({}, record.params);
    // console.log('params', params);

    if (!edit) {
      params.submitterAdminUserId = this.props.adminUser._id;
    }
    dispatch(saveMediaContent(params)).then((response) => {
      if (response.success) {
        const oldEdit = this.state.edit;
        const startDate = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
        const endDate = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
        if (!this.state.leaveData) {
          this.setState({
            selectedBrand: null,
            selectedInfluencer: null,
            selectedDeal: null,
            selectedType: null,
            selectedAccountManager: null,
            notesEditor: Math.random().toString(),
            transcriptEditor: Math.random().toString(),
            record: {
              params: {},
              errors: {},
              populated: {},
            },
            edit: false,
          }, () => {
            if (!this.state.stayOnPage) {
              setTimeout(() => {
                // eslint-disable-next-line max-len
                window.location.href = `/scorecard/content-dashboard?direction=desc&sortBy=createdAt&filters.createdAt.from=${startDate}&filters.createdAt.to=${endDate}&page=1&filters.scorecardsCount=0&filters.scorecardsCount=1&filters.scorecardsCount=2&filters.scorecardsCount=3&perPage=10&filters.dealType=CPA%2FAmbassador&filters.dealType=Upfront+Only&filters.dealType=Hybrid&filters.dealType=Upfront+CPM&filters.dealType=Agent+Commission&filters.dealType=Media+License`;
              }, 1500);
            }
            if (this.state.stayOnPage && oldEdit) {
              window.location.href = '/scorecard/media-content/new';
            }
          });
        }
      }
    });
    return false;
  }
  render() {
    const {
      record,
      selectedBrand,
      selectedInfluencer,
      selectedDeal,
      defaultDealOptions,
      defaultInfluencerOptions,
      selectedType,
      contentTextLabel,
      edit,
      transcriptEditor,
      notesEditor,
      hsInBlacklist,
      selectedAccountManager,
      transcriptionLoaded,
    } = this.state;
    // console.log('selectedType', selectedType);
    const {
      adminUser, loading, mediaContent, existContentWithUrl, adminUsers,
    } = this.props;
    let accountManagerOptions = [];
    if (adminUsers.length) {
      accountManagerOptions = adminUsers.filter(adminUser => (adminUser.value && adminUser.affiliateManagerId));
    }
    let selectedBrandOption = null;
    if (selectedBrand) {
      selectedBrandOption = {
        value: selectedBrand._id,
        label: selectedBrand.companyName,
      };
    }
    let selectedInfluencerOption = null;
    if (selectedInfluencer) {
      selectedInfluencerOption = {
        value: selectedInfluencer.id,
        label: selectedInfluencer.email,
      };
    }
    let selectedDealOption = null;
    let contentName = '';
    if (selectedDeal) {
      // console.log('!!!!selectedDeal:  ', selectedDeal);
      selectedDealOption = {
        value: selectedDeal._id,
        label: selectedDeal.dealname,
      };
      contentName = selectedDeal.dealname;
    }
    const submittedBy = edit && mediaContent?.data ?
      `${mediaContent.data.submitterAdminUserId?.firstName} ${mediaContent.data.submitterAdminUserId?.lastName}` :
      `${adminUser?.firstName} ${adminUser?.lastName}`;

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Breadcrumbs
              isBackButton
              links={[
                { title: 'Home', path: '/' },
                { title: 'Compliance', path: false },
                { title: 'Submit Content', path: false },
              ]}
            />
          </Col>
          <Col>
            <Card>
              <CardBody style={{ padding: '2rem' }}>
                <CardTitle>
                  <h3>Submit Content to Score</h3>
                </CardTitle>
                {loading.saveMediaContent &&
                (
                  <div className="text-center"><Spinner color="primary" size="lg" /></div>
                )
                }
                {!loading.saveMediaContent && (
                  <form onSubmit={this.handleSubmit}>
                    <FormGroup row>
                      <Label sm={2} style={styles.label}>Submitted by</Label>
                      <Col sm={10} style={{ marginTop: 6 }}><span>{`${submittedBy}`}</span></Col>
                    </FormGroup>
                    <Row>
                      <Col md={8} sm={12}>
                        <FormGroup>
                          <Label style={styles.label}>Deal</Label>
                          <SelectAsync
                            cacheOptions={false}
                            value={selectedDealOption}
                            defaultOptions={defaultDealOptions}
                            loadOptions={this.loadDealOptions}
                            onChange={this.handleChangeDeal}
                            placeholder=""
                            isClearable
                          />
                          {record.errors.deal && (<span className="text-danger">{record.errors.deal}</span>)}
                        </FormGroup>
                      </Col>
                      <Col md={4} sm={12}>
                        <FormGroup>
                          <Label style={styles.label}>Account Manager</Label>
                          <Select
                            value={selectedAccountManager}
                            options={accountManagerOptions}
                            onChange={this.handleChangeAM}
                            placeholder=""
                            isClearable
                            isDisabled={!selectedDealOption || hsInBlacklist}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {hsInBlacklist && (
                      <Alert color="warning" className="w-100 mt-3">
                        <p className="py-2 text-dark">
                          The contact of this deal is on the blacklist and blocked from submitting new media content. <a href="/influencers/contacts" target="_blank" rel="noopener">Manage Blacklist</a>
                        </p>
                      </Alert>
                    )}
                    <Row>
                      <Col md="3" sm="6" xs="12">
                        <FormGroup>
                          <Label>Creator</Label>
                          <SelectAsync
                            cacheOptions
                            value={selectedInfluencerOption}
                            defaultOptions={defaultInfluencerOptions}
                            loadOptions={this.loadInfluencersOptions}
                            onChange={this.handleChangeInfluencer}
                            placeholder=""
                            isClearable
                            isDisabled={!selectedDealOption || hsInBlacklist}
                          />
                          {record.errors.influencer && (<span className="text-danger">{record.errors.influencer}</span>)}
                        </FormGroup>
                      </Col>
                      <Col md="3" sm="6" xs="12">
                        <FormGroup>
                          <Label>Brand</Label>
                          <SelectAsync
                            cacheOptions
                            value={selectedBrandOption}
                            defaultOptions
                            loadOptions={this.loadBrandOptions}
                            onChange={this.handleChangeBrand}
                            placeholder=""
                            isClearable
                            isDisabled={!selectedDealOption || hsInBlacklist}
                          />
                          {selectedDeal && (
                            <div className="grey-small-text">{`Brand for this deal in HS is ${selectedDeal.dealTerms?.brand}`}</div>
                          )}
                          {record.errors.advertiser && (<span className="text-danger">{record.errors.advertiser}</span>)}
                        </FormGroup>
                      </Col>
                      <Col md="3" sm="6" xs="12">
                        <FormGroup>
                          <Label style={styles.label}>Content Type</Label>
                          <Select
                            value={selectedType}
                            options={mapType}
                            onChange={this.handleChangeType}
                            placeholder=""
                            isClearable
                            isDisabled={!selectedDealOption || hsInBlacklist}
                          />
                          {selectedDeal && (
                            <div className="grey-small-text">{`Content Type for this deal in HS is ${selectedDeal.dealTerms?.content_type || 'not set'}`}</div>
                          )}
                          {record.errors.type && (<span className="text-danger">{record.errors.type}</span>)}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={(selectedType?.value === 'TikTok') ? 4 : 6} sm={12} xs={12}>
                        <FormGroup>
                          <Label>Content Name</Label>
                          <Input
                            type="text"
                            id="contentName"
                            name="contentName"
                            onChange={e => this.handleInputChange('contentName', e)}
                            value={typeof record.params !== 'undefined' && typeof record.params.contentName !== 'undefined' && record.params.contentName !== null ? record.params.contentName : contentName}
                            disabled={!selectedDealOption || hsInBlacklist}
                          />
                          {record.errors.contentName && (<span className="text-danger">{record.errors.contentName}</span>)}
                        </FormGroup>
                      </Col>
                      <Col md={(selectedType?.value === 'TikTok') ? 4 : 6} sm={12} xs={12}>
                        <FormGroup>
                          <Label>Content URL</Label>
                          <Input
                            type="search"
                            id="mediaUrl"
                            name="mediaUrl"
                            onChange={e => this.handleInputChange('mediaUrl', e)}
                            onBlur={e => this.handleUrlInputBlur(e)}
                            value={typeof record.params !== 'undefined' && typeof record.params.mediaUrl !== 'undefined' && record.params.mediaUrl !== null ? record.params.mediaUrl : ''}
                            disabled={!selectedDealOption || hsInBlacklist}
                          />
                          {record.errors.mediaUrl && (<span className="text-danger">{record.errors.mediaUrl}</span>)}
                        </FormGroup>
                      </Col>
                      {(selectedType?.value === 'TikTok') && (
                      <Col md={(selectedType?.value === 'TikTok') ? 4 : 6} sm={12} xs={12}>
                        <FormGroup>
                          <Label>Profile URL</Label>
                          <Input
                            type="search"
                            id="tikTokProfileUrl"
                            name="tikTokProfileUrl"
                            onChange={e => this.handleInputChange('tikTokProfileUrl', e)}
                            // onBlur={e => this.handleUrlInputBlur(e)}
                            disabled={hsInBlacklist}
                            value={typeof record.params !== 'undefined' && typeof record.params.tikTokProfileUrl !== 'undefined' && record.params.tikTokProfileUrl !== null ? record.params.tikTokProfileUrl : ''}
                          />
                          {record.errors.tikTokProfileUrl && (<span className="text-danger">{record.errors.tikTokProfileUrl}</span>)}
                        </FormGroup>
                      </Col>
                      )}
                    </Row>
                    {existContentWithUrl && existContentWithUrl.deal && (
                      <Alert color="warning" className="w-100">
                        <p className="py-2 text-dark">
                          This content link is already associated with another piece of content. <a href={`/scorecard/content-dashboard?filters.deal=${existContentWithUrl?.deal}&range=all_time&filters.createdAt.from=2020-08-05`} target="_blank" rel="noreferrer">See other content</a>
                        </p>
                      </Alert>
                    )}
                    {record.params.notFound && (
                      <Alert color="warning" className="w-100">
                        <p className="py-2 text-dark">
                          Unable to get more information about the provided YouTube video. Please check if the video URL is valid and the privacy status should be set to either 'Public' or 'Unlisted'
                        </p>
                      </Alert>
                    )}
                    {(record.params.thumbnail) && (
                      <FormGroup row>
                        <Label sm={2} style={styles.label} />
                        <Col sm={10}>
                          <img style={{ width: 'auto', float: 'left', marginRight: '10px' }} src={record.params.thumbnail ? record.params.thumbnail : null} alt="" />
                          {record.params.title ? record.params.title : null}
                        </Col>
                      </FormGroup>
                    )}
                    <FormGroup>
                      <Label>{contentTextLabel}</Label>
                      <textarea
                        id="contentText"
                        name="contentText"
                        className="form-control"
                        onChange={e => this.handleInputChange('contentText', e)}
                        value={typeof record.params !== 'undefined' && typeof record.params.contentText !== 'undefined' && record.params.contentText !== null ? record.params.contentText : ''}
                        readOnly={!selectedDealOption}
                        disabled={hsInBlacklist}
                      />
                      {record.errors.contentText && (<span className="text-danger">{record.errors.contentText}</span>)}
                    </FormGroup>
                    <div ref={this.transcriptRef} />
                    <div ref={this.notesRef} />
                    <Row>
                      <Col md={6} sm={12} xs={12}>
                        {transcriptionLoaded && (
                          <FormGroup>
                            <Label>Transcript</Label>
                            <div key={transcriptEditor}>
                              <CKEditor
                                config={{versionCheck: false}}
                                id="bodyEditor1"
                                name="body1"
                                initData={record?.params?.transcriptionText || ''}
                                onChange={this.handleChangeTranscript}
                                onInstanceReady={({ editor }) => {
                                  this.updateTranscriptionText(editor);
                                }}
                                readOnly={!selectedDealOption || hsInBlacklist}
                              />
                            </div>
                          </FormGroup>
                        )}                        
                      </Col>
                      <Col md={6} sm={12} xs={12}>
                        <FormGroup>
                          <Label>Notes</Label>
                          <div key={notesEditor}>
                            <CKEditor
                              config={{versionCheck: false}}
                              id="bodyEditor"
                              name="body"
                              initData={typeof record.params !== 'undefined' && typeof record.params.notes !== 'undefined' && record.params.notes !== null ? record.params.notes : ''}
                              onChange={this.handleChangeNotes}
                              onInstanceReady={({ editor }) => {
                                this.updateNotesText(editor);
                              }}
                              readOnly={!selectedDealOption || hsInBlacklist}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup style={{ textAlign: 'right', marginBottom: 0 }}>
                          <StyledButton
                            type="submit"
                            className={`is-primary${loading.saveMediaContent ? ' is-loading' : ''}`}
                            style={{ marginTop: 20, width: '199px' }}
                            disabled={hsInBlacklist || existContentWithUrl}
                            onClick={() => { this.setState({ stayOnPage: false, leaveData: false }); }}
                          >
                            <i className="icomoon-save" />
                            <span className="btn-text">Save</span>
                          </StyledButton>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup style={{ marginBottom: 0 }}>
                          <UncontrolledButtonDropdown>
                            <DropdownToggle
                              id="saveMenu"
                              caret
                              color="primary"
                              style={{
                                width: '199px',
                                marginTop: 20,
                              }}
                            >
                              Save & Submit
                            </DropdownToggle>
                            <DropdownMenu right>
                              <DropdownItem disabled={hsInBlacklist || !!existContentWithUrl} onClick={() => { this.setState({ stayOnPage: true, leaveData: false }); this.handleSubmit(); }}>Save & Clear</DropdownItem>
                              <DropdownItem disabled={hsInBlacklist || !!existContentWithUrl} onClick={() => { this.setState({ stayOnPage: true, leaveData: true }); this.handleSubmit(); }}>Save & Keep</DropdownItem>
                            </DropdownMenu>
                          </UncontrolledButtonDropdown>
                        </FormGroup>
                      </Col>
                    </Row>
                  </form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

MediaContentNew.propTypes = {
  adminUser: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.objectOf(PropTypes.any).isRequired,
  mediaContent: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  newMediaContentData: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      thumbnail: PropTypes.string,
      title: PropTypes.string,
      contentText: PropTypes.string,
    }),
  ]),
  existContentWithUrl: PropTypes.objectOf(PropTypes.any),
  adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
};

MediaContentNew.defaultProps = {
  mediaContent: null,
  newMediaContentData: false,
  existContentWithUrl: null,
};

const mapStateToProps = state => ({
  adminUser: state.session,
  loading: state.score.loading,
  mediaContent: state.score.mediaContent,
  newMediaContentData: state.score.newMediaContentData,
  existContentWithUrl: state.score.existContentWithUrl,
  adminUsers: state.ResearchReducer.adminUsers,
});

export default connect(mapStateToProps)(MediaContentNew);
