import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import axios from 'axios';
import { boolToInt } from '../../../helpers/Form';
import { updateResourceData } from '../../../helpers/WVRequest';

const SelectResource = ({
  resource,
  record,
  property,
  options,
  placeholder,
  isClearable,
  fetching,
  primaryKey,
  fill,
  addUrl,
  afterChange,
  className,
}) => {
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);

  const onChange = (option) => {
    setValue(option);
    setLoading(true);
    const data = fill(property, [null, undefined].includes(option?.value) ? '' : option.value, record);
    const afterSave = () => setLoading(false);
    if (record.params[primaryKey]) {
      updateResourceData(resource, record.params[primaryKey], data).then(afterSave);
    } else if (addUrl) {
      axios.post(addUrl, { data }).then(afterSave);
    }
  };

  useEffect(() => {
    setValue([null, undefined].includes(record?.params?.[property]) ? null : options.find(o => o.value === boolToInt(record.params[property])));
  }, [record, options]);

  useEffect(() => {
    afterChange(value);
  }, [value]);

  useEffect(() => {
    setLoading(fetching);
  }, [fetching]);

  return (
    <Select
      value={value}
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      className={className}
      isDisabled={loading}
      isClearable={isClearable}
      menuPlacement="auto"
      menuPortalTarget={document.body}
    />
  );
};

SelectResource.propTypes = {
  resource: PropTypes.string.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  property: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  fetching: PropTypes.bool,
  primaryKey: PropTypes.string,
  className: PropTypes.string,
  fill: PropTypes.func,
  addUrl: PropTypes.string,
  afterChange: PropTypes.func,
};

SelectResource.defaultProps = {
  placeholder: '',
  isClearable: true,
  fetching: false,
  primaryKey: '_id',
  className: 'mb-3',
  fill: (property, value) => ({ [property]: value }),
  addUrl: null,
  afterChange: () => {},
};

export default SelectResource;
