import React, { useState, useEffect } from 'react';
import { Modal, ButtonToolbar, Button, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import {axios} from 'ApiClient';
import Hook from '../../../../../../../shared/hooks';
import PermissionsDialog from '../../../../AdminUser/PermissionsDialog';

export const modalId = 'PermissionModal';

const PermissionModal = ({ availablePermissions }) => {
  const { data: dataModal, showModal: modal, close: closeDialog } = Hook.useModalHook(modalId);
  const [adminUsers, setAdminUsers] = useState([]);
  const [finalPermissions, setFinalPermissions] = useState([]);
  const [finalAdminUsers, setFinalAdminUsers] = useState([]);
  const { showError } = Hook.useNoticeHook();


  const fetchAdminUsers = async () => {
    const response = await axios.get('/api/portal/adminuser/get');
    const suggestions = [];
    if (response.data.success === true && response.data.adminUsers?.length > 0) {
      response.data.adminUsers.forEach((user) => {
        suggestions.push({
          id: user._id,
          name: `${user.firstName} ${user.lastName} ${user.email}`,
          permissions: user.permissions ? [...user.permissions] : [],
        });
      });
    }
    return suggestions;
  };

  const saveAdminUsersPermissions = async () => {
    const permissionsSubSet = finalPermissions.filter(p => p.shouldUpdate === true);

    const response = await axios.post('/api/portal/adminuser/save-permissions', {
      adminUsers: finalAdminUsers.map(a => a.id),
      permissions: permissionsSubSet,
    });

    if (response.data.success) {
      closeDialog();
      setFinalAdminUsers([]);
      setFinalPermissions([]);
      setAdminUsers([]);
    } else {
      showError(response.data.error || 'Failed to save user permissions');
    }
  };
  useEffect(async () => {
    if (dataModal) {
      if (adminUsers.length === 0) {
        const users = await fetchAdminUsers();
        setAdminUsers(users);
      }
    }
  }, [])


  return (
    <Modal
      isOpen={modal}
      toggle={closeDialog}
      id="adminUserPermissionModal"
      style={{ minWidth: '60%' }}
    >
      <ModalHeader toggle={() => closeDialog()} tag="h1">Update Permissions</ModalHeader>
      <PermissionsDialog
        adminUsers={adminUsers}
        availablePermissions={availablePermissions}
        setFinalPermissions={setFinalPermissions}
        setFinalAdminUsers={setFinalAdminUsers}
        finalPermissions={finalPermissions}
        finalAdminUsers={finalAdminUsers}
      />
      <ButtonToolbar className="modal__footer">
        <Button color="primary" onClick={saveAdminUsersPermissions}>Save</Button>
         &nbsp;&emsp;&nbsp;&emsp;
        <Button color="secondary" onClick={() => closeDialog()}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};
PermissionModal.propTypes = {
  availablePermissions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,

};
export default PermissionModal;
