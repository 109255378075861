import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ThemeProps } from '../../../prop-types/ReducerProps';
import ReactTableDnDBody from './ReactTableDnDBody';

const getRowBackgroundColor = (row, enabledStyles, enableWarning) => {
  const status = row.original.existStatus || row.original.params?.existStatus;
  const isDeleted = row.original.isDeleted || row.original.params?.isDeleted;
  const inBlackList = row.original.inBlackList || row.original.params?.inBlackList || row.original.blacklist_ || row.original.params?.blacklist_;
  if (inBlackList || row.original.isTotal) {
    return '#c9cccf';
  }
  if (enabledStyles && status && status === 'info') {
    return '#fffdec';
  }
  if (enableWarning && enabledStyles && status && status === 'warning') {
    // return '#fadbdb';
    return '#ffd6a5';
  }
  if (isDeleted) {
    return '#ffc1c1';
  }
  return '';
};
const getRowClassName = (row, enabledStyles, enableWarning) => {
  const status = row.original.existStatus || row.original.params?.existStatus;
  const isDeleted = row.original.isDeleted || row.original.params?.isDeleted;
  const inBlackList = row.original.inBlackList || row.original.params?.inBlackList || row.original.blacklist_ || row.original.params?.blacklist_;
  if (row.original.isTotal) {
    return 'font-weight-bold';
  }
  if (inBlackList) {
    return 'table-bg-blacklist';
  }
  if (enabledStyles && status && status === 'info') {
    return 'table-bg-info';
  }
  if (enableWarning && enabledStyles && status && status === 'warning') {
    // return '#fadbdb';
    return 'table-bg-warning';
  }
  if (isDeleted) {
    return 'table-bg-deleted';
  }
  return '';
};

const getRowProps = (row, enabledStyles, enableWarning, useFlex) => ({
  style: {
    backgroundColor: getRowBackgroundColor(row, enabledStyles, enableWarning),
    ...useFlex && { alignItems: 'stretch' },
  },
  className: getRowClassName(row, enabledStyles, enableWarning),
});

const ReactTableDefaultBody = ({
  page, getTableBodyProps, prepareRow, updateRecordFunction, enabledStyles, enableWarning, isResizable, isLoading, notFoundProps, useFlex,
}) => (
  <>
    {page.length > 0 || isLoading ? (
      <tbody className="table table--bordered" {...getTableBodyProps()}>
        {page.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps(getRowProps(row, enabledStyles, enableWarning, (isResizable || useFlex)))}>
              {row.cells.map(cell => (
                <td
                  className={cell.column.className}
                  {...cell.getCellProps({
                    style: {
                      ...(!isResizable && !useFlex && { width: cell.column.width }),
                      ...(cell.column.isFreeze ? { position: 'sticky', left: cell.column.left || 0, zIndex: 1, backgroundColor: cell.column.backgroundColor || '#fff' } : {})
                    },
                  })}
                >
                  {cell.render('Cell', { updateRecordFunction })}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    ) : (
      <tbody>
        <tr>
          <td colSpan={notFoundProps.columnLength}>
            <h2 style={{ textAlign: 'center', ...notFoundProps.styles }}>
              {notFoundProps.title}
            </h2>
          </td>
        </tr>
      </tbody>
    )}
  </>
);

ReactTableDefaultBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  updateRecordFunction: PropTypes.func,
  enabledStyles: PropTypes.bool,
  enableWarning: PropTypes.bool,
  isResizable: PropTypes.bool.isRequired,
  useFlex: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  notFoundProps: PropTypes.objectOf(PropTypes.any).isRequired,
};
ReactTableDefaultBody.defaultProps = {
  enabledStyles: true,
  enableWarning: true,
  updateRecordFunction: null,
};

const ReactTableBody = ({
  page, getTableBodyProps, prepareRow, withDragAndDrop, updateDraggableData, theme, updateRecordFunction, enabledStyles, enableWarning, isResizable, isLoading, notFoundProps, useFlex,
}) => (
  <Fragment>
    {withDragAndDrop
      ? (
        <ReactTableDnDBody
          page={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          updateDraggableData={updateDraggableData}
          theme={theme}
        />
      ) : (
        <ReactTableDefaultBody
          page={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          updateRecordFunction={updateRecordFunction}
          enabledStyles={enabledStyles}
          enableWarning={enableWarning}
          isResizable={isResizable}
          useFlex={useFlex}
          isLoading={isLoading}
          notFoundProps={notFoundProps}
        />
      )}
  </Fragment>
);

ReactTableBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  updateDraggableData: PropTypes.func.isRequired,
  withDragAndDrop: PropTypes.bool.isRequired,
  theme: ThemeProps.isRequired,
  updateRecordFunction: PropTypes.func,
  enabledStyles: PropTypes.bool,
  enableWarning: PropTypes.bool,
  isResizable: PropTypes.bool.isRequired,
  useFlex: PropTypes.bool,
  isLoading: PropTypes.bool,
  notFoundProps: PropTypes.objectOf(PropTypes.any).isRequired,
};
ReactTableBody.defaultProps = {
  enabledStyles: true,
  enableWarning: true,
  updateRecordFunction: null,
  isLoading: false,
  useFlex: true,
};

export default connect(state => ({
  theme: state.theme,
}))(ReactTableBody);
