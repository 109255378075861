const initialFilters = {
  urlStatusFilters: {
    'Needs Review': true,
    'Pending Publication': false,
    'Needs Content URL': false,
    Approved: false,
    Inactive: false,
  },
  privacyStatus: {
    na: true,
    unlisted: true,
    public: true,
    private: true,
  },
  publishDateAssignedFilters: {
    Assigned: true,
    'Not Assigned': true,
  },
  hasPaidProductPlacementFilters: {
    Yes: true,
    No: true,
    'Not Assigned': true,
  },
};

export default initialFilters;
