import React, { useMemo } from 'react';
import { Col } from 'reactstrap';
import ReactTableBase from './ReactTableBase'; // імпортуйте ваш ReactTableBase компонент

const mapColumns = (columns) => {
  return Object.keys(columns).map((key) => {
    const col = columns[key];
    const { Component, valueFunc } = col;
    return {
      id: key,
      Header: col.title || ' ',
      accessor: d => col.sort ? col.sort(d) : d[key],
      Cell: ({ value, row }) => {
        const deal = row.original;
        if (Component) {
          
          return (<Component record={deal} fieldId={key} />);
        }
        return typeof valueFunc === 'function' ? valueFunc(deal) || ' ' : value || ' ';
      },
      sortType: col.sort ? 'basic' : undefined,
      ...col,
    };
  });
};

const MatTableToTable = ({ dataArray, columns, defaultOrder, hideToolbar, key, filters }) => {
  const getItemsForTable = () => {
    // console.log(columns, 'COLUMNS');
    if(!dataArray) return [];
    return dataArray
      .filter((item) => {
        let isGood = true;
        Object.keys(filters || {}).forEach((key) => {
          if (columns[key].filter && Array.isArray(columns[key].filter(item))) {
            const itemArray = columns[key].filter(item);
            let countFalse = 0;
            itemArray.forEach((d) => {
              if (!filters[key][d]) {
                countFalse += 1;
              }
            });
            if (countFalse === itemArray.length) {
              isGood = false;
            }
          } else if (columns[key].filter && !filters[key][columns[key].filter(item)]) {
            isGood = false;
          }
        });
        if (filters?.search && isGood && columns[filters.search.column] && columns[filters.search.column].searchFunc) {
          isGood = columns[filters.search.column].searchFunc(item, filters.search.value);
        }
        return isGood;
      });
  };

  const data = useMemo(() => {
    return getItemsForTable({ });
  }, [filters, dataArray])
  console.log(data,dataArray,filters, 'data')

  const columnTransform = useMemo(() => mapColumns(columns ), [columns]);
  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withPagination: true,
    manualPageSize: [10, 20, 30, 40],
    withSearchEngine: !hideToolbar,
    sortBy: defaultOrder?.orderBy || '_id',
    direction: defaultOrder?.order || 'desc',
    useFlex: true,
  };
  return (
    <Col className="mt-3">
      <ReactTableBase
        key={key || 'someDefaultFate'}
        columns={columnTransform}
        data={data}
        tableConfig={tableConfig}
      />
    </Col>
    
  );
};

export default MatTableToTable;