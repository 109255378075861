import React, { useState, useEffect } from 'react';
import {
  FormGroup,
  Label,
  Modal,
  Spinner,
  Input,
  ModalHeader,
  ModalBody,
  ButtonToolbar,
  Button,
} from 'reactstrap';
import { axios } from 'ApiClient';
import Select from 'react-select';
import recordToFormData from '@/shared/components/components/actions/record-to-form-data';
import Hook from 'ILHooks';
import ReactTableBase from '../../../../../../../shared/tables/table/ReactTableBase';

const roles = [
  {value: 'owner', label: 'Owner'},
  {value: 'superAdmin', label: 'Super Admin'},
  {value: 'admin', label: 'Admin'},
];

const requiredData = [
  'firstName', 'lastName', 'email', 'role'
];
export const modalId = 'CreateAdminUserModal';

const tableConfig = {
  isEditable: false,
  isResizable: false,
  isSortable: true,
  withPagination: true,
  manualPageSize: [15, 20, 30, 40],
  withSearchEngine: true,
  useFlex: true,
  placeholder: 'Search for Permission Item or Path...',
};
const groupByHierarchy = (array) => {
  const grouped = {};

  array.forEach((item) => {
    const levels = item.value.split("----");
    let currentLevel = grouped;

    levels.forEach((level, index) => {
      if (!currentLevel[level]) {
        currentLevel[level] = {
          value: level,
          originalValue: index === levels.length - 1 ? item.value : undefined,
          label: index === levels.length - 1 ? item.label : undefined,
          items: {},
        };
      }
      currentLevel = currentLevel[level].items;
    });
  });

  const transformToArray = (obj) =>
    Object.values(obj).map(({ value, label, items, originalValue }) => ({
      value,
      label,
      originalValue,
      items: Object.keys(items).length ? transformToArray(items) : undefined,
    }));

  return transformToArray(grouped);
};


const CreateAdminUserModal = ({ availablePermissions }) => {
  const { data: dataModal, showModal: modal, close: closeDialog } = Hook.useModalHook(modalId);
  const [slackOptions, setSlackOptions] = useState([]);
  const [hubspotOptions, setHubspotOptions] = useState([]);
  const [emploeeOptions, setEmploeeOptions] = useState([]);
  const { showError, showSuccess, showWarning } = Hook.useNoticeHook()
  // const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState({ form: false });
  const [adminUser, setAdminUser] = useState({ params: {}, errors: {} });
  const [permissions, setPermissions] = useState(availablePermissions.map(item => item.value).reduce((acc, item) => {
    acc[item] = true;
    return acc;
  }, {}));

  const handleSwitchChange = (event) => {
    const name = event.target.name;
    if (name === 'main') {
      setPermissions((perm) => Object.keys(perm).reduce((acc, item) => {
        acc[item] = event.target.checked;
        return acc;
      }, {}));
      return true;
    }
    const keys = Object.keys(permissions);
    const permsItems = keys.filter(a => a.startsWith(name)).reduce((acc, item) => {
      acc[item] = event.target.checked;
      return acc;
    }, {});
    const parents = name.split('----');
    if (keys.includes(parents[0]) && event.target.checked) {
      permsItems[parents[0]] = event.target.checked;
    }
    if (keys.includes(`${parents[0]}----${parents[1]}`) && event.target.checked) {
      permsItems[`${parents[0]}----${parents[1]}`] = event.target.checked;
    }

    setPermissions((perm) => ({ ...perm, ...permsItems }));
    return true;
  }

  const loadSlackUsers = async () => {
    try {
      const res = await axios.get('/api/portal/adminUsers/get-slack-users');
      const { success, users } = res.data;
      if (success) {
        setSlackOptions(users.filter(a => a.label?.trim()));
      }

    } catch (err) {
      showError(err.message);
      console.log(err);
    } finally {
      console.log('finally');
    }
    
  }

  const loadHubspotUsers = async () => {
    try {
      const res = await axios.get('/api/portal/adminUsers/get-hubspot-users');
      const { success, users } = res.data;
      if (success) {
        setHubspotOptions(users.filter(a => a.label?.trim()));
      }

    } catch (err) {
      showError(err.message);
      console.log(err);
    } finally {
      console.log('finally');
    }
  }

  const loadAffiliateUsers = async() => {
    try {
      const resp = await axios.get('/api/portal/finance/get-employees')
      setEmploeeOptions(resp.data.employees.map(e => ({ label: `${e.firstName || ''} ${e.lastName || ''}`, value: e.tuneId })).filter(a => a.label?.trim()));
    } catch (err) {
      showError(err.message);
      console.log(err);
    } finally {
      console.log('finally');
    }
  };
  useEffect(() => {
    if(modal === true) {
      loadSlackUsers();
      loadHubspotUsers();
      loadAffiliateUsers();
    }
  }, [modal]);


  const grouped = groupByHierarchy(availablePermissions);
  const columnTransform = [
    {
      id: 'main',
      Header: () => (
        <FormGroup check inline>
          <Input type="checkbox" id='main' name='main' checked={Object.values(permissions).every(a => a === true)} onChange={handleSwitchChange} />
        </FormGroup>
      ),
      accessor: (record) => record.originalValue,
      // accessor: (record) => record.label,
      Cell: record => (<FormGroup check inline>
        <Input type="checkbox" id={record.row.original.originalValue} name={record.row.original.originalValue} checked={permissions[record.row.original.originalValue]} onChange={handleSwitchChange} />
        <Label check for={record.row.original.originalValue}>
          {record.row.original.label}
        </Label>
      </FormGroup>),
      sort: () => { },
      width: 80,
      disableSortBy: true,
    },
    {
      Header: 'Items',
      accessor: (record) => record.items.map(a => `${a.value} ${a.items?.map(a => a.value).join(' ') || ''}`).join(' '),
      Cell: record => {
        console.log(record.row.original.items, 'record');
        const res = record.row.original.items.filter(a => a.items?.length > 0).map(a => (<div>
          <FormGroup check inline>
            <Input type="checkbox" id={a.originalValue} name={a.originalValue} checked={permissions[a.originalValue]} onChange={handleSwitchChange} />
            <Label check for={a.originalValue}>
              {a.label}
            </Label>
          </FormGroup>
          <div style={{ paddingLeft: '20px' }}>

            {a.items.map(b => {
              if (b.items && b.items.length > 0) {
                return (<div>
                  <FormGroup check inline>
                    <Input type="checkbox" id={b.originalValue} name={b.originalValue} checked={permissions[b.originalValue]} onChange={handleSwitchChange} />
                    <Label check for={b.originalValue}>
                      {b.label}
                    </Label>
                  </FormGroup>
                  <div style={{ paddingLeft: '20px' }}>
                    {b.items.map(c => (<FormGroup check inline>
                      <Input type="checkbox" id={c.originalValue} name={c.originalValue} checked={permissions[c.originalValue]} onChange={handleSwitchChange} />
                      <Label check for={c.originalValue}>
                        {c.label}
                      </Label>
                    </FormGroup>))}
                  </div>
                </div>);
              }
                    
              return (<FormGroup check inline>
                <Input type="checkbox" id={b.originalValue} name={b.originalValue} checked={permissions[b.originalValue]} onChange={handleSwitchChange} />
                <Label check for={b.originalValue}>
                  {b.label}
                </Label>
              </FormGroup>)
            }
            )}
          </div>
        </div>));
        if (res.length > 0) res.push(<div><hr /></div>);
        const res2 = record.row.original.items.filter(a => !a.items || a.items.length === 0).map(a => (<FormGroup check inline>
          <Input type="checkbox" id={a.originalValue} name={a.originalValue} checked={permissions[a.originalValue]} onChange={handleSwitchChange} />
          <Label check for={a.originalValue}>
            {a.label}
          </Label>
        </FormGroup>));
        return [...res, ...res2];
      },
      sort: () => { },
      width: 400,
      disableSortBy: true,

    }
  ];

  useEffect(() => {
    if (modal) {
      setAdminUser({
        params: { email: '', firstName: '', lastName: '', role: '', password: '',  },
        errors: {},
      });
    }
  }, [modal]);
  useEffect(() => {
    if (modal &&  dataModal.record && dataModal.record.id) {
      setAdminUser({ ...dataModal.record, params: { ...dataModal.record.params, password: '' } });
      const clearPermissions = availablePermissions.map(item => item.value).reduce((acc, item) => {
        acc[item] = false;
        return acc;
      }, {})
      console.log(clearPermissions, dataModal.record.params.permissions)
      const perms = { ...clearPermissions}
      dataModal.record.params.permissions.forEach(p => {
        perms[p] = true;
      });
      console.log(clearPermissions, dataModal.record.params.permissions, perms)
      setPermissions(perms);
    }
  }, [modal]);
  if (!dataModal) return false;
  const handleChange = (key, value) => {
    setAdminUser(prev => ({
      ...prev,
      params: {
        ...prev.params,
        [key]: value,
      },
      errors: {
        ...prev.errors,
        [key]: undefined,
      },
    }));
  };
  const handleChangeRole = (selected) => {
    handleChange('role', selected.value);
  };
  const handleChangeHS = (selected) => {
    handleChange('hsUserId', selected.value);
  };
  const handleChangeHO = (selected) => {
    handleChange('affiliateManagerId', selected.value);
  };
  const handleChangeSlack = (selected) => {
    handleChange('slackUserId', selected.id);
    handleChange('slackId', selected.value);
  };




  const handleInputChange = (name, event) => {
    const { type, checked, value } = event.target;
    handleChange(name, type === 'checkbox' ? checked : value);
  };

  const { successFunc } = dataModal;


  const handleSubmit = async (event) => {
    event.preventDefault();
    let isValid = true;

    requiredData.forEach((item) => {
      if (!adminUser.params[item]) {
        isValid = false;
        handleChange(item, undefined);
        setAdminUser((prev) => ({
          ...prev,
          errors: {...prev.errors, [item]: 'This field is required'},
        }));
      }
    });

    if ((adminUser.params.password || adminUser.params.repeatPassword) && adminUser.params.password !== adminUser.params.repeatPassword) {
      isValid = false;
      setAdminUser((prev) => ({
        ...prev,
        errors: {...prev.errors, repeatPassword: 'Passwords do not match'},
      }));
    }

    if (!isValid) {
      showWarning('Please Check form. Seems you did not fill some fields');
      return;
    }

    // const formData = recordToFormData(influencer);
    setLoading({ form: true });
    const formData = recordToFormData({ ...adminUser, params: { ...adminUser.params, permissions: Object.keys(permissions).filter(p => permissions[p]) }});
    const res = await axios({
      method: 'post',
      url: adminUser.params._id ? `/api/resources/AdminUser/records/${adminUser.params._id}/edit` : '/api/resources/AdminUser/actions/new',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });
    if (res.data.record.errors && Object.keys(res.data.record.errors).length > 0) {
      setAdminUser((prev) => ({
        ...prev,
        errors: res.data.record.errors,
      }));
    } else {
      showSuccess('Admin User Created Successfully');
      if (successFunc) successFunc();
      closeDialog();
    }
    setLoading({ form: false });


  };

  const closeFormDialog = () => {
    setPermissions((perm) => Object.keys(perm).reduce((acc, item) => {
      acc[item] = true;
      return acc;
    }, {}));
    setAdminUser({
      params: { email: '', firstName: '', lastName: '', role: '', password: '',  },
      errors: {},
    });
    closeDialog();
  };
  console.log(grouped, 'grouped');

  return (
    <Modal isOpen={modal} toggle={closeFormDialog} style={{ minWidth: '60%' }} className='ltr-support theme-light'>
      <ModalHeader toggle={closeFormDialog} tag="h4" className="modal-title-statistics">
        {dataModal?.record?.id ? 'Edit Admin User' : 'Create New Admin User'}
      </ModalHeader>
      <ModalBody>
        {loading.form ? (
          <div className="text-center"><Spinner color="primary" size="lg" /></div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className='col-6'>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    type="text"
                    id="email"
                    name="email"
                    placeholder='Email'
                    onChange={(e) => handleInputChange('email', e)}
                    value={adminUser.params.email || ''}
                  />
                  {adminUser.errors.email && (<span className="text-danger">{adminUser.errors.email}</span>)}
                </FormGroup>
              </div>
              <div className='col-6'>
                <FormGroup>
                  <Label>Role</Label>
                  <Select
                    value={roles.find(a => a.value === adminUser.params.role)}
                    options={roles}
                    onChange={handleChangeRole}
                    placeholder="Role"
                  />
                  {adminUser.errors.role && (<span className="text-danger">{adminUser.errors.role}</span>)}
                </FormGroup>
              </div>
              <div className='col-6'>
                <FormGroup>
                  <Label>First Name</Label>
                  <Input
                    type="text"
                    id="email"
                    name="firstName"
                    placeholder='First Name'
                    onChange={(e) => handleInputChange('firstName', e)}
                    value={adminUser.params.firstName || ''}
                  />
                  {adminUser.errors.firstName && (<span className="text-danger">{adminUser.errors.firstName}</span>)}
                </FormGroup>
              </div>
              <div className='col-6'>
                <FormGroup>
                  <Label>Last Name</Label>
                  <Input
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder='Last Name'
                    onChange={(e) => handleInputChange('lastName', e)}
                    value={adminUser.params.lastName || ''}
                  />
                  {adminUser.errors.lastName && (<span className="text-danger">{adminUser.errors.lastName}</span>)}
                </FormGroup>
              </div>
              <div className='col-6'>
                <FormGroup>
                  <Label>Password(leave empty to not change)</Label>
                  <Input
                    type="password"
                    id="password"
                    name="password"
                    placeholder='Password'
                    onChange={(e) => handleInputChange('password', e)}
                    value={adminUser.params.password || ''}
                    autoComplete={false}
                  />
                  {adminUser.errors.password && (<span className="text-danger">{adminUser.errors.password}</span>)}
                </FormGroup>
              </div>
              <div className='col-6'>
                <FormGroup>
                  <Label>Repeat Password</Label>
                  <Input
                    type="password"
                    id="repeatPassword"
                    name="repeatPassword"
                    placeholder='Repeat Password'
                    onChange={(e) => handleInputChange('repeatPassword', e)}
                    value={adminUser.params.repeatPassword || ''}
                    autoComplete={false}
                  />
                  {adminUser.errors.repeatPassword && (<span className="text-danger">{adminUser.errors.repeatPassword}</span>)}
                </FormGroup>
              </div>
              <div className='col-sm-4'>
                <FormGroup>
                  <Label>Hubspot User</Label>
                  <Select
                    value={hubspotOptions.find(a => String(a.value) === String(adminUser.params.hsUserId))}
                    options={hubspotOptions}
                    onChange={(e) => handleChangeHS(e)}
                    placeholder="Hubpost User"
                  />
                  {adminUser.errors.hsUserId && (<span className="text-danger">{adminUser.errors.hsUserId}</span>)}
                </FormGroup>
              </div>
              <div className='col-sm-4'>
                <FormGroup>
                  <Label>Slack User</Label>
                  <Select
                    value={slackOptions.find(a => a.value === adminUser.params.slackId)}
                    options={slackOptions}
                    onChange={handleChangeSlack}
                    placeholder="Slack User"
                  />
                  {adminUser.errors.slackUserId && (<span className="text-danger">{adminUser.errors.slackId}</span>)}
                </FormGroup>
              </div>
              <div className='col-sm-4'>
                <FormGroup>
                  <Label>Account Manager</Label>
                  <Select
                    value={emploeeOptions.find(a => a.value === adminUser.params.affiliateManagerId)}
                    options={emploeeOptions}
                    onChange={handleChangeHO}
                    placeholder="Account Manager"
                  />
                  {adminUser.errors.affiliateManagerId && (<span className="text-danger">{adminUser.errors.affiliateManagerId}</span>)}
                </FormGroup>
              </div>
            </div>
            <ReactTableBase
              key={'someDefaultFate'}
              columns={columnTransform}
              data={grouped}
              tableConfig={tableConfig}
            />

            <ButtonToolbar className="modal__footer">
              <Button
                className="modal_ok btn-sm is-primary"
                color="primary"
                type="submit"
              >
                Save
              </Button>
              &nbsp;&nbsp;
              <Button
                className="modal_cancel btn-sm"
                color="secondary"
                onClick={closeFormDialog}
              >
                Cancel
              </Button>
            </ButtonToolbar>
          </form>
        )}
      </ModalBody>
    </Modal>
  );
};

export default CreateAdminUserModal;
