// import { unflatten } from 'flat';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactTags from 'react-tag-autocomplete';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
  Label,
  FormGroup,
} from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { getCookie, setCookie } from '../../../../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';
import IntervalDatePickerField from '../../../Prospects/components/custom/IntervalDatePickerField';
import Hook from '@/shared/hooks';

const Actions = React.memo(({
  onSearchChange,
  tagsSuggestions,
  setTagsFilter,
  setTags,
  tags,
  getUrlSearch,
  searchValue,
  ownersSuggestions,
  setOwnersFilter,
  setOwners,
  owners,
  setBrandsFilter,
  setBrands,
  brands,
  brandsSuggestions,
  matchFilters,
  setMatchFilters,
  contactOwnerFilters,
  setContactOwnerFilters,
  dealOwnerFilters,
  setDealOwnerFilters,
  targetBrandFilters,
  setTargetBrandFilters,
  inBlacklistFilters,
  setInBlacklistFilters,
  doHubspotImport,
  // setShowAssignContactOwnerModal,
  setShowAssignDealOwnerModal,
  selectedProspectIds,
  disableGlobalImport,
  lastActivityDateValue,
  setLastActivityDateValue,
  enableLastAd,
  setEnableLastAd,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  singleDate,
  setSingleDate,
  isOppFilters,
  setIsOppFilters,
  isInternalFilters,
  setIsInternalFilters,
  countrySuggestions,
  setTagsCountriesFilter,
  tagsCountries,
  setTagsCountries,
}) => {
  console.log(tagsCountries, 'tagsCountries');
  const [showAccountManagerModal] = Hook.useShowModalHook('assignContactOwnerModal');
  const [showAssignAgentModal] = Hook.useShowModalHook('assignHubspotAgentModal');
  const [search, setSearch] = useState(searchValue);
  const [dateSearch, setDateSearch] = useState(null);
  const [showFilters, setShowFilters] = useState(getCookie('researchLeadsShowFilters') === 'true');

  const reactTagsTags = React.createRef();
  const reactTagsOwners = React.createRef();
  const reactTagsBrands = React.createRef();
  const reactTagsCountries = React.createRef();

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 1000));
  }, [search]);

  useEffect(() => {
    setCookie('researchLeadsShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  const setFiltersTag = (tagsNew) => {
    const tagsFilterNew = {};
    if (tagsNew.length > 0) {
      tagsNew.map((item) => {
        tagsFilterNew[item.name] = true;
        return true;
      });
    }
    setTagsFilter(tagsFilterNew);
  };

  const onDeleteTag = (i) => {
    const tagsNew = tags.slice(0);
    tagsNew.splice(i, 1);
    setFiltersTag(tagsNew);
    setTags(tagsNew);
  };

  const onAdditionTag = (tag) => {
    const tagsNew = [].concat(tags, tag);
    setFiltersTag(tagsNew);
    setTags(tagsNew);
  };

  const setFiltersOwner = (ownersNew) => {
    const ownersFilterNew = {};
    if (ownersNew.length > 0) {
      ownersNew.map((item) => {
        ownersFilterNew[item.id] = true;
        return true;
      });
    }
    setOwnersFilter(ownersFilterNew);
  };

  const setFiltersBrands = (brandsNew) => {
    const brandsFilterNew = {};
    if (brandsNew.length > 0) {
      brandsNew.map((item) => {
        brandsFilterNew[item.id] = true;
        return true;
      });
    }
    setBrandsFilter(brandsFilterNew);
  };

  const onDeleteOwner = (i) => {
    const ownersNew = owners.slice(0);
    ownersNew.splice(i, 1);
    setFiltersOwner(ownersNew);
    setOwners(ownersNew);
  };

  const onAdditionOwner = (owner) => {
    const ownersNew = [].concat(owners, owner);
    setFiltersOwner(ownersNew);
    setOwners(ownersNew);
  };

  const onDeleteBrand = (i) => {
    const brandsNew = brands.slice(0);
    brandsNew.splice(i, 1);
    setFiltersBrands(brandsNew);
    setBrands(brandsNew);
  };

  const onAdditionBrand = (brand) => {
    const brandsNew = [].concat(brands, brand);
    setFiltersBrands(brandsNew);
    setBrands(brandsNew);
  };

  const setFiltersCountry = (countriesNew) => {
    const countriesFilterNew = {};
    if (countriesNew.length > 0) {
      countriesNew.map((item) => {
        countriesFilterNew[item.id] = true;
        return true;
      });
    }
    setTagsCountriesFilter(countriesFilterNew);
  };
  const onDeleteCountry = (i) => {
    const countriesNew = tagsCountries.slice(0);
    countriesNew.splice(i, 1);
    setFiltersCountry(countriesNew);
    setTagsCountries(countriesNew);
  };

  const onAdditionCountry = (country) => {
    const countriesNew = [].concat(tagsCountries, country);
    setFiltersCountry(countriesNew);
    setTagsCountries(countriesNew);
  };

  const handleChangeMatchFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setMatchFilters({
        ...matchFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeContactOwnerFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setContactOwnerFilters({
        ...contactOwnerFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeDealOwnerFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setDealOwnerFilters({
        ...dealOwnerFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeTargetBrandFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setTargetBrandFilters({
        ...targetBrandFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeIsInternalFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setIsInternalFilters({
        ...isInternalFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeIsBlacklistFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setInBlacklistFilters({
        ...inBlacklistFilters,
        [name]: checked,
      });
    }
  };
  const handleChangeIsOppFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setIsOppFilters({
        ...isOppFilters,
        [name]: checked,
      });
    }
  };

  const resetFilters = () => {
    setSearch('');
    onSearchChange('');
    setMatchFilters({
      Match: true,
      'No Match': true,
    });
    setContactOwnerFilters({
      Assigned: true,
      'Not Assigned': true,
    });
    setDealOwnerFilters({
      Assigned: true,
      'Not Assigned': true,
    });
    setTargetBrandFilters({
      Assigned: true,
      'Not Assigned': true,
    });
    setIsInternalFilters({
      Standard: true,
      Internal: true,
    });
    setInBlacklistFilters({
      Yes: true,
      No: true,
    });
    setTags([]);
    setTagsFilter({});
    setBrands([]);
    setBrandsFilter({});
    setOwners([]);
    setOwnersFilter({});
    setEnableLastAd(false);
    setLastActivityDateValue({ value: '<=', label: '<=' });
    setSingleDate(null);
    setDateTo(null);
    setDateFrom(null);
  };
  const handleChangeLastADCheckBox = ({ target }) => {
    setEnableLastAd(!enableLastAd);
    if (!target.checked) {
      setSingleDate(null);
      setDateTo(null);
      setDateFrom(null);
    }
  };

  const lastActivityDateLabels = [
    { value: '=', label: '=' },
    { value: '>', label: '>' },
    { value: '>=', label: '>=' },
    { value: '<', label: '<' },
    { value: '<=', label: '<=' },
    { value: 'Between', label: 'Between' },
  ];

  const customStyles = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? '#e9ecef' : 'white',
      height: '44px',
    }),
  };

  return (
    <div>
      <Row className="mt-4">
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search First Name, Last Name, Email, YouTube Channel"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                disabled={selectedProspectIds.length === 0 || disableGlobalImport}
                onClick={() => { doHubspotImport(); }}
              >
                HubSpot Import
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                disabled={selectedProspectIds.length === 0}
                onClick={() => { showAccountManagerModal(true); }}
              >
                Assign Account Manager
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                disabled={selectedProspectIds.length === 0}
                onClick={() => { setShowAssignDealOwnerModal(true); }}
              >
                Assign Deal Owner
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                disabled={selectedProspectIds.length === 0}
                onClick={() => { showAssignAgentModal(true); }}
              >
                Assign Agent
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => window.open(`/resources/leads/download-csv?${getUrlSearch().toString()}`, '_blank').focus()}
              >
                Download CSV
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      { showFilters && (
        <>
          <Row>
            <Col sm={3}>
              <Label className="bold-text">
                Brand(s)
              </Label>
              <ReactTags
                ref={reactTagsBrands}
                tags={brands}
                suggestions={brandsSuggestions}
                onDelete={onDeleteBrand}
                onAddition={onAdditionBrand}
                delimiters={['Enter', ',']}
                placeholderText="Brand name(s)"
              />
            </Col>
            <Col sm={3}>
              <Label className="bold-text">
                Account Manager(s)
              </Label>
              <ReactTags
                className="mr-2"
                ref={reactTagsOwners}
                tags={owners}
                suggestions={ownersSuggestions}
                onDelete={onDeleteOwner}
                onAddition={onAdditionOwner}
                delimiters={['Enter', ',']}
                placeholderText="Contact Owner name(s)"
              />
            </Col>
            <Col sm={3}>
              <Label className="bold-text">
                Country of Residence
              </Label>
              <ReactTags
                className="mr-2"
                ref={reactTagsCountries}
                tags={tagsCountries}
                suggestions={countrySuggestions}
                onDelete={onDeleteCountry}
                onAddition={onAdditionCountry}
                delimiters={['Enter', ',']}
                placeholderText="Country of Residence"
              />
            </Col>
            <Col sm={3}>
              <Label className="bold-text">
                Tag(s)
              </Label>
              <ReactTags
                className="mr-2"
                ref={reactTagsTags}
                tags={tags}
                suggestions={tagsSuggestions}
                onDelete={onDeleteTag}
                onAddition={onAdditionTag}
                delimiters={['Enter', ',']}
                placeholderText="Tag name(s)"
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={4}>
              <Row>
                <Col sm={1} />
                <Col sm={11} style={{ paddingLeft: '20px' }}>
                  <Label className="bold-text" style={{ marginBottom: '8px' }}>
                    Last Activity Date
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <FormGroup
                    style={{ paddingTop: '13px', paddingLeft: '8px' }}
                  >
                    <CheckBoxField
                      name="lastActivityDateCheckBox"
                      value={enableLastAd}
                      onChange={e => e.target && handleChangeLastADCheckBox(e)}
                    />
                  </FormGroup>
                </Col>
                <Col sm={5} style={{ paddingLeft: '20px' }}>
                  <Select
                    value={lastActivityDateValue}
                    onChange={(option) => {
                      if ((lastActivityDateValue !== 'Between' && option.value === 'Between') || (lastActivityDateValue === 'Between' && option.value !== 'Between')) {
                        setDateFrom(null);
                        setDateTo(null);
                        setSingleDate(null);
                      }
                      setLastActivityDateValue(option);
                    }}
                    options={lastActivityDateLabels}
                    isDisabled={!enableLastAd}
                    styles={customStyles}
                  />
                </Col>
                <Col sm={6}>
                  {lastActivityDateValue.value === 'Between' ? (
                    <div style={{ marginRight: '-30px' }} className="pb-2">
                      <IntervalDatePickerField
                        onChange={({ start, end }) => {
                          setDateFrom(start);
                          end?.setHours(23, 59, 59);
                          setDateTo(end);
                        }}
                        startDate={dateFrom}
                        endDate={dateTo}
                        maxDate={new Date()}
                        placeholderFrom="Start Date"
                        placeholderTo="End Date"
                        verticalFields
                        disabled={!enableLastAd}
                      />
                    </div>
                  ) : (
                    <div className="date-picker datepicker-in-prospects">
                      <DatePicker
                        selected={singleDate}
                        onChange={(date) => {
                          if (lastActivityDateValue.value === '<=' || lastActivityDateValue.value === '>') {
                            date?.setHours(23, 59, 59);
                          }
                          setSingleDate(date);
                        }}
                        dateFormat="MMM d, yyyy"
                        maxDate={new Date()}
                        placeholderText="Select Date"
                        dropDownMode="select"
                        isClearable
                        wrapperClassName="date-picker--interval w-100"
                        className="form-control form-control-sm datepicker-in-prospects w-100"
                        disabled={!enableLastAd}
                      />
                      {singleDate === null && (
                        <svg
                          className="mdi-icon"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          style={{
                            position: 'absolute',
                            top: '13px',
                            right: '22px',
                            fill: '#999',
                            pointerEvents: 'none',
                          }}
                        >
                          <path
                            d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                          />
                        </svg>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                InfluenceLogic Contact
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="Match"
                  label="Match"
                  value={matchFilters.Match}
                  onChange={event => handleChangeMatchFilters(event, 'Match')}
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <CheckBoxField
                  name="NoMatch"
                  label="No Match"
                  value={matchFilters['No Match']}
                  onChange={event => handleChangeMatchFilters(event, 'No Match')}
                />
              </FormGroup>
            </Col>
            <Col>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Account Manager
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="AssignedContactOwner"
                  label="Assigned"
                  value={contactOwnerFilters.Assigned}
                  onChange={event => handleChangeContactOwnerFilters(event, 'Assigned')}
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <CheckBoxField
                  name="NotAssignedContactOwner"
                  label="Not Assigned"
                  value={contactOwnerFilters['Not Assigned']}
                  onChange={event => handleChangeContactOwnerFilters(event, 'Not Assigned')}
                />
              </FormGroup>
            </Col>
            <Col>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Deal Owner
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="AssignedDealOwner"
                  label="Assigned"
                  value={dealOwnerFilters.Assigned}
                  onChange={event => handleChangeDealOwnerFilters(event, 'Assigned')}
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <CheckBoxField
                  name="NotAssignedDealOwner"
                  label="Not Assigned"
                  value={dealOwnerFilters['Not Assigned']}
                  onChange={event => handleChangeDealOwnerFilters(event, 'Not Assigned')}
                />
              </FormGroup>
            </Col>
            <Col>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Target Brand
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="AssignedTargetBrand"
                  label="Assigned"
                  value={targetBrandFilters.Assigned}
                  onChange={event => handleChangeTargetBrandFilters(event, 'Assigned')}
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <CheckBoxField
                  name="NotAssignedTargetBrand"
                  label="Not Assigned"
                  value={targetBrandFilters['Not Assigned']}
                  onChange={event => handleChangeTargetBrandFilters(event, 'Not Assigned')}
                />
              </FormGroup>
            </Col>
            <Col>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Prospect Type
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="Standard"
                  label="Standard"
                  value={isInternalFilters.Standard}
                  onChange={event => handleChangeIsInternalFilters(event, 'Standard')}
                />
              </FormGroup>
              <FormGroup>
                <CheckBoxField
                  name="Internal"
                  label="Internal"
                  value={isInternalFilters.Internal}
                  onChange={event => handleChangeIsInternalFilters(event, 'Internal')}
                />
              </FormGroup>
            </Col>
            <Col>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                 Is Opportunity?
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="Yes"
                  label="Yes"
                  value={isOppFilters.yes}
                  onChange={event => handleChangeIsOppFilters(event, 'yes')}
                />
              </FormGroup>
              <FormGroup>
                <CheckBoxField
                  name="No"
                  label="No"
                  value={isOppFilters.no}
                  onChange={event => handleChangeIsOppFilters(event, 'no')}
                />
              </FormGroup>
            </Col>
            <Col>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Blacklist?
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="Yes"
                  label="Yes"
                  value={inBlacklistFilters.Yes}
                  onChange={event => handleChangeIsBlacklistFilters(event, 'Yes')}
                />
              </FormGroup>
              <FormGroup>
                <CheckBoxField
                  name="No"
                  label="No"
                  value={inBlacklistFilters.No}
                  onChange={event => handleChangeIsBlacklistFilters(event, 'No')}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}, (a, a1) =>
  a.searchValue === a1.searchValue &&
  _.isEqual(a.matchFilters, a1.matchFilters) &&
  _.isEqual(a.contactOwnerFilters, a1.contactOwnerFilters) &&
  _.isEqual(a.dealOwnerFilters, a1.dealOwnerFilters) &&
  _.isEqual(a.targetBrandFilters, a1.targetBrandFilters) &&
  _.isEqual(a.isInternalFilters, a1.isInternalFilters) &&
  _.isEqual(a.inBlacklistFilters, a1.inBlacklistFilters) &&
  _.isEqual(a.isOppFilters, a1.isOppFilters) &&
  _.isEqual(a.selectedProspectIds, a1.selectedProspectIds) &&
  _.isEqual(a.tags, a1.tags) &&
  _.isEqual(a.owners, a1.owners) &&
  _.isEqual(a.brands, a1.brands) &&
  _.isEqual(a.brandsSuggestions, a1.brandsSuggestions) &&
  _.isEqual(a.tagsSuggestions, a1.tagsSuggestions) &&
  _.isEqual(a.ownersSuggestions, a1.ownersSuggestions) &&
  _.isEqual(a.enableLastAd, a1.enableLastAd) &&
  _.isEqual(a.lastActivityDateValue, a1.lastActivityDateValue) &&
  _.isEqual(a.singleDate, a1.singleDate) &&
  _.isEqual(a.dateFrom, a1.dateFrom) &&
  _.isEqual(a.dateTo, a1.dateTo) &&
  _.isEqual(a.countrySuggestions, a1.countrySuggestions) &&
  _.isEqual(a.tagsCountries, a1.tagsCountries));

Actions.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  getUrlSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  tagsSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  setTagsFilter: PropTypes.func.isRequired,
  setTags: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.any).isRequired,
  ownersSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  setOwnersFilter: PropTypes.func.isRequired,
  setOwners: PropTypes.func.isRequired,
  owners: PropTypes.arrayOf(PropTypes.any).isRequired,
  setBrandsFilter: PropTypes.func.isRequired,
  setBrands: PropTypes.func.isRequired,
  brands: PropTypes.arrayOf(PropTypes.any).isRequired,
  brandsSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  setMatchFilters: PropTypes.func.isRequired,
  matchFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setContactOwnerFilters: PropTypes.func.isRequired,
  contactOwnerFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setDealOwnerFilters: PropTypes.func.isRequired,
  dealOwnerFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setTargetBrandFilters: PropTypes.func.isRequired,
  targetBrandFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  isInternalFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setIsInternalFilters: PropTypes.func.isRequired,
  inBlacklistFilters: PropTypes.objectOf(PropTypes.any),
  setInBlacklistFilters: PropTypes.func,
  isOppFilters: PropTypes.objectOf(PropTypes.any),
  setIsOppFilters: PropTypes.func,
  // setShowAssignContactOwnerModal: PropTypes.func.isRequired,
  setShowAssignDealOwnerModal: PropTypes.func.isRequired,
  selectedProspectIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  doHubspotImport: PropTypes.func.isRequired,
  disableGlobalImport: PropTypes.bool.isRequired,
  enableLastAd: PropTypes.bool.isRequired,
  setEnableLastAd: PropTypes.func.isRequired,
  lastActivityDateValue: PropTypes.objectOf(PropTypes.any).isRequired,
  setLastActivityDateValue: PropTypes.func.isRequired,
  dateFrom: PropTypes.objectOf(PropTypes.any),
  setDateFrom: PropTypes.func.isRequired,
  dateTo: PropTypes.objectOf(PropTypes.any),
  setDateTo: PropTypes.func.isRequired,
  singleDate: PropTypes.objectOf(PropTypes.any),
  setSingleDate: PropTypes.func.isRequired,
  countrySuggestions: PropTypes.arrayOf(PropTypes.any),
  setTagsCountriesFilter: PropTypes.func.isRequired,
  tagsCountries: PropTypes.arrayOf(PropTypes.any).isRequired,
  setTagsCountries: PropTypes.func.isRequired,
};

Actions.defaultProps = {
  dateFrom: null,
  dateTo: null,
  singleDate: null,
  scoreMin: null,
  scoreMax: null,
  singleScore: null,
  v30Min: null,
  v30Max: null,
  singleV30: null,
  inBlacklistFilters: {},
  setInBlacklistFilters: () => {},
  countrySuggestions: [],
};

export default Actions;
