/* eslint react/prop-types: 0 */
/* eslint react/no-did-mount-set-state: 0 */
/* eslint import/no-dynamic-require: 0 */
/* eslint global-require: 0 */

import React from 'react';
import ErrorBoundary from '../app/error-boundary';
import * as ArrayType from './array';
import * as MixedType from './mixed';

import * as defaultType from './default-type';
import * as boolean from './boolean';
import * as datetime from './datetime';
// import * as richtext from './richtext';
import * as reference from './reference';
import * as textarea from './textarea';

// let globalAny = {};
const types = {
  textarea,
  boolean,
  datetime,
  reference,
  date: datetime,
};

try {
  // globalAny = window;
} catch (error) {
  if (error.message !== 'window is not defined') {
    throw error;
  }
}

export default class PropertyType extends React.Component {
  static DefaultType;

  static Boolean;

  static DateTime;

  static RichText;

  static Reference;

  static TextArea;

  render() {
    // console.log('RENDER PROPERTY', this.props);
    const {
      property,
      resource,
      record,
      filter,
      where,
      error,
      validate,
      onChange,
      enableSaveButton,
    } = this.props;
    console.warn('Do not use that Way. Please Update Table using customColumn field')
    let Component = (types[property.type] && types[property.type][where])
    || defaultType[where];
    try {
      const { portal } = window;
      // console.log(resource, property);
      // console.log(`../../../../containers/${portal}/${resource.navigation.name}/${resource.id}/components/custom/`);
      const a = require(`../../../../containers/${portal}/${resource.navigation.name}/${resource.id}/components/custom/`);
      // console.log(a);
      if (a.default[where] && a.default[where][property.name]) {
        Component = a.default[where][property.name];
        return (
          <ErrorBoundary>
            <Component
              property={property}
              resource={resource}
              record={record}
              filter={filter}
              onChange={onChange}
              enableSaveButton={enableSaveButton}
            />
          </ErrorBoundary>
        );
      }
    } catch (e) {
      console.log('Unable to find custom components', e);
    }
    /* if (property.components && property.components[where] && isClient) {
      const component = property.components[where];
      if (!component) {
        throw new Error(`there is no "${property.name}.components.${where}"`);
      }
      Component = globalAny.AdminBro.UserComponents[component];
      console.log(Component, 'user component');
      return (
        <ErrorBoundary>
          <Component
            property={property}
            resource={resource}
            record={record}
            filter={filter}
            onChange={onChange}
          />
        </ErrorBoundary>
      );
    } */
    const Array = ArrayType[where];
    const Mixed = MixedType[where];


    if (property.isArray) {
      if (!Array) { return (<div />); }
      return (
        <Array
          {...this.props}
          ItemComponent={PropertyType}
        />
      );
    }

    if (property.type === 'mixed' && property.subProperties && property.subProperties.length) {
      if (!Mixed) { return (<div />); }
      return (
        <Mixed
          {...this.props}
          ItemComponent={PropertyType}
        />
      );
    }
    return (
      <ErrorBoundary>
        <Component
          property={property}
          resource={resource}
          record={record}
          filter={filter}
          error={error}
          validate={validate}
          onChange={onChange}
          enableSaveButton={enableSaveButton}
        />
      </ErrorBoundary>
    );
  }
}


function camelizePropertyType(type) {
  return {
    Edit: type.edit,
    Show: type.show,
    List: type.list,
    Filter: type.filter,
  };
}

PropertyType.DefaultType = camelizePropertyType(defaultType);
PropertyType.Boolean = camelizePropertyType(boolean);
PropertyType.DateTime = camelizePropertyType(datetime);
// BasePropertyComponent.RichText = camelizePropertyType(richtext);
PropertyType.Reference = camelizePropertyType(reference);
PropertyType.TextArea = camelizePropertyType(textarea);
