import React, { useEffect, useState, useMemo } from 'react';
import {
  Button,
  Col,
  Row,
  Label,
} from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from 'react-select';
import { axios } from 'ApiClient';
import { NumericFormat as NumberFormat } from 'react-number-format';
import IconButton from '@material-ui/core/IconButton';
import withNotice from '../../../../App/store/with-notice';


const ItemElem = ({
  item, advertisers, setValue, index, removeValue,
}) => {
  const {
    advertiser,
    type,
    cpmCriteria,
    upfrontCriteria,
    hybridCriteria,
    cpaCriteria,
    conversionCriteria,
  } = item;
  const types = [
    { value: 'Upfront CPM', label: 'Upfront CPM' },
    { value: 'Upfront Only', label: 'Upfront Only' },
    { value: 'CPA/Ambassador', label: 'CPA' },
    { value: 'Hybrid', label: 'Hybrid' },
  ];
  const advertiserOptions = useMemo(() => advertisers.filter(b => b.displayInUi === 'Yes').map(a => ({ label: a.companyName, value: a._id })), [advertisers]);

  const selectedType = useMemo(() => types.find(t => t.value === type), [type, types]);
  const selectedAdvertiser = useMemo(() => advertiserOptions.find(t => t.value === advertiser), [advertiser, advertiserOptions]);

  const onChange = (key, value) => {
    setValue({ ...item, [key]: value });
  };

  const isValid = useMemo(() => {
    switch (type) {
      case 'Upfront CPM':
        return Boolean(cpmCriteria);
      case 'Upfront Only':
        return Boolean(upfrontCriteria || conversionCriteria);
      case 'Hybrid':
        return Boolean(hybridCriteria);
      case 'CPA/Ambassador':
        return Boolean(cpaCriteria);
      default:
        return false;
    }
  }, [type, cpmCriteria, upfrontCriteria, hybridCriteria, cpaCriteria, conversionCriteria]);

  return (
    <Row className="mt-3">
      <Col>
        <Label className="my-0">Brand</Label>
        <Select
          value={selectedAdvertiser}
          options={advertiserOptions}
          onChange={value => onChange('advertiser', value.value)}
          placeholder="Select Brand..."
          styles={{
            control: baseStyles => ({
              ...baseStyles,
              height: '38px',
              fontSize: '16px',
            }),
            menu: baseStyles => ({
              ...baseStyles,
              fontSize: '14px',
            }),
          }}
        />
      </Col>
      <Col>
        <Label className="my-0">Deal Type</Label>
        <Select
          value={selectedType}
          options={types}
          onChange={value => onChange('type', value.value)}
          placeholder="Deal Type..."
          styles={{
            control: baseStyles => ({
              ...baseStyles,
              height: '38px',
              fontSize: '16px',
            }),
            menu: baseStyles => ({
              ...baseStyles,
              fontSize: '14px',
            }),
          }}
        />
      </Col>
      <Col sm={5}>
        {selectedType?.value === 'Upfront CPM' && (
          <>
            <Label className={isValid ? 'my-0' : 'my-0 text-danger'}>Number of Deals</Label>
            <NumberFormat
              thousandSeparator
              name="cpmCriteria"
              value={cpmCriteria}
              className="form-control"
              inputMode="numeric"
              step={1}
              min={0}
              allowNegative={false}
              allowLeadingZeros={false}
              onValueChange={(values) => {
                onChange('cpmCriteria', values.floatValue);
              }}
            />
            {cpmCriteria < 1 && (<span className="text-danger">This field is required</span>)}
          </>
          )}
        {selectedType?.value === 'CPA/Ambassador' && (
          <>
            <Label className={isValid ? 'my-0' : 'my-0 text-danger'}>Revenue Value</Label>
            <NumberFormat
              thousandSeparator
              prefix="$"
              name="cpaCriteria"
              value={cpaCriteria}
              className="form-control"
              inputMode="numeric"
              step={1}
              min={0}
              allowNegative={false}
              allowLeadingZeros={false}
              onValueChange={(values) => {
                onChange('cpaCriteria', values.floatValue);
              }}
            />
            {cpmCriteria < 1 && (<span className="text-danger">This field is required</span>)}
          </>
        )}
        {(selectedType?.value === 'Hybrid') && (
          <>
            <Label className={isValid ? 'my-0' : 'my-0 text-danger'}>Percent Recoup</Label>
            <NumberFormat
              thousandSeparator
              suffix="%"
              name="hybridCriteria"
              value={hybridCriteria}
              className="form-control"
              inputMode="numeric"
              step={1}
              min={0}
              allowNegative={false}
              allowLeadingZeros={false}
              onValueChange={(values) => {
                onChange('hybridCriteria', values.floatValue);
              }}
            />
            {hybridCriteria < 1 && (<span className="text-danger">This field is required</span>)}
          </>
        )}
        {(selectedType?.value === 'Upfront Only') && (
          <>
            <Row>
              <Col sm={6}>
                <Label className={isValid ? 'my-0' : 'my-0 text-danger'}>Percent Recoup</Label>
                <NumberFormat
                  thousandSeparator
                  suffix="%"
                  name="upfrontCriteria"
                  value={upfrontCriteria}
                  className="form-control"
                  inputMode="numeric"
                  step={1}
                  min={0}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  onValueChange={(values) => {
                    onChange('upfrontCriteria', values.floatValue);
                  }}
                />
              </Col>
              <Col sm={6}>
                <Label className={isValid ? 'my-0' : 'my-0 text-danger'}>Number of Conversions</Label>
                <NumberFormat
                  thousandSeparator
                  name="conversionCriteria"
                  value={conversionCriteria}
                  className="form-control"
                  inputMode="numeric"
                  step={1}
                  min={0}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  onValueChange={(values) => {
                    onChange('conversionCriteria', values.floatValue);
                  }}
                />
              </Col>
            </Row>
            {!(upfrontCriteria || conversionCriteria) && (<span className="text-danger">It is required to set a positive value to one of this fields.</span>)}
          </>
        )}
      </Col>
      <Col sm="auto" className="pt-3">
        <IconButton
          size="small"
          onClick={() => removeValue(index)}
          className="material-table__toolbar-button mt-3"
        >
          <i className="fa fa-times text-danger" />
        </IconButton>
      </Col>
    </Row>
  );
};

ItemElem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  advertisers: PropTypes.arrayOf(PropTypes.any).isRequired,
  setValue: PropTypes.func.isRequired,
  removeValue: PropTypes.func.isRequired,
  index: PropTypes.string.isRequired,
};

const NewWinnerSettingsComponent = ({ addNotice }) => {
  const [settings, setSettings] = useState({});
  const [defaultSetting, setDefaultSetting] = useState({});
  const [loading, setLoading] = useState(false);
  const [advertisers, setAdvertisers] = useState([]);

  const fetchSettings = async () => {
    try {
      const resp = await axios.get('/api/portal/reports/get-new-winners-report-settings');
      if (resp.data.success) {
        setDefaultSetting(resp.data.defaultSetting || {});
        let i = parseInt(moment().format('x'), 10);
        setSettings((resp.data.settings || []).reduce((acc, item) => {
          acc[i] = item;
          i += 1;
          return acc;
        }, {}));
      }
    } catch (e) {
      console.log(e);
      addNotice({
        message: 'Could not fetch settings',
        type: 'error',
      });
    }
  };

  const fetchAdvertisers = async () => {
    axios.get('/api/portal/finance/get-offers').then((resp) => {
      const brands = {};
      resp.data.offers.forEach((offer) => {
        if (!brands[offer.advertiser.tuneId]) brands[offer.advertiser.tuneId] = { ...offer.advertiser };
      });
      setAdvertisers(Object.values(brands).sort((a, b) => (a.companyName > b.companyName ? 1 : -1)));
    }).catch((error) => {
      console.log('error: ', error);
      addNotice({
        message: 'Could not fetch advertisers list',
        type: 'error',
      });
    });
  };

  useEffect(() => {
    fetchAdvertisers();
    fetchSettings();
  }, []);

  const saveButtonDisabled = useMemo(() => {
    let result = false;
    Object.keys(defaultSetting).forEach((key) => {
      if (!defaultSetting[key]) result = true;
    });
    Object.values(settings).forEach((item) => {
      switch (item.type) {
        case 'Upfront CPM':
          if (!item.cpmCriteria) result = true;
          break;
        case 'Upfront Only':
          if (!(item.upfrontCriteria || item.conversionCriteria)) result = true;
          break;
        case 'Hybrid':
          if (!item.hybridCriteria) result = true;
          break;
        case 'CPA/Ambassador':
          if (!item.cpaCriteria) result = true;
          break;
        default:
          break;
        }
    });
    return result;
  }, [defaultSetting, settings]);

  const saveSettings = async () => {
    try {
      setLoading(true);
      const resp = await axios.post(
        '/api/portal/reports/post-save-new-winners-report-settings',
        { settings: Object.values(settings), defaultSetting },
      );
      if (resp.data.success) {
        addNotice({
          message: 'Settings saved successfully.',
          type: 'success',
        });
      } else {
        addNotice({
          message: 'Could not save settings. Please try again.',
          type: 'error',
        });
      }
    } catch (e) {
      console.log(e);
      addNotice({
        message: 'Could not save settings. Please try again.',
        type: 'error',
      });
    }
    setLoading(false);
  };

  const onChangeDefaultValue = (type, value) => {
    setDefaultSetting(ds => ({ ...ds, [type]: value }));
  };

  const setValue = (
    index,
    {
      advertiser,
      type,
      cpmCriteria,
      upfrontCriteria,
      hybridCriteria,
      cpaCriteria,
      conversionCriteria,
    },
    newValue = false,
  ) => {
    let settingsClone = {
      ...(settings || {}),
    };
    const newSetting = {
      advertiser,
      type,
    };
    switch (type) {
      case 'Upfront CPM':
        newSetting.cpmCriteria = cpmCriteria;
        break;
      case 'Upfront Only':
        newSetting.upfrontCriteria = upfrontCriteria;
        newSetting.conversionCriteria = conversionCriteria;
        break;
      case 'Hybrid':
        newSetting.hybridCriteria = hybridCriteria;
        break;
      case 'CPA/Ambassador':
        newSetting.cpaCriteria = cpaCriteria;
        break;
      default:
        break;
    }
    if (newValue) {
      settingsClone = Object.assign({ [index]: newSetting }, settingsClone);
    } else {
      settingsClone[index] = newSetting;
    }
    setSettings(settingsClone);
  };

  const deleteValue = (index) => {
    console.log(index, settings?.brands);
    const settingsClone = { ...(settings || {}) };
    delete settingsClone[index];
    setSettings(settingsClone);
  };

  return (
    <>
      <h4 className="font-weight-bold mt-3">Default Winner Values</h4>
      <hr/>
      <Row className="align-items-top mb-3">
        <Col>
          <Row className="align-items-top">
            <Col sm="auto" className="pb-3">
              <Label className="mb-0 bold-text pt-2">Upfront CPM</Label>
            </Col>
            <Col>
              <NumberFormat
                thousandSeparator
                name="cpmCriteria"
                value={defaultSetting.cpmCriteria || 0}
                className="form-control"
                inputMode="numeric"
                step={1}
                min={0}
                allowNegative={false}
                allowLeadingZeros={false}
                onValueChange={(values) => {
                  onChangeDefaultValue('cpmCriteria', values.floatValue);
                }}
              />
              <span className={defaultSetting.cpmCriteria ? 'text-secondary' : 'text-danger'}>Number of Deals</span>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="align-items-top">
            <Col sm="auto" className="pb-3">
              <Label className="mb-0 bold-text pt-2">Upfront Only</Label>
            </Col>
            <Col>
              <NumberFormat
                thousandSeparator
                suffix="%"
                name="upfrontCriteria"
                value={defaultSetting.upfrontCriteria || 0}
                className="form-control"
                inputMode="numeric"
                step={1}
                min={0}
                allowNegative={false}
                allowLeadingZeros={false}
                onValueChange={(values) => {
                  onChangeDefaultValue('upfrontCriteria', values.floatValue);
                }}
              />
              <span
                className={(defaultSetting.upfrontCriteria || defaultSetting.conversionCriteria) ? 'text-secondary' : 'text-danger'}
              >
                Percent Recoup
              </span>
              <NumberFormat
                thousandSeparator
                name="conversionCriteria"
                value={defaultSetting.conversionCriteria || 0}
                className="form-control mt-1"
                inputMode="numeric"
                step={1}
                min={0}
                allowNegative={false}
                allowLeadingZeros={false}
                onValueChange={(values) => {
                  onChangeDefaultValue('conversionCriteria', values.floatValue);
                }}
              />
              <span
                className={(defaultSetting.upfrontCriteria || defaultSetting.conversionCriteria) ? 'text-secondary' : 'text-danger'}
              >
                Number of Conversions
              </span>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="align-items-top">
            <Col sm="auto" className="pb-3">
              <Label className="mb-0 bold-text pt-2">CPA</Label>
            </Col>
            <Col>
              <NumberFormat
                thousandSeparator
                prefix="$"
                name="cpaCriteria"
                value={defaultSetting.cpaCriteria || 0}
                className="form-control"
                inputMode="numeric"
                step={1}
                min={0}
                allowNegative={false}
                allowLeadingZeros={false}
                onValueChange={(values) => {
                  onChangeDefaultValue('cpaCriteria', values.floatValue);
                }}
              />
              <span className={defaultSetting.cpaCriteria ? 'text-secondary' : 'text-danger'}>Revenue Value</span>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="align-items-top">
            <Col sm="auto" className="pb-3">
              <Label className="mb-0 bold-text pt-2">Hybrid</Label>
            </Col>
            <Col>
              <NumberFormat
                thousandSeparator
                suffix="%"
                name="hybridCriteria"
                value={defaultSetting.hybridCriteria || 0}
                className="form-control"
                inputMode="numeric"
                step={1}
                min={0}
                allowNegative={false}
                allowLeadingZeros={false}
                onValueChange={(values) => {
                  onChangeDefaultValue('hybridCriteria', values.floatValue);
                }}
              />
              <span className={defaultSetting.hybridCriteria ? 'text-secondary' : 'text-danger'}>Percent Recoup</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <h4 className="font-weight-bold mt-3">Custom Winner Values</h4>
      <hr/>
      <IconButton
        size="small"
        onClick={async () => {
          setValue(
            moment().format('x'),
            {
              advertiser: advertisers[0]._id,
              type: 'Upfront CPM',
              upfrontCriteria: 1,
              conversionCriteria: 1,
            },
            true,
            );
        }}
        className="material-table__toolbar-button"
      >
        <i className="fa fa-plus text-primary"/>
      </IconButton>
      {Object.entries(settings).map(([key, value]) => (
        <ItemElem
          item={value}
          advertisers={advertisers}
          removeValue={deleteValue}
          setValue={e => setValue(key, e)}
          index={key}
          key={key}
        />
      ))}
      <div className="d-flex justify-content-center mt-3">
        <Button
          className="mb-0"
          color="primary"
          onClick={saveSettings}
          disabled={saveButtonDisabled || loading}
        >
          Save
        </Button>
      </div>
    </>
  );
};

NewWinnerSettingsComponent.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(NewWinnerSettingsComponent);
