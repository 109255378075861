import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NoResourceError, NoActionError } from '../../../../shared/components/components/ui/error404';
import ListContainer from './components/ListContainer';

const ResourceAction = (props) => {
  const {
    resources,
    history,
  } = props;
  const [tag, setTag] = useState('');
  const resource = resources.find(r => r.id === 'PilotTracker');
  if (!resource) {
    return (<NoResourceError resourceId="PilotTracker" />);
  }
  const action = resource.resourceActions.find(r => r.name === 'list');
  if (!action) {
    return (<NoActionError resourceId="PilotTracker" actionName="list" />);
  }

  return (
    <ListContainer
      filterVisible
      tag={tag}
      setTag={setTag}
      resource={resource}
      action={action}
      date={new Date()}
      history={history}
    />
  );
};

ResourceAction.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  resources: state.resources,
});

export default connect(mapStateToProps)(ResourceAction);
