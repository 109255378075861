import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, CardBody, Button, ButtonToolbar, FormGroup, Label, Input, Spinner } from 'reactstrap';
import SelectAsync from 'react-select/async';
import Select from 'react-select';
import { uid } from 'react-uid';
import { axios } from 'ApiClient';
import IconOk from 'mdi-react/CheckboxMarkedIcon';
import IconAlert from 'mdi-react/AlertCircleIcon';
import RefreshIcon from 'mdi-react/RefreshIcon';
import Docusign from './EngineData/Docusign';
import Dropbox from './EngineData/Dropbox';
import CustomFieldInfo from './CustomField/Info';
import Breadcrumb from '../../../../../shared/components/BreadCrumbs';
import CheckBoxField from '../../../../../shared/components/CheckBox';
import withNotice from '../../../../App/store/with-notice';
import WVFormatter from '../../../../../shared/helpers/WVFormatter';
import WVValidator from '../../../../../shared/helpers/WVValidator';
import { splitString, fixInputCursor } from '../../../../../shared/helpers/Form';
import { standardFieldKey, customFieldKey } from '../../../../../shared/helpers/models/ServiceAgreement';
import { CustomInput, standardFields } from '../../../../../shared/helpers/models/CustomAgreementTemplate';
import Alert from '../../../../../shared/easydev2/shared/components/Alert';
import WarningModal from '../../../../../shared/components/modals/Warning';

class ServiceAgreementForm extends React.Component {
  static propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    addNotice: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingDeal: false,
      loadingTemplates: false,
      creatorValue: null,
      docusignSettings: {},
      dsTemplates: {},
      dropboxTemplates: [],
      templateData: {},
      dsTemplateId: null,
      keenDsTemplateId: null,
      customAgreementTemplateId: null,
      influencerId: null,
      creatorName: '',
      creatorContact: '',
      creatorEmail: '',
      influencerEmail: '',
      company: '',
      firstName: '',
      lastName: '',
      tuneId: '',
      optDeals: [],
      oDeals: {},
      customTemplatesAll: [],
      customTemplates: {},
      dealId: null,
      keepDeal: false,
      dealOk: false,
      isCreatorBrandApproved: false,
      agreementType: null,
      isKeenDeal: false,
      brandId: null,
      offerId: null,
      brandName: { value: '', class: null },
      offerName: { value: '', class: null },
      dealType: { value: '', class: null },
      guarantAmount: { value: null, class: null },
      cpaConversion: { value: null, class: null },
      cpaPercentage: { value: null, class: null },
      cpaText: '',
      compensationText: '',
      creatorChannel: '',
      creatorUrl: '',
      additionalTerms: '',
      customData: {},
      sendKeenAgreement: '',
      notSendCreatorAgreement: '',
      facebookAccess: '',
      exclusivity: '',
      agents: [],
      agentContact: '',
      agentEmail: '',
      agency: '',
      agencyName: '',
      sendTo: null,
      sendToOptions: [],
      errors: {},
      disableSending: false,
      showWarning: false,
      updatingPAC: false,
      engine: null,
      embeddedSigning: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  onChangeInfluencer = (option) => {
    const { keepDeal } = this.state;
    this.setState({
      creatorValue: option,
      influencerId: option.value,
      loading: true,
    });
    axios.get(`/api/portal/influencer-deals?influencerId=${option.value}`).then((response) => {
      if (response.data.success) {
        const {
          contact,
          deals,
          influencerName,
          firstName,
          lastName,
          influencerEmail,
          influencerCompany,
          tuneId,
        } = response.data;
        const { dealId } = keepDeal ? this.state : response.data;
        const oDeals = {};
        const optDeals = deals.map((row) => {
          oDeals[row._id] = row;
          const dealDate = WVFormatter.getFormatedDate(row.createdAt);
          return {
            value: row._id,
            label: (`${row.dealname}  (${dealDate})` || `${row._id}  (${dealDate})`),
          };
        });
        if (!keepDeal) {
          this.setState({
            creatorName: influencerName ? influencerName.toString() : '',
            creatorContact: influencerName ? influencerName.toString() : '',
            creatorEmail: influencerEmail,
            company: influencerCompany || '',
            creatorChannel: (contact && contact.mediaChannels && contact.mediaChannels.youtube_channel_url) ? contact.mediaChannels.youtube_channel_url : '',
          });
        }
        this.setState({
          optDeals,
          oDeals,
          firstName,
          lastName,
          influencerEmail,
          tuneId,
          loading: false,
        });
        this.onChangeDeal({ value: dealId });
      }
    }).catch((error) => {
      console.log(error);
      this.setState({ loading: false });
    });
  }

  matchCustomTemplate = (t, agencyIds, agents, dealType) => (
    !agencyIds?.length > 0
    || agents.filter(a => a.agency?._id && agencyIds.includes(a.agency._id)).length > 0
  ) && dealType.value && t.dealTypes.includes(dealType.value)
  maxLength = key => (this.state.templateData[this.state.dsTemplateId]?.customFields?.[key]?.numCharsPerLine || null)
  maxLines = key => (this.state.templateData[this.state.dsTemplateId]?.customFields?.[key]?.numLines || 1)

  customFields = (agents, dealType) => {
    const { customTemplatesAll } = this.state;
    const dropboxTemplates = [];
    const customTemplates = {};
    customTemplatesAll.forEach((t) => {
      const {
        templateName,
        dropboxTemplateId,
        agencies,
        customFields,
      } = t;
      if (this.matchCustomTemplate(t, agencies?.map(a => a._id), agents, dealType)) {
        dropboxTemplates.push({ value: t._id, label: `${templateName || t._id} (Dropbox)`, engine: 'dropbox', agencies });
      }
      customTemplates[t._id] = {
        id: t._id,
        dropboxTemplateId,
        templateName,
        agencyIds: agencies.map(a => a._id),
        agencyNames: agencies.map(a => a.agencyName),
        customFields: {},
      };
      customFields.forEach((f) => {
        customTemplates[t._id].customFields[f.customDataField] = f;
      });
    });
    return { dropboxTemplates, customTemplates };
  }

  onChangeDeal = async (option) => {
    let dealOk = true;
    let brandId = null;
    let offerId = null;
    let brandName = { value: '', class: null };
    let offerName = { value: '', class: null };
    let dealType = { value: '', class: null };
    let guarantAmount = { value: null, class: null };
    let cpaConversion = { value: null, class: null };
    let cpaPercentage = { value: null, class: null };
    let agents = [];
    let disableSending = false;
    const {
      dsTemplates,
      keepDeal,
      agreementType,
      // creatorEmail,
    } = this.state;
    const st = { loadingDeal: true };
    if (keepDeal) {
      st.keepDeal = false;
    }
    this.setState(st);
    if (option.value) {
      const {
        oDeals,
        id,
        creatorUrl,
        compensationText,
        dsTemplateId,
        customAgreementTemplateId,
        engine,
      } = this.state;
      const oDeal = oDeals[option.value];
      this.setState({
        isKeenDeal: /(^|\W)keen(\W|$)/i.test(oDeal.dealname),
      });
      if (oDeal.advertisers.length) {
        brandId = oDeal.advertisers[0]._id;
        brandName = { value: oDeal.advertisers[0].companyName, class: null };
      } else {
        brandName = { value: 'Missing Brand', class: 'label-error' };
        dealOk = false;
      }
      if (oDeal.offers.length) {
        offerId = oDeal.offers[0]._id;
        offerName = { value: oDeal.offers[0].name, class: null };
      } else {
        offerName = { value: 'Missing Offer', class: 'label-error' };
        dealOk = false;
      }
      if (typeof oDeal.dealTerms !== 'undefined') {
        if (typeof oDeal.dealTerms.type_of_deal !== 'undefined' && oDeal.dealTerms.type_of_deal) {
          dealType = { value: oDeal.dealTerms.type_of_deal, class: null };
        } else {
          dealType = { value: 'Missing Deal Type', class: 'label-error' };
          dealOk = false;
        }
        if (dealType.value && ['Upfront Only', 'Upfront CPM', 'Hybrid', 'Bonus'].includes(dealType.value)) {
          if (typeof oDeal.dealTerms.guaranteed_payment_contract_amount !== 'undefined' && oDeal.dealTerms.guaranteed_payment_contract_amount) {
            guarantAmount = { value: WVFormatter.formatCurrency(oDeal.dealTerms.guaranteed_payment_contract_amount), class: null };
            if (!compensationText) this.setState({ compensationText: guarantAmount.value });
          } else {
            guarantAmount = { value: 'Missing Guaranteed Payment', class: 'label-error' };
            dealOk = false;
          }
        }
        if (dealType.value && ['CPA/Ambassador', 'Hybrid'].includes(dealType.value)) {
          if (typeof oDeal.dealTerms.cpa_percentage !== 'undefined' && oDeal.dealTerms.cpa_percentage) {
            cpaPercentage = { value: `${oDeal.dealTerms.cpa_percentage}%`, class: null };
          } else if (typeof oDeal.dealTerms.per_conversion_amount !== 'undefined' && oDeal.dealTerms.per_conversion_amount) {
            cpaConversion = { value: WVFormatter.formatCurrency(oDeal.dealTerms.per_conversion_amount), class: null };
          } else {
            cpaConversion = { value: 'Missing Conversion or Percentage Amount', class: 'label-error' };
            dealOk = false;
          }
        }
        if (dealType.value !== 'Media License') {
          if (!id && !creatorUrl && oDeal.dealTerms.referral_url) {
            this.setState({ creatorUrl: oDeal.dealTerms.referral_url });
          }
        }
        if (oDeal.ilDealData && oDeal.ilDealData.agentUsers.length > 0) {
          const response = await axios.get('/api/portal/agent/get-data', { params: { ids: oDeal.ilDealData.agentUsers, includes: ['hubilagents', 'agencies'] } });
          if (response.data.success) {
            agents = response.data.agents;
          }
        }
      } else {
        dealOk = false;
      }
      if (!dealOk) {
        disableSending = true;
      }
      let isCreatorBrandApproved = oDeal.authorizedPayment?.creatorBrandApproved;
      /* if (isCreatorBrandApproved !== false) {
        const response = await axios.get(`/api/portal/prospects/check-brand-approved?brandId=${brandId}&email=${encodeURIComponent(creatorEmail)}`);
        if (response.data.success) {
          isCreatorBrandApproved = response.data.approved;
        }
      } */
      // console.log(oDeal, 'oDeal')
      if (!oDeal.authorizedPayment?.creatorBrandApproved || typeof oDeal.authorizedPayment?.creatorBrandApproved === 'undefined') {
        this.setState({ updatingPAC: true });
        await axios.post('/api/portal/finance/post-deal-validation-prefill', { ids: [oDeal?._id] });

        const data = await axios.get(`/api/resources/HubilDeal/records/${oDeal?._id}/show`);
        if(data.data.record) {
          isCreatorBrandApproved = data.data.record.params['authorizedPayment.creatorBrandApproved'];
          // console.log(data.data.record.params['authorizedPayment.creatorBrandApproved']);

        }
      }
      const { dropboxTemplates, customTemplates } = this.customFields(agents, dealType);
      this.setState({
        isCreatorBrandApproved,
        updatingPAC: false,
        dropboxTemplates,
        customTemplates,
      });
      const defaultTemplate = dropboxTemplates.find(r => r.agencies?.length > 0) || dropboxTemplates[0];
      const defaultTemplateValue = defaultTemplate ? defaultTemplate.value : dsTemplates?.[dealType.value]?.default;
      const templateEngine = id ? engine : (defaultTemplate ? 'dropbox' : null);
      this.onChangeAgreementVersion({
        value: id ? (customTemplates?.[customAgreementTemplateId] ?  customAgreementTemplateId : dsTemplateId) : defaultTemplateValue,
        engine: templateEngine,
      }, {
        customTemplates,
        agents,
      });
    }
    this.setState({
      loadingDeal: false,
      dealId: option.value,
      dealOk,
      brandId,
      offerId,
      brandName,
      offerName,
      dealType,
      guarantAmount,
      cpaConversion,
      cpaPercentage,
      agents,
      disableSending,
    });
    if (!keepDeal && agreementType !== 'keen') {
      this.setState({
        keenDsTemplateId: dsTemplates?.Keen?.default,
      });
    }
  }

  engineData = (engine) => {
    return engine === 'dropbox' ? Dropbox : Docusign;
  }

  showField = (key) => {
    const {
      engine,
      dealType,
      dsTemplateId,
      templateData,
      agents,
    } = this.state;
    const hasAgents = agents.length > 0;
    const map = {
      AgentContact: hasAgents,
      AgentEmail: hasAgents,
      Agency: hasAgents,
    };
    if (map[key] === false) return false;
    return this.engineData(engine).showField({
      key,
      inTemplate: !!templateData?.[dsTemplateId]?.customFields?.[key],
      dealType: dealType?.value,
    });
  }

  characterLimitMessage = ({ numCharsPerLine, numLines, exceeded }) => {
    const limits = [];
    if (numCharsPerLine) {
      limits.push(numCharsPerLine);
      if (numLines > 1) limits.push(` per line,`);
    }
    if (numLines > 1 || !numCharsPerLine) {
      limits.push(`${numLines} line${WVFormatter.s(numLines)}`);
    }
    return `Character limit${exceeded ? ' exceeded' : ''}: ${limits.join(' ')}`;
  }

  checkFieldLength = (key, value) => {
    const { engine, templateData, dsTemplateId } = this.state;
    if (!(engine === 'dropbox' && templateData[dsTemplateId]?.customFields)) {
      return null;
    }
    const { customTemplates, customAgreementTemplateId } = this.state;
    if (!(standardFields[key] || ['text', 'textarea'].includes(customTemplates?.[customAgreementTemplateId]?.customFields?.[key]?.dataType))) {
      return null;
    }
    const { customFields } = templateData[dsTemplateId];
    const lines = value?.split(/\r?\n/);
    if (lines?.length && customFields[key]) {
      const { numLines, numCharsPerLine } = customFields[key];
      if (numLines && lines.length > numLines) {
        return this.characterLimitMessage({ numLines, exceeded: 1 });
      } else if (numCharsPerLine && lines.find(s => s.length > numCharsPerLine)) {
        return this.characterLimitMessage({ numCharsPerLine, numLines, exceeded: 1 });
      }
    }
    return null;
  }

  onChangeInput = (event) => {
    const { name, value, selectionStart } = event.target;
    const cf = customFieldKey(name);
    const val = (this.maxLines(cf) > 1) ? splitString(value, this.maxLength(cf)) : value;
    this.setState({
      [name]: val,
      errors: { ...this.state.errors, [name]: this.checkFieldLength(cf, val) },
    }, () => {
      if (val !== value) fixInputCursor(event.target, selectionStart);
    });
  }

  onChangeSendTo = (option) => {
    const { agents } = this.state;
    const agent = option.value ? agents.find(r => r._id === option.value) : null;
    this.setState({
      sendTo: option.value,
      agentContact: agent ? `${agent.first_name} ${agent.last_name}` : '',
      agentEmail: agent?.email || '',
      agencyName: agent?.agency?.agencyName || '',
      errors: {
        ...this.state.errors,
        sendTo: undefined,
      },
    });
  }

  onChangeCustomField = (name, value, after = () => {}) => {
    const {
      customAgreementTemplateId,
      customTemplates,
      customData,
      errors,
    } = this.state;
    const { dataType, readOnly } = customTemplates[customAgreementTemplateId].customFields[name];
    this.setState({
      customData: { ...customData, [name]: {
        value,
        dataType,
        readOnly,
      } },
      errors: { ...errors, [name]: this.checkFieldLength(customFieldKey(name), value) },
    }, after);
  }

  onChangeCheckbox = (event) => {
    if (event && event.target) {
      const { checked, name } = event.target;
      this.setState({ [name]: checked });
    }
  }

  fetchTemplate = async (id) => {
    const { templateData } = this.state;
    if (!templateData[id]) {
      axios.get('/api/portal/agreement/get-template', { params: { id } }).then((res) => {
        this.setState({
          templateData: {
            ...templateData,
            [id]: {
              customFields: res.data.template?.customFields,
            }
          }
        });
      });
    }
  }

  onChangeAgreementVersion = (e, params) => {
    const { engine, value } = e;
    const { id, agents, customTemplates } = this.state;
    const t = params?.customTemplates || customTemplates;
    const a = params?.agents || agents;
    const customTemplate = t[value];
    const dsTemplateId = customTemplate?.dropboxTemplateId || value;
    const { agencyIds } = customTemplate || {};
    const sendToOptions = a.filter(r => !agencyIds || agencyIds.length === 0 || (r.agency?._id && agencyIds.includes(r.agency?._id))).map(r => ({
      value: r._id,
      label: `${r.first_name} ${r.last_name} (${r.email})`,
    }));
    const data = {
      dsTemplateId,
      customAgreementTemplateId: customTemplate?.id,
      engine,
      sendToOptions: sendToOptions.sort((a, b) => (a.label > b.label ? 1 : -1)),
      agency: this.engineData(engine).agencyName({
        agents: a,
        customTemplate,
      }),
    };
    if (dsTemplateId && engine === 'dropbox') {
      this.fetchTemplate(dsTemplateId);
    }
    if (!id || typeof params === 'undefined') {
      const sendTo = (!id && sendToOptions.length === 1) ? sendToOptions[0].value : null;
      const agent = sendTo ? a.find(r => r._id === sendTo) : null;
      data.sendTo = sendTo;
      data.agentContact =  agent ? `${agent.first_name} ${agent.last_name}` : '';
      data.agentEmail = agent?.email || '';
      data.agencyName = agent?.agency?.agencyName || '';
      data.customData = {};
    }
    this.setState(data);
  };

  onChangeKeenAgreementVersion = (e) => {
    this.setState({ keenDsTemplateId: e.value });
  };

  onCancel = () => {
    this.props.history.goBack();
  }

  groupTemplates = (docusignTemplates, docusignSettings) => {
    const _docusignSettings = docusignSettings || this.state.docusignSettings;
    const fields = [
      { key: 'cpaServiceAgreementTemplateId', dealtype: 'CPA/Ambassador' },
      { key: 'upfrontServiceAgreementTemplateId', dealtype: 'Upfront Only' },
      { key: 'upfrontCpmServiceAgreementTemplateId', dealtype: 'Upfront CPM' },
      { key: 'hybridServiceAgreementTemplateId', dealtype: 'Hybrid' },
      { key: 'keenServiceProviderAgreement', dealtype: 'Keen' },
      { key: 'medialicenseServiceAgreement', dealtype: 'Media License' },
      { key: 'bonusServiceAgreementTemplateId', dealtype: 'Bonus' },
    ];
    const dsTemplates = {};
    fields.forEach((row) => {
      const listKey = `${row.key}s`;
      dsTemplates[row.dealtype] = {
        default: _docusignSettings[row.key],
        list: _docusignSettings[listKey] ? docusignTemplates.filter(opt => _docusignSettings[listKey].includes(opt.value)).map(r => ({ ...r, label: `${r.label} (DocuSign)` })) : [],
      };
    });
    return dsTemplates;
  }

  fetchData() {
    const { id } = this.props.match.params;
    this.setState({ id, loading: true });
    let { influencerId } = this.props.match.params;
    let apiUrl = '/api/portal/service-agreement-options';
    if (id) {
      apiUrl += `?id=${id}`;
    } else if (influencerId) {
      apiUrl += `?influencerId=${influencerId}`;
    }
    axios.get(apiUrl).then((response) => {
      if (response.data.success) {
        const { optInfluencers, docusignTemplates, docusignSettings, customTemplates } = response.data;
        if (id) {
          influencerId = response.data.serviceAgreement.influencer;
          const {
            deal,
            cpaText,
            compensationText,
            creatorName,
            creatorContact,
            creatorEmail,
            company,
            agentContact,
            agentEmail,
            agencyName,
            creatorChannel,
            creatorUrl,
            additionalTerms,
            customData,
            facebookAccess,
            exclusivity,
            dsTemplateId,
            customAgreementTemplate,
            sendTo,
            type,
            engine,
          } = response.data.serviceAgreement;
          this.setState({
            dealId: deal,
            keepDeal: true,
            cpaText: (typeof cpaText !== 'undefined') ? cpaText : '',
            compensationText: (typeof compensationText !== 'undefined') ? compensationText : '',
            creatorName: (typeof creatorName !== 'undefined') ? creatorName : '',
            creatorContact: (typeof creatorContact !== 'undefined') ? creatorContact : '',
            creatorEmail: (typeof creatorEmail !== 'undefined') ? creatorEmail : '',
            company: (typeof company !== 'undefined') ? company : '',
            creatorChannel: (typeof creatorChannel !== 'undefined') ? creatorChannel : '',
            creatorUrl: (typeof creatorUrl !== 'undefined') ? creatorUrl : '',
            additionalTerms: (typeof additionalTerms !== 'undefined') ? additionalTerms : '',
            customData,
            facebookAccess: facebookAccess ? 'on' : '',
            exclusivity: exclusivity ? 'on' : '',
            dsTemplateId,
            customAgreementTemplateId: customAgreementTemplate,
            sendTo,
            agentContact,
            agentEmail,
            engine,
            agencyName,
            agreementType: type,
          });
        }
        this.setState({
          dsTemplates: this.groupTemplates(docusignTemplates, docusignSettings),
          docusignSettings,
          customTemplatesAll: customTemplates,
        });
        const influencerOptions = optInfluencers.filter(option => option.value === influencerId);
        this.onChangeInfluencer((influencerOptions?.length > 0) ? influencerOptions[0] : { value: influencerId });
      }
    }).catch((error) => {
      console.log(error);
      this.setState({ loading: false });
    });
  }

  refreshTemplates = () => {
    this.setState({ loadingTemplates: true });
    axios.get('/api/portal/agreement/list-templates?platform=docusign&refresh[]=docusign&refresh[]=dropbox').then((res) => {
      const st = { loadingTemplates: false };
      if (res.data.success) {
        st.dsTemplates = this.groupTemplates(Object.values(res.data.templates).map(r => ({ label: r.label, value: r.value })));
      }
      this.setState(st);
    });
  }

  loadInfluencers = async (query) => {
    if (query?.length < 2) return [];
    const res = await axios.get(`/api/portal/influencer/get-options?query=${query}`);
    return res.data.success ? res.data.data : [];
  }

  validateErrors() {
    const errors = {};
    const {
      dealId,
      cpaText,
      compensationText,
      creatorEmail,
      creatorChannel,
      creatorUrl,
      notSendCreatorAgreement,
      sendKeenAgreement,
      customData,
      customTemplates,
      customAgreementTemplateId,
      dsTemplateId,
      engine,
      sendTo,
      sendToOptions,
      templateData,
    } = this.state;
    if (WVValidator.isEmpty(dealId)) {
      errors.dealId = 'Please select Deal.';
    }
    if (!(WVValidator.isEmpty(creatorEmail) || WVValidator.isEmail(creatorEmail))) {
      errors.creatorEmail = 'Please fill a valid email address.';
    }
    if (engine === 'dropbox' && sendToOptions?.length && !sendTo) {
      errors.sendTo = 'Please select.';
    }
    if (this.showField('CreatorLink') && WVValidator.isEmpty(creatorUrl)) {
      errors.creatorUrl = 'Please fill Creator Vanity URL.';
    }
    if (this.showField('CPA') && WVValidator.isEmpty(cpaText)) {
      errors.cpaText = 'Please fill CPA.';
    }
    if (this.showField('Compensation') && WVValidator.isEmpty(compensationText)) {
      errors.compensationText = 'Please fill Compensation.';
    }
    if (this.showField('Channel') && WVValidator.isEmpty(creatorChannel)) {
      errors.creatorChannel = 'Please fill Creator Channel.';
    }
    if (notSendCreatorAgreement && !sendKeenAgreement) {
      errors.notSendCreatorAgreement = 'Please check what agreement to send';
    }
    if (engine === 'dropbox' && templateData[dsTemplateId]?.customFields) {
      const { customFields } = templateData[dsTemplateId];
      let key, localKey;
      for (key in customFields) {
        localKey = standardFieldKey(key);
        errors[localKey || key] ??= this.checkFieldLength(key, localKey ? this.state[localKey] : this.state.customData?.[key]?.value);
      }
    }

    if (customTemplates[customAgreementTemplateId]?.customFields) {
      Object.keys(customTemplates[customAgreementTemplateId].customFields).forEach((k) => {
        if (this.showField(k) && customTemplates[customAgreementTemplateId].customFields[k].required && !customData?.[k]?.value) {
          errors[k] = `Please fill ${customTemplates[customAgreementTemplateId].customFields[k].ilUILabel}`;
        }
      });
    }

    this.setState({ errors });
    return Object.values(errors).filter(e => e).length === 0;
  }

  submit = () => {
    if (!this.validateErrors()) { return false; }
    const {
      loading,
      id,
      influencerId,
      dealId,
      dealType,
      offerId,
      creatorName,
      creatorContact,
      creatorEmail,
      company,
      cpaText,
      compensationText,
      creatorChannel,
      creatorUrl,
      additionalTerms,
      customData,
      sendKeenAgreement,
      notSendCreatorAgreement,
      facebookAccess,
      exclusivity,
      agents,
      sendTo,
      dsTemplateId,
      keenDsTemplateId,
      customAgreementTemplateId,
      engine,
      embeddedSigning,
      agentContact,
      agentEmail,
      agencyName,
    } = this.state;
    if (loading) { return false; }
    this.setState({ loading: true });
    const data = {
      influencerId,
      dealId,
      dealType: dealType.value,
      offerId,
      creatorName,
      creatorContact,
      creatorEmail,
      company,
      cpaText,
      compensationText,
      creatorChannel,
      creatorUrl,
      additionalTerms,
      customData,
      sendKeenAgreement,
      notSendCreatorAgreement,
      facebookAccess,
      exclusivity,
      agents: this.engineData(engine).agents({ agents, sendTo }),
      sendTo,
      dsTemplateId,
      keenDsTemplateId,
      customAgreementTemplateId,
      embeddedSigning,
      agentContact,
      agentEmail,
      agencyName,
    };
    if (id) {
      data.id = id;
    }
    if (engine) {
      data.engine = engine;
    }
    axios({
      method: 'post',
      url: '/api/portal/service-agreement',
      data,
    }).then((response) => {
      if (response.data.success) {
        this.props.addNotice({
          message: `Agreement has been ${id ? 'updated' : 'sent'}.`,
          type: 'success',
        });
        this.props.history.goBack();
      } else {
        this.setState({ loading: false });
        this.props.addNotice({
          message: response.data.error,
          type: 'error',
        });
      }
    }).catch((error) => {
      this.setState({ loading: false });
      this.props.addNotice({
        message: error.message,
        type: 'error',
      });
    });
    return true;
  }

  showEmbeddedSigning = () => {
    const { teams } = window.REDUX_STATE.session;
    return teams && teams.length > 0 && (teams.includes('super_powers') || teams.includes('management') || teams.includes('engineering'));
  }

  render() {
    const {
      loading,
      loadingDeal,
      loadingTemplates,
      creatorValue,
      docusignSettings,
      dsTemplates,
      dropboxTemplates,
      dsTemplateId,
      keenDsTemplateId,
      customAgreementTemplateId,
      id,
      creatorName,
      creatorContact,
      creatorEmail,
      influencerEmail,
      company,
      firstName,
      lastName,
      tuneId,
      optDeals,
      dealId,
      dealOk,
      isCreatorBrandApproved,
      agreementType,
      isKeenDeal,
      brandId,
      offerId,
      brandName,
      offerName,
      dealType,
      guarantAmount,
      cpaConversion,
      cpaPercentage,
      cpaText,
      compensationText,
      creatorChannel,
      creatorUrl,
      additionalTerms,
      customData,
      customTemplates,
      sendKeenAgreement,
      notSendCreatorAgreement,
      facebookAccess,
      exclusivity,
      agentContact,
      agentEmail,
      agency,
      agencyName,
      sendTo,
      sendToOptions,
      errors,
      disableSending,
      showWarning,
      updatingPAC,
      engine,
      embeddedSigning,
    } = this.state;
    const titlePrefix = id ? 'Edit' : 'Send';
    const missedNameParts = [];
    if (!firstName || (firstName.toLowerCase() === 'null')) {
      missedNameParts.push('first');
    }
    if (!lastName || (lastName.toLowerCase() === 'null')) {
      missedNameParts.push('last');
    }
    const strNameParts = missedNameParts.join(' and ');
    const allowByName = (missedNameParts.length === 0) || id;
    const docusignTemplates = (agreementType === 'keen') ? dsTemplates.Keen : dsTemplates[dealType.value];
    const l = docusignTemplates?.list || [];
    const agreementTemplates = [...l, ...dropboxTemplates];
    const keenDocusignTemplates = dsTemplates.Keen;

    const charLimitMessage = (key) => (!errors[standardFieldKey(key) || key] && !!this.maxLength(key)) && (
      <small style={{ color: '#999' }}>
        {this.characterLimitMessage({
          numCharsPerLine: this.maxLength(key),
          numLines: this.maxLines(key),
        })}
      </small>
    );

    return (
      <Container className="dashboard">
        <Breadcrumb
          links={[
            { title: 'Home', path: '/' },
            { title: 'Creators', path: '/resources/Influencer/actions/list' },
            { title: `${titlePrefix} Service Agreement`, path: null },
          ]}
          isBackButton
        />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <h4 className="border-bottom">{`${titlePrefix} Creator Service Agreement`}</h4>
                {loading && (<div className="text-center"><Spinner color="primary" size="lg" /></div>)}
                {!loading && (
                  <Row>
                    <Col>
                      <Row>
                        <Col sm={{ size: 6, offset: 3 }}>
                          <FormGroup>
                            <Label>Creator</Label>
                            <SelectAsync
                              cacheOptions
                              value={creatorValue}
                              defaultOptions
                              loadOptions={this.loadInfluencers}
                              onChange={this.onChangeInfluencer}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {!allowByName && (
                      <Alert color="warning">
                        <p className="py-2 text-dark">
                          This Creator is missing a {strNameParts} name. Both first and last name are required to send Agreements.
                          {tuneId && (
                            <span style={{ display: 'block' }}>
                              Click <a href={`https://influencelogic.hasoffers.com/admin/affiliates/view/${tuneId}`} className="font-weight-bold text-primary" target="_blank" rel="noopener noreferrer">here</a> to
                              specify and save names in HasOffers then use <b>Refresh &gt; HasOffers Data</b> to synchronize to the Portal.
                            </span>
                          )}
                        </p>
                      </Alert>
                      )}
                      {allowByName && (
                        <Row>
                          <Col sm={{ size: 6, offset: 3 }}>
                            <FormGroup className="pb-4">
                              <Row>
                                <Col sm={6}>
                                  <Label>Deal</Label>
                                </Col>
                                <Col sm={6}>
                                  {dealId && (
                                    <a href={`/influencers/creator-deal-editor/${dealId}`} style={{ float: 'right' }} target="_blank" rel="noopener noreferrer">
                                      View Deal&nbsp;&nbsp;
                                      <i className="fas fa-external-link-alt" />
                                    </a>
                                  )}
                                </Col>
                              </Row>
                              <Select
                                value={optDeals.filter(option => option.value === dealId)}
                                options={optDeals}
                                onChange={this.onChangeDeal}
                              />
                              {errors.dealId && (<div className="form__form-group-error">{errors.dealId}</div>)}
                            </FormGroup>
                          </Col>
                          <Col sm="3">
                            <Row>
                              <Col className="d-none d-sm-block">&nbsp;</Col>
                            </Row>
                            <Row>
                              <Col className="d-none d-sm-block" style={{ paddingLeft: 0, marginTop: '3px' }}>
                                {dealId && dealOk && !disableSending && (
                                  <IconOk style={{ width: '46px', height: '46px', color: 'green' }} />
                                )}
                                {dealId && (!dealOk || disableSending) && (
                                  <IconAlert style={{ width: '46px', height: '46px', color: 'red' }} />
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                      {loadingDeal && (
                        (<div className="text-center"><Spinner color="primary" size="lg" /></div>)
                      )}
                      {!loadingDeal && dealId && (
                        <Row>
                          <Col sm={{ size: 6, offset: 3 }} style={{ marginBottom: '20px' }}>
                            <h4 className="border-bottom">Template Settings</h4>
                            <Row>
                              <Col sm="5" className="bold-text">Deal Type</Col>
                              <Col sm="7">{dealType.value}</Col>
                            </Row>
                            {agency && (
                              <Row>
                                <Col sm="5" className="bold-text">Agency</Col>
                                <Col sm="7">{agency}</Col>
                              </Row>
                            )}
                          </Col>
                        </Row>
                      )}
                      {!loadingDeal && updatingPAC && (
                        (<div className="text-center"><Spinner color="primary" size="lg" /></div>)
                      )}
                      {!loadingDeal && !isCreatorBrandApproved && dealId && !updatingPAC && (
                        <Alert color="danger" className="col-sm-6 offset-sm-3">
                          <p className="py-2 text-dark">
                            An approved Prospect record for {brandName.value} needs to exist in order to send a Service Agreement.
                          </p>
                        </Alert>
                      )}
                      {!loadingDeal && allowByName && dealId && isCreatorBrandApproved && !updatingPAC && (
                        <Row>
                          <Col>
                            <Row>
                              <Col sm={{ size: 6, offset: 3 }}>
                                {agreementTemplates && (!notSendCreatorAgreement || id) && (
                                  <FormGroup>
                                    <Label>Service Agreement Template</Label>
                                    <Row>
                                      <Col md={docusignSettings.enableCreatorPortal ? 11 : 12}>
                                        <Select
                                          value={
                                            agreementTemplates.find(option => option.value === (customAgreementTemplateId || dsTemplateId))
                                            || (customAgreementTemplateId && customTemplates?.[customAgreementTemplateId]?.templateName ? {
                                              label: customTemplates[customAgreementTemplateId].templateName,
                                            } : null)
                                          }
                                          options={agreementTemplates}
                                          onChange={(o) => { this.onChangeAgreementVersion(o) }}
                                          isDisabled={disableSending || loadingTemplates}
                                        />
                                      </Col>
                                      {docusignSettings.enableCreatorPortal && (
                                        <Col md="1">
                                          <RefreshIcon style={{ width: '20px', height: '20px', color: '#007bff', marginTop: '8px' }} onClick={this.refreshTemplates} />
                                        </Col>
                                      )}
                                    </Row>
                                  </FormGroup>
                                )}
                                {keenDocusignTemplates && isKeenDeal && sendKeenAgreement && (
                                  <FormGroup style={{ marginTop: '12px' }}>
                                    <Label>Keen Agreement Version</Label>
                                    <Select
                                      value={keenDocusignTemplates.list.filter(option => option.value === keenDsTemplateId)}
                                      options={keenDocusignTemplates.list}
                                      onChange={this.onChangeKeenAgreementVersion}
                                      isDisabled={disableSending || loadingTemplates}
                                    />
                                  </FormGroup>
                                )}
                                {engine === 'dropbox' && (
                                  <FormGroup>
                                    <Label>Send To (Dropbox Email Signing)</Label>
                                    <Select
                                      value={sendToOptions.find(o => o.value === sendTo) || (!sendToOptions?.length && {
                                        label: `${firstName} ${lastName} (${influencerEmail})`
                                      })}
                                      options={sendToOptions}
                                      onChange={this.onChangeSendTo}
                                      isDisabled={disableSending}
                                    />
                                  {errors.sendTo && (<div className="form__form-group-error mt-0 pb-3">{errors.sendTo}</div>)}
                                  </FormGroup>
                                )}
                                <h4 className="border-bottom">Standard Template Fields</h4>
                                <Row>
                                  <Col md="5" className="bold-text">Brand</Col>
                                  <Col md="7" className={brandName.class}>{brandName.value}</Col>
                                </Row>
                                <Row>
                                  <Col md="5" className="bold-text">Offer</Col>
                                  <Col md="7" className={offerName.class}>{offerName.value}</Col>
                                </Row>
                                {dealType.value && ['Upfront Only', 'Upfront CPM', 'Hybrid'].includes(dealType.value) && (
                                  <Row>
                                    <Col md="5" className="bold-text">Guaranteed Payment</Col>
                                    <Col md="7" className={guarantAmount.class}>{guarantAmount.value}</Col>
                                  </Row>
                                )}
                                {dealType.value && ['CPA/Ambassador', 'Hybrid'].includes(dealType.value) && cpaConversion.value && (
                                  <Row>
                                    <Col md="5" className="bold-text">CPA / Conversion</Col>
                                    <Col md="7" className={cpaConversion.class}>{cpaConversion.value}</Col>
                                  </Row>
                                )}
                                {dealType.value && ['CPA/Ambassador', 'Hybrid'].includes(dealType.value) && cpaPercentage.value && (
                                  <Row>
                                    <Col md="5" className="bold-text">CPA Percentage</Col>
                                    <Col md="7" className={cpaPercentage.class}>{cpaPercentage.value}</Col>
                                  </Row>
                                )}
                                {this.showField('Creator') && (
                                  <FormGroup style={{ marginTop: '12px' }}>
                                    <Label>Creator Name</Label>
                                    <Input
                                      name="creatorName"
                                      type="text"
                                      value={creatorName}
                                      onChange={this.onChangeInput}
                                      disabled={disableSending}
                                    />
                                    {errors.creatorName && (<div className="form__form-group-error">{errors.creatorName}</div>)}
                                    {charLimitMessage('Creator')}
                                  </FormGroup>
                                )}
                                {this.showField('CreatorContact') && (
                                  <FormGroup>
                                    <Label>Contact Name</Label>
                                    <Input
                                      name="creatorContact"
                                      type="text"
                                      value={creatorContact}
                                      onChange={this.onChangeInput}
                                      disabled={disableSending}
                                    />
                                    {errors.creatorContact && (<div className="form__form-group-error">{errors.creatorContact}</div>)}
                                    {charLimitMessage('CreatorContact')}
                                  </FormGroup>
                                )}
                                {this.showField('CreatorEmail') && (
                                  <FormGroup>
                                    <Label>Contact Email</Label>
                                    <Input
                                      name="creatorEmail"
                                      type="text"
                                      value={creatorEmail}
                                      onChange={this.onChangeInput}
                                      disabled={disableSending}
                                    />
                                    {errors.creatorEmail && (<div className="form__form-group-error mt-0 pb-3">{errors.creatorEmail}</div>)}
                                    {charLimitMessage('CreatorEmail')}
                                  </FormGroup>
                                )}
                                {this.showField('Company') && (
                                  <FormGroup>
                                    <Label>Company</Label>
                                    <Input
                                      name="company"
                                      type="text"
                                      value={company}
                                      onChange={this.onChangeInput}
                                      disabled={disableSending}
                                    />
                                    {errors.company && (<div className="form__form-group-error">{errors.company}</div>)}
                                    {charLimitMessage('Company')}
                                  </FormGroup>
                                )}
                                {this.showField('AgentContact') && (
                                  <FormGroup>
                                    <Label>Agent Contact</Label>
                                    <Input
                                      name="agentContact"
                                      type="text"
                                      value={agentContact}
                                      onChange={this.onChangeInput}
                                      disabled={disableSending}
                                    />
                                    {errors.agentContact && (<div className="form__form-group-error">{errors.agentContact}</div>)}
                                    {charLimitMessage('AgentContact')}
                                  </FormGroup>
                                )}
                                {this.showField('AgentEmail') && (
                                  <FormGroup>
                                    <Label>Agent Email</Label>
                                    <Input
                                      name="agentEmail"
                                      type="text"
                                      value={agentEmail}
                                      onChange={this.onChangeInput}
                                      disabled={disableSending}
                                    />
                                    {errors.agentEmail && (<div className="form__form-group-error">{errors.agentEmail}</div>)}
                                    {charLimitMessage('AgentEmail')}
                                  </FormGroup>
                                )}
                                {this.showField('Agency') && (
                                  <FormGroup>
                                    <Label>Agency</Label>
                                    <Input
                                      name="agencyName"
                                      type="text"
                                      value={agencyName}
                                      onChange={this.onChangeInput}
                                      disabled={disableSending}
                                    />
                                    {errors.agencyName && (<div className="form__form-group-error">{errors.agencyName}</div>)}
                                    {charLimitMessage('Agency')}
                                  </FormGroup>
                                )}
                                {this.showField('CPA') && (
                                  <FormGroup>
                                    <Label>CPA</Label>
                                    <Input
                                      name="cpaText"
                                      type="text"
                                      value={cpaText}
                                      onChange={this.onChangeInput}
                                      disabled={disableSending}
                                    />
                                    {errors.cpaText && (<div className="form__form-group-error">{errors.cpaText}</div>)}
                                    {charLimitMessage('CPA')}
                                  </FormGroup>
                                )}
                                {this.showField('Compensation') && (
                                  <FormGroup>
                                    <Label>Compensation</Label>
                                    <Input
                                      name="compensationText"
                                      type={(dealType.value === 'Media License') ? 'textarea' : 'text'}
                                      value={compensationText}
                                      onChange={this.onChangeInput}
                                      disabled={disableSending}
                                    />
                                    {errors.compensationText && (<div className="form__form-group-error">{errors.compensationText}</div>)}
                                    {charLimitMessage('Compensation')}
                                  </FormGroup>
                                )}
                                {this.showField('Channel') && (
                                  <FormGroup>
                                    <Label>Creator Channel URL</Label>
                                    <Input
                                      name="creatorChannel"
                                      type="text"
                                      value={creatorChannel}
                                      onChange={this.onChangeInput}
                                      disabled={disableSending}
                                    />
                                    {errors.creatorChannel && (<div className="form__form-group-error">{errors.creatorChannel}</div>)}
                                    {charLimitMessage('Channel')}
                                  </FormGroup>
                                )}
                                {this.showField('CreatorLink') && (
                                  <FormGroup key="1">
                                    <Label>Creator Vanity URL</Label>
                                    <Input
                                      name="creatorUrl"
                                      type="text"
                                      value={creatorUrl}
                                      onChange={this.onChangeInput}
                                      disabled={disableSending}
                                    />
                                    {errors.creatorUrl && (<div className="form__form-group-error">{errors.creatorUrl}</div>)}
                                    {charLimitMessage('CreatorLink')}
                                  </FormGroup>
                                )}
                                {this.showField('AdditionalTerms') && (
                                  <FormGroup key="2">
                                    <Label>Additional Terms<span style={{ marginLeft: '10px', color: '#999' }}>(Optional)</span></Label>
                                    <Input
                                      name="additionalTerms"
                                      type="textarea"
                                      value={additionalTerms}
                                      onChange={this.onChangeInput}
                                      disabled={disableSending}
                                    />
                                    {errors.additionalTerms && (<div className="form__form-group-error">{errors.additionalTerms}</div>)}
                                    {charLimitMessage('AdditionalTerms')}
                                  </FormGroup>
                                )}
                                {customTemplates[customAgreementTemplateId]?.customFields && Object.keys(customTemplates[customAgreementTemplateId].customFields).length > 0 && (
                                  <Row>
                                    <Col>
                                      <h4 className="border-bottom">{customTemplates[customAgreementTemplateId].templateName} Template Fields</h4>
                                      {Object.values(customTemplates[customAgreementTemplateId].customFields).map((row, j) => this.showField(row.customDataField) && (
                                        <FormGroup key={uid(j)}>
                                          <Label>
                                            {row.ilUILabel}{!row.required && (<span style={{ marginLeft: '10px', color: '#999' }}>(Optional)</span>)}
                                            <CustomFieldInfo field={row.customDataField} />
                                          </Label>
                                          <div>
                                            <CustomInput
                                              customDataField={row.customDataField}
                                              value={customData?.[row.customDataField]?.value}
                                              dataType={row.dataType}
                                              onChange={this.onChangeCustomField}
                                              disabled={disableSending}
                                              maxLength={this.maxLength(row.customDataField)}
                                              maxLines={this.maxLines(row.customDataField)}
                                            />
                                          </div>
                                          {errors[row.customDataField] && (<div className="form__form-group-error mt-0 pb-3">{errors[row.customDataField]}</div>)}
                                          {['text', 'textarea'].includes(row.dataType) && charLimitMessage(row.customDataField)}
                                        </FormGroup>
                                      ))}
                                    </Col>
                                  </Row>
                                )}
                                {!id && isKeenDeal && [(
                                  <Row key="1">
                                    <Col>
                                      <FormGroup>
                                        <CheckBoxField
                                          name="sendKeenAgreement"
                                          label="Send Keen Service Provider Agreement?"
                                          value={sendKeenAgreement}
                                          onChange={this.onChangeCheckbox}
                                          disabled={disableSending}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                ), (
                                  <Row key="2">
                                    <Col>
                                      <FormGroup>
                                        <CheckBoxField
                                          name="notSendCreatorAgreement"
                                          label="Don't send Creator Service Agreement?"
                                          value={notSendCreatorAgreement}
                                          onChange={this.onChangeCheckbox}
                                          disabled={disableSending}
                                        />
                                        {errors.notSendCreatorAgreement && (<div className="form__form-group-error">{errors.notSendCreatorAgreement}</div>)}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                )]}
                                {(dealType.value === 'Media License') && [(
                                  <Row key="1">
                                    <Col>
                                      <FormGroup>
                                        <CheckBoxField
                                          name="facebookAccess"
                                          label="Facebook Access"
                                          value={facebookAccess}
                                          onChange={this.onChangeCheckbox}
                                          disabled={disableSending}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                ), (
                                  <Row key="2">
                                    <Col>
                                      <FormGroup>
                                        <CheckBoxField
                                          name="exclusivity"
                                          label="Exclusivity Clause"
                                          value={exclusivity}
                                          onChange={this.onChangeCheckbox}
                                          disabled={disableSending}
                                        />
                                        {errors.notSendCreatorAgreement && (<div className="form__form-group-error">{errors.notSendCreatorAgreement}</div>)}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                )]}
                                {brandId && offerId && this.showEmbeddedSigning() && (
                                  <Row>
                                    <Col>
                                      <FormGroup>
                                        <CheckBoxField
                                          name="embeddedSigning"
                                          label="Embedded Signing"
                                          value={embeddedSigning}
                                          onChange={this.onChangeCheckbox}
                                          disabled={disableSending}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                )}
                                {brandId && offerId && (
                                  <ButtonToolbar className="form__button-toolbar mt-4 justify-content-center">
                                    <Button className="btn-sm mr-2" color="primary" onClick={() => { this.submit(); }} disabled={disableSending}>
                                      {id ? 'Save' : 'Send'}
                                    </Button>
                                    <Button className="btn-sm" color="secondary" onClick={this.onCancel}>
                                      Cancel
                                    </Button>
                                  </ButtonToolbar>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <WarningModal
          showModal={showWarning}
          setShowModal={(value) => { this.setState({ showWarning: value }); }}
          title="Service Agreement"
          header="Unable to send the service agreement"
          errors={['A brand approved prospect record is required in order to send a service agreement']}
        />
      </Container>
    );
  }
}

export default withNotice(ServiceAgreementForm);
