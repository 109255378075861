import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Button,
  Label,
  FormGroup,
} from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { getCookie, setCookie } from '../../../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../../../shared/components/CheckBox';
import { clearFilterProperty } from '../../../../../../redux/reducers/TableReducerDefault';
import Hook from '../../../../../../shared/hooks';
import DashboardActions from './Table/DashboardActions'
import Tags from '../../../../../../shared/hooks/components/Tags';

const Actions = React.memo(({
  downloadCsv,
}) => {
  console.log('RELOAD ACRIONS');
  const dispatch = useDispatch();
  const [showFilters, setShowFilters] = useState(getCookie('payablesBillsShowFilters') === 'true');

  const [search, setSearch] = Hook.useTableFilterHookString('fullSearchName');
  const [companyStatusFilter, handleChangeCompanyStatusFilters] = Hook.useTableFilterHookCheckbox('status');
  const [statusFilter, handleChangeStatusFilters] = Hook.useTableFilterHookCheckbox('companyStatus');
  const [inBlacklistFilter, handleChangeIsBlacklistFilters] = Hook.useTableFilterHookCheckbox('inBlackList');
  const [taxForm, handleChangeTaxFormFilters] = Hook.useTableFilterHookCheckbox('taxForm');
  const [assignedCategory, handleChangeAssignedFilters] = Hook.useTableFilterHookCheckbox('assignedCategory');

  const [dealOwners, setDealOwners, dealOwnersSugestion] = Hook.useTableFilterHookTags('contactOwner');
  const [AF, setAF, AFSugestion] = Hook.useTableFilterHookTags('accountManager');
  const [tags, setTags, tagsSugestion] = Hook.useTableFilterHookTags('tags');
  const [offers, setOffers, offersSugestion] = Hook.useTableFilterHookTags('offers');
  const [categories, setCategories, categoriesSugestion] = Hook.useTableFilterHookTags('category');
  const [country, setCountry, countrySuggestions] = Hook.useTableFilterHookTags('countryOfResidence');
  // console.log(selectedIds, 'selectedIds');
  useEffect(() => {
    setCookie('cpmPricingApprovalsFilters', showFilters.toString(), 35);
  }, [showFilters]);
  const resetFilters = () => {
    dispatch(clearFilterProperty());
  };
  return (
    <>
      <Row>
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search First Name, Last Name, Company Name, Email, Social media channel URLs"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '44px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col md={2} className="text-right">
          <DashboardActions  categories={categoriesSugestion} downloadCsv={downloadCsv}/>
        </Col>
      </Row>
      { showFilters && (
        <Row className="pb-3">
          <Col sm={4}>
            <Row>
              <Col sm="auto">
                <Label className="bold-text">
                  Partner Status
                </Label>
                <FormGroup style={{ marginBottom: '0' }}>
                  <CheckBoxField
                    name="Active1"
                    label="Active"
                    value={statusFilter.Active}
                    onChange={event => handleChangeStatusFilters(event, 'Active')}
                  />
                </FormGroup>
                <FormGroup style={{ marginBottom: '0' }}>
                  <CheckBoxField
                    name="Deleted1"
                    label="Deleted"
                    value={statusFilter.Deleted}
                    onChange={event => handleChangeStatusFilters(event, 'Deleted')}
                  />
                </FormGroup>
                <FormGroup style={{ marginBottom: '0' }}>
                  <CheckBoxField
                    name="Blocked1"
                    label="Blocked"
                    value={statusFilter.Blocked}
                    onChange={event => handleChangeStatusFilters(event, 'Blocked')}
                  />
                </FormGroup>
                <FormGroup style={{ marginBottom: '0' }}>
                  <CheckBoxField
                    name="NotSet1"
                    label="Not Set"
                    value={statusFilter['Not Set']}
                    onChange={event => handleChangeStatusFilters(event, 'Not Set')}
                  />
                </FormGroup>
              </Col>
              <Col sm="auto">
                <Label className="bold-text">
                  Company Status
                </Label>
                <FormGroup style={{ marginBottom: '0' }}>
                  <CheckBoxField
                    name="ActiveCompanyStatus"
                    label="Active"
                    value={companyStatusFilter.Active}
                    onChange={event => handleChangeCompanyStatusFilters(event, 'Active')}
                  />
                </FormGroup>
                <FormGroup style={{ marginBottom: '0' }}>
                  <CheckBoxField
                    name="DeletedCompanyStatus"
                    label="Deleted"
                    value={companyStatusFilter.Deleted}
                    onChange={event => handleChangeCompanyStatusFilters(event, 'Deleted')}
                  />
                </FormGroup>
                <FormGroup style={{ marginBottom: '0' }}>
                  <CheckBoxField
                    name="BlockedCompanyStatus"
                    label="Blocked"
                    value={companyStatusFilter.Blocked}
                    onChange={event => handleChangeCompanyStatusFilters(event, 'Blocked')}
                  />
                </FormGroup>
                <FormGroup style={{ marginBottom: '0' }}>
                  <CheckBoxField
                    name="NotSetCompanyStatus"
                    label="Not Set"
                    value={companyStatusFilter['Not Set']}
                    onChange={event => handleChangeCompanyStatusFilters(event, 'Not Set')}
                  />
                </FormGroup>
              </Col>
              <Col sm="auto">
                <Label className="bold-text">
                  Blacklist?
                </Label>
                <FormGroup style={{ marginBottom: '0' }}>
                  <CheckBoxField
                    name="Yes"
                    label="Yes"
                    value={inBlacklistFilter.Yes}
                    onChange={event => handleChangeIsBlacklistFilters(event, 'Yes')}
                  />
                </FormGroup>
                <FormGroup>
                  <CheckBoxField
                    name="No"
                    label="No"
                    value={inBlacklistFilter.No}
                    onChange={event => handleChangeIsBlacklistFilters(event, 'No')}
                  />
                </FormGroup>
              </Col>
              <Col sm="auto">
                <Label className="bold-text">
                  Tax Form
                </Label>
                <FormGroup style={{ marginBottom: '0' }}>
                  <CheckBoxField
                    name="taxFormYes"
                    label="On File"
                    value={taxForm.Yes}
                    onChange={event => handleChangeTaxFormFilters(event, 'Yes')}
                  />
                </FormGroup>
                <FormGroup>
                  <CheckBoxField
                    name="taxFormNo"
                    label="Missing"
                    value={taxForm.No}
                    onChange={event => handleChangeTaxFormFilters(event, 'No')}
                  />
                </FormGroup>
              </Col>
              <Col sm="auto">
                <Label className="bold-text">
                  Category?
                </Label>
                <FormGroup style={{ marginBottom: '0' }}>
                  <CheckBoxField
                    name="assignedCategory"
                    label="Assigned"
                    value={assignedCategory.Yes}
                    onChange={event => handleChangeAssignedFilters(event, 'Yes')}
                  />
                </FormGroup>
                <FormGroup>
                  <CheckBoxField
                    name="NotAssignedCategory"
                    label="Not Assigned"
                    value={assignedCategory.No}
                    onChange={event => handleChangeAssignedFilters(event, 'No')}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col sm={8}>
            <Row>
              <Col md={6}>
                <Row>
                  <Col sm={6}>
                    <Label className="bold-text">
                      Contact Owner(s)
                    </Label>
                    <Tags
                      tags={dealOwners}
                      setTags={tags => setDealOwners(tags)}
                      tagsSuggestion={dealOwnersSugestion}
                      title="HubSpot Contact Owner name(s)"
                    />
                  </Col>
                  <Col sm={6}>
                    <Label className="bold-text mt-0">
                      Account Manager(s)
                    </Label>
                    <Tags
                      tags={AF}
                      setTags={tags => setAF(tags)}
                      tagsSuggestion={AFSugestion}
                      title="HasOffers Account Manager name(s)"
                    />
                    {/* <Select
                      value={AF}
                      options={AFSugestion}
                      onChange={tags => setAF(tags)}
                      placeholder="HasOffers Account Manager name(s)"
                      isClearable
                      isMulti
                    /> */}
                  </Col>
                </Row>
                <Label className="bold-text mt-2">
                  Categories
                </Label>
                <Tags
                  tags={categories}
                  setTags={tags => setCategories(tags)}
                  tagsSuggestion={categoriesSugestion}
                  title="Categories"
                />
                <Label className="bold-text mt-2">
                  Country of Residence
                </Label>
                <Tags
                  tags={country}
                  setTags={tags => setCountry(tags)}
                  tagsSuggestion={countrySuggestions}
                  title="Country of Residence"
                />
              </Col>
              <Col md={6}>
                <Label className="bold-text mt-0">
                  Tag(s)
                </Label>
                <Tags
                  tags={tags}
                  setTags={tags => setTags(tags)}
                  tagsSuggestion={tagsSugestion}
                  title="Tag name(s)"
                />
                <Label className="bold-text mt-2">
                  Offer(s)
                </Label>
                <Tags
                  tags={offers}
                  setTags={tags => setOffers(tags)}
                  tagsSuggestion={offersSugestion}
                  title="Offer name(s)"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
});
Actions.propTypes = {
  downloadCsv: PropTypes.func.isRequired,
};

export default Actions;
