/* eslint-disable no-param-reassign */
import moment from 'moment';
import ApiClient from 'ApiClient';
import { createSlice } from '@reduxjs/toolkit';
import { addNotice } from '../../../containers/App/store/store';
import { getAllParams } from '../../../shared/helpers/WVQuery';

/*
  NOTE: createSlice allows us to work with state directly.
  Internally, it does not mute state, but makes a copy.
  https://redux-toolkit.js.org/tutorials/intermediate-tutorial#creating-the-todos-slice
*/
const filterCreators = (creators, filters) => {
  // console.log(filters.search, 'FILTERS');
  const items = creators.filter((creator) => {
    const contact = creator.hsContactObject[0];
    const searchString = filters.search.toLowerCase();
    const isCompliance = filters.compliantFilter && ((filters.compliantFilter.Yes && contact?.ftc_compliance_status === 'Compliant') || (filters.compliantFilter.No && contact?.ftc_compliance_status !== 'Compliant'));

    const isPublishedMedia = creator.mediaContent && creator.mediaContent.find(p => p.publishDate);
    const isPublished = filters.publishedFilter && ((filters.publishedFilter.Yes && isPublishedMedia) || (filters.publishedFilter.No && !isPublishedMedia));
    // console.log(filters.compliantFilter, 'FILTERS_');
    // console.log(filters.publishedFilter, 'FILTERS___');
    // console.log(compliantFilter, publishedFilter);
    // console.log(isCompliance, isPublished);
    // const isPublishedFilter= (compliantFilter.Yes && contact.ftc_compliance_status) || (compliantFilter.No && contact.ftc_compliance_status);
    let isSearch = true;
    if (searchString) {
      isSearch = (`${creator.first_name} ${creator.last_name} ${creator.email}`.toLowerCase().includes(searchString))
        || (contact?.mediaChannels.youtube_channel_url && contact?.mediaChannels.youtube_channel_url.includes(searchString))
        || (contact?.mediaChannels.youtube_channel_url && contact?.mediaChannels.youtube_channel_url.includes(searchString))
        || (contact?.mediaChannels.facebook_channel_url && contact?.mediaChannels.facebook_channel_url.includes(searchString))
        || (contact?.mediaChannels.instagram_channel && contact?.mediaChannels.instagram_channel.includes(searchString));
    }
    const isCountries = filters.countryFilter.length && creator.countryOfResidence && filters.countryFilter.find(c => c.value === creator.countryOfResidence);
    return isSearch && isCompliance && isPublished && isCountries;
  });
  // console.log(items, 'FILTERS_ite');
  return items.map((d) => {
    const isPublishedMedia = d.mediaContent && d.mediaContent.find(p => p.publishDate);
    return { ...d, params: { existStatus: isPublishedMedia ? 'info' : '' } };
  });
};
const creatorsReducer = createSlice({
  name: 'creators',
  initialState: {
    creators: [],
    filteredCreators: [],
    currentCreator: {},
    creatorContent: [],
    brandContent: [],
    brandSafetySearchTerms: [],
    showSafetyCheck: false,
    showBrandStatusHistoryModal: false,
    showBrandStatusNotesModal: false,
    currentMediaContent: {},
    loading: {
      creators: false,
      currentCreator: false,
      creatorContent: false,
      brandContent: false,
      channels: false,
      statistics: false,
      tubular: false,
      stats: false,
      brandSafetySearchTerms: false,
    },
    tubularStatistics: {},
    creatorsTableVersion: new Date().toString(),
    errors: [],
    error: null,
    showStatisticsDialog: false,
    showStatisticsCreatorId: '',
    creatorStatistics: false,
    showScorecardAnswersDialog: false,
    showBrandStatusHistoryDialog: false,
    showProfileDialog: false,
    creatorLink: '/creators/list',
    prospectLink: '/prospects',
    profileData: {
      mediaChannels: {},
    },
    view: {
      creators: 'list',
    },
    brandUsers: [],
    stats: {
      totalCreators: 0,
      totalCreatorsThisMonth: 0,
      totalContent: 0,
      totalContentThisMonth: 0,
    },
    lastFetched: new Date(),
    filters: {
      creators: {
        search: '',
        compliantFilter: { Yes: true, No: true },
        publishedFilter: { Yes: true, No: true },
        creatorType: { Standard: true, InfluenceLogic: true },
        channels: {
          'No Content': true,
          YouTube: true,
          'YouTube Description Text': true,
          Podcast: true,
          'Podcast Script Text': true,
          'Instagram Story': true,
          'Instagram Post': true,
          'Instagram TV Video': true,
          TikTok: true,
          'Facebook Post': true,
          'Facebook Watch Video': true,
          'Twitter Post': true,
          LinkTree: true,
          Website: true,
          Email: true,
          Text: true,
        },
        countryFilter: getAllParams('filters.country', { pathname: '/creators/list' }).map(value => ({ value })),
      },
    },
  },
  reducers: {
    getCreatorsStart(state) {
      state.loading = {
        ...state.loading,
        creators: true,
      };
    },
    getCreatorsSuccess(state, action) {
      const { data, mediaContent, prospects } = action.payload;
      // console.log('MEDIA', mediaContent);
      state.loading = {
        ...state.loading,
        creators: false,
      };
      state.error = null;
      if (data) {
        data.forEach((c) => {
          c.mediaContent = mediaContent.filter(m => m.influencer === c._id);
          c.prospect = prospects.find(p => Number(p.hsUserId) === Number(c.hsContactVid));
          // console.log(c.prospect, 'PROSECT');
          // console.log('MEDIA', c._id);
          // console.log('MEDIA', c.mediaContent);
        });
      }
      // console.log('MEDIA', data);
      state.creators = data || [];
      state.lastFetched = new Date();
      state.filteredCreators = filterCreators(state.creators, state.filters.creators);
      // state.updateFiltereItems = data || [];
    },
    getCreatorsError(state) {
      state.loading = {
        ...state.loading,
        creators: false,
      };
    },
    getCreatorStart(state) {
      state.loading = {
        ...state.loading,
        currentCreator: true,
      };
    },
    getCreatorSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        currentCreator: false,
      };
      state.error = null;
      state.currentCreator = data;
    },
    getCreatorError(state) {
      state.loading = {
        ...state.loading,
        currentCreator: false,
      };
    },

    getStatisticsStart(state) {
      state.loading = {
        ...state.loading,
        statistics: true,
      };
    },
    getStatisticsSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        statistics: false,
      };
      state.error = null;
      state.creatorStatistics = data;
    },
    getStatisticsError(state) {
      state.loading = {
        ...state.loading,
        statistics: false,
      };
    },


    getTubularStatisticsStart(state) {
      state.loading = {
        ...state.loading,
        tubular: true,
      };
    },
    getTubularStatisticsSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        tubular: false,
      };
      state.error = null;
      state.tubularStatistics = data;
    },
    getTubularStatisticsError(state) {
      state.loading = {
        ...state.loading,
        tubular: false,
      };
    },

    getChannelsStart(state) {
      state.loading = {
        ...state.loading,
        channels: true,
      };
    },
    getChannelsSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        channels: false,
      };
      state.error = null;
      const updatedCreators = [];
      state.creators.forEach((creator) => {
        creator.channels = data[creator._id].channels;
        creator.contentTotal = data[creator._id].contentTotal;
        creator.totalMonthContent = data[creator._id].totalMonthContent;
        updatedCreators.push(creator);
      });
      state.creators = updatedCreators;
      // console.log(state.filters);
      state.filteredCreators = filterCreators(state.creators, state.filters.creators);
      state.creatorsTableVersion = new Date().toString();
    },
    getChannelsError(state) {
      state.loading = {
        ...state.loading,
        channels: false,
      };
    },
    saveShowStatisticsDialog(state, action) {
      state.showStatisticsDialog = action.payload.show;
      state.showStatisticsCreatorId = action.payload.creatorId;
      if (!action.payload.show) {
        state.creatorStatistics = false;
        state.showStatisticsCreatorId = '';
      }
    },
    saveShowScorecardAnswersDialog(state, action) {
      state.showScorecardAnswersDialog = action.payload.show;
    },
    setShowBrandStatusHistoryModalValue(state, action) {
      state.showBrandStatusHistoryModal = action.payload.show;
    },
    setShowBrandStatusNotesModalValue(state, action) {
      state.showBrandStatusNotesModal = action.payload.show;
    },
    setShowSafetyCheckValue(state, action) {
      state.showSafetyCheck = action.payload.show;
    },
    setCurrentMediaContentValue(state, action) {
      state.currentMediaContent = action.payload.value;
    },
    saveShowProfileDialog(state, action) {
      state.showProfileDialog = action.payload.show;
      if (!action.payload.show) {
        state.profileData = {};
      }
    },
    saveProfileData(state, action) {
      const data = action.payload.profileData;
      state.profileData[data.property] = data.value;
    },
    updateMenuCreatorLink(state, action) {
      state.creatorLink = action.payload.creatorLink;
    },
    updateMenuProspectLink(state, action) {
      state.prospectLink = action.payload.prospectLink;
    },
    getCreatorContentStart(state) {
      state.loading = {
        ...state.loading,
        creatorContent: true,
      };
    },
    getCreatorContentSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        creatorContent: false,
      };
      state.error = null;
      // state.lastFetched = new Date();
      state.creatorContent = data;
    },
    getCreatorContentError(state) {
      state.loading = {
        ...state.loading,
        creatorContent: false,
      };
    },
    getTotalStatsStart(state) {
      state.loading.stats = true;
      state.stats = {
        totalCreators: 0,
        totalCreatorsThisMonth: 0,
        totalContent: 0,
        totalContentThisMonth: 0,
      };
    },
    getTotalStatsSuccess(state, action) {
      console.log(action);
      const {
        totalCreators, totalCreatorsThisMonth, totalContent, totalContentThisMonth,
      } = action.payload.data;
      state.loading.stats = false;
      state.stats = {
        totalCreators,
        totalCreatorsThisMonth,
        totalContent,
        totalContentThisMonth,
      };
    },
    getBrandContentStart(state) {
      state.loading = {
        ...state.loading,
        brandContent: true,
      };
    },
    getBrandContentSuccess(state, action) {
      const { data } = action.payload;
      state.loading = {
        ...state.loading,
        brandContent: false,
      };
      state.error = null;
      // state.lastFetched = new Date();
      state.brandContent = data;
    },
    getBrandContentError(state) {
      state.loading = {
        ...state.loading,
        brandContent: false,
      };
    },
    updateCreatorsFilters(state, action) {
      const { data } = action.payload;
      state.filters.creators[data.property] = data.value;
    },
    updateCreatorsView(state, action) {
      const { data } = action.payload;
      state.view.creators = data.value;
    },
    updateFiltereItems(state) {
      const { filters, creators } = state;
      state.filteredCreators = filterCreators(creators, filters.creators);
    },
    getBrandUsersStart(state) {
      state.brandUsers = [];
    },
    getBrandUsersSuccess(state, action) {
      const { data } = action.payload;
      state.brandUsers = data;
    },
    getBrandUsersError(state) {
      state.brandUsers = [];
    },
    getBrandSafetySearchTermsStart(state) {
      state.loading = {
        ...state.loading,
        brandSafetySearchTerms: true,
      };
    },
    getBrandSafetySearchTermsError(state) {
      state.loading = {
        ...state.loading,
        brandSafetySearchTerms: false,
      };
    },
    getBrandSafetySearchTermsSuccess(state, action) {
      const { data } = action.payload;
      state.brandSafetySearchTerms = data;
      state.loading = {
        ...state.loading,
        brandSafetySearchTerms: false,
      };
    },
  },
});

const getPayablesApi = async () => {
  const api = new ApiClient();
  return api.client.get('/api/brand/creators/get-my-creators');
};
const getTotalStatsApi = async () => {
  const api = new ApiClient();
  return api.client.get('/api/brand/creators/get-total-statistics');
};
const getCreatorApi = async (creatorId) => {
  const api = new ApiClient();
  return api.client.get(`/api/brand/creators/get-my-creator?creatorId=${creatorId}`);
};

const getStatisticsApi = async (creatorId, dateFrom, dateTo) => {
  const api = new ApiClient();
  return api.client.post('/api/brand/creators/get-statistics', { creatorId, dateFrom, dateTo });
};
const getTubularStatsApi = async (creatorId) => {
  const api = new ApiClient();
  return api.client.post('/api/brand/prospects/post-tubular-stats', { creatorId });
};

const getChannelsApi = async (creatorsIds) => {
  const api = new ApiClient();
  return api.client.post('/api/brand/creators/get-media-channels', { creatorsIds });
};

const getCreatorContentApi = async (creatorId, forCreatorDashboard = false) => {
  const api = new ApiClient();
  return api.client.get(`/api/brand/creators/get-my-content?creatorId=${creatorId}&forCreatorDashboard=${forCreatorDashboard}`);
};

const getBrandContentApi = async () => {
  const api = new ApiClient();
  return api.client.get('/api/brand/creators/get-brand-content');
};
const getBrandUsersApi = async () => {
  const api = new ApiClient();
  return api.client.get('/api/brand/prospects/get-brand-users');
};

const getBrandSafetySearchTermsAPI = async () => {
  const api = new ApiClient();
  return api.client.get('/api/brand/creators/get-brand-safety-search-terms');
};

export const {
  // get creators
  getCreatorsStart,
  getCreatorsSuccess,
  getCreatorsError,
  // get creator by Id
  getCreatorStart,
  getCreatorSuccess,
  getCreatorError,
  // get creator content
  getCreatorContentStart,
  getCreatorContentSuccess,
  getCreatorContentError,
  // get Brand Content
  getBrandContentStart,
  getBrandContentSuccess,
  getBrandContentError,
  // get statistics
  getStatisticsStart,
  getStatisticsSuccess,
  getStatisticsError,
  // get statistics
  getTubularStatisticsStart,
  getTubularStatisticsSuccess,
  getTubularStatisticsError,
  // get channels
  getChannelsStart,
  getChannelsSuccess,
  getChannelsError,
  // show statistics dialog
  saveShowStatisticsDialog,
  // show scorecard answers dialog
  saveShowScorecardAnswersDialog,
  setShowBrandStatusHistoryModalValue,
  setShowBrandStatusNotesModalValue,
  setShowSafetyCheckValue,
  setCurrentMediaContentValue,
  saveShowProfileDialog,
  saveProfileData,
  // update filters on Creators page
  updateCreatorsFilters,
  // update view on Creators page
  updateCreatorsView,
  updateMenuCreatorLink,
  // update Top Menu Prspect Link
  updateMenuProspectLink,

  updateFiltereItems,

  getTotalStatsStart,
  getTotalStatsSuccess,

  getBrandUsersStart,
  getBrandUsersSuccess,
  getBrandUsersError,

  getBrandSafetySearchTermsStart,
  getBrandSafetySearchTermsError,
  getBrandSafetySearchTermsSuccess,
} = creatorsReducer.actions;

export const getCreators = done => async (dispatch, getState) => {
  const store = getState();
  // console.log(moment(store.creators.lastFetched));
  // console.log(moment().diff(moment(store.creators.lastFetched), 'minutes'), 'minutes');
  if (store.creators.creators.length > 0 && moment().diff(moment(store.creators.lastFetched), 'minutes') < 10) {
    return true;
  }
  try {
    dispatch(getCreatorsStart());
    const response = await getPayablesApi();
    if (response.data) {
      dispatch(getCreatorsSuccess({
        data: response.data.records,
        mediaContent: response.data.media,
        prospects: response.data.prospects,
      }));
      done();
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(getCreatorsError(err.message));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
  return true;
};
export const getTotalStats = () => async (dispatch) => {
  try {
    dispatch(getTotalStatsStart());
    const response = await getTotalStatsApi();
    console.log(response);
    if (response.data) {
      dispatch(getTotalStatsSuccess({ data: response.data }));
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const getCreator = creatorId => async (dispatch) => {
  try {
    dispatch(getCreatorStart());
    const response = await getCreatorApi(creatorId);
    if (response.data) {
      dispatch(getCreatorSuccess({ data: response.data.record }));
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(getCreatorError(err.message));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const getCreatorStatistics = (creatorId, dateFrom, dateTo) => async (dispatch) => {
  try {
    dispatch(getCreatorsStart());
    const response = await getStatisticsApi(creatorId, dateFrom, dateTo);
    if (response.data) {
      dispatch(getCreatorsSuccess({ data: response.data.statistics }));
    } else {
      throw new Error('There was an error fetching statistics, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(getCreatorsError(err.message));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const getCreatorContent = (creatorId, forCreatorDashboard = false) => async (dispatch) => {
  try {
    dispatch(getCreatorContentStart());
    const response = await getCreatorContentApi(creatorId, forCreatorDashboard);
    if (response.data) {
      dispatch(getCreatorContentSuccess({ data: response.data.records }));
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(getCreatorContentError(err.message));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const getCreatorsChannels = creatorsIds => async (dispatch) => {
  try {
    dispatch(getChannelsStart());
    const response = await getChannelsApi(creatorsIds);
    if (response.data) {
      dispatch(getChannelsSuccess({ data: response.data.records }));
    } else {
      throw new Error('There was an error fetching channels, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(getChannelsError(err.message));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const getBrandContent = () => async (dispatch) => {
  try {
    dispatch(getBrandContentStart());
    const response = await getBrandContentApi();
    if (response.data) {
      dispatch(getBrandContentSuccess({ data: response.data.records }));
    } else {
      throw new Error('There was an error fetching records, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(getBrandContentError(err.message));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const showScorecardAnswersDialog = () => async (dispatch) => {
  dispatch(saveShowScorecardAnswersDialog({ show: true }));
};

export const closeScorecardAnswersDialog = () => async (dispatch) => {
  dispatch(saveShowScorecardAnswersDialog({ show: false }));
};
export const setShowBrandStatusHistoryModal = show => async (dispatch) => {
  dispatch(setShowBrandStatusHistoryModalValue({ show }));
};
export const setShowBrandStatusNotesModal = show => async (dispatch) => {
  console.log('setShowBrandStatusNotesModal(): ', show);
  dispatch(setShowBrandStatusNotesModalValue({ show }));
};
export const setShowSafetyCheck = show => async (dispatch) => {
  dispatch(setShowSafetyCheckValue({ show }));
};
export const setCurrentMediaContent = value => async (dispatch) => {
  dispatch(setCurrentMediaContentValue({ value }));
};

export const showStatisticsDialog = creatorId => async (dispatch) => {
  dispatch(saveShowStatisticsDialog({ show: true, creatorId }));
};

export const closeStatisticsDialog = creatorId => async (dispatch) => {
  dispatch(saveShowStatisticsDialog({ show: false, creatorId }));
};

export const showProfileDialog = () => async (dispatch) => {
  dispatch(saveShowProfileDialog({ show: true }));
};

export const closeProfileDialog = () => async (dispatch) => {
  dispatch(saveShowProfileDialog({ show: false }));
};


export const updateProfileDialog = (property, value) => async (dispatch) => {
  dispatch(saveProfileData({ profileData: { property, value } }));
};

export const updateTopMenuCreatorLink = value => async (dispatch) => {
  dispatch(updateMenuCreatorLink({ creatorLink: value }));
};

export const updateTopMenuProspectLink = value => async (dispatch) => {
  dispatch(updateMenuProspectLink({ prospectLink: value }));
};

export const loadCreatorStatistics = (creatorId, dateFrom, dateTo) => async (dispatch) => {
  try {
    dispatch(getStatisticsStart());
    const response = await getStatisticsApi(creatorId, dateFrom, dateTo);
    if (response.data) {
      dispatch(getStatisticsSuccess({ data: response.data.statistics }));
    } else {
      throw new Error('There was an error fetching statistics, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(getStatisticsError(err.message));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};
export const getBrandUsers = () => async (dispatch) => {
  try {
    dispatch(getBrandUsersStart());
    const response = await getBrandUsersApi();
    if (response.data) {
      dispatch(getBrandUsersSuccess({ data: response.data.records }));
    } else {
      throw new Error('There was an error fetching brand Users, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(getBrandUsersError(err.message));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const loadTubularStats = () => async (dispatch, getState) => {
  try {
    const store = getState();
    dispatch(getTubularStatisticsStart());
    const response = await getTubularStatsApi(store.creators.currentCreator._id);
    // console.log(response.data.tubularStats);
    if (response.data) {
      dispatch(getTubularStatisticsSuccess({ data: response.data.tubularStats }));
    } else {
      throw new Error('There was an error fetching statistics, Check out console to see more information.');
    }
  } catch (err) {
    dispatch(getTubularStatisticsError(err.message));
    dispatch(addNotice({
      message: err.message,
      type: 'error',
    }));
  }
};

export const updateCreatorFilterItem = (property, value) => async (dispatch) => {
  dispatch(updateCreatorsFilters({ data: { property, value } }));
};

export const updateCreatorFilterItemUpdate = (property, value) => async (dispatch) => {
  dispatch(updateCreatorsFilters({ data: { property, value } }));
  dispatch(updateFiltereItems());
};

export const updateCreatorsPageView = value => async (dispatch) => {
  dispatch(updateCreatorsView({ data: { value } }));
};

export const getBrandSafetySearchTerms = () => async (dispatch) => {
  try {
    dispatch(getBrandSafetySearchTermsStart());
    const response = await getBrandSafetySearchTermsAPI();
    if (response.data.success) {
      dispatch(getBrandSafetySearchTermsSuccess({ data: response.data.data }));
    } else {
      dispatch(getBrandSafetySearchTermsError());
    }
  } catch (err) {
    dispatch(getBrandSafetySearchTermsError());
    dispatch(addNotice({
      message: 'There was an error while fetching list of brand safety search Terms, Check out console to see more information.',
      type: 'error',
    }));
  }
};

export default creatorsReducer.reducer;
