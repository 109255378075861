import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Spinner } from 'reactstrap';
import axios from 'axios';
import withNotice from '../../../../../containers/App/store/with-notice';

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
    minWidth: '150px',
    marginRight: '10px',
  }),
};

const OfferStatusUpdater = ({ offerId, currentStatus, affiliateId, addNotice, refreshData }) => {
  const [selectedStatus, setSelectedStatus] = useState({ value: currentStatus, label: currentStatus });
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const statusOptions = [
    { value: 'approved', label: 'Approved' },
    { value: 'rejected', label: 'Not Approved' }
  ];

  const handleStatusChange = async (selectedOption) => {
    setSelectedStatus(selectedOption);
    setLoading(true);

    try {
      const response = await axios.post('/api/portal/hs-offers/updateStatus', {
        offerId,
        affiliateId,
        status: selectedOption.value,
      });

      if (response.data.success) {
        addNotice({
          message: `Offer status updated to ${selectedOption.label}`,
          type: 'success',
        });

        if (typeof refreshData === 'function') {
          refreshData();
        }
      } else {
        addNotice({
          message: 'Failed to update the offer status.',
          type: 'error',
        });
      }
    } catch (error) {
      console.error('Error updating offer status:', error);
      addNotice({
        message: 'Error updating the offer status. Please try again.',
        type: 'error',
      });
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Select
        value={selectedStatus}
        onChange={handleStatusChange}
        options={statusOptions}
        isDisabled={loading}
        placeholder="Status not set"
        styles={customStyles}
        menuPortalTarget={document.body}
        menuPosition="fixed"
      />
      {loading && <Spinner size="sm" color="primary" />}
    </div>
  );
};

OfferStatusUpdater.propTypes = {
  offerId: PropTypes.number.isRequired,
  affiliateId: PropTypes.number.isRequired,
  currentStatus: PropTypes.string.isRequired,
  addNotice: PropTypes.func.isRequired,
  refreshData: PropTypes.func,
};

OfferStatusUpdater.defaultProps = {
  refreshData: null,
};

export default withNotice(OfferStatusUpdater);