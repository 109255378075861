import React, { useState } from 'react';
import { unflatten } from 'flat';
import { axios } from 'ApiClient';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import Hook from '@/shared/hooks';
import { NumericFormat } from 'react-number-format';

const Cost = ({ initialValue, isSaving, handleBlur }) => {
  const [amount, setAmount] = useState(initialValue);

  return (
    <div>
      <InputGroup className="min-width-150">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>$</InputGroupText>
        </InputGroupAddon>
        <NumericFormat
          thousandSeparator
          prefix=""
          value={amount}
          className="form-control"
          inputMode="numeric"
          allowEmptyFormatting={false}
          fixedDecimalScale
          decimalScale={2}
          disabled={isSaving}
          defaultValue={initialValue}
          onBlur={(e) => handleBlur(amount)}
          placeholder="Enter amount in dollars"
          onValueChange={(values) => {
            setAmount(values.floatValue);
          }}
          min={0}
        />
      </InputGroup>
    </div>
  )
};

const Percent = ({ initialValue, isSaving, handleBlur }) => {
  const [amount, setAmount] = useState(initialValue);
  console.log('amount', amount);
  return (
    <InputGroup className="min-width-150">
      <NumericFormat
        thousandSeparator
        prefix=""
        value={amount}
        className="form-control"
        inputMode="numeric"
        allowEmptyFormatting={false}
        fixedDecimalScale
        decimalScale={2}
        disabled={isSaving}
        defaultValue={initialValue}
        onBlur={(e) => handleBlur(amount)}
        placeholder="Enter percent"
        onValueChange={(values) => {
          setAmount(values.floatValue);
        }}
        max={100}
        min={0}
      />
      <InputGroupAddon addonType='append'>
        <InputGroupText>%</InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  )
};

const CostPercent = ({ record }) => {
  const [data, setData] = useState(record);
  const { showSuccess, showError } = Hook.useNoticeHook();
  const [isSaving, setIsSaving] = useState(false);

  const updateRecordFunction = (updatedRecord) => {
    setData(updatedRecord);
  };

  const handleUpdate = async (key, value) => {
    setIsSaving(true);
    try {
      const { data } = await axios.post('/api/portal/prospects/post-update-many', {
        params: { [key]: value},
        ids: [record.id ],
      });
      if (!data.success) {
        throw new Error(data.error || 'Failed to update record');
      } else {
        updateRecordFunction(unflatten(data.record));
        showSuccess('Opportunity saved successfully!');
      }
    } catch (error) {
      showError(`Failed to save ${key}.`);
    } finally {
      setIsSaving(false);
    }
  };
  const isPercent = record?.params?.opportunity?.dealType === 'CPA/Ambassador';
  if (!record?.params?.opportunity?.dealType) {
    return 'Select Deal Type to view Cost and Percent';
  }

  if (!isPercent) {
    return (
      <Cost
        initialValue={data?.params?.opportunity?.dealAmount}
        isSaving={isSaving}
        handleBlur={(e) => handleUpdate('opportunity.dealAmount', e)}
      />
    );
  }
  return (
    <Percent
      initialValue={data?.params?.opportunity?.dealPercentage}
      isSaving={isSaving}
      handleBlur={(e) => handleUpdate('opportunity.dealPercentage', e)}
    />
  );

};

export default CostPercent;