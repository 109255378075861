/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Row, Col, Card, CardTitle, CardBody,
  FormGroup, Label, Input, InputGroup, InputGroupAddon,
  Spinner, Popover, PopoverBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import moment from 'moment';
import Select, { components } from 'react-select';
import { Link } from 'react-router-dom';
import SelectAsync from 'react-select/async';
import DatePicker from 'react-datepicker';
import { unflatten } from 'flat';
import ApiClient from 'ApiClient';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import QrcodeIcon from 'mdi-react/QrcodeIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import ReactQr from 'react-awesome-qr';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Breadcrumbs from '../../../../../shared/components/BreadCrumbs';
import { currentTimezoneAggr, momentLocaleToServerUTC, momentUTCToLocalDate } from '../../../../../shared/helpers/Dates';
import PercentsField from '../../../../../shared/components/form/PercentsField';
import CurrencyField from '../../../../../shared/components/form/CurrencyField';
import StyledButton from '../../../../../shared/components/components/ui/styled-button';
import { addNotice } from '../../../../App/store/store';
import { clear } from '../../../../../redux/reducers/admin/GlobalSelectedReducer';
import CreatorDealPortalAgentSelect from './custom/CreatorDealPortalAgentSelect';
import OfferPayoutsForm from './custom/OfferPayoutsForm';
// import HubilAgentForm from '../../../HubIL/HubilAgent/components/custom/HubilAgentForm';
import AgentForm from '../../../Agents/AgentUser/components/custom/AgentForm';
import HubspotDealLink from '../../../../../shared/components/HubspotDealLink';
import CreatorDealPortalHubilDuplicateModal from './custom/CreatorDealPortalHubilDuplicateModal';
import { getConstant } from '../../../../../shared/helpers/WVConstants';
import { isUrl, isUrlPath } from '../../../../../shared/helpers/WVValidator';
import { toYesNo } from '../../../../../shared/helpers/WVUtilities';
import Alert from '../../../../../shared/easydev2/shared/components/Alert';
import { fetchDictionary } from '../../../../../shared/helpers/WVRequest';

const mapStatusArray = {
  Active: 'Active',
  Pending: 'Pending',
  Expired: 'Expired',
};
const mapStatusArrayBonus = {
  Pending: 'Pending',
  Paid: 'Paid',
};

const mapTypeArray = {
  'CPA/Ambassador': 'CPA/Ambassador',
  'Upfront Only': 'Upfront Only',
  Hybrid: 'Hybrid',
  'Upfront CPM': 'Upfront CPM',
  'Media License': 'Media License',
  Bonus: 'Bonus',
};

const creatorStatuses = {
  // donothing: 'donothing',
  createnew: 'createnew',
  selectexisting: 'selectexisting',
};

const contactStatuses = {
  createnew: 'createnew',
  selectexisting: 'selectexisting',
};

const yesNoOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

const mapGuaranteedPaymentRecoverableArray = {
  Yes: 'Yes',
  No: 'No',
};

const unsortedContentTypeOptions = [
  { label: 'YouTube', value: 'YouTube' },
  { label: 'YouTube Description Text', value: 'YouTube Description Text' },
  { label: 'Podcast', value: 'Podcast' },
  { label: 'Podcast Script Text', value: 'Podcast Script Text' },
  { label: 'Instagram Story', value: 'Instagram Story' },
  { label: 'Instagram Post', value: 'Instagram Post' },
  { label: 'Instagram TV Video', value: 'Instagram TV Video' },
  { label: 'TikTok', value: 'TikTok' },
  { label: 'X Post', value: 'Twitter Post' },
  { label: 'Facebook Post', value: 'Facebook Post' },
  { label: 'Facebook Watch Video', value: 'Facebook Watch Video' },
  // { label: 'LinkTree', value: 'LinkTree' },
  { label: 'Website', value: 'Website' },
  // { label: 'Email', value: 'Email' },
  // { label: 'Text', value: 'Text' },
];


const contentTypeOptions = unsortedContentTypeOptions.sort((a, b) => ((a.label < b.label) ? -1 : 1));

const formatCreatorOptionLabel = ({ record }) => (
  <div style={{ display: 'flex' }}>
    <div>{record?.params.first_name} {record?.params.last_name}</div>
    <div style={{ color: '#888', marginLeft: '10px' }}>
      {record?.params.email}
    </div>
  </div>
);

const creatorOption = (props) => {
  const { record } = props.data;
  return (
    <components.Option {...props} >
      <div>
        <div className="bold-text">{record.params.first_name} {record.params.last_name}</div>
        <div >{record.params['affiliateDataObject.company']}</div>
        <div style={{ color: '#888', fontSize: '13px' }}>
          {record.params.email}
        </div>
      </div>
    </components.Option>
  );
};

const formatContactOptionLabel = ({ record }) => (
  <div style={{ display: 'flex' }}>
    <div >{record?.params.firstname} {record?.params.lastname}</div>
    <div style={{ color: '#888', marginLeft: '10px' }}>
      {record?.params.email}
    </div>
  </div>
);

const contactOption = (props) => {
  const { record } = props.data;
  return (
    <components.Option {...props} >
      <div>
        <div>{record?.params.firstname} {record?.params.lastname}</div>
        <div style={{ color: '#888', fontSize: '13px' }}>
          {record?.params.email}
        </div>
      </div>
    </components.Option>
  );
};

class CreatorDealEditor extends React.Component {
  static async loadHSOwnerOptions(inputValue) {
    const api = new ApiClient();
    const response = await api.client.get(`/api/portal/adminUsers/get-hubspot-users-local?term=${inputValue}`);
    return response.data.hsOwners.map(item => ({
      value: item._id,
      label: `${item.firstName} ${item.lastName}`,
      item,
    })).sort((a, b) => ((a.label < b.label) ? -1 : 1));
  }
  static async loadHOOwnerOptions(inputValue) {
    const api = new ApiClient();
    const response = await api.client.get(`/api/portal/adminUsers/get-ho-users-local?term=${inputValue}`);
    return response.data.hsOwners.map(item => ({
      value: item._id,
      label: `${item.firstName} ${item.lastName}`,
      item,
    }));
  }

  static async loadCreatorsOptions(inputValue) {
    const response = inputValue ? await axios.get('/api/portal/influencer/get-creator-search', {
      params: {
        value: inputValue,
      },
    }) : { records: [] };
    return response.data.records.map(record => ({
      value: record.id,
      label: record.params.email,
      record,
    }));
  }

  static async loadAgentsOptions(inputValue) {
    const response = await axios.get('/api/portal/agent/get-agent-search', {
      params: {
        value: inputValue,
      },
    });
    // const api = new ApiClient();
    // const records = await api.searchRecords({
    //   resourceId: 'HubilAgent',
    //   query: inputValue,
    // });
    console.log(response.data.records);
    const contacts = response.data.records ? response.data.records.map(record => ({
      value: record.id,
      label: record.params.email !== null ? record.params.email : `${record.params.first_name} ${record.params.last_name}`,
      record: {
        ...record,
        params: {
          ...record.params,
          firstname: record.params.first_name,
          lastname: record.params.last_name,
        },
      },
    })) : [];
    return [{ value: 'create_new_contact', label: '-- Create Agent Portal User --', record: { params: { _id: null, firstname: '-- Create Agent Portal User --', lastname: '' } } }].concat(contacts);
  }

  constructor(props) {
    super(props);
    this.api = new ApiClient();
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeCreator = this.handleChangeCreator.bind(this);
    this.handleChangeCreatorSelect = this.handleChangeCreatorSelect.bind(this);
    this.handleChangeContact = this.handleChangeContact.bind(this);
    this.handleChangeContactSelect = this.handleChangeContactSelect.bind(this);
    this.handleChangeContactAgentSelect = this.handleChangeContactAgentSelect.bind(this);
    this.handleChangeDealSelect = this.handleChangeDealSelect.bind(this);
    this.handleChangeOffer = this.handleChangeOffer.bind(this);
    this.handleChangeBrand = this.handleChangeBrand.bind(this);
    this.handleChangeHSOwner = this.handleChangeHSOwner.bind(this);
    this.handleChangeHOOwner = this.handleChangeHOOwner.bind(this);
    this.handleChangeCategory = this.handleChangeCategory.bind(this);
    this.handleChangePipeline = this.handleChangePipeline.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    // this.handleDatepickerChange = this.handleDatepickerChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleElementChange = this.handleElementChange.bind(this);
    this.loadContactsOptions = this.loadContactsOptions.bind(this);
    this.loadPipelineOptions = this.loadPipelineOptions.bind(this);
    this.loadBrandsOptions = this.loadBrandsOptions.bind(this);
    this.loadDealsOptions = this.loadDealsOptions.bind(this);
    this.storeError = this.storeError.bind(this);
    this.prefillDealName = this.prefillDealName.bind(this);
    this.prefillSelectedAgents = this.prefillSelectedAgents.bind(this);
    this.prefillSelectedAgency = this.prefillSelectedAgency.bind(this);

    this.handleCreatorTypeChange = this.handleCreatorTypeChange.bind(this);
    this.handleContactTypeChange = this.handleContactTypeChange.bind(this);
    this.handleChangeEnableAgents = this.handleChangeEnableAgents.bind(this);
    this.handleChangeEnableMockup = this.handleChangeEnableMockup.bind(this);
    this.handleAttachAgent = this.handleAttachAgent.bind(this);
    // this.openDialogHsAgent = this.openDialogHsAgent.bind(this);
    // this.closeDialogHsAgent = this.closeDialogHsAgent.bind(this);
    this.openDialogAgentUser = this.openDialogAgentUser.bind(this);
    this.closeDialogAgentUser = this.closeDialogAgentUser.bind(this);
    this.openDialogMediaChannels = this.openDialogMediaChannels.bind(this);
    this.setHubilDuplicateModal = this.setHubilDuplicateModal.bind(this);
    this.setHubilDuplicateId = this.setHubilDuplicateId.bind(this);
    this.closeDialogMediaChannels = this.closeDialogMediaChannels.bind(this);
    this.removeAgent = this.removeAgent.bind(this);
    this.clearReloadAgent = this.clearReloadAgent.bind(this);
    this.handleChangeCloneDeal = this.handleChangeCloneDeal.bind(this);
    this.handleChangeCloneDealSelect = this.handleChangeCloneDealSelect.bind(this);
    this.handleDealTypeChange = this.handleDealTypeChange.bind(this);
    this.handleContentReleaseDatepickerChange = this.handleContentReleaseDatepickerChange.bind(this);
    this.loadAgencyAffiliationOptions = this.loadAgencyAffiliationOptions.bind(this);
    this.handleChangeAgencyAff = this.handleChangeAgencyAff.bind(this);
    this.handleYouTubeUrlChange = this.handleYouTubeUrlChange.bind(this);
    this.saveOfferPayouts = this.saveOfferPayouts.bind(this);
    this.contactEmailBlured = this.contactEmailBlured.bind(this);
    this.prefillExistingAgent = this.prefillExistingAgent.bind(this);
    this.getDealName = this.getSetDealName.bind(this);
    this.downloadQr = this.downloadQr.bind(this);
    this.setQrCodeBase64 = this.setQrCodeBase64.bind(this);
    this.toggleQrPopover = this.toggleQrPopover.bind(this);
    this.getVanityUrlRedirect = this.getVanityUrlRedirect.bind(this);
    this.handlePlatformChange = this.handlePlatformChange.bind(this);
    this.handleChangeContactMediaChannels = this.handleChangeContactMediaChannels.bind(this);
    this.updateFieldsBasedOnReceivedData = this.updateFieldsBasedOnReceivedData.bind(this);
    this.loadCreatorData = this.loadCreatorData.bind(this);
    const { globalSelectedInfluencer, globalSelectedContact } = props;
    this.state = {
      marketingCampaignOptions: [],
      loading: {
        form: false,
        saving: false,
      },
      selectedCreator: globalSelectedInfluencer || null,
      selectedContact: globalSelectedContact || null,
      // selectedAgentOption: null,
      selectedDeal: null,
      selectedCloneDeal: null,
      selectedOffer: null,
      selectedBrand: null,
      selectedHSOwner: null,
      selectedMarketingCampaign: null,
      selectedPipeline: null,
      mulligan: null,
      dealExistsActive: null, // state to check active Deals Duplicate
      selectedAgents: [{
        params: {
          _id: null,
          firstname: '',
          lastname: '',
          hs_object_id: null,
          email: '--- Please Choose Agent ---',
        },
      }],
      stageOptions: [],
      allOffersOptions: [],
      dealsOptions: [],
      pipelines: [],
      pipelineChosen: false,
      enableAgents: null,
      enableMockup: false,
      deal: {
        params: {
          type_of_deal: 'CPA/Ambassador',
          cpaType: 'percentage',
          cpa_percentage: '100',
          guaranteed_payment_contract_amount: null,
          projected_gross_profit: null,
          guaranteed_payment_recoverable: null,
        },
        errors: {},
        // populated: {},
      },
      contact: {
        params: {
          mediaChannels: null,
          contact: null,
          contactAction: contactStatuses.selectexisting,
        },
        errors: {},
      },
      creator: {
        params: {
          creatorAction: creatorStatuses.createnew,
          creator: null,
        },
        errors: {},
      },
      // hsAgentDialogVisible: false,
      // hsAgentDialogIndex: null,
      agentUserDialogVisible: false,
      hubilDuplicateModal: false,
      hubilDuplicateId: '',
      selectedHsAgent: null,
      selectedAgentIndex: null,
      reloadAgentSelect: null,
      cloneDeal: false,
      agencyAffiliations: [],
      selectedAgencyAffiliation: null,
      offerPayouts: [],
      hubilAgentRecord: null,
      hsContactData: {
        existContact: false,
        contact: null,
        type: 'Creator',
      },
      hsContactInBlacklist: false,
      hsAgentInBlacklist: false,
      enableContentReleaseDate: true,
      loadingRedirect: false,
      redirectUrl: false,
      qrPopoverOpen: false,
      qrCodeBase64: null,
      showQrCodeTargetId: `showQrCode_${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10)}`,
      categories: [],
      contactMediaPlatform: { label: 'YouTube', value: 'YouTube' },
      youtube_channel_url: '',
      isContractSigned: false,
    };
  }

  updateFieldsBasedOnReceivedData(influencerData, contactData) {
    if (influencerData) {
      if (!this.state.selectedCreator) {
        this.setState({
          selectedCreator: influencerData,
        });
      }
      if (contactData) {
        if (!this.state.selectedContact) {
          this.setState({
            selectedContact: contactData,
          });
        }
        // this.handleChangeContact({ ...contactData });
        this.handleChangeContact('contact', contactData.params.email);
        this.handleChangeContact('first_name', contactData.params.firstname || '');
        this.handleChangeContact('last_name', contactData.params.lastname || '');
        this.handleChangeContact('company', contactData.params.company);
        this.handleChangeContact('mediaChannels', contactData.params.mediaChannels || {});
      } else {
        this.handleChangeContact('contact_email', influencerData.params.email || '');
        this.handleChangeContact('first_name', influencerData.params.first_name || '');
        this.handleChangeContact('last_name', influencerData.params.last_name || '');
        this.handleChangeContact('company', influencerData.params.affiliateData.company || '');
        this.handleChangeContact('mediaChannels', influencerData.params.mediaChannels || {});
      }
      // this.handleChangeCreator({ ...influencerData });
      this.handleChangeCreator('creator', influencerData.id);
      this.handleCreatorTypeChange({ target: { value: creatorStatuses.selectexisting } });
    }
  };

  componentDidMount() {
    const {
      dispatch,
      globalSelectedInfluencer,
      globalSelectedContact,
    } = this.props;
    if (!this.props.match.params.dealId) {
      this.updateFieldsBasedOnReceivedData(globalSelectedInfluencer, globalSelectedContact);
    }
    dispatch(clear(['influencer', 'contact']));

    axios({
      method: 'get',
      url: '/api/portal/adminUsers/get-marketing-campaing',
    }).then((response) => {
      const mCampaing = response.data.marketingCampaigns.map(item => ({
        value: item.value,
        label: `${item.label}`,
        item,
      }));
      this.setState({
        marketingCampaignOptions: mCampaing,
      });
    }).catch((err) => {
        console.log('Error from /api/portal/adminUsers/get-marketing-campaing:' , err)
    });
    this.fetchDictionaryData();

    const searchParams = new URLSearchParams(window.location.search)
    const creatorId = searchParams.get('creatorId');
    if (creatorId) {
      console.log('creatorId:', creatorId);
      this.loadCreatorData(creatorId);
    }

    if (!this.props.match.params.dealId) {
      const pipelinesLocalArr = this.loadPipelineOptions();
      pipelinesLocalArr.then((pipelinesLocal) => {
        const mainPipeline = pipelinesLocal.find(p => p.label.toLowerCase() === 'main pipeline');
        this.handleChangePipeline(mainPipeline);
      });
      if (this.props.adminUser && typeof this.props.adminUser.hsUserId !== 'undefined' && this.props.adminUser.hsUserId !== null) {
        this.handleChangeHSOwner({
          item: {
            hsUserId: this.props.adminUser.hsUserId,
            _id: this.props.adminUser._id,
            firstName: this.props.adminUser.firstName,
            lastName: this.props.adminUser.lastName,
          },
        });
      }
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.dealId && this.state.loading.form === false && prevState.loading.form === false && typeof this.state.deal.params._id === 'undefined') {
      this.fetchData(this.props.match.params.dealId);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  async getVanityUrlRedirect() {
    const {
      deal,
    } = this.state;
    this.setState(prevState => ({ ...prevState, loadingRedirect: true, redirectUrl: null }));
    try {
      if (deal?.params?.referral_url && deal.params._id && deal.params.coupon_code) {
        const resp = await axios.post(
          '/api/portal/find-vanity-url-redirect',
          {
            deals: [{
              dealId: deal.params._id,
              offerId: deal.params.offer._id,
              couponCode: deal.params.coupon_code,
            }],
            qr: true,
          },
        );
        if (resp.data.success && resp.data.resultUrls.length > 0) {
          this.setState(prevState => ({ ...prevState, loadingRedirect: false, redirectUrl: resp.data.resultUrls[0].resultUrl }));
        } else {
          throw new Error(resp.data.error);
        }
      }
    } catch (e) {
      console.error(e);
      this.setState(prevState => ({ ...prevState, loadingRedirect: false, redirectUrl: null }));
    }
  }

  setHubilDuplicateModal = (value) => {
    this.setState({ hubilDuplicateModal: value });
  };

  setHubilDuplicateId = (value) => {
    this.setState({ hubilDuplicateId: value });
  };

  getSetDealName() {
    const { deal } = this.state;
    let dealName = deal?.params?.dealname || '';
    const date = deal?.params?.content_release_date;
    const path = window.location.pathname;
    const dealNameHasDate = dealName ? moment.months().some(month => dealName.includes(month)) : false;
    const newDealPathLen = '/influencers/creator-deal-editor/'.length;

    if (date && dealName
      && (newDealPathLen === path.length || (newDealPathLen - 1) === path.length)
      && !dealNameHasDate) {
      dealName += ` ${moment(date).utc().format('MMMM YYYY')}`;
    }

    if (dealName !== deal?.params?.dealname) {
      this.setState({
        deal: {
          ...this.state.deal,
          params: {
            ...this.state.deal.params,
            dealname: dealName,
          },
        },
      });
    }
  }

  setQrCodeBase64(url, id) {
    console.log(id);
    this.setState({
      qrCodeBase64: url,
    });
  }
  async fetchDictionaryData() {
    fetchDictionary('PROSPECT_').then((data) => {
      const { success, records } = data;
      if (success === true) {
        const _categories = records.PROSPECT_CATEGORIES ? JSON.parse(records.PROSPECT_CATEGORIES) : [];
        const _categorySuggestions = _categories.map(row => ({ value: row, label: row }));
        _categorySuggestions.unshift({ value: 'null', label: '-- Not Assigned --' });
        this.setState({ categories: _categorySuggestions });
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target)) {
      this.setState({ qrPopoverOpen: false });
    }
  }

  toggleQrPopover() {
    const { qrPopoverOpen } = this.state;
    this.setState(prevState => ({ ...prevState, qrPopoverOpen: !qrPopoverOpen }));
  }

  downloadQr() {
    const { deal, qrCodeBase64 } = this.state;
    const name = deal?.params?.dealname ? `QR Code ${deal.params.dealname}.png` : 'QR Code.png';
    if (deal.params?.referral_url?.trim() && qrCodeBase64) {
      const link = document.createElement('a');
      document.body.appendChild(link); // for Firefox
      link.setAttribute('href', qrCodeBase64);
      link.setAttribute('download', name);
      link.click();
    }
  }

  fetchData(dealId) {
    this.setState({
      pipelineChosen: false,
      loading: {
        form: true,
      },
    });
    axios({
      method: 'get',
      url: `/api/portal/deals/get-data?id=${dealId}`,
    }).then((response) => {
      if (response.data.success === true) {
        // if (typeof response.data.deal.params.start_date !== 'undefined' && response.data.deal.params.start_date !== null) {
        //   response.data.deal.params.start_date = moment(response.data.deal.params.start_date).toDate();
        // }
        if (typeof response.data.deal.params.content_release_date !== 'undefined' && response.data.deal.params.content_release_date !== null) {
          response.data.deal.params.content_release_date = moment(response.data.deal.params.content_release_date).toDate();
        }
        const selectedDeal = {
          id: response.data.deal.params._id,
          title: typeof response.data.deal.params.dealname !== 'undefined' ? response.data.deal.params.dealname : '--- Create New Deal ---',
        };
        let selectedCreator = {};
        if (typeof response.data.deal.params !== 'undefined' && typeof response.data.deal.params.influencer !== 'undefined' && response.data.deal.params.influencer !== null) {
          selectedCreator = {
            id: response.data.deal.params.influencer._id,
            title: response.data.deal.params.influencer.email,
            params: {
              first_name: response.data.deal.params.influencer.first_name,
              last_name: response.data.deal.params.influencer.last_name,
              email: response.data.deal.params.influencer.email,
            },
          };
          this.handleChangeCreator('creator', selectedCreator.id);
        }
        if (typeof response.data.deal.params !== 'undefined' && typeof response.data.deal.params.contact !== 'undefined' && response.data.deal.params.contact !== null) {
          const selectedContact = {
            params: {
              _id: response.data.deal.params.contact._id,
              firstname: response.data.deal.params.contact.firstname,
              lastname: response.data.deal.params.contact.lastname,
              hs_object_id: response.data.deal.params.contact.hs_object_id,
              email: response.data.deal.params.contact.email,
              mediaChannels: response.data.deal.params.contact.mediaChannels,
            },
          };
          this.setState({
            selectedContact,
            hsContactInBlacklist: !!(response.data.deal.params.contact.blacklist_),
          });
          this.handleChangeContact('contact', response.data.deal.params.contact.email);
          this.handleChangeContact('first_name', typeof response.data.deal.params.contact.firstname !== 'undefined' ? response.data.deal.params.contact.firstname : '');
          this.handleChangeContact('last_name', typeof response.data.deal.params.contact.lastname !== 'undefined' ? response.data.deal.params.contact.lastname : '');
          this.handleChangeContact('company', typeof response.data.deal.params.contact.company !== 'undefined' ? response.data.deal.params.contact.company : '');
          this.handleChangeContact('mediaChannels', typeof response.data.deal.params.contact.mediaChannels !== 'undefined' ? response.data.deal.params.contact.mediaChannels : '');
        }
        if (response.data.deal?.params?.agency_affiliation) {
          this.prefillSelectedAgency(response.data.deal.params.agency_affiliation);
        }
        this.prefillSelectedAgents(response.data.deal);
        this.handleCreatorTypeChange({ target: { value: response.data.deal.params.creatorAction } });
        if (typeof response.data.deal.params.prefill_content_type_status !== 'undefined' && response.data.deal.params.prefill_content_type_status !== null) {
          this.setState({
            prefillContentTypeStatus: response.data.deal.params.prefill_content_type_status,
          });
          delete response.data.deal.params.prefill_content_type_status;
        }
        this.saveDealData(response);
        this.setState({
          loading: {
            form: false,
          },
          selectedCreator,
          selectedDeal,
          mulligan: toYesNo(response.data.deal.params.mulligan_),
        });
      } else {
        console.log(response.data);
        this.props.dispatch(addNotice({
          message: 'An error occurred',
          type: 'warning',
        }));
        this.setState({
          deal: { params: {}, errors: {} },
        });
      }
    }).catch((error) => {
      console.log('CATCH ERROR: ', error);
      this.props.dispatch(addNotice({
        message: 'An error occurred',
        type: 'warning',
      }));
    });
    return null;
  }

  saveDealData(response) {
    let selectedOffer = {};
    if (typeof response.data.deal.params !== 'undefined' && typeof response.data.deal.params.offer !== 'undefined' && response.data.deal.params.offer !== null) {
      selectedOffer = {
        id: response.data.deal.params.offer._id,
        title: response.data.deal.params.offer.name,
      };
    }
    let selectedBrand = {};
    if (typeof response.data.deal.params !== 'undefined' && typeof response.data.deal.params.offer !== 'undefined' && response.data.deal.params.offer !== null && typeof response.data.deal.params.offer.advertiser !== 'undefined') {
      selectedBrand = {
        id: response.data.deal.params.offer.advertiser._id,
        title: response.data.deal.params.offer.advertiser.companyName,
        params: {
          _id: response.data.deal.params.offer.advertiser._id,
        },
      };
      this.handleChangeBrand({ record: selectedBrand });
      this.handleChangeOffer({ value: selectedOffer.id, label: selectedOffer.title });
    }

    let selectedHSOwner = {};
    if (typeof response.data.deal.params !== 'undefined' &&
      typeof response.data.deal.params.hubspotOwner !== 'undefined' &&
      typeof response.data.deal.params.hubspotOwner.hsUserId !== 'undefined' &&
      response.data.deal.params.hubspotOwner.hsUserId !== null &&
      response.data.deal.params.hubspotOwner.hsUserId) {
      selectedHSOwner = {
        _id: response.data.deal.params.hubspotOwner._id,
        title: false,
        firstName: response.data.deal.params.hubspotOwner.firstName,
        lastName: response.data.deal.params.hubspotOwner.lastName,
      };
      response.data.deal.params.hsOwner = response.data.deal.params.hubspotOwner.hsUserId;
    }

    let selectedPipeline = {};
    if (typeof response.data.deal.params !== 'undefined' && typeof response.data.deal.params.pipeline !== 'undefined' && response.data.deal.params.pipeline !== null && response.data.deal.params.pipeline.id !== null) {
      selectedPipeline = {
        id: response.data.deal.params.pipeline.id,
        title: false,
      };
      this.handleChangePipeline({ record: selectedPipeline, stages: response.data.deal.params.pipeline.stages });
      response.data.deal.params.pipeline = selectedPipeline.id;
    }
    // let selectedAgencyAffiliation = {};
    // if (typeof response.data.deal.params !== 'undefined' && typeof response.data.deal.params.agency_affiliation !== 'undefined' && response.data.deal.params.agency_affiliation !== null) {
    //   selectedAgencyAffiliation = {
    //     id: response.data.deal.params.agency_affiliation,
    //     title: response.data.deal.params.agency_affiliation,
    //   };
    //   this.handleChange('agency_affiliation', selectedAgencyAffiliation.id);
    // }
    if (typeof response.data.deal.params !== 'undefined' && typeof response.data.deal.params.content_type !== 'undefined' && response.data.deal.params.content_type !== null) {
      response.data.deal.params.content_type = { label: response.data.deal.params.content_type, value: response.data.deal.params.content_type };
    }
    // if (typeof response.data.deal.params !== 'undefined' && typeof response.data.deal.params.justification !== 'undefined' && response.data.deal.params.justification !== null) {
    //   response.data.deal.params.justification = { label: response.data.deal.params.justification, value: response.data.deal.params.justification };
    // }
    this.setState({
      deal: response.data.deal,
      selectedHSOwner,
      selectedPipeline,
      pipelineChosen: true,
      // selectedAgencyAffiliation,
      selectedMarketingCampaign: response.data.deal.params.marketing_campaign,
      enableContentReleaseDate: (!response.data.deal.params.content_release_date || !response.data.deal.params.mediaContent),
      isContractSigned: response.data.deal.params.isContractSigned,
    });
    console.log('hello');
    setTimeout(() => this.getVanityUrlRedirect(), 100);
  }

  async loadContactsOptions(inputValue) {
    const response = inputValue ? await axios.get('/api/portal/hubil/get-contact-search', {
      params: {
        param: 'email',
        value: inputValue,
      },
    }) : { records: [] };
    const contacts = response.data.records.map(record => ({
      value: record.id,
      label: record.params.email !== null ? record.params.email : `${record.params.firstname} ${record.params.lastname}`,
      record,
    }));
    if (inputValue.includes('@') && inputValue.includes('.')) {
      const api = new ApiClient();
      const agentRecords = await api.searchRecords({
        resourceId: 'HubilAgent',
        query: inputValue,
      });
      if (agentRecords && agentRecords.length === 1 && agentRecords[0].params.email === inputValue) {
        this.setState({
          hubilAgentRecord: agentRecords[0],
        });
      } else {
        this.setState({
          hubilAgentRecord: null,
        });
      }
    } else {
      this.setState({
        hubilAgentRecord: null,
      });
    }
    return contacts;
  }

  async loadPipelineOptions(q) {
    const pipelines = getConstant('deal', 'pipelinesOrdered');
    // const response = await axios.get(`/api/portal/hubil/search-pipeline?pipelineName=${inputValue}`);
    // const { success, pipelines } = response.data;
    const pipelinesArr = pipelines.map(record => ({
      value: record.id,
      label: record.label,
      stages: record.stages.map(s => ({ id: s.id, label: s.label, title: s.label })),
      record: { id: record.id, title: record.label },
    }));
    this.setState({
      pipelines: pipelinesArr,
    });
    return pipelinesArr.filter(({ label }) => !q || label.toLowerCase().indexOf(q.toLowerCase()) !== -1)
      .sort((a, b) => ((a.label < b.label) ? -1 : 1));
  }
  async checkDealStatus({ influencer, offer }) {
    console.log(influencer, offer);
    if (!influencer || !offer) {
      this.setState({
        dealExistsActive: null,
      });
      return false;
    }
    const response = await axios.post('/api/portal/hubil/post-check-deal', {
      influencer,
      offer,
    });
    const { success, deal } = response.data;
    if (success) {
      this.setState({
        dealExistsActive: deal || null,
      });
      return deal;
    }
    return null;
  }

  async loadDealsOptions(contactId) {
    if (typeof this.props.match.params.dealId === 'undefined') {
      const response = await axios.get(`/api/portal/hubil/search-deal-by-contact?contactId=${contactId}`);
      const { success, deals } = response.data;
      if (success) {
        const dealsArr = deals.map(record => ({
          value: record.id,
          label: record.dealname,
          record,
        }));
        const dealsArrFinal = [{
          value: null,
          label: '--- Create New Deal ---',
          record: {
            _id: false,
            id: false,
            title: '--- Create New Deal ---',
            params: {
              _id: false,
            },
          },
        }].concat(dealsArr);
        this.setState({
          dealsOptions: dealsArrFinal,
        });
      }
    }
    return [];
  }

  async loadCreatorData(creatorId) {
    if (creatorId) {
      const response = await axios.get(`/api/portal/influencer/influencer-data-for-deal?id=${creatorId}`);
      const { success, influencer, contact } = response.data;
      if (success) {
        this.updateFieldsBasedOnReceivedData(unflatten(influencer), unflatten(contact));
      }
    }
  }

  async loadBrandsOptions(inputValue) {
    const api = new ApiClient();
    const recordsBrands = await api.client.get(`/api/portal/scorecard/media-content/get-brands-data?term=${inputValue}`);
    if (this.state.allOffersOptions.length === 0) {
      const allOffers = [];
      recordsBrands.data.brands.filter(b => b.displayInUi === 'Yes').map((brand) => {
        brand.offersObj.map((offer) => {
          allOffers.push({
            value: offer._id,
            label: offer.name,
            advertiser: offer.advertiser,
          });
          return false;
        });
        return false;
      });
      this.setState({ allOffersOptions: allOffers });
    }
    return recordsBrands.data.brands.filter(b => b.displayInUi === 'Yes').map(record => ({
      value: record._id,
      label: record.companyName,
      record: {
        id: record._id,
        title: record.companyName,
        params: {
          ...record,
        },
      },
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  async loadCloneDealOptions(inputValue, contactId = null) {
    const api = new ApiClient();
    const recordsDeals = await api.client.get(`/api/portal/scorecard/media-content/get-deal-data?term=${inputValue}&contact=${contactId}`);
    return recordsDeals.data.deals
      .map(record => ({
        value: record._id,
        label: `${record.dealname} (${typeof record.hs_createdate !== 'undefined' ? moment(record.hs_createdate).format('M/D/YYYY') : ''})`,
        createdAt: record.createdAt,
      }))
      .sort((a, b) => ((a.createdAt < b.createdAt) ? 1 : -1));
  }

  async loadAgencyAffiliationOptions(inputValue) {
    const response = await axios.get(`/api/portal/hubil/search-agency-affiliation?agencyAffiliationName=${inputValue}`);
    const { success, agencyAffiliations } = response.data;
    if (success) {
      const agencyAfArr = agencyAffiliations.map(record => ({
        value: record.id,
        label: record.title,
        record,
      }));
      this.setState({
        agencyAffiliations: agencyAfArr,
      });
      return agencyAfArr;
    }
    return [];
  }

  handleChange(propertyOrRecord, value) {
    if (typeof value === 'undefined' && (propertyOrRecord).params) {
      this.setState({
        deal: propertyOrRecord,
      });
    } else {
      this.setState(state => ({
        deal: {
          ...state.deal,
          params: {
            ...state.deal.params,
            [propertyOrRecord]: value,
          },
          errors: {
            ...state.deal.errors,
            [propertyOrRecord]: undefined,
          },
        },
      }));
    }
    if (propertyOrRecord === 'content_release_date') {
      setTimeout(() => this.getSetDealName(), 10);
    }
    if (propertyOrRecord === 'referral_url') {
      setTimeout(() => this.getVanityUrlRedirect(), 100);
    }
  }
  handleChangeContact(propertyOrRecord, value) {
    if (typeof value === 'undefined' && (propertyOrRecord).params) {
      this.setState({
        contact: propertyOrRecord,
      });
    } else {
      this.setState(state => ({
        contact: {
          ...state.contact,
          params: {
            ...state.contact.params,
            [propertyOrRecord]: value,
          },
          errors: {
            ...state.contact.errors,
            [propertyOrRecord]: undefined,
          },
        },
      }), () => {
        if (propertyOrRecord !== 'company') {
          this.prefillDealName();
        }
      });
    }
  }
  handleChangeContactMediaChannels(propertyOrRecord, value) {
    if (typeof value === 'undefined' && (propertyOrRecord).params) {
      this.setState({
        contact: propertyOrRecord,
      });
    } else {
      this.setState(state => ({
        contact: {
          ...state.contact,
          params: {
            ...state.contact.params,
            mediaChannels: {
              ...state.contact.params.mediaChannels,
              [propertyOrRecord]: value,
            },
          },
          errors: {
            ...state.contact.errors,
            [propertyOrRecord]: undefined,
          },
        },
      }));
    }
  }
  handleChangeCreator(propertyOrRecord, value) {
    if (typeof value === 'undefined' && (propertyOrRecord).params) {
      this.setState({
        creator: propertyOrRecord,
      });
    } else {
      this.setState(state => ({
        creator: {
          ...state.creator,
          params: {
            ...state.creator.params,
            [propertyOrRecord]: value,
          },
          errors: {
            ...state.creator.errors,
            [propertyOrRecord]: undefined,
          },
        },
      }));
    }
  }
  handleChangeCreatorSelect(selected) {
    this.setState({
      selectedCreator: selected !== null ? selected.record : null,
    });
    this.handleChangeCreator('creator', selected !== null ? selected.record.id : null);
  }
  handleChangeEnableAgents(checked) {
    if (checked) {
      this.setState({
        enableAgents: checked.currentTarget.value === 'false',
      });
    }
  }
  handleChangeEnableMockup(checked) {
    if (checked) {
      this.setState({
        enableMockup: checked.currentTarget.value === 'false',
      });
    }
  }
  handleChangeHSOwner(selected) {
    this.setState({
      selectedHSOwner: selected !== null ? selected.item : null,
    });
    this.handleChange('hsOwner', selected !== null ? selected.item.hsUserId : null);
  }
  handleChangeHOOwner(selected) {
    this.setState({
      selectedHOOwner: selected !== null ? selected.item : null,
    });
    this.handleChangeCreator('affiliateManagerId', selected !== null ? selected.item.affiliateManagerId : null);
  }
  handleChangeCategory(selected) {
    this.handleChangeCreator('category', selected !== null ? selected.value : null);
  }
  handleChangeOffer(selected) {
    this.setState({
      selectedOffer: selected !== null ? selected : null,
    }, () => {
      this.prefillDealName();
      if (this.state.deal.params.cpa_status === 'Active') { // Check if we have active status
        this.checkDealStatus({ influencer: this.state.creator.params.creator, offer: this.state.selectedOffer?.value });
      } else {
        this.setState({ dealExistsActive: null });
      }
    });
    this.handleChange('offer', selected !== null ? selected.value : null);
  }
  handleChangeBrand(selected) {
    this.setState({
      selectedBrand: selected !== null ? selected.record : null,
      selectedOffer: null,
    });
    this.handleChange('brand', selected !== null ? selected.record.id : null);
    this.handleChange('offer', null);
  }
  handleChangeContactSelect(selected) {
    // console.log(selected);
    if (selected || selected === null) {
      this.setState({
        selectedContact: selected !== null ? selected.record : null,
        hsContactData: {
          existContact: false,
          contact: null,
          type: 'Creator',
        },
      });
      const isCreateNewContact = selected === null;
      if (!isCreateNewContact) {
        this.setState({
          hubilAgentRecord: null,
        });
      }
      if (!isCreateNewContact && selected.record?.params?.blacklist_) {
        this.setState({
          hsContactInBlacklist: true,
        });
      } else {
        this.setState({
          hsContactInBlacklist: false,
        });
      }
      this.handleChangeContact('contact', !isCreateNewContact ? selected.record.title : null);
      this.handleChangeContact('first_name', !isCreateNewContact ? selected.record.params.firstname : '');
      this.handleChangeContact('last_name', !isCreateNewContact ? selected.record.params.lastname : '');
      this.handleChangeContact('company', !isCreateNewContact ? selected.record.params.company : '');
      this.handleChangeContact('contact_email', null);
      const unflatRecord = unflatten(selected);
      this.handleChangeContact('mediaChannels', !isCreateNewContact ? unflatRecord.record.params.mediaChannels : null);
      if (!isCreateNewContact) {
        this.prefillExistingCreator(selected.record.params.hs_object_id, selected.record.params.email);
      }
      if (selected !== null) {
        this.loadDealsOptions(selected.record.id);
        this.prefillExistingAgent(selected.record.id);
      } else if (this.state.creator.params.creatorAction === creatorStatuses.createnew) {
        this.setState(state => ({
          creator: {
            ...state.creator,
            params: {
              ...state.creator.params,
              email: '',
            },
            errors: {
              ...state.creator.errors,
            },
          },
        }));
      }
    }
  }
  prefillExistingCreator(hsContactVid, email) {
    axios({
      method: 'get',
      url: `/api/portal/deals/get-creator-by-hs-contact-vid?vid=${hsContactVid}`,
    }).then((response) => {
      if (response.data.success === true && response.data.creator !== null) {
        this.setState(state => ({
          creator: {
            ...state.creator,
            params: {
              ...state.creator.params,
              creatorAction: creatorStatuses.selectexisting,
              creator: response.data.creator._id,
            },
            errors: {
              ...state.creator.errors,
            },
          },
        }));
        const selectedCreator = {
          id: response.data.creator._id,
          title: response.data.creator.email,
          params: {
            first_name: response.data.creator.first_name,
            last_name: response.data.creator.last_name,
            email: response.data.creator.email,
          },
        };
        this.setState({
          selectedCreator,
        });
      } else {
        this.setState(state => ({
          creator: {
            ...state.creator,
            params: {
              ...state.creator.params,
              email,
            },
            errors: {
              ...state.creator.errors,
            },
          },
        }));
      }
    }).catch((error) => {
      console.log('CATCH ERROR: ', error);
    });
  }
  handleChangeDealSelect(selected) {
    if (selected === null || !selected.record._id) {
      this.setState({
        selectedCreator: null,
        selectedOffer: null,
        selectedBrand: null,
        selectedPipeline: null,
        selectedDeal: null,
        stageOptions: [],
      });
    }
    this.fetchData(selected !== null ? selected.record._id : false);
  }
  handleChangeContactAgentSelect(selected, index) {
    const { selectedAgents } = this.state;
    if (selected !== null && selected.record?.params?._id !== null) {
      selectedAgents[index].params = {
        email: selected.record.params.email,
        firstname: selected.record.params.firstname,
        hs_object_id: selected.record.params.hsContactVid || selected.record.params.hs_object_id,
        lastname: selected.record.params.lastname,
        _id: selected.record.params._id,
      };
      selectedAgents[index].record = selected.record;
    } else {
      selectedAgents[index].params = {
        _id: null,
        firstname: '',
        lastname: '',
        hs_object_id: null,
        email: '-- Create Agent Portal User --',
        record: { params: { _id: null, firstname: '', lastname: '' } },
      };
    }
    this.setState({
      selectedAgents,
    });
    if (selected?.record?.params?.blacklist_) {
      this.setState({
        hsAgentInBlacklist: true,
      });
    } else {
      this.setState({
        hsAgentInBlacklist: false,
      });
    }
  }
  handleChangePipeline(selected) {
    this.setState({
      selectedPipeline: selected !== null ? selected.record : null,
    });
    this.handleChange('pipeline', selected !== null ? selected.record.id : null);
    const options = [];
    if (selected !== null && typeof selected.stages !== 'undefined' && selected.stages.length > 0) {
      let stageSet = false;
      selected.stages.forEach((stage) => {
        if (!stage.archived) {
          options.push({ value: stage.id, label: stage.label });
          if (!stageSet) {
            this.handleSelectChange({ value: stage.id }, { name: 'dealstage' });
            stageSet = true;
          }
        }
      });
    }
    this.setState({
      stageOptions: options,
      pipelineChosen: selected !== null,
    });
  }
  handleChangeAgencyAff(selected) {
    this.setState({
      selectedAgencyAffiliation: selected !== null ? selected.record : null,
    });
    this.handleChange('agency_affiliation', selected !== null ? selected.record.id : null);
  }
  prefillDealName = () => {
    const { selectedOffer, contact } = this.state;
    if (typeof contact !== 'undefined' &&
      contact !== null &&
      typeof contact.params !== 'undefined' &&
      typeof contact.params.first_name !== 'undefined' &&
      typeof contact.params.last_name !== 'undefined' &&
      typeof selectedOffer !== 'undefined' &&
      selectedOffer !== null &&
      typeof selectedOffer.label !== 'undefined') {
      const companyName = contact?.params?.company || '';
      const lastName = contact.params.last_name !== null && contact.params.last_name !== '' ? ` ${contact.params.last_name}` : '';
      const creatorName = `${contact.params.first_name}${lastName}`;
      const dealName = `${companyName || creatorName} - ${selectedOffer.label}`;
      this.handleChange('dealname', dealName);
    }
  };
  prefillSelectedAgency = (agencyAffiliation) => {
    // console.log('AGENCY', agencyAffiliation);
    axios({
      method: 'get',
      url: '/api/portal/hubil/search-agency-affiliation?fetchAll=1',
    }).then((response) => {
      const { allAgencies } = response.data;
      // console.log('ALL Agencies', allAgencies);
      const agency = allAgencies.find(a => a.value === agencyAffiliation);
      // console.log('FIND AGENCY', agency);
      if (agency) {
        const selectedAgencyAffiliation = {
          id: agency.value,
          title: agency.label,
        };
        this.handleChange('agency_affiliation', selectedAgencyAffiliation.id);
        this.setState({
          selectedAgencyAffiliation,
        })
      }
    }).catch((error) => {
      console.log('CATCH ERROR from Prefill Agency: ', error);
    });
  };
  prefillSelectedAgents = (deal) => {
    const selectedAgents = [];
    // console.log('CLONED DEAL', deal);
    if (typeof deal.params !== 'undefined' && typeof deal.params.agents !== 'undefined' && deal.params.agents.length > 0) {
      this.handleChangeEnableAgents({ currentTarget: { value: 'false' } });
      deal.params.agents.forEach((agent) => {
        const selectedAgent = {
          params: {
            _id: agent._id,
            firstname: agent.firstname,
            lastname: agent.lastname,
            hs_object_id: agent.hs_object_id,
            email: agent.email,
          },
        };
        selectedAgents.push(selectedAgent);
      });
    } else {
      const selectedAgent = {
        params: {
          _id: null,
          firstname: '',
          lastname: '',
          hs_object_id: null,
          email: '--- Please Choose Agent ---',
        },
      };
      selectedAgents.push(selectedAgent);
    }
    this.setState({
      selectedAgents,
    });
  }
  // handleDatepickerChange(value) {
  //   this.handleChange('start_date', momentLocaleToServerUTC(moment(value)).toISOString());
  // }
  handleContentReleaseDatepickerChange(value) {
    this.handleChange('content_release_date', momentLocaleToServerUTC(moment(value)).toISOString());
  }
  handleSelectChange(value, element) {
    console.log(element.name);
    if (element.name === 'cpa_status' && value.value === 'Active') {
      this.checkDealStatus({ influencer: this.state.creator.params.creator, offer: this.state.selectedOffer?.value });
    } else {
      this.setState({
        dealExistsActive: null,
      });
    }
    this.handleChange(element.name, value !== null ? value.value : null);
  }
  handleDealTypeChange(value, element) {
    const { contact } = this.state;
    this.handleChange(element.name, value.value);
    if (value.value !== 'Hybrid') {
      this.handleChange('guaranteed_payment_recoverable', null);
    }
    if (value.value !== 'Upfront CPM') {
      // this.handleChange('content_release_date', null);
      this.handleChange('guaranteed_payment_contract_amount', null);
      // this.handleChange('projected_gross_profit', null);
      this.handleChange('agency_affiliation', null);
      this.setState({ selectedAgencyAffiliation: null });
      this.handleChange('youtube_target_url', null);
      this.handleChange('v30_number_of_impressions', null);
    } else if (!this.state.deal?.params?.youtube_target_url) {
      this.handleChange('youtube_target_url', contact?.params?.mediaChannels?.youtube_channel_url || null);
    }
    // if (value.value === 'Media License') {
    // this.handleChange('content_release_date', null);
    // }
  }
  handlePlatformChange(selected) {
    this.setState({
      contactMediaPlatform: selected,
    });
  }
  handleElementChange(event) {
    this.handleChange(`${event.target.name}`, event.target.value);
    // if (event.target.value === 'percentage') {
    //   this.handleInputChange('per_conversion_amount', { target: { value: 0 } });
    // } else if (event.target.value === 'flatRate') {
    //   this.handleInputChange('percentage', { target: { value: 0 } });
    // }
  }
  handleInputChange(name, event) {
    if (event.target.type === 'checkbox') {
      this.handleChange(name, event.target.checked);
    } else {
      this.handleChange(name, event.target.value);
    }
  }
  handleYouTubeUrlChange(e) {
    this.handleInputChange('youtube_target_url', e);
  }
  handleCreatorTypeChange(event) {
    const val = event.target.value;
    this.setState(state => ({
      creator: {
        ...state.creator,
        params: {
          ...state.creator.params,
          creatorAction: val,
        },
      },
    }));
  }

  handleContactTypeChange(event) {
    const val = event.target.value;
    this.setState(state => ({
      contact: {
        ...state.contact,
        params: {
          ...state.contact.params,
          contactAction: val,
          contact: null,
          first_name: '',
          last_name: '',
          company: '',
          contact_email: null,
          mediaChannels: null,
        },
      },
      creator: {
        ...state.creator,
        params: {
          ...state.creator.params,
          email: '',
        },
        errors: {
          ...state.creator.errors,
        },
      },
      selectedContact: null,
      hsContactData: {
        existContact: false,
        contact: null,
        type: 'Creator',
      },
      hsContactInBlacklist: false,
      hsAgentInBlacklist: false,
    }));
  }

  storeError(model, item, message) {
    console.log(item, message);
    this.setState(state => ({
      [model]: {
        ...state[model],
        errors: {
          ...state[model].errors,
          [item]: message,
        },
      },
    }));
  }

  handleSubmit(event, isCpmPa = false) {
    event.preventDefault();
    const {
      deal,
      contact,
      creator,
      selectedAgents,
      offerPayouts,
      enableMockup,
      selectedMarketingCampaign,
      mulligan,
    } = this.state;
    const { dispatch } = this.props;
    let isValid = true;

    if (creator.params.creatorAction === creatorStatuses.selectexisting) {
      if (creator.params.creator === null || creator.params.creator === '') {
        isValid = false;
        this.storeError('creator', 'creator', 'This field is required');
      }
    } else if (creator.params.creatorAction === creatorStatuses.createnew) {
      ['email', 'category'].forEach((item) => {
        if (!creator.params[item]) {
          isValid = false;
          this.storeError('creator', item, 'This field is required');
        }
      });
    }

    let requiredContactData;
    if (contact.params.contact !== null) {
      requiredContactData = ['first_name', 'company'];
    } else {
      requiredContactData = ['contact_email', 'first_name', 'company'];
    }
    // if (isCpmPa) requiredContactData.push('youtube_channel_url');
    requiredContactData.forEach((item) => {
      if (!contact.params[item]) {
        isValid = false;
        this.storeError('contact', item, 'This field is required');
      }
    });

    ['dealname', 'offer', 'cpa_status', 'content_release_date', 'hsOwner'].forEach((item) => {
      if (!deal.params[item]) {
        isValid = false;
        this.storeError('deal', item, 'This field is required');
      }
    });
    if (deal.params.type_of_deal === 'Upfront CPM') {
      const reqFields = ['content_release_date', 'agency_affiliation', 'youtube_target_url', 'v30_number_of_impressions', 'guaranteed_payment_contract_amount'];
      if (isCpmPa) {
        reqFields.push('justification');
      }
      reqFields.forEach((item) => {
        if (!deal.params[item]) {
          isValid = false;
          this.storeError('deal', item, 'This field is required');
        }
      });
      if (isCpmPa && !contact?.params?.mediaChannels?.youtube_channel_url) {
        isValid = false;
        this.storeError('contact', 'youtube_channel_url', 'This field is required');
      }
    }
    if (deal.params.type_of_deal === 'Media License'
      || deal.params.type_of_deal === 'CPA/Ambassador'
      || deal.params.type_of_deal === 'Hybrid'
      || deal.params.type_of_deal === 'Bonus') {
      ['content_release_date'].forEach((item) => {
        if (!deal.params[item]) {
          isValid = false;
          this.storeError('deal', item, 'This field is required');
        }
      });
    }
    if (!['Media License', 'Bonus'].includes(deal.params.type_of_deal)) {
      const urlValue = deal.params.referral_url?.trim();
      if (urlValue && !(isUrl(urlValue) && isUrlPath(urlValue))) {
        isValid = false;
        this.storeError('deal', 'referral_url', 'Invalid Url');
      }
      if (deal.params.coupon_code && !isUrlPath(deal.params.coupon_code)) {
        isValid = false;
        this.storeError('deal', 'coupon_code', 'The Promotion Code does not allow capital letters and spaces');
      }
    }
    console.log(deal.params.type_of_deal, isValid);
    if (!isValid) {
      dispatch(addNotice({
        message: 'Please Check form. Seems you did not fill some fields',
        type: 'warning',
      }));
      return true;
    }

    if (!isCpmPa && deal.params.notes) {
      dispatch(addNotice({
        message: 'Please note, if you updated the notes, to save them, you will need to hit "Save and Submit for Price Approval" button.',
        type: 'warning',
        duration: 30,
      }));
    }

    if (typeof deal.params.pipeline !== 'undefined' && deal.params.pipeline !== null && deal.params.pipeline !== undefined && deal.params.pipeline.id === null) {
      deal.params.pipeline = null;
    }
    if (deal.params.pipeline === undefined) {
      deal.params.pipeline = null;
    }

    deal.params.agents = [];
    selectedAgents.forEach((agent) => {
      if (agent.params.hs_object_id !== null) {
        deal.params.agents.push(agent.params.hs_object_id);
      }
    });
    let params = Object.assign({}, deal.params);
    params = Object.assign(params, creator.params);
    params = Object.assign(params, contact.params);

    if (typeof params.per_conversion_amount !== 'undefined' && params.per_conversion_amount !== null) {
      params.per_conversion_amount = params.per_conversion_amount.toString().replace(/,/g, '');
    }
    if (typeof params.guaranteed_payment_contract_amount !== 'undefined' && params.guaranteed_payment_contract_amount !== null) {
      if (params.type_of_deal === 'Upfront CPM') {
        params.projected_gross_profit = (parseFloat(params.guaranteed_payment_contract_amount.toString().replace(/,/g, '')) * 0.15);
        params.projected_gross_profit = params.projected_gross_profit.toString().replace(/,/g, '');
      }
      params.guaranteed_payment_contract_amount = params.guaranteed_payment_contract_amount.toString().replace(/,/g, '');
    }
    if (typeof params.projected_gross_profit !== 'undefined' && params.projected_gross_profit !== null) {
      params.projected_gross_profit = params.projected_gross_profit.toString().replace(/,/g, '');
    }
    if (typeof params.cpa_percentage !== 'undefined' && params.cpa_percentage !== null) {
      params.cpa_percentage = params.cpa_percentage.toString().replace(/,/g, '');
    }
    if (typeof params.content_type !== 'undefined' && params.content_type !== null && params.content_type.value) {
      params.content_type = params.content_type.value;
    }
    params.marketing_campaign = selectedMarketingCampaign;
    params.adminUser = this.props.adminUser._id;
    params.offerPayouts = offerPayouts;
    params.mulligan = mulligan;
    params.submitCpmDeal = isCpmPa;

    this.setState({
      loading: { saving: true },
      hsContactData: {
        existContact: false,
        contact: null,
        type: 'Creator',
      },
    });
    if (enableMockup) {
      const timer = (counter) => {
        if (counter < 50) {
          setTimeout(() => {
            // eslint-disable-next-line no-param-reassign
            counter += 1;
            const paramsCopy = {
              ...params,
              contact_email: `ilqa+${100 + counter}${params.contact_email}`,
              email: `ilqa+${100 + counter}${params.email}`,
              dealname: `${params.dealname} ${100 + counter}`,
            };
            axios.post('/api/portal/deals/save-deal-editor', paramsCopy).then(() => {
              console.log(`Request number ${counter} is done!`);
              if (counter === 50) {
                this.setState({ loading: { saving: false } });
              }
            }).catch((e) => {
              this.setState({ loading: { saving: false } });
              console.log(e);
              console.log(e.message);
              dispatch(addNotice({
                message: 'There was an error creating record, Check out console to see more information.',
                type: 'error',
              }));
            });
            timer(counter);
          }, 10000);
        }
      };
      timer(0);
    } else {
      axios({
        method: 'post',
        url: '/api/portal/deals/save-deal-editor',
        data: params,
      }).then((response) => {
        this.setState({ loading: { saving: false } });
        if (response.data.success === true) {
          dispatch(addNotice({
            message: deal.params._id === undefined ? 'New Deal has been added successfully' : 'Deal data has been saved successfully',
            type: 'success',
          }));
          const searchParams = new URLSearchParams(window.location.search)
          const creatorId = searchParams.get('creatorId');
          if (creatorId) {
            this.setState({
              deal: {
                params: {
                  type_of_deal: 'CPA/Ambassador',
                  cpaType: 'percentage',
                  cpa_percentage: '100',
                  guaranteed_payment_contract_amount: null,
                  projected_gross_profit: null,
                  guaranteed_payment_recoverable: null,
                },
                errors: {},
              },
              selectedOffer: null,
              selectedBrand: null,
              selectedPipeline: null,
              selectedCloneDeal: null,
              cloneDeal: false,
              selectedAgents: [{
                params: {
                  _id: null,
                  firstname: '',
                  lastname: '',
                  hs_object_id: null,
                  email: '--- Please Choose Agent ---',
                },
              }],
              stageOptions: [],
              offerPayouts: [],
            });
          } else if (!this.props.match.params.dealId) {
            this.setState(state => ({
              contact: {
                ...state.contact,
                params: {
                  ...state.contact.params,
                  contactAction: contactStatuses.selectexisting,
                },
              },
            }));
            this.setState({
              selectedCreator: null,
              selectedOffer: null,
              selectedBrand: null,
              selectedPipeline: null,
              selectedCloneDeal: null,
              cloneDeal: false,
              selectedAgents: [{
                params: {
                  _id: null,
                  firstname: '',
                  lastname: '',
                  hs_object_id: null,
                  email: '--- Please Choose Agent ---',
                },
              }],
              stageOptions: [],
              offerPayouts: [],
            });
            if (response.data.dbDealId) {
              this.setState({
                deal: {
                  ...this.state.deal,
                  params: {
                    ...this.state.deal.params,
                    hs_object_id: response.data.hsDealId,
                  },
                  errors: {
                    ...this.state.deal.errors,
                  },
                },
              });
              // this.props.history.push(`/influencers/creator-deal-editor/${response.data.dbDealId}`);
              if (this.state.selectedContact === null) {
                this.handleChangeContactSelect(null);
              }
              this.fetchData(false);
            } else {
              this.props.history.push('/resources/HubilDeal/actions/list');
            }
          }
        } else if (response.data.error.substring(0, 37) === 'E11000 duplicate key error collection') {
          dispatch(addNotice({
            message: 'New Deal has been added successfully',
            type: 'success',
          }));
          console.log('Please check deal...');
        } else {
          dispatch(addNotice({
            message: response.data.error,
            type: 'error',
          }));
        }
      }).catch((e) => {
        this.setState({ loading: { saving: false } });
        console.log(e);
        console.log(e.message);
        dispatch(addNotice({
          message: 'There was an error creating record, Check out console to see more information.',
          type: 'error',
        }));
      });
    }
    return false;
  }
  // eslint-disable-next-line class-methods-use-this
  resetForm() {
    window.location.reload();
  }

  handleAttachAgent(event) {
    const selectedAgent = {
      params: {
        _id: null,
        firstname: '',
        lastname: '',
        hs_object_id: null,
        email: '--- Please Choose Agent ---',
      },
    };
    const { selectedAgents } = this.state;
    selectedAgents.push(selectedAgent);
    this.setState({
      selectedAgents,
    });
    event.persist();
  }

  /* openDialogHsAgent = (index) => {
    this.setState({ hsAgentDialogVisible: true, hsAgentDialogIndex: index });
  };

  closeDialogHsAgent = (newHubilAgent) => {
    const { hsAgentDialogIndex } = this.state;
    this.setState({ hsAgentDialogVisible: false });
    if (newHubilAgent && newHubilAgent._id) {
      const selected = {
        record: {
          params: {
            ...newHubilAgent,
          },
          value: newHubilAgent._id,
          label: newHubilAgent.email,
        },
      };
      this.handleChangeContactAgentSelect(selected, hsAgentDialogIndex);
    }
  }; */

  openDialogAgentUser = (selectedHsContact = null, index = null) => {
    this.setState({
      agentUserDialogVisible: true,
      selectedHsAgent: selectedHsContact !== null && selectedHsContact.record ? selectedHsContact.record : null,
      selectedAgentIndex: index,
    });
  };

  closeDialogAgentUser = (event, reloadAgent = null) => {
    this.setState({
      agentUserDialogVisible: false,
      selectedHsAgent: null,
      selectedAgentIndex: null,
      reloadAgentSelect: reloadAgent,
    });
  };

  clearReloadAgent = () => {
    this.setState({ reloadAgentSelect: null });
  };

  removeAgent = (index) => {
    const { selectedAgents } = this.state;
    selectedAgents.splice(index, 1);
    this.setState({ selectedAgents });
  };

  openDialogMediaChannels = () => {
    this.setState({ mediaChannelsModal: true });
  };

  closeDialogMediaChannels = () => {
    this.setState({ mediaChannelsModal: false });
  };

  handleChangeCloneDeal = (checked) => {
    if (checked) {
      this.setState({
        cloneDeal: checked.currentTarget.value === 'false',
      });
    }
  };

  saveOfferPayouts = (offerPayouts) => {
    this.setState({
      offerPayouts,
    });
  };

  handleChangeCloneDealSelect = (selected) => {
    if (selected) {
      this.setState({
        selectedCloneDeal: {
          id: selected.value,
          title: selected.label,
        },
      });
      axios({
        method: 'get',
        url: `/api/portal/deals/get-data?id=${selected.value}`,
      }).then((response) => {
        if (response.data.success === true) {
          const dealFieldsToSave = [
            'brand',
            'offer',
            'hubspotOwner',
            'pipeline',
            'dealstage',
            'cpa_status',
            'type_of_deal',
            'cpaType',
            'cpa_percentage',
            'per_conversion_amount',
            'flatRate',
            'guaranteed_payment_contract_amount',
            'guaranteed_payment_recoverable',
            'referral_url',
            'coupon_code',
            'youtube_target_url',
            'projected_gross_profit',
            'content_release_date',
            'agency_affiliation',
            'v30_number_of_impressions',
            'content_type',
            'agents',
            'justification',
          ];
          const paramsToPass = {};
          // eslint-disable-next-line array-callback-return
          dealFieldsToSave.map((property) => {
            paramsToPass[property] = response.data.deal.params[property];
          });
          response.data.deal.params = paramsToPass;
          this.saveDealData(response);
          this.prefillDealName();
          this.prefillSelectedAgents(response.data.deal);
        }
      }).catch((error) => {
        console.log('CATCH ERROR: ', error);
        this.props.dispatch(addNotice({
          message: 'An error occurred',
          type: 'warning',
        }));
      });
    }
  };

  contactEmailBlured(e) {
    const { contact } = this.state;
    if (contact.params.contact === null) {
      this.setState(state => ({
        creator: {
          ...state.creator,
          params: {
            ...state.creator.params,
            creatorAction: creatorStatuses.createnew,
            email: e.target.value,
          },
          errors: {
            ...state.creator.errors,
          },
        },
      }));
      if (e.target.value.includes('@') && e.target.value.includes('.')) {
        let contactFound = false;
        axios.get(`/api/portal/hubil-data/get-contact-exist?email=${encodeURIComponent(e.target.value.toLowerCase().trim())}`).then((response) => {
          if (response.data.success) {
            this.setState({
              hsContactData: {
                existContact: response.data.existContact,
                contact: response.data.contact,
                type: response.data.type,
              },
            });
            contactFound = response.data.existContact;
          }
        });
        if (!contactFound) {
          axios.get(`/api/portal/blacklist/get-item-by-email?email=${encodeURIComponent(e.target.value.toLowerCase().trim())}`).then((response) => {
            if (response.data.success) {
              console.log(response.data.result);
              if (response.data.result) {
                this.setState({
                  hsContactInBlacklist: true,
                });
              } else {
                this.setState({
                  hsContactInBlacklist: false,
                });
              }
            }
          });
        }
      }
    }
  }
  prefillExistingAgent(contactId) {
    const { deal } = this.state;
    if (typeof deal.params._id === 'undefined') {
      axios.get(`/api/portal/hubil-data/get-agent-by-research-user?contactId=${contactId}`).then((response) => {
        if (response.data.success && response.data.agent) {
          this.handleChangeEnableAgents({ currentTarget: { value: 'false' } });
          this.setState({
            selectedAgents: [{
              params: {
                _id: response.data.agent._id,
                firstname: response.data.agent.firstname,
                lastname: response.data.agent.lastname,
                hs_object_id: response.data.agent.hs_object_id,
                email: response.data.agent.email,
              },
            }],
          });
        }
      });
    }
  }
  render() {
    const {
      adminUser,
    } = this.props;
    const {
      loading,
      deal,
      contact,
      creator,
      selectedCreator,
      selectedContact,
      // selectedAgentOption,
      selectedDeal,
      selectedCloneDeal,
      selectedOffer,
      selectedBrand,
      selectedPipeline,
      selectedHSOwner,
      selectedHOOwner,
      selectedMarketingCampaign,
      selectedAgents,
      selectedAgencyAffiliation,
      mulligan,
      stageOptions,
      pipelines,
      pipelineChosen,
      allOffersOptions,
      dealsOptions,
      enableAgents,
      enableMockup,
      // hsAgentDialogVisible,
      agentUserDialogVisible,
      selectedHsAgent,
      selectedAgentIndex,
      reloadAgentSelect,
      cloneDeal,
      agencyAffiliations,
      hubilAgentRecord,
      hsContactData,
      dealExistsActive,
      prefillContentTypeStatus,
      marketingCampaignOptions,
      hubilDuplicateModal,
      hubilDuplicateId,
      hsContactInBlacklist,
      hsAgentInBlacklist,
      loadingRedirect,
      redirectUrl,
      qrPopoverOpen,
      showQrCodeTargetId,
      categories,
      contactMediaPlatform,
      isContractSigned,
    } = this.state;

    const selectedDealJustificationOption = (getConstant('deal', 'dealJustification') || []).map(a => ({ value: a, label: a }));
    const setSelectedAgentOption = (selected, index) => { this.handleChangeContactAgentSelect(selected, index); }

    /* const setSelectedAgentOption = (option) => {
      this.setState({
        selectedAgentOption: option,
      });
    }; */

    let selectedCreatorOption = {};
    if (selectedCreator) {
      // console.log('selectedCreator: ', selectedCreator);
      selectedCreatorOption = {
        value: selectedCreator.id,
        label: selectedCreator.title,
        record: {
          params: {
            _id: selectedCreator.id,
            first_name: selectedCreator.params?.first_name,
            last_name: selectedCreator.params?.last_name,
            email: selectedCreator.params?.email,
          },
        },
      };
    }
    let selectedOfferOption = {};
    if (selectedOffer) {
      selectedOfferOption = selectedOffer;
    }
    let selectedBrandOption = {};
    if (selectedBrand) {
      selectedBrandOption = {
        value: selectedBrand.id,
        label: selectedBrand.title,
      };
    }
    let selectedHSOwnerOption = {};
    if (selectedHSOwner && selectedHSOwner._id) {
      selectedHSOwnerOption = {
        value: selectedHSOwner._id,
        label: `${selectedHSOwner.firstName} ${selectedHSOwner.lastName}`,
      };
    } else if (typeof adminUser.hsUserId !== 'undefined' && adminUser.hsUserId !== null) {
      selectedHSOwnerOption = {
        value: adminUser._id,
        label: `${adminUser.firstName} ${adminUser.lastName}`,
      };
    }
    let selectedHOOwnerOption = {};
    if (selectedHOOwner && selectedHOOwner._id) {
      selectedHOOwnerOption = {
        value: selectedHOOwner._id,
        label: `${selectedHOOwner.firstName} ${selectedHOOwner.lastName}`,
      };
    } else if (typeof adminUser.affiliateManagerId !== 'undefined' && adminUser.affiliateManagerId !== null) {
      selectedHOOwnerOption = {
        value: adminUser._id,
        label: `${adminUser.firstName} ${adminUser.lastName}`,
      };
    }
    let selectedMarketingOption = {};
    if (selectedMarketingCampaign && marketingCampaignOptions?.length) {
      const selected = marketingCampaignOptions.find(a => a.value === selectedMarketingCampaign);
      if (selected) {
        selectedMarketingOption = {
          value: selected.value,
          label: `${selected.label}`,
        };
      }
    }
    let selectedContactOption = {};
    if (selectedContact) {
      // console.log('selectedContact: ', selectedContact);
      selectedContactOption = {
        value: selectedContact.params._id,
        label: typeof selectedContact.params.email !== 'undefined' && selectedContact.params.email !== null ? selectedContact.params.email : `${selectedContact.params.firstname} ${selectedContact.params.lastname}`,
        record: {
          params: {
            _id: selectedContact.params._id,
            firstname: selectedContact.params.firstname,
            lastname: selectedContact.params.lastname,
            email: selectedContact.params.email,
            mediaChannelData: selectedContact.params.mediaChannels,
          },
        },
      };
    } else {
      selectedContactOption = {
        value: null,
        label: '',
        record: { params: { _id: null, firstname: '', lastname: '' } },
      };
    }
    let selectedDealOption = {};
    if (selectedDeal) {
      selectedDealOption = {
        value: selectedDeal.id,
        label: selectedDeal.title,
      };
    } else {
      selectedDealOption = {
        value: null,
        label: '--- Create New Deal ---',
      };
    }
    let selectedCloneDealOption = {};
    if (selectedCloneDeal) {
      selectedCloneDealOption = {
        value: selectedCloneDeal.id,
        label: selectedCloneDeal.title,
      };
    }
    let selectedPipelineOption = {};
    if (selectedPipeline) {
      if (selectedPipeline.title === false) {
        pipelines.forEach((pipeline) => {
          if (pipeline.value === selectedPipeline.id) {
            selectedPipeline.title = pipeline.label;
          }
        });
      }
      selectedPipelineOption = {
        value: selectedPipeline.id,
        label: selectedPipeline.title,
      };
    }
    let selectedAgencyAffiliationOption = {};
    if (selectedAgencyAffiliation) {
      if (selectedAgencyAffiliation.title === false) {
        agencyAffiliations.forEach((item) => {
          if (item.value === selectedAgencyAffiliation.id) {
            selectedAgencyAffiliation.title = item.label;
          }
        });
      }
      selectedAgencyAffiliationOption = {
        value: selectedAgencyAffiliation.id,
        label: selectedAgencyAffiliation.title,
      };
    }
    const displayDealStage = deal.params._id === undefined || pipelineChosen;

    const stageValue = stageOptions.filter(option => typeof deal.params !== 'undefined' && typeof deal.params.dealstage !== 'undefined' && option.value === deal.params.dealstage);
    if (stageValue.length === 0 && stageOptions.length > 0) {
      stageValue.push(stageOptions[0]);
    }

    const unsortedOffersOptions = [];
    allOffersOptions.forEach((record) => {
      if (selectedBrand !== null && typeof selectedBrand.params !== 'undefined' && record.advertiser === selectedBrand.params._id) {
        unsortedOffersOptions.push(record);
      }
    });
    const offersOptions = unsortedOffersOptions.sort((a, b) => ((a.label < b.label) ? -1 : 1));

    const mapStatus = (deal.params.type_of_deal !== 'Bonus' ? Object.keys(mapStatusArray) : Object.keys(mapStatusArrayBonus)).map(key => ({ value: key, label: key }));
    const mapGuaranteedPaymentRecoverable = Object.keys(mapGuaranteedPaymentRecoverableArray).map(key => ({ value: key, label: mapGuaranteedPaymentRecoverableArray[key] }));
    const mapType = Object.keys(mapTypeArray).map(key => ({ value: key, label: mapTypeArray[key] }));

    const isEditAndAgentsEnabled = (typeof deal.params !== 'undefined' && typeof deal.params._id !== 'undefined') &&
      ((typeof deal.params.agents !== 'undefined' && deal.params.agents.length > 0) ||
        (typeof deal.params.ilDealData !== 'undefined' && typeof deal.params.ilDealData.agentUsers !== 'undefined' && deal.params.ilDealData.agentUsers.length > 0));
    const displayAgents = enableAgents === null ? isEditAndAgentsEnabled : enableAgents;
    let creatorIdForPayouts;
    if (creator.params.creatorAction === creatorStatuses.createnew) {
      creatorIdForPayouts = 'newcreator';
    // } else if (creator.params.creatorAction === creatorStatuses.donothing) {
    //   creatorIdForPayouts = null;
    } else {
      creatorIdForPayouts = creator.params.creator;
    }

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <Breadcrumbs
              isBackButton
              links={[
                { title: 'Home', path: '/' },
                { title: 'Creators', path: '/resources/Influencer/actions/list' },
                { title: 'Deal Editor', path: false },
              ]}
            />
          </Col>
          <Col>
            <Card>
              <CardTitle className="row justify-content-center">
                <Col md={typeof this.props.match.params.dealId === 'undefined' ? 9 : 12} xs="12">
                  <h3>Creator Deal Editor</h3>
                </Col>
                {typeof this.props.match.params.dealId === 'undefined' && (
                  <Col md="3" xs="12" className="align-right">
                    <StyledButton
                      type="button"
                      className="is-primary"
                      onClick={this.resetForm}
                      style={{ marginBottom: 0 }}
                    >
                      Reset
                    </StyledButton>
                  </Col>
                )}
              </CardTitle>
              <CardBody>
                {loading.form &&
                (
                  <div className="text-center"><Spinner color="primary" size="lg" /></div>
                )
                }
                {!loading.form &&
                (
                  <form onSubmit={this.handleSubmit} className="deal-editor">
                    <h4>Contact Properties</h4>
                    <hr />
                    <div className="grey-small-text grey-minus-margin">Specify the Creator&apos;s HubSpot Contact record details</div>
                    <Row>
                      <Col md={6} style={{ paddingLeft: '50px' }}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Input
                                type="radio"
                                name="contactAction"
                                value={contactStatuses.selectexisting}
                                className="mt-0"
                                checked={contact.params.contactAction === contactStatuses.selectexisting}
                                onChange={this.handleContactTypeChange}
                              />
                            }
                            label="Select an Existing Creator"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6} style={{ paddingLeft: '50px' }}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Input
                                type="radio"
                                name="contactAction"
                                value={contactStatuses.createnew}
                                className="mt-0"
                                checked={contact.params.contactAction === contactStatuses.createnew}
                                onChange={this.handleContactTypeChange}
                              />
                            }
                            label="Create a New Creator"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {contact.params.contactAction === contactStatuses.selectexisting && (
                      <FormGroup>
                        <Label>
                          Contact
                          {selectedContact && typeof selectedContact.params !== 'undefined' && typeof selectedContact.params.hs_object_id !== 'undefined' && (
                            <>
                              {/* eslint-disable-next-line react/jsx-no-target-blank,max-len */}
                              &nbsp;&nbsp;<a title="View Contact on HubSpot" target="_blank" href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${selectedContact.params.hs_object_id}/`}><i className="fas fa-external-link-alt" /></a>
                            </>
                          )}
                        </Label>
                        <SelectAsync
                          // cacheOptions
                          id="hs_creator_contact"
                          value={selectedContactOption}
                          defaultOptions
                          loadOptions={this.loadContactsOptions}
                          onChange={this.handleChangeContactSelect}
                          placeholder="Please Select Contact"
                          formatOptionLabel={formatContactOptionLabel}
                          components={{ Option: contactOption }}
                          isClearable
                        />
                        {hsContactInBlacklist && (
                          <Alert color="warning" className="w-100 mt-3" bordered icon>
                            <p className="2 text-dark">
                              This contact is on the blacklist and blocked from creating new deals. <a href="/influencers/contacts" target="_blank" rel="noopener">Manage Blacklist</a>
                            </p>
                          </Alert>
                        )}
                        {contact.errors.contact && (<span className="text-danger">{contact.errors.contact}</span>)}
                        {hubilAgentRecord && (
                          <Alert color="warning" icon={false} className="mt-1">
                            <span style={{ color: 'black' }}>
                              Contact with email&nbsp;
                              <a
                                title="Link to HubSpot contact page"
                                rel="noreferrer"
                                target="_blank"
                                href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${hubilAgentRecord.params.hs_object_id}/`}
                              >
                                {hubilAgentRecord.params.email}
                              </a>
                              &nbsp;already exists in HubSpot and has Contact Type set to Agent.
                            </span>
                          </Alert>
                        )}
                      </FormGroup>
                    )}
                    {contact.params.contactAction === creatorStatuses.createnew && (
                      <div>
                        <FormGroup>
                          <Label>Contact Email</Label>
                          <Input
                            type="text"
                            id="contact_email"
                            name="contact_email"
                            autoComplete="off"
                            onChange={e => this.handleChangeContact('contact_email', e.target.value)}
                            onBlur={this.contactEmailBlured}
                            value={typeof contact.params !== 'undefined' && typeof contact.params.contact_email !== 'undefined' && contact.params.contact_email !== null ? contact.params.contact_email : ''}
                          />
                          {hsContactInBlacklist && (
                            <Alert color="warning" className="w-100 mt-3">
                              <p className="py-2 text-dark">
                                This contact is on the blacklist and blocked from creating new deals. <a href="/influencers/contacts" target="_blank" rel="noopener">Manage Blacklist</a>
                              </p>
                            </Alert>
                          )}
                          {contact.errors.contact_email && (<span className="text-danger">{contact.errors.contact_email}</span>)}
                          {hsContactData.existContact && hsContactData.contact && (
                            <Alert color="warning" icon={false} className="mt-1">
                              <span style={{ color: 'black' }}>
                                {`${hsContactData.type === 'Creator' ? 'Creator ' : ''}Contact with email `}
                                <a
                                  title="Link to HubSpot contact page"
                                  rel="noreferrer"
                                  target="_blank"
                                  href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${hsContactData.contact.hs_object_id}/`}
                                >
                                  {hsContactData.contact.email}
                                </a>
                                {` already exists in HubSpot${hsContactData.type === 'Creator' ?
                                  '. Use a unique email when creating a new Contact.' :
                                  ' and has Contact Type set to Agent.'}`}
                              </span>
                            </Alert>
                          )}
                        </FormGroup>
                        <div className="grey-small-text grey-minus-margin">Hubspot Contact Email (Required)</div>
                      </div>
                    )}
                    <Row>
                      <Col md="4" sm="6" xs="12">
                        <FormGroup>
                          <Label>First Name</Label>
                          <Input
                            type="text"
                            id="first_name"
                            name="first_name"
                            disabled={hsContactInBlacklist}
                            onChange={e => this.handleChangeContact('first_name', e.target.value)}
                            value={typeof contact.params !== 'undefined' && typeof contact.params.first_name !== 'undefined' && contact.params.first_name !== null ? contact.params.first_name : ''}
                            // invalid={!!deal.errors.coupon_code}
                          />
                          {contact.errors.first_name && (<span className="text-danger">{contact.errors.first_name}</span>)}
                        </FormGroup>
                        <div className="grey-small-text grey-minus-margin">Hubspot Contact First Name (Required)</div>
                      </Col>
                      <Col md="4" sm="6" xs="12">
                        <FormGroup>
                          <Label>Last Name</Label>
                          <Input
                            type="text"
                            id="last_name"
                            name="last_name"
                            disabled={hsContactInBlacklist}
                            onChange={e => this.handleChangeContact('last_name', e.target.value)}
                            value={typeof contact.params !== 'undefined' && typeof contact.params.last_name !== 'undefined' && contact.params.last_name !== null ? contact.params.last_name : ''}
                            // invalid={!!deal.errors.coupon_code}
                          />
                          {contact.errors.last_name && (<span className="text-danger">{contact.errors.last_name}</span>)}
                        </FormGroup>
                        <div className="grey-small-text grey-minus-margin">Hubspot Contact Last Name (Required)</div>
                      </Col>
                      <Col md="4" sm="6" xs="12">
                        <FormGroup>
                          <Label>Company</Label>
                          <Input
                            type="text"
                            id="company"
                            name="company"
                            disabled={hsContactInBlacklist}
                            onChange={e => this.handleChangeContact('company', e.target.value)}
                            value={typeof contact.params !== 'undefined' && typeof contact.params.company !== 'undefined' && contact.params.company !== null ? contact.params.company : ''}
                            // invalid={!!deal.errors.coupon_code}
                          />
                          {contact.errors.company && (<span className="text-danger">{contact.errors.company}</span>)}
                        </FormGroup>
                        <div className="grey-small-text grey-minus-margin">Hubspot Contact Company (Required)</div>
                      </Col>
                    </Row>
                    <br /><br />
                    <h4>Creator Properties</h4>
                    <hr />
                    <div className="grey-small-text grey-minus-margin">Specify the Creator&apos;s HasOffers account details</div>
                    <Row>
                      {/* <Col md={4} style={{ paddingLeft: '50px' }}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Input
                                type="radio"
                                name="creatorAction"
                                value={creatorStatuses.donothing}
                                className="mt-0"
                                checked={creator.params.creatorAction === creatorStatuses.donothing}
                                onChange={this.handleCreatorTypeChange}
                                disabled={hsContactInBlacklist}
                              />
                            }
                            label="Do Not Select a Creator"
                          />
                        </FormGroup>
                      </Col> */}
                      <Col md={6} style={{ paddingLeft: '50px' }}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Input
                                type="radio"
                                name="creatorAction"
                                value={creatorStatuses.selectexisting}
                                className="mt-0"
                                checked={creator.params.creatorAction === creatorStatuses.selectexisting}
                                onChange={this.handleCreatorTypeChange}
                                disabled={hsContactInBlacklist}
                              />
                            }
                            label="Select an Existing Creator"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6} style={{ paddingLeft: '50px' }}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Input
                                type="radio"
                                name="creatorAction"
                                value={creatorStatuses.createnew}
                                className="mt-0"
                                checked={creator.params.creatorAction === creatorStatuses.createnew}
                                onChange={this.handleCreatorTypeChange}
                                disabled={hsContactInBlacklist}
                              />
                            }
                            label="Create a New Creator"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {creator.params.creatorAction === creatorStatuses.selectexisting && (
                      <FormGroup>
                        <Label>Creator</Label>
                        <SelectAsync
                          cacheOptions
                          id="influencer"
                          name="influencer"
                          value={selectedCreatorOption}
                          defaultOptions
                          loadOptions={CreatorDealEditor.loadCreatorsOptions}
                          onChange={this.handleChangeCreatorSelect}
                          placeholder="Please Select Creator"
                          formatOptionLabel={formatCreatorOptionLabel}
                          components={{ Option: creatorOption }}
                          isClearable
                          isDisabled={hsContactInBlacklist}
                        />
                        {creator.errors.creator && (<span className="text-danger">{creator.errors.creator}</span>)}
                      </FormGroup>
                    )}
                    {creator.params.creatorAction === creatorStatuses.createnew && (
                      <>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>Creator Email</Label>
                              <Input
                                type="text"
                                id="creator_email"
                                name="creator_email"
                                autoComplete="off"
                                onChange={e => this.handleChangeCreator('email', e.target.value)}
                                value={typeof creator.params !== 'undefined' && typeof creator.params.email !== 'undefined' && creator.params.email !== null ? creator.params.email : ''}
                                disabled={hsContactInBlacklist}
                                // invalid={!!deal.errors.coupon_code}
                              />
                              {creator.errors.email && (<span className="text-danger">{creator.errors.email}</span>)}
                              {!creator.errors.email && (<div className="grey-small-text">HasOffers Email (Required)</div>)}
                            </FormGroup>
                          </Col>
                          <Col md="3" xs="12">
                            <FormGroup>
                              <Label>
                                Category
                              </Label>
                              <Select
                                value={categories.find(c => String(c.value) === String(creator.params.category || null))}
                                options={categories}
                                onChange={this.handleChangeCategory}
                                placeholder="Please Select Category"
                                isClearable
                              />
                              {creator.errors.category && (<span className="text-danger">{creator.errors.category}</span>)}
                              {!creator.errors.category && (<div className="grey-small-text">Creator Category (Required)</div>)}
                            </FormGroup>
                          </Col>
                          <Col md="3" xs="12">
                            <FormGroup>
                              <Label>Affilite Manager</Label>
                              <SelectAsync
                                cacheOptions
                                id="affiliate_manager"
                                name="affiliate_manager"
                                value={selectedHOOwnerOption}
                                defaultOptions
                                loadOptions={CreatorDealEditor.loadHOOwnerOptions}
                                onChange={this.handleChangeHOOwner}
                                placeholder="Please Select Affilite Manager"
                                isClearable
                                isDisabled={hsContactInBlacklist}
                              />
                              {deal.errors.affiliteManager && (<span className="text-danger">{deal.errors.affiliteManager}</span>)}
                              {!deal.errors.affiliteManager && (<div className="grey-small-text">HubSpot Deal Owner (Required)</div>)}
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                    )}
                    {window.process.env.NODE_ENV === 'local' && creator.params.creatorAction === creatorStatuses.createnew && (
                      <Row>
                        <Col md="6" xs="6">
                          <FormGroup>
                            <Col md="12" xs="12">
                              <Label check>
                                <Input
                                  type="checkbox"
                                  onChange={this.handleChangeEnableMockup}
                                  value={enableMockup}
                                  checked={enableMockup}
                                  name="enableMockup"
                                  className="checkbox-input-static"
                                  disabled={hsContactInBlacklist}
                                />
                                <span>Create mock ups from given data?</span>
                              </Label>
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                    )}

                    <br /><br />
                    <h4>Agent Properties</h4>
                    <hr />
                    <Row>
                      <Col md="6" xs="6">
                        <FormGroup>
                          <Col md="12" xs="12">
                            <Label check>
                              <Input
                                type="checkbox"
                                onChange={this.handleChangeEnableAgents}
                                value={displayAgents}
                                checked={displayAgents}
                                name="enableAgents"
                                className="checkbox-input-static"
                                disabled={hsContactInBlacklist || isContractSigned}
                              />
                              <span>Deal is related to an Agent?</span>
                            </Label>
                            {isContractSigned && (
                              <div className="grey-small-text">Adding new Agent is not allowed (This
                                deal already has signed agreement)</div>
                            )}
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                    {displayAgents && (
                      <>
                        <Row>
                          <Col md="12" xs="12">
                            {selectedAgents.map((agent, index) => {
                              const selectedOption = {
                                value: agent.params._id,
                                label: agent.params.email,
                                record: {
                                  params: {
                                    _id: agent.params._id,
                                    firstname: agent.params.firstname,
                                    lastname: agent.params.lastname,
                                    email: agent.params.email,
                                    mediaChannelData: agent.params.mediaChannels,
                                    hs_object_id: agent.params.hsContactVid,
                                  },
                                },
                              };
                              return (
                                // eslint-disable-next-line react/no-array-index-key
                                <div key={`agent${agent.params._id}-${index}`}>
                                  <Row>
                                    <Col md="6" xs="12" className="pb-2">
                                      <Label>
                                        Select Agent Portal User
                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                        &nbsp;<Link title="Remove Agent from the Deal" style={{ color: 'red' }} to="#" onClick={() => { this.removeAgent(index); }}><DeleteIcon style={{ marginTop: '-3px' }} size="18" /></Link>
                                      </Label>
                                      <FormGroup>
                                        <CreatorDealPortalAgentSelect
                                          hubilAgentRecord={agent}
                                          index={index}
                                          openDialogAgentUser={this.openDialogAgentUser}
                                          reloadAgent={reloadAgentSelect}
                                          afterFetch={this.clearReloadAgent}
                                          selectedAgentOption={selectedOption}
                                          setSelectedAgentOption={selected => setSelectedAgentOption(selected, index)}
                                        />
                                      </FormGroup>
                                      {hsAgentInBlacklist && (
                                        <Alert color="warning" className="w-100 mt-3" bordered icon>
                                          <p className="py-2 text-dark">
                                            This contact is on the blacklist and blocked from creating new deals. <a href="/influencers/contacts" target="_blank" rel="noopener">Manage Blacklist</a>
                                          </p>
                                        </Alert>
                                      )}
                                    </Col>
                                  </Row>
                                </div>);
                            })}
                            {!isContractSigned && (
                              <Link to="#" onClick={this.handleAttachAgent}>Add Agent Portal User</Link>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}
                    <br /><br />
                    <h4>Deal Details</h4>
                    <hr />
                    <div className="grey-small-text grey-minus-margin">Specify the Creator&apos;s HubSpot Deal record details</div>
                    <Row>
                      <Col md="6" xs="6">
                        <FormGroup>
                          <Label>
                            Deal
                            {deal && typeof deal.params !== 'undefined' && typeof deal.params.hs_object_id !== 'undefined' && (
                              <>
                                {/* eslint-disable-next-line react/jsx-no-target-blank,max-len */}
                                &nbsp;&nbsp;<a title="View Deal on HubSpot" target="_blank" href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${deal.params.hs_object_id}`}><i className="fas fa-external-link-alt" /></a>
                              </>
                            )}
                          </Label>
                          <Select
                            id="deal"
                            isDisabled={typeof this.props.match.params.dealId !== 'undefined' || hsContactInBlacklist}
                            value={selectedDealOption}
                            options={dealsOptions}
                            onChange={this.handleChangeDealSelect}
                            placeholder="Please Select Deal"
                            isClearable
                          />
                          {deal.errors.dealId && (<span className="text-danger">{deal.errors.dealId}</span>)}
                        </FormGroup>
                      </Col>
                      {(typeof deal.params._id === 'undefined' || deal.params._id === 'undefined') && (
                        <Col md="6" xs="6">
                          <FormGroup>
                            <Label check style={{ margin: '5px 0 3px 20px' }}>
                              <Input
                                type="checkbox"
                                onChange={this.handleChangeCloneDeal}
                                value={cloneDeal}
                                checked={cloneDeal}
                                name="cloneDeal"
                                className="checkbox-input-static"
                                disabled={hsContactInBlacklist}
                              />
                              <span>Clone Deal?</span>
                            </Label>
                            {cloneDeal && (
                              <>
                                <SelectAsync
                                  id="clone_deal"
                                  cacheOptions
                                  value={selectedCloneDealOption}
                                  defaultOptions
                                  loadOptions={async value => this.loadCloneDealOptions(value, this.state.selectedContact?.params?._id)}
                                  onChange={this.handleChangeCloneDealSelect}
                                  placeholder="Please Select Deal"
                                />
                              </>
                            )}
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col md="6" xs="12">
                        <FormGroup>
                          <Label>Brand</Label>
                          <SelectAsync
                            id="advertiser"
                            cacheOptions
                            value={selectedBrandOption}
                            defaultOptions
                            loadOptions={this.loadBrandsOptions}
                            onChange={this.handleChangeBrand}
                            placeholder="Please Select Brand"
                            isClearable
                            isDisabled={hsContactInBlacklist}
                          />
                          {deal.errors.brand && (<span className="text-danger">{deal.errors.brand}</span>)}
                        </FormGroup>
                      </Col>
                      <Col md="6" xs="12">
                        <FormGroup>
                          <Label>Offer</Label>
                          <Select
                            id="offer"
                            value={selectedOfferOption}
                            options={offersOptions}
                            onChange={this.handleChangeOffer}
                            placeholder="Please Select Offer"
                            isClearable
                            isDisabled={hsContactInBlacklist}
                          />
                          {deal.errors.offer && (<span className="text-danger">{deal.errors.offer}</span>)}
                          {!deal.errors.offer && (<div className="grey-small-text">HasOffers Offer</div>)}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" xs="12">
                        <FormGroup>
                          <Label>Deal Name</Label>
                          <Input
                            type="text"
                            id="dealname"
                            name="dealname"
                            onChange={e => this.handleInputChange('dealname', e)}
                            value={deal?.params?.dealname || ''}
                            disabled={hsContactInBlacklist}
                            // invalid={!!deal.errors.coupon_code}
                          />
                          {deal.errors.dealname && (<span className="text-danger">{deal.errors.dealname}</span>)}
                          {!deal.errors.dealname && (<div className="grey-small-text">HubSpot Deal Name</div>)}
                        </FormGroup>
                      </Col>
                      <Col md="6" xs="12">
                        <FormGroup>
                          <Label>Content Type</Label>
                          <Select
                            id="content_type"
                            name="content_type"
                            isClearable
                            value={typeof deal.params !== 'undefined' && typeof deal.params.content_type !== 'undefined' && deal.params.content_type !== null ? deal.params.content_type : ''}
                            options={contentTypeOptions}
                            onChange={value => this.handleChange('content_type', value)}
                            placeholder=""
                            isDisabled={hsContactInBlacklist}
                          />
                          {deal.errors.content_type && (<span className="text-danger">{deal.errors.content_type}</span>)}
                          {prefillContentTypeStatus && (<span className="text-danger">Press Save to set the Content Type in HubSpot (not currently set)</span>)}
                          {!deal.errors.content_type && (<div className="grey-small-text">HubSpot Deal Content Type</div>)}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3" xs="12">
                        <FormGroup>
                          <Label>Owner</Label>
                          <SelectAsync
                            cacheOptions
                            id="hs_owner"
                            value={selectedHSOwnerOption}
                            defaultOptions
                            loadOptions={CreatorDealEditor.loadHSOwnerOptions}
                            onChange={this.handleChangeHSOwner}
                            placeholder="Please Select HubSpot Owner"
                            isClearable
                            isDisabled={hsContactInBlacklist}
                          />
                          {deal.errors.hsOwner && (<span className="text-danger">{deal.errors.hsOwner}</span>)}
                          {!deal.errors.hsOwner && (<div className="grey-small-text">HubSpot Deal Owner</div>)}
                        </FormGroup>
                      </Col>
                      <Col md="3" xs="12">
                        <FormGroup>
                          <Label>Pipeline</Label>
                          <SelectAsync
                            cacheOptions
                            id="hs_pipeline"
                            value={selectedPipelineOption}
                            defaultOptions
                            loadOptions={this.loadPipelineOptions}
                            onChange={this.handleChangePipeline}
                            placeholder="Please Select Pipeline"
                            isClearable
                            isDisabled={hsContactInBlacklist}
                          />
                          {deal.errors.pipeline && (<span className="text-danger">{deal.errors.pipeline}</span>)}
                          {!deal.errors.pipeline && (<div className="grey-small-text">HubSpot Pipeline</div>)}
                        </FormGroup>
                      </Col>
                      <Col md="3" xs="12">
                        {displayDealStage &&
                        (
                          <FormGroup>
                            <Label>Deal Stage</Label>
                            <Select
                              id="dealstage"
                              name="dealstage"
                              value={stageValue}
                              options={stageOptions}
                              onChange={this.handleSelectChange}
                              isDisabled={hsContactInBlacklist}
                            />
                            {deal.errors.dealstage && (<span className="text-danger">{deal.errors.dealstage}</span>)}
                            {!deal.errors.dealstage && (<div className="grey-small-text">HubSpot Pipeline Deal Stage</div>)}
                          </FormGroup>)
                        }
                      </Col>
                      <Col md="3" xs="12">
                        <FormGroup>
                          <Label>Marketing Campaign</Label>
                          <Select
                            id="marketing_campaign"
                            name="marketing_campaign"
                            value={selectedMarketingOption}
                            options={marketingCampaignOptions}
                            onChange={(e) => {
                              this.setState({
                                selectedMarketingCampaign: e.value,
                              });
                            }}
                            placeholder="Please Select Marketing Campaign"
                            isDisabled={hsContactInBlacklist}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <br /><br />
                    <h4>Deal Terms</h4>
                    <hr />
                    <div>
                      <Row>
                        <Col md={3}>
                          <FormGroup>
                            <Label>Deal Type</Label>
                            <Select
                              id="type_of_deal"
                              name="type_of_deal"
                              value={mapType.filter(option => typeof deal.params !== 'undefined' && typeof deal.params.type_of_deal !== 'undefined' && option.value === deal.params.type_of_deal)}
                              options={mapType}
                              onChange={this.handleDealTypeChange}
                              placeholder="Select Deal Type"
                              isDisabled={hsContactInBlacklist}
                            />
                            {deal.errors.type_of_deal && (<span className="text-danger">{deal.errors.type_of_deal}</span>)}
                            {deal?.params?.bill?.paymentStatus === 'paidinfull' && (
                            <Alert color="warning" icon={false} className="mt-1">
                              <span style={{ color: 'black' }}>
                                This deal{'\''}s upfront payment has already been paid with Invoice{' '}
                                <a
                                  href={`/resources/Bill/actions/list?filters.fullSearchName=${deal?.params?.bill?.invoiceNumber}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {deal?.params?.bill?.invoiceNumber}
                                </a>. Do not change the Deal Type.
                              </span>
                            </Alert>)}
                          </FormGroup>
                        </Col>
                        {/* <Col md={6}>
                          <FormGroup>
                            <Label>{`Start Date (${currentTimezoneAggr()})`}</Label>
                            <DatePicker
                              onChange={this.handleDatepickerChange}
                              selected={typeof deal.params !== 'undefined' && typeof deal.params.start_date !== 'undefined' && deal.params.start_date !== null ? momentUTCToLocalDate(moment(deal.params.start_date)).toDate() : null}
                              dateFormat="MM/dd/yyyy"
                              placeholderText="Start Date"
                              dropDownMode="select"
                              className="form-control"
                              style={{ width: '100%' }}
                            />
                            {deal.errors.start_date && (<span className="text-danger">{deal.errors.start_date}</span>)}
                          </FormGroup>
                          </Col> */}
                        <Col md={3}>
                          <FormGroup>
                            <Label>Status</Label>
                            <Select
                              name="cpa_status"
                              id="cpa_status"
                              value={mapStatus.filter(option => typeof deal.params !== 'undefined' && typeof deal.params.cpa_status !== 'undefined' && option.value === deal.params.cpa_status)}
                              options={mapStatus}
                              onChange={this.handleSelectChange}
                              placeholder="Select Status"
                              isClearable
                              isDisabled={hsContactInBlacklist}
                            />
                            {deal.errors.cpa_status && (<span className="text-danger">{deal.errors.cpa_status}</span>)}
                            {dealExistsActive && (
                              <Alert color="warning">
                                <span style={{ color: 'black' }}>
                                  An existing Active deal already exists for this Brand and Offer - <HubspotDealLink id={dealExistsActive.hs_object_id} title={dealExistsActive.dealname} />. Only 1 Active deal is allowed.
                                </span>
                              </Alert>)}
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <FormGroup>
                            <Label>{`Content Release Date (${currentTimezoneAggr()})`}</Label>
                            <DatePicker
                              id="content_release_date"
                              onChange={this.handleContentReleaseDatepickerChange}
                              selected={
                                typeof deal.params !== 'undefined' && typeof deal.params.content_release_date !== 'undefined' && deal.params.content_release_date !== null ?
                                  momentUTCToLocalDate(moment(deal.params.content_release_date)).toDate() :
                                  null}
                              dateFormat="MM/dd/yyyy"
                              placeholderText="Content Release Date"
                              dropDownMode="select"
                              className="form-control"
                              readOnly={!this.state.enableContentReleaseDate}
                              style={{ width: '100%' }}
                              disabled={hsContactInBlacklist}
                            />
                            {deal.errors.content_release_date && (<span className="text-danger">{deal.errors.content_release_date}</span>)}
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <FormGroup>
                            <Label>Mulligan?</Label>
                            <Select
                              name="mulligan"
                              id="mulligan"
                              value={yesNoOptions.filter(option => option.value === mulligan)}
                              options={yesNoOptions}
                              onChange={(selected) => { this.setState({ mulligan: selected?.value }); }}
                              placeholder=""
                              isDisabled={hsContactInBlacklist}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {deal.params?.type_of_deal !== 'Media License' && deal.params?.type_of_deal !== 'Bonus' && (
                        <OfferPayoutsForm
                          offerId={typeof selectedOffer !== 'undefined' && selectedOffer !== null && typeof selectedOffer.value !== 'undefined' ? selectedOffer.value : null}
                          creatorId={creatorIdForPayouts}
                          offerName={typeof selectedOffer !== 'undefined' && selectedOffer !== null && typeof selectedOffer.label !== 'undefined' ? selectedOffer.label : null}
                          saveOfferPayouts={this.saveOfferPayouts}
                          dealType={deal.params?.type_of_deal}
                        />
                      )}
                    </div>
                    {typeof deal.params !== 'undefined' && deal.params.type_of_deal !== 'Bonus'
                      && deal.params.type_of_deal !== 'Upfront Only' && deal.params.type_of_deal !== 'Media License'
                      && deal.params.type_of_deal !== 'Upfront CPM' && (
                      <Row>
                        <Col md={6} style={{ paddingLeft: '50px' }}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Input
                                  type="radio"
                                  id="cpaType_percentage"
                                  name="cpaType"
                                  value="percentage"
                                  className="mt-0"
                                  checked={typeof deal.params === 'undefined' || typeof deal.params.cpaType === 'undefined' || deal.params.cpaType === 'percentage'}
                                  onChange={this.handleElementChange}
                                  disabled={hsContactInBlacklist}
                                />
                              }
                              label="Percentage"
                            />
                            <PercentsField
                              name="cpa_percentage"
                              disabled={!(typeof deal.params === 'undefined' || typeof deal.params.cpaType === 'undefined' || deal.params.cpaType === 'percentage') || hsContactInBlacklist}
                              defaultValue={typeof deal.params !== 'undefined' && typeof deal.params.cpa_percentage !== 'undefined' && deal.params.cpa_percentage !== null ? deal.params.cpa_percentage.toString() : '100'}
                              onBlur={this.handleElementChange}
                            />
                            {deal.errors.cpa_percentage && (<span className="text-danger">{deal.errors.cpa_percentage}</span>)}
                          </FormGroup>
                        </Col>
                        <Col md={6} style={{ paddingLeft: '50px' }}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Input
                                  type="radio"
                                  id="cpaType_flatRate"
                                  name="cpaType"
                                  value="flatRate"
                                  className="mt-0"
                                  checked={typeof deal.params !== 'undefined' && deal.params.cpaType === 'flatRate'}
                                  onChange={this.handleElementChange}
                                  disabled={hsContactInBlacklist}
                                />
                              }
                              label="Flat Rate Per Conversion"
                            />
                            <CurrencyField
                              name="per_conversion_amount"
                              className="form-control"
                              disabled={!(typeof deal.params !== 'undefined' && deal.params.cpaType === 'flatRate') || hsContactInBlacklist}
                              defaultValue={typeof deal.params !== 'undefined' &&
                              typeof deal.params.per_conversion_amount !== 'undefined' &&
                              deal.params.per_conversion_amount !== null &&
                              deal.params.per_conversion_amount !== undefined ? parseFloat(deal.params.per_conversion_amount.toString().replace(/,/g, '')) : 0}
                              onBlur={this.handleElementChange}
                            />
                            {deal.errors.flatRate && (<span className="text-danger">{deal.errors.flatRate}</span>)}
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    {typeof deal.params !== 'undefined' && deal.params.type_of_deal !== 'CPA/Ambassador' && (
                      <Row>
                        <Col md={deal?.params?.type_of_deal === 'Upfront CPM' ? 3 : 12}>
                          <Label>{deal.params?.type_of_deal === 'Bonus' ? 'Bonus Amount' : 'Contract Amount'}</Label>
                          <CurrencyField
                            name="guaranteed_payment_contract_amount"
                            className="form-control"
                            disabled={deal?.params?.bill?.paymentStatus === 'paidinfull' || hsContactInBlacklist}
                            defaultValue={typeof deal.params !== 'undefined' &&
                            typeof deal.params.guaranteed_payment_contract_amount !== 'undefined' &&
                            deal.params.guaranteed_payment_contract_amount !== null &&
                            deal.params.guaranteed_payment_contract_amount !== undefined ? parseFloat(deal.params.guaranteed_payment_contract_amount.toString().replace(/,/g, '')) : 0}
                            onBlur={this.handleElementChange}
                          />
                          {deal.errors.guaranteed_payment_contract_amount && (<span className="text-danger">{deal.errors.guaranteed_payment_contract_amount}</span>)}
                          {deal?.params?.bill?.paymentStatus === 'paidinfull' && (
                            <Alert color="warning" icon={false} className="mt-1">
                              <span style={{ color: 'black' }}>
                                This deal{'\''}s upfront payment has already been paid with Invoice{' '}
                                <a
                                  href={`/resources/Bill/actions/list?filters.fullSearchName=${deal?.params?.bill?.invoiceNumber}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {deal?.params?.bill?.invoiceNumber}
                                </a>. Do not change the Contract Amount.
                              </span>
                            </Alert>)}
                          <br />
                        </Col>
                        {deal?.params?.type_of_deal === 'Upfront CPM' && [
                          <Col md={3} key="0">
                            <FormGroup>
                              <Label>Agency Affiliation</Label>
                              <SelectAsync
                                cacheOptions
                                value={selectedAgencyAffiliationOption}
                                defaultOptions
                                loadOptions={this.loadAgencyAffiliationOptions}
                                onChange={this.handleChangeAgencyAff}
                                placeholder="Select Agency Affiliation"
                                isClearable
                                isDisabled={hsContactInBlacklist}
                              />
                              {deal.errors.agency_affiliation && (<span className="text-danger">{deal.errors.agency_affiliation}</span>)}
                            </FormGroup>
                          </Col>,
                          <Col md={3} key="1">
                            <FormGroup>
                              <Label>Justification</Label>
                              <Select
                                name="justification"
                                id="justification"
                                value={selectedDealJustificationOption.filter(option => typeof deal.params !== 'undefined' && typeof deal.params.justification !== 'undefined' && option.value === deal.params.justification)}
                                options={selectedDealJustificationOption}
                                onChange={this.handleSelectChange}
                                placeholder=""
                                isClearable
                                isDisabled={hsContactInBlacklist}
                              />
                              {deal.errors.justification && (<span className="text-danger">{deal.errors.justification}</span>)}
                            </FormGroup>
                          </Col>,
                          <Col md={3} key="2" className="ml-auto">
                            <Label>Projected Gross Profit</Label>
                            <CurrencyField
                              name="projected_gross_profit"
                              className="form-control"
                              defaultValue={typeof deal.params !== 'undefined' &&
                              typeof deal.params.guaranteed_payment_contract_amount !== 'undefined' &&
                              deal.params.guaranteed_payment_contract_amount !== null &&
                              deal.params.guaranteed_payment_contract_amount !== undefined ? (parseFloat(deal.params.guaranteed_payment_contract_amount.toString().replace(/,/g, '')) * 0.15) : 0}
                              onBlur={() => {}}
                              disabled
                            />
                            <br />
                          </Col>,
                        ]}
                      </Row>
                    )}
                    {deal?.params?.type_of_deal === 'Upfront CPM' && (
                      <>
                      <Row>
                        <Col md={4}>
                          <Label>YouTube Target Url</Label>
                          <Input
                            type="text"
                            id="youtube_target_url"
                            name="youtube_target_url"
                            disabled={hsContactInBlacklist}
                            onChange={e => this.handleYouTubeUrlChange(e)}
                            value={typeof deal.params !== 'undefined' && typeof deal.params.youtube_target_url !== 'undefined' && deal.params.youtube_target_url !== null ? deal.params.youtube_target_url : ''}
                          />
                          {deal.errors.youtube_target_url && (<span className="text-danger">{deal.errors.youtube_target_url}</span>)}
                          <br />
                        </Col>
                        <Col md={4} className="ml-auto">
                          <Label>V30/Number of Impressions</Label>
                          <Input
                            type="number"
                            id="v30_number_of_impressions"
                            name="v30_number_of_impressions"
                            disabled={hsContactInBlacklist}
                            onChange={e => this.handleInputChange('v30_number_of_impressions', e)}
                            value={typeof deal.params !== 'undefined' && typeof deal.params.v30_number_of_impressions !== 'undefined' && deal.params.v30_number_of_impressions !== null ? deal.params.v30_number_of_impressions : ''}
                          />
                          {deal.errors.v30_number_of_impressions && (<span className="text-danger">{deal.errors.v30_number_of_impressions}</span>)}
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label>Notes</Label>
                            <Input
                              type="textarea"
                              id="notes"
                              name="notes"
                              disabled={loading.saving || hsContactData.existContact || hsContactInBlacklist || hsAgentInBlacklist || !!deal.params.dealCpmProspect}
                              onChange={e => this.handleInputChange('notes', e)}
                              value={deal.params?.notes || ''}
                            />
                            <p>These notes are for pricing approval only and will only be saved when submitting for Price Approval. </p>
                        </FormGroup>
                        </Col>
                      </Row>
                    </>
                    )}
                    {typeof deal.params !== 'undefined' && deal.params.type_of_deal === 'Hybrid' && (
                      <div>
                        <FormGroup>
                          <Label>Guaranteed Payment Recoverable</Label>
                          <Select
                            name="guaranteed_payment_recoverable"
                            id="guaranteed_payment_recoverable"
                            /* eslint-disable-next-line max-len */
                            value={mapGuaranteedPaymentRecoverable.filter(option => typeof deal.params !== 'undefined' && typeof deal.params.guaranteed_payment_recoverable !== 'undefined' && option.value === deal.params.guaranteed_payment_recoverable)}
                            options={mapGuaranteedPaymentRecoverable}
                            onChange={this.handleSelectChange}
                            placeholder="Select value"
                            isClearable
                            isDisabled={hsContactInBlacklist}
                          />
                          {deal.errors.guaranteed_payment_recoverable && (<span className="text-danger">{deal.errors.guaranteed_payment_recoverable}</span>)}
                        </FormGroup>
                      </div>
                    )}
                    { /* hey */ }
                    {deal.params?.type_of_deal !== 'Media License' && deal.params?.type_of_deal !== 'Bonus' && (
                      <Row>
                        <Col lg="6">
                          <FormGroup key="1">
                            <Label>Referral Url</Label>
                            <InputGroup size="md">
                              <Input
                                type="text"
                                id="referral_url"
                                name="referral_url"
                                disabled={hsContactInBlacklist}
                                onChange={e => this.handleInputChange('referral_url', e)}
                                value={typeof deal.params !== 'undefined' && typeof deal.params.referral_url !== 'undefined' && deal.params.referral_url !== null ? deal.params.referral_url : ''}
                                // invalid={!!deal.errors.coupon_code}
                              />
                              {!loadingRedirect && redirectUrl && (
                                <InputGroupAddon addonType="append">
                                  <Popover
                                    id="popoverQrCode"
                                    placement="right"
                                    isOpen={qrPopoverOpen}
                                    target={showQrCodeTargetId}
                                    trigger="click"
                                    toggle={this.toggleQrPopover}
                                  >
                                    <PopoverBody
                                      style={{ width: '218px', height: '240px' }}
                                    >
                                      <div
                                        ref={this.wrapperRef}
                                      >
                                        <a
                                          className="il-color-deep-blue bold-text"
                                          role="button"
                                          onClick={() => this.downloadQr()}
                                          style={{ fontSize: '150%' }}
                                        >
                                          Download QR Code
                                        </a>
                                        <ReactQr
                                          text={`${redirectUrl.trim()}${redirectUrl.trim().indexOf('?') !== -1 ? '&' : '?'}aff_unique1=qr`}
                                          size={1000}
                                          callback={this.setQrCodeBase64}
                                        />
                                      </div>
                                    </PopoverBody>
                                  </Popover>
                                  <Tooltip title="Show QR Code">
                                    <button
                                      id={showQrCodeTargetId}
                                      style={{
                                        maxHeight: '38px',
                                        border: '1px solid #ced4da',
                                        borderRadius: '0 0.25rem 0.25rem 0',
                                      }}
                                      type="button"
                                      className={`form__form-group-button${qrPopoverOpen ? ' active' : ''}`}
                                      onClick={this.toggleQrPopover}
                                    ><QrcodeIcon />
                                    </button>
                                  </Tooltip>
                                </InputGroupAddon>
                              )}
                            </InputGroup>
                            {deal.errors.referral_url && (<span className="text-danger">{deal.errors.referral_url}</span>)}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup key="2">
                            <Label>Promotion Code</Label>
                            <Input
                              type="text"
                              id="coupon_code"
                              name="coupon_code"
                              disabled={hsContactInBlacklist}
                              onChange={e => this.handleInputChange('coupon_code', e)}
                              value={typeof deal.params !== 'undefined' && typeof deal.params.coupon_code !== 'undefined' && deal.params.coupon_code !== null ? deal.params.coupon_code : ''}
                              // invalid={!!deal.errors.coupon_code}
                            />
                            {deal.errors.coupon_code && (<span className="text-danger">{deal.errors.coupon_code}</span>)}
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    {deal?.params?.type_of_deal === 'Upfront CPM' && (
                      <Row>
                        <Col xs={4}>
                          <FormGroup>
                            <Label>Platform</Label>
                            <Select
                              name="media_platform"
                              id="media_platform"
                              value={contactMediaPlatform}
                              options={contentTypeOptions}
                              onChange={this.handlePlatformChange}
                              placeholder="Select platform"
                              // isClearable
                              isDisabled={hsContactInBlacklist || contact?.params?.mediaChannels?.youtube_channel_url || true}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={8}>
                          <FormGroup>
                            <Label>Media Url</Label>
                            <Input
                              type="text"
                              id="youtube_channel_url"
                              name="youtube_channel_url"
                              disabled={hsContactInBlacklist || contact?.params?.mediaChannels?.youtube_channel_url}
                              onChange={e => this.handleChangeContactMediaChannels('youtube_channel_url', e.target.value)}
                              /* eslint-disable-next-line max-len */
                              value={typeof contact.params !== 'undefined' && typeof contact.params.mediaChannels?.youtube_channel_url !== 'undefined' && contact.params.mediaChannels?.youtube_channel_url !== null ? contact.params.mediaChannels.youtube_channel_url : ''}
                            />
                            {contact.errors.youtube_channel_url && (<span className="text-danger">{contact.errors.youtube_channel_url}</span>)}
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    <br /><br />
                    <Row>
                      <Col xs={deal?.params?.type_of_deal === 'Upfront CPM' ? 6 : 12}>
                        <FormGroup style={{ textAlign: deal?.params?.type_of_deal === 'Upfront CPM' ? 'right' : 'center' }}>
                          <StyledButton
                            type="submit"
                            className="is-primary"
                            disabled={loading.saving || hsContactData.existContact || hsContactInBlacklist || hsAgentInBlacklist}
                          >
                            {!loading.saving ? '' : <><Spinner color="primary" size="sm" />&nbsp;&nbsp;</>}
                            <i className="icomoon-save" />
                            <span className="btn-text">Save</span>
                          </StyledButton>
                        </FormGroup>
                      </Col>
                      {deal?.params?.type_of_deal === 'Upfront CPM' && (
                        <Col xs={6}>
                          <FormGroup style={{ textAlign: 'left' }}>
                            <StyledButton
                              name="submitPA"
                              id="submitPA"
                              // type="submit"
                              className="is-primary mb-1"
                              onClick={(e) => { this.handleSubmit(e, true); }}
                              disabled={loading.saving || hsContactData.existContact || hsContactInBlacklist || hsAgentInBlacklist || !!deal.params.dealCpmProspect}
                            >
                              {!loading.saving ? '' : <><Spinner color="primary" size="sm" />&nbsp;&nbsp;</>}
                              <span>Save and Submit for Price Approval</span>
                            </StyledButton>
                            {!!deal.params.dealCpmProspect ? (<div><small>Note: already submitted for pricing approval</small></div>) : null}
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                  </form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <AgentForm
          modal={agentUserDialogVisible}
          modalTitle="Create New Agent Portal User Account"
          closeDialog={this.closeDialogAgentUser}
          hubilAgent={selectedHsAgent}
          setSelectedAgentOption={selected => setSelectedAgentOption(selected, selectedAgentIndex)}
        />
        <CreatorDealPortalHubilDuplicateModal
          modal={hubilDuplicateModal}
          setModalVisible={this.setHubilDuplicateModal}
          hsObjectId={hubilDuplicateId}
        />
      </Container>
    );
  }
}

CreatorDealEditor.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  adminUser: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  globalSelectedInfluencer: PropTypes.objectOf(PropTypes.any),
  globalSelectedContact: PropTypes.objectOf(PropTypes.any),
};

CreatorDealEditor.defaultProps = {
  globalSelectedInfluencer: null,
  globalSelectedContact: null,
};

const mapStateToProps = state => ({
  adminUser: state.session,
  globalSelectedInfluencer: (state.globalSelected.params.influencer || null),
  globalSelectedContact: (state.globalSelected.params.contact || null),
});


export default connect(mapStateToProps)(CreatorDealEditor);
