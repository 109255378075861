import React, { useState, useEffect } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  FormGroup, 
  Modal,
  Button,
  Spinner,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { axios } from 'ApiClient';
import { io } from 'socket.io-client';
import { useLocation } from 'react-router-dom';
import withNotice from '../../../App/store/with-notice';
import Breadcrumb from '@/shared/components/BreadCrumbs';
import useDomains from './components/useDomains';
import RedirectDomainForm from './components/RedirectDomainForm';
import Alert from '@/shared/easydev2/shared/components/Alert';

const webSocketChannelName = 'redirect-domains';
const webSocketChannelErrorName = 'redirect-domains-errors';

const ManageRedirects = ({ addNotice }) => {
  const domains = useDomains();
  const [selectedDomain, setSelectedDomaintValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [isRedirectDomainFormOpen, setIsRedirectDomainFormOpen] = useState(false);
  const [backendMessage, setBackendMessage] = useState('');
  const [backendErrorMessage, setBackendErrorMessage] = useState('');
  const [goDaddyGreenLight, setGoDaddyGreenLight] = useState(false);
  const [isGoDaddyModalOpen, setIsGoDaddyModalOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    let socket = null;
    if (window.location.hostname.indexOf('localportal') !== -1) {
      const url = `http://${window.location.hostname}:${8080}`;
      socket = io(url, { transports: ['websocket'] });
    } else {
      socket = io({ transports: ['websocket'] });
    }
    
    socket.on(webSocketChannelName, (data) => {
      console.log('data', data);
      if('DONE' === data) {
        setBackendErrorMessage('');
        setIsRedirectDomainFormOpen(true);
        setLoading(false);
        setBackendMessage('Domain has been created successfully!');
      } else {
        setBackendMessage(data);
      }
    });

    socket.on(webSocketChannelErrorName, (data) => {
      if (data.includes('PENDING_VALIDATION')) {
        setLoading(false);
        setBackendMessage(`Failed at step: ${backendMessage}`);
      }
      setBackendErrorMessage(data);
    });

    return function cleanup() {
      socket.close();
    };
  }, [location.pathname]);
  
  const toggleRedirectDomainForm = () => {
    setIsRedirectDomainFormOpen(!isRedirectDomainFormOpen);
  }

  const domainDropDownUpdate = async (value) => {
    setSelectedDomaintValue(value);
    if(backendErrorMessage) {
      setBackendErrorMessage('');
    }
  }

  const createHostedZone = async () => {
    if (!selectedDomain || !selectedDomain.value) {
      setBackendErrorMessage('Select a domain from dropdown list.');
      setBackendMessage(''); 
      return false;
    }
    if((selectedDomain?.location?.toLowerCase() === 'godaddy') && !goDaddyGreenLight) {
      setIsGoDaddyModalOpen(true);
      return false;
    }

    setBackendErrorMessage('');
    setLoading(true);
    axios({
      method: 'post',
      url: '/api/portal/redirects/create-hosted-zone',
      data : { 
        'domainName': selectedDomain.value,
        'domainLocation': selectedDomain.location,
      },
    }).then((response) => { 
      console.log('createHostedZone response', response.data);
      addNotice({
        message: `Creating RedirectDomain '${selectedDomain.value}'. This could take from a few minutes to an hour. Please wait.`,
        type: 'success',
      });
    });
  }

  return (
    <Container>
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'Redirect Domain', path: '/resources/RedirectDomain/actions/list?page=1' },
          { title: 'Setup Domain Infrustracture', path: false },
        ]}
        isBackButton
      />
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <h3 className="il-color-deep-blue font-weight-bold">Add brand domain</h3>
              <hr className="mt-3 mb-4" />
              <label>Choose available brand domain:</label>
              <FormGroup style={{ minWidth: '210px', maxWidth: '410px', marginBottom: 0 }}>
                <Select
                  value={selectedDomain}
                  onChange={domainDropDownUpdate}
                  options={domains}
                  menuPlacement="auto"
                  menuPortalTarget={document.body}
                />
              </FormGroup>
              <br />
              <p className="text-danger">{backendErrorMessage}</p>
              <p className="text-success">{backendMessage}</p>
              <br />
              { loading ? (
                <Spinner color="primary" size="lg" />) : (
                <Button
                  color="primary"
                  disabled={loading || domains.length === 0}
                  onClick={createHostedZone}>
                    Create
                </Button>
                )
              }
              <Modal
                isOpen={isRedirectDomainFormOpen}
                toggle={toggleRedirectDomainForm}
              >
                <RedirectDomainForm 
                  brandName={selectedDomain.value}
                  onSubmit={() => {}}
                  setBackendMessage={setBackendMessage}
                  toggleRedirectDomainForm={toggleRedirectDomainForm}
                  setSelectedDomaintValue={setSelectedDomaintValue}
                />
              </Modal>
              <Modal
                isOpen={isGoDaddyModalOpen}
                toggle={() => setIsGoDaddyModalOpen(!isGoDaddyModalOpen)}
              >
                <Row>
                  <Col sm={12}>
                    <Alert color="warning" className="w-100">
                      <p className="py-2 text-dark">
                        Check to make sure that GoDaddy domain is parked and not currently in use!
                        <br />
                        Otherwise, this will delete all prevsious settings.
                      </p>
                      <p className="py-2 text-dark">
                        Once confirmed, click on "Create" button again to proceed.
                      </p>
                    </Alert>
                  </Col>
                </Row>
                <Row>
                  &nbsp;
                </Row>
                <Row>
                  <Col sm={6}>
                    <Button
                      color="primary"
                      className="float-left"
                      disabled={loading || domains.length === 0}
                      onClick={() => {
                        setGoDaddyGreenLight(true);
                        setIsGoDaddyModalOpen(false);
                      }}
                    >
                      Confirm
                    </Button>
                  </Col>
                  <Col sm={6}>
                    <Button
                      color="secondary"
                      className="float-right"
                      onClick={() => setIsGoDaddyModalOpen(false)}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

ManageRedirects.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  rtl: state.rtl,
});

export default withNotice(connect(mapStateToProps)(ManageRedirects));
