
import moment from 'moment';
import func from '../../../../../../shared/hooks';

export default ({ brandOptions, employees }) => [
  {
    initialState: moment().startOf('month').format('YYYY-MM-DD'),
    func: ({ filters, initialState}) => filters['winnerDatetime.from'] || initialState || '',
    filterName: 'winnerDatetime.from',
    funcToUrl: ({ search, item }) => {
      if (item) {
        search.set('filters.winnerDatetime.from', item);
      } else {
        search.delete('filters.winnerDatetime.from');
      }
      return search;
    },
  },
  {
    initialState: moment().format('YYYY-MM-DD'),
    func: ({ filters, initialState }) =>filters['winnerDatetime.to'] || initialState || '',
    filterName: 'winnerDatetime.to',
    funcToUrl: ({ search, item }) => {
      if (item) {
        search.set('filters.winnerDatetime.to', item);
      } else {
        search.delete('filters.winnerDatetime.to');
      }
      return search;
    },
  },
  {
    initialState: 'current_month',
    func: ({ filters, initialState }) => filters['winnerDatetime.selectedButton'] || initialState || '',
    filterName: 'winnerDatetime.selectedButton',
    funcToUrl: ({ search, item }) => {
      if (item) {
        search.set('filters.winnerDatetime.selectedButton', item);
      } else {
        search.delete('filters.winnerDatetime.selectedButton');
      }
      return search;
    },
  },
  {
    initialState: 'accepted',
    func: ({ filters, initialState }) => filters['status'] || initialState || '',
    filterName: 'status',
    funcToUrl: ({ search, item }) => {
      if (item) {
        search.set('filters.status', item);
      } else {
        search.delete('filters.status');
      }
      return search;
    },
  },
  {
    initialState: { tags: [] },
    func: func.getFromUrlSelectfunction,
    funcToUrl: func.getToUrlSelectfunction,
    options: brandOptions,
    filterName: 'brand',
  },
  {
    initialState: { tags: [] },
    func: func.getFromUrlSelectfunction,
    funcToUrl: func.getToUrlSelectfunction,
    options: employees,
    filterName: 'affiliateManager',
  },

];
