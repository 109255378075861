import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import formatter from '../../../../shared/helpers/WVFormatter';
import CreatorInList from './Table/CreatorInList';
import CAC from './Table/CAC';

const getCellStyles = (record) => {
  if (record?.creator?.inBlackList) {
    return { color: '#ff4861', fontWeight: 'bold' };
  }
  return {};
};

const getCACValueForSorting = (value, positive) => {
  let result = 0.001;
  if (typeof value !== 'undefined' && value !== null) {
    if (typeof value === 'string') {
      if (/^[0-9]+(\.[0-9]+)?$/.test(value)) {
        result = Number(value);
      }
    } else result = value;
  }

  return positive ? result : -result;
};

const ColumnsCreators = () => {
  return [
    {
      Header: 'Creator',
      accessor: record => `${record?.creator?.first_name || ''} ${record?.creator?.last_name || ''}`,
      id: 'Creator',
      Cell: record => useMemo(() => (
        <CreatorInList record={record?.row?.original?.creator} prospects={record?.row?.original?.prospects} />
      ), [record?.row?.original?.creator, record?.row?.original?.prospects]),
      width: 300,
    },
    {
      Header: 'Account Manager',
      accessor: record => (`${record?.latestDealData?.admin?.firstName || ''} ${record?.latestDealData?.admin?.lastName || ''}`),
      id: 'accountManager',
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        return (
          <span style={styles}>
            {record?.value}
          </span>
        );
      },
      width: 100,
    },
    {
      Header: 'Brand',
      accessor: record => (record?.latestDealData?.brand || ''),
      id: 'brand',
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        return (
          <span style={styles}>
            {record?.value}
          </span>
        );
      },
      width: 100,
    },
    {
      Header: 'New or Existing',
      accessor: record => (record?.isNew ? 'New' : 'Existing'),
      id: 'isNew',
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        return (
          <span style={styles}>
            {record?.value}
          </span>
        );
      },
      width: 100,
    },
    {
      Header: 'Recent Publish Date',
      accessor: record => record?.latestDealData?.publishDate || null,
      id: 'publishDate',
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        return (
          <span style={styles}>
            {record?.value ? moment.tz(moment.utc(record.value).format('YYYY-MM-DD'), 'America/New_York').format('MM/DD/YYYY') : ''}
          </span>
        );
      },
      width: 100,
    },
    {
      Header: 'Type Of Deal',
      accessor: record => (record.latestDealData?.typeOfDeal || ''),
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        return (
          <span style={styles}>
            {record?.value}
          </span>
        );
      },
      width: 125,
    },
    {
      Header: 'Spend In Timeframe',
      accessor: record => Number(record?.totalSpendInTimeframe || 0),
      id: 'totalSpendInTimeframe',
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        return (
          <span style={styles}>
            {formatter.formatCurrency(record?.value || 0)}
          </span>
        );
      },
      width: 120,
    },
    {
      Header: 'Spend To Date',
      accessor: record => Number(record?.totalSpendToDate || 0),
      id: 'totalSpendToDate',
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        return (
          <span style={styles}>
            {formatter.formatCurrency(record?.value || 0)}
          </span>
        );
      },
      width: 120,
    },
    {
      Header: 'Revenue Conversions In Timeframe',
      accessor: record => record?.totalConversionsInTimeframe,
      id: 'totalConversionsInTimeframe',
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        return (
          <span style={styles}>
            {(record?.value !== null && /^[0-9]+(\.[0-9]+)?$/.test(record.value)) ? formatter.formatIntNumber(Number(record.value)) : record.value}
          </span>
        );
      },
      width: 120,
    },
    {
      Header: 'Revenue Conversions To Date',
      accessor: record => record?.totalConversionsToDate,
      id: 'totalConversionsToDate',
      Cell: (record) => {
        const styles = getCellStyles(record?.row?.original);
        return (
          <span style={styles}>
            {(record?.value !== null && /^[0-9]+(\.[0-9]+)?$/.test(record.value)) ? formatter.formatIntNumber(Number(record.value)) : record.value}
          </span>
        );
      },
      width: 120,
    },
    {
      Header: 'CAC In Timeframe',
      accessor: (record) => getCACValueForSorting(record?.cacInTimeframe, record?.positiveCacInTimeframe),
      sortType: (rowA, rowB) => {
        const valueA = getCACValueForSorting(rowA?.original?.cacInTimeframe, rowA?.original?.positiveCacInTimeframe);
        const valueB = getCACValueForSorting(rowB?.original?.cacInTimeframe, rowB?.original?.positiveCacInTimeframe);
        if (valueA > valueB) return 1;
        if (valueB > valueA) return -1;
        return 0;
      },
      id: 'cacInTimeframe',
      Cell: record => useMemo(() => (
        <div>
          {record?.row?.original?.latestDealData?.typeOfDeal === 'CPA/Ambassador' ? (
            <FontAwesomeIcon icon="thumbs-up" className="text-success" />
          ) : (
            <CAC
              cac={record?.value}
              targetCac={record?.row?.original?.targetCac}
              defaultTargetCac={record?.row?.original?.defaultTargetCac}
              targetCacPercentage={record?.row?.original?.targetCacPercentage}
              allow={record?.row?.original?.positiveCacInTimeframe}
            />
          )}
        </div>
      ), [record?.value, record?.row?.original]),
      width: 120,
    },
    {
      Header: 'CAC To Date',
      accessor: (record) => getCACValueForSorting(record?.cacToDate, record?.positiveCacToDate),
      sortType: (rowA, rowB) => {
        const valueA = getCACValueForSorting(rowA?.original?.cacToDate, rowA?.original?.positiveCacToDate);
        const valueB = getCACValueForSorting(rowB?.original?.cacToDate, rowB?.original?.positiveCacToDate);
        if (valueA > valueB) return 1;
        if (valueB > valueA) return -1;
        return 0;
      },
      id: 'cacToDate',
      Cell: record => useMemo(() => (
        <div>
          {record?.row?.original?.latestDealData?.typeOfDeal === 'CPA/Ambassador' ? (
            <FontAwesomeIcon icon="thumbs-up" className="text-success" />
          ) : (
            <CAC
              cac={record?.value}
              targetCac={record?.row?.original?.targetCac}
              defaultTargetCac={record?.row?.original?.defaultTargetCac}
              targetCacPercentage={record?.row?.original?.targetCacPercentage}
              allow={record?.row?.original?.positiveCacToDate}
            />
          )}
        </div>
      ), [record?.value, record?.row?.original]),
      width: 120,
    },
  ];
};
export default ColumnsCreators;
