import React from 'react';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
 } from 'reactstrap';
import Hooks from '../../../../../../../shared/hooks';
 // import AccessComponent from '@/shared/components/auth/AccessComponent';
// import InfluencersForm, { modalId } from '../Modal/InfluencersForm';
 // import ImportTagsModal from '../../../../Influencer/components/ImportTags';
// import { useSelector } from 'react-redux';


const DashboardActions = ({ categories, downloadCsv }) => {
  const [showModal] = Hooks.useShowModalHook('PermissionModal');
  const [showModalAddNew] = Hooks.useShowModalHook('CreateAdminUserModal');
  const reload = Hooks.useReloadTableHook();
  return (
    <>
      <UncontrolledButtonDropdown className="w-100">
        <DropdownToggle
          id="actionsMenu"
          caret
          color="primary"
          style={{
            padding: '4px 25px',
            height: '42px',
          }}
        >
          Actions
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem
            onClick={() => showModalAddNew({ successFunc: () => reload() })}
          >
            Add New User
          </DropdownItem>
          <DropdownItem
            onClick={() => showModal()}
          >
            Update Permissions
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </>
  );
};
DashboardActions.propTypes = {

};


export default DashboardActions;
