import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Modal, ModalBody, ModalHeader, Row, Spinner, Button, Label, FormGroup } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { axios } from 'ApiClient';
import Select from 'react-select';
import MoneyIcon from 'mdi-react/MoneyIcon';
import { useAsyncDebounce } from 'react-table';
import { hideModalById } from '../../../../../../../../redux/reducers/TableReducer';
import { StyledButton } from '../../../../../../../../shared/components/components/ui';
import ReactTableBase from '../../../../../../../../shared/tables/table/ReactTableBase';
import { getConstant } from '../../../../../../../../shared/helpers/WVConstants';
import { getServerDate } from '../../../../../../../../shared/helpers/WVFormatter';
import CheckBoxField from '../../../../../../../../shared/components/CheckBox';

export const AddDealModalId = 'PriceApprovedGroupAddDeals';

const tableConfig = {
  isEditable: false,
  isResizable: false,
  isSelectable: true,
  useFlex: true,
  isSortable: false,
  withPagination: true,
  manualPageSize: [5, 10, 15, 20],
  withSearchEngine: false,
};

const AddDealModal = ({ getCpmPriceApprovalsGroup }) => {
  const [loading, setLoading] = useState(false);
  const [endAdding, setEndAdding] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [selected, setSelected] = useState([]);
  const [dealStageFilter, setDealStageFilter] = useState([]);
  const [dealStageOptions, setDealStageOptions] = useState([]);
  const [creatorsFiltered, setCreatorsFiltered] = useState([]);
  // const [possibleDeals, setPossibleDeals] = useState([]);
  const pipelines = getConstant('deal', 'pipelines', []);
  const [approvalStatus, setApprovalStatusState] = useState({
    approved: true,
    inReview: false,
    pendingEdits: false,
    actionRequired: false,
    hold: false,
    rejected: false,
  });
  const [approvalsBrandStatus, setApprovalsBrandStatusState] = useState({
    approved: true,
    inReview: true,
    pendingEdits: true,
    hold: true,
    rejected: true,
  });
  const [paGroupId, setPaGroupIdState] = useState({
    assigned: false,
    notAssigned: true,
  });


  const setApprovalStatus = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setApprovalStatusState({ ...approvalStatus, [name]: checked });
    }
  };
  const setApprovalBrandStatus = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setApprovalsBrandStatusState({ ...approvalsBrandStatus, [name]: checked });
    }
  };
  const setPaGroupId = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setPaGroupIdState({ ...paGroupId, [name]: checked });
    }
  };

  console.log(approvalStatus, 'approvalStatus');

  const { stages } = pipelines.find(item => item.label.toLowerCase() === 'main pipeline');
  const stageOptions = Object.entries(stages).map(([key, val]) => ({ value: key, label: val }));
  // console.log(stageOptions);

  const columnTransform = [
    {
      id: 'creatorName',
      Header: 'Creator',
      accessor: value => `${value.row?.original.contact?.firstname || ''} ${value.row?.original.contact?.lastname || ''}`,
      Cell: value => (
        <div>
          <span>
            {`${value.row?.original.contact?.firstname || ''} ${value.row?.original.contact?.lastname || ''}`}
            {value.row?.original.contact?.hs_object_id && (
              <a
                href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${value.row.original.contact.hs_object_id}/`}
                target="_blank"
                rel="noreferrer noopener"
                title="Open HubSpot Contact"
                style={{ marginLeft: '5px' }}
              >
                <i className="fab fa-hubspot" />
              </a>
            )}
            {value.row?.original.deal?.hs_object_id && (
              <a
                href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${value.row.original.deal.hs_object_id}/`}
                target="_blank"
                rel="noreferrer noopener"
                title="Open HubSpot Deal"
                style={{ marginLeft: '5px' }}
              >
                <MoneyIcon size="20" />
              </a>
            )}
          </span>
        </div>
      ),
      width: 300,
    },
    {
      id: 'createdAt',
      Header: 'Date Added',
      Cell: value => (
        <div>
          <span>{getServerDate(value.row.original.createdAt)}</span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (pipelines.length > 0) {
      // const { stages } = pipelines.find(item => item.label.toLowerCase() === 'main pipeline');
      // const stageOptions = Object.entries(stages).map(([key, val]) => ({ value: key, label: val }));
      // console.log(stageOptions);
      setDealStageOptions(stageOptions);
      setDealStageFilter([stageOptions.find(item => item.label.toLowerCase() === 'pricing review')]);
    }
  }, []);

  /* useEffect(() => {
    if (dealStageFilter && dealStageFilter.length > 0) {
      const selectedStagesArray = dealStageFilter.map(a => a.value);
      const result = possibleDeals.filter(creator => (
        !(selectedStagesArray &&
          selectedStagesArray.length > 0 &&
          !selectedStagesArray.includes(creator?.deal?.dealstage))
      ));
      setCreatorsFiltered(result);
    } else {
      setCreatorsFiltered(possibleDeals);
    }
  }, [dealStageFilter, possibleDeals]); */

  const modalInfo = useSelector(state => state.table.modals[AddDealModalId]);
  const dispatch = useDispatch();

  const { data: { record }, isShow: showUploadModal } = modalInfo || { data: {}, isShow: false };

  const getData = async () => {
    setLoading(true);
    const search = new URLSearchParams();
    search.set('brand', record.brand._id);
    Object.keys(approvalStatus).forEach((a) => {
      if (approvalStatus[a]) search.append('approvalStatus', a);
    });
    Object.keys(approvalsBrandStatus).forEach((a) => {
      if (approvalsBrandStatus[a]) search.append('approvalsBrandStatus', a);
    });
    Object.keys(paGroupId).forEach((a) => {
      if (paGroupId[a]) search.append('paGroupId', a);
    });
    const selectedStagesArray = dealStageFilter.map(a => a.value);
    selectedStagesArray.forEach((a) => {
      search.append('dealstage', a);
    });
    // console.log('Fetch creators');
    const response = await axios.get(`/api/portal/prospects/get-free-cpm-deals-for-pa-group?${search.toString()}`);
    if (response.data.success) {
      setCreatorsFiltered(response.data.cpmCreators);
      // console.log('response.data.cpmCreators', response.data.cpmCreators);
    }
    setLoading(false);
  };

  const startSearch = useAsyncDebounce(() => {
    getData();
  }, 1000);
  useEffect(async () => {
    if (!record) return false;
    await startSearch();
    return true;
  }, [
    approvalStatus,
    approvalsBrandStatus,
    paGroupId,
    dealStageFilter,
    record,
  ]);
  if (!modalInfo) return false;
  const close = () => {
    dispatch(hideModalById(AddDealModalId));
    // setLoading(false);
    setEndAdding(false);
    // setVersion(new Date().toString());
    getCpmPriceApprovalsGroup();
  };

  const onAdd = () => {
    setLoading(true);
    const data = {
      cpmCreators: selected,
      paId: record._id,
    };
    axios({
      method: 'post',
      url: '/api/portal/prospects/post-add-deals-to-cpm-pa-group',
      data,
    }).then((response) => {
      if (response.data.success) {
        setResultMessage('Deals were added successfully.');
      } else {
        setResultMessage('Deals were not added.');
      }
      setLoading(false);
      setEndAdding(true);
    });
  };

  return (
    <Modal isOpen={showUploadModal} toggle={close} size="lg" style={{ maxWidth: '900px' }}>
      <ModalHeader toggle={close} tag="h4">
        Add Creators
      </ModalHeader>
      <ModalBody>
        {dealStageOptions.length > 0 && !endAdding && (
          <div className="theme-light">
            <Row className="mb-5">
              <Col>
                <p className="mb-1 font-weight-bold">Deal Stages</p>
                <Select
                  isDisabled={dealStageOptions.length === 0}
                  value={dealStageFilter}
                  options={dealStageOptions}
                  onChange={setDealStageFilter}
                  placeholder="Select Deal Stage..."
                  isMulti
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={4}>
                <Label className="bold-text" style={{ marginBottom: '14px' }}>
                  IL Pricing Approval Status
                </Label>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="Approved0"
                    label="Approved"
                    value={approvalStatus?.approved || false}
                    onChange={event => setApprovalStatus(event, 'approved')}
                  />
                </FormGroup>
                <FormGroup style={{ marginBottom: '10px' }}>
                  <CheckBoxField
                    name="inreview1"
                    label="In Review"
                    value={approvalStatus?.inReview || false}
                    onChange={event => setApprovalStatus(event, 'inReview')}
                  />
                </FormGroup>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="pendingEdits0"
                    label="Pending Edits"
                    value={approvalStatus?.pendingEdits || false}
                    onChange={event => setApprovalStatus(event, 'pendingEdits')}
                  />
                </FormGroup>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="actionRequired0"
                    label="Action Required"
                    value={approvalStatus?.actionRequired || false}
                    onChange={event => setApprovalStatus(event, 'actionRequired')}
                  />
                </FormGroup>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="hold1"
                    label="Hold"
                    value={approvalStatus?.hold || false}
                    onChange={event => setApprovalStatus(event, 'hold')}
                  />
                </FormGroup>
                <FormGroup className="mb-0">
                  <CheckBoxField
                    name="rejected1"
                    label="Rejected"
                    value={approvalStatus?.rejected || false}
                    onChange={event => setApprovalStatus(event, 'rejected')}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <Label className="bold-text" style={{ marginBottom: '14px' }}>
                  Brand Pricing Approcal Status
                </Label>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="Approved12"
                    label="Approved"
                    value={approvalsBrandStatus?.approved || false}
                    onChange={event => setApprovalBrandStatus(event, 'approved')}
                  />
                </FormGroup>
                <FormGroup style={{ marginBottom: '10px' }}>
                  <CheckBoxField
                    name="inreview12"
                    label="In Review"
                    value={approvalsBrandStatus?.inReview || false}
                    onChange={event => setApprovalBrandStatus(event, 'inReview')}
                  />
                </FormGroup>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="pendingEdits12"
                    label="Pending Edits"
                    value={approvalsBrandStatus?.pendingEdits || false}
                    onChange={event => setApprovalBrandStatus(event, 'pendingEdits')}
                  />
                </FormGroup>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="hold12"
                    label="Hold"
                    value={approvalsBrandStatus?.hold || false}
                    onChange={event => setApprovalBrandStatus(event, 'hold')}
                  />
                </FormGroup>
                <FormGroup className="mb-0">
                  <CheckBoxField
                    name="rejected12"
                    label="Rejected"
                    value={approvalsBrandStatus?.rejected || false}
                    onChange={event => setApprovalBrandStatus(event, 'rejected')}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <Label className="bold-text" style={{ marginBottom: '14px' }}>
                  PA Group Assigned
                </Label>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="assigned1"
                    label="Assigned"
                    value={paGroupId?.assigned}
                    onChange={event => setPaGroupId(event, 'assigned')}
                  />
                </FormGroup>
                <FormGroup style={{ marginBottom: '10px' }}>
                  <CheckBoxField
                    name="notAssigned1"
                    label="Not Assigned"
                    value={paGroupId?.notAssigned}
                    onChange={event => setPaGroupId(event, 'notAssigned')}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <ReactTableBase
                  key="addCreatorsModal"
                  id="addCreatorsModal"
                  columns={columnTransform}
                  data={creatorsFiltered}
                  tableConfig={tableConfig}
                  updateSelect={(select, rows) => {
                    const selectedMap = Object.keys(select).map((sel) => {
                      const c = rows.find(r => r.id === sel);
                      return c?.original._id;
                    });
                    // console.log(selectedMap);
                    setSelected(selectedMap);
                  }}
                />
              </Col>
            </Row>
          </div>
        )}
        {loading && (
          <div className="text-center"><Spinner color="primary" size="lg" />&nbsp;&nbsp;</div>
        )}
        {endAdding && (
          <div className="text-center">
            <h4>{resultMessage}</h4>
          </div>
        )}
        <Row className="modal__footer" style={{ marginTop: '30px' }}>
          {!endAdding && (
            <StyledButton
              className="btn btn-primary btn-sm"
              onClick={onAdd}
              style={{ margin: '0 10px 0 10px' }}
              disabled={loading || selected.length === 0}
            >
              <i className="icomoon-save" />
              <span className="btn-text">Add</span>
            </StyledButton>
          )}
          <Button
            className="btn btn-secondary btn-sm"
            onClick={close}
            style={{ margin: '0 10px 0 10px' }}
          >
            {endAdding ? 'Close' : 'Cancel'}
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  );
};

AddDealModal.propTypes = {
  // possibleDeals: PropTypes.arrayOf(PropTypes.any).isRequired,
  // setVersion: PropTypes.func.isRequired,
  getCpmPriceApprovalsGroup: PropTypes.func.isRequired,
};

export default AddDealModal;
