import React, { useState, useEffect, useRef, useMemo } from 'react';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap';
import { useAsyncDebounce } from 'react-table';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import withNotice from '../../../App/store/with-notice';
import { NoResourceError } from '../../../../shared/components/components/ui/error404';
import UserPropertyType from './components/custom';
import MatTableList from '../../../../shared/tables/materialTable/MatTableList';
import { currentTimezoneAggr } from '../../../../shared/helpers/Dates';
import { parseUrl } from '../../../../shared/helpers/WVUtilities';
import Actions from './components/custom/Actions';

const getUrlCollisionsInitial = () => {
  const filters = parseUrl();

  const initialInCollisionFilters = {
    inCollision: true,
    notInCollision: true,
  };

  if (filters?.inCollision) {
    Object.keys(initialInCollisionFilters).forEach((key) => {
      const additionalCheck = key === 'inCollision' ? 'true' : 'false';
      initialInCollisionFilters[key] = filters?.inCollision.includes(key) || filters?.inCollision.includes(additionalCheck);
    });
  }

  return initialInCollisionFilters;
};

const getUrlsWithHsApprovalInitial = () => {
  const filters = parseUrl();

  const initialWithHsApprovalFilters = {
    withHsApproval: true,
    withOutHsApproval: true,
  };

  if (filters?.withHsApproval) {
    Object.keys(initialWithHsApprovalFilters).forEach((key) => {
      const additionalCheck = key === 'withHsApproval' ? 'true' : 'false';
      initialWithHsApprovalFilters[key] = filters?.withHsApproval.includes(key) || filters?.withHsApproval.includes(additionalCheck);
    });
  }

  return initialWithHsApprovalFilters;
};

const getStatusInitial = () => {
  const filters = parseUrl();
  const initialStatusFilters = {
    Success: true,
    Error: true,
  };
  if (filters?.is200Status) {
    Object.keys(initialStatusFilters).forEach((key) => {
      const additionalCheck = key === 'Success' ? 'true' : 'false';
      initialStatusFilters[key] = filters?.is200Status.includes(key) || filters?.is200Status.includes(additionalCheck);
    });
  }
  return initialStatusFilters;
};

const VanityUrlList = ({
  addNotice,
  resources,
  history,
}) => {
  const [version, setVersion] = useState((new Date()).toString());
  const [loadingCreators, setLoadingCreators] = useState(false);
  const [loadingOffers, setLoadingOffers] = useState(false);
  const [loadingDomains, setLoadingDomains] = useState(false);
  const [creatorOptions, setCreatorOptions] = useState([]);
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [offerOptions, setOfferOptions] = useState([]);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [domainOptions, setDomainOptions] = useState([]);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [showLegacy, setShowLegacy] = useState(true);
  const [inCollisionFilters, setInCollisionFilters] = useState(getUrlCollisionsInitial());
  const [withHsApprovalFilters, setWithHsApprovalFilters] = useState(getUrlsWithHsApprovalInitial());
  const [tag, setTag] = useState('');
  const [sortBy] = useState('createdAt');
  const [direction] = useState('desc');
  const [statusFilters, setStatusFilters] = useState(getStatusInitial());

  const fetchCreatorsData = async () => {
    setLoadingCreators(true);
    try {
      const responseCreators = await axios.get('/api/portal/vanity-url/get-creator-data-sets');
      if (responseCreators?.data?.success) {
        setCreatorOptions(responseCreators?.data?.creatorOptions || []);
        setLoadingCreators(false);
      } else {
        throw new Error(responseCreators?.data?.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  const fetchOffersData = async () => {
    setLoadingOffers(true);
    try {
      const responseOffers = await axios.get('/api/portal/vanity-url/get-offer-data-sets');
      if (responseOffers?.data?.success) {
        setOfferOptions(responseOffers?.data?.offerOptions || []);
        setLoadingOffers(false);
      } else {
        throw new Error(responseOffers?.data?.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  const fetchDomainsData = async () => {
    setLoadingDomains(true);
    try {
      const responseDomains = await axios.get('/api/portal/vanity-url/get-domain-data-sets');
      if (responseDomains?.data?.success) {
        setDomainOptions(responseDomains?.data?.domainOptions || []);
        setLoadingDomains(false);
      } else {
        throw new Error(responseDomains?.data?.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  useEffect(async () => {
    fetchCreatorsData();
    fetchOffersData();
    fetchDomainsData();
  }, []);

  const getUrlSearch = () => {
    const search = new URLSearchParams(window.location.search);

    search.delete('filters.creatorEmail');
    if (selectedCreators) {
      selectedCreators.forEach((selectedCreator) => {
        if (selectedCreator.value !== '') {
          search.append('filters.creatorEmail', selectedCreator.value);
        }
      });
    }

    search.delete('filters.offerName');
    if (selectedOffers) {
      selectedOffers.forEach((selectedOffer) => {
        if (selectedOffer.value !== '') {
          search.append('filters.offerName', selectedOffer.value);
        }
      });
    }

    search.delete('filters.redirectDomainName');
    if (selectedDomains) {
      selectedDomains.forEach((selectedDomain) => {
        if (selectedDomain.value !== '') {
          search.append('filters.redirectDomainName', selectedDomain.value);
        }
      });
    }

    search.delete('filters.legacyURL');
    if (showLegacy === false) {
      search.append('filters.legacyURL', false);
    }

    search.delete('filters.inCollision');
    if (inCollisionFilters && Object.keys(inCollisionFilters).length > 0) {
      Object.keys(inCollisionFilters).forEach((key) => {
        if (!inCollisionFilters[key]) return false;
        search.append('filters.inCollision', key);
        return true;
      });
    }

    search.delete('filters.withHsApproval');
    if (withHsApprovalFilters && Object.keys(withHsApprovalFilters).length > 0) {
      Object.keys(withHsApprovalFilters).forEach((key) => {
        if (!withHsApprovalFilters[key]) return false;
        search.append('filters.withHsApproval', key);
        return true;
      });
    }

    search.delete('filters.is200Status');
    if (statusFilters && Object.keys(statusFilters).length > 0) {
      let allSwitchedOff = true;
      Object.keys(statusFilters).forEach((key) => {
        if (!statusFilters[key]) return false;
        search.append('filters.is200Status', key);
        allSwitchedOff = false;
        return true;
      });
      if (allSwitchedOff) {
        search.append('filters.is200Status', 'Not Exist');
      }
    }
    search.delete('filters.searchValue');
    if (searchValue?.length > 0) {
      search.append('filters.searchValue', searchValue);
    }
    return search;
  };

  const firstUpdate = useRef(true);
  const setUrl = (refreshFilters = false) => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      const search = getUrlSearch(refreshFilters);
      search.set('page', '1');
      history.push(`${history.location.pathname}?${search.toString()}`);
    }
  };

  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 500);

  useEffect(() => {
    setUrlDebounced();
  }, [
    searchValue,
    selectedCreators,
    selectedOffers,
    selectedDomains,
    showLegacy,
    statusFilters,
    inCollisionFilters,
    withHsApprovalFilters,
  ]);

  const resource = resources.find(r => r.id === 'VanityUrl');
  if (!resource) {
    return (<NoResourceError resourceId="VanityUrl" />);
  }
  resource.href = '/resources/VanityUrl/actions/list';
  resource.listProperties.forEach((l) => {
    // eslint-disable-next-line no-param-reassign
    if (l.name === 'createdAt') l.label = `Created At (${currentTimezoneAggr()})`;
  });
  const action = resource.resourceActions.find(r => r.name === 'list');

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            links={[
              { title: 'Home', path: '/' },
              { title: 'Vanity Urls', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody style={{ padding: '2rem 2rem 0 2rem' }}>
              <CardTitle>
                <Row>
                  <Col sm={8}>
                    <div className="d-flex w-100 justify-content-between" >
                      <h3>Vanity URLs</h3>
                    </div>
                  </Col>
                </Row>
                <hr className="mt-3 mb-4" />
              </CardTitle>
              <Actions
                searchValue={searchValue}
                loadingCreators={loadingCreators}
                selectedCreators={selectedCreators}
                creatorOptions={creatorOptions}
                loadingOffers={loadingOffers}
                selectedOffers={selectedOffers}
                offerOptions={offerOptions}
                loadingDomains={loadingDomains}
                selectedDomains={selectedDomains}
                domainOptions={domainOptions}
                statusFilters={statusFilters}
                showLegacy={showLegacy}
                setSearchValue={setSearchValue}
                setSelectedCreators={setSelectedCreators}
                setSelectedOffers={setSelectedOffers}
                setSelectedDomains={setSelectedDomains}
                setStatusFilters={setStatusFilters}
                setInCollisionFilters={setInCollisionFilters}
                inCollisionFilters={inCollisionFilters}
                withHsApprovalFilters={withHsApprovalFilters}
                setWithHsApprovalFilters={setWithHsApprovalFilters}
                setShowLegacy={setShowLegacy}
                history={history}
              />
              <div style={{ marginLeft: '-17px', marginRight: '-17px', marginBottom: '-30px' }}>
                <MatTableList
                  action={action}
                  resource={resource}
                  sortBy={sortBy}
                  direction={direction}
                  setTag={setTag}
                  date={new Date()}
                  version={version}
                  UserPropertyType={UserPropertyType}
                  noActions={false}
                  columnsSettings={{
                    creatorName: {
                      width: 180,
                    },
                    offerName: {
                      width: 180,
                    },
                    redirectDomainName: {
                      width: 150,
                    },
                    localPath: {
                      width: 150,
                      accessor: () => '',
                      Cell: p => useMemo(
                        () => (
                          <a target="_blank" href={`https://${p.row.original.params?.redirectDomainName}/${p.row.original.params?.localPath}`} rel="noreferrer">{p.row.original.params?.localPath}</a>
                        ),
                        [p.row.original],
                      ),
                    },
                    resultUrl: {
                      width: 150,
                      Cell: p => useMemo(
                        () => (
                          <a target="_blank" href={p.row.original.params?.resultUrl} rel="noreferrer">
                            <span className={p.row.original.params.is200Status ? '' : 'text-danger'}>{p.row.original.params?.resultUrl}</span>
                          </a>
                        ),
                        [p.row.original],
                      ),
                    },
                    // affSub: {
                    //   width: 150,
                    // },
                    affSub2: {
                      width: 140,
                    },
                    affSub4: {
                      width: 140,
                    },
                    urlId: {
                      width: 120,
                    },
                    lgcCode: {
                      width: 80,
                    },
                    createdAt: {
                      width: 150,
                    },
                    actions: {
                      width: 50,
                    },
                  }}
                  tag={tag}
                  v={2}
                  hideToolbar
                  showTitle={false}
                  meta={{ closeFunction: () => setVersion((new Date()).toString()) }}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
VanityUrlList.propTypes = {
  addNotice: PropTypes.func.isRequired,
  resources: PropTypes.arrayOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  
  resources: state.resources,
});

export default connect(mapStateToProps)(withNotice(VanityUrlList));
