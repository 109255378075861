/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardBody, CardTitle, } from 'reactstrap';
import queryHasFilter from '@/shared/ViewHelpers/QueryHasFilter';
import Filter from '@/shared/components/components/app/filter';
import { useAsyncDebounce } from 'react-table';
import { NoResourceError, NoActionError } from '@/shared/components/components/ui/error404';
import MatTableList from '@/shared/tables/materialTable/MatTableList';
import Breadcrumbs from '@/shared/components/BreadCrumbs';
import { useHistory } from 'react-router-dom';
import UserPropertyType from './components/custom';
import ContextMenuInList from './components/custom/ContextMenuInList';
import Actions from './components/custom/Actions';

const ResourceAction = (props) => {
  const {
    location,
    resources,
  } = props;
  const [filterVisible, setFilerVisible] = useState(queryHasFilter(location.search));
  const [searchValue, setSearchValue] = useState('');
  const history  = useHistory();

  const setUrl = (refreshFilters = false) => {
    const search = getUrlSearch(refreshFilters);
    search.set('page', '1');
    history.push(`${history.location.pathname}?${search.toString()}`);
  };

  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 500);

  useEffect(() => {
    setUrlDebounced();
  }, [
    searchValue,
  ]);

  const resource = resources.find(r => r.id === 'RedirectDomain');
  if (!resource) {
    return (<NoResourceError resourceId="RedirectDomain" />);
  }
  resource.href = '/resources/RedirectDomain/actions/list';
  const action = resource.resourceActions.find(r => r.name === 'list');
  if (!action) {
    return (<NoActionError resourceId="RedirectDomain" actionName="list" />);
  }

  const getUrlSearch = () => {
    const search = new URLSearchParams(window.location.search);

    search.delete('filters.searchValue');
    if (searchValue) {
      search.append('filters.searchValue', searchValue);
    }

    return search;
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            links={[
              { title: 'Home', path: '/' },
              { title: 'Redirect Domain', path: false },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
        <Card>
        <CardBody style={{ padding: '2rem 2rem 0 2rem' }}>
          <CardTitle>
            <Row>
              <Col sm={8}>
                <div className="d-flex w-100 justify-content-between" >
                  <h3>Redirect Domains</h3>
                </div>
              </Col>
            </Row>
            <hr className="mt-3 mb-4" />
          </CardTitle>
          <Actions
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
          <div style={{ marginLeft: '-17px', marginRight: '-17px', marginBottom: '-30px' }}>
          <MatTableList
            v={2}
            action={action}
            resource={resource}
            date={new Date()}
            UserPropertyType={UserPropertyType}
            columnsSettings={{
              domain: {
                width: 160,
                accessor: p => p.params.domain,
                Cell: p => (
                  <a target="_blank" rel="noreferrer" href={`https://${p.value}`}>{p.value}</a>
                ),
              },
              redirectUrlIndex: {
                width: 350,
                accessor: p => p.params.redirectUrlIndex,
                Cell: p => (
                  <a target="_blank" rel="noreferrer" href={`https://${p.value}`}>{p.value}</a>
                ),
              },
              redirectUrl404: {
                width: 350,
                accessor: p => p.params.redirectUrl404,
                Cell: p => (
                  <a target="_blank" rel="noreferrer" href={`https://${p.value}`}>{p.value}</a>
                ),
              },
              logoPath: {
                width: 150,
              },
              createdAt: {
                width: 150,
              },
              contextMenuRedirectDomains: {
                Header: '',
                accessor: () => null,
                Cell: p => (
                  <ContextMenuInList
                    record={p.row.original}
                    edit={() => {}}
                  />
                ),
                disableSortBy: true,
                className: 'align-self-center',
                width: 30,
                maxWidth: 30,
              },
            }}
            noActions
            sortBy="domain"
            direction="asc"
            showFilters={false}
            hideToolbar={false}
            showTitle={false}
            useFlex
          />
          </div>
        </CardBody>
        </Card>
        </Col>
      </Row>
      {action.showFilter ? (
        <Filter
          resource={resource}
          isVisible={filterVisible}
          toggleFilter={() => setFilerVisible(!filterVisible)}
        />
      ) : ''}
    </Container>
  );
};

const mapStateToProps = state => ({
  
  resources: state.resources,
});

export default connect(mapStateToProps)(ResourceAction);
