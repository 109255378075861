import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import {
  Col, Row, Button, CardBody, Card, Badge,
  DropdownToggle, DropdownMenu, DropdownItem, UncontrolledButtonDropdown,
} from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment-timezone';
import _ from 'lodash';
import AccountKeyIcon from 'mdi-react/AccountKeyIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import Tooltip from '@material-ui/core/Tooltip';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Placeholder from '../../../../../shared/components/components/ui/placeholder';
import socialIcon from '../../../../../shared/components/icons/Social';
import WVValidator from '../../../../../shared/helpers/WVValidator';
import { currentTimezoneAggr } from '../../../../../shared/helpers/Dates';
import { getLogoUrl } from '../../../../../shared/helpers/models/Influencer';
import { getMediaChannel } from '../../../../../shared/helpers/models/HubilContact';
import CopyToClipboard from '../../../../../shared/components/CopyToClipboard';
import CopyToClipboardNoIcon from '../../../../../shared/components/CopyToClipboardNoIcon';
import CreatorTags from './CreatorTags';
import CreatorCategory from './CreatorCategory';
import OnboardingStatus from './OnboardingStatus';
import DisConnectModal from '../../../Agents/AgentBillCom/DisConnectModal';
import CreatorDetailsModal from '../../../../../shared/components/CreatorDetailsModal';
import StatusHistoryModal from './StatusHistoryModal';
import EditMediaChannelsModal from './components/modals/EditMediaChannels';
import Alert from '../../../../../shared/easydev2/shared/components/Alert';
import BillComCreatorProfile from './BillComCreatorProfile';
import StripeCreatorProfile from './StripeCreatorProfile';
import EnablePaymentSelect from './PayeeSelect';
import StatusCircle from './components/StatusCircle';
import Hook from '../../../../../shared/hooks';
import TaxFormUploadModal, { TaxFormUploadModalId } from '../../../../../shared/components/modals/TaxFormUploadModal';
import AccessComponent from '../../../../../shared/components/auth/AccessComponent';
import { getTaxStatus } from '../../../../../shared/helpers/TaxHelper';
import CountryOfResidenceSelect from './CountryOfResidenceSelect';

const CreatorProfile = ({
  influencerId, influencer, contact, prospect, loading, changePassword, spyLogin,
  editPayment, loadinginfluencerPayment, refreshHasoffers,
  refreshHubspot, refreshBillCom, editStatus, loadinginfluencerStatus, editCompanyStatus, loadingCompanyStatus,
  connectBillCom, inviteBillCom, reloadInfluecner,
}) => {
  let status = '-';
  let color = '#95a5a6';
  if (influencer) {
    const statusArray = [influencer.params.status, influencer.params.affiliateDataObject?.status];
    if (_.isEqual(statusArray, ['active', 'active'])) {
      status = 'Active';
      color = '#4ce1b6';
    } else if (statusArray.includes('active')) {
      status = 'Inactive';
      color = '#ffc107';
    } else {
      status = 'Inactive';
      color = '#de4b33';
      if (_.isEqual(statusArray, ['deleted', 'deleted'])) status = 'Deleted';
      if (_.isEqual(statusArray, ['pending', 'pending'])) status = 'Pending';
      if (_.isEqual(statusArray, ['rejected', 'rejected'])) status = 'Rejected';
      if (_.isEqual(statusArray, ['blocked', 'blocked'])) status = 'Blocked';
    }
  }
  const [logo, setLogo] = useState('/images/user/defaultAvatar.png');
  const [spyLoginLoading, setSpyLoginLoading] = useState(false);
  const [researchDetailsModalOpen, setResearchDetailsModalOpen] = useState(false);
  const [loadingTubularCreatorId, setLoadingTubularCreatorId] = useState(false);
  const [tubularCreatorId, setTubularCreatorId] = useState(null);
  const [modal, setModal] = useState(false);
  const [openHistoryStatus, setOpenHistoryStatus] = useState(false);
  const [openHistoryStatusI, setOpenHistoryStatusI] = useState(false);
  const [mediaChannels, setMediaChannels] = useState([]);
  const [mediaChannelsSource, setMediaChannelsSource] = useState('');
  const [editMediaChannelsModalIsOpen, setEditMediaChannelsModalIsOpen] = useState(false);
  const [taxFormValue, setTaxFormValue] = useState(influencer?.params?.validTaxForm?.value ? 1 : 0);
  const [stripeColor, setStripeColor] = useState('text-secondary');


  const taxFormValues = { 1: 'On File', 0: 'Missing' };
  const showWhenTeamIs = ['management', 'super_powers', 'finance', 'engineering', 'customer_success'];
  // const taxFormValue = getTaxStatus(influencer);

  const [showModalTaxFormUpload] = Hook.useShowModalHook(TaxFormUploadModalId);

  const fetchTubularCreatorId = () => {
    let youtubeChannelUrl = null;
    if (influencer?.params?.mediaChannels?.youtube_channel_url) {
      youtubeChannelUrl = influencer.params.mediaChannels.youtube_channel_url;
    } else if (contact?.params?.mediaChannels?.youtube_channel_url) youtubeChannelUrl = contact.params.mediaChannels.youtube_channel_url;
    if (youtubeChannelUrl) {
      setLoadingTubularCreatorId(true);
      axios.post(
        '/api/portal/research/do-search',
        {
          type: 'creator',
          value: youtubeChannelUrl,
          filters: {},
          nextPageToken: '',
        },
      ).then((response) => {
        if (response.data.success && response.data.data) {
          const { creators } = response.data.data;
          if (creators && creators.length > 0) {
            if (creators[0] && creators[0].creator_id) {
              setTubularCreatorId(creators[0].creator_id);
            }
          }
        }
        setLoadingTubularCreatorId(false);
      });
    }
  };

  useEffect(() => {
    fetchTubularCreatorId();
    if (!taxFormValue && influencer) {
      setTaxFormValue(getTaxStatus(influencer));
    }
  }, [influencer, contact]);

  const spyLoginHandler = async () => {
    setSpyLoginLoading(true);
    await spyLogin(influencer.id);
    setSpyLoginLoading(false);
  };

  const onPaymentProviderChanged = (option) => {
    console.log(option);
    editPayment(option.value !== 'stripe' ? 'billCom' : 'stripe');
  };

  const paymentOptions = () => {
    let hasStripe = false;
    let hasBillCom = false;
    if (influencer && influencer.params.payment) {
      hasStripe = influencer.params.payment.stripe && !WVValidator.isEmpty(influencer.params.payment.stripe.accountId);
      hasBillCom = influencer.params.payment.billCom && !WVValidator.isEmpty(influencer.params.payment.billCom.accountId);
    }
    return [
      { value: 'stripe', label: `Stripe${!hasStripe ? ' (not connected)' : ''}`, isDisabled: !hasStripe },
      { value: 'billCom', label: `Bill.com${!hasBillCom ? ' (not connected)' : ''}`, isDisabled: !hasBillCom },
    ];
  };

  const onStatusChanged = (option) => {
    editStatus(option.value);
  };

  const onCompanyStatusChanged = (option) => {
    editCompanyStatus(option.value);
  };

  const statusOptions = () => ([
    { value: 'active', label: 'Active' },
    { value: 'blocked', label: 'Blocked' },
    { value: 'deleted', label: 'Deleted' },
  ]);

  const defaultOption = () => {
    let isStripeDefault = false;
    let isBillComDefault = false;

    if (influencer && influencer.params.payment) {
      isStripeDefault = influencer.params.payment.stripe && influencer.params.payment.stripe.isDefault;
      isBillComDefault = influencer.params.payment.billCom && influencer.params.payment.billCom.isDefault;
    }
    // console.log(isStripeDefault, isBillComDefault);
    const options = paymentOptions();
    // console.log(options);
    if (isStripeDefault) {
      return options[0];
    }
    if (isBillComDefault) {
      return options[1];
    }
    return null;
  };

  const defaultStatusOption = () => {
    if (influencer && influencer.params.status) {
      const options = statusOptions();
      const result = options.filter(item => (item.value === influencer.params.status));
      return result[0];
    }
    return null;
  };

  const defaultCompanyStatusOption = () => {
    if (influencer?.params.affiliateDataObject?.status) {
      const options = statusOptions();
      const result = options.filter(item => (item.value === influencer.params.affiliateDataObject.status));
      return result[0];
    }
    return null;
  };

  const missedNameParts = [];
  if (influencer) {
    if (!influencer.params.first_name || (influencer.params.first_name.toLowerCase() === 'null')) {
      missedNameParts.push('first');
    }
    if (!influencer.params.last_name || (influencer.params.last_name.toLowerCase() === 'null')) {
      missedNameParts.push('last');
    }
  }
  const strNameParts = missedNameParts.join(' and ');

  const updateMediaChannels = (newParams = undefined) => {
    const allPlatforms = ['youtube', 'facebook', 'instagram', 'twitter', 'tiktok', 'podcast', 'twitch'];
    if (newParams) {
      if (newParams.mediaChannels?.tubularCreatorId) setTubularCreatorId(newParams.mediaChannels.tubularCreatorId);
      setMediaChannels(allPlatforms.map(platform => getMediaChannel(newParams, platform)));
      setMediaChannelsSource('creator');
    } else if (influencer?.params?.mediaChannels) {
      setMediaChannels(allPlatforms.map(platform => getMediaChannel(influencer.params, platform)));
      setMediaChannelsSource('creator');
    } else if (prospect?.tubularDetails?.accounts?.length > 0) {
      setMediaChannels(prospect.tubularDetails.accounts);
      setMediaChannelsSource('prospect');
    }
  };

  useEffect(updateMediaChannels, [prospect, contact]);

  useEffect(() => {
    getLogoUrl(influencer, prospect).then((res) => {
      setLogo(res);
    });
    if (influencer) {
      if (influencer.params.payment.stripe.accountId) {
        setStripeColor('text-success');
      } else if (influencer.params.payment.stripe.accountIdTemp) {
        setStripeColor('text-warning')
      }
    }
  }, [influencer, prospect]);

  return (
    <div>
      <h2 className="section-title mt-0 mb-4">Creator Profile</h2>
      {influencer?.params?.inBlackList && (
        <Alert color="warning" className="w-100 mt-2" bordered icon>
          <p className="py-2 text-dark">
            This contact is on the blacklist and some functionality is blocked. <a href="/influencers/contacts" target="_blank" rel="noopener">Manage Blacklist</a>
          </p>
        </Alert>
      )}
      {(missedNameParts.length > 0) && (
        <Alert color="warning" bordered icon>
          <p className="py-2">
            This Creator is missing a {strNameParts} name. Both first and last name are required to send Agreements.
            {influencer && influencer.params.affiliateData && influencer.params.affiliateData.tune_id && (
              <span style={{ display: 'block' }}>
                Click <a href={`https://influencelogic.hasoffers.com/admin/affiliates/view/${influencer.params.affiliateData.tune_id}`} className="font-weight-bold text-primary" target="_blank" rel="noopener noreferrer">here</a> to
                specify and save names in HasOffers then use <b>Refresh &gt; HasOffers Data</b> to synchronize to the Portal.
              </span>
            )}
          </p>
        </Alert>
      )}
      <Card id="section-creator-profile">
        <CardBody>
          <Row>
            <Col>
              <Row>
                <Col xs="auto">
                  <div
                    style={{
                      width: 150,
                      height: 150,
                      borderRadius: 75,
                      backgroundImage: `url(${logo})`,
                      backgroundPosition: 'center center',
                      backgroundSize: 'cover',
                    }}
                  />
                </Col>
                <Col>
                  {!loading && influencer ?
                  (
                    <div className="w-100 float-left">
                      <h3 className="mt-0 il-color-deep-blue float-left main-header">
                        <Tooltip
                          title="HasOffers Affiliate User Name"
                        >
                          <span>{influencer.params.first_name} {influencer.params.last_name}</span>
                        </Tooltip>
                        {loadinginfluencerStatus ?
                          (
                            <Badge color="light" className="mt-0 mx-2 top-rounded" style={{ minWidth: '70px', marginTop: '-5px' }}>
                              <div role="status" className="spinner-border-sm spinner-border text-primary" />
                            </Badge>)
                          : (
                            <StatusCircle
                              color={color}
                              status={status}
                            />
                          )
                        }
                      </h3>
                      <h4 className="il-text-gray company-header" style={{ paddingTop: '2px' }}>
                        {!loading && influencer ? (
                          <Tooltip
                            title="HasOffers Company Name"
                          >
                            <span>{influencer.params.affiliateData && influencer.params.affiliateData.company}</span>
                          </Tooltip>
                        ) : (<Placeholder style={{ height: 15, maxWidth: '200px', marginTop: 3 }} />)}
                      </h4>

                    </div>)
                  : (<Placeholder style={{ height: 30, width: '100%' }} />)}
                  <div className="mt-2 float-left">
                    <h4 className="il-text-gray">
                      {!loading && influencer ? (
                        <>
                          {influencer.params.tune_id && (
                            <>
                              <span>
                                HO User
                                {' '}
                                <CopyToClipboardNoIcon
                                  value={influencer.params.tune_id}
                                  title="Copy HO User ID to clipboard"
                                >
                                  <strong>ID {influencer.params.tune_id}</strong>
                                </CopyToClipboardNoIcon>
                                {' '}
                              </span>
                              /
                            </>
                          )}
                          {influencer.params.affiliateDataObject?.tune_id && (
                            <span>
                              {' '}
                              HO Company
                              {' '}
                              <CopyToClipboardNoIcon
                                value={influencer.params.affiliateDataObject?.tune_id}
                                title="Copy HO Company ID to clipboard"
                              >
                                <strong>ID {influencer.params.affiliateDataObject?.tune_id}</strong>
                              </CopyToClipboardNoIcon>
                              {' '}
                              <div style={{ display: 'inline-flex' }}>
                                <a
                                  href={`https://influencelogic.hasoffers.com/admin/affiliates/view/${influencer.params.affiliateDataObject?.tune_id}`}
                                  className="font-weight-bold il-text-gray"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  title="View Affiliate account on HasOffers"
                                >
                                  <OpenInNewIcon
                                    size={25}
                                    style={{
                                      position: 'sticky',
                                      top: '0',
                                      paddingBottom: '4px',
                                    }}
                                  />
                                </a>
                              </div>
                            </span>
                          )}
                        </>
                      ) : (
                        <Placeholder style={{
                          height: 30,
                          width: '100%'
                        }}/>
                      )}
                    </h4>
                    <div className="mt-1">{!loading && influencer ? (
                      <>
                        <span className="il-text-gray">
                          <span className="lnr lnr-envelope" />{' '}
                          <CopyToClipboard value={influencer.params.email || ''} iconClassName="text-blue">
                            <a className="il-text-gray" href={`mailto:${influencer.params.email}`}>
                              {influencer.params.email}
                            </a>
                          </CopyToClipboard>
                        </span>
                        {!loadingTubularCreatorId && (
                          <>
                            <br />
                            {tubularCreatorId
                              ? (
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
                                <a
                                  className="il-color-deep-blue bold-text"
                                  role="button"
                                  onClick={() => setResearchDetailsModalOpen(true)}
                                >
                                  <FontAwesomeIcon icon={['fa', 'search']} />
                                  {' '}
                                  Research
                                </a>
                              )
                              : (
                                <span className="text-warning mt-1">
                                  Set the YouTube Channel URL to view Research information
                                </span>
                              )
                            }
                          </>)
                        }
                        <div>
                          {mediaChannels.map(t => socialIcon(t, mediaChannelsSource === 'prospect' ? '(from associated Prospect)' : ''))}
                          <Tooltip
                            title={mediaChannelsSource === 'prospect' ? 'Set Creator\'s Media Channels' : 'Edit Creator\'s Media Channels'}
                          >
                            <IconButton
                              size="small"
                              onClick={() => setEditMediaChannelsModalIsOpen(true)}
                            >
                              <FontAwesomeIcon icon="edit" className="text-blue" />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <EditMediaChannelsModal
                          key="editCreatorMediaChannelsModal"
                          isOpen={editMediaChannelsModalIsOpen}
                          toggle={() => { setEditMediaChannelsModalIsOpen(false); }}
                          name={`${contact?.params?.firstname} ${contact?.params?.lastname}`}
                          saveUrl={`/api/portal/influencer/post-save-creator-media-channels?id=${influencer.id}`}
                          afterSave={updateMediaChannels}
                        />
                      </>) : (<Placeholder style={{ height: 15, maxWidth: '200px', marginTop: 3 }} />)}
                    </div>
                  </div>
                </Col>
              </Row>
              {researchDetailsModalOpen && (
                <CreatorDetailsModal
                  isOpen={!!researchDetailsModalOpen}
                  creatorId={tubularCreatorId}
                  toggleModal={() => setResearchDetailsModalOpen(false)}
                />
              )}
            </Col>
            <Col xs="auto">
              <UncontrolledButtonDropdown className="m-0">
                <DropdownToggle caret className="mr-2 payments-btn" color="primary" style={{ padding: '5px 25px' }} disabled={!!loading}>
                  Payments
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={connectBillCom} className="payments-btn-connect">
                    <Tooltip title="Connect Bill Com">
                      <>Connect Bill.com</>
                    </Tooltip>
                  </DropdownItem>
                  <DropdownItem onClick={() => setModal(true)} className="payments-btn-disconnect">
                    Disconnect
                  </DropdownItem>
                  {influencer && !influencer.params.payment.billCom.accountId ? (
                    <DropdownItem onClick={inviteBillCom} className="payments-btn-invite">
                      Send Bill.com Invite
                    </DropdownItem>) : null}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
              <Tooltip title="Spy Login to the Creator Portal (Secure & Safe)">
                <span className="mr-2">
                  <Button
                    color="primary"
                    disabled={!!loading || spyLoginLoading}
                    onClick={spyLoginHandler}
                    className="spyLoginButton spy-login-btn"
                  >
                    <AccountKeyIcon size="1em" /> Spy Login
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title="Update the Creator account password, email, name, etc.">
                <span className="mr-2">
                  <Button
                    color="primary"
                    disabled={!!loading}
                    onClick={changePassword}
                    className="edit-account-btn"
                  >
                    <FontAwesomeIcon icon={['fa', 'edit']} />
                    {' '}
                    Account Settings
                  </Button>
                </span>
              </Tooltip>
              <UncontrolledButtonDropdown className="m-0">
                <DropdownToggle caret className="mr-2 refresh-btn" color="primary" style={{ padding: '5px 25px' }} disabled={!!loading}>
                  <FontAwesomeIcon icon={['fa', 'sync-alt']} />
                  {' '}
                  Refresh
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={refreshHasoffers} className="refresh-btn-ho-data">
                    <Tooltip title="Synchronize all HasOffers Creator Information">
                      <>HasOffers Data</>
                    </Tooltip>
                  </DropdownItem>
                  {typeof contact !== 'undefined' && contact !== null && (
                    <DropdownItem onClick={refreshHubspot} className="refresh-btn-hs-data">
                      <Tooltip title="Synchronize all HubSpot Creator Information">
                        <>HubSpot Data</>
                      </Tooltip>
                    </DropdownItem>
                  )}
                  <DropdownItem onClick={refreshBillCom} className="refresh-btn-bill-data">
                    <Tooltip title="Update or connect bill.com account ID">
                      <>Bill.com Account</>
                    </Tooltip>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </Col>
          </Row>
          {!loading ? [(
            <Row className="mt-3" key="0">
              <Col md={5}>
                <Row>
                  <Col>
                    <h5>Account Manager</h5>
                  </Col>
                  <Col>
                    <h5>Join Date (ET)</h5>
                  </Col>
                  <Col>
                    <h5>Last Login Date ({currentTimezoneAggr()})</h5>
                  </Col>
                </Row>
              </Col>
              <Col md={7}>
                <Row>
                  <Col md={2}>
                    <h5>Connected Payment Method</h5>
                  </Col>
                  <Col md={2} className="pl-0">
                    <h5>Tax Form</h5>
                  </Col>
                  <Col md={3}>
                    <h5>Partner Status</h5>
                  </Col>
                  <Col md={3}>
                    <h5>Company Status</h5>
                  </Col>
                  <Col md={2}>
                    <h5>Default Payment Provider</h5>
                  </Col>
                </Row>
              </Col>
            </Row>),
            (<hr key="1" />),
            (
              <Row key="2">
                <Col md={5}>
                  <Row>
                    <Col className="account-manager">
                      {influencer ? (
                        <>
                          {influencer.params.employee ? (
                            <strong>{`${influencer.params.employee.firstName} ${influencer.params.employee.lastName}`}</strong>
                          ) : (
                            <strong style={{ paddingLeft: '52px' }}>-</strong>
                          )}
                        </>
                        ) : (<Placeholder style={{ height: 14, width: '100%' }} />)
                      }
                    </Col>
                    <Col className="join-date">
                      {influencer ? (
                        <>
                          {influencer.params.join_date ? (
                            <strong>{ moment.utc(influencer.params.join_date).utc().format('M/D/YYYY h:mm A') }</strong>
                        ) : (
                          <strong style={{ paddingLeft: '26px' }}>-</strong>
                        )}
                        </>
                        ) : (<Placeholder style={{ height: 14, width: '100%' }} />)
                      }
                    </Col>
                    <Col className="last-login-date">
                      {influencer ? (
                        <>
                          {influencer.params.lastLoginDate ? (
                            <strong>{ moment(influencer.params.lastLoginDate).format('M/D/YYYY h:mm A') }</strong>
                          ) : (
                            <strong style={{ paddingLeft: '48px' }}>-</strong>
                          )}
                        </>
                        ) : (<Placeholder style={{ height: 14, width: '100%' }} />)
                      }
                    </Col>
                  </Row>
                </Col>
                <Col md={7}>
                  <Row>
                    <Col md={2}>
                      <Row>
                        <Col>
                          { influencer &&
                            (
                              <Tooltip title={!influencer.params.payment.stripe.accountId && influencer.params.payment.stripe.accountIdTemp ? 'Account onboarding incomplete' : ''}>
                                <div className={`float-left mr-2 mt-1 ${stripeColor}`}>
                                  <FontAwesomeIcon icon={['fab', 'cc-stripe']} style={{fontSize: '1.6em'}}/>
                                </div>
                              </Tooltip>
                            )
                          }
                          {influencer &&
                            <div className="float-left mr-2 mt-1">
                              <img src={`/images/payments/billCom${influencer.params.payment.billCom.accountId ? 'Green' : 'Gray'}.png`} style={{ width: '30px' }} className="img-fluid" alt="Bill.com" />
                            </div>
                          }
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          { influencer
                            && influencer.params.payment.billCom.isDefault
                            && influencer.params.payment.billCom.accountId
                            && <BillComCreatorProfile vendorId={influencer.params.payment.billCom.accountId} />
                          }
                          { influencer
                            && (
                              (influencer.params.payment.stripe.isDefault && influencer.params.payment.stripe.accountId)
                              || (!influencer.params.payment.billCom.isDefault && influencer.params.payment.stripe.accountIdTemp)
                            )
                            && <StripeCreatorProfile accountId={influencer.params.payment.stripe.accountId || influencer.params.payment.stripe.accountIdTemp} />
                          }
                        </Col>
                      </Row>
                    </Col>
                    <Col md={2}>
                      <AccessComponent showWhenTeam={showWhenTeamIs} showWhenRole={['superAdmin', 'owner']} and>
                        <Row>
                          <Col className="pr-0 pl-0">
                            <b className="mr-2">{taxFormValues[taxFormValue]}</b>
                            <Tooltip title="Upload Tax Form">
                              <IconButton size="small" onClick={() => showModalTaxFormUpload({ source: 'portal' })}><FontAwesomeIcon icon="upload" /></IconButton>
                            </Tooltip>
                            {influencer && (
                              <TaxFormUploadModal person="creator" record={influencer} />
                            )}
                          </Col>
                        </Row>
                      </AccessComponent>
                      <AccessComponent showWhenRole={['superAdmin', 'owner', 'admin']} hideWhenTeam={showWhenTeamIs} and>
                        <b>{taxFormValues[taxFormValue]}</b>
                      </AccessComponent>
                    </Col>
                    <Col md={3}>
                      <Row>
                        <Col className="pr-0 ho-partner-status">
                          <Select
                            value={defaultStatusOption()}
                            options={statusOptions()}
                            onChange={onStatusChanged}
                            placeholder="Partner Status"
                            className="mb-3"
                            isDisabled={loadinginfluencerStatus}
                          />
                        </Col>
                        <Col xs="auto" className="px-1 ho-partner-status-history">
                          <Tooltip title="Status History">
                            <IconButton size="small" onClick={() => setOpenHistoryStatusI(true)} className="mt-2"><FontAwesomeIcon icon="history" /></IconButton>
                          </Tooltip>
                          <StatusHistoryModal
                            isOpen={openHistoryStatusI}
                            closeFunc={() => setOpenHistoryStatusI(false)}
                            history={influencer?.params?.statusHistory || []}
                            influencer={influencer}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={3}>
                      <Row>
                        <Col className="pr-0 ho-company-status">
                          <Select
                            value={defaultCompanyStatusOption()}
                            options={statusOptions()}
                            onChange={onCompanyStatusChanged}
                            placeholder="Company Status"
                            className="mb-3"
                            isDisabled={loadingCompanyStatus}
                          />
                        </Col>
                        <Col xs="auto" className="px-1 ho-company-status-history">
                          <Tooltip title="Status History">
                            <IconButton size="small" onClick={() => setOpenHistoryStatus(true)} className="mt-2"><FontAwesomeIcon icon="history" /></IconButton>
                          </Tooltip>
                          <StatusHistoryModal
                            isOpen={openHistoryStatus}
                            closeFunc={() => setOpenHistoryStatus(false)}
                            history={influencer?.params?.affiliateData?.statusHistory || []}
                            influencer={influencer}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col className="default-payment-method" md={2}>
                      <Select
                        value={defaultOption()}
                        options={paymentOptions()}
                        onChange={onPaymentProviderChanged}
                        placeholder="Select Provider"
                        className="mb-3"
                        isDisabled={loadinginfluencerPayment}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>),
            (
              <Row key="3">
                { influencer && [
                  <Col sm={2} key="shouldEnablePayments">
                    <EnablePaymentSelect record={influencer.params} />
                  </Col>,
                  <Col sm={2} key="countryOfResidence">
                    <CountryOfResidenceSelect creatorId={influencerId} countryCode={influencer?.params?.countryOfResidence} />
                  </Col>,
                  <Col sm={3} key="cat">
                    <CreatorCategory creatorId={influencerId} category={influencer?.params?.category} reloadInfluecner={reloadInfluecner} />
                  </Col>,
                  <Col sm={5} key="tag">
                    <CreatorTags creatorId={influencerId} />
                  </Col>,
                ]}
              </Row>
            ),
            (
              <Row key="4">
                { influencer && (
                  <Col>
                    <OnboardingStatus influencer={influencer} />
                  </Col>
                )}
              </Row>
            )] : null}
        </CardBody>
      </Card>
      {influencer ? (<DisConnectModal
        agent={influencer}
        type="creator"
        toggle={() => setModal(false) || console.log('close')}
        modal={modal}
        meta={{ closeFunction: () => reloadInfluecner() }}
      />) : null}
    </div>
  );
};
CreatorProfile.propTypes = {
  influencerId: PropTypes.string.isRequired,
  influencer: PropTypes.objectOf(PropTypes.any),
  contact: PropTypes.objectOf(PropTypes.any),
  prospect: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool.isRequired,
  changePassword: PropTypes.func.isRequired,
  spyLogin: PropTypes.func.isRequired,
  editPayment: PropTypes.func.isRequired,
  editStatus: PropTypes.func.isRequired,
  editCompanyStatus: PropTypes.func.isRequired,
  refreshHasoffers: PropTypes.func.isRequired,
  refreshBillCom: PropTypes.func.isRequired,
  refreshHubspot: PropTypes.func.isRequired,
  loadinginfluencerPayment: PropTypes.bool.isRequired,
  loadinginfluencerStatus: PropTypes.bool.isRequired,
  loadingCompanyStatus: PropTypes.bool.isRequired,
  connectBillCom: PropTypes.func.isRequired,
  inviteBillCom: PropTypes.func.isRequired,
  reloadInfluecner: PropTypes.func.isRequired,
};
CreatorProfile.defaultProps = {
  influencer: null,
  contact: null,
  prospect: null,
};

export default CreatorProfile;
