/* eslint-disable no-param-reassign */
const moment = require('moment');

const searchUrl = (filters, params = {}, paramString) => {
  const s = (typeof paramString === 'undefined') ? window.location.search : paramString;
  const search = new URLSearchParams(s);
  if (params) {
    Object.keys(params).forEach((param) => {
      search.delete(param);
      if (params[param]) search.set(param, params[param]);
    });
  }
  Object.keys(filters).forEach((filter) => {
    const param = `filters.${filter}`;
    search.delete(param);
    const val = filters[filter];
    if (val) {
      if (val instanceof Array) {
        val.forEach((key) => {
          search.append(param, key);
        });
      } else {
        search.set(param, val);
      }
    }
  });
  return search;
};

exports.setUrl = (filters, ref, history, params = {}) => {
  const search = searchUrl(filters, params);
  const firstUpdate = ref;
  if (firstUpdate.current) {
    firstUpdate.current = false;
  } else {
    search.set('page', '1');
  }
  history.push(`${history.location.pathname}?${search.toString()}`);
};

exports.setPeriodFilters = (after, key, operator, from, to) => {
  const keyFrom = `${key}~~from`;
  const keyTo = `${key}~~to`;
  const keyType = `${key}Type`;
  const filters = { [keyFrom]: null, [keyTo]: null };
  const params = { [keyType]: operator };

  const _from = moment(from).startOf('day').toISOString();

  if (from && to && operator === 'Between') {
    if (from < to) {
      filters[keyFrom] = _from;
      filters[keyTo] = moment(to).endOf('day').toISOString();
    } else {
      filters[keyFrom] = moment(to).startOf('day').toISOString();
      filters[keyTo] = moment(from).endOf('day').toISOString();
    }
  }
  if (from) {
    switch (operator) {
      case '>=': filters[keyFrom] = _from; break;
      case '>': filters[keyFrom] = moment(from).startOf('day').add(1, 'days').toISOString(); break;
      case '<=': filters[keyTo] = _from; break;
      case '<': filters[keyTo] = moment(from).startOf('day').subtract(1, 'seconds').toISOString(); break;
      case '=': filters[keyFrom] = _from; filters[keyTo] = moment(from).endOf('day').toISOString(); break;
      default: break;
    }
  }
  after(filters, params);
};

const getOperator = key => new URLSearchParams(window.location.search).get(`${key}Type`);

exports.dateFromFilters = (filters, key) => {
  let res = null;
  const type = getOperator(key) || 'Between';
  if (type) {
    let _key;
    if (['>', '>=', '=', 'Between'].includes(type)) {
      _key = `${key}~~from`;
    } else if (['<', '<='].includes(type)) {
      _key = `${key}~~to`;
    }
    if (filters?.[_key]) {
      res = filters[_key];
      if (type === '>') res = moment(res).subtract(1, 'days').toISOString();
      else if (type === '<') res = moment(res).add(1, 'seconds').toISOString();
    }
  }
  return res;
};

exports.endDateFromFilters = (filters, key) => {
  const type = getOperator(key) || 'Between';
  const _key = `${key}~~to`;
  return (filters?.[_key] && type === 'Between') ? filters[_key] : null;
};

exports.getContentApprovalsFilter = (range = null, filters = {}) => {
  let startDate = moment().startOf('Year').format('YYYY-MM-DD HH:mm:ss');
  if (range && range === '7_days') {
    startDate = moment().subtract(8, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
  }
  const timeFilters = `filters.createdAt~~from=${startDate}&filters.createdAt~~to=${moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')}`;
  let res = `${timeFilters}&filters.brandStatus=In+Review&filters.brandStatus=Pending+Edits&filters.isFTCCompliant=Compliant`; // &filters.status=Awaiting+Brand+Approval
  Object.keys(filters).forEach((k) => {
    res += '&';
    res += filters[k].map(f => `filters.${k}=${f.value}`).join('&');
  });
  return res;
};

exports.getDealsFilterTypes = () => ({
  Hybrid: true,
  'CPA/Ambassador': true,
  'Upfront Only': true,
  'Upfront CPM': true,
  'Media License': true,
  Bonus: true,
});

/*
  The function will try to produce equal size collumns and each collumn will not exceed maxCollumnSize.
  For example: if maxCollumnSize = 5 and there are a total of 10 objects, it will produce 2 collumns with 5 elements in each collumn.
  But, if maxCollumnSize = 5 and there are a total of 12 objects, it will produce 3 collumns with 4 elements in each collumn.
  As you can see, it will favour equality of collumns over making each collumn = maxCollumnSize.
*/
exports.splitIntoEqualCollumns = (objectToBreakUp, maxCollumnSize) => {
  const convertArrayToObject = (array, start, end) => array.slice(start, end).reduce((a, b) => {
    const [k, v] = b;
    a[k] = v;
    return a;
  }, {});

  const getPartsSizes = (total, maxPartSize) => {
    const numParts = Math.ceil(total / maxPartSize);
    const partSize = Math.ceil(total / numParts);
    const partsToReturn = [];

    for (let i = 0; i < numParts; i += 1) {
      if (total >= partSize) {
        partsToReturn.push(partSize);
        total -= partSize;
      } else {
        partsToReturn.push(total);
      }
    }

    return partsToReturn;
  };

  const objectEntries = Object.entries(objectToBreakUp);
  const totalEntries = objectEntries.length;
  const chunkSizes = getPartsSizes(totalEntries, maxCollumnSize);
  const chunksToReturn = [];
  let startWindow = 0;
  let endWindow = 0;

  for (let j = 0; j < chunkSizes.length; j += 1) {
    endWindow += chunkSizes[j];
    chunksToReturn.push(convertArrayToObject(objectEntries, startWindow, endWindow));
    startWindow = endWindow;
  }

  return chunksToReturn;
};

exports.searchUrl = searchUrl;
exports.getOperator = getOperator;
