import React, { useState } from 'react';
import { Form, FormGroup, Label, Input, Button, Row, Col } from 'reactstrap';
import axios from 'axios';
import withNotice from '../../../../App/store/with-notice';

const RedirectDomainForm = ({ brandName = '', toggleRedirectDomainForm, setBackendMessage, setSelectedDomaintValue, addNotice }) => {
  const [formData, setFormData] = useState({
    redirectUrlIndex: '',
    redirectUrl404: '',
    trackingPixels: [],
    logoPath: '',
    logoUrl: '',
    ogTitle: '',
    ogDescription: '',
    offerId: '',
  });

  const handleFormSubmit = async () => {
    try {
      const response = await axios.post('/api/portal/redirects/create-redirect-mongodbmodel', 
        { ...formData, domain: brandName }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.data.success) {
        addNotice({
          message: `Redirect Domain '${brandName}' was created successfully!`,
          type: 'success',
        });
        setSelectedDomaintValue({});
        toggleRedirectDomainForm();
        setBackendMessage(`Redirect Domain '${brandName}' was created successfully!`);
      } else {
        addNotice({
          message: `Failed to crate RedirectDomain '${brandName}'! ${response.data.error}`,
          type: 'error',
        });
      }
    } catch (error) {
      addNotice({
        message: `Error creating Redirect Domain. Error: ${error.message}`,
        type: 'error',
      });
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleFormSubmit) {
      handleFormSubmit(formData);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleOfferIdInputChange = (e) => {
    const { name, value } = e.target;
    const urlString = `https://go.influencerlogic.com/aff_c?offer_id=${value}&aff_id=4660&aff_sub=catch&aff_sub2=catch&aff_sub4=catch`;
    setFormData((prev) => ({ ...prev, [name]: value, 'redirectUrlIndex': urlString, 'redirectUrl404': urlString }));
  };

  const handlePixelChange = (index, field, value) => {
    const updatedPixels = [...formData.trackingPixels];
    updatedPixels[index][field] = value;
    setFormData((prev) => ({ ...prev, trackingPixels: updatedPixels }));
  };

  const addPixel = () => {
    setFormData((prev) => ({
      ...prev,
      trackingPixels: [...prev.trackingPixels, { name: '', location: 'head', pixelBody: '' }],
    }));
  };

  const removePixel = (index) => {
    setFormData((prev) => ({
      ...prev,
      trackingPixels: prev.trackingPixels.filter((_, i) => i !== index),
    }));
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <h2>Create Redirect Domain</h2>

      <FormGroup>
        <Label for="domain">Domain</Label>
        <Input
          type="text"
          name="domain"
          id="domain"
          value={brandName}
          onChange={handleInputChange}
          required
          readOnly
        />
      </FormGroup>

      <FormGroup>
        <Label for="offerId">Offer ID</Label>
        <Input
          type="text"
          name="offerId"
          id="offerId"
          value={formData.offerId}
          onChange={handleOfferIdInputChange}
        />
      </FormGroup>

      <FormGroup>
        <Label for="redirectUrlIndex">Redirect URL (Index)</Label>
        <Input
          type="text"
          name="redirectUrlIndex"
          id="redirectUrlIndex"
          value={formData.redirectUrlIndex}
          onChange={handleInputChange}
        />
      </FormGroup>

      <FormGroup>
        <Label for="redirectUrl404">Redirect URL (404)</Label>
        <Input
          type="text"
          name="redirectUrl404"
          id="redirectUrl404"
          value={formData.redirectUrl404}
          onChange={handleInputChange}
        />
      </FormGroup>

      <h4>Tracking Pixels</h4><br />
      {formData.trackingPixels.map((pixel, index) => (
        <Row key={index} form>
          <Col md={3}>
            <FormGroup>
              <Label for={`pixelName-${index}`}>Pixel Name</Label>
              <Input
                type="text"
                id={`pixelName-${index}`}
                value={pixel.name}
                onChange={(e) => handlePixelChange(index, 'name', e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for={`pixelLocation-${index}`}>Pixel Location</Label>
              <Input
                type="select"
                id={`pixelLocation-${index}`}
                value={pixel.location}
                onChange={(e) => handlePixelChange(index, 'location', e.target.value)}
              >
                <option value="head">Head</option>
                <option value="body">Body</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for={`pixelBody-${index}`}>Pixel Body</Label>
              <Input
                type="textarea"
                id={`pixelBody-${index}`}
                value={pixel.pixelBody}
                onChange={(e) => handlePixelChange(index, 'pixelBody', e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md={2} className="d-flex align-items-center">
            <Button color="danger" type="button" onClick={() => removePixel(index)}>
              Remove
            </Button>
          </Col>
        </Row>
      ))}
      <Button color="primary" type="button" onClick={addPixel}>
        Add Pixel
      </Button>

      <FormGroup>
        <Label for="logoPath">Logo Path</Label>
        <Input
          type="text"
          name="logoPath"
          id="logoPath"
          value={formData.logoPath}
          onChange={handleInputChange}
        />
      </FormGroup>

      <FormGroup>
        <Label for="logoUrl">Logo URL</Label>
        <Input
          type="text"
          name="logoUrl"
          id="logoUrl"
          value={formData.logoUrl}
          onChange={handleInputChange}
        />
      </FormGroup>

      <FormGroup>
        <Label for="ogTitle">OG Title</Label>
        <Input
          type="text"
          name="ogTitle"
          id="ogTitle"
          value={formData.ogTitle}
          onChange={handleInputChange}
        />
      </FormGroup>

      <FormGroup>
        <Label for="ogDescription">OG Description</Label>
        <Input
          type="textarea"
          name="ogDescription"
          id="ogDescription"
          value={formData.ogDescription}
          onChange={handleInputChange}
        />
      </FormGroup>

      <Button 
        color="primary"
        className="float-left"
        onClick={handleSubmit}
      >
        Submit
      </Button>
      <Button
        color="secondary"
        className="float-right"
        onClick={toggleRedirectDomainForm}
      >
        Cancel
      </Button>
      
    </Form>
  );
};

export default withNotice(RedirectDomainForm);