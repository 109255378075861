import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  ButtonToolbar,
  Button,
} from 'reactstrap';
import { axios } from 'ApiClient';
import NameInList from '../../../AgentUser/components/custom/NameInListCustom';
import Search from '../../../../../../shared/components/filter/Search';
import MatTableForRedux from '../../../../../../shared/tables/table/MatTableToTable';
import CopyLeft from '../../../AgentUser/components/custom/CopyLeftCustom';
import { searchUrl } from '../../../../../../shared/helpers/Filter';

const Roster = ({
  id,
  close,
}) => {
  const [search, onSearchChange] = useState('');
  // const [loading, setLoading] = useState(false);
  const [agents, setAgents] = useState([]);
  const [filteredAgents, setFilteredAgents] = useState([]);

  const filters = {
    agency: id,
    fullSearchName: search,
  };

  const fetchData = () => {
    if (id) {
      // setLoading(true);
      axios.get(`/api/portal/agent/get-agents-for-agency?id=${id}`)
        .then((response) => {
          const { agents } = response.data;
          setAgents(agents || []);
        });
      // setLoading(false);
    }
  }

  useEffect(() => {
    if (agents?.length > 0) {
      setFilteredAgents(agents.filter(record => `${record?.first_name || ''} ${record?.last_name || ''} ${record?.email || ''}`.trim().includes(search)));
    } else setFilteredAgents([]);
  }, [agents, search]);

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <>
      <Row className="mt-4">
        <Col md={8} className="mb-3">
          <Search
            placeholder="Search Agent Name or Agent Email"
            value={search}
            onChange={onSearchChange}
          />
        </Col>
        <Col md={4}>
          <Button
            color="secondary"
            size="sm"
            onClick={() => window.open(`/api/resources/AgentUser/actions/csv?${searchUrl(filters, {}, 'perPage=500000')}`, '_blank').focus()}
            style={{
              width: '100%',
              maxWidth: '160px',
              padding: '4px 8px',
              height: '42px',
            }}
          >
            <span className="btn-text">Download CSV</span>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <MatTableForRedux
            dataArray={filteredAgents}
            selectable={false}
            columns={[
              {
                Header: () => 'Agent Name',
                accessor: record => (`${record?.first_name || ''} ${record?.last_name || ''}`.trim() || ''),
                Cell: p => (
                  <NameInList record={p.row.original} />
                ),
                className: 'd-flex align-items-center',
                disableSortBy: true,
              },
              {
                Header: () => 'Email',
                accessor: record => (record?.email || ''),
                Cell: p => (
                  <CopyLeft
                    property={{ name: 'email' }}
                    record={p.row.original}
                  />
                ),
                className: 'd-flex align-items-center',
                disableSortBy: true,
              },
            ]}
            version={(new Date()).toString()}
            defaultOrder={{
              order: 'asc',
              orderBy: 'fullName',
            }}
            rowsPerPage={10}
            hideToolbar
          />
        </Col>
      </Row>
      <ButtonToolbar
        className="modal__footer"
      >
        <Button
          className="modal_ok btn-sm is-primary"
          color="primary"
          onClick={close}
        >
          Close
        </Button>
      </ButtonToolbar>
    </>
  );
};

Roster.propTypes = {
  id: PropTypes.string,
  close: PropTypes.func,
};

Roster.defaultProps = {
  id: null,
  close: () => {},
};

export default Roster;
