import React, { useEffect, useState, useRef } from 'react';
import {
  Row, Col, Input, Button, FormGroup,
} from 'reactstrap';

import PropTypes from 'prop-types';
import moment from 'moment';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import SelectFilter from '../../../../../../../shared/components/SelectFilter';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';
import { getConstant } from '../../../../../../../shared/helpers/WVConstants';
import IntervalDatePickerField from '../../../../../Influencer/Research/Prospects/components/custom/IntervalDatePickerField';

const DealsFilter = ({
  deals,
  allStages,
  setRightDeals,
  setVersion,
}) => {
  const dateRangeFilterLabels = [
    { value: '=', label: '=' },
    { value: '>', label: '>' },
    { value: '>=', label: '>=' },
    { value: '<', label: '<' },
    { value: '<=', label: '<=' },
    { value: 'Between', label: 'Between' },
  ];

  const statusInitials = {
    Active: false,
    Pending: false,
    Expired: false,
    Paid: false,
    'Not Set': false,
  };

  const [searchValue, setSearchValue] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [startReset, setStartReset] = useState(false);
  const [offerFilter, setOfferFilter] = useState({});
  const [dealTypeFilter, setDealTypeFilter] = useState({});
  const [dealStageFilter, setDealStageFilter] = useState({});
  const [statusFilter, setStatusFilter] = useState(statusInitials);
  const [enableBadDateFilter, setEnableBadDateFilter] = useState(true);
  const [badFilterType, setBadFilterType] = useState(dateRangeFilterLabels[3]);
  const [brandApprovedDateFrom, setBrandApprovedDateFrom] = useState(null);
  const [brandApprovedDateTo, setBrandApprovedDateTo] = useState(null);
  const [singleBrandApprovedDate, setSingleBrandApprovedDate] = useState(null);
  const [enableCrdDateFilter, setEnableCrdDateFilter] = useState(true);
  const [crdFilterType, setCrdFilterType] = useState(dateRangeFilterLabels[3]);
  const [contentReleaseDateFrom, setContentReleaseDateFrom] = useState(null);
  const [contentReleaseDateTo, setContentReleaseDateTo] = useState(null);
  const [singleContentReleaseDate, setSingleContentReleaseDate] = useState(null);
  const [versionSelectFilters, setVersionSelectFilters] = useState((new Date()).toString());

  const filterDeals = () => {
    let filteredDeals = [];
    deals.forEach((deal) => {
      filteredDeals.push(deal);
    });
    // filter by offer name
    if (Object.values(offerFilter).find(v => v === true)) {
      filteredDeals = filteredDeals.filter(deal => ((deal.ilDealData.offer?.name && offerFilter[deal.ilDealData.offer.name]) || (!deal.ilDealData.offer?.name && offerFilter['Not Assigned'])));
    }
    // filter by deal type
    if (Object.values(dealTypeFilter).find(v => v === true)) {
      filteredDeals = filteredDeals.filter(deal => ((deal.dealTerms.type_of_deal && dealTypeFilter[deal.dealTerms.type_of_deal]) || (!deal.dealTerms.type_of_deal && dealTypeFilter['Not Assigned'])));
    }
    // filter by deal stage
    if (Object.values(dealStageFilter).find(v => v === true)) {
      filteredDeals = filteredDeals.filter(deal => (deal.dealstage && dealStageFilter[allStages[deal.dealstage]]) || (!deal.dealstage && dealStageFilter['Not Assigned']));
    }
    // filter by status
    if (Object.values(statusFilter).find(v => v === true)) {
      filteredDeals = filteredDeals.filter(deal => (deal.dealTerms.cpa_status && statusFilter[deal.dealTerms.cpa_status]) || (!deal.dealTerms.cpa_status && statusFilter['Not Set']));
    }
    // filter by Brand Approved Date
    if (enableBadDateFilter) {
      switch (badFilterType.value) {
        case '=':
          if (singleBrandApprovedDate) {
            filteredDeals = filteredDeals.filter(deal => (deal.brandApprovedDate &&
              (moment(moment(deal.brandApprovedDate).format('MM/DD/YYYY')).isSame(moment(moment(singleBrandApprovedDate.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
        case '>':
          if (singleBrandApprovedDate) {
            filteredDeals = filteredDeals.filter(deal => (deal.brandApprovedDate &&
              (moment(moment(deal.brandApprovedDate).format('MM/DD/YYYY')).isAfter(moment(moment(singleBrandApprovedDate.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
        case '>=':
          if (singleBrandApprovedDate) {
            filteredDeals = filteredDeals.filter(deal => (deal.brandApprovedDate &&
              (moment(moment(deal.brandApprovedDate).format('MM/DD/YYYY')).isSameOrAfter(moment(moment(singleBrandApprovedDate.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
        case '<':
          if (singleBrandApprovedDate) {
            filteredDeals = filteredDeals.filter(deal => (deal.brandApprovedDate &&
              (moment(moment(deal.brandApprovedDate).format('MM/DD/YYYY')).isBefore(moment(moment(singleBrandApprovedDate.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
        case '<=':
          if (singleBrandApprovedDate) {
            filteredDeals = filteredDeals.filter(deal => (deal.brandApprovedDate &&
              (moment(moment(deal.brandApprovedDate).format('MM/DD/YYYY')).isSameOrBefore(moment(moment(singleBrandApprovedDate.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
        default:
          if (!singleBrandApprovedDate && (brandApprovedDateFrom || brandApprovedDateTo)) {
            let dateFrom = brandApprovedDateFrom;
            if (!brandApprovedDateFrom) dateFrom = moment('2000-01-20');
            filteredDeals = filteredDeals.filter(deal => (deal.brandApprovedDate &&
              (moment(moment(deal.brandApprovedDate).format('MM/DD/YYYY'))
                .isBetween(moment(moment(dateFrom.toString()).format('MM/DD/YYYY')), moment(moment(brandApprovedDateTo?.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
      }
    }
    // filter by Content Release Date
    if (enableCrdDateFilter) {
      switch (crdFilterType.value) {
        case '=':
          if (singleContentReleaseDate) {
            filteredDeals = filteredDeals.filter(deal => (deal.dealTerms?.content_release_date &&
              (moment(moment(deal.dealTerms.content_release_date).format('MM/DD/YYYY')).isSame(moment(moment(singleContentReleaseDate.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
        case '>':
          if (singleContentReleaseDate) {
            filteredDeals = filteredDeals.filter(deal => (deal.dealTerms?.content_release_date &&
              (moment(moment(deal.dealTerms.content_release_date).format('MM/DD/YYYY')).isAfter(moment(moment(singleContentReleaseDate.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
        case '>=':
          if (singleContentReleaseDate) {
            filteredDeals = filteredDeals.filter(deal => (deal.dealTerms?.content_release_date &&
              (moment(moment(deal.dealTerms.content_release_date).format('MM/DD/YYYY')).isSameOrAfter(moment(moment(singleContentReleaseDate.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
        case '<':
          if (singleContentReleaseDate) {
            filteredDeals = filteredDeals.filter(deal => (deal.dealTerms?.content_release_date &&
              (moment(moment(deal.dealTerms.content_release_date).format('MM/DD/YYYY')).isBefore(moment(moment(singleContentReleaseDate.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
        case '<=':
          if (singleContentReleaseDate) {
            filteredDeals = filteredDeals.filter(deal => (deal.dealTerms?.content_release_date &&
              (moment(moment(deal.dealTerms.content_release_date).format('MM/DD/YYYY')).isSameOrBefore(moment(moment(singleContentReleaseDate.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
        default:
          if (!singleContentReleaseDate && (contentReleaseDateFrom || contentReleaseDateTo)) {
            let dateFrom = contentReleaseDateFrom;
            if (!contentReleaseDateFrom) dateFrom = moment('2000-01-20');
            filteredDeals = filteredDeals.filter(deal => (deal.dealTerms?.content_release_date &&
              (moment(moment(deal.dealTerms.content_release_date).format('MM/DD/YYYY'))
                .isBetween(moment(moment(dateFrom.toString()).format('MM/DD/YYYY')), moment(moment(contentReleaseDateTo?.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
      }
    }
    // filter by search string
    if (searchValue) {
      filteredDeals = filteredDeals.filter(deal => (deal.dealname?.toLowerCase().includes(searchValue.toLowerCase()) || deal.dealTerms?.coupon_code?.toLowerCase().includes(searchValue.toLowerCase())));
    }
    setRightDeals(filteredDeals);
  };

  const allOffers = getConstant('deal', 'allOffers', []);
  const allDealTypes = getConstant('deal', 'dealTypes', []);

  const customStyles = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? '#e9ecef' : 'white',
      // height: '44px',
    }),
  };

  const initFilterVar = (data, callback, key) => {
    if (data.length > 0) {
      const res = {};
      res['Not Assigned'] = false;
      data.forEach((r) => {
        const k = key ? key(r) : r;
        if (k) {
          res[k] = false;
        }
      });
      callback(res);
    }
  };

  const getOffers = () => { initFilterVar(allOffers?.sort((a, b) => ((a.name < b.name) ? -1 : 1)), setOfferFilter, r => r.name); };
  const getDealTypes = () => { initFilterVar(Object.values(allDealTypes), setDealTypeFilter); };
  const getDealStages = () => { initFilterVar(Object.values(allStages), setDealStageFilter); };

  const resetFilters = () => {
    setStartReset(true);
    getOffers();
    getDealTypes();
    getDealStages();
    setStatusFilter(statusInitials);
    setSearchValue(null);
    setBrandApprovedDateFrom(null);
    setBrandApprovedDateTo(null);
    setSingleBrandApprovedDate(null);
    setBadFilterType(dateRangeFilterLabels[3]);
    if (enableBadDateFilter) setEnableBadDateFilter(!enableBadDateFilter);
    setContentReleaseDateFrom(null);
    setContentReleaseDateTo(null);
    setSingleContentReleaseDate(null);
    setCrdFilterType(dateRangeFilterLabels[3]);
    if (enableCrdDateFilter) setEnableCrdDateFilter(!enableCrdDateFilter);
    setStartReset(false);
    setRightDeals(deals);
    setVersion((new Date()).toString());
    setVersionSelectFilters((new Date()).toString());
  };

  useEffect(() => {
    getOffers();
    getDealTypes();
    getDealStages();
  }, []);

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (!startReset) {
      filterDeals();
      setVersion((new Date()).toString());
    }
  }, [
    searchValue, offerFilter, dealTypeFilter, dealStageFilter, statusFilter,
    singleBrandApprovedDate, badFilterType, brandApprovedDateTo, brandApprovedDateFrom, enableBadDateFilter,
    singleContentReleaseDate, crdFilterType, contentReleaseDateTo, contentReleaseDateFrom, enableCrdDateFilter, deals,
  ]);

  return (<>
    <Row className="mt-2" style={{ marginBottom: '-16px' }}>
      <Col md={5}>
        <div className="table__search">
          <Input
            className="table__search table__search-input"
            value={searchValue || ''}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            type="search"
            placeholder="Search Deal Name, Promo Code"
            style={{ minWidth: '100%' }}
          />
        </div>
      </Col>
      <Col md={3}>
        <Button
          color="secondary"
          onClick={resetFilters}
          style={{ marginBottom: '0px' }}
        >
          <i className="fa fa-sync" aria-hidden="true" />&nbsp;
          <span className="btn-text">Reset</span>
        </Button>
        <Tooltip title="Toggle Filters">
          <IconButton
            id="filtersMenuButton"
            size="small"
            onClick={() => setShowFilters(!showFilters)}
            className="material-table__toolbar-button"
            style={{ marginBottom: '0px' }}
          >
            <TuneIcon size="30" />
          </IconButton>
        </Tooltip>
      </Col>
    </Row>
    {showFilters && (
      <>
        <Row className="mb-1 small-height">
          <Col md={4}>
            <h5>Offers</h5>
            <SelectFilter
              key="123"
              title="Offers"
              filterProp={offerFilter}
              filterAction={setOfferFilter}
              styles={{ marginTop: '8px' }}
              version={versionSelectFilters}
              defaultValue={[]}
            />
          </Col>
          <Col md={4}>
            <h5>Deal Types</h5>
            <SelectFilter
              key="234"
              title="Deal Types"
              filterProp={dealTypeFilter}
              filterAction={setDealTypeFilter}
              styles={{ marginTop: '8px' }}
              version={versionSelectFilters}
              defaultValue={[]}
            />
          </Col>
          <Col md={4}>
            <h5>Deal Stages</h5>
            <SelectFilter
              key="345"
              title="Deal Stages"
              filterProp={dealStageFilter}
              filterAction={setDealStageFilter}
              styles={{ marginTop: '8px' }}
              version={versionSelectFilters}
              defaultValue={[]}
            />
          </Col>
        </Row>
        <Row className="small-height mb-1">
          <Col md={4} style={{ marginTop: '10px' }}>
            <h5>Status</h5>
            <SelectFilter
              key="456"
              title="Status"
              filterProp={statusFilter}
              filterAction={setStatusFilter}
              styles={{ marginTop: '8px' }}
              version={versionSelectFilters}
              defaultValue={[]}
            />
          </Col>
          <Col md={4} style={{ marginTop: '10px' }}>
            <h5>Brand Approved Date</h5>
            <Row style={{ marginTop: '8px' }}>
              <Col sm={1}>
                <FormGroup
                  style={{ paddingTop: '8px', paddingLeft: '8px' }}
                >
                  <CheckBoxField
                    name="BAD"
                    value={enableBadDateFilter}
                    onChange={() => { setEnableBadDateFilter(!enableBadDateFilter); }}
                  />
                </FormGroup>
              </Col>
              <Col sm={4} style={{ paddingLeft: '20px' }}>
                <Select
                  value={badFilterType}
                  onChange={(option) => {
                    if ((badFilterType !== 'Between' && option.value === 'Between') || (badFilterType === 'Between' && option.value !== 'Between')) {
                      setBrandApprovedDateFrom(null);
                      setBrandApprovedDateTo(null);
                      setSingleBrandApprovedDate(null);
                    }
                    setBadFilterType(option);
                  }}
                  options={dateRangeFilterLabels}
                  isDisabled={!enableBadDateFilter}
                  styles={customStyles}
                />
              </Col>
              <Col sm={6}>
                {badFilterType.value === 'Between' ? (
                  <div style={{ marginRight: '-30px' }} className="pb-2">
                    <IntervalDatePickerField
                      onChange={({ start, end }) => {
                        setBrandApprovedDateFrom(start);
                        end?.setHours(23, 59, 59);
                        setBrandApprovedDateTo(end);
                      }}
                      startDate={brandApprovedDateFrom}
                      endDate={brandApprovedDateTo}
                      maxDate={new Date()}
                      placeholderFrom="Start Date"
                      placeholderTo="End Date"
                      verticalFields
                      disabled={!enableBadDateFilter}
                      datePickerCustomClass="datepicker-in-profile"
                    />
                  </div>
                ) : (
                  <div className="date-picker datepicker-in-profile">
                    <DatePicker
                      selected={singleBrandApprovedDate}
                      onChange={(date) => {
                        if (badFilterType.value === '<=' || badFilterType.value === '>') {
                          date?.setHours(23, 59, 59);
                        }
                        setSingleBrandApprovedDate(date);
                      }}
                      dateFormat="MMM d, yyyy"
                      maxDate={new Date()}
                      placeholderText="Select Date"
                      dropDownMode="select"
                      isClearable
                      wrapperClassName="date-picker--interval w-100"
                      className="form-control form-control-sm datepicker-in-profile w-100"
                      disabled={!enableBadDateFilter}
                    />
                    {singleBrandApprovedDate === null && (
                      <svg
                        className="mdi-icon"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        style={{
                          position: 'absolute',
                          top: '8px',
                          right: '22px',
                          fill: '#999',
                          pointerEvents: 'none',
                        }}
                      >
                        <path
                          d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col md={4} style={{ marginTop: '8px' }}>
            <h5>Content Release Date</h5>
            <Row style={{ marginTop: '8px' }}>
              <Col sm={1}>
                <FormGroup
                  style={{ paddingTop: '8px', paddingLeft: '8px' }}
                >
                  <CheckBoxField
                    name="CRD"
                    value={enableCrdDateFilter}
                    onChange={() => { setEnableCrdDateFilter(!enableCrdDateFilter); }}
                  />
                </FormGroup>
              </Col>
              <Col sm={4} style={{ paddingLeft: '20px' }}>
                <Select
                  value={crdFilterType}
                  onChange={(option) => {
                    if ((crdFilterType !== 'Between' && option.value === 'Between') || (crdFilterType === 'Between' && option.value !== 'Between')) {
                      setContentReleaseDateFrom(null);
                      setContentReleaseDateTo(null);
                      setSingleContentReleaseDate(null);
                    }
                    setCrdFilterType(option);
                  }}
                  options={dateRangeFilterLabels}
                  isDisabled={!enableCrdDateFilter}
                  styles={customStyles}
                />
              </Col>
              <Col sm={6}>
                {crdFilterType.value === 'Between' ? (
                  <div style={{ marginRight: '-30px' }} className="pb-2">
                    <IntervalDatePickerField
                      onChange={({ start, end }) => {
                        setContentReleaseDateFrom(start);
                        end?.setHours(23, 59, 59);
                        setContentReleaseDateTo(end);
                      }}
                      startDate={contentReleaseDateFrom}
                      endDate={contentReleaseDateTo}
                      maxDate={new Date()}
                      placeholderFrom="Start Date"
                      placeholderTo="End Date"
                      verticalFields
                      disabled={!enableCrdDateFilter}
                      datePickerCustomClass="datepicker-in-profile"
                    />
                  </div>
                ) : (
                  <div className="date-picker datepicker-in-profile">
                    <DatePicker
                      selected={singleContentReleaseDate}
                      onChange={(date) => {
                        if (crdFilterType.value === '<=' || crdFilterType.value === '>') {
                          date?.setHours(23, 59, 59);
                        }
                        setSingleContentReleaseDate(date);
                      }}
                      dateFormat="MMM d, yyyy"
                      maxDate={new Date()}
                      placeholderText="Select Date"
                      dropDownMode="select"
                      isClearable
                      wrapperClassName="date-picker--interval w-100"
                      className="form-control form-control-sm datepicker-in-profile w-100"
                      disabled={!enableCrdDateFilter}
                    />
                    {singleContentReleaseDate === null && (
                      <svg
                        className="mdi-icon"
                        width="24"
                        height="24"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        style={{
                          position: 'absolute',
                          top: '8px',
                          right: '22px',
                          fill: '#999',
                          pointerEvents: 'none',
                        }}
                      >
                        <path
                          d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                        />
                      </svg>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    )}
  </>);
};

DealsFilter.propTypes = {
  deals: PropTypes.arrayOf(PropTypes.any).isRequired,
  allStages: PropTypes.objectOf(PropTypes.any).isRequired,
  setRightDeals: PropTypes.func.isRequired,
  setVersion: PropTypes.func.isRequired,
};

export default DealsFilter;
