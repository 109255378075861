import React, { useEffect, useState, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container, Nav, NavItem, NavLink, TabContent, TabPane, CardTitle,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { axios } from 'ApiClient';
import classnames from 'classnames';
import { useAsyncDebounce } from 'react-table';
import ApiClient from 'ApiClient';
import Table from './custom/Table';
import Actions from './custom/Actions';
import Breadcrumb from '../../../../../../shared/components/BreadCrumbs';
import withNotice from '../../../../../App/store/with-notice';
import {
  getAdminUsers,
  clearHubspotImportResults,
  loadProspectTubularStatistics,
  changeCurrentLeadId,
  changeCurrentDealOwnerId,
} from '../../../../../../redux/reducers/admin/ResearchReducer';
import AssignContactOwnerModal from './custom/AssignContactOwnerModal';
import AssignDealOwnerModal from './custom/AssignDealOwnerModal';
import DealsListModal from './custom/DealsListModal';
import HubspotImportModal from './custom/HubspotImportModal';
import LogsActions from './custom/LogsActions';
import LogsTable from './custom/LogsTable';
import Reports from './custom/Report';
import { getCookie, setCookie, parseUrl } from '../../../../../../shared/helpers/WVUtilities';
import StatsModal from '../../../../../../shared/components/StatsModal';
import {countriesBH} from "../../../../../../shared/helpers/WVConstants";
import AssignAgentModal from './custom/modals/AssignAgentModal';
import { getConstant } from '@/shared/helpers/WVConstants';

const compare = {
  Match: 'info',
  'No Match': 'noMatch',
};

const getMatchInitial = () => {
  const filters = parseUrl();
  const initialMatchFilters = {
    Match: true,
    'No Match': true,
  };
  if (filters?.existStatusLead) {
    Object.keys(initialMatchFilters).forEach((key) => {
      const rightKey = Object.keys(compare).find(keyInState => keyInState === key);
      initialMatchFilters[key] = filters?.existStatusLead.includes(compare[rightKey]);
    });
  }
  return initialMatchFilters;
};

const getContactOwnerInitial = () => {
  const filters = parseUrl();
  const initialAnalystFilters = {
    Assigned: true,
    'Not Assigned': true,
  };
  if (filters?.contactOwnerAssigned) {
    Object.keys(initialAnalystFilters).forEach((key) => {
      initialAnalystFilters[key] = filters?.contactOwnerAssigned.includes(key);
    });
  }
  return initialAnalystFilters;
};

const getDealOwnerInitial = () => {
  const filters = parseUrl();
  const initialAnalystFilters = {
    Assigned: true,
    'Not Assigned': true,
  };
  if (filters?.dealOwnerAssigned) {
    Object.keys(initialAnalystFilters).forEach((key) => {
      initialAnalystFilters[key] = filters?.dealOwnerAssigned.includes(key);
    });
  }
  return initialAnalystFilters;
};

const getTargetBrandAssignedInitial = () => {
  const filters = parseUrl();
  const initialTargetBrandFilters = {
    Assigned: true,
    'Not Assigned': true,
  };
  if (filters?.targetBrandAssigned) {
    Object.keys(initialTargetBrandFilters).forEach((key) => {
      initialTargetBrandFilters[key] = filters?.targetBrandAssigned.includes(key);
    });
  }
  return initialTargetBrandFilters;
};

const getStatusInitial = () => {
  const filters = parseUrl();
  const initialStatusFilters = {
    Lead: true,
  };
  if (filters?.status) {
    Object.keys(initialStatusFilters).forEach((key) => {
      initialStatusFilters[key] = filters?.status.includes(key);
    });
  }
  return initialStatusFilters;
};

const getFilterValueInitial = (filterType) => {
  let initialFilterType = '<=';
  const type = new URLSearchParams(window.location.search).get(filterType);
  if (type) {
    initialFilterType = type;
  }
  return initialFilterType;
};

const getSingleDateInitial = () => {
  let singleDate = null;
  const filters = parseUrl();
  const type = new URLSearchParams(window.location.search).get('lastADType');
  if (type && type !== 'Between') {
    if ((filters?.hs_last_activity_date?.from) && (type === '>' || type === '>=' || type === '=')) {
      if (filters?.hs_last_activity_date.from) {
        singleDate = new Date(filters.hs_last_activity_date.from);
      }
    }
    if ((filters?.hs_last_activity_date?.to) && (type === '<' || type === '<=')) {
      singleDate = new Date(filters.hs_last_activity_date.to);
    }
  }
  return singleDate;
};

const getIsInternalInitial = () => {
  const filters = parseUrl();
  const initialIsInternalFilters = {
    Standard: true,
    Internal: true,
  };
  if (filters?.prospectType) {
    Object.keys(initialIsInternalFilters).forEach((key) => {
      initialIsInternalFilters[key] = filters?.prospectType.includes(key);
    });
  }
  return initialIsInternalFilters;
};

const getInBlacklistInitial = () => {
  const filters = parseUrl();
  const initialInBlacklistFilters = {
    Yes: true,
    No: true,
  };
  if (filters?.inBlackList) {
    Object.keys(initialInBlacklistFilters).forEach((key) => {
      initialInBlacklistFilters[key] = filters?.inBlackList.includes(key);
    });
  }
  return initialInBlacklistFilters;
};
const getIsOppInitial = () => {
  const filters = parseUrl();
  const initialInBlacklistFilters = {
    yes: true,
    no: true,
  };
  if (filters?.opp) {
    Object.keys(initialInBlacklistFilters).forEach((key) => {
      initialInBlacklistFilters[key] = filters?.opp.includes(key);
    });
  }
  return initialInBlacklistFilters;
};


const basicProspect = {
  firstname: '',
  lastname: '',
};

const LeadsListContainer = ({
  addNotice,
  dispatch,
  loading,
  history,
  action,
  resource,
  adminUsers,
  loadingHubspotImport,
  hubspotImportResults,
  loadingTubularStatistics,
  tubularStatistics,
}) => {
  const [ownersSuggestions, setOwnersSuggestions] = useState([]);
  // const [brandsSuggestions, setBrandsSuggestions] = useState([]);
  const [countrySuggestions, setCountrySuggestions] = useState([]);

  const brandsSuggestions = useMemo(() => {
    const brands = getConstant('deal', 'allAdvertisers', []);
    const brandOptions = brands./* filter(a => a.displayInUi === 'Yes'). */map((b) => {  
      return { id: b._id, name: b.companyName };
    }).sort((a, b) => String(a.name).localeCompare(String(b.name)));
    return brandOptions;
  }, []);
  const getTagsInitial = (tagType, returnObject) => {
    const determineName = (tag) => {
      let result = tag;
      if (tagType === 'contactOwners' && ownersSuggestions.length > 0) {
        ownersSuggestions.forEach((ownerSuggestion) => {
          if (tag === ownerSuggestion.id) {
            result = ownerSuggestion.name;
          }
        });
      }
      if (tagType === 'brands' && brandsSuggestions.length > 0) {
        brandsSuggestions.forEach((brandsSuggestion) => {
          if (tag === brandsSuggestion.id) {
            result = brandsSuggestion.name;
          }
        });
      }
      if (tagType === 'countryOfResidence' && countrySuggestions.length > 0) {
        countrySuggestions.forEach((suggestion) => {
          if (tag === suggestion.id) {
            result = suggestion.name;
          }
        });
      }
      return result;
    };
    const determineObject = (tag) => {
      let result = { name: determineName(tag) };
      if (tagType === 'contactOwners' && ownersSuggestions.length > 0) {
        ownersSuggestions.forEach((ownerSuggestion) => {
          if (tag === ownerSuggestion.id) {
            result = { name: ownerSuggestion.name, id: ownerSuggestion.id };
          }
        });
      }
      if (tagType === 'countryOfResidence' && countrySuggestions.length > 0) {
        countrySuggestions.forEach((suggestion) => {
          if (tag === suggestion.id) {
            result = { name: suggestion.name, id: suggestion.id };
          }
        });
      }
      return result;
    };
    const filters = parseUrl();
    const initialTagFilters = returnObject ? {} : [];
    let filtersProspectTags = [];
    if (filters[tagType] && Array.isArray(filters[tagType])) {
      filtersProspectTags = filters[tagType];
    } else if (filters[tagType]) {
      filtersProspectTags = [filters[tagType]];
    }
    filtersProspectTags.forEach((tag) => {
      if (returnObject) {
        initialTagFilters[determineName(tag)] = true;
      } else {
        initialTagFilters.push(determineObject(tag));
      }
    });
    return initialTagFilters;
  };


  const filters = parseUrl();
  const lastADType = getFilterValueInitial('lastADType');
  const [dateFrom, setDateFrom] = useState(filters?.['hs_last_activity_date.from'] && lastADType === 'Between' ? new Date(filters['hs_last_activity_date.from']) : null);
  const [dateTo, setDateTo] = useState(filters?.['hs_last_activity_date.to'] && lastADType === 'Between' ? new Date(filters['hs_last_activity_date.to']) : null);
  const [singleDate, setSingleDate] = useState(getSingleDateInitial());
  const [enableLastAd, setEnableLastAd] = useState(false);
  const [lastActivityDateValue, setLastActivityDateValue] = useState({ value: lastADType, label: lastADType });
  const [version, setVersion] = useState((new Date()).toString());
  // const [ilFilters] = useState(getIlInitial());
  // const [brandFilters] = useState(getBrandInitial());
  const [tagsFilter, setTagsFilter] = useState(getTagsInitial('tags', true));
  const [ownersFilter, setOwnersFilter] = useState(getTagsInitial('contactOwners', true));
  const [brandsFilter, setBrandsFilter] = useState(getTagsInitial('brands', true));
  const [brandsFilterList, setBrandsFilterList] = useState(getTagsInitial('brands', false));
  const [statusFilters] = useState(getStatusInitial());
  const [matchFilters, setMatchFilters] = useState(getMatchInitial());
  const [contactOwnerFilters, setContactOwnerFilters] = useState(getContactOwnerInitial());
  const [dealOwnerFilters, setDealOwnerFilters] = useState(getDealOwnerInitial());
  const [targetBrandAssignedFilters, setTargetBrandAssignedFilters] = useState(getTargetBrandAssignedInitial());
  const [isInternalFilters, setIsInternalFilters] = useState(getIsInternalInitial());
  const [inBlacklistFilters, setInBlacklistFilters] = useState(getInBlacklistInitial());
  const [isOppFilters, setIsOppFilters] = useState(getIsOppInitial());
  const [searchValue, onSearchChange] = useState(filters.fullSearchName || '');
  const [selectedProspectIds, setSelectedProspectIds] = useState([]);
  const [selectedProspectIdsForImport, setSelectedProspectIdsForImport] = useState([]);
  const [disableGlobalImport, setDisableGlobalImport] = useState(false);
  const [tags, setTags] = useState(getTagsInitial('tags', false));
  const [owners, setOwners] = useState(getTagsInitial('contactOwners', false));
  const [tagsSuggestions, setTagsSuggestions] = useState([]);
  // const [brands, setBrands] = useState([]);
  // const [showAssignContactOwnerModal, setShowAssignContactOwnerModal] = useState(false);
  const [showAssignDealOwnerModal, setShowAssignDealOwnerModal] = useState(false);
  const [metaTotal, setMetaTotal] = useState(0);
  const [hubspotImportVisible, setHubspotImportVisible] = useState(false);
  const [hubspotImportSelectAllVisible, setHubspotImportSelectAllVisible] = useState(false);
  const [activeTab, setActiveTab] = useState(getCookie('LeadsActiveTab') || 'leads');
  const [logsSearchValue, setLogsSearchValue] = useState('');
  const [logsDateFrom, setLogsDateFrom] = useState(null);
  const [logsDateTo, setLogsDateTo] = useState(null);
  const [pseudoUrlLogs, setPseudoUrlLogs] = useState('?filters.uploadStatus=Success&filters.uploadStatus=Fail&filters.uploadStatus=Not+Assigned&sortBy=uploadedDate&direction=desc&requestType=lead&page=1&perPage=10');
  const [statusLogFilters, setStatusLogFilters] = useState({ Success: true, Fail: true, 'Not Assigned': true });
  const [importLogId, setImportLogId] = useState(null);
  const [tableTag, setTableTag] = useState('');
  const [uploadsLeadsLimit, setUploadLeadsLimit] = useState('500');
  const [matTableVersionLogs] = useState(new Date().toString());
  const recordsRef = useRef([]);
  const [prospect, setProspect] = useState(basicProspect);
  const [openStatsModal, setOpenStatsModal] = useState(false);
  const [deals, setDeals] = useState([]);
  const [showDealsListModal, setShowDealsListModal] = useState(false);
  const [tagsCountriesFilter, setTagsCountriesFilter] = useState(getTagsInitial('countryOfResidence', true));
  const [tagsCountries, setTagsCountries] = useState(getTagsInitial('countryOfResidence', false));
  const [countryTableOptions, setCountryTableOptions] = useState([]);

  const mapCountriesOptions = () => {
    const countries = countriesBH();
    const co =  Object.entries(countries).map(([countryCode, countries]) => ({ id: countryCode, name: countries }));
    const coTable =  Object.entries(countries).map(([countryCode, countries]) => ({ value: countryCode, label: countries }));
    co.unshift({ id: 'null', name: '-- Not Assigned --' });
    setCountrySuggestions(co);
    setCountryTableOptions(coTable);
  };

  const getUrlSearch = () => {
    const search = new URLSearchParams(window.location.search);
    if (searchValue) {
      search.set('filters.fullSearchName', searchValue);
    } else {
      search.delete('filters.fullSearchName');
    }
    if (statusFilters && Object.keys(statusFilters).length > 0) {
      search.delete('filters.status');
      Object.keys(statusFilters).forEach((key) => {
        if (!statusFilters[key]) return false;
        search.append('filters.status', key);
        return true;
      });
    }
    if (matchFilters && Object.keys(matchFilters).length > 0) {
      search.delete('filters.existStatusLead');
      Object.keys(matchFilters).forEach((key) => {
        if (!matchFilters[key]) return false;
        search.append('filters.existStatusLead', compare[key]);
        return true;
      });
    }
    if (contactOwnerFilters && Object.keys(contactOwnerFilters).length > 0) {
      search.delete('filters.contactOwnerAssigned');
      Object.keys(contactOwnerFilters).forEach((key) => {
        if (!contactOwnerFilters[key]) return false;
        search.append('filters.contactOwnerAssigned', key);
        return true;
      });
    }
    if (dealOwnerFilters && Object.keys(dealOwnerFilters).length > 0) {
      search.delete('filters.dealOwnerAssigned');
      Object.keys(dealOwnerFilters).forEach((key) => {
        if (!dealOwnerFilters[key]) return false;
        search.append('filters.dealOwnerAssigned', key);
        return true;
      });
    }
    if (targetBrandAssignedFilters && Object.keys(targetBrandAssignedFilters).length > 0) {
      search.delete('filters.targetBrandAssigned');
      Object.keys(targetBrandAssignedFilters).forEach((key) => {
        if (!targetBrandAssignedFilters[key]) return false;
        search.append('filters.targetBrandAssigned', key);
        return true;
      });
    }
    search.delete('filters.tags');
    if (tagsFilter && Object.keys(tagsFilter).length > 0) {
      Object.keys(tagsFilter).forEach((key) => {
        if (!tagsFilter[key]) return false;
        search.append('filters.tags', key);
        return true;
      });
    }
    search.delete('filters.contactOwners');
    if (ownersFilter && Object.keys(ownersFilter).length > 0) {
      Object.keys(ownersFilter).forEach((key) => {
        if (!ownersFilter[key]) return false;
        search.append('filters.contactOwners', key);
        return true;
      });
    }
    search.delete('filters.brands');
    if (brandsFilter && Object.keys(brandsFilter).length > 0) {
      Object.keys(brandsFilter).forEach((key) => {
        if (!brandsFilter[key]) return false;
        search.append('filters.brands', key);
        return true;
      });
    }
    if (inBlacklistFilters && Object.keys(inBlacklistFilters).length > 0) {
      search.delete('filters.inBlackList');
      Object.keys(inBlacklistFilters).forEach((key) => {
        if (!inBlacklistFilters[key]) return false;
        search.append('filters.inBlackList', key);
        return true;
      });
    }
    if (isOppFilters && Object.keys(isOppFilters).length > 0) {
      search.delete('filters.opp');
      Object.keys(isOppFilters).forEach((key) => {
        if (!isOppFilters[key]) return false;
        search.append('filters.opp', key);
        return true;
      });
    }

    search.delete('filters.hs_last_activity_date.from');
    search.delete('filters.hs_last_activity_date.to');
    search.delete('lastADType');
    if (dateFrom && dateTo && lastActivityDateValue.value === 'Between') {
      search.append('filters.hs_last_activity_date.from', dateFrom.toISOString());
      search.append('filters.hs_last_activity_date.to', dateTo.toISOString());
    }
    if (singleDate && singleDate !== '') {
      if (lastActivityDateValue.value === '>' || lastActivityDateValue.value === '>=') {
        search.append('filters.hs_last_activity_date.from', singleDate.toISOString());
      }
      if (lastActivityDateValue.value === '<' || lastActivityDateValue.value === '<=') {
        search.append('filters.hs_last_activity_date.to', singleDate.toISOString());
      }
      if (lastActivityDateValue.value === '=') {
        search.append('filters.hs_last_activity_date.from', singleDate.toISOString());
        const nextDay = new Date(singleDate);
        nextDay.setDate(singleDate.getDate() + 1);
        search.append('filters.hs_last_activity_date.to', nextDay.toISOString());
      }
    }
    if (lastActivityDateValue.value && ((dateTo && dateFrom) || singleDate)) {
      setEnableLastAd(true);
      search.append('lastADType', lastActivityDateValue.value);
    }
    if (isInternalFilters && Object.keys(isInternalFilters).length > 0) {
      search.delete('filters.prospectType');
      Object.keys(isInternalFilters).forEach((key) => {
        if (!isInternalFilters[key]) return false;
        search.append('filters.prospectType', key);
        return true;
      });
    }

    search.delete('filters.countryOfResidence');
    if (tagsCountriesFilter && Object.keys(tagsCountriesFilter).length > 0) {
      Object.keys(tagsCountriesFilter).forEach((key) => {
        if (!tagsCountriesFilter[key]) return false;
        search.append('filters.countryOfResidence', key);
        return true;
      });
    }

    return search;
  };

  const firstUpdate = useRef(true);
  const setUrl = () => {
    const search = getUrlSearch();
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      search.set('page', '1');
    }
    // search.set('filters.allRequiredDataIsSet', 'true');
    history.push(`${history.location.pathname}?${search.toString()}`);
  };

  useEffect(() => {
    setUrl();
  }, [
    searchValue,
    // ilFilters,
    tagsFilter,
    ownersFilter,
    brandsFilter,
    matchFilters,
    contactOwnerFilters,
    dealOwnerFilters,
    targetBrandAssignedFilters,
    enableLastAd, singleDate, dateTo, dateFrom,
    isInternalFilters,
    inBlacklistFilters,
    tagsCountriesFilter,
    isOppFilters,
  ]);

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    if (search.has('lastADType') && search.get('lastADType') !== lastActivityDateValue.value) {
      setUrl();
    }
  }, [lastActivityDateValue]);

  useEffect(() => {
    setOwners(getTagsInitial('contactOwners', false));
  }, [
    ownersSuggestions,
  ]);

  useEffect(() => {
    setBrandsFilterList(getTagsInitial('brands', false));
  }, [
    brandsSuggestions,
  ]);

  useEffect(() => {
    if (countrySuggestions?.length > 0 && filters.countryOfResidence) setTagsCountries(getTagsInitial('countryOfResidence', false));
  }, [
    countrySuggestions,
  ]);

  useEffect(() => {
    // detects if we came back from editing prospect page
    setVersion((new Date()).toString());
  }, [document.location]);

  const handleSelectCheckbox = (selectedIds) => {
    let shouldDisableGlobalImport = false;
    recordsRef.current.forEach((record) => {
      if (selectedIds.includes(record.id)) {
        if (!(record.populated.ownerUser && record.populated?.approvalStatus?.brand?.targetAdvertiserId && !record.params?.inBlackList)) {
          shouldDisableGlobalImport = true;
        }
      }
    });
    setDisableGlobalImport(shouldDisableGlobalImport);
    setSelectedProspectIds(selectedIds);
  };

  if (!enableLastAd && lastActivityDateValue.value && ((dateTo && dateFrom) || singleDate)) {
    setEnableLastAd(true);
  }

  const fetchSuggestions = () => {
    axios({
      method: 'get',
      url: '/api/portal/prospects/get-prospects-tags?suggestionsOnly=true',
    }).then((response) => {
      if (response.data.success === true) {
        setTagsSuggestions(response.data.suggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const fetchOwnersSuggestions = () => {
    axios({
      method: 'get',
      url: '/api/portal/prospects/get-prospect-owners',
    }).then((response) => {
      if (response.data.success === true) {
        setOwnersSuggestions(response.data.suggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  /* const loadBrandsOptions = async () => {
    const res = [
      { value: null, label: '-- Not Assigned --' },
    ];
    const resTagsBrands = [];
    const response = await axios({
      method: 'get',
      url: '/api/portal/scorecard/media-content/get-brands-data?term=',
    });
    if (response.data.success) {
      response.data.brands.forEach((brandRecord) => {
        res.push({
          value: brandRecord._id,
          label: brandRecord.companyName,
          isDisabled: !brandRecord.displayInUi || (brandRecord.displayInUi && brandRecord.displayInUi === 'No'),
        });
        resTagsBrands.push({
          id: brandRecord._id,
          name: brandRecord.companyName,
          isDisabled: !brandRecord.displayInUi || (brandRecord.displayInUi && brandRecord.displayInUi === 'No'),
        });
      });
    }
    // setBrands(res);
    // setBrandsSuggestions(resTagsBrands);
  }; */

  const doHubspotImport = (selectedItem = null) => {
    if (selectedItem) {
      setSelectedProspectIdsForImport([selectedItem]);
      setHubspotImportSelectAllVisible(false);
    } else if (selectedProspectIds.length > 0) {
      setSelectedProspectIdsForImport(selectedProspectIds);
      setHubspotImportSelectAllVisible(true);
    } else {
      addNotice({
        message: 'Select results to import to HubSpot.',
        type: 'warning',
      });
      return;
    }
    setHubspotImportVisible(true);
  };

  const closeHubspotImportModal = () => {
    setVersion((new Date()).toString());
    setHubspotImportVisible(false);
    dispatch(clearHubspotImportResults());
  };

  const startSearchLogs = useAsyncDebounce(() => {
    const search = new URLSearchParams(pseudoUrlLogs);

    search.delete('filters.fullSearchName');
    if (logsSearchValue && logsSearchValue.length > 0) search.append('filters.fullSearchName', logsSearchValue);

    search.delete('filters.uploadStatus');
    Object.keys(statusLogFilters).forEach((key) => {
      if (!statusLogFilters[key]) return false;
      search.append('filters.uploadStatus', key);
      return true;
    });

    search.delete('filters.importLog');
    if (importLogId && importLogId.value) {
      search.append('filters.importLog', importLogId.value.toString());
    }

    search.delete('filters.actionDate.from');
    search.delete('filters.actionDate.to');
    if (logsDateFrom && logsDateFrom !== '') {
      search.append('filters.actionDate.from', logsDateFrom.toISOString());
    }
    if (logsDateTo && logsDateTo !== '') {
      search.append('filters.actionDate.to', logsDateTo.toISOString());
    }
    search.set('requestType', 'lead');
    search.set('page', '1');
    setPseudoUrlLogs(`?${search.toString()}`);
  }, 500);

  useEffect(() => {
    startSearchLogs();
  }, [
    logsSearchValue,
    statusLogFilters,
    importLogId,
    logsDateFrom,
    logsDateTo,
  ]);

  const fetchUploadLeadsLimit = () => {
    const api = new ApiClient();
    api.resourceAction({
      actionName: 'list',
      resourceId: 'Dictionary',
      params: new URLSearchParams('?filters.key=UPLOAD_LEADS_LIMIT'),
    }).then((response) => {
      const listActionReponse = response.data;
      if (listActionReponse.records?.length > 0) {
        setUploadLeadsLimit(listActionReponse.records[0].params.value);
      }
    });
  };

  useEffect(() => {
    fetchSuggestions();
    fetchOwnersSuggestions();
    // loadBrandsOptions();
    if (adminUsers.length < 1) {
      dispatch(getAdminUsers(true));
    }
    fetchUploadLeadsLimit();
    mapCountriesOptions();
  }, []);

  useEffect(() => {
    fetchSuggestions();
    fetchOwnersSuggestions();
  }, [version]);

  const goToEditProspectPage = (prospectId) => {
    document.location = `/research/prospects/edit/${prospectId}`;
  };
  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'Research', path: null },
          { title: 'Import Leads', path: null },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>Import Leads</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Row>
            <Col className="tabs">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'leads' })}
                    onClick={() => {
                      setActiveTab('leads');
                      setCookie('LeadsActiveTab', 'leads', 2);
                    }}
                  >
                    Leads
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'reports' })}
                    onClick={() => {
                      setActiveTab('reports');
                      setCookie('LeadsActiveTab', 'reports', 2);
                    }}
                  >
                    Report
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 'logs' })}
                    onClick={() => {
                      setActiveTab('logs');
                      setCookie('LeadsActiveTab', 'logs', 2);
                    }}
                  >
                    Logs
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="leads">
                  <Actions
                    // ilFilters={ilFilters}
                    // setILFilters={setILFilters}
                    onSearchChange={onSearchChange}
                    tagsSuggestions={tagsSuggestions}
                    setTagsFilter={setTagsFilter}
                    setTags={setTags}
                    tags={tags}
                    getUrlSearch={getUrlSearch}
                    searchValue={searchValue}
                    selectedProspectIds={selectedProspectIds}
                    ownersSuggestions={ownersSuggestions}
                    setOwnersFilter={setOwnersFilter}
                    setOwners={setOwners}
                    owners={owners}
                    brandsSuggestions={brandsSuggestions}
                    setBrandsFilter={setBrandsFilter}
                    setBrands={setBrandsFilterList}
                    brands={brandsFilterList}
                    matchFilters={matchFilters}
                    setMatchFilters={setMatchFilters}
                    contactOwnerFilters={contactOwnerFilters}
                    setContactOwnerFilters={setContactOwnerFilters}
                    dealOwnerFilters={dealOwnerFilters}
                    setDealOwnerFilters={setDealOwnerFilters}
                    targetBrandFilters={targetBrandAssignedFilters}
                    setTargetBrandFilters={setTargetBrandAssignedFilters}
                    isInternalFilters={isInternalFilters}
                    setIsInternalFilters={setIsInternalFilters}
                    inBlacklistFilters={inBlacklistFilters}
                    setInBlacklistFilters={setInBlacklistFilters}
                    // setShowAssignContactOwnerModal={setShowAssignContactOwnerModal}
                    setShowAssignDealOwnerModal={setShowAssignDealOwnerModal}
                    setShowDealsListModal={setShowDealsListModal}
                    doHubspotImport={doHubspotImport}
                    disableGlobalImport={disableGlobalImport}
                    enableLastAd={enableLastAd}
                    setEnableLastAd={setEnableLastAd}
                    lastActivityDateValue={lastActivityDateValue}
                    setLastActivityDateValue={setLastActivityDateValue}
                    dateFrom={dateFrom}
                    setDateFrom={setDateFrom}
                    dateTo={dateTo}
                    setDateTo={setDateTo}
                    singleDate={singleDate}
                    setSingleDate={setSingleDate}
                    countrySuggestions={countrySuggestions}
                    tagsCountries={tagsCountries}
                    setTagsCountries={setTagsCountries}
                    setTagsCountriesFilter={setTagsCountriesFilter}
                    isOppFilters={isOppFilters}
                    setIsOppFilters={setIsOppFilters}
                  />
                  <Row className="">
                    {activeTab === 'leads' && (
                      <Table
                        handleSelectCheckbox={handleSelectCheckbox}
                        action={action}
                        resource={resource}
                        dispatch={dispatch}
                        // brands={brands}
                        addNotice={addNotice}
                        version={version}
                        setVersion={setVersion}
                        doHubspotImport={doHubspotImport}
                        setMetaTotal={setMetaTotal}
                        goToEditProspectPage={goToEditProspectPage}
                        recordsRef={recordsRef}
                        setProspect={setProspect}
                        setOpenStatsModal={setOpenStatsModal}
                        setDeals={setDeals}
                        setShowDealsListModal={setShowDealsListModal}
                        countryOptions={countryTableOptions}
                      />
                    )}
                  </Row>
                </TabPane>
                <TabPane tabId="reports">
                  <Row className="">
                    {activeTab === 'reports' && !loading && (
                      <Reports />
                    )}
                  </Row>
                </TabPane>
                <TabPane tabId="logs">
                  <LogsActions
                    onSearchChange={setLogsSearchValue}
                    searchValue={logsSearchValue}
                    statusFilters={statusLogFilters}
                    setStatusFilters={setStatusLogFilters}
                    logsDateFrom={logsDateFrom}
                    setLogsDateFrom={setLogsDateFrom}
                    logsDateTo={logsDateTo}
                    setLogsDateTo={setLogsDateTo}
                    pseudoUrlLogs={pseudoUrlLogs}
                    importLogId={importLogId}
                    setImportLogId={setImportLogId}
                  />
                  <Row className="">
                    {activeTab === 'logs' && (
                      <LogsTable
                        version={matTableVersionLogs}
                        action={action}
                        resource={resource}
                        pseudoUrlLogs={pseudoUrlLogs}
                        setPseudoUrlLogs={setPseudoUrlLogs}
                        tableTag={tableTag}
                        setTableTag={setTableTag}
                      />
                    )}
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <StatsModal
        isOpen={openStatsModal}
        close={() => {
          setOpenStatsModal(false);
          setProspect(basicProspect);
        }}
        loadData={() => loadProspectTubularStatistics(prospect._id)}
        creatorName={`${prospect.firstname ? `${prospect.firstname} ` : ''}${prospect.lastname ? `${prospect.lastname}` : ''}`}
        loading={loadingTubularStatistics}
        tubularStatistics={tubularStatistics}
      />
      <AssignContactOwnerModal
        setVersion={setVersion}
        adminUsers={adminUsers}
      />
      <AssignDealOwnerModal
        isOpen={showAssignDealOwnerModal}
        closeFunc={() => { setShowAssignDealOwnerModal(false); }}
        setVersion={setVersion}
        prospectIds={selectedProspectIds}
        adminUsers={adminUsers}
        total={metaTotal}
      />
      <DealsListModal
        isOpen={showDealsListModal}
        closeFunc={() => {
          dispatch(changeCurrentLeadId(''));
          dispatch(changeCurrentDealOwnerId(''));
          setShowDealsListModal(false);
        }}
        deals={deals}
      />
      <HubspotImportModal
        title="Hubspot Import"
        modal={hubspotImportVisible}
        setModalVisible={closeHubspotImportModal}
        loading={loadingHubspotImport}
        hubspotImportResults={hubspotImportResults}
        selectedProspectIds={selectedProspectIdsForImport}
        numberOfFound={metaTotal}
        dispatch={dispatch}
        hubspotImportSelectAllVisible={hubspotImportSelectAllVisible}
        UPLOAD_LEADS_LIMIT={uploadsLeadsLimit}
      />
      <AssignAgentModal
        setVersion={setVersion}
        adminUsers={adminUsers}
      />
    </Container>
  );
};

LeadsListContainer.propTypes = {
  addNotice: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
  adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  loadingHubspotImport: PropTypes.bool.isRequired,
  hubspotImportResults: PropTypes.objectOf(PropTypes.any).isRequired,
  loadingTubularStatistics: PropTypes.bool.isRequired,
  tubularStatistics: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  loading: state.ResearchReducer.loading.prospectsGrid,
  adminUsers: state.ResearchReducer.adminUsers,
  loadingHubspotImport: state.ResearchReducer.loading.hubspotImport,
  hubspotImportResults: state.ResearchReducer.hubspotImportResults,
  loadingTubularStatistics: state.ResearchReducer.loading.prospectTubularStatistics,
  tubularStatistics: state.ResearchReducer.prospectTubularStatistics,
});

export default withNotice(connect(mapStateToProps)(withRouter(LeadsListContainer)));
