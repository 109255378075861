/* eslint react/prop-types: 0 */
import React from 'react';
import {
  FormGroup,
  Label,
  Row,
  Col,
  Button,
} from 'reactstrap';
import { CKEditor } from 'ckeditor4-react';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import Select from 'react-select';
import NotificationProvider from 'rc-notification';
import { axios } from 'ApiClient';
import { unflatten } from 'flat';
import { FullWideNotification } from '../../../../../../shared/components/Notification';
import EmailTemplatePreviewModal from '../../../../../../shared/components/modals/EmailTemplatePreviewModal';
import CheckBoxField from '../../../../../../shared/components/CheckBox';

let notification = null;
// eslint-disable-next-line no-return-assign
NotificationProvider.newInstance({ style: {} }, n => notification = n);

export default class BodyInEdit extends React.Component {
  constructor(props) {
    super(props);
    const { body, dataSourcesTypes } = unflatten(this.props.record.params);

    let previewTypeInfluencers = false;
    let previewTypeAdmins = false;
    let previewTypeAgents = false;
    let previewTypeBrands = false;
    if (dataSourcesTypes && dataSourcesTypes.length > 0) {
      if (dataSourcesTypes.includes('creator')) previewTypeInfluencers = true;
      if (dataSourcesTypes.includes('admin')) previewTypeAdmins = true;
      if (dataSourcesTypes.includes('agent')) previewTypeAgents = true;
      if (dataSourcesTypes.includes('brand')) previewTypeBrands = true;
    }
    this.handleDataChange = this.handleDataChange.bind(this);
    this.handleDataSelectChange = this.handleDataSelectChange.bind(this);
    this.handlePreviewTokensChange = this.handlePreviewTokensChange.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.showPreviewModal = this.showPreviewModal.bind(this);
    this.closePreviewModal = this.closePreviewModal.bind(this);

    this.state = {
      dataSources: [],
      dataSourcesTypes,
      loading: {
        dataSources: false,
        modalData: false,
      },
      previewModal: false,
      body,
      subjectWithData: '',
      bodyWithData: '',
      previewTypeInfluencers,
      previewTypeAdmins,
      previewTypeAgents,
      previewTypeBrands,
      selectedInfluencerOption: null,
      selectedAdminOption: null,
      selectedAgentOption: null,
      selectedBrandOption: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  showPreviewModal = async function showPreviewModal() {
    this.setState(prevState => ({
      ...prevState,
      loading: {
        ...prevState.loading,
        modalData: true,
      },
      previewModal: true,
    }));
    const {
      body,
      previewTypeInfluencers,
      previewTypeAdmins,
      previewTypeAgents,
      previewTypeBrands,
      selectedInfluencerOption,
      selectedAdminOption,
      selectedAgentOption,
      selectedBrandOption,
    } = this.state;
    let influencer = null;
    let admin = null;
    let agent = null;
    let brand = null;
    if (previewTypeInfluencers && selectedInfluencerOption && selectedInfluencerOption.value) {
      influencer = selectedInfluencerOption.value;
    }
    if (previewTypeAdmins && selectedAdminOption && selectedAdminOption.value) {
      admin = selectedAdminOption.value;
    }
    if (previewTypeAgents && selectedAgentOption && selectedAgentOption.value) {
      agent = selectedAgentOption.value;
    }
    if (previewTypeBrands && selectedBrandOption && selectedBrandOption.value) {
      brand = selectedBrandOption.value;
    }
    const subject = document.getElementById('subject').value;
    const useWrapping = document.getElementById('useWrapping').checked;
    const templateWrapping = useWrapping ? 'wrapping-influencelogic-default' : 'wrapping-disabled';

    axios.post(
      '/api/portal/emailTemplates/email-template-preview-universal-users',
      {
        templateWrapping,
        influencer,
        admin,
        agent,
        brand,
        subject,
        body,
      },
    ).then((response) => {
      const { emailSubject, emailBody } = response.data;
      this.setState(prevState => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          modalData: false,
        },
        subjectWithData: emailSubject,
        bodyWithData: emailBody,
      }));
    }).catch((error) => {
      console.log(error);
      this.setState(prevState => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          modalData: false,
        },
        previewModal: false,
      }));
    });
  };

  closePreviewModal() {
    this.setState({ previewModal: false });
  }

  handleDataChange(event) {
    const { onChange, enableSaveButton } = this.props;
    const { editor } = event;
    enableSaveButton(false);
    this.setState({ body: editor.getData() });
    onChange('body', editor.getData());
    enableSaveButton(true);
  }

  copyToken = (e) => {
    let textInput = false;
    try {
      textInput = e.nativeEvent.target.closest('.form__form-group-field').querySelector('input');
    } catch (err) {
      textInput = false;
    }

    e.preventDefault();

    if (textInput) {
      textInput.select();
      document.execCommand('copy');

      notification.notice({
        content: <FullWideNotification color="success" title="" message="Copied..." />,
        duration: 1,
        closable: false,
        style: { top: 0, left: 0 },
        className: 'full',
      });
    }
  };

  fetchData() {
    this.setState(prevState => ({
      ...prevState,
      loading: {
        ...prevState.loading,
        dataSources: false,
      },
    }));

    axios.get('/api/portal/emailTemplates/preview-data').then((response) => {
      this.setState(prevState => ({
        ...prevState,
        dataSources: response.data.dataSources,
        loading: {
          ...prevState.loading,
          dataSources: false,
        },
      }));
    }).catch((error) => {
      console.log(error);
      this.setState(prevState => ({
        ...prevState,
        loading: {
          ...prevState.loading,
          dataSources: false,
        },
      }));
    });
  }

  handlePreviewTokensChange(event) {
    if (event && event.target) {
      const { checked, name } = event.target;
      this.setState({ [name]: checked });
    }
  }

  handleDataSelectChange(selectedOption, name) {
    if (selectedOption && name) {
      this.setState({ [name]: selectedOption });
    }
  }

  render() {
    const {
      body,
      subjectWithData,
      bodyWithData,
      dataSources,
      dataSourcesTypes,
      loading,
      previewTypeAdmins,
      previewTypeInfluencers,
      previewTypeBrands,
      previewTypeAgents,
      previewModal,
      selectedInfluencerOption,
      selectedAdminOption,
      selectedAgentOption,
      selectedBrandOption,
    } = this.state;

    const tokens = [
      '{{date}}',
      '{{il_creator_portal_url}}',
      '{{il_admin_portal_url}}',
      '{{il_agent_portal_url}}',
      '{{il_brand_portal_url}}',
    ];

    const optionsInfluencers = [];
    const optionsAdmins = [];
    const optionsAgents = [];
    const optionsBrands = [];

    if (previewTypeInfluencers) {
      tokens.push(
        '{{il_creator_first_name}}',
        '{{il_creator_last_name}}',
        '{{il_creator_email}}',
        '{{il_creator_reset_pw_url}}',
        '{{il_creator_payout_amount}}',
        '{{il_device_verification_code}}',
        '{{brandNameOpportunity}}',
        '{{il_bill_com_invoice_number}}',
        '{{il_transaction_id}}',
        '{{il_deal_brand}}',
        '{{il_deal_offer}}',
        '{{payment_date}}',
        '{{media_url}}',
        '{{media_publish_date}}',
      );
      if (dataSources && dataSources.influencers) {
        dataSources.influencers.forEach((user) => {
          const option = {
            value: user._id,
            label: `${user.first_name} ${user.last_name}`.trim(),
          };
          optionsInfluencers.push(option);
        });
      }
    }

    if (previewTypeAdmins) {
      tokens.push(
        '{{il_admin_first_name}}',
        '{{il_admin_last_name}}',
        '{{il_admin_email}}',
        '{{il_admin_reset_pw_url}}',
      );
      if (dataSources && dataSources.admins) {
        dataSources.admins.forEach((user) => {
          const option = {
            value: user._id,
            label: `${user.firstName} ${user.lastName}`.trim(),
          };
          optionsAdmins.push(option);
        });
      }
    }

    if (previewTypeAgents) {
      tokens.push(
        '{{il_agent_first_name}}',
        '{{il_agent_last_name}}',
        '{{il_agent_email}}',
        '{{il_agent_reset_pw_url}}',
        '{{il_agent_payout_amount}}',
        '{{il_agent_creator_first_name}}',
        '{{il_agent_creator_last_name}}',
        '{{il_bill_com_invoice_number}}',
        '{{il_transaction_id}}',
        '{{il_deal_brand}}',
        '{{il_deal_offer}}',
        '{{payment_date}}',
        '{{media_url}}',
        '{{media_publish_date}}',
      );
      if (dataSources && dataSources.agents) {
        dataSources.agents.forEach((user) => {
          const option = {
            value: user._id,
            label: `${user.first_name} ${user.last_name}`.trim(),
          };
          optionsAgents.push(option);
        });
      }
    }

    if (previewTypeBrands) {
      tokens.push(
        '{{il_brand_first_name}}',
        '{{il_brand_last_name}}',
        '{{il_brand_email}}',
        '{{il_brand_reset_pw_url}}',
      );
      if (dataSources && dataSources.brands) {
        dataSources.brands.forEach((user) => {
          const option = {
            value: user._id,
            label: `${user.firstName} ${user.lastName}`.trim(),
          };
          optionsBrands.push(option);
        });
      }
    }

    return (
      <div
        style={{ marginBottom: '15px' }}
      >
        <Row>
          <Col md={7}>
            <FormGroup style={{ width: '100%' }}>
              <Label for="bodyEditor">Email Body</Label>
              <CKEditor
                config={{versionCheck: false}}
                id="bodyEditor"
                name="body"
                initData={body}
                onChange={this.handleDataChange}
              />
            </FormGroup>
          </Col>
          <Col
            md={2}
            style={{ paddingTop: '30px' }}
          >
            {(!dataSourcesTypes || dataSourcesTypes.length === 0 || dataSourcesTypes.includes('creator')) && (
              <FormGroup>
                <CheckBoxField
                  name="previewTypeInfluencers"
                  label="Use Creator's tokens in preview"
                  value={previewTypeInfluencers}
                  onChange={this.handlePreviewTokensChange}
                />
                <Select
                  name="influencerSelect"
                  placeholder="Select a Creator"
                  isDisabled={!previewTypeInfluencers}
                  value={selectedInfluencerOption}
                  options={optionsInfluencers}
                  onChange={selected => this.handleDataSelectChange(selected, 'selectedInfluencerOption')}
                />
              </FormGroup>
            )}

            {(!dataSourcesTypes || dataSourcesTypes.length === 0 || dataSourcesTypes.includes('admin')) && (
              <FormGroup>
                <CheckBoxField
                  name="previewTypeAdmins"
                  label="Use Admin's tokens in preview"
                  value={previewTypeAdmins}
                  onChange={this.handlePreviewTokensChange}
                />
                <Select
                  name="adminSelect"
                  placeholder="Select an Admin"
                  isDisabled={!previewTypeAdmins}
                  value={selectedAdminOption}
                  options={optionsAdmins}
                  onChange={selected => this.handleDataSelectChange(selected, 'selectedAdminOption')}
                />
              </FormGroup>
            )}

            {(!dataSourcesTypes || dataSourcesTypes.length === 0 || dataSourcesTypes.includes('agent')) && (
              <FormGroup>
                <CheckBoxField
                  name="previewTypeAgents"
                  label="Use Agent's tokens in preview"
                  value={previewTypeAgents}
                  onChange={this.handlePreviewTokensChange}
                />
                <Select
                  name="agentSelect"
                  placeholder="Select an Agent"
                  isDisabled={!previewTypeAgents}
                  value={selectedAgentOption}
                  options={optionsAgents}
                  onChange={selected => this.handleDataSelectChange(selected, 'selectedAgentOption')}
                />
              </FormGroup>
            )}

            {(!dataSourcesTypes || dataSourcesTypes.length === 0 || dataSourcesTypes.includes('brand')) && (
              <FormGroup>
                <CheckBoxField
                  name="previewTypeBrands"
                  label="Use Brand's tokens in preview"
                  value={previewTypeBrands}
                  onChange={this.handlePreviewTokensChange}
                />
                <Select
                  name="brandSelect"
                  placeholder="Select a Brand"
                  isDisabled={!previewTypeBrands}
                  value={selectedBrandOption}
                  options={optionsBrands}
                  onChange={selected => this.handleDataSelectChange(selected, 'selectedBrandOption')}
                />
              </FormGroup>
            )}

            <Button
              className="w-100"
              color="primary"
              onClick={() => this.showPreviewModal()}
              disabled={loading.dataSources}
            >
              Show Preview
            </Button>
          </Col>
          <Col
            md={3}
            style={{ paddingTop: '28px' }}
          >
            <div
              style={{ paddingTop: '1px' }}
            >
              <span className="font-weight-bold">
                Tokens for Subject and Body:
              </span>
              <ul
                className="tokens-list"
                style={{ padding: '2px', maxHeight: '382px', overflowY: 'auto' }}
              >
                {tokens.map(value => (
                  <FormGroup
                    key={`div_${value}`}
                    className="form__form-group-field"
                    style={{ marginTop: '5px' }}
                  >
                    <input
                      style={{ width: '100%', paddingLeft: '5px' }}
                      readOnly
                      value={value}
                    />
                    <button
                      className="btn btn-primary btn-sm"
                      type="button"
                      title="Copy"
                      style={{
                        padding: '5px 5px 5px 10px',
                        height: '38px',
                        margin: '0px',
                        borderRadius: '0px 5px 5px 0px',
                      }}
                      onClick={e => this.copyToken(e)}
                    ><ContentCopyIcon size={16} />
                    </button>
                  </FormGroup>
                  ))}
              </ul>
            </div>
          </Col>
          <EmailTemplatePreviewModal
            subject={subjectWithData}
            body={bodyWithData}
            modal={previewModal}
            closeDialog={this.closePreviewModal}
            loading={loading.modalData}
          />
        </Row>
        {dataSourcesTypes && dataSourcesTypes.length > 0 && (
          <span
            className="font-weight-bold"
            style={{ color: 'red' }}
          >
            Attention! The data sources for the Email Templates do not change dynamically. You are limited to the tokens that work with the data sources used in this Email Template.
            <br />
            Current data sources:&nbsp;
            <span
              className="font-weight-bold"
              style={{ textTransform: 'capitalize' }}
            >
              { dataSourcesTypes.join(', ')}.
            </span>
            <br />
            Contact the Engineering Department for changes in data sources.
          </span>
        )}
      </div>
    );
  }
}

