import func from '@/shared/hooks';
import { getConstant } from '@/shared/helpers/WVConstants';

export default ({
  allTags, 
}) => {
  const pipelines = getConstant('deal', 'pipelines', []);
  const brands = getConstant('deal', 'allAdvertisers', []);
  const stages = pipelines.find(p => p.label.toLowerCase() === 'Main Pipeline'.toLowerCase()).stages;
  console.log('stages', stages, allTags);
  const dealStageOptions = Object.keys(stages).map((k) => {
    return { value: k, label: stages[k] };
  });
  const brandOptions = brands.filter(a => a.displayInUi === 'Yes').map((b) => {  
    return { value: b._id, label: b.companyName };
  }).sort((a, b) => String(a.label).localeCompare(String(b.label)));

  const selectedStages = [
    'New Lead',
    'Awaiting Brand Approval',
    'Initial Outreach',
    'In Negotiations',
    'Pricing Review',
    'Pricing Approved'
  ].map(stage => stage.toLowerCase());
  const allowwedStageOption = dealStageOptions.filter((d) => [...selectedStages, 'offer sent'].includes(d.label.toLowerCase()));
  const selectedstages = dealStageOptions.filter((d) => selectedStages.includes(d.label.toLowerCase()));
  const tagOptions = allTags.map((t) => {
    return { value: t, label: t };
  });
  return [
    {
      initialState: { tags: [] },
      func: func.getFromUrlSelectfunction,
      funcToUrl: func.getToUrlSelectfunction,
      options: brandOptions || [],
      filterName: 'brand',
    },
    {
      initialState: { tags: [] },
      func: func.getFromUrlSelectfunction,
      funcToUrl: func.getToUrlSelectfunction,
      options: [{ value: 'CPA/Ambassador', label: 'CPA' }, { value: 'Upfront Only', label: 'Upfront' }, { value: 'Upfront CPM', label: 'CPM' }] || [],
      filterName: 'dealType',
    },
    {
      initialState: { tags: selectedstages },
      func: func.getFromUrlSelectfunction,
      funcToUrl: func.getToUrlSelectfunction,
      options: allowwedStageOption || [],
      filterName: 'dealStages',
    },
    {
      initialState: { tags: [{ value: 'new', label: 'New' }] },
      func: func.getFromUrlSelectfunction,
      funcToUrl: func.getToUrlSelectfunction,
      options: [
        { value: 'new', label: 'New' },
        { value: 'sent', label: 'Sent' },
      ],
      filterName: 'status',
    },
    {
      initialState: { tags: [] },
      func: func.getFromUrlSelectfunction,
      funcToUrl: func.getToUrlSelectfunction,
      options: tagOptions || [],
      filterName: 'tags',
    },
  ]
};
