import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardTitle, Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useAsyncDebounce } from 'react-table';
import { axios } from 'ApiClient';
import { getAdminUsers } from '../../../../../../redux/reducers/admin/ResearchReducer';
import Table from './custom/Table';
import Actions from './custom/Actions';
import Breadcrumb from '../../../../../../shared/components/BreadCrumbs';
import NotesModal from './custom/modals/NotesModal';
import withNotice from '../../../../../App/store/with-notice';
import { parseUrl, processUrlSearch } from '../../../../../../shared/helpers/WVUtilities';
import { fetchDictionary } from '../../../../../../shared/helpers/WVRequest';
import DetailsModal from './custom/modals/DetailsModal';
import StatusHistoryModal from './custom/modals/StatusHistoryModal';
import ResubmitProspectModal from './custom/modals/ResubmitProspectModal';
import ShowRejectedApprovedBrands from '../../Search/components/modals/ShowRejectedApprovedBrands';
import EditTagsModal from './custom/modals/EditTagsModal';
import { countriesBH } from '../../../../../../shared/helpers/WVConstants';

const compare = {
  Match: 'info',
  'Possible Match': 'warning',
  'No Match': 'noMatch',
};

const goToEditProspectPage = (prospectId) => {
  document.location = `/research/prospects/edit/${prospectId}`;
};

const getIlStatusInitial = () => {
  const filters = parseUrl();
  const initialILFilters = {
    Approved: true,
    Hold: true,
    'In Review': true,
    Mining: true,
    Rejected: true,
    Resubmit: true,
  };
  if (filters?.approvalStatusinfluencelogicstatus) {
    Object.keys(initialILFilters).forEach((key) => {
      initialILFilters[key] = filters?.approvalStatusinfluencelogicstatus.includes(key);
    });
  }
  return initialILFilters;
};
const getIsInternalInitial = () => {
  const filters = parseUrl();
  const initialIsInternalFilters = {
    Standard: true,
    Internal: true,
  };
  if (filters?.prospectType) {
    Object.keys(initialIsInternalFilters).forEach((key) => {
      initialIsInternalFilters[key] = filters?.prospectType.includes(key);
    });
  }
  return initialIsInternalFilters;
};
const getIsResubmittedInitial = () => {
  const filters = parseUrl();
  const initialIsResubmittedFilters = {
    Yes: true,
    No: true,
  };
  if (filters?.resubmitted) {
    Object.keys(initialIsResubmittedFilters).forEach((key) => {
      initialIsResubmittedFilters[key] = filters?.resubmitted.includes(key);
    });
  }
  return initialIsResubmittedFilters;
};

const getBrandStatusInitial = () => {
  const filters = parseUrl();
  const initialBrandFilters = {
    Approved: true,
    'In Review': true,
    Hold: true,
    Rejected: true,
    'In Appeals': true,
  };
  if (filters?.approvalStatusbrandstatus) {
    Object.keys(initialBrandFilters).forEach((key) => {
      initialBrandFilters[key] = filters?.approvalStatusbrandstatus.includes(key);
    });
  }
  return initialBrandFilters;
};

const getMatchInitial = () => {
  const filters = parseUrl();
  const initialMatchFilters = {
    Match: true,
    'Possible Match': true,
    'No Match': true,
  };
  if (filters?.existStatus) {
    Object.keys(initialMatchFilters).forEach((key) => {
      const rightKey = Object.keys(compare).find(keyInState => keyInState === key);
      initialMatchFilters[key] = filters?.existStatus.includes(compare[rightKey]);
    });
  }
  return initialMatchFilters;
};

const getDateInitial = (type) => {
  let date = null;
  const filters = parseUrl();
  // eslint-disable-next-line prefer-template
  const name = 'createdAt.' + type;
  if (filters?.[name]) {
    date = new Date(filters[name]);
  }
  return date;
};

const getResubmitDateInitial = (type) => {
  let date = null;
  const filters = parseUrl();
  // eslint-disable-next-line prefer-template
  const name = 'resubmitDate.' + type;
  if (filters?.[name]) {
    date = new Date(filters[name]);
  }
  return date;
};

const InternalProspectsListContainer = ({
  addNotice,
  dispatch,
  loading,
  history,
  action,
  resource,
  adminUsers,
  currentAdminUser,
}) => {
  const [prospectOwnerSuggestions, setProspectOwnerSuggestions] = useState([]);
  const [categorySuggestions, setCategorySuggestions] = useState([]);
  const [countrySuggestions, setCountrySuggestions] = useState([]);
  const getTagsInitial = (tagType, returnObject) => {
    const determineName = (tag) => {
      let result = tag;
      if (tagType === 'prospectOwners' && prospectOwnerSuggestions?.length > 0) {
        prospectOwnerSuggestions.forEach((suggestion) => {
          if (tag === suggestion.id) {
            result = suggestion.name;
          }
        });
      }
      if (tagType === 'adminUsers' && prospectOwnerSuggestions?.length > 0) {
        prospectOwnerSuggestions.forEach((suggestion) => {
          if (tag === suggestion.id) {
            result = suggestion.name;
          }
        });
      }
      if (tagType === 'categories' && categorySuggestions.length > 0) {
        categorySuggestions.forEach((suggestion) => {
          if (tag === suggestion.id) {
            result = suggestion.name;
          }
        });
      }
      if (tagType === 'countryOfResidence' && countrySuggestions.length > 0) {
        countrySuggestions.forEach((suggestion) => {
          if (tag === suggestion.id) {
            result = suggestion.name;
          }
        });
      }
      return result;
    };
    const determineObject = (tag) => {
      let result = { name: determineName(tag) };
      if (tagType === 'prospectOwners') {
        if (prospectOwnerSuggestions?.length > 0) {
          prospectOwnerSuggestions.forEach((suggestion) => {
            if (tag === suggestion.id) {
              result = { name: suggestion.name, id: suggestion.id };
            }
          });
        } else {
          result = { name: `${currentAdminUser.firstName ? currentAdminUser.firstName : ''} ${currentAdminUser.lastName ? currentAdminUser.lastName : ''}`.trim(), id: currentAdminUser._id };
        }
        // console.log(result);
        // prospectOwnerSuggestions.forEach((suggestion) => {
        //   if (tag === suggestion.id) {
        //     result = { name: suggestion.name, id: suggestion.id };
        //   }
        // });
      }
      if (tagType === 'categories' && categorySuggestions.length > 0) {
        categorySuggestions.forEach((suggestion) => {
          if (tag === suggestion.id) {
            result = { name: suggestion.name, id: suggestion.id };
          }
        });
      }
      if (tagType === 'countryOfResidence' && countrySuggestions.length > 0) {
        countrySuggestions.forEach((suggestion) => {
          if (tag === suggestion.id) {
            result = { name: suggestion.name, id: suggestion.id };
          }
        });
      }
      return result;
    };
    const filters = parseUrl();
    const initialTagFilters = returnObject ? {} : [];
    let filtersProspectTags = [];
    if (filters[tagType] && Array.isArray(filters[tagType])) {
      filtersProspectTags = filters[tagType];
    } else if (filters[tagType]) {
      filtersProspectTags = [filters[tagType]];
    }
    filtersProspectTags.forEach((tag) => {
      if (returnObject) {
        initialTagFilters[tag] = true;
      } else if (tagType === 'brands') {
        initialTagFilters.push({ id: tag });
      } else {
        initialTagFilters.push(determineObject(tag));
      }
    });
    return initialTagFilters;
  };
  const filters = parseUrl();
  const [version, setVersion] = useState((new Date()).toString());
  const [ilStatusFilters, setILStatusFilters] = useState(getIlStatusInitial());
  const [brandStatusFilters, setBrandStatusFilters] = useState(getBrandStatusInitial());
  const [matchFilters, setMatchFilters] = useState(getMatchInitial());
  const [tagsFilter, setTagsFilter] = useState(getTagsInitial('tags', true));
  const [tagsBrandsFilter, setTagsBrandsFilter] = useState(getTagsInitial('brands', true));
  const [searchValue, onSearchChange] = useState(filters.fullSearchName || '');
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [disableNote, setDisableNote] = useState(false);
  const [currentProspect, setCurrentProspect] = useState({});
  // const [selectedProspectIds, setSelectedProspectIds] = useState([]);
  const [tags, setTags] = useState(getTagsInitial('tags', false));
  const [tagsSuggestions, setTagsSuggestions] = useState([]);
  const [tagsBrands, setTagsBrands] = useState(getTagsInitial('brands', false));
  const [tagsBrandsSuggestions, setTagsBrandsSuggestions] = useState([]);
  const [tagsProspectOwnersFilter, setTagsProspectOwnersFilter] = useState(getTagsInitial('prospectOwners', true));
  const [prospectOwners, setProspectOwners] = useState(getTagsInitial('prospectOwners', false));

  const [tagsCategoriesFilter, setTagsCategoriesFilter] = useState(getTagsInitial('categories', true));
  const [tagsCategories, setTagsCategories] = useState(getTagsInitial('categories', false));
  const [tagsCountriesFilter, setTagsCountriesFilter] = useState(getTagsInitial('countryOfResidence', true));
  const [tagsCountries, setTagsCountries] = useState(getTagsInitial('countryOfResidence', false));
  const [tagsAdminUserFilter, setTagsAdminUserFilter] = useState(getTagsInitial('adminUsers', true));
  const [adminUsersArray, setAdminUsers] = useState(getTagsInitial('adminUsers', false));

  const [brands, setBrands] = useState([]);
  const [createDateFrom, setCreateDateFrom] = useState(getDateInitial('from'));
  const [createDateTo, setCreateDateTo] = useState(getDateInitial('to'));
  const [resubmitDateFrom, setResubmitDateFrom] = useState(getResubmitDateInitial('from'));
  const [resubmitDateTo, setResubmitDateTo] = useState(getResubmitDateInitial('to'));
  const [portal, setPortal] = useState('influenceLogic');
  const [isInternalFilters, setIsInternalFilters] = useState(getIsInternalInitial());
  const [isResubmittedFilters, setIsResubmittedFilters] = useState(getIsResubmittedInitial());
  const [removeProspectIds, setRemoveProspectIds] = useState(false);
  const [countryTableOptions, setCountryTableOptions] = useState([]);

  const mapCountriesOptions = () => {
    const countries = countriesBH();
    const co =  Object.entries(countries).map(([countryCode, countries]) => ({ id: countryCode, name: countries }));
    const coTable =  Object.entries(countries).map(([countryCode, countries]) => ({ value: countryCode, label: countries }));
    co.unshift({ id: 'null', name: '-- Not Assigned --' });
    setCountrySuggestions(co);
    setCountryTableOptions(coTable);
  };

  const fetchProspectOwnerSuggestions = () => {
    axios({
      method: 'get',
      url: '/api/portal/prospects/get-all-admin-users',
    }).then((response) => {
      if (response.data.success === true) {
        const suggestions = [];
        response.data.data.forEach((user) => {
          suggestions.push({ id: user.value, name: user.label });
        });
        setProspectOwnerSuggestions(suggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const fetchDictionaryData = async () => {
    await fetchDictionary('PROSPECT_').then((data) => {
      const { success, records } = data;
      if (success === true) {
        const _categories = records.PROSPECT_CATEGORIES ? JSON.parse(records.PROSPECT_CATEGORIES) : [];
        const _categorySuggestions = _categories.map(row => ({ id: row, name: row }));
        _categorySuggestions.unshift({ id: 'null', name: '-- Not Assigned --' });
        setCategorySuggestions(_categorySuggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const getUrlSearch = () => {
    let search = new URLSearchParams(window.location.search);

    if (removeProspectIds) {
      search.delete('filters.prospectId');
    }

    if (searchValue) {
      search.set('filters.fullSearchName', searchValue);
    } else {
      search.delete('filters.fullSearchName');
    }
    if (isInternalFilters && Object.keys(isInternalFilters).length > 0) {
      search.delete('filters.prospectType');
      Object.keys(isInternalFilters).forEach((key) => {
        if (!isInternalFilters[key]) return false;
        search.append('filters.prospectType', key);
        return true;
      });
    }
    if (isResubmittedFilters && Object.keys(isResubmittedFilters).length > 0) {
      search.delete('filters.resubmitted');
      Object.keys(isResubmittedFilters).forEach((key) => {
        if (!isResubmittedFilters[key]) return false;
        search.append('filters.resubmitted', key);
        return true;
      });
    }
    // if (!search.has('filters.prospectType')) {
    //   search.append('filters.prospectType', 'Internal');
    // }
    if (ilStatusFilters && Object.keys(ilStatusFilters).length > 0) {
      search = processUrlSearch(search, ilStatusFilters, 'approvalStatusinfluencelogicstatus', true);
    }
    if (brandStatusFilters && Object.keys(brandStatusFilters).length > 0) {
      search = processUrlSearch(search, brandStatusFilters, 'approvalStatusbrandstatus', true);
    }
    if (matchFilters && Object.keys(matchFilters).length > 0) {
      search = processUrlSearch(search, matchFilters, 'existStatus', true, compare);
    }
    search.delete('filters.tags');
    if (tagsFilter && Object.keys(tagsFilter).length > 0) {
      search = processUrlSearch(search, tagsFilter, 'tags');
    }

    search.delete('filters.brands');
    if (tagsBrandsFilter && Object.keys(tagsBrandsFilter).length > 0) {
      search = processUrlSearch(search, tagsBrandsFilter, 'brands');
    }
    search.delete('filters.prospectOwners');
    if (tagsProspectOwnersFilter && Object.keys(tagsProspectOwnersFilter).length > 0) {
      search = processUrlSearch(search, tagsProspectOwnersFilter, 'prospectOwners');
    }
    search.delete('filters.adminUsers');
    if (tagsAdminUserFilter && Object.keys(tagsAdminUserFilter).length > 0) {
      search = processUrlSearch(search, tagsAdminUserFilter, 'adminUsers');
    }
    search.delete('filters.createdAt.from');
    search.delete('filters.createdAt.to');
    if (createDateFrom && createDateFrom !== '') {
      search.append('filters.createdAt.from', createDateFrom.toISOString());
    }
    if (createDateTo && createDateTo !== '') {
      search.append('filters.createdAt.to', createDateTo.toISOString());
    }

    search.delete('filters.resubmitDate.from');
    search.delete('filters.resubmitDate.to');
    if (resubmitDateFrom && resubmitDateFrom !== '') {
      search.append('filters.resubmitDate.from', resubmitDateFrom.toISOString());
    }
    if (resubmitDateTo && resubmitDateTo !== '') {
      search.append('filters.resubmitDate.to', resubmitDateTo.toISOString());
    }
    search.delete('requestType');
    search.append('requestType', 'internal');

    search.delete('filters.categories');
    if (tagsCategoriesFilter && Object.keys(tagsCategoriesFilter).length > 0) {
      Object.keys(tagsCategoriesFilter).forEach((key) => {
        if (!tagsCategoriesFilter[key]) return false;
        search.append('filters.categories', key);
        return true;
      });
    }

    search.delete('filters.countryOfResidence');
    if (tagsCountriesFilter && Object.keys(tagsCountriesFilter).length > 0) {
      Object.keys(tagsCountriesFilter).forEach((key) => {
        if (!tagsCountriesFilter[key]) return false;
        search.append('filters.countryOfResidence', key);
        return true;
      });
    }

    return search;
  };
  console.log(isInternalFilters, 'isInternalFilters');

  const firstUpdate = useRef(true);
  const setUrl = () => {
    const search = getUrlSearch();
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      search.set('page', '1');
    }
    history.push(`${history.location.pathname}?${search.toString()}`);
  };

  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 500);

  useEffect(() => {
    setUrlDebounced();
  }, [
    searchValue,
    ilStatusFilters,
    brandStatusFilters,
    matchFilters,
    tagsFilter,
    tagsBrandsFilter,
    tagsProspectOwnersFilter,
    tagsAdminUserFilter,
    createDateFrom,
    createDateTo,
    isInternalFilters,
    isResubmittedFilters,
    resubmitDateFrom,
    resubmitDateTo,
    removeProspectIds,
    tagsCategoriesFilter,
    tagsCountriesFilter,
  ]);

  useEffect(() => {
    setProspectOwners(getTagsInitial('prospectOwners', false));
    console.log('ADMIN USER', getTagsInitial('prospectOwners', false));
    console.log('ADMIN USER', getTagsInitial('adminUsers', false));
    setAdminUsers(getTagsInitial('adminUsers', false));
  }, [prospectOwnerSuggestions]);

  useEffect(() => {
    if (categorySuggestions?.length > 0 && filters.categories) setTagsCategories(getTagsInitial('categories', false));
  }, [
    categorySuggestions,
  ]);

  useEffect(() => {
    if (countrySuggestions?.length > 0 && filters.countryOfResidence) setTagsCountries(getTagsInitial('countryOfResidence', false));
  }, [
    countrySuggestions,
  ]);

  useEffect(() => {
    // detects if we came back from other page
    setVersion((new Date()).toString());
  }, [document.location]);

  useEffect(async () => {
    // fetchOwnersSuggestions();
    fetchProspectOwnerSuggestions();
    if (adminUsers?.length < 1) {
      dispatch(getAdminUsers(true));
    }
    mapCountriesOptions();
  }, []);

  // const handleSelectCheckbox = (selectedIds) => {
  //   setSelectedProspectIds(selectedIds);
  // };

  const fetchSuggestions = () => {
    axios({
      method: 'get',
      url: '/api/portal/prospects/get-prospects-tags?suggestionsOnly=true',
    }).then((response) => {
      if (response.data.success === true) {
        setTagsSuggestions(response.data.suggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const fetchSuggestionsDebounced = useAsyncDebounce(() => {
    fetchSuggestions();
  }, 1500);

  const loadBrandsOptions = async () => {
    const res = [
      { value: null, label: '-- Not Assigned --' },
    ];
    const resTagsBrands = [];
    const response = await axios({
      method: 'get',
      url: '/api/portal/scorecard/media-content/get-brands-data?term=',
    });
    if (response.data.success) {
      response.data.brands.forEach((brandRecord) => {
        res.push({
          value: brandRecord._id,
          label: brandRecord.companyName,
        });
        resTagsBrands.push({
          id: brandRecord._id,
          name: brandRecord.companyName,
        });
      });
    }
    setBrands(res);
    setTagsBrandsSuggestions(resTagsBrands);
  };

  useEffect(() => {
    fetchSuggestionsDebounced();
    loadBrandsOptions();
    fetchDictionaryData();
  }, []);

  useEffect(() => {
    fetchSuggestionsDebounced();
  }, [version]);

  useEffect(() => {
    if (tagsBrandsSuggestions?.length > 0) {
      const tagsBrandsNew = [];
      tagsBrands.forEach((t) => {
        const withName = tagsBrandsSuggestions.find(t1 => t1.id === t.id);
        // console.log(withName, [t, tagsBrandsSuggestions]);
        tagsBrandsNew.push({ ...withName });
      });
      setTagsBrands(tagsBrandsNew);
    }
  }, [tagsBrandsSuggestions]);

  return (
    <Container className="dashboard full-width">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'Research', path: null },
          { title: 'Internal Prospects', path: null },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>Internal Prospects</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Actions
            ilStatusFilters={ilStatusFilters}
            setILStatusFilters={setILStatusFilters}
            brandFilters={brandStatusFilters}
            setBrandFilters={setBrandStatusFilters}
            matchFilters={matchFilters}
            setMatchFilters={setMatchFilters}
            onSearchChange={onSearchChange}
            tagsBrandsSuggestions={tagsBrandsSuggestions}
            tagsSuggestions={tagsSuggestions}
            setTagsFilter={setTagsFilter}
            setTagsBrandsFilter={setTagsBrandsFilter}
            setTags={setTags}
            tags={tags}
            tagsBrands={tagsBrands}
            setTagsBrands={setTagsBrands}
            getUrlSearch={getUrlSearch}
            searchValue={searchValue}
            prospectOwnerSuggestions={prospectOwnerSuggestions}
            setTagsProspectOwnersFilter={setTagsProspectOwnersFilter}
            setProspectOwners={setProspectOwners}
            prospectOwners={prospectOwners}
            createDateFrom={createDateFrom}
            setCreateDateFrom={setCreateDateFrom}
            createDateTo={createDateTo}
            setCreateDateTo={setCreateDateTo}
            isInternalFilters={isInternalFilters}
            setIsInternalFilters={setIsInternalFilters}
            tagsAdminUserFilter={tagsAdminUserFilter}
            setTagsAdminUserFilter={setTagsAdminUserFilter}
            adminUsers={adminUsersArray}
            setAdminUsers={setAdminUsers}
            isResubmittedFilters={isResubmittedFilters}
            setIsResubmittedFilters={setIsResubmittedFilters}
            resubmitDateFrom={resubmitDateFrom}
            resubmitDateTo={resubmitDateTo}
            setResubmitDateTo={setResubmitDateTo}
            setResubmitDateFrom={setResubmitDateFrom}
            setRemoveProspectIds={setRemoveProspectIds}
            tagsCategories={tagsCategories}
            setTagsCategories={setTagsCategories}
            setTagsCategoriesFilter={setTagsCategoriesFilter}
            categoriesSuggestions={categorySuggestions}
            countrySuggestions={countrySuggestions}
            tagsCountries={tagsCountries}
            setTagsCountries={setTagsCountries}
            setTagsCountriesFilter={setTagsCountriesFilter}
          />
          <Row className="">
            {loading && (<div className="w-100 text-center"><Spinner color="primary" size="lg" /></div>)}
            {!loading && (
              <Table
                // handleSelectCheckbox={handleSelectCheckbox}
                action={action}
                resource={resource}
                dispatch={dispatch}
                addNotice={addNotice}
                brands={brands}
                goToEditProspectPage={goToEditProspectPage}
                version={version}
                setVersion={setVersion}
                setDisableNote={setDisableNote}
                setCurrentProspect={setCurrentProspect}
                setShowNotesModal={setShowNotesModal}
                setShowDetailsModal={setShowDetailsModal}
                setShowHistoryModal={setShowHistoryModal}
                setPortal={setPortal}
                countryOptions={countryTableOptions}
              />
            )}
          </Row>
        </CardBody>
      </Card>
      <NotesModal
        isOpen={showNotesModal}
        closeFunc={() => setShowNotesModal(false)}
        setVersion={setVersion}
        prospect={currentProspect}
        disabled={disableNote}
        portal={portal}
      />
      <DetailsModal record={currentProspect} setModalVisible={setShowDetailsModal} modal={showDetailsModal} />
      <StatusHistoryModal record={currentProspect} modal={showHistoryModal} setModalVisible={setShowHistoryModal} />
      <ResubmitProspectModal />
      <ShowRejectedApprovedBrands />
      <EditTagsModal />
    </Container>
  );
};
InternalProspectsListContainer.propTypes = {
  addNotice: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
  adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  currentAdminUser: PropTypes.objectOf(PropTypes.any).isRequired,
};
const mapStateToProps = state => ({
  loading: state.ResearchReducer.loading.prospectsGrid,
  prospects: state.ResearchReducer.prospectsForGrid,
  adminUsers: state.ResearchReducer.adminUsers,
  currentAdminUser: state.session,
});


export default withNotice(connect(mapStateToProps)(withRouter(InternalProspectsListContainer)));
