import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ButtonToolbar,
  ModalBody,
  Button, Input,
} from 'reactstrap';
import { axios } from 'ApiClient';
import Hook from '@/shared/hooks';

export const modalId = 'RejectWinsModal';


const RejectModal = () => {
  const [loading, setLoading] = useState(false);
  const { showSuccess, showError } = Hook.useNoticeHook();
  const [note, setNote] = useState('');
  const { data: dataModal, showModal: isShow, close } = Hook.useModalHook(modalId);
  const reload = Hook.useReloadTableHook('/api/portal/reports/get-new-winners-items-report');

  if (!dataModal) return false;

  const closeFunc = () => {
    setLoading(false);
    setNote('');
    close();
  };


  const { record } = dataModal;

  const rejectItem = async () => {
    try {
      setLoading(true);
      const res = await axios.post('/api/portal/reports/post-reject-win-report', {
        id: record._id,
        note,
        status: 'rejected',
      });
      if (res.data.success) {
        showSuccess('New Winner rejected successfully');
        reload();
        closeFunc();
      } else {
        showError('Failed to reject New Winner');
      }
    } catch (error) {
      showError('Failed to reject New Winner');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      size="md"
      isOpen={isShow}
      toggle={close}
      className='ltr-support theme-light'
    >
      <ModalHeader toggle={close} tag="h4">
        Reject Creator
      </ModalHeader>
      <ModalBody>
        <div>
          <Input
            type="textarea"
            placeholder="Rejection Reason"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            disabled={loading}
          />
        </div>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button
          color="primary"
          onClick={() => rejectItem()}
          disabled={loading}
        >
          {loading ? 'Rejecting Creator...' : 'Reject Creator'}
        </Button>&nbsp;&nbsp;
        <Button color="secondary" onClick={closeFunc}>Cancel</Button>

      </ButtonToolbar>
    </Modal>
  );
};

export default RejectModal;
