import React, { useEffect, useState } from 'react';
import { FormGroup } from 'reactstrap';
import Select from 'react-select';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
// import { getCountriesOptions } from '../../../../../shared/helpers/WVUtilities';
import AccessComponent from '../../../../../shared/components/auth/AccessComponent';
import { countriesBH } from '../../../../../shared/helpers/WVConstants';

const CountryOfResidenceSelect = (props) => {
  const { countryCode, creatorId } = props;
  const [value, setValue] = useState({ value: '', label: '' });
  const [disabled, setDisabled] = useState(false);
  // const [countryOptions] = useState(getCountriesOptions());
  const [countryOptions, setCountryOptions] = useState([]);

  const showWhenTeamIs = ['management', 'super_powers', 'finance', 'engineering', 'customer_success'];

  const mapCountriesOptions = () => {
    const countries = countriesBH();
    const co =  Object.entries(countries).map(([countryCode, countries]) => ({ value: countryCode, label: countries }));
    co.unshift({ value: null, label: '-- Not Assigned --' });
    setCountryOptions(co);
  };

  useEffect(() => {
    mapCountriesOptions();
  }, []);

  useEffect(() => {
    if (countryCode && countryOptions.length > 0) {
      const result = countryOptions.find(item => (item.value === countryCode));
      setValue(result);
    }
    if (!countryCode && countryOptions.length > 0) {
      setValue({ value: null, label: '-- Not Assigned --' });
    }
  }, [countryOptions]);

  const handleChangeCountryCode = (selectedItem) => {
    setValue(selectedItem);
    setDisabled(true);
    try {
      axios.post('/api/portal/influencer/post-update-country-of-residence', {
        id: creatorId,
        params: {
          countryOfResidence: selectedItem.value,
          countryOfResidenceProcessed: false,
        },
      }).then(() => {
        setDisabled(false);
      });
    } catch (e) {
      console.log(e.message);
      setDisabled(false);
    }
    return true;
  };

  return (
    <div className="pr-0 mb-0 w-100">
      <h4 className="mb-1">Country of Residence</h4>
      <AccessComponent showWhenTeam={showWhenTeamIs}>
        <FormGroup className="w-100" style={{ marginBottom: 0 }}>
          <Select
            value={value}
            onChange={handleChangeCountryCode}
            options={countryOptions}
            isDisabled={!!disabled}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            placeholder="Select Country..."
          />
        </FormGroup>
      </AccessComponent>
      <AccessComponent hideWhenTeam={showWhenTeamIs}>
        {value.label}
      </AccessComponent>
    </div>
  );
};

CountryOfResidenceSelect.propTypes = {
  creatorId: PropTypes.string.isRequired,
  countryCode: PropTypes.string,
};

CountryOfResidenceSelect.defaultProps = {
  countryCode: '',
};

export default CountryOfResidenceSelect;
