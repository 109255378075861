import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

function StatusCircle({ color, status }) {
  const outerStyle = {
    marginRight: '8px',
    marginLeft: '8px',
    width: '24px',
    height: '24px',
    backgroundColor: color,
    borderRadius: '50%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const innerStyle = {
    width: '19px',
    height: '19px',
    backgroundColor: color,
    borderRadius: '50%',
    border: '3px solid white',
  };

  return (
    <Tooltip title={status}>
      <div style={outerStyle}>
        <div style={innerStyle}></div>
      </div>
    </Tooltip>
  );
}

export default StatusCircle;
