import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Input,
} from 'reactstrap';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uid } from 'react-uid';
import Modal from '../../modals/Action';
import { updateResourceData } from '../../../helpers/WVRequest';
import { s } from '../../../helpers/WVFormatter';

const Score = ({
  resource,
  record,
  property,
  options,
  label,
  primaryKey,
  fill,
  addUrl,
}) => {
  const [value, setValue] = useState(record.params[property.name]);
  const [total, setTotal] = useState(null);
  const [score, setScore] = useState([]);
  const [isOpen, toggle] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const changeScore = (e) => {
    const {
      id,
      value,
      min,
      max,
    } = e.target;
    const d = id.split('-');
    const val = [...score];
    val[d[1]] = Math.max(Number(min), Math.min(Number(value), Number(max)));
    setScore(val);
    setTotal(val.reduce((a, v) => a += Number(v || 0), 0));
    const err = [...errors];
    err[d[1]] = null;
    setErrors(err);
    if (val.filter(v => typeof v !== 'undefined').length === options.length) {
      setDisableSave(false);
    }
  };

  const validate = () => {
    const len = options.length;
    const e = [];
    for (let i = 0; i < len; i += 1) {
      if (typeof score[i] === 'undefined') {
        e[i] = 'Please fill';
      }
    }
    const res = e.filter(v => v).length;
    if (res > 0) setErrors(e);
    return !res;
  };

  const save = async () => {
    setValue(total);
    setLoading(true);
    setDisableSave(true);
    const data = fill(property.name, total, record);
    const afterSave = (res) => {
      setLoading(false);
    }
    if (record.params[primaryKey]) {
      afterSave(await updateResourceData(resource.id, record.params[primaryKey], data));
    } else if (addUrl) {
      afterSave(await axios.post(addUrl, { data }));
    }
  };

  const process = async () => {
    if (!validate()) {
      setDisableSave(false);
      return { notClose: true };
    }
    await save();
    return { notClose: false };
  };

  return (
    <>
      <div className="float-left" style={{ width: '12px' }}>
        {typeof value !== 'undefined' ? value : '-'}
      </div>
      <div className="float-left pl-2">
        <IconButton
          size="small"
          onClick={() => toggle(true)}
        >
          <FontAwesomeIcon
            icon={['fas', 'fa-ruler-combined']}
            color={typeof value !== 'undefined' ? '#70bbfd' : ''}
          />
        </IconButton>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          label={label}
          confirmButtonCaption="Save"
          process={process}
          disableButton={disableSave}
        >
          {options.map((row, i) => (
            <Row key={uid(i)}>
              <Col xs={10}>
                <div className="pb-1 font-weight-bold">{row.label} ({row.score} pt{s(row.score)})</div>
                <div className="pb-3">{row.description}</div>
              </Col>
              <Col xs={2} className="float-right pt-3">
                <Input
                  type="number"
                  id={`score-${i}`}
                  min={0}
                  max={row.score}
                  value={typeof score[i] === 'undefined' ? '' : score[i]}
                  onChange={changeScore}
                  disabled={loading}
                />
                {errors[i] && (
                  <small className="text-danger">{errors[i]}</small>
                )}
              </Col>
            </Row>
          ))}
          <hr />
          <Row>
            <Col xs={10} className="font-weight-bold">
              Total
            </Col>
            <Col xs={2} className="font-weight-bold text-center">
              {total || ''}
            </Col>
          </Row>
        </Modal>
      </div>
      <div className="clearfix" />
    </>
  );
};

Score.propTypes = {
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  property: PropTypes.objectOf(PropTypes.any).isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  label: PropTypes.string.isRequired,
  primaryKey: PropTypes.string,
  fill: PropTypes.func,
  addUrl: PropTypes.string,
};

Score.defaultProps = {
  primaryKey: '_id',
  fill: (property, value) => ({ [property]: value }),
  addUrl: null,
};

export default Score;
