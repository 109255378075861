// import React from 'react';
// import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
import hooks from '../../hooks';

const AccessComponent = (params) => {
  const [showElem] = hooks.useAccessHook(params);

  /* const session = useSelector(state => state.session);
  // const session = false;
  if (!session) return null;
  const { teams } = session;
  const { role } = session;

  const checkTeam = () => (!(showWhenTeam?.length > 0) || (showWhenTeam?.length > 0 && showWhenTeam.some(r => teams.includes(r))))
      && ((hideWhenTeam?.length > 0 && !hideWhenTeam.some(r => teams.includes(r))))
      && (!(hideWhenRole?.length > 0) || !(hideWhenRole?.length > 0 && hideWhenRole.some(r => teams.includes(r))));
  const checkRole = () => !(showWhenRole?.length > 0) || (showWhenRole?.length > 0 && showWhenRole.includes(role));
  if (and || (!and && !or)) {
    if (checkTeam() && checkRole()) {
      return (children);
    }
    return null;
  }
  if (or) {
    if (checkTeam() || checkRole()) {
      return (children);
    }
    return null;
  } */
  // console.log(hooks.useAccessHook(params), params, showElem);
  return showElem ? (params.children) : null;
};

export default AccessComponent;
