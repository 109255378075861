import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { renderChildren } from '../../helpers/React';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ButtonToolbar,
  Button,
  Spinner,
} from 'reactstrap';

const Action = ({
  isOpen,
  toggle,
  label,
  customStyle,
  children,
  process,
  confirmButtonCaption,
  disableButton,
  loading,
}) => {
  const [title, settitle] = useState(label);
  const close = (data) => {
    if (!data?.notClose) {
      toggle(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={close}
      style={customStyle}
    >
      <ModalHeader
        toggle={close}
        tag="h4"
      >
        {title}
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <div className="text-center"><Spinner color="primary" size="lg" /></div>
        ) : renderChildren(children, { settitle, close })}
      </ModalBody>
      {process && confirmButtonCaption && (
        <ButtonToolbar className="modal__footer">
          <Button
            color="primary"
            onClick={() => { process().then(close) }}
            disabled={loading || disableButton}
          >
            {confirmButtonCaption}
          </Button>
          &nbsp;&nbsp;
          <Button color="secondary" onClick={close}>Cancel</Button>
        </ButtonToolbar>
      )}
    </Modal>
  );
};

Action.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  label: PropTypes.string,
  customStyle: PropTypes.any,
  children: PropTypes.node.isRequired,
  process: PropTypes.func,
  confirmButtonCaption: PropTypes.string,
  disableButton: PropTypes.bool,
  loading: PropTypes.bool,
};

Action.defaultProps = {
  label: '',
  customStyle: {},
  process: null,
  confirmButtonCaption: null,
  disableButton: false,
  loading: false,
};

export default Action;
