import React, { useState, useEffect, forwardRef } from 'react';
import moment from 'moment';
// import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import { Row, Col, Button, InputGroup, InputGroupAddon } from 'reactstrap';
import CalendarIcon from 'mdi-react/CalendarIcon';


// eslint-disable-next-line react/prop-types
const DatePickerCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
  <div className="form-group mb-2">
    <InputGroup size="md" >
      <input
        type="text"
        className="form-control form-control-sm"
        value={value}
        onClick={onClick}
        onChange={onChange}
        style={{
          border: '1px solid #ced4da',
          borderRadius: '5px',
        }}
        ref={ref}
      />
      <InputGroupAddon
        addonType="append"
        style={{
          padding: '3px 0 7px 7px',
        }}
      >
        <CalendarIcon
          className="date-picker-icon"
          onClick={onClick}
        />
      </InputGroupAddon>
    </InputGroup>
  </div>
));

const currentDay = (moment().subtract(1, 'day').endOf('day') < moment().startOf('month') ? moment().startOf('month') : moment().subtract(1, 'day').endOf('day')).toDate();

export const getPeriods = (params = {}) => {
  const { showByDate, lastMonths } = params;
  const periods = [
    { range: 'current_month', label: 'Current Month' },
    { range: 'last_month', label: 'Last Month' },
    { range: 'year_to_date', label: 'Year to Date' },
    { range: showByDate ? 'custom_dates' : 'custom', label: 'Custom' },
  ];
  if (lastMonths && lastMonths.length > 0) {
    lastMonths.forEach((v, i) => {
      periods.splice(2 + i, 0, {
        range: `${v}_month`,
        label: `Last ${v} Months`,
        setDateFrom: () => moment().subtract(v, 'month').startOf('day').toDate(),
        setDateTo: () => currentDay,
      });
    });
  }
  return periods;
};

const PeriodTabs = ({
  from,
  setFrom,
  to,
  setTo,
  periods,
  startDateLimit,
  selectButton,
  selected,
  disabled,
}) => {
  const startDate = from ? moment(from).toDate() : null;
  const setStartDate = (value) => { setFrom(value.toISOString()); };
  const endDate = to ? moment(to).toDate() : null;
  const setEndDate = (value) => { setTo(value.toISOString()); };
  const [selectedButton, setSelectedButton] = useState(selected || selectButton);
  const [showCustom, setShowCustom] = useState(selectedButton === 'custom');
  const [showCustomDay, setShowCustomDay] = useState(false);
  const [showCustomDates, setShowCustomDates] = useState(selectedButton === 'custom_dates');

  const getMaxDate = val => (moment(val) > moment() ? moment().format('MMM D, YYYY') : moment(val).format('MMM D, YYYY'));

  useEffect(() => {
    if (!selectedButton) {
      if (startDate && endDate) {
        const format = 'YYYY-MM-DD';

        const _startDate = moment(startDate).format(format);
        const _endDate = moment(endDate).format(format);

        const now = moment().format(format);
        const startOfMonth = moment().startOf('month').format(format);
        const startOfLastMonth = moment().subtract(1, 'month').startOf('month').format(format);
        const endOfLastMonth = moment().subtract(1, 'month').endOf('month').format(format);
        const startOfYear = moment().startOf('year').format(format);
        if (_startDate === startOfMonth && _endDate >= now) {
          setSelectedButton('current_month');
        } else if (_startDate === startOfLastMonth && _endDate === endOfLastMonth) {
          setSelectedButton('last_month');
        } else if (_startDate === startOfYear && _endDate >= now) {
          setSelectedButton('year_to_date');
        } else {
          setSelectedButton('custom');
        }
      } else {
        setSelectedButton('custom');
      }
    }
  }, []);

  const selectDateRange = ({ range, setDateFrom, setDateTo }) => {
    let dateFrom;
    let dateTo;
    switch (range) {
      case 'day':
        setShowCustomDay(true);
        setShowCustom(false);
        setShowCustomDates(false);
        dateFrom = moment().startOf('day').toDate();
        dateTo = moment().endOf('day').toDate();
        break;
      case 'current_month':
        setShowCustom(false);
        setShowCustomDates(false);
        setShowCustomDay(false);
        dateFrom = moment().startOf('month').toDate();
        dateTo = currentDay;
        break;
      case 'last_month':
        setShowCustom(false);
        setShowCustomDates(false);
        setShowCustomDay(false);
        dateFrom = moment().subtract(1, 'month').startOf('month').toDate();
        dateTo = moment().subtract(1, 'month').endOf('month').toDate();
        break;
      case 'year_to_date':
        setShowCustom(false);
        setShowCustomDates(false);
        setShowCustomDay(false);
        dateFrom = moment().startOf('Year').toDate();
        dateTo = currentDay;
        break;
      case 'all_time':
        setShowCustom(false);
        setShowCustomDates(false);
        setShowCustomDay(false);
        dateFrom = moment('2019-01-01').startOf('day').toDate();
        dateTo = moment().endOf('day').toDate();
        break;
      case 'custom':
        setShowCustomDates(false);
        setShowCustom(true);
        setShowCustomDay(false);
        dateFrom = moment().startOf('Year').toDate();
        dateTo = moment().toDate();
        break;
      case 'custom_dates':
        setShowCustomDates(true);
        setShowCustom(false);
        setShowCustomDay(false);
        dateFrom = moment().startOf('Year').toDate();
        dateTo = moment().subtract(1, 'day').endOf('day').toDate();
        break;
      default:
        if (setDateFrom && setDateTo) {
          setShowCustom(false);
          setShowCustomDates(false);
          setShowCustomDay(false);
          dateFrom = setDateFrom();
          dateTo = setDateTo();
        } else {
          dateFrom = moment().startOf('Year').toDate();
          dateTo = moment().toDate();
        }
        break;
    }
    setEndDate(dateTo);
    setStartDate(dateFrom);
    setSelectedButton(range);
  };
  const getText = () => {
    if (!(startDate && endDate)) return '';
    let dateText = '';
    if (
      moment(startDate).format('M') === moment(endDate).format('M') &&
      moment(startDate).format('D') === moment(endDate).format('D') &&
      moment(startDate).format('YYYY') === moment(endDate).format('YYYY')) {
      dateText = moment(startDate).format('MMM D, YYYY');
    } else {
      dateText = `${moment(startDate).format('MMM D, YYYY')} - ${getMaxDate(endDate)}`;
    }
    return `${dateText} (ET)`;
  };
  const minDate = startDateLimit || moment().subtract(25, 'months');
  // console.log(minDate);
  const buttonClass = 'btn-sm mr-0 time-period-filter-buttons-extended';
  return (
    <div>
      <Row style={{ minWidth: '300px' }}>
        <Col lg="auto">
          {periods.map((p, i) => {
            let style = { borderRadius: '0', borderRight: '0' };
            if (i === 0) {
              style = { borderRadius: '5px 0 0 5px', borderRight: '0', borderColor: '#4A69F2' };
            } else if (i === periods.length - 1) {
              style = { borderRadius: '0 5px 5px 0', borderColor: '#4A69F2' };
            }
            return (
              <Button
                key={uid(i)}
                onClick={() => selectDateRange(p)}
                className={selectedButton === p.range ? `active ${buttonClass}` : buttonClass}
                style={style}
                disabled={disabled}
              >
                {p.label}
              </Button>
            );
          })}
        </Col>
        <Col lg="auto">
          {showCustom ? (
            <Row>
              <Col xs="auto">
                <div style={{ maxWidth: '120px' }}>
                  <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    dateFormat="MM/yyyy"
                    maxDate={new Date()}
                    minDate={minDate.toDate()}
                    placeholderText="Start Date"
                    dropDownMode="select"
                    className="form-control"
                    showMonthYearPicker
                    customInput={<DatePickerCustomInput />}
                    disabled={disabled}
                  />
                </div>
              </Col>
              <Col xs="auto">
                <div style={{ maxWidth: '120px' }}>
                  <DatePicker
                    selected={endDate}
                    onChange={date => setEndDate(moment(date).endOf('month').toDate())}
                    dateFormat="MM/yyyy"
                    maxDate={new Date()}
                    minDate={minDate.toDate()}
                    placeholderText="End Date"
                    dropDownMode="select"
                    className="form-control"
                    showMonthYearPicker
                    customInput={<DatePickerCustomInput />}
                    disabled={disabled}
                  />
                </div>
              </Col>
            </Row>) : null
          }
          {showCustomDates ? (
            <Row>
              <Col xs="auto">
                <div style={{ maxWidth: '120px' }}>
                  <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    minDate={minDate.toDate()}
                    placeholderText="Start Date"
                    dropDownMode="select"
                    className="form-control"
                    customInput={<DatePickerCustomInput />}
                    disabled={disabled}
                  />
                </div>
              </Col>
              <Col xs="auto">
                <div style={{ maxWidth: '120px' }}>
                  <DatePicker
                    selected={endDate}
                    onChange={date => setEndDate(moment(date).toDate())}
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    minDate={minDate.toDate()}
                    placeholderText="End Date"
                    dropDownMode="select"
                    className="form-control"
                    customInput={<DatePickerCustomInput />}
                    disabled={disabled}
                  />
                </div>
              </Col>
            </Row>) : null
          }
          {showCustomDay ? (
            <Row>
              <Col xs="auto">
                <div style={{ maxWidth: '120px' }}>
                  <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date) || setEndDate(date)}
                    dateFormat="MM/dd/yyyy"
                    maxDate={new Date()}
                    minDate={minDate.toDate()}
                    placeholderText="Start Date"
                    dropDownMode="select"
                    className="form-control"
                    customInput={<DatePickerCustomInput />}
                    disabled={disabled}
                  />
                </div>
              </Col>
            </Row>) : null
          }
        </Col>
      </Row>
      <Row style={{ minWidth: '300px' }}>
        <Col>
          <h5 style={{ color: 'black', marginBottom: '8px' }} className="bold-text">{getText()}</h5>
        </Col>
      </Row>
    </div>
  );
};
PeriodTabs.propTypes = {
  from: PropTypes.string,
  setFrom: PropTypes.func.isRequired,
  to: PropTypes.string,
  setTo: PropTypes.func.isRequired,
  periods: PropTypes.arrayOf(PropTypes.any),
  startDateLimit: PropTypes.objectOf(PropTypes.any),
  selectButton: PropTypes.arrayOf(PropTypes.any),
  selected: PropTypes.string,
  disabled: PropTypes.bool,
};
PeriodTabs.defaultProps = {
  from: null,
  to: null,
  periods: getPeriods(),
  startDateLimit: null,
  selectButton: null,
  selected: 'current_month',
  disabled: false,
};
export default PeriodTabs;
