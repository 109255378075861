/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { axios } from 'ApiClient';
import _ from 'lodash';
import MoneyIcon from 'mdi-react/MoneyIcon';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import {
  changeCurrentLeadId,
  changeCurrentDealOwnerId,
} from '../../../../../../../redux/reducers/admin/ResearchReducer';

const DealOwner = React.memo(({
  record,
  afterSaveFunc,
  adminUsers,
  updateRecordFunction,
  openDealsModal,
  loadingAdminUsers,
  currentLeadId,
  currentDealOwnerId,
  dispatch,
}) => {
  const val = (typeof record.populated.dealOwnerUser !== 'undefined' && record.populated.dealOwnerUser) ?
    { value: record.populated.dealOwnerUser.id, label: `${record.populated.dealOwnerUser.params.firstName} ${record.populated.dealOwnerUser.params.lastName}` } :
    { value: null, label: '-- Not Assigned --' };
  const [value, setValue] = useState(val);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);
  const [deals, setDeals] = useState([]);

  useEffect(() => {
    setError(null);
    setValue(val);
    setDisabled(false);
  }, [record.id, record.populated?.dealOwnerUser?.id]);

  const handleChange = async (option) => {
    setDisabled(true);
    setError(null);
    setValue(option);
    try {
      const data = await axios.post('/api/portal/prospects/post-update-deal-owner', {
        prospectIds: [record.id],
        adminUserId: option.value,
      });
      if (data?.data?.prospectPopulated && updateRecordFunction) {
        updateRecordFunction(data?.data?.prospectPopulated);
      } else {
        afterSaveFunc();
      }
    } catch (e) {
      setError(e.message);
      setDisabled(false);
    }
    setDisabled(false);
    return true;
  };

  const getDeals = async () => {
    if (record.params.hsUserId) {
      try {
        const response = await axios.get(`/api/portal/prospects/get-deals-for-prospect?hsUserId=${record.params.hsUserId}`);
        if (response?.data?.deals && response.data.deals.length > 0) {
          setDeals(response.data.deals);
        }
      } catch (e) {
        setDeals([]);
        console.log(e.message);
      }
    }
  };

  useEffect(async () => {
    if (currentLeadId === record.id && currentDealOwnerId !== '') {
      dispatch(changeCurrentLeadId(''));
      dispatch(changeCurrentDealOwnerId(''));
      const selectedValue = adminUsers.find(option => option.value === currentDealOwnerId);
      if (selectedValue) await handleChange(selectedValue);
    }
    if (currentLeadId === record.id && currentDealOwnerId === '') {
      dispatch(changeCurrentLeadId(''));
    }
  }, [currentDealOwnerId]);

  useEffect(() => {
    if (record.params?.hsUserId) {
      getDeals();
    }
  }, [record.params?.hsUserId]);

  return record.params?.hsUserId && deals.length > 0 ? (
    <div
      className="d-flex mb-0"
      style={{ maxWidth: '180px' }}
    >
      <div
        className="pr-0 mb-0 mr-0"
        style={{ minWidth: '150px', maxWidth: '150px' }}
      >
        <Select
          loading={loadingAdminUsers}
          disabled={loadingAdminUsers}
          value={value}
          options={adminUsers}
          className="dealOwnerSelector"
          onChange={handleChange}
          placeholder=""
          isDisabled={disabled}
          menuPlacement="auto"
          menuPortalTarget={document.body}
        />
        {error ? (<span className="text-danger">{error}</span>) : null}
      </div>
      <div className="pl-1" style={{ paddingTop: '4px' }}>
        <Tooltip
          title="View Contact's Deals"
        >
          <IconButton
            size="small"
            onClick={() => {
              dispatch(changeCurrentLeadId(record.id));
              openDealsModal(deals);
            }}
            className="material-table__toolbar-button"
            style={{ color: '#70bbfd' }}
          >
            <MoneyIcon size="24" />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  ) : (
    <div style={{ minWidth: '180px', maxWidth: '180px' }} >
      <Select
        isLoading={loadingAdminUsers}
        value={value}
        options={adminUsers}
        className="dealOwnerSelector"
        onChange={handleChange}
        placeholder=""
        isDisabled={disabled}
        menuPlacement="auto"
        menuPortalTarget={document.body}
      />
      {error ? (<span className="text-danger">{error}</span>) : null}
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record)
  && _.isEqual(a.adminUsers, a1.adminUsers)
  && a.loadingAdminUsers === a1.loadingAdminUsers
  && a.currentDealOwnerId === a1.currentDealOwnerId);

DealOwner.propTypes = {
  dispatch: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  afterSaveFunc: PropTypes.func.isRequired,
  updateRecordFunction: PropTypes.func.isRequired,
  openDealsModal: PropTypes.func.isRequired,
  loadingAdminUsers: PropTypes.bool.isRequired,
  currentLeadId: PropTypes.string.isRequired,
  currentDealOwnerId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  loadingAdminUsers: state.ResearchReducer.loading.prospectsGrid,
  adminUsers: state.ResearchReducer.adminUsers,
  currentLeadId: state.ResearchReducer.currentLeadId,
  currentDealOwnerId: state.ResearchReducer.currentDealOwnerId,
});

export default connect(mapStateToProps)(DealOwner);
