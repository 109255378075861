import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTags from 'react-tag-autocomplete';
import Select from 'react-select';
import { axios } from 'ApiClient';
import {
  FormGroup,
  Input,
  Container,
  Card,
  CardBody,
  CardTitle, Label, Button,
} from 'reactstrap';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import SelectAsync from 'react-select/async';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ApiClient from 'ApiClient';
import withNotice from '../../../../App/store/with-notice';
import Breadcrumbs from '../../../../../shared/components/BreadCrumbs';
import { ProgramOptions } from '../../../../../shared/helpers/models/Prospect';
import { getSelectedOption } from '../../../../../shared/helpers/Form';

const channelsArray = {
  YouTube: 'YouTube',
  Instagram: 'Instagram',
  Facebook: 'Facebook',
  Twitter: 'X',
  TikTok: 'TikTok',
  Website: 'Website',
};

const EditProspect = ({
  match,
  addNotice,
}) => {
  const [loading, setLoading] = useState(false);
  const [prospectData, setProspectData] = useState({});
  const [mediaChannelsData, setMediaChannelsData] = useState({});
  const [tags, setTags] = useState([]);
  const [tagsSuggestions, setTagsSuggestions] = useState([]);
  const [podcastGenresOptionsLoading, setPodcastGenresOptionsLoading] = useState(false);
  const [podcastGenresOptions, setPodcastGenresOptions] = useState([]);
  const [prospectOwners, setProspectOwners] = useState([]);
  const [podcastGenres, setPodcastGenres] = useState([]);
  const [errors, setErrors] = useState({});
  const reactTags = React.createRef();

  const onDeleteTag = (i) => {
    const tagsNew = tags.slice(0);
    tagsNew.splice(i, 1);
    setTags(tagsNew);
  };

  const onAdditionTag = (tag) => {
    const tagsNew = [].concat(tags, tag);
    setTags(tagsNew);
  };

  const fetchTags = () => {
    axios({
      method: 'get',
      url: `/api/portal/research/get-prospects-tags?id=${match.params.prospectId}`,
    }).then((response) => {
      if (response.data.success === true) {
        setTagsSuggestions(response.data.suggestions);
        setTags(response.data.tags);
      }
    }).catch((err) => {
      console.log(err);
      addNotice({
        message: 'An error occurred while loading the tags. Please try again.',
        type: 'error',
      });
    });
  };


  const fetchProspect = async () => {
    axios({
      method: 'get',
      url: `/api/portal/research/get-prospect?id=${match.params.prospectId}`,
    }).then((response) => {
      if (response.data.success === true) {
        const anProspect = response.data.prospect;
        const mediaChannels = {};
        anProspect.tubularDetails.accounts.map((account) => {
          mediaChannels[account.platform] = {
            url: account.url,
            channel: account.title,
          };
          return true;
        });
        if (anProspect.listenNotesDetails) {
          mediaChannels.podcast = {
            url: anProspect.listenNotesDetails.url,
            channel: anProspect.listenNotesDetails.title,
            genre_ids: anProspect.listenNotesDetails.genre_ids,
          };
        }

        if (anProspect.agent) {
          anProspect.selectedAgentOption = {
            label: `${response.data.prospect?.agent?.first_name} ${response.data.prospect?.agent?.last_name} (${response.data.prospect?.agent?.email})`,
            value: anProspect.agent,
            record: response.data.prospect?.agent,
          };
        }
        anProspect.selectedProgramOption = getSelectedOption(ProgramOptions, anProspect.program);
        setProspectData(anProspect);
        setMediaChannelsData(mediaChannels);

        setPodcastGenresOptionsLoading(true);
        axios.get('/api/portal/research/get-podcast-genres').then((res) => {
          if (res.data && res.data.success) {
            if (res.data.podcastGenresOptions) {
              setPodcastGenresOptions(res.data.podcastGenresOptions);
              setPodcastGenres(() => {
                try {
                  if (
                    typeof mediaChannels.podcast?.genre_ids !== 'undefined'
                    && mediaChannels.podcast?.genre_ids !== null
                    && Array.isArray(mediaChannels.podcast.genre_ids)
                    && res.data.podcastGenresOptions
                  ) {
                    const genres = [];
                    mediaChannels.podcast.genre_ids.forEach((genre) => {
                      const foundOption = res.data.podcastGenresOptions.find(option => option.value === genre);
                      if (foundOption) genres.push(foundOption);
                    });
                    return genres;
                  }
                  return [];
                } catch (error) {
                  console.log('Error with setPodcastGenres(): ', error);
                  return [];
                }
              });
            }
          }
          setPodcastGenresOptionsLoading(false);
        }).catch((error) => {
          console.error(error);
          addNotice({
            message: 'An error occurred while loading podcast genres. Please try again.',
            type: 'error',
          });
          setPodcastGenresOptionsLoading(false);
        });
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const fetchProspectOwnerSuggestions = async () => {
    axios({
      method: 'get',
      url: '/api/portal/prospects/get-all-admin-users',
    }).then((response) => {
      if (response.data.success === true) {
        setProspectOwners(response.data.data);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  useEffect(async () => {
    setLoading(true);
    await fetchProspectOwnerSuggestions();
    await fetchProspect();
    await fetchTags();

    setLoading(false);
  }, [match.params.prospectId]);

  const api = new ApiClient();

  const loadAgentOptions = async (inputValue) => {
    const records = await api.searchRecords({
      resourceId: 'AgentUser',
      query: inputValue,
    });
    const contacts = records.map(recordItem => ({
      value: recordItem.id,
      label: `${recordItem.params.first_name} ${recordItem.params.last_name} ${recordItem.params.email !== null ? ` (${recordItem.params.email})` : ''}`,
      record: recordItem,
    }));
    return contacts;
  };

  const setProspectProperty = (field, value) => {
    setProspectData({
      ...prospectData,
      [field]: value,
    });
    setErrors({
      ...errors,
      [field]: false,
    });
  };
  const setMediaChannelsProperty = (platform, field, value) => {
    setMediaChannelsData({
      ...mediaChannelsData,
      [platform]: {
        ...mediaChannelsData[platform],
        [field]: value,
      },
    });
  };
  const submit = async () => {
    setLoading(true);

    axios({
      method: 'post',
      url: '/api/portal/prospects/post-save-edit-prospect',
      data: {
        prospectData,
        mediaChannelsData,
        tags,
      },
    }).then((response) => {
      if (response.data.success) {
        addNotice({
          message: 'The prospect was updated.',
          type: 'success',
        });
        setLoading(false);
      }
    }).catch((error) => {
      console.log(error);
      addNotice({
        message: 'An error occurred while saving the prospect. Please try again.',
        type: 'error',
      });
      setLoading(false);
    });
    return true;
  };

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            links={[
              { title: 'Home', path: '/' },
              { title: 'Prospects', path: '/research/prospects' },
              { title: 'Edit Prospect', path: false },
            ]}
            isBackButton
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardTitle className="row justify-content-center">
              <Col xs="12">
                <h3>Edit Prospect</h3>
              </Col>
            </CardTitle>
            <CardBody style={{ padding: '2rem 2rem 0 2rem' }}>
              <CardTitle>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                      <label htmlFor="firstname">Prospect Type</label>
                      <Row>
                        <Col sm={3} style={{ paddingLeft: '50px' }}>
                          <FormControlLabel
                            control={
                              <Input
                                type="radio"
                                name="isInternal"
                                value={prospectData.isInternal}
                                className="mt-0"
                                id="isInternalTrue"
                                checked={Boolean(prospectData.isInternal)}
                                onChange={() => { setProspectProperty('isInternal', true); }}
                              />
                              }
                            label="Internal"
                          />
                        </Col>
                        <Col sm={3} style={{ paddingLeft: '50px' }}>
                          <FormControlLabel
                            control={
                              <Input
                                type="radio"
                                name="isInternal"
                                className="mt-0"
                                id="isInternalFalse"
                                value={prospectData.isInternal}
                                checked={Boolean(prospectData.isInternal) === false}
                                onChange={() => { setProspectProperty('isInternal', false); }}
                              />
                              }
                            label="Standard"
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                      <label htmlFor="prospectOwnerNew">Prospect Owner</label>
                      <Select
                        name="prospectOwnerNew"
                        value={prospectOwners.filter(owner => owner.value === prospectData.prospectOwner)}
                        options={prospectOwners}
                        onChange={(inputValue) => {
                          setProspectProperty('prospectOwner', inputValue.value);
                        }}
                        placeholder="Please Select Prospect Owner"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                      <label htmlFor="firstname">First Name</label>
                      <Input
                        type="text"
                        id="firstname"
                        name="firstname"
                        placeholder=""
                        autoComplete="off"
                        onChange={e => setProspectProperty('firstname', e.target.value)}
                        value={prospectData.firstname || ''}
                        // disabled={loading}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                      <label htmlFor="lastname">Last Name</label>
                      <Input
                        type="text"
                        id="lastname"
                        name="lastname"
                        placeholder=""
                        autoComplete="off"
                        onChange={e => setProspectProperty('lastname', e.target.value)}
                        value={prospectData.lastname || ''}
                        // disabled={loading}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                      <label htmlFor="email">Email</label>
                      <Input
                        type="text"
                        id="email"
                        name="email"
                        placeholder=""
                        autoComplete="off"
                        onChange={e => setProspectProperty('email', e.target.value)}
                        value={prospectData.email || ''}
                        // disabled={loading}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                      <label htmlFor="agent">Agent Contact</label>
                      <SelectAsync
                        id="agent"
                        cacheOptions
                        value={prospectData.selectedAgentOption}
                        defaultOptions
                        loadOptions={loadAgentOptions}
                        onChange={e => setProspectProperty('selectedAgentOption', e)}
                        placeholder="Please Select Agent Contact"
                        isClearable
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="edit-prospect-tags">
                    <FormGroup>
                      <Label>Tags</Label>
                      <ReactTags
                        ref={reactTags}
                        tags={tags}
                        suggestions={tagsSuggestions}
                        onDelete={onDeleteTag}
                        onAddition={onAdditionTag}
                        delimiters={['Enter', ',']}
                        allowNew
                        placeholderText="Tags"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                      <label htmlFor="program">Program</label>
                      <Select
                        name="program"
                        value={prospectData.selectedProgramOption}
                        options={ProgramOptions}
                        onChange={(e) => {
                          setProspectProperty('selectedProgramOption', e);
                        }}
                        placeholder="Select Program"
                        isDisabled={prospectData.inBlackList}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <h4>Media Channel Property</h4>
                <hr />
                <div className="grey-small-text grey-minus-margin">Specify the Creator&apos;s HubSpot Media Channel details</div>
                <br />
                {Object.keys(channelsArray).map(channel => (
                  <div key={`channel-${channel}`}>
                    <h5 style={{ fontSize: '17px', marginBottom: '5px' }}><b>{channelsArray[channel]}</b></h5>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                          <label htmlFor={`${channel.toLowerCase()}_channel_name`}>{`${channelsArray[channel]} ${channel === 'Website' ? 'Title' : 'Channel Name'}`}</label>
                          <Input
                            type="text"
                            id={`${channel.toLowerCase()}_channel_name`}
                            name={`${channel.toLowerCase()}_channel_name`}
                            placeholder=""
                            autoComplete="off"
                            onChange={e => setMediaChannelsProperty(channel.toLowerCase(), 'channel', e.target.value)}
                            value={mediaChannelsData[channel.toLowerCase()]?.channel || ''}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                          <label htmlFor={`${channel.toLowerCase()}_channel_url`}>{`${channelsArray[channel]} ${channel === 'Website' ? 'URL' : 'Channel URL'}`}</label>
                          <Input
                            type="text"
                            id={`${channel.toLowerCase()}_channel_url`}
                            name={`${channel.toLowerCase()}_channel_url`}
                            placeholder=""
                            autoComplete="off"
                            onChange={e => setMediaChannelsProperty(channel.toLowerCase(), 'url', e.target.value)}
                            value={mediaChannelsData[channel.toLowerCase()]?.url || ''}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                ))}
                <h5 style={{ fontSize: '17px', marginBottom: '5px' }}><b>Podcast</b></h5>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                      <label htmlFor="podcast_title">Podcast Title</label>
                      <Input
                        type="text"
                        id="podcast_title"
                        name="podcast_title"
                        placeholder=""
                        autoComplete="off"
                        onChange={e => setMediaChannelsProperty('podcast', 'channel', e.target.value)}
                        value={mediaChannelsData.podcast?.channel || ''}
                      />
                      {errors.podcast_title && (<span className="text-danger">{errors.podcast_title}</span>)}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                      <label htmlFor="podcast_url">Podcast URL</label>
                      <Input
                        type="text"
                        id="podcast_url"
                        name="podcast_url"
                        placeholder=""
                        autoComplete="off"
                        onChange={e => setMediaChannelsProperty('podcast', 'url', e.target.value)}
                        value={mediaChannelsData.podcast?.url || ''}
                      />
                      {errors.podcast_url && (<span className="text-danger">{errors.podcast_url}</span>)}
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <div className="w-100 research-watchlists">
                        <Label className="bold-text">
                          Genre(s)
                        </Label>
                        <Select
                          isMulti
                          name="podcastGenresSelect"
                          isDisabled={podcastGenresOptionsLoading}
                          isLoading={podcastGenresOptionsLoading}
                          value={podcastGenres}
                          options={podcastGenresOptions}
                          onChange={(value) => {
                            setPodcastGenres(value);
                            setMediaChannelsProperty('podcast', 'genre_ids', value.map(genre => genre.value));
                          }}
                          placeholder="Select Genres..."
                          style={{ maxHeight: '120px' }}
                          styles={colourStyles}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col className="mt-2" md={12}>
                    <Button className="modal_ok btn-sm" color="primary" onClick={submit} disabled={loading}>
                      {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
                      <span>Save</span>
                    </Button>{' '}
                  </Col>
                </Row>
              </CardTitle>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
EditProspect.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  addNotice: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  adminUser: state.session,
});

export default connect(mapStateToProps)(withNotice(EditProspect));
