import axios from 'axios';

export const loadBrandsOptions = async (inputValue, displayInUiOnly = false) => {
  let response = {};
  if (inputValue === 'getByIds') {
    const initialSearch = new URLSearchParams(window.location.search);
    if (initialSearch.has('filters.brand') && initialSearch.getAll('filters.brand').length > 0) {
      response = await axios.get('/api/portal/influencer/get-brands-by-ids', {
        params: { ids: initialSearch.getAll('filters.brand') },
      });
      if (!response.data.success) {
        return {};
      }
    }
  } else if (inputValue === 'getByNames') {
    const initialSearch = new URLSearchParams(window.location.search);
    if (initialSearch.has('filters.brand') && initialSearch.getAll('filters.brand').length > 0) {
      response = await axios.get('/api/portal/influencer/get-brands-by-names', {
        params: { names: initialSearch.getAll('filters.brand') },
      });
      if (!response.data.success) {
        return {};
      }
    }
  } else {
    response = await axios({
      method: 'get',
      url: `/api/portal/scorecard/media-content/get-brands-data?term=${inputValue}`,
    });
  }
  let brands = response.data.brands;
  if (displayInUiOnly) {
    brands = response.data.brands.filter(b => b.displayInUi === 'Yes');
  }
  return brands.map(record => ({
    value: record.params?._id || record._id,
    label: record.params ? record.params.companyName : record.companyName,
    record,
  }));
};

export const loadContactsOptions = async (value) => {
  let response = {};
  if (value === 'getByIds') {
    const initialSearch = new URLSearchParams(window.location.search);
    if (initialSearch.has('filters.creator') && initialSearch.getAll('filters.creator').length > 0) {
      response = await axios.get('/api/portal/hubil/get-contacts-by-ids', {
        params: { ids: initialSearch.getAll('filters.creator') },
      });
      if (!response.data.success) {
        return {};
      }
    }
  } else {
    response = await axios.get('/api/portal/hubil/get-contact-search', {
      params: {
        value,
        param: 'name',
        defaultSearch: 'a',
      },
    });
  }
  return response.data.records.map(record => ({
    value: record.params?._id || record._id,
    label: record.params ? `${record.params.firstname} ${record.params.lastname}` : `${record.firstname} ${record.lastname}`,
    record,
  }));
};

export const loadCreatorsOptions = async (value) => {
  let response = {};
  if (value === 'getByIds') {
    const initialSearch = new URLSearchParams(window.location.search);
    if (initialSearch.has('filters.creator') && initialSearch.getAll('filters.creator').length > 0) {
      response = await axios.get('/api/portal/influencer/get-creators-by-ids', {
        params: { ids: initialSearch.getAll('filters.creator') },
      });
      if (!response.data.success) {
        return {};
      }
    }
  } else {
    response = await axios.get('/api/portal/influencer/get-creator-search', {
      params: {
        value,
        defaultSearch: 'a',
      },
    });
  }
  return response.data.records.map(record => ({
    value: record.params?._id || record._id,
    label: record.params ? `${record.params.first_name} ${record.params.last_name}` : `${record.first_name} ${record.last_name}`,
    record,
  }));
};

export const mediaContentTypeFilterOptions = [
  { label: 'YouTube', value: 'YouTube' },
  { label: 'YouTube Description Text', value: 'YouTube Description Text' },
  { label: 'Podcast', value: 'Podcast' },
  { label: 'Podcast Script Text', value: 'Podcast Script Text' },
  { label: 'Instagram Story', value: 'Instagram Story' },
  { label: 'Instagram Post', value: 'Instagram Post' },
  { label: 'Instagram TV Video', value: 'Instagram TV Video' },
  { label: 'TikTok', value: 'TikTok' },
  { label: 'X Post', value: 'Twitter Post' },
  { label: 'Facebook Post', value: 'Facebook Post' },
  { label: 'Facebook Watch Video', value: 'Facebook Watch Video' },
  { label: 'LinkTree', value: 'LinkTree' },
  { label: 'Website', value: 'Website' },
  { label: 'Email', value: 'Email' },
  { label: 'Text', value: 'Text' },
];
