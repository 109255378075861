function config() {
  return {
    recaptcha: {
      siteKey: '6LesWtUqAAAAAIVyZ2_EscBOf2X6JwuoV_iDMJeB',
      secretKey: '6LdpwdsUAAAAALZoPCstoFt9hxcSR3ox0NRg9pD2',
    },
  };
}

export default config;
