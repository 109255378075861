import func from '../../../../../../shared/hooks';

const ActionProperties = [
  {
    initialState: 'false',
    func: ({ filters, filterName }) => filters[filterName] || 'false',
    filterName: 'deleted',
    funcToUrl: func.getToUrlStringfunction,
  }
];

export default ActionProperties;
