import LogoInEdit from './LogoInEdit';
import PasswordInEdit from '../../../../Influencer/Influencer/components/custom/PasswordInEdit';
import RoleInList from './RoleInList';

export default {
  edit: {
    logo: LogoInEdit,
    password: PasswordInEdit,
  },
  list: {
    role: RoleInList,
  },
  show: {
    contextMenuBrandUser: () => '',
  },
};
