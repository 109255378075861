import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Col, Row, CardBody, Card, Badge,
  Spinner, Button, Input, FormGroup,
} from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { axios } from 'ApiClient';
import Select from 'react-select';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CashMultipleIcon from 'mdi-react/CashMultipleIcon';
import TuneIcon from 'mdi-react/TuneIcon';
import DatePicker from 'react-datepicker';
import ApiClient from 'ApiClient';
import CheckBoxField from '../../../../../shared/components/CheckBox';
import WVValidator from '../../../../../shared/helpers/WVValidator';
import { getConstant } from '../../../../../shared/helpers/WVConstants';
import MatTableForRedux from '@/shared/tables/table/MatTableToTable';
import withNotice from '../../../../App/store/with-notice';
import AdminTable from './AdminTable';
import DealInfoModal from './DealInfoModal';
import QrCodeComponent from './QrCodeComponent';
import DealStageOption from './components/DealStageOption';
import DealStatusOption from './components/DealStatusOption';
import TableCellContextMenu from '../../Research/Prospects/components/custom/TableCellContextMenu';
import SelectFilter from '../../../../../shared/components/SelectFilter';
import IntervalDatePickerField from '../../Research/Prospects/components/custom/IntervalDatePickerField';
import Alert from '../../../../../shared/easydev2/shared/components/Alert';
import Hook from "../../../../../shared/hooks";
import Overlay from "../../../../../shared/components/Overlay";
import Authorized from '../../../Finance/Bill/components/custom/Authorized';


const goToPage = (url) => {
  const win = window.open(url, '_blank');
  win.focus();
};

const dateRangeFilterLables = [
  { value: '=', label: '=' },
  { value: '>', label: '>' },
  { value: '>=', label: '>=' },
  { value: '<', label: '<' },
  { value: '<=', label: '<=' },
  { value: 'Between', label: 'Between' },
];

const DealsProfile = ({
  deals, agents, loading, statusChange, influencer, fixDeal, createDeal, salesActivity, addNotice,
}) => {
  const [clickDeal, setClickDeal] = useState(null);
  const [allowMultipleDealsForBrand, setAllowMultipleDealsForBrand] = useState(influencer?.params?.allowMultipleDealsForBrand || false);
  const [searchValue, setSearchValue] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [rightDeals, setRightDeals] = useState([]);
  const [version, setVersion] = useState((new Date()).toString());
  const [versionSelectFilters, setVersionSelectFilters] = useState((new Date()).toString());
  const [offerFilter, setOfferFilter] = useState({});
  const [dealTypeFilter, setDealTypeFilter] = useState({});
  const [dealStageFilter, setDealStageFilter] = useState({});
  const [statusFilter, setStatusFilter] = useState({
    Active: false,
    Pending: false,
    Expired: false,
    Paid: false,
    'Not Set': false,
  });
  // Brand Approved date filter
  const [enableBadDateFilter, setEnableBadDateFilter] = useState(true);
  const [badFilterType, setBadFilterType] = useState(dateRangeFilterLables[3]);
  const [brandApprovedDateFrom, setBrandApprovedDateFrom] = useState(null);
  const [brandApprovedDateTo, setBrandApprovedDateTo] = useState(null);
  const [singleBrandApprovedDate, setSingleBrandApprovedDate] = useState(null);
  // Content Release Date filter
  const [enableCrdDateFilter, setEnableCrdDateFilter] = useState(true);
  const [crdFilterType, setCrdFilterType] = useState(dateRangeFilterLables[3]);
  const [contentReleaseDateFrom, setContentReleaseDateFrom] = useState(null);
  const [contentReleaseDateTo, setContentReleaseDateTo] = useState(null);
  const [singleContentReleaseDate, setSingleContentReleaseDate] = useState(null);
  const [startReset, setStartReset] = useState(false);
  const [redirects, setRedirects] = useState({});
  const [processing, setProcessing] = useState(false);

  const { showSuccess } = Hook.useNoticeHook();

  const pipelines = getConstant('deal', 'pipelines', []);
  const allOffers = getConstant('deal', 'allOffers', []);
  const allDealTypes = getConstant('deal', 'dealTypes', []);
  const allStages = {};
  const dealStageOptions = pipelines?.map((p) => {
    const stages = Object.keys(p.stages).map((k) => {
      if (p.label.toLowerCase() === 'Main Pipeline'.toLowerCase()) allStages[k] = p.stages[k];
      return { value: k, label: p.stages[k] };
    });
    return {
      id: p.id,
      stages,
      pipeLineName: p.label
    };
  });

  const getOffers = () => {
    if (allOffers.length > 0) {
      const sortedOffers = allOffers.sort((a, b) => ((a.name > b.name) ? 1 : -1));
      const offersObj = {};
      offersObj['Not Assigned'] = false;
      sortedOffers.forEach((offer) => {
        if (offer.name) {
          offersObj[offer.name] = false;
        }
      });
      setOfferFilter(offersObj);
    }
  };
  const getDealTypes = () => {
    if (Object.keys(allDealTypes).length > 0) {
      const typesObj = {};
      typesObj['Not Assigned'] = false;
      Object.values(allDealTypes).forEach((type) => {
        typesObj[type] = false;
      });
      setDealTypeFilter(typesObj);
    }
  };
  const getDealStages = () => {
    if (Object.keys(allStages).length > 0) {
      const stages = {};
      stages['Not Assigned'] = false;
      Object.values(allStages).forEach((stage) => {
        stages[stage] = false;
      });
      setDealStageFilter(stages);
    }
  };

  const getVanityUrlsRedirect = async (data) => {
    try {
      const resp = await axios.post('/api/portal/find-vanity-url-redirect', data);
      if (resp.data.success && resp.data.resultUrls.length > 0) {
        const redirectsObj = {};
        resp.data.resultUrls.forEach((item) => {
          redirectsObj[item.dealId] = item.resultUrl;
        });
        setRedirects(redirectsObj);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const filterDeals = () => {
    let filteredDeals = [];
    deals.forEach((deal) => {
      filteredDeals.push(deal);
    });
    // filter by offer name
    if (Object.values(offerFilter).find(v => v === true)) {
      filteredDeals = filteredDeals.filter(deal => ((deal.params?.ilDealData.offer?.name && offerFilter[deal.params.ilDealData.offer.name]) || (!deal.params?.ilDealData.offer?.name && offerFilter['Not Assigned'])));
    }
    // filter by deal type
    if (Object.values(dealTypeFilter).find(v => v === true)) {
      filteredDeals = filteredDeals.filter(deal => ((deal.params?.dealTerms.type_of_deal && dealTypeFilter[deal.params.dealTerms.type_of_deal]) || (!deal.params?.dealTerms.type_of_deal && dealTypeFilter['Not Assigned'])));
    }
    // filter by deal stage
    if (Object.values(dealStageFilter).find(v => v === true)) {
      filteredDeals = filteredDeals.filter(deal => (deal.params?.dealstage && dealStageFilter[allStages[deal.params.dealstage]]) || (!deal.params?.dealstage && dealStageFilter['Not Assigned']));
    }
    // filter by status
    if (Object.values(statusFilter).find(v => v === true)) {
      filteredDeals = filteredDeals.filter(deal => (deal.params?.dealTerms.cpa_status && statusFilter[deal.params.dealTerms.cpa_status]) || (!deal.params?.dealTerms.cpa_status && statusFilter['Not Set']));
    }
    // filter by Brand Approved Date
    if (enableBadDateFilter) {
      switch (badFilterType.value) {
        case '=':
          if (singleBrandApprovedDate) {
            filteredDeals = filteredDeals.filter(deal => (deal.params?.brandApprovedDate &&
              (moment(moment(deal.params.brandApprovedDate).format('MM/DD/YYYY')).isSame(moment(moment(singleBrandApprovedDate.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
        case '>':
          if (singleBrandApprovedDate) {
            filteredDeals = filteredDeals.filter(deal => (deal.params?.brandApprovedDate &&
              (moment(moment(deal.params.brandApprovedDate).format('MM/DD/YYYY')).isAfter(moment(moment(singleBrandApprovedDate.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
        case '>=':
          if (singleBrandApprovedDate) {
            filteredDeals = filteredDeals.filter(deal => (deal.params?.brandApprovedDate &&
              (moment(moment(deal.params.brandApprovedDate).format('MM/DD/YYYY')).isSameOrAfter(moment(moment(singleBrandApprovedDate.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
        case '<':
          if (singleBrandApprovedDate) {
            filteredDeals = filteredDeals.filter(deal => (deal.params?.brandApprovedDate &&
              (moment(moment(deal.params.brandApprovedDate).format('MM/DD/YYYY')).isBefore(moment(moment(singleBrandApprovedDate.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
        case '<=':
          if (singleBrandApprovedDate) {
            filteredDeals = filteredDeals.filter(deal => (deal.params?.brandApprovedDate &&
              (moment(moment(deal.params.brandApprovedDate).format('MM/DD/YYYY')).isSameOrBefore(moment(moment(singleBrandApprovedDate.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
        default:
          if (!singleBrandApprovedDate && (brandApprovedDateFrom || brandApprovedDateTo)) {
            let dateFrom = brandApprovedDateFrom;
            if (!brandApprovedDateFrom) dateFrom = moment('2000-01-20');
            filteredDeals = filteredDeals.filter(deal => (deal.params?.brandApprovedDate &&
              (moment(moment(deal.params.brandApprovedDate).format('MM/DD/YYYY'))
                .isBetween(moment(moment(dateFrom.toString()).format('MM/DD/YYYY')), moment(moment(brandApprovedDateTo?.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
      }
    }
    // filter by Content Release Date
    if (enableCrdDateFilter) {
      switch (crdFilterType.value) {
        case '=':
          if (singleContentReleaseDate) {
            filteredDeals = filteredDeals.filter(deal => (deal.params?.dealTerms?.content_release_date &&
              (moment(moment(deal.params.dealTerms.content_release_date).format('MM/DD/YYYY')).isSame(moment(moment(singleContentReleaseDate.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
        case '>':
          if (singleContentReleaseDate) {
            filteredDeals = filteredDeals.filter(deal => (deal.params?.dealTerms?.content_release_date &&
              (moment(moment(deal.params.dealTerms.content_release_date).format('MM/DD/YYYY')).isAfter(moment(moment(singleContentReleaseDate.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
        case '>=':
          if (singleContentReleaseDate) {
            filteredDeals = filteredDeals.filter(deal => (deal.params?.dealTerms?.content_release_date &&
              (moment(moment(deal.params.dealTerms.content_release_date).format('MM/DD/YYYY')).isSameOrAfter(moment(moment(singleContentReleaseDate.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
        case '<':
          if (singleContentReleaseDate) {
            filteredDeals = filteredDeals.filter(deal => (deal.params?.dealTerms?.content_release_date &&
              (moment(moment(deal.params.dealTerms.content_release_date).format('MM/DD/YYYY')).isBefore(moment(moment(singleContentReleaseDate.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
        case '<=':
          if (singleContentReleaseDate) {
            filteredDeals = filteredDeals.filter(deal => (deal.params?.dealTerms?.content_release_date &&
              (moment(moment(deal.params.dealTerms.content_release_date).format('MM/DD/YYYY')).isSameOrBefore(moment(moment(singleContentReleaseDate.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
        default:
          if (!singleContentReleaseDate && (contentReleaseDateFrom || contentReleaseDateTo)) {
            let dateFrom = contentReleaseDateFrom;
            if (!contentReleaseDateFrom) dateFrom = moment('2000-01-20');
            filteredDeals = filteredDeals.filter(deal => (deal.params?.dealTerms?.content_release_date &&
              (moment(moment(deal.params.dealTerms.content_release_date).format('MM/DD/YYYY'))
                .isBetween(moment(moment(dateFrom.toString()).format('MM/DD/YYYY')), moment(moment(contentReleaseDateTo?.toString()).format('MM/DD/YYYY')), 'day'))));
          }
          break;
      }
    }
    // filter by search string
    if (searchValue) {
      filteredDeals = filteredDeals.filter(deal => (deal.params.dealname?.toLowerCase().includes(searchValue.toLowerCase()) || deal.params.dealTerms?.coupon_code?.toLowerCase().includes(searchValue.toLowerCase())));
    }
    setRightDeals(filteredDeals);
  };

  useEffect(async () => {
    if (deals.length > 0) {
      const data = { deals: [], qr: true };
      deals.forEach((deal) => {
        if (deal.params?.dealTerms.coupon_code) {
          const shortData = {
            dealId: deal.id,
            offerId: deal.params?.ilDealData?.offer?._id,
            couponCode: deal.params?.dealTerms?.coupon_code,
          };
          data.deals.push(shortData);
        }
      });
      await getVanityUrlsRedirect(data);
      filterDeals();
      setVersion((new Date()).toString());
    }
  }, [deals]);
  const firstUpdate = useRef(true);

  useEffect(() => {
    setAllowMultipleDealsForBrand(influencer?.params?.allowMultipleDealsForBrand || false);
  }, [influencer?.params?.allowMultipleDealsForBrand]);

  useEffect(() => {
    getOffers();
    getDealTypes();
    getDealStages();
  }, []);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (!startReset) {
      filterDeals();
      setVersion((new Date()).toString());
    }
  }, [
    searchValue, offerFilter, dealTypeFilter, dealStageFilter, statusFilter,
    singleBrandApprovedDate, badFilterType, brandApprovedDateTo, brandApprovedDateFrom, enableBadDateFilter,
    singleContentReleaseDate, crdFilterType, contentReleaseDateTo, contentReleaseDateFrom, enableCrdDateFilter, deals,
  ]);

  const getValidationStatus = (deal) => {
    let color = 'success';
    let message = 'Valid';

    if (WVValidator.isEmpty(deal.params.ilDealData?.offer)) {
      color = 'danger';
      message = 'Missing Offer';
    } else if (WVValidator.isEmpty(deal.params.dealTerms?.type_of_deal)) {
      color = 'danger';
      message = 'Missing Deal Type';
    } else if (WVValidator.isEmpty(deal.params.ilDealData?.influencer) || deal.params.ilDealData?.influencer !== influencer.id) {
      return (
        <Badge color="danger" className="table-rounded deal-valid-status-missing-creator">
          Missing Creator{' '}
          <Tooltip title="Fix Deal">
            <button className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall " onClick={() => fixDeal(deal)}>
              <i className="fa fa-wrench" />
            </button>
          </Tooltip>
        </Badge>);
    }
    return (<Badge color={color} className="table-rounded deal-valid-status">{message}</Badge>);
  };

  const getAgent = agentId => agents[agentId] || null;

  const getDealAgent = (agentIds) => {
    if (!agentIds || agentIds.length < 1) return null;
    const len = agentIds.length;
    if (len > 1) {
      for (let i = 0; i < len; i += 1) {
        const agent = getAgent(agentIds[i]);
        if (agent && agent.hasPaymentAccount) {
          return agent;
        }
      }
    }
    return getAgent(agentIds[0]);
  };

  const dealTypeMap = {
    'CPA/Ambassador': 'CPA',
    'Upfront Only': 'Upfront',
    'Hybrid': 'Hybrid',
    'Upfront CPM': 'CPM',
    'Bonus': 'Bonus',
    'License': 'License',
  };

  const columnTransform = {
    name: {
      title: 'Deal Name',
      valueFunc: (deal) => {
        const dealAgent = getDealAgent(deal?.params?.agents);
        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {dealAgent && (
              <Tooltip
                title={`${dealAgent.first_name || ''} ${dealAgent.last_name || ''} (${dealAgent.email || ''})`}
              >
                <Link to={`/agent/profile/${dealAgent._id}`} target="_blank" rel="noreferrer">
                  <img
                    style={{ width: '25px', height: '25px' }}
                    src="/images/creator/agent-enabled.png"
                    alt="Agent"
                  />
                </Link>
              </Tooltip>
            )}
            <strong style={{ paddingLeft: '12px', overflow: 'auto' }} className="deal-name">
              {deal?.params?.dealname}
            </strong>
          </span>
        );
      },
      sort: deal => deal?.params?.dealname,
    },
    offer: {
      title: 'Offer',
      valueFunc: deal => (<strong className="deal-offer">{deal?.params?.ilDealData?.offer?.name}</strong>),
      sort: deal => deal?.params?.ilDealData?.offer?.name,
      width: 120,
    },
    status: {
      title: 'Status',
      valueFunc: deal => (
        <DealStatusOption
          deal={deal}
          statusChange={statusChange}
        />
      ),
      sort: deal => deal.params.dealTerms?.cpa_status || '',
      width: 130,
    },
    dealtype: {
      title: 'Deal Type',
      valueFunc: deal => (<strong className="deal-type">{deal.params.dealTerms?.type_of_deal ? dealTypeMap[deal.params.dealTerms?.type_of_deal] : ''}</strong>),
      sort: deal => deal.params.dealTerms?.type_of_deal,
      width: 120,
    },
    dealStage: {
      title: 'Deal Stage',
      valueFunc: deal => (
        <DealStageOption
          dealStageOptons={dealStageOptions}
          deal={deal}
          addNotice={addNotice}
          statusChange={statusChange}
        />
      ),
      sort: deal => deal.params.dealstage,
    },
    brandApprovedDate: {
      title: 'Brand Approved Date',
      valueFunc: deal => (<strong style={{paddingLeft: '20px'}} className="brand-approved-date">
        {deal.params.brandApprovedDate ? moment.utc(deal.params.brandApprovedDate).format('MM/DD/YYYY') : ''}
      </strong>),
      sort: deal => deal.params.brandApprovedDate || '',
      width: 150,
    },
    publishedDate: {
      title: 'Publish Date',
      valueFunc: deal => (<strong style={{paddingLeft: '10px'}}>
        {deal.params.dealTerms?.published_date ? moment.utc(deal.params.dealTerms.published_date).format('MM/DD/YYYY') : ''}
      </strong>),
      sort: deal => deal.params.dealTerms?.published_date || '',
      width: 150,
    },
    content_release_date: {
      title: 'Content Release Date',
      valueFunc: deal => (<strong style={{paddingLeft: '5px'}} className="content-release-date">
        {deal.params.dealTerms?.content_release_date ? moment.utc(deal.params.dealTerms.content_release_date).format('MM/DD/YYYY') : ''}
      </strong>),
      sort: deal => deal.params.dealTerms?.content_release_date || '',
      width: 150,
    },
    promoCode: {
      title: 'Promo Code',
      valueFunc: deal => (<strong className="promo-code">{deal.params.dealTerms?.coupon_code}</strong>),
      sort: deal => deal.params.dealTerms?.coupon_code || '',
      width: 120,
    },
    pac: {
      title: 'PAC',
      valueFunc: deal => (<Authorized record={{ populated: { deal }, }} />),
      sort: () => 1,
      width: 70,
    },
    validation: {
      title: 'Valid?',
      valueFunc: deal => getValidationStatus(deal),
      sort: () => 1,
      width: 120,
    },
    actions: {
      title: ' ',
      valueFunc: (deal) => {
        const contextMenuOptions = [
          { label: 'Deal Information', handler: () => { setClickDeal(deal.id); } },
          { type: 'divider', label: 'divider1' },
          { label: 'Edit Deal', handler: () => goToPage(`/influencers/creator-deal-editor/${deal.id}`) },
          { type: 'divider', label: 'divider2' },
          { label: 'View Hubspot Deal', handler: () => goToPage(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${deal ? deal.params.hs_object_id : 0}/`) },
          { label: 'View Hubspot Contact ', handler: () => goToPage(`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${deal.params.contact?.hs_object_id || 0}/`) },
          { type: 'divider', label: 'divider3' },
          {
            label: 'Refresh Payment Authorization',
            handler: async () => {
              try {
                setProcessing(true);
                await axios.post('/api/portal/finance/post-deal-validation-prefill', { ids: [deal.id] });
                setProcessing(false);
                showSuccess('Authorization has been updated');
                setVersion((new Date()).toString());
              } catch (e) {
                console.log(e);
              }
            },
          },
        ];
        if (redirects[deal.id]) {
          contextMenuOptions.push({ type: 'divider', label: 'divider4' });
          contextMenuOptions.push({
            label: 'Show QR Code',
            type: 'popover',
            popover: <QrCodeComponent
              dealname={deal?.params?.dealname?.trim()}
              url={redirects[deal.id]}
              showIcon={false}
            />,
          });
        }
        return (
          <>
            <TableCellContextMenu options={contextMenuOptions} />
            <DealInfoModal
              isOpen={clickDeal && clickDeal === deal.id}
              deal={deal}
              toggleModal={() => setClickDeal(null)}
            />
          </>
        );
      },
      sort: () => 1,
      disableSortBy: true,
      width: 50,
    },
  };

  const onChangeMultipleDeals = (e) => {
    if (e && e.target) {
      setAllowMultipleDealsForBrand(e.target.checked);
      const data = new FormData();
      data.append('allowMultipleDealsForBrand', e.target.checked);
      const api = new ApiClient();
      api.recordAction({
        resourceId: 'Influencer',
        recordId: influencer.id,
        actionName: 'edit',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        if (!response.data.record) {
          addNotice({
            message: 'Unable to update influencer data',
            type: 'error',
          });
        }
      }).catch((error) => {
        addNotice({
          message: error.message,
          type: 'error',
        });
      });
    }
  };

  const resetFilters = () => {
    setStartReset(true);
    getOffers();
    getDealTypes();
    getDealStages();
    setSearchValue(null);
    setBrandApprovedDateFrom(null);
    setBrandApprovedDateTo(null);
    setSingleBrandApprovedDate(null);
    setBadFilterType(dateRangeFilterLables[3]);
    if (enableBadDateFilter) setEnableBadDateFilter(!enableBadDateFilter);
    setContentReleaseDateFrom(null);
    setContentReleaseDateTo(null);
    setSingleContentReleaseDate(null);
    setCrdFilterType(dateRangeFilterLables[3]);
    if (enableCrdDateFilter) setEnableCrdDateFilter(!enableCrdDateFilter);
    setStartReset(false);
    setRightDeals(deals);
    setVersion((new Date()).toString());
    setVersionSelectFilters((new Date()).toString());
  };

  const customStyles = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? '#e9ecef' : 'white',
      // height: '44px',
    }),
  };

  return (
    <div key={`deals-${deals && deals.length > 0 ? deals[0].params._id : 'loading'}`}>
      <h2 className="section-title mt-0 mb-4">Hubspot Deals</h2>
      <Card id="section-hubspot-deals">
        <CardBody>
          <Row>
            <Col>
              <CheckBoxField
                name="allowMultipleDealsForBrand"
                label="Allow multiple Active deals per month for the same Brand?"
                value={(typeof allowMultipleDealsForBrand === 'undefined') ? influencer?.params?.allowMultipleDealsForBrand : allowMultipleDealsForBrand}
                onChange={onChangeMultipleDeals}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={5}>
              <div className="table__search">
                <Input
                  className=""
                  value={searchValue || ''}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  type="search"
                  placeholder="Search Deal Name, Promo Code"
                  style={{ minWidth: '100%' }}
                />
                {/* dataLength !== rows.length && (
                  <span>Found {rows.length} matches</span>
                ) */}
              </div>
            </Col>
            <Col md={3}>
              <Button
                color="secondary"
                onClick={resetFilters}
                style={{ marginBottom: '0px', height: '38px' }}
              >
                <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                <span className="btn-text">Reset</span>
              </Button>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{ marginBottom: '0px' }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
            <Col />
            <Col xs="auto">
              <Tooltip title="See Sales Activity of the Influencer">
                <span className="">
                  <Button
                    color="primary"
                    className="radius-button"
                    disabled={!!loading}
                    onClick={salesActivity}
                    style={{ marginBottom: '0px' }}
                  >
                    <CashMultipleIcon size="1em" className="mt-0" /> Sales Activity
                  </Button>
                </span>
              </Tooltip>
              <Button
                color="primary"
                id="new-deal-btn"
                className="radius-button"
                disabled={!!loading || influencer?.params?.inBlackList}
                onClick={createDeal}
                style={{ marginLeft: '10px', marginBottom: '0px' }}
              >
                <i className="fas fa-plus-circle" />
                New Deal
              </Button>
            </Col>
          </Row>
          {showFilters && (
            <>
              <Row className="mb-1 small-height">
                <Col md={4}>
                  <h5>Offers</h5>
                  <SelectFilter
                    key="123"
                    title="Offers"
                    filterProp={offerFilter}
                    filterAction={v => setOfferFilter(v)}
                    styles={{ marginTop: '8px' }}
                    version={versionSelectFilters}
                    defaultValue={[]}
                  />
                </Col>
                <Col md={4}>
                  <h5>Deal Types</h5>
                  <SelectFilter
                    key="234"
                    title="Deal Types"
                    filterProp={dealTypeFilter}
                    filterAction={v => setDealTypeFilter(v)}
                    styles={{ marginTop: '8px' }}
                    version={versionSelectFilters}
                    defaultValue={[]}
                  />
                </Col>
                <Col md={4}>
                  <h5>Deal Stages</h5>
                  <SelectFilter
                    key="345"
                    title="Deal Stages"
                    filterProp={dealStageFilter}
                    filterAction={v => setDealStageFilter(v)}
                    styles={{ marginTop: '8px' }}
                    version={versionSelectFilters}
                    defaultValue={[]}
                  />
                </Col>
              </Row>
              <Row className="small-height mb-1">
                <Col md={4} style={{ marginTop: '10px' }}>
                  <h5>Status</h5>
                  <SelectFilter
                    key="456"
                    title="Status"
                    filterProp={statusFilter}
                    filterAction={v => setStatusFilter(v)}
                    styles={{ marginTop: '8px' }}
                    version={versionSelectFilters}
                    defaultValue={[]}
                  />
                </Col>
                <Col md={4} style={{ marginTop: '10px' }}>
                  <h5>Brand Approved Date</h5>
                  <Row style={{ marginTop: '8px' }}>
                    <Col sm={1}>
                      <FormGroup
                        style={{ paddingTop: '8px', paddingLeft: '8px' }}
                      >
                        <CheckBoxField
                          name="BAD"
                          value={enableBadDateFilter}
                          onChange={() => { setEnableBadDateFilter(!enableBadDateFilter); }}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={4} style={{ paddingLeft: '20px' }}>
                      <Select
                        value={badFilterType}
                        onChange={(option) => {
                          if ((badFilterType !== 'Between' && option.value === 'Between') || (badFilterType === 'Between' && option.value !== 'Between')) {
                            setBrandApprovedDateFrom(null);
                            setBrandApprovedDateTo(null);
                            setSingleBrandApprovedDate(null);
                          }
                          setBadFilterType(option);
                        }}
                        options={dateRangeFilterLables}
                        isDisabled={!enableBadDateFilter}
                        styles={customStyles}
                      />
                    </Col>
                    <Col sm={6}>
                      {badFilterType.value === 'Between' ? (
                        <div style={{ marginRight: '-30px' }} className="pb-2">
                          <IntervalDatePickerField
                            onChange={({ start, end }) => {
                              setBrandApprovedDateFrom(start);
                              end?.setHours(23, 59, 59);
                              setBrandApprovedDateTo(end);
                            }}
                            startDate={brandApprovedDateFrom}
                            endDate={brandApprovedDateTo}
                            maxDate={new Date()}
                            placeholderFrom="Start Date"
                            placeholderTo="End Date"
                            verticalFields
                            disabled={!enableBadDateFilter}
                            datePickerCustomClass="datepicker-in-profile"
                          />
                        </div>
                      ) : (
                        <div className="date-picker datepicker-in-profile">
                          <DatePicker
                            selected={singleBrandApprovedDate}
                            onChange={(date) => {
                              if (badFilterType.value === '<=' || badFilterType.value === '>') {
                                date?.setHours(23, 59, 59);
                              }
                              setSingleBrandApprovedDate(date);
                            }}
                            dateFormat="MMM d, yyyy"
                            maxDate={new Date()}
                            placeholderText="Select Date"
                            dropDownMode="select"
                            isClearable
                            wrapperClassName="date-picker--interval w-100"
                            className="form-control form-control-sm datepicker-in-profile w-100"
                            disabled={!enableBadDateFilter}
                          />
                          {singleBrandApprovedDate === null && (
                            <svg
                              className="mdi-icon"
                              width="24"
                              height="24"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                              style={{
                                position: 'absolute',
                                top: '8px',
                                right: '22px',
                                fill: '#999',
                                pointerEvents: 'none',
                              }}
                            >
                              <path
                                d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                              />
                            </svg>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col md={4} style={{ marginTop: '8px' }}>
                  <h5>Content Release Date</h5>
                  <Row style={{ marginTop: '8px' }}>
                    <Col sm={1}>
                      <FormGroup
                        style={{ paddingTop: '8px', paddingLeft: '8px' }}
                      >
                        <CheckBoxField
                          name="CRD"
                          value={enableCrdDateFilter}
                          onChange={() => { setEnableCrdDateFilter(!enableCrdDateFilter); }}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={4} style={{ paddingLeft: '20px' }}>
                      <Select
                        value={crdFilterType}
                        onChange={(option) => {
                          if ((crdFilterType !== 'Between' && option.value === 'Between') || (crdFilterType === 'Between' && option.value !== 'Between')) {
                            setContentReleaseDateFrom(null);
                            setContentReleaseDateTo(null);
                            setSingleContentReleaseDate(null);
                          }
                          setCrdFilterType(option);
                        }}
                        options={dateRangeFilterLables}
                        isDisabled={!enableCrdDateFilter}
                        styles={customStyles}
                      />
                    </Col>
                    <Col sm={6}>
                      {crdFilterType.value === 'Between' ? (
                        <div style={{ marginRight: '-30px' }} className="pb-2">
                          <IntervalDatePickerField
                            onChange={({ start, end }) => {
                              setContentReleaseDateFrom(start);
                              end?.setHours(23, 59, 59);
                              setContentReleaseDateTo(end);
                            }}
                            startDate={contentReleaseDateFrom}
                            endDate={contentReleaseDateTo}
                            maxDate={new Date()}
                            placeholderFrom="Start Date"
                            placeholderTo="End Date"
                            verticalFields
                            disabled={!enableCrdDateFilter}
                            datePickerCustomClass="datepicker-in-profile"
                          />
                        </div>
                      ) : (
                        <div className="date-picker datepicker-in-profile">
                          <DatePicker
                            selected={singleContentReleaseDate}
                            onChange={(date) => {
                              if (crdFilterType.value === '<=' || crdFilterType.value === '>') {
                                date?.setHours(23, 59, 59);
                              }
                              setSingleContentReleaseDate(date);
                            }}
                            dateFormat="MMM d, yyyy"
                            maxDate={new Date()}
                            placeholderText="Select Date"
                            dropDownMode="select"
                            isClearable
                            wrapperClassName="date-picker--interval w-100"
                            className="form-control form-control-sm datepicker-in-profile w-100"
                            disabled={!enableCrdDateFilter}
                          />
                          {singleContentReleaseDate === null && (
                            <svg
                              className="mdi-icon"
                              width="24"
                              height="24"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                              style={{
                                position: 'absolute',
                                top: '8px',
                                right: '22px',
                                fill: '#999',
                                pointerEvents: 'none',
                              }}
                            >
                              <path
                                d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                              />
                            </svg>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col>
              {loading ? (<div className="text-center"><Spinner color="primary" size="lg" /></div>) : null}
              {!loading && deals.length > 0 && rightDeals.length > 0 ? (
                <AdminTable>
                  {processing && (<Overlay />)}
                  <MatTableForRedux
                    dataArray={rightDeals}
                    selectable={false}
                    columns={columnTransform}
                    version={version}
                    defaultOrder={{
                      order: 'desc',
                      orderBy: 'dealname',
                    }}
                    rowsPerPage={10}
                    hideToolbar
                  />
                </AdminTable>
              ) : !loading && (<Alert color="warning" bordered icon ><p className="py-2">The Creator currently has no deals. Use the <strong>New Deal</strong> button to add a deal.</p></Alert>)}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
DealsProfile.propTypes = {
  deals: PropTypes.arrayOf(PropTypes.any).isRequired,
  agents: PropTypes.objectOf(PropTypes.any).isRequired,
  influencer: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool.isRequired,
  statusChange: PropTypes.func.isRequired,
  fixDeal: PropTypes.func.isRequired,
  createDeal: PropTypes.func.isRequired,
  salesActivity: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};
DealsProfile.defaultProps = {
  influencer: null,
};

export default withNotice(DealsProfile);
