import React, { useState, useEffect } from 'react';
import { Badge } from 'reactstrap';
import MatTableList from '../../../../../../../shared/tables/materialTable/TableRedux';
import fetchResourceData from '../../../../../../../shared/helpers/WVRequest';
import SelectResource from '../../../../../../../shared/components/table/cell/SelectResource';
import ContextMenuInList from './ContextMenuInList';
import IsPilot from './IsPilot';
import IsDisplayInUi from './IsDisplayInUi';
import Inactive from './Inactive';
import CopyToClipboard from '@/shared/components/CopyToClipboard';
import WVFormatter from '@/shared/helpers/WVFormatter';

const Table = React.memo(() => {
  const [loadingOffers, setLoadingOffers] = useState(false);
  const [offerOptions, setOfferOptions] = useState([]);

  useEffect(() => {
    setLoadingOffers(true);
    fetchResourceData('Offer', {}, 'perPage=5000&sortBy=name&direction=asc').then((res) => {
      setOfferOptions(res.records.map(r => ({ value: r.params.tuneId, label: r.params.name, id: r.id })));
      setLoadingOffers(false);
    });
  }, []);
  return (
    <MatTableList
      useFlex
      enableWarning={false}
      notFoundProps={{ title: 'No Brand Users Found' }}
      useUnflatten
      customColumns={[
        {
          width: 120,
          id: 'companyName',
          Header: () => 'Company',
          accessor: record => (record?.params?.companyName || ''),
          Cell : (p) => (
            <div>
              <div>
                <CopyToClipboard value={p.value || ''}>{p.value}</CopyToClipboard>
              </div>
              {p.row.original?.params?.tuneId && (
                <small className="text-secondary">
                  Tune ID: <CopyToClipboard value={p.row.original.params.tuneId.toString()}>
                    {p.row.original.params.tuneId.toString()}
                  </CopyToClipboard>
                </small>
              )}
            </div>
          ),
          isFreeze: true,
          className: 'd-flex align-items-center',
        },
        {
          width: 140,
          id: 'defaultOffer',
          Header: () => 'Default Offer',
          accessor: record => (record?.params?.defaultOfferTuneID || ''),
          Cell : (p) => <div style={{ width: '90%' }}>
            <SelectResource
              resource="Advertiser"
              record={p.row.original}
              property="defaultOfferTuneID"
              options={offerOptions.filter(r => p.row.original?.params?.offers?.includes(r.id))}
              fetching={loadingOffers}
              placeholder="-- Not Assigned --"
            />
          </div>,
          className: 'd-flex align-items-center',
        },
        {
          width: 110,
          id: 'inactive',
          Header: () => 'Inactive',
          accessor: record => (record?.params?.inactive || ''),
          Cell : (p) => <Inactive record={p.row.original} updateRecordFunction={p.updateRecordFunction} />,
          className: 'd-flex align-items-center',
        },
        {
          width: 110,
          id: 'isPilot',
          Header: () => 'Is Pilot',
          accessor: record => (record?.params?.isPilot || ''),
          Cell : (p) => <IsPilot record={p.row.original} updateRecordFunction={p.updateRecordFunction} />,
          className: 'd-flex align-items-center',
        },
        {
          width: 110,
          id: 'displayInUi',
          Header: () => 'Display',
          accessor: record => (record?.params?.displayInUi || ''),
          Cell : (p) => <IsDisplayInUi record={p.row.original} updateRecordFunction={p.updateRecordFunction} />,
          className: 'd-flex align-items-center',
        },
        {
          width: 60,
          id: 'status',
          Header: () => 'Status',
          accessor: record => (record?.params?.firstName || ''),
          className: 'd-flex align-items-center',
          Cell: r => (<Badge color={r.row.original?.params?.status === 'active' ? 'success' : 'secondary'}>{WVFormatter.capitalize(r.row.original.params?.status)}</Badge>),
        },          
        {
          id: 'actions',
          Header: ' ',
          accessor: () => {},
          Cell: p => (
            <ContextMenuInList
              record={p.row.original}
            />
          ),
          disableSortBy: true,
          className: 'd-flex align-items-center',
          width: 30,
          maxWidth: 30,
        },
      ]}
    />
  );
});

Table.propTypes = {};

export default Table;
