import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button, Label, FormGroup,
} from 'reactstrap';
import { connect } from 'react-redux';
import { useAsyncDebounce } from 'react-table';
import SelectAsync from 'react-select/async';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import TuneIcon from 'mdi-react/TuneIcon';
import moment from 'moment';
import Select from 'react-select';
import { getCookie, setCookie } from '../../../../../shared/helpers/WVUtilities';
import TimePeriodFilter from '../../../../../shared/components/filter/TimePeriodFilter';
import { setUrl } from './custom/actions/setUrl';
import { loadBrandsOptions, loadCreatorsOptions, mediaContentTypeFilterOptions } from '../../../../../shared/helpers/OptionsLoader';
import CheckBoxField from '../../../../../shared/components/CheckBox';

const ilStatusFilters = {
  New: true,
  'First Score': true,
  'Second Score': true,
  Rescore: true,
  'Pending Edits': true,
  'Awaiting Brand Approval': true,
  'Brand Approved': true,
};
const brandStatusFilters = {
  'Not Set': true,
  'In Review': true,
  'Pending Edits': true,
  'Approved Pending Edits': true,
  Approved: true,
  Rejected: true,
};

const Actions = ({
  selectedContentIds, downloadCsv, setLoadingFiltersData, setBulkUpdateType, setShowUpdateStatusModal,
}) => {
  let selectedStartDateInitialState = moment(moment.utc().format('YYYY-MM-DD HH:mm:ss')).startOf('year');
  let selectedEndDateInitialState = moment(moment.utc().format('YYYY-MM-DD HH:mm:ss')).endOf('day');
  let selectedInitialRange = 'year_to_date';
  const selectedTypesInitialState = [];
  const selectedDealTypesInitialState = [];

  const initialSearch = new URLSearchParams(window.location.search);
  if (initialSearch.has('filters.createdAt.from') && initialSearch.getAll('filters.createdAt.from').length > 0) {
    initialSearch.getAll('filters.createdAt.from').forEach((from) => {
      selectedStartDateInitialState = moment(moment.utc(from).format('YYYY-MM-DD')).startOf('day');
    });
  }
  if (initialSearch.has('filters.createdAt.to') && initialSearch.getAll('filters.createdAt.to').length > 0) {
    initialSearch.getAll('filters.createdAt.to').forEach((to) => {
      selectedEndDateInitialState = moment(moment.utc(to).format('YYYY-MM-DD')).endOf('day');
    });
  }
  if (initialSearch.has('range') && initialSearch.getAll('range').length > 0) {
    initialSearch.getAll('range').forEach((range) => {
      selectedInitialRange = range;
    });
  }
  if (initialSearch.has('filters.type') && initialSearch.getAll('filters.type').length > 0) {
    initialSearch.getAll('filters.type').forEach((type) => {
      selectedTypesInitialState.push({ value: type, label: type });
    });
  }
  if (initialSearch.has('filters.dealType') && initialSearch.getAll('filters.dealType').length > 0) {
    initialSearch.getAll('filters.dealType').forEach((dealType) => {
      selectedDealTypesInitialState.push({ value: dealType, label: dealType });
    });
  }
  if (initialSearch.has('filters.status') && initialSearch.getAll('filters.status').length > 0) {
    Object.keys(ilStatusFilters).map((key) => {
      ilStatusFilters[key] = false;
      return true;
    });
    initialSearch.getAll('filters.status').forEach((type) => {
      ilStatusFilters[type] = true;
    });
  }
  if (initialSearch.has('filters.brandStatus') && initialSearch.getAll('filters.brandStatus').length > 0) {
    Object.keys(brandStatusFilters).map((key) => {
      brandStatusFilters[key] = false;
      return true;
    });
    initialSearch.getAll('filters.brandStatus').forEach((type) => {
      brandStatusFilters[type] = true;
    });
  }

  const dealTypeFilterOptions = [
    { label: 'CPA/Ambassador', value: 'CPA/Ambassador' },
    { label: 'Upfront Only', value: 'Upfront Only' },
    { label: 'Hybrid', value: 'Hybrid' },
    { label: 'Upfront CPM', value: 'Upfront CPM' },
    { label: 'Media License', value: 'Media License' },
    { label: 'Bonus', value: 'Bonus' },
  ];

  const [search, setSearch] = useState('');
  const [dateSearch, setDateSearch] = useState(null);
  const [showFilters, setShowFilters] = useState(getCookie('contentManagementShowFilters') === 'true');
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState(selectedTypesInitialState);
  const [selectedDealTypes, setSelectedDealTypes] = useState(selectedDealTypesInitialState);
  const [selectedIlStatuses, setSelectedIlStatuses] = useState(ilStatusFilters);
  const [selectedBrandStatuses, setSelectedBrandStatuses] = useState(brandStatusFilters);
  const [resetDealFilter, setResetDealFilter] = useState(false);

  const [dateFrom, setDateFrom] = useState(selectedStartDateInitialState.format('YYYY-MM-DD HH:mm:ss'));
  const [dateTo, setDateTo] = useState(selectedEndDateInitialState.format('YYYY-MM-DD HH:mm:ss'));
  const [selectedButton, setSelectedButton] = useState(selectedInitialRange);

  const history = useHistory();
  const firstUpdate = useRef(true);

  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl({
      history,
      search,
      dateFrom,
      dateTo,
      selectedButton,
      selectedCreators,
      selectedBrands,
      selectedTypes,
      selectedDealTypes,
      selectedIlStatuses,
      selectedBrandStatuses,
      resetDealFilter,
    });
  }, 1000);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setUrlDebounced();
  }, [
    search,
    dateFrom, dateTo,
    selectedCreators,
    selectedBrands,
    selectedTypes,
    selectedDealTypes,
    selectedIlStatuses,
    selectedBrandStatuses,
    resetDealFilter,
  ]);

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      // onSearchChange(search);
      // console.log(search);
    }, 1000));
  }, [search]);

  useEffect(() => {
    setCookie('contentManagementShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  useEffect(() => {
    if (initialSearch.has('filters.creator') && initialSearch.getAll('filters.creator').length > 0) {
      loadCreatorsOptions('getByIds').then((response) => {
        setSelectedCreators(response);
      });
    }
    if (initialSearch.has('filters.brand') && initialSearch.getAll('filters.brand').length > 0) {
      loadBrandsOptions('getByIds').then((response) => {
        setSelectedBrands(response);
      });
    }
    setLoadingFiltersData(false);
  }, []);

  const handleChangeStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setSelectedIlStatuses({
        ...selectedIlStatuses,
        [name]: checked,
      });
    }
  };
  const handleChangeBrandStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setSelectedBrandStatuses({
        ...selectedBrandStatuses,
        [name]: checked,
      });
    }
  };

  const resetFilters = () => {
    setSearch('');
    setSelectedBrands([]);
    setSelectedCreators([]);
    setSelectedTypes(null);
    setSelectedDealTypes([]);
    setSelectedIlStatuses({
      New: true,
      'First Score': true,
      'Second Score': true,
      Rescore: true,
      'Pending Edits': true,
      'Awaiting Brand Approval': true,
      'Brand Approved': true,
    });
    setSelectedBrandStatuses({
      'Not Set': true,
      'In Review': true,
      'Pending Edits': true,
      Approved: true,
      Rejected: true,
    });
    setResetDealFilter(true);
  };

  return (
    <div className="mb-1">
      <TimePeriodFilter
        startDateInitial={dateFrom}
        endDateInitial={dateTo}
        selectedButtonInitial={selectedButton}
        setValue={(param, value, range) => {
          if (param === 'startDate') {
            setDateFrom(moment(value).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
          }
          if (param === 'endDate') {
            setDateTo(moment(value).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
          }
          setSelectedButton(range);
        }}
        title="Submit Date"
        options={[
          { value: 'current_month', label: 'Current Month', border: '5px 0 0 5px' },
          { value: 'last_month', label: 'Last Month' },
          { value: 'today', label: 'Today' },
          { value: 'last_7_days', label: 'Last 7 days' },
          { value: 'year_to_date', label: 'Year to Date' },
          { value: 'all_time', label: 'All Time' },
          {
            value: 'custom', label: 'Custom', border: '0 5px 5px 0', lastElement: true,
          },
        ]}
      />
      <Row className="mt-4">
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Video Title, Creator Name or Content URL"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() => { setBulkUpdateType('influenceLogic'); setShowUpdateStatusModal(true); }}
                disabled={selectedContentIds.length === 0}
              >
                Bulk Update InfluenceLogic Status
              </DropdownItem>
              <DropdownItem
                onClick={() => { setBulkUpdateType('brand'); setShowUpdateStatusModal(true); }}
                disabled={selectedContentIds.length === 0}
              >
                Bulk Update Brand Status
              </DropdownItem>
              <DropdownItem
                onClick={downloadCsv}
              >
                Download CSV
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      {showFilters && (
        <>
          <Row className="mt-3">
            <Col md={3}>
              <FormGroup>
                <Label className="bold-text">
                  Brand(s)
                </Label>
                <SelectAsync
                  cacheOptions
                  defaultOptions
                  isClearable
                  isMulti
                  loadOptions={async value => loadBrandsOptions(value)}
                  onChange={setSelectedBrands}
                  placeholder="Select Brand..."
                  value={selectedBrands}
                  styles={colourStyles}
                />
              </FormGroup>
              <FormGroup>
                <Label className="bold-text">
                  Creator(s)
                </Label>
                <SelectAsync
                  cacheOptions
                  defaultOptions
                  isClearable
                  isMulti
                  loadOptions={async value => loadCreatorsOptions(value)}
                  onChange={setSelectedCreators}
                  placeholder="Select Creator..."
                  value={selectedCreators}
                  styles={colourStyles}
                />
              </FormGroup>
            </Col>
            <Col sm={3}>
              <FormGroup>
                <Label className="bold-text">
                  Type(s)
                </Label>
                <Select
                  isMulti
                  name="typeSelect"
                  isClearable
                  value={selectedTypes || []}
                  options={mediaContentTypeFilterOptions}
                  onChange={value => setSelectedTypes(value)}
                  placeholder="Select Type..."
                  style={{ maxHeight: '120px' }}
                  styles={colourStyles}
                />
              </FormGroup>
              <FormGroup>
                <Label className="bold-text">
                  Deal Type(s)
                </Label>
                <Select
                  isMulti
                  name="dealTypeSelect"
                  isClearable
                  value={selectedDealTypes}
                  options={dealTypeFilterOptions}
                  onChange={value => setSelectedDealTypes(value)}
                  placeholder="Select Deal Type..."
                  style={{ maxHeight: '120px' }}
                  styles={colourStyles}
                />
              </FormGroup>
            </Col>
            <Col sm={3}>
              <Label className="bold-text" style={{ marginBottom: '8px' }}>
                InfluenceLogic Status
              </Label>
              <Row>
                <Col sm={6} style={{ paddingRight: '0px' }}>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="NewStatus"
                      label="New"
                      value={selectedIlStatuses.New}
                      onChange={event => handleChangeStatusFilters(event, 'New')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="FirstScoreStatus"
                      label="First Score"
                      value={selectedIlStatuses['First Score']}
                      onChange={event => handleChangeStatusFilters(event, 'First Score')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="SecondScoreStatus"
                      label="Second Score"
                      value={selectedIlStatuses['Second Score']}
                      onChange={event => handleChangeStatusFilters(event, 'Second Score')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '0' }}
                  >
                    <CheckBoxField
                      name="RescoreStatus"
                      label="Rescore"
                      value={selectedIlStatuses.Rescore}
                      onChange={event => handleChangeStatusFilters(event, 'Rescore')}
                    />
                  </FormGroup>
                </Col>
                <Col sm={6} style={{ paddingLeft: '0px' }}>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="PendingEditsStatus"
                      label="Pending Edits"
                      value={selectedIlStatuses['Pending Edits']}
                      onChange={event => handleChangeStatusFilters(event, 'Pending Edits')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="AwaitingBrandApprovalStatus"
                      label="Approved"
                      value={selectedIlStatuses['Awaiting Brand Approval']}
                      onChange={event => handleChangeStatusFilters(event, 'Awaiting Brand Approval')}
                    />
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <CheckBoxField
                      name="BrandApprovedMatch"
                      label="Brand Approved"
                      value={selectedIlStatuses['Brand Approved']}
                      onChange={event => handleChangeStatusFilters(event, 'Brand Approved')}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col sm={3}>
              <Label className="bold-text" style={{ marginBottom: '8px' }}>
                Brand Status
              </Label>
              <Row>
                <Col sm={6} style={{ paddingRight: '0px' }}>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="NotSetBrandStatus"
                      label="Not Set"
                      value={selectedBrandStatuses['Not Set']}
                      onChange={event => handleChangeBrandStatusFilters(event, 'Not Set')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="InReviewStatus"
                      label="In Review"
                      value={selectedBrandStatuses['In Review']}
                      onChange={event => handleChangeBrandStatusFilters(event, 'In Review')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="PendingEditsBrandStatus"
                      label="Pending Edits"
                      value={selectedBrandStatuses['Pending Edits']}
                      onChange={event => handleChangeBrandStatusFilters(event, 'Pending Edits')}
                    />
                  </FormGroup>
                </Col>
                <Col sm={6} style={{ paddingRight: '0px' }}>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="ApprovedPendingEditsBrandStatus"
                      label="Approved Pending Edits"
                      value={selectedBrandStatuses['Approved Pending Edits']}
                      onChange={event => handleChangeBrandStatusFilters(event, 'Approved Pending Edits')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '10px' }}
                  >
                    <CheckBoxField
                      name="ApprovedBrandStatus"
                      label="Approved"
                      value={selectedBrandStatuses.Approved}
                      onChange={event => handleChangeBrandStatusFilters(event, 'Approved')}
                    />
                  </FormGroup>
                  <FormGroup
                    style={{ marginBottom: '0' }}
                  >
                    <CheckBoxField
                      name="RejectedBrandStatus"
                      label="Rejected"
                      value={selectedBrandStatuses.Rejected}
                      onChange={event => handleChangeBrandStatusFilters(event, 'Rejected')}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
Actions.propTypes = {
  downloadCsv: PropTypes.func.isRequired,
  setShowUpdateStatusModal: PropTypes.func.isRequired,
  selectedContentIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  setLoadingFiltersData: PropTypes.func.isRequired,
  setBulkUpdateType: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  total: state.table.total,
});

export default connect(mapStateToProps)(Actions);
