import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Modal, ButtonToolbar, Button, ModalHeader } from 'reactstrap';
import ApiClient from 'ApiClient';


const TranscriptTextModal = ({ record, showTranscriptText, setShowTranscriptText }) => {
  const [transcript, setTranscript] = useState('');
  useEffect(async () => {
    const mediaContentId = record.params?._id;
    if (showTranscriptText && mediaContentId) {
      const api = new ApiClient();
      const response = await api.client.get(`/api/portal/scorecard/media-content/get-transcription?mediaContentId=${mediaContentId}`);
      setTranscript(response.data.transcription || '');
    }
  }, [showTranscriptText, record]);

  return (
    <Modal
      isOpen={showTranscriptText}
      toggle={() => { setShowTranscriptText(false); }}
    >
      <ModalHeader
        toggle={() => { setShowTranscriptText(false); }}
        tag="h4"
        className="modal-title-statistics"
      >
        Transcript
      </ModalHeader>
      {/* eslint-disable-next-line react/no-danger */}
      <div className="modal__body" dangerouslySetInnerHTML={{ __html: transcript !== 'null' ? transcript : '' }} />
      <ButtonToolbar className="modal__footer">
        <Button className="btn btn-sm" color="secondary" onClick={() => { setShowTranscriptText(false); }}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};

TranscriptTextModal.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  showTranscriptText: PropTypes.bool.isRequired,
  setShowTranscriptText: PropTypes.func.isRequired,
};
export default TranscriptTextModal;
