/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import ViewHelpers from '@/shared/ViewHelpers';
import { Route, Switch } from 'react-router-dom';
import Dashboard from '../../../admin/Dashboard';
import Finance from '../../../admin/Finance';
import Brands from '../../../admin/Brands';
import Reports from '../../../admin/Reports';
import Layout from '../../../admin/Layout/index';
import Settings from '../../../admin/Settings';
import StripePayouts from '../../../admin/Stripe/Payouts';
import RecordAction from '../../../Show';
import ResourceAction from '../../../Resources';
import CampaignAction from '../../../admin/Podcasts/Campaign/CampaignList';
import MediaChannelAction from '../../../admin/Podcasts/MediaChannel/MediaChannelList';
// import InfluencerAction from '../../../admin/Influencer/Influencer/InfluencerList';
// import BrandUserAction from '../../../admin/Brands/BrandUser/BrandUserList';
import BrandUsers from '../../../admin/Brands/BrandUsers';
import Agencies from '../../../admin/Agents/Agency';
import AgentUserAction from '../../../admin/Agents/AgentUser/AgentUserList';
import AgentUsers from '../../../admin/Agents/Agents';
import AgentProfile from '../../../admin/Agents/AgentUser/agentProfile';
import Bulk from '../../../Bulk';
import AdminUsersList from '../../../admin/Users/AdminUser/AdminUsersList';
import StripeEventsList from '../../../admin/Stripe/StripeEvent/StripeEventsList';
import EmailTemplateList from '../../../admin/EmailSettings/EmailTemplate/EmailTemplateList';
import RedirectDomainList from '../../../admin/RedirectDomains/RedirectDomain/RedirectDomainList';
import NewRedirectDomainList from '../../../admin/RedirectDomains/manage';
import VanityUrlList from '../../../admin/RedirectDomains/VanityUrl/VanityUrlList';
import DuplicateRedirects from '../../../admin/RedirectDomains/VanityUrl/components/DuplicateRedirects';
import EmployeesList from '../../../admin/Employees/Employee/EmployeesList';
// import AdvertiserList from '../../../admin/Brands/Advertiser/AdvertiserList';
import BrandsList from '../../../admin/Brands/Brands';
import BrandResourceList from '../../../admin/Brands/BrandResource/BrandResourceList';
import BrandOpportunityList from '../../../admin/Brands/BrandOpportunity/BrandOpportunityList';
import OfferList from '../../../admin/Finance/Offer/OfferList';
import StripeAccounts from '../../../admin/Finance/PaymentMethods/Stripe';
import GeneralLedgerList from '../../../admin/Finance/GeneralLedger/GeneralLedgerList';
// import AccountPayableList from '../../../admin/Finance/AccountPayable/AccountPayableList';
// import AccountPayableListItems from '../../../admin/Finance/AccountPayable/AccountPayableListItems';
import AccountPayableList2 from '../../../admin/Finance/AccountPayableReports2/AccountPayableList';
import AccountPayableListDashboard from '../../../admin/Finance/AccountPayableReports2/AccountPayableDashboard';
import AccountReceivableListItems from '../../../admin/Finance/AccountReceivable/AccountReceivableListItems';
import PayableJournalList from '../../../admin/Finance/PayableJournal/PayableJournalList';
import AccountPayableNew from '../../../admin/Finance/AccountPayable/AccountPayableNew';
import AccountReceivableNew from '../../../admin/Finance/AccountReceivable/AccountReceivableNew';
import AccountReceivableList from '../../../admin/Finance/AccountReceivable/AccountReceivableList';
import CreatorPayment from '../../../admin/Finance/CreatorPayment';
import HubilContactList from '../../../admin/HubIL/HubilContact/HubilContactList';
import HubilContactsDashboard from '../../../admin/HubIL/HubilContacts/HubilContactsList';
import HubilAgentList from '../../../admin/HubIL/HubilAgent/HubilAgentList';
import HubilDealList from '../../../admin/HubIL/HubilDeal/HubilDealsList';
import HubILDealPipeline from '../../../admin/HubIL/HubILDealPipeline/HubilDealPipelineList';
import EmployeeCommissionReport from '../../../admin/Finance/EmployeeCommission/EmployeeCommissionsReport';
import HubILEventList from '../../../admin/HubIL/HubILEvent/HubILEventList';
import LogList from '../../../admin/Dev/Log/LogList';
import StatisticList from '../../../admin/Dev/Statistic/StatisticList';
import StatsReport from '../../../admin/Dev/Statistic/StatsReport';
import DictionaryList from '../../../admin/Dev/Dictionary/DictionaryList';
import ImpactActionsList from '../../../admin/Impact/ImpactAction/ImpactActionsList';
import ImpactActionItemsList from '../../../admin/Impact/ImpactActionItem/ImpactActionItemsList';
import InvoiceList from '../../../admin/Finance/Invoice/InvoiceList';
import ScheduledJobsList from '../../../admin/Jobs/ScheduledJobsList';
import BillList from '../../../admin/Finance/Bill/BillList';
import BillComDashboard from '../../../admin/BillCom/Dashboard';
import BillComImportVendors from '../../../admin/BillCom/ImportVendors';
import BillComInviteVendor from '../../../admin/BillCom/InviteVendor';
import MediaContentNew from '../../../admin/Scorecard/MediaContent/MediaContentNew';
import Notifications from '../../../admin/Influencer/PortalNotification';
import NotificationsDashboard from '../../../admin/Influencer/Notifications';
import CreateNotification from '../../../admin/Influencer/Notifications/CreateNotification';
import NotificationToCreator from '../../../admin/Influencer/PortalNotification/NotificationToCreators';
import ContentDashboard from '../../../admin/Scorecard/MediaContent/ContentDashboard';
// import YouTubeCommentsDashboard from '../../../admin/Scorecard/YouTubeVideoComment';
import ComplianceMetrics from '../../../admin/Scorecard/ComplianceMetrics';
import ContentReviewRules from '../../../admin/Scorecard/ContentReviewRules';
// import CommentMonitoring from '../../../admin/Scorecard/CommentMonitoring';
import AiCommentMonitoring from '../../../admin/Scorecard/AiCommentMonitoring';
import ContentURLValidationDashboard from '../../../admin/Scorecard/ContentURLValidation/ContentURLValidationDashboard';
import ContentReviewDashboard from '../../../admin/Scorecard/ContentReview/ContentReviewDashboard';
import ScorecardAnswersSheetDashboard from '../../../admin/Scorecard/ScorecardAnswersSheet/ScorecardAnswersSheetDashboard';
import MediaContentScorecardDashboard from '../../../admin/Scorecard/MediaContent/MediaContentScorecardsDashboard';
import ScorecardDashboard from '../../../admin/Scorecard/Scorecard/ScorecardDashboard';
import ScorecardSheetEdit from '../../../admin/Scorecard/ScorecardAnswersSheet/components/custom/ScorecardAnswersSheetEdit';
import ScorecardsForm from '../../../admin/Scorecard/Scorecard/ScorecardsForm';
import ServiceAgreements from '../../../admin/Influencer/ServiceAgreement';
import ServiceAgreementForm from '../../../admin/Influencer/ServiceAgreement/Form';
import CustomServiceAgreements from '../../../admin/Influencer/ServiceAgreement/CustomAgreementTemplate';
import CustomServiceAgreementTemplate from '../../../admin/Influencer/ServiceAgreement/CustomAgreementTemplate/components/Form';
import CreateBillForm from '../../../admin/Finance/Bill/CreateNewBill';
import DocuSignSettings from '../../../admin/DocuSign';
import DocuSignDownload from '../../../admin/DocuSign/Download';
import CreatorDealEditor from '../../../admin/Influencer/Influencer/components/CreatorDealEditor';
import PushNotifications from '../../../admin/Mobile/PushNotifications';
import PromoCodesReport from '../../../admin/PromoCodes/Report';
import InfluencerProfile from '../../../admin/Influencer/Influencer/influencerProfile';
import InfluencerSalesActivity from '../../../admin/Influencer/Influencer/influencerProfile/SalesActivity';
import BrandResourceNew from '../../../admin/Brands/BrandResource/BrandResourceNew';
import BrandOpportunityNew from '../../../admin/Brands/BrandOpportunity/BrandOpportunityNew';
import BrandOpportunityOrdering from '../../../admin/Brands/BrandOpportunity/BrandOpportunityOrdering';
import JobNotifications from '../../../admin/Jobs/Notifications';
import AgentCreatorsList from '../../../admin/Agents/AgentCreators/AgentCreatorsList';
import AgentBillCom from '../../../admin/Agents/AgentBillCom';
import CreatorBillCom from '../../../admin/Influencer/Influencer/influencerBillCom';
/* import SavedReport from '../../../admin/Reports/SavedReport';
import SavedReportSummary from '../../../admin/Reports/Report/SavedReport';
import SavedReportLtv from '../../../admin/Reports/LTVReport/SavedReport';
import SavedReportBase from '../../../admin/Reports/BaseReport/SavedReport';
import SavedBrandPerformanceReport from '../../../admin/Reports/BrandPerformanceReport/SavedReport';
import SavedReportLeads from '../../../admin/Reports/LeadsPerformanceReport/SavedReport';
import SummaryReport from '../../../admin/Reports/Report';
import SummaryReport2 from '../../../admin/Reports/Report2';
*/
import SummaryReport3 from '../../../admin/Reports/Report3';
import DuplicateActiveDealsReport from '../../../admin/Reports/DuplicateActiveDeals';
import Audit from '../../../admin/Reports/Audit';
import AuditPublic from '../../../admin/Reports/AuditPublic';
import AuditPublicV2 from '../../../admin/Reports/AuditPublicV2';
// import LeadsPerformanceReport from '../../../admin/Reports/LeadsPerformanceReport';
import AuditMastMonth from '../../../admin/Reports/Audit/last-month';
import AuditCurrentMonth from '../../../admin/Reports/Audit/current-month';
// import CreatorsReport from '../../../admin/Reports/CreatorsReport';
import CreatorsReport2 from '../../../admin/Reports/CreatorsReport2';
import CreatorsReportByCSP from '../../../admin/Reports/CreatorsReportBy/CreatorsReportByCSP';
import CreatorsReportByBrand from '../../../admin/Reports/CreatorsReportBy/CreatorsReportByBrand';
import CreatorsReportByCreator from '../../../admin/Reports/CreatorsReportBy/CreatorsReportByCreator';

import CreatorsReportByCSP2 from '../../../admin/Reports/CreatorsReportBy2/CreatorsReportByCSP';
import CreatorsReportByBrand2 from '../../../admin/Reports/CreatorsReportBy2/CreatorsReportByBrand';
import CreatorsReportByCreator2 from '../../../admin/Reports/CreatorsReportBy2/CreatorsReportByCreator';

// import CreatorsReportIndividual from '../../../admin/Reports/CreatorsReportBy/CreatorsReportByDeal';
import CreatorsReportIndividual2 from '../../../admin/Reports/CreatorsReportBy2/CreatorsReportByDeal';
// import SavedReportCreator from '../../../admin/Reports/CreatorsReport/SavedReport';
import LTVReport from '../../../admin/Reports/LTVReport';
import BaseReport from '../../../admin/Reports/BaseReport';
// import EOMReport from '../../../admin/Reports/EOMReport';
import EOMReport2 from '../../../admin/Reports/EOMReport2';
import EOMReport2Affiliate from '../../../admin/Reports/EOMReport2Affiliate';
import RebuyTrigger from '../../../admin/Reports/RebuyTriggers';
import CompanyReport from '../../../admin/Reports/CompanyReport';
import IssuesReport from '../../../admin/Reports/IssuesReport/Index';
import BrandPerformanceReport from '../../../admin/Reports/BrandPerformanceReport';
import Brand5CReport from '../../../admin/Reports/BrandPerformanceReport/5C';
import POWL5CsReport from '../../../admin/Reports/Management/POWL5Cs';
import Brand7CReport from '../../../admin/Reports/BrandPerformanceReport/7C';
import B7CAffiliate from '../../../admin/Reports/BrandPerformanceReport/7CAffiliate';
import SalesActivityReport from '../../../admin/Reports/SalesActivity';
import ClicksActivityReport from '../../../admin/Reports/ClicksActivity';
import VideoStatisticsReport from '../../../admin/Reports/VideoStatistics';
import YTVideoCommentsBreakdownReport from '../../../admin/Reports/YTVideoCommentsBreakdown';
import PaymentAuthorizationChecklist from '../../../admin/Reports/Compliance/PaymentAuthorizationChecklist';
import CpmConversionsList from '../../../admin/Influencer/Research/CpmConversion';
import PilotTracker from '../../../admin/Reports/PilotTracker';
import YouTubeCommentsAnalysis from '../../../admin/Reports/YouTubeCommentsAnalysis';
import BrandCommentsAnalysis from '../../../admin/Reports/BrandCommentsAnalysis';
import CreatorCommentsAnalysis from '../../../admin/Reports/CreatorCommentsAnalysis';
import VideoCommentsAnalysis from '../../../admin/Reports/VideoCommentsAnalysis';
import CalculateGrossProfit from '../../../admin/Reports/CalculateGrossProfit';
import RebuyReport from '../../../admin/Reports/RebuyReport';
import RebuyReport2 from '../../../admin/Reports/RebuyReport2';
import NewCreatorsReport from '../../../admin/Reports/NewCreatorsReport';
import NewCreatorsReport2 from '../../../admin/Reports/NewCreatorsReport2';
import TestToWinReport from '../../../admin/Reports/TestToWinReport';
import FutureNewVsExistingReport from '../../../admin/Reports/FutureNewVsExistingReport';
import SearchList from '../../../admin/Influencer/Research/Search/SearchList';
import OverlapList from '../../../admin/Influencer/Research/Overlap/OverlapList';
import FormulasList from '../../../admin/Influencer/Research/Formulas/FormulasList';
import ResearchSettingsList from '../../../admin/Influencer/Research/Settings/SettingsList';
import WatchlistsList from '../../../admin/Influencer/Research/Watchlists/WatchlistsList';
import ProspectsList from '../../../admin/Influencer/Research/Prospects/ProspectsList';
import MiningProspectsList from '../../../admin/Influencer/Research/Prospects/MiningProspectsList';
import UploadQueueList from '../../../admin/Influencer/Research/UploadQueue/UploadQueueList';
import LeadsList from '../../../admin/Influencer/Research/Leads/LeadsList';
import LeadOperationsDashboard from '../../../admin/Influencer/Research/Operations';
import RecycleLeadsList from '../../../admin/Influencer/Research/Recycle/RecycleLeadsList';
import InternalProspects from '../../../admin/Influencer/Research/InternalProspects/InternalProspectsList';
import AddCpmCreator from '../../../admin/Influencer/Research/CpmProspects/AddCpmCreator';
import CpmCreatorsList from '../../../admin/Influencer/Research/CpmProspects/CPMProspectsList';
import CpmInsertionOrderList from '../../../admin/Influencer/Research/CpmInsertionOrder/CPMInsertionOrderList';
import CpmPricingApprovals from '../../../admin/Influencer/Research/CpmPricingApprovals';
import CpmPricingApprovalsIPT from '../../../admin/Influencer/Research/CpmPricingApprovalsIPT';
import CpmPriceApprovalsGroup from '../../../admin/Influencer/Research/CpmPriceApprovalsGroup';
import CpmPriceApprovalsProspects from '../../../admin/Influencer/Research/CpmPriceApprovalsProspects';
import CPMInvoiceList from '../../../admin/Influencer/Research/CpmInvoice/CPMInvoiceList';
import CpmInsertionOrderProspectsList from '../../../admin/Influencer/Research/CpmInsertionOrderProspects/CPMInsertionOrderProspects';
import CpmInvoiceProspectsList from '../../../admin/Influencer/Research/CpmInvoiceProspects';

import ResearchCreatorAccountList from '../../../admin/Influencer/Research/ResearchCreatorAccount/ResearchCreatorAccountList';
import ResearchCreateContact from '../../../admin/Influencer/Research/ResearchCreatorAccount/ResearchCreateContact';
import EditProspect from '../../../admin/Influencer/Research/Prospects/EditProspect';
import AgentDealEditor from '../../../admin/Agents/DealEditor';
import TagManagerList from '../../../admin/Influencer/TagManager/TagManagerList';
import Conversions from '../../../admin/HasOffers/HasOffersConversions';
import CpmConversions from '../../../admin/HasOffers/CpmConversions';
import CpmConversionUpload from '../../../admin/HasOffers/CpmConversionUpload';
import NewCreatorsReports from '../../../admin/Reports/NewCreatorsReports';

import ReportsSettings from '../../../admin/Reports/Settings';
import NewWinnersReportSettings from '../../../admin/Reports/NewWinnersReport/Settings';
import AddInternalProspectRequest
  from '../../../admin/Influencer/Research/InternalProspects/components/AddInternalProspectRequest';
import GoogleApi from '../../../admin/Google';
import TubularApi from '../../../admin/Tubular/index';
import MigrationsApi from '../../../admin/Migrations/index';
import AtlasSearch from '../../../admin/Migrations/AtlasSearch';
import HubspotApi from '../../../admin/HubIL/HubilMetrics/index';
import HasOffersAPI from '../../../admin/HasOffers/HasOffersAPI/index';
import CreatorReview from '../../../admin/Scorecard/CreatorReview';
import ContentManagement from '../../../admin/Scorecard/ContentManagement';
import Extension from '../../../admin/Extension';
import ConversionsReport from '../../../admin/Reports/Category/ConversionsByCategory';
import ConversionsByCreator from '../../../admin/Reports/Category/ConversionsByCreator';
import ContactAuditReport from '../../../admin/Reports/ContactAuditReport';
import BillAuditReport from '../../../admin/Reports/BillAuditReport';
import PayableAuditReport from '../../../admin/Reports/PayableAuditReport';
import AdminTeam from '../../../admin/Users/AdminTeam';
import CreatorsDashboard from '../../../admin/Influencer/CreatorsDashboard';
import hooks from '../../../../shared/hooks';
import CpaFraudDetection from '../../../admin/Reports/Compliance/CpaFraudDetection';
import TaxForms from '../../../admin/Finance/TaxForms';
import DealMergeAuditReport from '../../../admin/Reports/Audit/DealMergeAuditReport';
import ContactMergeAuditReport from '../../../admin/Reports/Audit/ContactMergeAuditReport';
import DealContactsAudit from '../../../admin/Reports/Audit/DealContactsAudit';
import InfluencerMergeAuditReport from '../../../admin/Reports/Audit/InfluencerMergeAuditReport'
import AdminsDashboard from '../../../admin/Users/AdminsDashboard';
import NewWinnersReport from '../../../admin/Reports/NewWinnersReport';
import NewWinnersItemsReport from '../../../admin/Reports/NewWinnersItemsReport';
import Staging from '../../../admin/Staging';
import Opportunities from '../../../admin/Influencer/Research/Opportunities';

/* import OffersReport from '../../../admin/Reports/OffersReport';
import CreatorsByBrandReport from '../../../admin/Reports/CreatorsByBrandReport';
import CreatorsByOfferReport from '../../../admin/Reports/CreatorsByOfferReport'; */


const getPortalTitle = () => {
  if (window.location.href.includes('localportal.influencelogic.com')) return 'IL Admin LOCAL';
  if (window.location.href.includes('devportal.influencelogic.com')) return 'IL Admin DEV';
  return 'IL Admin';
};
const portalTitle = getPortalTitle();

const Page = (props) => {
  const [hasAccess] = hooks.useAccessHook(props.access || {});
  // console.log('HAS ACCESS', hasAccess, teams, props.access);
  useEffect(() => {
    document.title = props.title ? `${props.title} | ${portalTitle}` : portalTitle;
  }, [props.title]);
  if (props.access && !hasAccess) return null;

  return (
    <Route
      path={props.path}
      exact={props.exact}
      component={props.component}
    />
  );
};

const Admin =  () => {
  const h = new ViewHelpers();
  const resourceId = ':resourceId';
  const actionName = ':actionName';
  const recordId = ':recordId';
  const recordActionUrl = h.recordActionUrl({ resourceId, recordId, actionName });
  const resourceActionUrl = h.resourceActionUrl({ resourceId, actionName });
  console.log(recordActionUrl);
  const bulkActionUrl = h.bulkActionUrl({ resourceId, actionName });
  return (
    <div>
      <Layout />
      <div className="container__wrap">
        <Switch>
          <Page
            title="Campaigns"
            path={h.resourceActionUrl({ resourceId: 'Campaign', actionName: 'list' })}
            exact
            component={CampaignAction}
          />
          <Page
            title="Podcasts"
            path={h.resourceActionUrl({ resourceId: 'MediaChannel', actionName: 'list' })}
            exact
            component={MediaChannelAction}
          />
          <Page
            title="Admin Users"
            path={h.resourceActionUrl({ resourceId: 'AdminUser', actionName: 'list' })}
            exact
            component={AdminUsersList}
          />
          <Page
            title="Admin Users"
            path="/admin/admin-users"
            exact
            component={AdminsDashboard}
          />
          <Page
            title="Creators"
            path={h.resourceActionUrl({ resourceId: 'Influencer', actionName: 'list' })}
            exact
            component={CreatorsDashboard}
          />
          <Page
            title="Creators"
            path="/creators"
            exact
            component={CreatorsDashboard}
          />


          {/* <Page
            title="Brand Users"
            path={h.resourceActionUrl({ resourceId: 'BrandUser', actionName: 'list' })}
            exact
            component={BrandUserAction}
          /> */}
          <Page
            title="Brand Users"
            path={h.resourceActionUrl({ resourceId: 'BrandUser', actionName: 'list' })}
            exact
            component={BrandUsers}
          />
          <Page
            title="Custom Service Agreements"
            path={h.resourceActionUrl({ resourceId: 'CustomAgreementTemplate', actionName: 'list' })}
            exact
            component={CustomServiceAgreements}
          />
          <Page
            title="Agencies"
            path={h.resourceActionUrl({ resourceId: 'Agency', actionName: 'list' })}
            exact
            component={Agencies}
          />
          <Page
            title="Agent Users"
            path={h.resourceActionUrl({ resourceId: 'AgentUser', actionName: 'list' })}
            exact
            component={AgentUsers}
          />
          <Page
            title="Agent Users"
            path={'/agents'}
            exact
            component={AgentUserAction}
          />
          <Page
            title="Agent Bill.com account"
            path="/resources/AgentUser/agent-bill-com/:id"
            exact
            component={AgentBillCom}
          />
          <Page
            title="Agent Deal Editor"
            path="/resources/AgentUser/agent-deal-editor/:agentId"
            exact
            component={AgentDealEditor}
          />
          <Page
            title="Agent Deal Editor"
            path="/resources/AgentUser/agent-deal-editor"
            exact
            component={AgentDealEditor}
          />
          <Page
            title="Creator Bill.com account"
            path="/resources/Influencer/creator-bill-com/:id"
            exact
            component={CreatorBillCom}
          />
          <Page
            title="Stripe Events Log"
            path={h.resourceActionUrl({ resourceId: 'StripeEvent', actionName: 'list' })}
            exact
            component={StripeEventsList}
          />
          <Page
            title="Email Templates"
            path={h.resourceActionUrl({ resourceId: 'EmailTemplate', actionName: 'list' })}
            exact
            component={EmailTemplateList}
          />
          <Page
            title="Redirect Domains"
            path={h.resourceActionUrl({ resourceId: 'RedirectDomain', actionName: 'list' })}
            exact
            component={RedirectDomainList}
          />
          <Page
            title="New Redirect Domains"
            path='/redirectdomains/manage'
            exact
            component={NewRedirectDomainList}
          />
          <Page
            title="Vanity URLs"
            path={h.resourceActionUrl({ resourceId: 'VanityUrl', actionName: 'list' })}
            exact
            component={VanityUrlList}
          />
          <Page
            title="Duplicate VanityUrls"
            path='/vanityurls/duplicates'
            exact
            component={DuplicateRedirects}
          />
          <Page
            title="Employees"
            path={h.resourceActionUrl({ resourceId: 'Employee', actionName: 'list' })}
            exact
            component={EmployeesList}
          />
          {/* <Page
            title="Brands"
            path={h.resourceActionUrl({ resourceId: 'Advertiser', actionName: 'list' })}
            exact
            component={AdvertiserList}
          /> */}
          <Page
            title="Brands"
            path={h.resourceActionUrl({ resourceId: 'Advertiser', actionName: 'list' })}
            exact
            component={BrandsList}
          />
          <Page
            title="Brand Resources"
            path={h.resourceActionUrl({ resourceId: 'BrandResource', actionName: 'list' })}
            exact
            component={BrandResourceList}
          />
          <Page
            title="Brand Opportunities"
            path={h.resourceActionUrl({ resourceId: 'BrandOpportunity', actionName: 'list' })}
            exact
            component={BrandOpportunityList}
          />
          <Page
            title="Contacts"
            path="/influencers/contacts"
            exact
            component={HubilContactsDashboard}
          />
          <Page
            title="HubIL Contacts"
            path={h.resourceActionUrl({ resourceId: 'HubilContact', actionName: 'list' })}
            exact
            component={HubilContactList}
          />
          <Page
            title="HubIL Agents"
            path={h.resourceActionUrl({ resourceId: 'HubilAgent', actionName: 'list' })}
            exact
            component={HubilAgentList}
          />
          <Page
            title="Creator Deals"
            path={h.resourceActionUrl({ resourceId: 'HubilDeal', actionName: 'list' })}
            exact
            component={HubilDealList}
          />
          <Page
            title="Offers"
            path={h.resourceActionUrl({ resourceId: 'Offer', actionName: 'list' })}
            exact
            component={OfferList}
          />
          <Page
            title="HubIL Deal Pipelines"
            path={h.resourceActionUrl({ resourceId: 'HubilDealPipeline', actionName: 'list' })}
            exact
            component={HubILDealPipeline}
          />
          <Page
            title="Accounts Receivable"
            path={h.resourceActionUrl({ resourceId: 'AccountsReceivable', actionName: 'list' })}
            exact
            component={AccountReceivableListItems}
          />
          <Page
            title="Payable Journal"
            path={h.resourceActionUrl({ resourceId: 'PayableJournal', actionName: 'list' })}
            exact
            component={PayableJournalList}
          />
          <Page
            title="HubIL Events"
            path={h.resourceActionUrl({ resourceId: 'HubILEvent', actionName: 'list' })}
            exact
            component={HubILEventList}
          />
          <Page
            title="Dev Logs"
            path={h.resourceActionUrl({ resourceId: 'Log', actionName: 'list' })}
            exact
            component={LogList}
          />
          <Page
            title="CPM Conversions Uploads"
            path={h.resourceActionUrl({ resourceId: 'CpmConversionUpload', actionName: 'list' })}
            exact
            component={CpmConversionUpload}
          />
          <Page
            title="Dictionary"
            path={h.resourceActionUrl({ resourceId: 'Dictionary', actionName: 'list' })}
            exact
            component={DictionaryList}
          />
          <Page
            title="Statistics"
            path={h.resourceActionUrl({ resourceId: 'Statistic', actionName: 'list' })}
            exact
            component={StatisticList}
          />
          <Page
            title="Daily Conversion Totals"
            path="/statistics/total-by-brand"
            exact
            component={StatsReport}
          />
          <Page
            title="Audit Report"
            path="/reports/contact-audits"
            exact
            component={ContactAuditReport}
          />
          <Page
            title="Bill Report"
            path="/reports/bill-audits"
            exact
            component={BillAuditReport}
          />
          <Page
            title="Deal Merge Audit Report"
            path="/reports/deal-merge-audit-report"
            exact
            component={DealMergeAuditReport}
          />
          <Page
            title="Contact Merge Audit Report"
            path="/reports/contact-merge-audit-report"
            exact
            component={ContactMergeAuditReport}
          />
          <Page
            title="Deal Merge Audit Report"
            path="/reports/deal-contacts-audit-report"
            exact
            component={DealContactsAudit}
          />
           <Page
            title="Influencer Merge Audit Report"
            path="/reports/influencers-merge-audit-report"
            exact
            component={InfluencerMergeAuditReport}
          />

          <Page
            title="Payable Report"
            path="/reports/payable-audits"
            exact
            component={PayableAuditReport}
          />
          <Page
            title="Rebuy Report"
            path="/reports/rebuy-report"
            exact
            component={RebuyReport2}
          />
          <Page
            title="Rebuy Report"
            path="/reports/rebuy-report-2"
            exact
            component={RebuyReport}
          />
          <Page
            title="New Creators Report"
            path="/reports/new-creators-report"
            exact
            component={NewCreatorsReport2}
          />
          <Page
            title="New Creators Report"
            path="/reports/new-creators-report-2"
            exact
            component={NewCreatorsReport}
          />
          <Page
            title="Future New vs. Existing Report"
            path="/reports/future-new-vs-existing"
            exact
            component={FutureNewVsExistingReport}
          />
          <Page
            title="Test to Win Report"
            path="/reports/creators-test-to-win"
            exact
            component={TestToWinReport}
          />
          <Page
            title="Category Performance By Brand"
            path="/reports/category-by-brand"
            exact
            component={ConversionsReport}
          />
          <Page
            title="Category Performance By Creator"
            path="/reports/category-by-creator"
            exact
            component={ConversionsByCreator}
          />
          <Page
            title="Impact Actions"
            path={h.resourceActionUrl({ resourceId: 'ImpactAction', actionName: 'list' })}
            exact
            component={ImpactActionsList}
          />
          <Page
            title="Impact Action Items"
            path={h.resourceActionUrl({ resourceId: 'ImpactActionItem', actionName: 'list' })}
            exact
            component={ImpactActionItemsList}
          />
          <Page
            title="New AP Report"
            path="/finance/account-payable-report/new"
            exact
            component={AccountPayableNew}
          />
          <Page
            title="Accounts Payable Report"
            path="/finance/account-payable-report-2/:reportId/:type"
            exact
            component={AccountPayableListDashboard}
          />
          <Page
            title="Accounts Payable"
            path="/finance/account-payable-report-2/"
            exact
            component={AccountPayableList2}
          />
          <Page
            title="New AR Report"
            path="/finance/account-receivable-report/new"
            exact
            component={AccountReceivableNew}
          />
          <Page
            title="Accounts Receivable"
            path="/finance/account-receivable-report"
            exact
            component={AccountReceivableList}
          />
          <Page
            title="Account Receivable"
            path="/finance/account-receivable-report/:reportId"
            exact
            component={AccountReceivableList}
          />
          <Page
            title="Tax Forms Management"
            path="/finance/tax-form-management"
            exact
            component={TaxForms}
          />
          <Page
            title="Employee Commissions Report"
            path="/finance/employee-commissions-report/:payableReportId"
            exact
            component={EmployeeCommissionReport}
          />
          <Page
            title="Send Payment"
            path="/finance/creator-payment"
            exact
            component={CreatorPayment}
          />
          <Page
            title="Send Payment"
            path="/finance/creator-payment/:payableId"
            exact
            component={CreatorPayment}
          />
          <Page
            title="Stripe"
            path="/finance/payment-methods/stripe"
            exact
            component={StripeAccounts}
          />
          <Page
            title="Notifications Dashboard"
            path={h.resourceActionUrl({ resourceId: 'PortalNotification', actionName: 'list' })}
            exact
            component={Notifications}
          />
          <Page
            title="Notifications Dashboard"
            path='/notifications'
            exact
            component={NotificationsDashboard}
          />
          <Page
            title="Notifications Dashboard"
            path='/create-notification/:id'
            exact
            component={CreateNotification}
          />
          <Page
            title="Notifications Dashboard"
            path='/create-notification'
            exact
            component={CreateNotification}
          />
          <Page
            title="Creator Notifications"
            path="/resources/PortalNotification/creatorNotification/:notificationId"
            exact
            component={NotificationToCreator}
          />
          <Page
            title="Media Content"
            path="/scorecard/content-dashboard"
            exact
            component={ContentDashboard}
          />
          {/* <Page
            title="YouTube Comments Dashboard"
            path="/scorecard/comments-dashboard"
            exact
            component={YouTubeCommentsDashboard}
          />
          <Page
            title="Comment Monitoring"
            path="/scorecard/comment-monitoring"
            exact
            component={CommentMonitoring}
          /> */}
          <Page
            title="AI Comment Monitoring"
            path="/scorecard/ai-comment-monitoring"
            exact
            component={AiCommentMonitoring}
          />
          <Page
            title="Content URL Validation"
            path="/scorecard/content-url-validation"
            exact
            component={ContentURLValidationDashboard}
          />
          <Page
            title="Historical Content Review"
            path="/scorecard/content-review"
            exact
            component={ContentReviewDashboard}
          />
          <Page
            title="Creator Safety Review"
            path="/compliance/creator-safety-review"
            exact
            component={CreatorReview}
          />
          <Page
            title="Content Management"
            path="/compliance/content-management"
            exact
            component={ContentManagement}
          />
          <Page
            title="Compliance Metrics Dashboard"
            path="/compliance/metrics-dashboard"
            exact
            component={ComplianceMetrics}
          />
          <Page
            title="Content Review Rules"
            path="/compliance/content-review-rules"
            exact
            component={ContentReviewRules}
          />
          <Page
            title="Scorecards"
            path="/scorecard/scorecard-answers-dashboard/:mediaContentId"
            exact
            component={MediaContentScorecardDashboard}
          />
          <Page
            title="Scorecard Answers Sheet"
            path="/scorecard/scorecard-answers-dashboard"
            exact
            component={ScorecardAnswersSheetDashboard}
          />
          <Page
            title="Scorecards"
            path={h.resourceActionUrl({ resourceId: 'Scorecard', actionName: 'list' })}
            exact
            component={ScorecardAnswersSheetDashboard}
          />
          <Page
            title="Scorecard Definitions"
            path="/scorecard/scorecard-dashboard"
            exact
            component={ScorecardDashboard}
          />
          <Page
            title="Score Content"
            path="/scorecard/score-content"
            exact
            component={ScorecardSheetEdit}
          />
          <Page
            title="Score Content"
            path="/scorecard/score-content/:mediaContentId"
            exact
            component={ScorecardSheetEdit}
          />
          <Page
            title="Edit Scorecard"
            path="/scorecard/score-content/:mediaContentId/:answersId"
            exact
            component={ScorecardSheetEdit}
          />
          <Page
            title="Submit Content to Score"
            path="/scorecard/media-content/new"
            exact
            component={MediaContentNew}
          />
          <Page
            title="Edit Media Content"
            path="/scorecard/media-content/edit/:mediaContentId"
            exact
            component={MediaContentNew}
          />
          <Page
            title="Submit Content to Score"
            path={h.resourceActionUrl({ resourceId: 'MediaContent', actionName: 'new' })}
            exact
            component={MediaContentNew}
          />
          <Page
            title="General Ledgers"
            path={h.resourceActionUrl({ resourceId: 'GeneralLedger', actionName: 'list' })}
            exact
            component={GeneralLedgerList}
          />
          <Page
            title="Invoices"
            path={h.resourceActionUrl({ resourceId: 'Invoice', actionName: 'list' })}
            exact
            component={InvoiceList}
          />
          <Page
            title="Jobs"
            path={h.resourceActionUrl({ resourceId: 'ScheduledJob', actionName: 'list' })}
            exact
            component={ScheduledJobsList}
          />
          <Page
            title="Bills"
            path={h.resourceActionUrl({ resourceId: 'Bill', actionName: 'list' })}
            exact
            component={BillList}
          />
          {/* <Page
            title="Bills"
            path="/finance/bills"
            exact
            component={BillList2}
          /> */}
          <Page
            title="Add New Scorecard"
            path={h.resourceActionUrl({ resourceId: 'Scorecard', actionName: 'new' })}
            exact
            component={ScorecardsForm}
          />
          <Page
            title="Edit Scorecard"
            path="/resources/Scorecard/records/:resourceId/edit"
            exact
            component={ScorecardsForm}
          />
          <Page
            title="Create Bill"
            path="/finance/Bill/new"
            exact
            component={CreateBillForm}
          />
          <Page
            title="Edit Bill"
            path="/finance/Bill/:billId"
            exact
            component={CreateBillForm}
          />
          <Page
            title="Create Brand Resource"
            path="/brand-resources/new"
            exact
            component={BrandResourceNew}
          />
          <Page
            title="Edit Brand Resource"
            path="/brand-resources/edit/:resourceId"
            exact
            component={BrandResourceNew}
          />
          <Page
            title="Create Brand Opportunity"
            path="/brand-opportunities/new"
            exact
            component={BrandOpportunityNew}
          />
          <Page
            title="Edit Brand Opportunity"
            path="/brand-opportunities/edit/:opportunityId"
            exact
            component={BrandOpportunityNew}
          />
          <Page
            title="Change Order of Brand Opportunities"
            path="/brand-opportunities/ordering"
            exact
            component={BrandOpportunityOrdering}
          />
          <Page
            title="New Creators Report"
            path="/reports/new-creator-report"
            exact
            component={NewCreatorsReports}
          />
          <Page
            title="Settings"
            path="/reports/settings"
            exact
            component={ReportsSettings}
          />
          <Page
            title="New Winners Report Settings"
            path="/reports/new-winners-report-settings"
            exact
            component={NewWinnersReportSettings}
          />

          <Page
            title="New Winners Report - Leaderboard"
            path="/reports/new-winners"
            exact
            component={NewWinnersReport}
          />
          <Page
            title="New Winners Report - New Winners"
            path="/reports/new-winners-table"
            exact
            component={NewWinnersItemsReport}
          />
          {/* <Page
            title="Saved Creator Performance Report"
            path="/reports/saved-report/creatorReport/:id"
            exact
            component={SavedReportCreator}
          />
          <Page
            title="Saved Brand Performance Report"
            path="/reports/saved-report/brandPerformance/:id"
            exact
            component={SavedBrandPerformanceReport}
          />
          <Page
            title="Saved Creator Performance Report"
            path="/reports/saved-report/creatorReportByDates/:id"
            exact
            component={SavedReportCreator}
          />
          <Page
            title="Saved Gross Revenue & Gross Profit Summary Report"
            path="/reports/saved-report/summaryReport/:id"
            exact
            component={SavedReportSummary}
          />
          <Page
            title="Saved Creator Lifetime Value Report"
            path="/reports/saved-report/ltvReport/:id"
            exact
            component={SavedReportLtv}
          />
          <Page
            title="Saved Base Performance Report"
            path="/reports/saved-report/baseReport/:id"
            exact
            component={SavedReportBase}
          />
          <Page
            title="Saved Leads Performance Report"
            path="/reports/saved-report/leadsPerformance/:id"
            exact
            component={SavedReportLeads}
          />
          <Page
            title="Saved Reports"
            path="/reports/saved-report/"
            exact
            component={SavedReport}
          />
          <Page
            title="Gross Revenue & Gross Profit Summary"
            path="/reports/summary-report"
            exact
            component={SummaryReport}
          />
          <Page
            title="Gross Revenue & Gross Profit Summary"
            path="/reports/summary-report2"
            exact
            component={SummaryReport2}
          /> */}
          <Page
            title="Gross Revenue & Gross Profit Summary"
            path="/reports/summary-report3"
            exact
            component={SummaryReport3}
          />
          {/* <Page
              title="Finance EOM Revenue & Costs"
              path="/reports/eom-report"
              exact
              component={EOMReport}
            /> */}
          <Page
            title="Finance EOM Revenue & Costs"
            path="/reports/eom-report2"
            exact
            component={EOMReport2}
          />
          <Page
            title="Finance EOM Revenue & Costs"
            path="/reports/eom-report-affiliate"
            exact
            component={EOMReport2Affiliate}
          />
          <Page
            title="Conversions Audit Report"
            path="/reports/eom-issues-report"
            exact
            component={IssuesReport}
          />
          <Page
            title="Conversions Audit Report"
            path="/reports/conversions-audit-report"
            exact
            component={IssuesReport}
          />
          <Page
            title="Vanity URLs Audit"
            path="/reports/vanityurls"
            exact
            component={DuplicateRedirects}
          />
          {/* <Page
              title="Creator Performance"
              path="/reports/creators-report"
              exact
              component={CreatorsReport}
          /> */}
          <Page
            title="Creator Performance"
            path="/reports/creators-report2"
            exact
            component={CreatorsReport2}
          />
          <Page
            title="Creator Performance By Account Manager"
            path="/reports/creators-report-by-csp"
            exact
            component={CreatorsReportByCSP2}
          />
          <Page
            title="Creator Performance By Brand"
            path="/reports/creators-report-by-brand"
            exact
            component={CreatorsReportByBrand2}
          />
          <Page
            title="Creator Performance By Creator"
            path="/reports/creators-report-by-creator"
            exact
            component={CreatorsReportByCreator2}
          />
          <Page
            title="Creator Performance By Deal Owner"
            path="/reports/creators-report-by-csp2"
            exact
            component={CreatorsReportByCSP}
          />
          <Page
            title="Creator Performance By Brand"
            path="/reports/creators-report-by-brand2"
            exact
            component={CreatorsReportByBrand}
          />
          <Page
            title="Creator Performance By Creator"
            path="/reports/creators-report-by-creator2"
            exact
            component={CreatorsReportByCreator}
          />

          <Page
            title="Creator Deals"
            path="/reports/creators-report-deals/:influencerId/:startDate/:endDate"
            exact
            component={CreatorsReportIndividual2}
          />
          <Page
            title="Creator Lifetime Value Report"
            path="/reports/ltv-report"
            exact
            component={LTVReport}
          />
          <Page
            title="Base Performance Report"
            path="/reports/base-report"
            exact
            component={BaseReport}
          />
          <Page
            title="Audit"
            path="/reports/audit"
            exact
            component={Audit}
          />
          <Page
            title="Audit Report"
            path="/reports/audit-public"
            exact
            component={AuditPublic}
          />
          <Page
            title="Audit Report"
            path="/reports/audit-public-v2"
            exact
            component={AuditPublicV2}
          />
          <Page
            title="Audit Last Month"
            path="/reports/audit-last-month"
            exact
            component={AuditMastMonth}
          />
          <Page
            title="Audit Current Month"
            path="/reports/audit-current-month"
            exact
            component={AuditCurrentMonth}
          />
          <Page
            title="Duplicate Active Deals"
            path="/reports/duplicate-active-deals"
            exact
            component={DuplicateActiveDealsReport}
          />
          {/* <Page
            title="Leads Performance"
            path="/reports/leads-report"
            exact
            component={LeadsPerformanceReport}
          /> */}
          <Page
            title="Impact Sales Report"
            path="/reports/sales-activity"
            exact
            component={SalesActivityReport}
          />
          <Page
            title="Impact Clicks Report"
            path="/reports/clicks-activity"
            exact
            component={ClicksActivityReport}
          />
          <Page
            title="Video Statistics Report"
            path="/reports/video-statistics"
            exact
            component={VideoStatisticsReport}
          />
          <Page
            title="YouTube Video Comments Breakdown"
            path="/reports/yt-video-comments-breakdown"
            exact
            component={YTVideoCommentsBreakdownReport}
            access={{ hideWhenTeam: ['Auditors'] }}
          />
          <Page
            title="Payment Authorization"
            path="/reports/compliance/payment-authorization-report"
            exact
            component={PaymentAuthorizationChecklist}
          />
          <Page
            title="CPA Fraud Detection"
            path="/reports/compliance/cpa-fraud-detection"
            exact
            component={CpaFraudDetection}
          />
          <Page
            title="CPM Conversions"
            path={h.resourceActionUrl({ resourceId: 'CpmConversion', actionName: 'list' })}
            exact
            component={CpmConversionsList}
          />
          <Page
            title="YouTube Comments Analysis"
            path="/reports/youtube-comments-analysis"
            exact
            component={YouTubeCommentsAnalysis}
            access={{ hideWhenTeam: ['Auditors'] }}
          />
          <Page
            title="Brand Comments Analysis"
            path="/reports/brand-comments-analysis"
            exact
            component={BrandCommentsAnalysis}
            access={{ hideWhenTeam: ['Auditors'] }}
          />
          <Page
            title="Creator Comments Analysis"
            path="/reports/creator-comments-analysis"
            exact
            component={CreatorCommentsAnalysis}
            access={{ hideWhenTeam: ['Auditors'] }}
          />
          <Page
            title="Video Comments Analysis"
            path="/reports/video-comments-analysis"
            exact
            component={VideoCommentsAnalysis}
            access={{ hideWhenTeam: ['Auditors'] }}
          />
          <Page
            title="Brand Performance"
            path="/reports/brand-performance"
            exact
            component={BrandPerformanceReport}
          />
          <Page
            title="Pilot Tracker"
            path="/reports/pilot-tracker"
            exact
            component={PilotTracker}
          />
          <Page
            title="5Cs"
            path="/reports/5c"
            exact
            component={B7CAffiliate}
          />
          <Page
            title="5Cs"
            path="/reports/5c-a"
            exact
            component={Brand7CReport}
          />
          <Page
            title="POWL 5Cs"
            path="/reports/management/powl-5cs"
            exact
            component={POWL5CsReport}
          />
          <Page
            title="7Cs"
            path="/reports/7c"
            exact
            component={Brand5CReport}
          />
          <Page
            title="Calculate Gross Profit"
            path="/reports/calculate-gross-profit"
            exact
            component={CalculateGrossProfit}
          />
          <Page
            title="Company Report"
            path="/reports/company-report"
            exact
            component={CompanyReport}
          />
          <Page
            title="Rebuy Triggers"
            path="/reports/rebuy-triggers"
            exact
            component={RebuyTrigger}
          />
          <Page
            path={resourceActionUrl}
            exact
            component={ResourceAction}
          />
          <Page
            path={recordActionUrl}
            exact
            component={RecordAction}
          />
          <Page
            path={bulkActionUrl}
            exact
            component={Bulk}
          />
          <Page
            title="Home"
            path="/"
            exact
            component={Dashboard}
          />
          <Page
            title="Dashboard"
            path="/dashboard"
            exact
            component={Dashboard}
          />
          <Page
            title="Brands"
            path="/brands"
            exact
            component={Brands}
          />
          <Page
            title="Finance"
            path="/finance"
            exact
            component={Finance}
          />
          <Page
            title="Reports"
            path="/reports"
            exact
            component={Reports}
          />
          <Page
            title="Account Settings"
            path="/settings"
            exact
            component={Settings}
          />
          <Page
            title="Stripe Transfers"
            path="/stripe/payouts"
            exact
            component={StripePayouts}
          />
          <Page
            title="Bill.com Dashboard"
            path="/billCom/dashboard"
            exact
            component={BillComDashboard}
          />
          <Page
            title="Import New Vendors"
            path="/billCom/import-vendors"
            exact
            component={BillComImportVendors}
          />
          <Page
            title="Invite to Bill.com"
            path="/billCom/invite/:influencerId/:userType"
            exact
            component={BillComInviteVendor}
          />
          <Page
            title="Creator Service Agreements"
            path="/service-agreements/:influencerId"
            exact
            component={ServiceAgreements}
          />
          <Page
            title="Edit Service Agreement"
            path="/service-agreements/edit/:id"
            exact
            component={ServiceAgreementForm}
          />
          <Page
            title="Send Creator Service Agreement"
            path="/send-agreement/:influencerId"
            exact
            component={ServiceAgreementForm}
          />
          <Page
            title="Custom Service Agreement Template"
            path="/custom-agreement-template/:id"
            exact
            component={CustomServiceAgreementTemplate}
          />
          <Page
            title="DocuSign Settings"
            path="/docusign-settings"
            exact
            component={DocuSignSettings}
          />
          <Page
            title="Download Agreements"
            path="/docusign/download"
            exact
            component={DocuSignDownload}
          />
          <Page
            title="Push Notifications"
            path="/mobile/push-notifications"
            exact
            component={PushNotifications}
          />
          <Page
            title="Promo Codes"
            path="/promocodes"
            exact
            component={PromoCodesReport}
          />
          <Page
            title="Creator Profile"
            path="/influencer/profile/:influencerId"
            // path={h.resourceActionUrl({ resourceId: 'Influencer', actionName: 'profile' })}
            exact
            component={InfluencerProfile}
          />
          <Page
            title="Creator Sales Activity"
            path="/influencer/profile/sales-activity/:influencerId"
            exact
            component={InfluencerSalesActivity}
          />
          <Page
            title="Creator Deal Editor"
            path="/influencers/creator-deal-editor"
            exact
            component={CreatorDealEditor}
          />
          <Page
            title="Edit Deal"
            path="/influencers/creator-deal-editor/:dealId"
            exact
            component={CreatorDealEditor}
          />
          <Page
            title="Job Notifications"
            path="/job-notifications/:jobId"
            exact
            component={JobNotifications}
          />
          <Page
            title="Agent Profile"
            path="/agent/profile/:agentId"
            exact
            component={AgentProfile}
          />
          <Page
            title="Agent's Creators"
            path="/agents/:agentId/creators"
            exact
            component={AgentCreatorsList}
          />
          <Page
            title="Creator Search"
            path="/research/search"
            exact
            component={SearchList}
          />
          <Page
            title="Audience Overlap"
            path="/research/overlap/:creatorId"
            exact
            component={OverlapList}
          />
          <Page
            title="Creator Watchlists"
            path="/research/creators"
            exact
            component={ResearchCreatorAccountList}
          />
          <Page
            title="Research Formulas"
            path="/research/formulas"
            exact
            component={FormulasList}
          />
          <Page
            title="Research Watchlists"
            path="/research/watchlists"
            exact
            component={WatchlistsList}
          />
          <Page
            title="Prospects"
            path="/research/prospects"
            exact
            component={ProspectsList}
          />
          <Page
            title="Mining"
            path="/research/mining"
            exact
            component={MiningProspectsList}
          />
          <Page
            title="Upload Queue"
            path="/research/upload-queue"
            exact
            component={UploadQueueList}
          />
          <Page
            title="Import Leads"
            path="/research/leads"
            exact
            component={LeadsList}
          />
          <Page
            title="Internal Prospects"
            path="/research/internal-prospects"
            exact
            component={InternalProspects}
          />
          <Page
            title="Add Internal Prospect Request"
            path="/research/add-internal-prospect-request"
            exact
            component={AddInternalProspectRequest}
          />
          <Page
            title="CPM Deals"
            path="/research/cpmcreators"
            exact
            component={CpmCreatorsList}
          />
          <Page
            title="CPM Insertion Order"
            path="/research/cpm-insertion-order"
            exact
            component={CpmInsertionOrderList}
          />
          <Page
            title="Price Approvals"
            path="/research/cpm-pricing-approvals"
            exact
            component={CpmPricingApprovals}
          />
          <Page
            title="Price Approvals IPT"
            path="/research/cpm-pricing-approvals-ipt"
            exact
            component={CpmPricingApprovalsIPT}
          />
          <Page
            title="Price Approvals Group"
            path="/research/cpm-price-approvals-groups"
            exact
            component={CpmPriceApprovalsGroup}
          />
          <Page
            title="Price Approvals Group"
            path="/research/cpm-price-approvals-group-prospects/:cpmPriceAppGroupId"
            exact
            component={CpmPriceApprovalsProspects}
          />
          <Page
            title="CPM Invoice"
            path="/research/cpm-invoice"
            exact
            component={CPMInvoiceList}
          />
          <Page
            title="Add CPM Deal"
            path="/research/add-cpm-creator"
            exact
            component={AddCpmCreator}
          />
          <Page
            title="Insertion Order"
            path="/research/insertion-order-prospects/:cpmInsertionOrderId"
            exact
            component={CpmInsertionOrderProspectsList}
          />
          <Page
            title="Invoice"
            path="/research/invoice-prospects/:cpmInvoiceId"
            exact
            component={CpmInvoiceProspectsList}
          />

          <Page
            title="Lead Operations Dashboard"
            path="/research/lead-operations-dashboard"
            exact
            component={LeadOperationsDashboard}
          />
          <Page
            title="Recycle Leads"
            path="/research/recycle-leads"
            exact
            component={RecycleLeadsList}
          />
          <Page
            title="Research Settings"
            path="/research/settings"
            exact
            component={ResearchSettingsList}
          />
          <Page
            title="Create HubSpot Contact"
            path="/research/create-hs-contact/:researchCreatorId"
            exact
            component={ResearchCreateContact}
          />
          <Page
            title="Edit Prospect"
            path="/research/prospects/edit/:prospectId"
            exact
            component={EditProspect}
          />
          <Page
            title="Tag Manager"
            path="/influencer/tag-manager"
            exact
            component={TagManagerList}
          />
          <Page
            title="Conversions"
            path="/has-offers/conversions"
            exact
            component={Conversions}
          />
          <Page
            title="CPM Conversions"
            path="/has-offers/cpm-conversions"
            exact
            component={CpmConversions}
          />
          <Page
            title="Chrome Extension"
            path="/extension"
            exact
            component={Extension}
          />
          <Page
            title="Google API"
            path="/google/api"
            exact
            component={GoogleApi}
          />
          <Page
            title="Tubular API"
            path="/tubular/api"
            exact
            component={TubularApi}
          />
          <Page
            title="Hubspot API"
            path="/hubspot/api"
            exact
            component={HubspotApi}
          />
          <Page
            title="HasOffers API"
            path="/has-offers/api"
            exact
            component={HasOffersAPI}
          />
          <Page
            title="Migrations"
            path="/migrations"
            exact
            component={MigrationsApi}
          />
          <Page
            title="Atals Search"
            path="/atlas-search"
            exact
            component={AtlasSearch}
          />

          <Page
            title="Admin Teams"
            path="/admin/teams"
            exact
            component={AdminTeam}
          />
          <Page
            title="Staging page"
            path="/staging"
            exact
            component={Staging}
          />
           <Page
            title="Opportunities"
            path="/opportunities"
            exact
            component={Opportunities}
          />
        </Switch>
      </div>
    </div>
  );
};

export default Admin;
