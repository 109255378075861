import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardTitle,
  Nav,
  NavItem,
  NavLink,

} from 'reactstrap';
import moment from 'moment';
import { axios } from 'ApiClient';
import { useAsyncDebounce } from 'react-table';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Table from './custom/Table';
import Actions from '../../NewWinnersItemsReport/components/custom/Actions';
import Breadcrumb from '@/shared/components/BreadCrumbs';
import filterProperties from '../../NewWinnersItemsReport/components/custom/ActionProperties';
import Hook from '@/shared/hooks';
import { getConstant } from '@/shared/helpers/WVConstants';


const ContainerD = ({
  history,
}) => {
  const [brandOptions, setBrandOptions] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [date, setDate] = useState(null);
  const dealstages = (getConstant('deal', 'pipelines').find(p => p.label === 'Main Pipeline')).stages;
  const [searchUrl, setPrefill, loadPage, , useLoadOrDebounce] = Hook.usePrefillHook({
    loadTableUrl: '/api/portal/reports/get-new-winners-report',
    initialState: {
      sortBy: 'createdAt',
      direction: 'asc',
      page: 1,
      perPage: 10,
    },
    urlModify: (search) => {
      search.set('sortBy', 'count');
      search.set('direction', 'desc');
      return search;
    },
  });
  const getWinnersDate = async () => {
    const data = await axios.get('/api/portal/reports/get-new-wins-report-date');
    setDate(data.data.date);
    return data.data.date;
  };
  const fetchBrandOptions = async () => {
    try {
      const resp = await axios.get('/api/portal/scorecard/media-content/get-filters-data-set');
      if (resp?.data?.success) {
        setBrandOptions(resp?.data?.brandOptions);
      } else {
        throw new Error(resp?.data?.error);
      }
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    axios.get('/api/portal/finance/get-deal-owners').then((resp) => {
      setEmployees(resp.data.dealOwners.map((a) => ({
        value: String(a.affiliateManagerId),
        label: `${a.firstName} ${a.lastName}`,
      })));
    }).catch(() => {
      setEmployees([]);
    });
  }, []);

  useEffect(() => {
    fetchBrandOptions();
    getWinnersDate();
  }, []);

  useEffect(() => {
    if (!brandOptions.length || !employees.length) return;
    setPrefill(filterProperties({
      brandOptions,
      employees,
      dealstages: Object.keys(dealstages).map(d => ({ value: d, label: dealstages[d] })),
    }));
  }, [brandOptions, employees]);

  const setUrl = () => {
    loadPage();
  };
  const downloadCsv = () => {
    window.open(`/api/portal/reports/get-new-winners-report-csv?${searchUrl}`, '_blank').focus();
  };
  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 1000);

  useLoadOrDebounce({
    loadFunc: () => loadPage(), loadDebounceFunc: () => setUrlDebounced(), setSearchUrl: () => history.push(`${history.location.pathname}?${searchUrl}`)
  })

  console.log(searchUrl, 'SEARCH');
  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'Reports', path: null },
          { title: 'Management', path: null },
          { title: 'New Winners Report', path: null },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>New Winners Report</h3>
              </Col>
              <Col/>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Row>
            <Col>
              <div className='mb-3'>
                {date ? (<span className='text-muted'>Last Updated on <span className='font-weight-bold'>{moment(date).format('MM/DD/YYYY hh:mm A')}</span></span>) : null}
              </div>
              {searchUrl !== null ? <Actions
                type="leaderBoard"
                downloadCsv={downloadCsv}
                getWinnersDate={getWinnersDate}
              /> : null}
              <Row className="mt-3">
                <Col className="tabs">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className="active"

                      >
                        Leaderboard
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className=""
                        onClick={() => {
                          const currentUrlParams = new URLSearchParams(window.location.search);
                          currentUrlParams.set('sortBy', 'winnerDatetime');
                          currentUrlParams.set('direction', 'desc');
                          history.push(`/reports/new-winners-table?${currentUrlParams.toString()}`);
                        }}
                      >
                        New Winners
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
              <Row>
                {searchUrl !== null ? <Table /> : null}
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};
ContainerD.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

export default withRouter(ContainerD);
