import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardTitle,
  InputGroup, Input, InputGroupAddon, InputGroupText, Button,
} from 'reactstrap';
import { unflatten } from 'flat';
import { useAsyncDebounce } from 'react-table';
import withNotice from '../../../App/store/with-notice';
import { NoResourceError } from '../../../../shared/components/components/ui/error404';
import Breadcrumbs from '../../../../shared/components/BreadCrumbs';
import MatTableList from '../../../../shared/tables/materialTable/MatTableList';
import UserPropertyType from '../../Impact/ImpactActionItem/components/custom';
import WVFormatter from '../../../../shared/helpers/WVFormatter';
import TableCellContextMenu from '../../Scorecard/MediaContent/components/custom/TableCellContextMenu';
import DeleteModalDialog from './components/custom/DeleteModalDialog';
import EditModalDialog from './components/custom/EditModalDialog';
import MergeModalDialog from './components/custom/MergeModalDialog';
import HighLight from '../../../../shared/components/HighLight';


const TagManagerList = ({
  resources,
  history,
}) => {
  const [searchValue, setSearchValue] = useState(new URLSearchParams(window.location.search).get('filters.title') || '');
  const [tag, setTag] = useState('');
  const [sortBy] = useState('tagName');
  const [direction] = useState('asc');
  const [page] = useState(Number(new URLSearchParams(window.location.search).get('page')) || 1);
  const [matTableVersion, setMatTableVersion] = useState(new Date().toString());
  const [type, setType] = useState('creator');
  const [typeFilter, setTypeFilter] = useState({ type: { creator: true, agent: false } });
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [tagIdToDelete, setTagIdToDelete] = useState('');
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editRecord, setEditRecord] = useState({});
  const [mergeModalVisible, setMergeModalVisible] = useState(false);
  const [mergeRecord, setMergeRecord] = useState({});

  const startSearch = useAsyncDebounce(() => {
    const search = new URLSearchParams(window.location.search);
    search.delete('filters.tagName');
    if (searchValue && searchValue.length > 0) search.append('filters.tagName', searchValue);
    search.set('page', '1');

    history.push(`${history.location.pathname}?${search.toString()}`);
  }, 500);

  const reloadGrid = () => {
    setMatTableVersion(new Date().toString());
  };

  useEffect(async () => {
    // reloadGrid();
  }, [type]);

  useEffect(async () => {
    startSearch();
  }, [searchValue]);

  const onSearchChange = (event) => {
    setSearchValue(event?.target?.value);
    return true;
  };

  useEffect(async () => {
    setTypeFilter({
      type: {
        creator: type === 'creator',
        agent: type === 'agent',
      },
    });
  }, [type]);

  const customColumns = useMemo(() => [
    {
      Header: 'Tag Name',
      accessor: () => null,
      id: 'tagName',
      minWidth: 550,
      maxWidth: 1000,
      Cell: record => (
        <div
          className="d-flex"
        >
          <HighLight searchParam="tagName">{record.row.original.params.tagName}</HighLight>
        </div>
      ),
      disableSortBy: false,
    },
    {
      Header: `No. ${type === 'creator' ? 'Creators' : 'Agents'}`,
      accessor: () => null,
      id: `numberof${type}s`,
      minWidth: 70,
      maxWidth: 70,
      className: 'align-middle text-center',
      Cell: (record) => {
        const params = unflatten(record.row.original.params);
        return (
          <div>
            {(params[`${type}s`].length)}
          </div>
        );
      },
      disableSortBy: false,
    },
    {
      Header: 'Created Date',
      accessor: () => null,
      id: 'createdAt',
      minWidth: 70,
      maxWidth: 70,
      className: 'align-middle text-center',
      Cell: record => (
        <div>
          {WVFormatter.getFormatedDate(record.row.original.params.createdAt)}
        </div>
      ),
      disableSortBy: false,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'actions',
      maxWidth: 30,
      Cell: (record) => {
        const contextMenuOptions = [
          { label: 'Rename', handler: () => { setEditRecord(record.row.original.params); setEditModalVisible(true); } },
          { label: 'Delete', handler: () => { setTagIdToDelete(record.row.original.params._id); setDeleteModalVisible(true); } },
          { label: 'Merge', handler: () => { setMergeRecord(record.row.original.params); setMergeModalVisible(true); } },
        ];
        if (record.row.original.params.type === 'creator') {
          contextMenuOptions.push({ label: 'View Creators', handler: () => { window.open(`/resources/Influencer/actions/list/?filters.tags=${record.row.original.params.tagName}`); } });
        }
        return (
          <span>
            <TableCellContextMenu options={contextMenuOptions} />
          </span>
        );
      },
      disableSortBy: true,
      className: 'align-middle',
    },
  ], [type]);

  const resource = resources.find(r => r.id === 'UserTag');
  if (!resource) {
    return (<NoResourceError resourceId="UserTag" />);
  }
  const listAction = resource.resourceActions.find(r => r.name === 'list');

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            links={[
              { title: 'Home', path: '/' },
              { title: 'Creators', path: '/resources/Influencer/actions/list' },
              { title: 'Tag manager', path: false },
            ]}
            isBackButton
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody style={{ padding: '2rem 2rem 0 2rem' }}>
              <CardTitle>
                <Row className="pb-1">
                  <Col md="4" className="pt-1">
                    <h3>Tag Manager</h3>
                  </Col>
                </Row>
                <hr className="mt-2 mb-4" />
                <Row>
                  <Col xs="3">
                    <div dir="ltr" role="group" className="btn-group">
                      <button className={`btn btn-outline-primary ${type === 'creator' ? 'active' : ''}`} onClick={() => setType('creator')}>Creators</button>
                      <button className={`btn btn-outline-primary ${type === 'agent' ? 'active' : ''}`} onClick={() => setType('agent')}>Agents</button>
                    </div>
                  </Col>
                  <Col xs="3" />
                  <Col xs="2" style={{ textAlign: 'right', paddingTop: '2px' }}>
                    <Button
                      className="btn btn-primary"
                      color="primary"
                      onClick={() => { setEditRecord({}); setEditModalVisible(true); }}
                    >
                      Create Tag
                    </Button>
                  </Col>
                  <Col xs="4">
                    <InputGroup>
                      <Input
                        name="search"
                        type="text"
                        autoComplete="off"
                        placeholder="Search by Tag Name"
                        value={searchValue}
                        onChange={onSearchChange}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          <i
                            className="fa fa-search"
                            aria-hidden="true"
                            style={{ color: '#70bbfd', fontSize: 18 }}
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
              </CardTitle>
              <div style={{ margin: '0 -30px' }}>
                <MatTableList
                  filters={typeFilter}
                  action={listAction}
                  resource={resource}
                  sortBy={sortBy}
                  direction={direction}
                  setTag={setTag}
                  date={new Date().toString()}
                  UserPropertyType={UserPropertyType}
                  tag={tag}
                  v={2}
                  hideToolbar
                  showTitle={false}
                  page={page}
                  useCheckboxes
                  // checkboxHandler={handleSelectCheckbox}
                  customColumns={customColumns}
                  noActions
                  version={matTableVersion}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DeleteModalDialog
        isOpen={deleteModalVisible}
        id={tagIdToDelete}
        toggleModal={() => setDeleteModalVisible(false)}
        onDeleteRecordFunc={reloadGrid}
      />
      <EditModalDialog
        isOpen={editModalVisible}
        toggleModal={() => setEditModalVisible(false)}
        record={editRecord}
        onUpdateRecordFunc={reloadGrid}
        type={type}
      />
      <MergeModalDialog
        isOpen={mergeModalVisible}
        toggleModal={() => setMergeModalVisible(false)}
        record={mergeRecord}
        onUpdateRecordFunc={reloadGrid}
      />
    </Container>
  );
};
TagManagerList.propTypes = {
  // addNotice: PropTypes.func.isRequired,
  resources: PropTypes.arrayOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({

  resources: state.resources,
});

export default connect(mapStateToProps)(withNotice(TagManagerList));
