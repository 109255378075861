import React, { useMemo } from 'react';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import ScoreActionInList from './ScoreActionInList';
import AdminUserInList from './AdminUserInList';
import StatusInList from './StatusInList';
import NameInList from './NameInList';
import { currentTimezoneAggr } from '../../../../../../shared/helpers/Dates';
import WVFormatter from '../../../../../../shared/helpers/WVFormatter';
import mapMediaTypeIcons from './mapMediaTypeIcons';
import BrandApprovedDateField from './BrandApprovedDateField';

const mapType = mapMediaTypeIcons();

export default function TableColumns(setVersion) {
  const { teams } = window.REDUX_STATE.session;
  const isEditAllowed = teams && teams.length > 0 && (teams.includes('compliance') || teams.includes('super_powers'));
  return [
    {
      Header: '',
      accessor: () => null,
      id: 'scoreAction',
      Cell: record => (
        <div style={{ width: '80px' }}>
          <ScoreActionInList
            record={record.row.original}
          />
        </div>
      ),
      className: 'align-middle',
      disableSortBy: true,
      width: 80,
    },
    {
      Header: 'Submitted By',
      accessor: p => (p.populated?.submitterAdminUserId?.params?.firstName),
      id: 'submitterAdminUserId',
      Cell: record => (
        <div style={{ paddingRight: '10px' }}>
          <AdminUserInList
            record={record.row.original}
          />
        </div>
      ),
      disableSortBy: false,
      className: 'align-middle',
      width: 120,
    },
    {
      Header: 'Account Manager',
      accessor: p => (p.populated?.accountManagerId?.params?.firstName),
      id: 'accountManagerId',
      Cell: record => (
        <div style={{ paddingRight: '10px' }}>
          <AdminUserInList
            record={record.row.original}
            type="accountManager"
          />
        </div>
      ),
      disableSortBy: true,
      className: 'align-middle',
      width: 120,
    },
    {
      Header: 'Creator',
      accessor: p => (p.params?.creatorFullName),
      id: 'creatorFullName',
      Cell: record => (
        <div style={{ paddingRight: '10px' }}>
          <a
            target="_blank"
            rel="noreferrer"
            href={`/influencer/profile/${record.row.original.params.creatorId}`}
          >
            {record.row.original.params.creatorFullName}
          </a>
        </div>
      ),
      disableSortBy: false,
      className: 'align-middle',
      width: 120,
    },
    {
      Header: 'Status',
      accessor: () => null,
      id: 'status',
      Cell: record => (
        <div style={{ width: '120px', paddingRight: '10px' }}>
          <StatusInList
            record={record.row.original}
          />
        </div>
      ),
      className: 'align-middle',
      disableSortBy: false,
      width: 120,
    },
    {
      Header: () => (
        <span> Details
          <Tooltip title="Deal Name, Video Title">
            <IconButton size="small">
              <i className="fa fa-info-circle" style={{ fontSize: '0.8em' }} />
            </IconButton>
          </Tooltip>
        </span>), //
      accessor: () => null,
      id: 'name',
      Cell: record => (
        <div style={{ paddingRight: '10px' }}>
          <NameInList
            record={record.row.original}
          />
        </div>
      ),
      className: 'align-middle',
      disableSortBy: false,
      width: 250,
    },
    {
      Header: 'Type',
      accessor: p => (p.params?.type),
      id: 'typeInList',
      width: 50,
      Cell: value => (
        <div>
          <a href={value.row.original.params.mediaUrl} target="_blank" rel="noreferrer">
            <img
              style={{ width: '30px' }}
              src={`/images/mediacontent/${mapType[value.row.original?.params?.type]}.png`}
              alt={value.row.original.params.type}
              title={value.row.original.params.type}
            />
          </a>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: true,
    },
    {
      Header: `Submit Date (${currentTimezoneAggr()})`,
      accessor: () => null, // p => (WVFormatter.getServerDateTime(p.params?.createdAt)),
      id: 'createdAt',
      Cell: value => (
        <div style={{ paddingRight: '10px' }}>
          <span>{WVFormatter.getServerDateTime(value.row.original.params?.createdAt)}</span>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: false,
      width: 160,
    },
    {
      Header: 'Publish Date (ET)',
      accessor: item => (item?.params?.publishDate ? item?.params?.publishDate.split('T')[0] : null), // p => (WVFormatter.getServerDateTime(p.params?.createdAt)),
      id: 'publishDate',
      Cell: value => (
        <div style={{ paddingRight: '10px' }}>
          <span>{value.value ? moment(value.value).format('MM/DD/YY') : '-'}</span>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: false,
      width: 100,
    },
    {
      Header: 'Privacy Status',
      accessor: () => null,
      id: 'privacyStatus',
      Cell: (record) => {
        const status = record.row.original.params.privacyStatus;
        let value = '-';
        switch (status) {
          case 'public':
            value = 'Public';
            break;
          case 'private':
            value = 'Private';
            break;
          case 'unlisted':
            value = 'Unlisted';
            break;
          default:
            break;
        }
        return (
          <div>
            {value}
          </div>);
      },
      className: 'align-middle',
      disableSortBy: false,
      width: 100,
    },
    {
      Header: 'Brand Approved Date',
      accessor: item => item?.params?.brandApprovedDate,
      id: 'brandApprovedDate',
      Cell: p => useMemo(() => (
        <BrandApprovedDateField
          record={p.row.original}
          afterSaveFunc={() => setVersion((new Date()).toString())}
          updateRecordFunction={p.updateRecordFunction}
          isEditAllowed={isEditAllowed}
        />), [p.row.original]),
      className: 'align-middle',
      disableSortBy: false,
      width: 180,
    },
    {
      Header: 'Average Score',
      accessor: p => (
        <div>
          {p.params?.averageScore !== null ? Math.round(p.params.averageScore) : <span>&mdash;</span>}
        </div>),
      id: 'averageScore',
      className: 'align-middle text-center',
      disableSortBy: false,
      width: 130,
    },
  ];
}
