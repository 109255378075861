import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TableCellContextMenu from '@/shared/components/table/TableCellContextMenu';
import Hook from '@/shared/hooks';
import ApiClient from 'ApiClient';

const ContextMenuInList = ({
  record,
}) => {
  const [showDeleteModal] = Hook.useShowModalHook('DeleteModalDefault');
  const { showError, showSuccess } = Hook.useNoticeHook();
  const [showModalAddNew] = Hook.useShowModalHook('CreateAdminUserModal');
  const reload = Hook.useReloadTableHook();
  const api = new ApiClient();
  

  const contextMenuOptions = [
    {
      label: 'Edit',
      handler: () => showModalAddNew({ record, successFunc: () => reload() }),
    },
    {
      label: 'Delete',
      handler: () => {
        showDeleteModal({
          title: 'Delete Admin User',
          message: `Are you sure you want to delete ${record.params.firstName} ${record.params.lastName}?`,
          deleteRecord: async (data, addNotice) => {
            const response = await api.recordAction({
              resourceId: 'AdminUser', actionName: 'delete', recordId: record.id,
            });
            if (response.data.error) {
              showError(response.data.error);
            }
          },
          successFunction: () => {
            showSuccess('Admin User deleted successfully');
            reload();
          },
        });
      },
    },
  ];
  return (
    <>
      <TableCellContextMenu options={contextMenuOptions} />
    </>
  );
};

ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, null)(ContextMenuInList);
