/* eslint-disable no-param-reassign */
import { axios } from 'ApiClient';
import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { addNotice } from '../../../containers/App/store/store';

/*
  NOTE: createSlice allows us to work with state directly.
  Internally, it does not mute state, but makes a copy.
  https://redux-toolkit.js.org/tutorials/intermediate-tutorial#creating-the-todos-slice
*/
const DashboardReducer = createSlice({
  name: 'dashboard',
  initialState: {
    fromDate: null,
    toDate: null,
    loading: {
      clicks: false,
      revenue: false,
      conversions: false,
      reports: false,
      newWinners: false,
    },
    topStats: {
      conversions: [],
      clicks: [],
      revenue: [],
      newWinners: [],
    },
    reports: {
      contacts: 0,
      contracts: 0,
      content: 0,
      conversions: 0,
      creators: 0,
    },
    lastFetched: new Date(),
  },
  reducers: {
    setNewDates(state, action) {
      const { fromDate, toDate } = action.payload;
      state.fromDate = fromDate;
      state.toDate = toDate;
    },
    get5csStatsStart(state) {
      state.loading = {
        ...state.loading,
        reports: true,
      };
      state.reports = {
        contacts: 0,
        contracts: 0,
        content: 0,
        conversions: 0,
        creators: 0,
      };
    },
    get5csSuccess(state, action) {
      const {
        contacts, contracts, content, conversions, creators,
      } = action.payload;
      state.loading = {
        ...state.loading,
        reports: false,
      };
      state.reports = {
        contacts,
        contracts,
        content,
        conversions,
        creators,
      };
    },

    getClicksStatsStart(state) {
      state.loading = {
        ...state.loading,
        clicks: true,
      };
      state.topStats = {
        ...state.topStats,
        clicks: [],
      };
    },
    getRevenueStatsStart(state) {
      state.loading = {
        ...state.loading,
        revenue: true,
      };
      state.topStats = {
        ...state.topStats,
        revenue: [],
      };
    },
    getConversionsStatsStart(state) {
      state.loading = {
        ...state.loading,
        conversions: true,
      };
      state.topStats = {
        ...state.topStats,
        conversions: [],
      };
    },
    getNewWinnersStatsStart(state) {
      state.loading = {
        ...state.loading,
        newWinners: true,
      };
      state.topStats = {
        ...state.topStats,
        newWinners: [],
      };
    },
    getStatsError(state) {
      state.loading = {
        ...state.loading,
        clicks: false,
        revenue: false,
        conversions: false,
      };
      state.topStats = {
        ...state.topStats,
        clicks: [],
        conversions: [],
        revenue: [],
      };
    },
    getStatsSuccess(state, action) {
      const { clicks, revenue, conversions, newWinners } = action.payload;
      state.loading = {
        ...state.loading,
        clicks: false,
        revenue: false,
        conversions: false,
        newWinners: false,
      };
      state.topStats = {
        revenue,
        conversions,
        clicks,
        newWinners, 
      };
      state.lastFetched = new Date();
    },
    /* getClicksStatsSuccess(state, action) {
      const { clicks } = action.payload;
      state.loading = {
        ...state.loading,
        clicks: false,
      };
      state.topStats = {
        ...state.topStats,
        clicks,
      };
      state.lastFetched = new Date();
    },
    getConversionsStatsSuccess(state, action) {
      const { conversions } = action.payload;
      state.loading = {
        ...state.loading,
        conversions: false,
      };
      state.topStats = {
        ...state.topStats,
        conversions,
      };
      state.lastFetched = new Date();
    },
    getRevenueStatsSuccess(state, action) {
      const { revenue } = action.payload;
      state.loading = {
        ...state.loading,
        revenue: false,
      };
      state.topStats = {
        ...state.topStats,
        revenue,
      };
      state.lastFetched = new Date();
    }, */
  },
});
const getClicksApi = async (fromDate, toDate) => {
  return axios.get('/api/portal/dashboard/get-click-stats', { params: { fromDate, toDate } });
};
const getRevenueApi = async (fromDate, toDate) => {
  return axios.get('/api/portal/dashboard/get-revenue-stats', { params: { fromDate, toDate } });
};
const getConversionsApi = async (fromDate, toDate) => {
  return axios.get('/api/portal/dashboard/get-conversions-stats', { params: { fromDate, toDate } });
};
const getNewWinnersApi = async (fromDate, toDate) => {
  const res = await axios.get('/api/portal/reports/get-new-winners-report', {
    params: {
      'filters.winnerDatetime.from': moment.unix(fromDate).format('YYYY-MM-DD'),
      'filters.winnerDatetime.to': moment.unix(toDate).format('YYYY-MM-DD'),
      perPage:100,
      page: 1,
      direction: 'desc',
      sortBy: 'count',
    }
  });
  return res.data.records
};

export const {
  setNewDates,
  getClicksStatsStart,
  getRevenueStatsStart,
  getConversionsStatsStart,
  getNewWinnersStatsStart,
  getStatsError,
  getStatsSuccess,
  get5csStatsStart,
  get5csSuccess,
} = DashboardReducer.actions;

export const getSelectNewDates = (fromDate, toDate) => async (dispatch) => {
  try {
    dispatch(setNewDates({ fromDate, toDate }));
    dispatch(getClicksStatsStart());
    dispatch(getRevenueStatsStart());
    dispatch(getConversionsStatsStart());
    dispatch(getNewWinnersStatsStart());

    const [clicks, revenue, conversions, newWinners] = await Promise.all([
      getClicksApi(fromDate, toDate),
      getRevenueApi(fromDate, toDate),
      getConversionsApi(fromDate, toDate),
      getNewWinnersApi(fromDate, toDate),
    ]);
    console.log(clicks, revenue, conversions)
    dispatch(getStatsSuccess({ clicks: clicks.data.items, revenue: revenue.data.items, conversions: conversions.data.items,  newWinners, }));
  } catch (err) {
    console.log(err);
    dispatch(getStatsError(err));
    dispatch(addNotice({
      message: 'There was an error fetching records, Check out console to see more information.',
      type: 'error',
    }));
  }
};
export default DashboardReducer.reducer;
