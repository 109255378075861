import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

class CopyToClipboardNoIcon extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.string,
    ]),
    value: PropTypes.number.isRequired,
    className: PropTypes.string,
    iconClassName: PropTypes.string,
    title: PropTypes.string,
  };
  static defaultProps = {
    className: '',
    iconClassName: '',
    children: null,
    title: 'Copy to clipboard',
  };
  constructor(props) {
    super(props);

    this.state = {
      copied: false,
    };
  }

  handleCopy = (e, value) => {
    const textField = document.createElement('textarea');
    textField.innerText = value;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 1000);
  };

  render() {
    const {
      children,
      value,
      title,
      className,
    } = this.props;
    const { copied } = this.state;
    return (
      <Tooltip title={copied ? 'Copied!' : title}>
        <span
          className={className}
          style={{maxWidth: '100%'}}
          onClick={e => this.handleCopy(e, value)}
        >
          {children}
        </span>
      </Tooltip>
    );
  }
}

export default CopyToClipboardNoIcon;
