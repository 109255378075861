/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Col, Input, Row } from 'reactstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const ItemTypes = {
  CARD: 'card',
};

const BrandResourceFileCard = ({
  styles, file, index, filesIndex, moveFile, handleInputChange, removeFile,
}) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveFile(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({ id: file.order, index }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  let opacityStyle = {};
  if (isDragging) {
    opacityStyle = {
      opacity: 0.5,
    };
  }

  drag(drop(ref));

  return (
    <div ref={ref} key={`div-${filesIndex}-${index}`} style={{ ...styles.fileZone, ...opacityStyle }}>
      <Input name="title" placeholder="Title" value={file.title} onChange={(e) => { handleInputChange(e, index); }} />
      <br />
      <textarea
        name="description"
        placeholder="Description"
        className="form-control"
        onChange={(e) => { handleInputChange(e, index); }}
        value={file.description}
      />
      <br />
      <Row>
        <Col sm={6} style={{ paddingLeft: '50px' }}>
          <FormControlLabel
            control={
              <Input
                type="radio"
                name={`fileType${filesIndex}${index}`}
                value="upload"
                className="mt-0"
                checked={typeof file.fileType === 'undefined' || file.fileType === 'upload'}
                onChange={() => {}}
                disabled
              />
            }
            label="File uploaded"
          />
        </Col>
        <Col sm={6} style={{ paddingLeft: '50px' }}>
          <FormControlLabel
            control={
              <Input
                type="radio"
                name={`fileType${filesIndex}${index}`}
                value="url"
                className="mt-0"
                checked={file.fileType === 'url'}
                onChange={() => {}}
                disabled
              />
            }
            label="URL of the file is entered"
          />
        </Col>
      </Row>
      {file.fileType === 'url' && (
        <>
          <Input name="fileUrlExternal" placeholder="URL" value={file.fileUrlExternal} onChange={(e) => { handleInputChange(e, index); }} />
        </>
      )}
      <a target="_blank" rel="noopener noreferrer" href={file.fileType === 'url' ? file.fileUrlExternal : file.fileUrl}>Preview&nbsp;&nbsp;<i className="fas fa-external-link-alt" /></a>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
      <a style={{ color: '#ff0000' }} role="button" onClick={() => { removeFile(index); }}>Delete&nbsp;&nbsp;<i className="fas fa-trash fas-trash" /></a>
    </div>
  );
};

export default BrandResourceFileCard;
