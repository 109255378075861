import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button, FormGroup,
} from 'reactstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import WVFormatter from '@/shared/helpers/WVFormatter';
import RadioButton from '@/shared/components/RadioButton';
import Hook from '@/shared/hooks';

const modalId = 'assignContactOwnerModal';

const notAssigned = {
  value: null,
  label: 'Not Assigned',
  record: { params: { _id: null, firstname: 'Not Assigned', lastname: '' } },
};

const AssignContactOwnerModal = ({
  setVersion,
  adminUsers,
}) => {
  const [loading, setLoading] = useState(false);
  const [owner, setOwner] = useState(notAssigned);
  const [includeAll, setIncludeAll] = useState(false);


  const handleChangeOwner = async (selectedItem) => {
    setOwner(selectedItem);
  };
  const total = useSelector((state) => state.table.total);
  const prospectIds = useSelector((state) => state.table.selectedRecordsIds);
  const { data: dataModal, showModal: isOpen, close } = Hook.useModalHook(modalId);
  const { showError, showSuccess } = Hook.useNoticeHook();
  if (!dataModal) return false;
  const closeFunc = () => {
    setOwner(notAssigned);
    setIncludeAll(false);
    close();
  };

  // SUBMIT Data
  const submit = async () => {
    setLoading(true);
    const query = new URLSearchParams(window.location.search);
    console.log('Submit');
    try { 
      const response = await axios({
        method: 'post',
        url: '/api/portal/prospects/post-update-admin-user',
        data: {
          prospectIds,
          adminUserId: owner.value,
          includeAll,
          query: query.toString(),
        },
      })
      if (response.data.success) {
        setLoading(false);
        setVersion((new Date()).toString());
        closeFunc();
        showSuccess('Successfully updated Account Manager');
      }
      if (!response.data.success) {
        showError(response.data.error || 'Error updating Account Manager');
      }
    } catch (e) {
      showError(e.message);
    }
    
    return true;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeFunc()}
      style={{ minWidth: '425px' }}
    >
      <ModalHeader toggle={() => closeFunc()} tag="h4" className="modal-title-statistics">Assign Account Manager</ModalHeader>
      <ModalBody>
        {prospectIds.length !== total && (
          <>
            <div>{`You have selected ${prospectIds.length} Lead${prospectIds.length !== 1 ? 's' : ''} to assign to Account Manager`}</div>
            <div className="theme-light radio-in-prospects">
              {`Would you like to select all ${WVFormatter.formatIntNumber(total)} Leads?`}<br />
              <RadioButton
                name="action"
                label="&nbsp;Yes"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="includeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
              <RadioButton
                name="action"
                label="&nbsp;No"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="notIncludeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
            </div>
          </>
        )}
        <Row>
          <Col>
            <FormGroup>
              <Select
                value={owner}
                options={adminUsers}
                onChange={handleChangeOwner}
                placeholder=""
                isDisabled={!!loading}
                menuPlacement="auto"
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_ok btn-sm" color="primary" onClick={submit} disabled={loading}>
          {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
          <span>Save</span>
        </Button>&nbsp;&nbsp;
        <Button className="modal_cancel btn-sm" color="danger" onClick={() => closeFunc()}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};

AssignContactOwnerModal.propTypes = {
  adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
};

export default AssignContactOwnerModal;
