import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { unflatten } from 'flat';
import { Modal, Button, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import ApiClient from 'ApiClient';
import TableCellContextMenu from './TableCellContextMenu';
import withNotice from '../../../../../App/store/with-notice';

const ContextMenuInList = ({
  record,
  addNotice,
}) => {
  const vanityURL = unflatten(record);
  const [loading, setLoading] = useState(false);
  const [showRemoveRd, setShowRemoveRd] = useState(false);

  const handleDeleteDialogSubmit = async () => {
    setLoading(true);
    const api = new ApiClient();
    const response = await api.recordAction({
      resourceId: 'RedirectDomain', actionName: 'delete', recordId: vanityURL.id,
    });
    if (response.status === 200) {
      addNotice({
        message: 'The selected RedirectDomain has been deleted',
        type: 'success',
      });
    } else {
      console.log('Response to deleting RedirectDomain', response);
      addNotice({
        message: 'Couldn\'t delete a selected RedirectDomain',
        type: 'error',
      });
    }
    setShowRemoveRd(!showRemoveRd);
  };

  const goToPage = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  const contextMenuOptions = [
    {
      label: 'Details',
      handler: () => goToPage(`/resources/RedirectDomain/records/${vanityURL.id}/show`),
    },
    {
      label: 'Edit',
      handler: () => goToPage(`/resources/RedirectDomain/records/${vanityURL.id}/edit`),
    },
    {
      label: 'Delete',
      handler: () => setShowRemoveRd(!showRemoveRd),
    },
  ];

  return (
    <>
      <TableCellContextMenu options={contextMenuOptions} />

      <Modal isOpen={showRemoveRd} toggle={() => setShowRemoveRd(!showRemoveRd)} className="delete-modal" size="sm">
        <ModalHeader toggle={() => setShowRemoveRd(!showRemoveRd)} >
          Delete RedirectDomain
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete the selected RedirectDomain?<br />

        </ModalBody>
        <ModalFooter>
          <Button
            disabled={loading}
            className="btn-sm"
            color="danger"
            onClick={handleDeleteDialogSubmit}
          >
            Delete
          </Button>{' '}
          <Button
            disabled={loading}
            className="btn-sm"
            color="secondary"
            onClick={() => setShowRemoveRd(!showRemoveRd)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(ContextMenuInList);

