import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { CKEditor } from 'ckeditor4-react';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../../modals/Action';
import { updateResourceData } from '../../../helpers/WVRequest';

const Description = ({
  resource,
  record,
  property,
  primaryKey,
  fill,
  addUrl,
}) => {
  const v = record.params[property.name];
  const maxLength = 128;
  const [value, setValue] = useState(v);
  const [loading, setLoading] = useState(false);
  const [isOpen, toggle] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const len = value?.length || 0;
  const getPreview = (v) => (
    (len || 0) <= maxLength ? v : v?.substring(0, maxLength).replace(/\s+(\w+)?$/, '').replace(/(<([^>]+)>)/gi, "")
  );
  const [preview, setPreview] = useState(getPreview(v));

  const save = async (option) => {
    setPreview(getPreview(value));
    setLoading(true);
    setDisableSave(true);
    const data = fill(property.name, value, record);
    const afterSave = (res) => {
      setLoading(false);
      if (!(res.success || res.statusText === 'OK')) {
        setDisableSave(false);
      }
    };
    if (record.params[primaryKey]) {
      afterSave(await updateResourceData(resource.id, record.params[primaryKey], data));
    } else if (addUrl) {
      afterSave(await axios.post(addUrl, { data }));
    }
  };

  return (
    <>
      <div className="float-left" style={{ maxWidth: 'calc(100% - 30px)' }}>
        {preview ? (
          <span dangerouslySetInnerHTML={{ __html: preview }} />
        ) :  '-'}
        {preview?.length < len && (
          <span onClick={() => setPreview(value)}>...</span>
        )}
      </div>
      <div className="float-left pl-4" style={{ maxWidth: '30px' }}>
        <IconButton
          size="small"
          onClick={() => toggle(true)}
        >
          <FontAwesomeIcon
            icon={['fa', 'file-alt']}
            color={len > 0 ? '#70bbfd' : ''}
          />
        </IconButton>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          label={property.custom?.label || property.label}
          confirmButtonCaption="Save"
          process={save}
          disableButton={disableSave}
        >
          <CKEditor
            config={{versionCheck: false}}
            readOnly={loading}
            initData={value}
            onChange={(e) => { setValue(e.editor.getData()); setDisableSave(false) }}
          />
        </Modal>
      </div>
      <div className="clearfix" />
    </>
  );
};

Description.propTypes = {
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  property: PropTypes.objectOf(PropTypes.any).isRequired,
  primaryKey: PropTypes.string,
  fill: PropTypes.func,
  addUrl: PropTypes.string,
};

Description.defaultProps = {
  primaryKey: '_id',
  fill: (propertyName, value) => ({ [propertyName]: value }),
  addUrl: null,
};

export default Description;
