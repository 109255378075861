/* eslint-disable filenames/match-exported */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, CardTitle, Col, Container, Row,
} from 'reactstrap';
import { axios } from 'ApiClient';
import moment from 'moment-timezone';
import Breadcrumb from '../../../../../../shared/components/BreadCrumbs';
import ReactTableBase from '../../../../../../shared/tables/table/ReactTableBase';
import Columns from './custom/Table/ColumnsCpmProspects';
import Actions from './custom/Actions';
import AddDealModal from './custom/Modal/AddDealModal';
import PublishPAGroupModal from './custom/Modal/PublishPAGroupModal';
import HistoryModal from '../../CpmPricingApprovals/components/custom/Modal/HistoryModal';
import RefreshStatisticsOptionsModal from '../../CpmPricingApprovals/components/custom/Modal/RefreshStatisticsOptionsModal';
import csvHelper from '../../../../../../shared/helpers/CSVHelper';
import formater from '../../../../../../shared/helpers/WVFormatter';
import Hook from '../../../../../../shared/hooks';

const justifications = [
  { label: 'Test', value: 'Test' },
  { label: 'Rebuy, Actual CAC', value: 'Rebuy, Actual CAC' },
  { label: 'Rebuy, Predicted CAC', value: 'Rebuy, Predicted CAC' },
  { label: 'Price Adjusted Rebuy to Actual', value: 'Price Adjusted Rebuy to Actual' },
  { label: 'Price Adjusted Rebuy to Predicted', value: 'Price Adjusted Rebuy to Predicted' },
  { label: 'Marginal Rebuy, Actual CAC', value: 'Marginal Rebuy, Actual CAC' },
  { label: 'Marginal Rebuy, Predicted CAC', value: 'Marginal Rebuy, Predicted CAC' },
];

const CPMPricingApprovalsProspects = ({ resource, history, cpmPriceAppGroupId }) => {
  const [group, setGroup] = useState({});
  const [loading, setLoading] = useState(false);
  const [ilStatus, setIlStatus] = useState({
    approved: true, inReview: true, pendingEdits: true, actionRequired: true, hold: true, rejected: true,
  });
  const [brandStatus, setBrandStatus] = useState({
    approved: true, inReview: true, hold: true, pendingEdits: true, rejected: true,
  });
  // const [possibleDeals, setPossibleDeals] = useState([]);
  const [monthFilter, setMonthFilter] = useState([]);
  const [justificationFilter, setJustificationFilter] = useState([]);
  const [search, setSearch] = useState('');
  const [showDeleteModal] = Hook.useShowModalHook('DeleteModalDefault');
  const [showHistoryModal] = Hook.useShowModalHook('HistoryModal');
  const [showRefreshStatisticsModal] = Hook.useShowModalHook('refreshCPMProspectFromOrder');
  const [error, setError] = useState(null);
  console.log(history);
  console.log(resource);
  console.log(error);

  const getCpmPriceApprovalsGroup = () => {
    try {
      setError(null);
      setLoading(true);
      axios.get(`/api/portal/prospects/get-cpm-price-approvals-group?id=${cpmPriceAppGroupId}`).then((result) => {
        if (result.data.success) {
          setGroup(result.data.record);
        } else {
          setError(result.data.error);
        }
        // console.log(result.data);
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCpmPriceApprovalsGroup();
    // setPrefill(filterProperties({
    //   cpmPriceApprovals: cpmPriceAppGroupId,
    // }));
  }, [cpmPriceAppGroupId]);

  /* useEffect(() => {
    if (Object.keys(group).length > 0) {
      console.log(group);
      fetchPossibleDeals();
    }
  }, [group]); */

  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40, 100],
    placeholder: 'Search...',
    sortBy: 'cac',
    direction: 'asc',
    useFlex: true,
  };
  const columns = useMemo(() => Columns({
    getCpmPriceApprovalsGroup,
    groupId: group._id,
    groupStatus: group.status?.status,
    showDeleteModal,
    showHistoryModal,
    showRefreshStatisticsModal,
  }), [group]);

  const filteredItems = useMemo(() => {
    console.log('start fill', group.cpmProspects);
    let items = [];
    // const now = moment();
    if (group.cpmProspects && group.cpmProspects.length > 0) {
      items = group.cpmProspects.filter((prospect) => {
        let allow = true;
        if (allow && prospect.approvalStatus) {
          allow = Object.keys(ilStatus).find(k => (ilStatus[k] && prospect.approvalStatus.status === k));
        } else {
          allow = false;
        }
        if (allow && prospect.approvalsBrandStatus) {
          allow = Object.keys(brandStatus).find(k => (brandStatus[k] && prospect.approvalsBrandStatus.status === k));
        } else {
          allow = false;
        }
        if (allow && monthFilter?.length > 0) {
          const contentRD = prospect.contentReleaseDate ? moment(prospect.contentReleaseDate) : moment();
          const formattedMonthYear = contentRD.format('MMMM, YYYY');
          allow = monthFilter.map(a => String(a.label)).includes(formattedMonthYear);
        }
        if (allow && justificationFilter?.length > 0) {
          allow = justificationFilter.map(a => String(a.value)).includes(prospect.dealJustification);
          console.log(allow);
        }
        if (allow && search) {
          const name = `${prospect.influencer?.first_name || ''} ${prospect.influencer?.last_name || ''}`;
          allow = name?.toLowerCase().includes(search.toLowerCase()) || prospect.deal.dealTerms.coupon_code?.toLowerCase().includes(search.toLowerCase());
        }
        return allow;
      });
    }
    return items;
  }, [group.cpmProspects, ilStatus, brandStatus, monthFilter, justificationFilter, search]);

  const downloadCsv = () => {
    console.log(filteredItems)
    const data = [['Creator', 'Promo Code', 'Brand Safety', 'Justification', 'Month', 'IL PA Status', 'Brand PA Status', 'V30', 'Total Price', 'CPM']];
    filteredItems.forEach((item) => {
      const row = [
        item.influencer ? `${item.influencer.first_name || ''} ${item.influencer.last_name || ''}` : '',
        item.deal.dealTerms?.coupon_code || '',
        item.deal?.authorizedPayment?.creatorBrandApproved ? 'Approved' : 'Not Approved',
        // (item.prospect?.approvalStatus?.brand?.history.length > 0 && item.prospect.approvalStatus.brand.status === 'Approved') ?
        //   moment.tz(moment.utc(item.prospect.approvalStatus.brand.history[item.prospect.approvalStatus.brand.history.length - 1].dateModified).format('YYYY-MM-DD'), 'America/New_York').format('MM/DD/YYYY') :
        //   '',
        item.dealJustification || '',
        item.contentReleaseDate?.currentDate ? moment(item.contentReleaseDate?.currentDate).format('MMMM, YYYY') : '',
        item.approvalStatus.status,
        item.approvalsBrandStatus.status,
        item.v30 || 0,
        item.totalPrice ? formater.formatCurrency(item.totalPrice) : formater.formatCurrency(0),
        item.cpm ? formater.formatCurrency(item.cpm) : formater.formatCurrency(0),
      ];
      data.push(row);
    });
    return csvHelper.generateCSV(`Price Approval Group ${group.name}.csv`, data);
  };

  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'CPM Price Approval Groups', path: '/research/cpm-price-approvals-groups' },
          { title: `Price Approval Group${group.name ? ` - ${group.name}` : ''}`, path: null },
        ]}
        isBackButton
        backButtonUrl="/research/cpm-price-approvals-groups"
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>{`Price Approval Group${group.name ? ` - ${group.name}` : ''}`}</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Row className="mt-3">
            <Col>
              <Actions
                ilStatus={ilStatus}
                setIlStatus={setIlStatus}
                brandStatus={brandStatus}
                setBrandStatus={setBrandStatus}
                loading={loading}
                approvalGroup={group}
                downloadCsv={downloadCsv}
                getCpmPriceApprovalsGroup={getCpmPriceApprovalsGroup}
                justifications={justifications}
                monthFilter={monthFilter}
                setMonthFilter={setMonthFilter}
                justificationFilter={justificationFilter}
                setJustificationFilter={setJustificationFilter}
                search={search}
                setSearch={setSearch}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {!loading ? (
                <Row className="mt-3">
                  <Col>
                    <ReactTableBase
                      key="searchable"
                      columns={columns}
                      data={filteredItems}
                      tableConfig={tableConfig}
                    />
                  </Col>
                </Row>) : null
              }
            </Col>
          </Row>
        </CardBody>
      </Card>
      <AddDealModal getCpmPriceApprovalsGroup={getCpmPriceApprovalsGroup} />
      <PublishPAGroupModal getCpmPriceApprovalsGroup={getCpmPriceApprovalsGroup} />
      <RefreshStatisticsOptionsModal updateTable={getCpmPriceApprovalsGroup} />
      <HistoryModal />
    </Container>
  );
};

CPMPricingApprovalsProspects.propTypes = {
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
  cpmPriceAppGroupId: PropTypes.string.isRequired,
};

export default CPMPricingApprovalsProspects;
