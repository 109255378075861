import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import { axios } from 'ApiClient';
import Hook from '@/shared/hooks'; // Adjust the import path as necessary
import { unflatten } from 'flat'; // Adjust the import path as necessary

const Cr = ({ initialText, id, updateRecordFunction, status }) => {
  const [text, setText] = useState(initialText);
  const [loading, setLoading] = useState(false);
  const { showSuccess, showError } = Hook.useNoticeHook();

  useEffect(() => {
    setText(initialText);
  }, [initialText]);

  const handleBlur = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post('/api/portal/opportunity/post-edit-opportunity-params', {
        id,
        key: 'contentRequirements',
        value: text,
      });
      if (!data.success) {
        throw new Error(data.error || 'Failed to update record');
      } else {
        updateRecordFunction(unflatten(data.opportunityPopulated));
        showSuccess('Content Requirements saved successfully');
      }
    } catch (error) {
      console.error('API call error:', error);
      showError('Failed to update record');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Input
        type="textarea"
        value={text}
        onChange={(e) => setText(e.target.value)}
        onBlur={handleBlur}
        disabled={loading || status === 'Sent'}
      />
    </div>
  );
};

export default Cr;