const { calculatePercentage } = require('../reports/Calculations');

exports.getCpm = p => calculatePercentage(p.dealCost, p.views / 1000);
exports.getRpm = p => calculatePercentage(p.grossRevenue, p.views / 1000);
exports.getCommentViewRatio = p => calculatePercentage(p.comments, p.views);
exports.getClickComRatio = p => calculatePercentage(p.comments, p.clicks);
exports.getConversionsComments7DayRatio = p => calculatePercentage(p.conversions7Day, p.comments7Day);
exports.getEpc = p => calculatePercentage(p.grossRevenue, p.clicks);
exports.getCpc = p => calculatePercentage(p.dealCost, p.clicks);
exports.getClickThroughRate = p => calculatePercentage(p.clicks, p.views);
exports.getConversionRate = p => calculatePercentage(p.revenueConversions, p.clicks);
