import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Modal,
  ModalHeader,
  ButtonToolbar,
  ModalBody,
  Button,
  Row,
  Col,
  Label,
  Spinner,
} from 'reactstrap';
// import Select from 'react-select';

import { axios } from 'ApiClient';
import Hook from '@/shared/hooks';
import { IconButton, Tooltip } from '@material-ui/core';
// import CheckBoxField from '@/shared/components/CheckBox';
// import Alert from '@/shared/easydev2/shared/components/Alert';


export const modalId = 'EmailStatusModal';

const CountdownTimer = () => {
  const [secondsRemaining, setSecondsRemaining] = useState(20);

  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsRemaining((prev) => (prev === 1 ? 20 : prev - 1));
    }, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return <div>Next update in: {secondsRemaining} sec</div>;
};

const EmailStatusModal = () => {
  const [loading, setLoading] = useState(false);
  const [statusItems, setStatusItems] = useState([]);
  const [timeInterval, setTimeInterval] = useState(null);
  const { data: dataModal, showModal: isShow, close } = Hook.useModalHook(modalId);
  // const selectedRows = useSelector((state) => state.tableRedux.selectedRecordsIdsMultiselect);
  const getItems = async () => {
    setLoading(true);
    const res = await axios.get('/api/portal/opportunity/get-email-status-from-hubspot?id=' + dataModal?.record?._id);
    if (res.data.statuses) {
      setStatusItems(res.data.statuses);
    }
    setLoading(false);
  };
  useEffect(async () => {
    if (isShow) {
      setTimeInterval(setInterval(async () => {
        await getItems();
      }, 20000));
      getItems();
    }
  }, [isShow]);
  if (!dataModal || !dataModal.record) return false;
  const closeFunc = () => {
    clearInterval(timeInterval);
    setStatusItems([]);
    setLoading(false);
    close();
  };

  const { record } = dataModal;
  console.log(record, 'modalInfo');
  return (
    <Modal
      size="md"
      isOpen={isShow}
      toggle={closeFunc}
      className='ltr-support theme-light'
    >
      <ModalHeader toggle={closeFunc} tag="h4">
        Email Send Status
      </ModalHeader>
      <ModalBody>
        <div class='text-right'><CountdownTimer /></div>
        <div class='mt-3'>
          {!loading ? statusItems.map((statusItem) => (
            <Row>
              <Col md={6}>
                <div>
                  <Label className='font-weight-bold'>Date Requested</Label>: {moment(statusItem.requestedAt).format('MM/DD/YYYY hh:mm A')}
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <Label className='font-weight-bold'>Date Completed</Label>: {moment(statusItem.completedAt).format('MM/DD/YYYY hh:mm A')}
                </div>
              </Col>
              <Col md={4} style={{ wordWrap: 'break-word' }}>
                <div>
                  <Label className='font-weight-bold'>Send To</Label>:
                </div>
                {statusItem.emailToSend}
              </Col>
              <Col md={4}>
                <div>
                  <Label className='font-weight-bold'>Request Status</Label>:

                </div>
                {statusItem.status}
                <Tooltip title={statusItem.statusDescription}>
                  <IconButton size="small" onClick={() => { }}><i className="fa fa-info-circle" style={{ fontSize: '0.8em' }} /></IconButton>
                </Tooltip>
              </Col>
              <Col md={4}>
                <div>
                  <Label className='font-weight-bold'>Send Status</Label>:

                </div>
                {statusItem.sendResult}
                <Tooltip title={statusItem.sendStatusDescription}>
                  <IconButton size="small" onClick={() => { }}><i className="fa fa-info-circle" style={{ fontSize: '0.8em' }} /></IconButton>
                </Tooltip>
              </Col>
              <Col md={12}>
                <hr />
              </Col>
            </Row>
          )) : null}
          {loading ? (<div className='text-center'><Spinner /></div>) : null}
        </div>
        {/* */}


      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button color="secondary" onClick={closeFunc}>Close</Button>

      </ButtonToolbar>
    </Modal>
  );
};
export default EmailStatusModal
