exports.init = () => ({
  sum: 0,
  count: 0,
});

exports.add = (a, v) => {
  if (!isNaN(parseFloat(v)) && isFinite(v)) {
    a.sum += v;
    a.count += 1;
  }
  return a;
};

exports.get = (value, digits) => value.count ? value.sum / value.count : null;
