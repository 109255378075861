import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import RefreshIcon from 'mdi-react/RefreshIcon';
import { uid } from 'react-uid';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import Tags from '../../../../../../../shared/components/form/Tags';
import { standardFields } from '../../../../../../../shared/helpers/models/CustomAgreementTemplate';
import { dealTypes } from '../../../../../../../shared/helpers/models/HubilDeal';
import CustomField from './CustomField';

const Form = (props) => {
  const {
    data,
    update,
    agencyOptions,
    loadingAgencies,
    dropboxTemplates,
    loadingDropboxTemplates,
    refreshTemplates,
  } = props;

  const updateStandardField = (index, key, value) => {
    const d = data?.params?.standardFields ? { ...data.params.standardFields } : {};
    d[index] = {
      ...(d[index] || { readOnly: standardFields[index].defaults?.readOnly, required: standardFields[index].defaults?.required }),
      [key]: value,
    }
    update({ standardFields: d });
  };

  const updateCustomField = (index, key, value) => {
    const customFields = [...data.params.customFields];
    customFields[index] = {
      ...customFields[index],
      [key]: value,
    }
    update({ customFields });
  };

  const deleteCustomField = (index) => {
    const customFields = [...data.params.customFields];
    customFields.splice(index, 1);
    update({ customFields });
  };

  const dropboxTemplateOptions = Object.keys(dropboxTemplates).map(r => ({
    label: dropboxTemplates[r].label,
    value: dropboxTemplates[r].value,
  })).sort((a, b) => (a.label < b.label) ? -1 : 1);

  const { dropboxTemplateId } = data.params || {};

  const customFieldOptions = dropboxTemplates?.[dropboxTemplateId]?.customFields ? Object.keys(dropboxTemplates[dropboxTemplateId].customFields).filter(r => !Object.keys(standardFields).includes(r)).map(r => ({
    label: r,
    value: r,
  })).sort((a, b) => (a.label < b.label) ? -1 : 1) : [];

  const errorClass = v => (v ? 'text-black-50' : 'text-danger');

  return (
    <>
      <Row>
        <Col md="6" xl="3">
          <FormGroup>
            <Label>Template Name</Label>
            <Input
              type="text"
              id="templateName"
              name="templateName"
              placeholder="Template Name"
              onChange={(e) => { update({ templateName: e.target.value }); }}
              value={data.params?.templateName || ''}
            />
            <small className={errorClass(data.params?.templateName)}>{data.errors?.templateName || 'Required'}</small>
          </FormGroup>
        </Col>
        <Col md="6" xl="3">
          <FormGroup>
            <Label>Agency?</Label>
            <Select
              value={agencyOptions.filter(o => data.params?.agencies?.includes(o.value))}
              options={agencyOptions}
              placeholder="Select Agency..."
              onChange={(e) => { update({ agencies: e?.map(r => r.value) || [] }); }}
              isDisabled={loadingAgencies}
              isMulti
              isClearable
            />
            <small className="text-black-50">{data.errors?.agencies || 'Optional'}</small>
          </FormGroup>
        </Col>
        <Col md="6" xl="3">
          <FormGroup>
            <Label>Deal Type(s)</Label>
            <Tags
              values={data.params?.dealTypes || []}
              setValues={(values) => { update({ dealTypes: values }); }}
              suggestions={Object.keys(dealTypes).map(k => ({ id: dealTypes[k], name: dealTypes[k] }))}
              placeholder="Deal Type(s)"
            />
            <small className={errorClass(data.params?.dealTypes?.length)}>{data.errors?.dealTypes || 'Required'}</small>
          </FormGroup>
        </Col>
        <Col md="6" xl="3">
          <FormGroup>
            <Label className="float-left">Dropbox Template</Label>
            <RefreshIcon className="float-right" style={{ width: '20px', height: '20px', color: '#007bff' }} onClick={refreshTemplates} />
            <div className="clearfix" />
            <Select
              value={dropboxTemplateOptions.filter(o => o.value === data.params?.dropboxTemplateId)}
              options={dropboxTemplateOptions}
              onChange={(e) => { update({ dropboxTemplateId: e.value, dropboxTemplateName: e.label }); }}
              placeholder="Select Dropbox Template..."
              isDisabled={loadingDropboxTemplates}
            />
            <small className={errorClass(data.params?.dropboxTemplateId)}>{data.errors?.dropboxTemplateId || 'Required'}</small>
          </FormGroup>
        </Col>
      </Row>
      <hr className="mt-0" />
      <h4 style={{marginBottom: '12px'}}>Standard Data Fields</h4>
      {Object.values(standardFields).filter(r => r.defaults).map((field, i) => (
        <CustomField
          key={uid(i)}
          data={data.params?.standardFields?.[field.key] || { readOnly: field.defaults?.readOnly, required: field.defaults?.required }}
          defaults={field.defaults}
          index={field.key}
          customFieldOptions={[{ label: field.key }]}
          errors={data.errors?.standardFields?.[field.key] || {}}
          update={updateStandardField}
          disableStandardField
        />
      ))}
      <hr className="mt-0" />
      <h4 style={{marginBottom: '12px'}}>Custom Data Fields</h4>
      {data.params?.customFields && data.params.customFields.map((row, i) => (
        <CustomField
          key={uid(i)}
          data={row}
          index={i}
          customFieldOptions={customFieldOptions}
          disableStandardField={loadingDropboxTemplates}
          errors={data.errors?.customFields?.[i] || {}}
          update={updateCustomField}
          del={deleteCustomField}
        />
      ))}
      <Row>
        <Col style={{paddingTop: '12px'}}>
          <Button
            color="secondary"
            size="sm"
            onClick={() => { update({ customFields: [...data.params?.customFields || [], {}] }) }}
            style={{
              width: '100%',
              maxWidth: '200px',
              padding: '4px 8px',
              height: '42px',
            }}
          >
            <i className="fa fa-plus" aria-hidden="true" />&nbsp;
            <span className="btn-text">Add Custom Data Field</span>
          </Button>
        </Col>
      </Row>
    </>
  );
};

Form.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  update: PropTypes.func.isRequired,
  agencyOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  loadingAgencies: PropTypes.bool.isRequired,
  dropboxTemplates: PropTypes.objectOf(PropTypes.any).isRequired,
  loadingDropboxTemplates: PropTypes.bool.isRequired,
  refreshTemplates: PropTypes.func.isRequired,
};

export default Form;
