/* eslint react/prop-types: 0 */
/* eslint react/jsx-no-bind: 0 */
import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardBody,
  ButtonToolbar,
  Button,
} from 'reactstrap';
import ApiClient from 'ApiClient';
// import { PropertyPlace } from 'admin-bro/lib/backend/decorators/';
import PropertyType from '../property-type';

import StyledButton from '../ui/styled-button';
import withNotice from '../../../../containers/App/store/with-notice';
import recordToFormData from './record-to-form-data';

/**
 * @name EditAction
 * @category Actions
 * @description Shows form for updating a given record.
 * @private
 * @component
 */
class Edit extends React.Component {
  constructor(props) {
    super(props);
    const { record } = props;
    this.handleChange = this.handleChange.bind(this);
    this.enableSaveButton = this.enableSaveButton.bind(this);
    this.validate = this.validate.bind(this);
    this.state = {
      record,
      loading: false,
      enableSaveButton: true,
      errors: {},
    };
    this.api = new ApiClient();
  }

  validate(property, value) {
    const { resource } = this.props;
    const properties = property ? [property] : resource.editProperties;
    const errors = { ...this.state.errors };
    properties.forEach((p) => {
      errors[p.name] = (p?.custom?.required && !(property ? value : this.state.record?.params?.[p.name])) ? {
        message: 'This field is required.'
      } : null;
    });
    this.setState({ errors });
    return Object.values(errors).filter(r => r).length === 0;
  }

  enableSaveButton(enableSaveButton) {
    this.setState({ enableSaveButton });
  }

  handleChange(propertyOrRecord, value) {
    if (typeof value === 'undefined' && (propertyOrRecord).params) {
      this.setState({
        record: propertyOrRecord,
      });
    } else {
      this.setState(state => ({
        record: {
          ...state.record,
          params: {
            ...state.record.params,
            [propertyOrRecord]: value,
          },
        },
      }));
    }
  }

  handleSubmit(event) {
    if (!this.validate()) {
      this.props.addNotice({
        message: 'There Were Validation Errors',
        type: 'error',
      });
      event.preventDefault();
      return false;
    }
    const {
      resource,
      history,
      close,
      afterSave,
      addNotice,
    } = this.props;
    const { record } = this.state;

    const formData = recordToFormData(record);

    this.setState({ loading: true });

    this.api.recordAction({
      resourceId: resource.id,
      actionName: 'edit',
      recordId: record.id,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    }).then((response) => {
      if (response.data.notice) {
        addNotice(response.data.notice);
      }
      if (!close && response.data.redirectUrl) {
        if (response.data.redirectUrl.indexOf('records') >= 0) {
          history.push(response.data.redirectUrl);
        } else {
          history.push(`${response.data.redirectUrl}/actions/list`);
        }
        if (response.data.adminUserDataChanged) {
          window.location.reload();
        }
      } else {
        this.setState(state => ({
          record: {
            ...state.record,
            errors: response.data.record.errors,
          },
          loading: false,
        }));
        if (close) close();
        if (afterSave) afterSave();
      }
    }).catch(() => {
      this.setState({ loading: false });
      addNotice({
        message: 'There was an error updating record, Check out console to see more information.',
        type: 'error',
      });
    });
    event.preventDefault();
    return false;
  }

  render() {
    const { resource, close } = this.props;
    const properties = resource.editProperties;
    const {
      record,
      loading,
      enableSaveButton,
      errors,
    } = this.state;

    return (
      <Card>
        <CardBody>
          <form onSubmit={this.handleSubmit.bind(this)} className="">
            {properties.map(property => (
              <PropertyType
                key={property.name}
                where="edit"
                error={errors[property.name]}
                validate={this.validate}
                onChange={this.handleChange}
                enableSaveButton={this.enableSaveButton}
                property={property}
                resource={resource}
                record={record}
              />
            ))}
            {close ? (
              <ButtonToolbar className="modal__footer">
                <Button
                  className="modal_ok btn-sm is-primary"
                  color="primary"
                  type="submit"
                  disabled={loading || !enableSaveButton || Object.values(errors).filter(r => r).length > 0}
                >
                  Save
                </Button>
                &nbsp;&nbsp;
                <Button
                  className="modal_cancel btn-sm"
                  color="secondary"
                  onClick={close}
                >
                  Cancel
                </Button>
              </ButtonToolbar>
            ) : (
              <StyledButton
                type="submit"
                disabled={!enableSaveButton || Object.values(errors).filter(r => r).length > 0}
                className={`is-primary${loading ? ' is-loading' : ''}`}
              >
                <i className="icomoon-save" />
                <span className="btn-text">Save</span>
              </StyledButton>
            )}
          </form>
        </CardBody>
      </Card>
    );
  }
}

export default withNotice(withRouter(Edit));
