/* eslint react/prop-types: 0 */
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Container, Row, Col, Button, Label, FormGroup, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledButtonDropdown,
  CardBody, CardTitle, Card, Input, InputGroup, InputGroupAddon, InputGroupText,
} from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import Select from 'react-select';
import SelectAsync from 'react-select/async';
import { useHistory } from 'react-router-dom';
import { useAsyncDebounce } from 'react-table';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TuneIcon from 'mdi-react/TuneIcon';
import { NumericFormat as NumberFormat } from 'react-number-format';
import MatTableList from '../../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../../shared/components/BreadCrumbs';
import UserPropertyType from './custom';
import withNotice from '../../../../App/store/with-notice';
import TopButtonsPeriod from '../../../Reports/TimePeriodFilterExtended';
import getTableColumns from './custom/TableColumns';
import { getCookie, parseUrl, setCookie } from '../../../../../shared/helpers/WVUtilities';
import { fetchDictionary } from '../../../../../shared/helpers/WVRequest';
import { addDays } from '../../../../../shared/helpers/Dates';
import CheckBoxField from '../../../../../shared/components/CheckBox';
import StatusHistoryModal from './custom/modals/StatusHistoryModal';
import InitialFilters from './custom/InitialFilters';
import UpdateStatusModal from './custom/modals/UpdateStatusModal';
import DeleteContentModal from './custom/modals/DeleteContentModal';
import UpdatePendingStatusModal from './custom/modals/UpdatePendingStatusModal';
import PublishDate from './custom/PublishDate';
import RefreshYoutubeDialog from './custom/modals/RefreshYoutubeDialog';
import Hook from '../../../../../shared/hooks';
import MediaContentTotals from './custom/Totals';
import NotesModal from './custom/NotesModal';

const compare = (a, b) => {
  const keyA = a.label;
  const keyB = b.label;
  if (keyA < keyB) return -1;
  if (keyA > keyB) return 1;
  return 0;
};

const typeFilterOptions = [
  { label: 'YouTube', value: 'YouTube' },
  { label: 'YouTube Description Text', value: 'YouTube Description Text' },
  { label: 'Podcast', value: 'Podcast' },
  { label: 'Podcast Script Text', value: 'Podcast Script Text' },
  { label: 'Instagram Story', value: 'Instagram Story' },
  { label: 'Instagram Post', value: 'Instagram Post' },
  { label: 'Instagram TV Video', value: 'Instagram TV Video' },
  { label: 'TikTok', value: 'TikTok' },
  { label: 'X Post', value: 'Twitter Post' },
  { label: 'Facebook Post', value: 'Facebook Post' },
  { label: 'Facebook Watch Video', value: 'Facebook Watch Video' },
  { label: 'LinkTree', value: 'LinkTree' },
  { label: 'Website', value: 'Website' },
  { label: 'Email', value: 'Email' },
  { label: 'Text', value: 'Text' },
];

const getFilterValueInitial = (filterType) => {
  let initialFilterType = '<=';
  const type = new URLSearchParams(window.location.search).get(filterType);
  if (type) {
    initialFilterType = type;
  }
  return initialFilterType;
};

const loadCreatorsOptions = async (value) => {
  let response = {};
  if (value === 'getByIds') {
    const initialSearch = new URLSearchParams(window.location.search);
    if (initialSearch.has('filters.creator') && initialSearch.getAll('filters.creator').length > 0) {
      response = await axios.get('/api/portal/influencer/get-creators-by-ids', {
        params: { ids: initialSearch.getAll('filters.creator') },
      });
      if (!response.data.success) {
        return {};
      }
    }
  } else {
    response = await axios.get('/api/portal/influencer/get-creator-search', {
      params: {
        value,
        defaultSearch: 'a',
      },
    });
  }
  return response.data.records.map(record => ({
    value: record.params?._id || record._id,
    label: record.params ? `${record.params.first_name} ${record.params.last_name}` : `${record.first_name} ${record.last_name}`,
    record,
  }));
};

const MediContentListContainer = ({
  resource,
  action,
  addNotice,
}) => {
  const [version, setVersion] = useState((new Date()).toString());
  const [loadingFilters, setLoadingFilters] = useState(true);
  const selectedBrandsInitialState = [];
  const selectedCreatorsInitialState = [];
  const selectedCspsInitialState = [];
  const selectedAmInitialState = [];
  const selectedCategoriesInitialState = [];
  const selectedTypesInitialState = [];
  const selectedMarketingCampaignInitialState = [];
  let selectedInitialRange = 'current_month';
  let selectedStartDateInitialState = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
  let selectedEndDateInitialState = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss');
  const averageScoreFilterType = getFilterValueInitial('averageScoreType');
  const questionScoreFilterType = getFilterValueInitial('questionScoreType');
  const [allTimeStartDate, setAllTimeStartDate] = useState(null);

  const averageScoreLabels = [
    { value: '=', label: '=' },
    { value: '>', label: '>' },
    { value: '>=', label: '>=' },
    { value: '<', label: '<' },
    { value: '<=', label: '<=' },
    { value: 'Between', label: 'Between' },
  ];

  const {
    statusFilters, countFilters, dealTypeFilters, specialCriteriaFilters, categoryFilters, privacyStatus,
  } = InitialFilters;
  const initialSearch = new URLSearchParams(window.location.search);
  if (initialSearch.has('filters.submitter') && initialSearch.getAll('filters.submitter').length > 0) {
    initialSearch.getAll('filters.submitter').forEach((submitter) => {
      selectedCspsInitialState.push({ value: submitter, label: submitter });
    });
  }
  if (initialSearch.has('filters.accountManager') && initialSearch.getAll('filters.accountManager').length > 0) {
    initialSearch.getAll('filters.accountManager').forEach((am) => {
      selectedAmInitialState.push({ value: am, label: am });
    });
  }
  if (initialSearch.has('filters.categories') && initialSearch.getAll('filters.categories').length > 0) {
    initialSearch.getAll('filters.categories').forEach((category) => {
      selectedCategoriesInitialState.push({ value: category, label: category });
    });
  }
  if (initialSearch.has('filters.type') && initialSearch.getAll('filters.type').length > 0) {
    initialSearch.getAll('filters.type').forEach((type) => {
      selectedTypesInitialState.push({ value: type, label: type });
    });
  }
  if (initialSearch.has('filters.brand') && initialSearch.getAll('filters.brand').length > 0) {
    initialSearch.getAll('filters.brand').forEach((brand) => {
      selectedBrandsInitialState.push({ value: brand, label: brand });
    });
  }
  if (initialSearch.has('filters.marketingCampaign') && initialSearch.getAll('filters.marketingCampaign').length > 0) {
    initialSearch.getAll('filters.marketingCampaign').forEach((brand) => {
      selectedMarketingCampaignInitialState.push({ value: brand, label: brand });
    });
    console.log(selectedMarketingCampaignInitialState, 'MARKETING');
  }
  if (initialSearch.has('filters.dealType') && initialSearch.getAll('filters.dealType').length > 0) {
    Object.keys(dealTypeFilters).map((key) => {
      dealTypeFilters[key] = false;
      return true;
    });
    initialSearch.getAll('filters.dealType').forEach((type) => {
      dealTypeFilters[type] = true;
    });
  }
  if (initialSearch.has('filters.special') && initialSearch.getAll('filters.special').length > 0) {
    Object.keys(specialCriteriaFilters).map((key) => {
      specialCriteriaFilters[key] = false;
      return true;
    });
    initialSearch.getAll('filters.special').forEach((key) => {
      specialCriteriaFilters[key] = true;
    });
  }
  if (initialSearch.has('filters.category') && initialSearch.getAll('filters.category').length > 0) {
    Object.keys(categoryFilters).map((key) => {
      categoryFilters[key] = false;
      return true;
    });
    initialSearch.getAll('filters.category').forEach((key) => {
      categoryFilters[key] = true;
    });
  }
  if (initialSearch.has('filters.status') && initialSearch.getAll('filters.status').length > 0) {
    Object.keys(statusFilters).map((key) => {
      statusFilters[key] = false;
      return true;
    });
    initialSearch.getAll('filters.status').forEach((type) => {
      statusFilters[type] = true;
    });
  }
  if (initialSearch.has('filters.privacyStatus') && initialSearch.getAll('filters.privacyStatus').length > 0) {
    Object.keys(privacyStatus).map((key) => {
      privacyStatus[key] = false;
      return true;
    });
    initialSearch.getAll('filters.privacyStatus').forEach((type) => {
      privacyStatus[type] = true;
    });
  }
  if (initialSearch.has('filters.scorecardsCount') && initialSearch.getAll('filters.scorecardsCount').length > 0) {
    Object.keys(countFilters).map((key) => {
      countFilters[key] = false;
      return true;
    });
    initialSearch.getAll('filters.scorecardsCount').forEach((type) => {
      if (type === '0' || type === '1') {
        countFilters[type] = true;
      } else {
        countFilters['2+'] = true;
      }
    });
  }
  if (initialSearch.has('range') && initialSearch.getAll('range').length > 0) {
    initialSearch.getAll('range').forEach((range) => {
      selectedInitialRange = range;
    });
  }
  if (initialSearch.has('filters.createdAt.from') && initialSearch.getAll('filters.createdAt.from').length > 0) {
    initialSearch.getAll('filters.createdAt.from').forEach((from) => {
      selectedStartDateInitialState = moment(from).format('YYYY-MM-DD HH:mm:ss');
    });
  }
  if (initialSearch.has('filters.createdAt.to') && initialSearch.getAll('filters.createdAt.to').length > 0) {
    initialSearch.getAll('filters.createdAt.to').forEach((to) => {
      selectedEndDateInitialState = moment(to).format('YYYY-MM-DD HH:mm:ss');
    });
  }

  const getSingleAvgScoreInitial = () => {
    let singleAvgScore = '';
    const filters = parseUrl();
    const type = new URLSearchParams(window.location.search).get('averageScoreType');
    if (type && type !== 'Between') {
      if (type === '>=' || type === '=') {
        if (filters?.['averageScore.min']) {
          singleAvgScore = filters?.['averageScore.min'];
        }
      }
      if (type === '>') {
        if (filters?.['averageScore.min']) {
          singleAvgScore = Number(filters['averageScore.min']) - 1;
        }
      }
      if (type === '<=') {
        singleAvgScore = filters?.['averageScore.max'];
      }
      if (type === '<') {
        singleAvgScore = Number(filters['averageScore.max']) + 1;
      }
    }
    return parseInt(singleAvgScore, 10);
  };
  const getSingleDateInitial = () => {
    let singleDate = null;
    const filters = parseUrl();
    const type = new URLSearchParams(window.location.search).get('pdType');
    if (type && type !== 'Between') {
      if (type === '>') {
        if (filters?.['publishDate.from']) {
          singleDate = addDays(new Date(filters['publishDate.from']), -1);
        }
      } else if (type === '>=' || type === '=') {
        if (filters?.['publishDate.from']) {
          singleDate = new Date(filters['publishDate.from']);
        }
      } else if (type === '<') {
        singleDate = addDays(new Date(filters['publishDate.to']), 1);
      } else if (type === '<=') {
        singleDate = new Date(filters['publishDate.to']);
      }
    }
    return singleDate;
  };


  const filters = parseUrl();
  const [filtersStatus, setFiltersStatus] = useState(statusFilters);
  const [filtersPrivacyStatus, setFiltersPrivacyStatus] = useState(privacyStatus);
  const [filtersMarketingCampaign, setFiltersMarketingCampaign] = useState({});
  const [updateFiltersMarketingCampaign, setUpdateFiltersMarketingCampaign] = useState(false);
  const [filtersScorecard, setFiltersScorecard] = useState(countFilters);
  const [filtersDealType, setFiltersDealType] = useState(dealTypeFilters);
  const [filtersSpecialCriteria, setFiltersSpecialCriteria] = useState(specialCriteriaFilters);
  const [brandOptions, setBrandOptions] = useState(selectedBrandsInitialState);
  const [selectedBrands, setSelectedBrands] = useState(selectedBrandsInitialState);
  const [selectedMarketingCampaign, setSelectedMarketingCampaign] = useState(selectedMarketingCampaignInitialState);
  const [selectedCreators, setSelectedCreators] = useState(selectedCreatorsInitialState);
  const [cspOptions, setCspOptions] = useState(selectedCspsInitialState);
  const [amOptions, setAmOptions] = useState(selectedAmInitialState);
  const [selectedCsps, setSelectedCsps] = useState(selectedCspsInitialState);
  const [selectedAm, setSelectedAm] = useState(selectedAmInitialState);
  const [selectedCategories, setSelectedCategories] = useState(selectedCategoriesInitialState);
  const [categoriesOptions, setCategoriesOptions] = useState(selectedCategoriesInitialState);
  const [selectedTypes, setSelectedTypes] = useState(selectedTypesInitialState);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [startDate, setStartDate] = useState(selectedStartDateInitialState);
  const [endDate, setEndDate] = useState(selectedEndDateInitialState);
  const [selectedButton, setSelectedButton] = useState(selectedInitialRange);
  const [loadingTotals, setLoadingTotals] = useState(true);
  const [totals, setTotals] = useState({});
  const [searchValue, setSearchValue] = useState(initialSearch.get('filters.searchField') || '');
  const [showFilters, setShowFilters] = useState(getCookie('mediaContentShowFilters') === 'true');
  const [filterOptionsLoaded, setFilterOptionsLoaded] = useState(false);
  const [enableAverageScoreFilter, setEnableAverageScoreFilter] = useState(false);
  const [averageScoreTypeValue, setAverageScoreTypeValue] = useState({ value: averageScoreFilterType, label: averageScoreFilterType });
  const [singleAvgScore, setSingleAvgScore] = useState(getSingleAvgScoreInitial());
  const [minAvgScore, setMinAvgScore] = useState(filters?.['averageScore.min'] && averageScoreFilterType === 'Between' ? parseInt(filters['averageScore.min'], 10) : '');
  const [maxAvgScore, setMaxAvgScore] = useState(filters?.['averageScore.max'] && averageScoreFilterType === 'Between' ? parseInt(filters['averageScore.max'], 10) : '');
  const [questionScoreTypeValue, setQuestionScoreTypeValue] = useState({ value: questionScoreFilterType, label: questionScoreFilterType });
  const [singleQuestionScore, setSingleQuestionScore] = useState(null);
  const [minQuestionScore, setMinQuestionScore] = useState(null);
  const [maxQuestionScore, setMaxQuestionScore] = useState(null);
  const [scorecardQuestionOptions, setScorecardQuestionOptions] = useState(null);
  const [binaryQuestion, setBinaryQuestion] = useState(null);
  const [filtersCategory, setFiltersCategory] = useState(categoryFilters);
  const [resetClicked, setResetClicked] = useState(false);
  // const [showNotes, setShowNotes] = useState(false);

  const [showTotalsModal] = Hook.useShowModalHook('mediaContentTotals');


  const lastADType = getFilterValueInitial('pdType');
  const [dateFrom, setDateFrom] = useState(filters?.['publishDate.from'] && lastADType === 'Between' ? moment(moment.utc(filters['publishDate.from']).format('YYYY-MM-DD')).endOf('day').toDate() : null);
  const [dateTo, setDateTo] = useState(filters?.['publishDate.to'] && lastADType === 'Between' ? moment(moment.utc(filters['publishDate.to']).format('YYYY-MM-DD')).endOf('day').toDate() : null);
  const [singleDate, setSingleDate] = useState(getSingleDateInitial());
  const [lastActivityDateValue, setLastActivityDateValue] = useState({ value: lastADType, label: lastADType });


  useEffect(() => {
    setCookie('mediaContentShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  const history = useHistory();

  const fetchScorecardQuestions = (type) => {
    try {
      axios.get(`/api/portal/scorecard/get-scorecard-questions?type=${type}`).then((responce) => {
        if (responce.data.success) {
          let questionOptions = [];
          responce.data.scorecard.questions.forEach((item) => {
            const qArray = item.questions.map(question => ({ label: question.question, value: question._id, type: question.questionType }));
            questionOptions = questionOptions.concat(qArray);
          });
          console.log('questionOptions: ', questionOptions);
          setScorecardQuestionOptions(questionOptions);
        }
      });
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  const fetchTotalsData = async () => {
    setLoadingTotals(true);
    try {
      const search = new URLSearchParams(window.location.search);
      const resp = await axios.get(`/api/portal/scorecard/media-content/get-media-content-totals-data?${search.toString()}`);
      if (resp?.data?.success) {
        // console.log(resp.data);
        const totalAverageScore = resp.data.totalAverageScore === '' ? <span>&mdash;</span> : resp.data.totalAverageScore;
        setTotals({
          totalSubmittedContent: resp.data.totalSubmittedContent,
          totalAverageScore,
          totalGrossProfit: resp.data.totalGrossProfit,
          uniqNumberCreators: resp.data.uniqNumberCreators,
        });
        setLoadingTotals(false);
      } else {
        throw new Error(resp?.data?.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  // let fetchTotalsDataTimer = setTimeout(() => console.log('timer'), 1500);
  //
  // const restartFetchTotalsDataTimer = () => {
  //   clearTimeout(fetchTotalsDataTimer);
  //   fetchTotalsDataTimer = setTimeout(fetchTotalsData, 500);
  // };

  const setFilterOptionsLoadedTrueDebounced = useAsyncDebounce(() => {
    setFilterOptionsLoaded(true);
  }, 500);

  const fetchFilterData = async () => {
    setLoadingFilters(true);
    try {
      const search = new URLSearchParams(window.location.search);

      fetchDictionary('PROSPECT_').then((data) => {
        const { success, records } = data;
        if (success === true) {
          const _categories = records.PROSPECT_CATEGORIES ? JSON.parse(records.PROSPECT_CATEGORIES) : [];
          const _categorySuggestions = _categories.map(row => ({ value: row, label: row }));
          _categorySuggestions.unshift({ value: 'null', label: '-- Not Assigned --' });
          setCategoriesOptions(_categorySuggestions);

          const foundCategoriesOptions = [];
          if (search.has('filters.categories') && search.getAll('filters.categories').length > 0) {
            search.getAll('filters.categories').forEach((category) => {
              const foundOption = _categorySuggestions.find(option => option.value === category);
              if (foundOption) foundCategoriesOptions.push(foundOption);
            });
          }
          if (foundCategoriesOptions.length > 0) setSelectedCategories(foundCategoriesOptions);
        }
      }).catch((err) => {
        console.log(err);
      });

      const resp = await axios.get('/api/portal/scorecard/media-content/get-filters-data-set');
      if (resp?.data?.success) {
        setCspOptions(resp?.data?.cspOptions || []);
        setBrandOptions(resp?.data?.brandOptions?.sort(compare) || []);
        setAmOptions(resp?.data?.accountManagersOptions || []);

        const foundTypeOptions = [];
        const foundCspOptions = [];
        const foundBrandnOptions = [];

        if (search.has('filters.type') && search.getAll('filters.type').length > 0) {
          search.getAll('filters.type').forEach((type) => {
            const foundOption = typeFilterOptions.find(option => option.value === type);
            if (foundOption) foundTypeOptions.push(foundOption);
          });
        }
        if (foundTypeOptions.length > 0) setSelectedTypes(foundTypeOptions);

        if (search.has('filters.submitter') && search.getAll('filters.submitter').length > 0) {
          search.getAll('filters.submitter').forEach((submitter) => {
            const foundOption = resp?.data?.cspOptions?.find(option => option.value === submitter);
            if (foundOption) foundCspOptions.push(foundOption);
          });
        }
        if (foundCspOptions.length > 0) setSelectedCsps(foundCspOptions);

        if (search.has('filters.brand') && search.getAll('filters.brand').length > 0) {
          search.getAll('filters.brand').forEach((brand) => {
            const foundOption = resp?.data?.brandOptions?.find(option => option.value === brand);
            if (foundOption) foundBrandnOptions.push(foundOption);
          });
        }
        if (foundBrandnOptions.length > 0) setSelectedBrands(foundBrandnOptions);

        const r = await axios({
          method: 'get',
          url: '/api/portal/adminUsers/get-marketing-campaing',
        });
        const mc = {};
        r.data?.marketingCampaigns?.forEach((item) => { mc[item.value] = item.value; });
        setFiltersMarketingCampaign(mc);

        setLoadingFilters(false);
        setFilterOptionsLoadedTrueDebounced();
      } else {
        throw new Error(resp?.data?.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  const selectItem = (value, type) => {
    switch (type) {
      case 'creator':
        setSelectedCreators(value);
        break;
      case 'type':
        setSelectedTypes(value);
        setSelectedQuestion(null);
        break;
      case 'csp':
        setSelectedCsps(value);
        break;
      case 'brand':
        setSelectedBrands(value);
        break;
      case 'marketingCampaign':
        setSelectedMarketingCampaign(value);
        break;
      case 'categories':
        setSelectedCategories(value);
        break;
      case 'am':
        setSelectedAm(value);
        break;
      default:
        return false;
    }
    return true;
  };

  const firstUpdate = useRef(true);
  const firstUpdateMC = useRef(true);

  const setUrl = (removeBeforeSet = false) => {
    let search = new URLSearchParams(window.location.search);
    const oldUrl = `${history.location.pathname}?${search.toString()}`;

    if (removeBeforeSet) {
      search = new URLSearchParams();
    }

    if (resetClicked) {
      search.delete('filters.deal');
    }
    search.delete('filters.searchField');
    if (searchValue) search.append('filters.searchField', searchValue);

    search.delete('filters.createdAt.from');
    search.delete('filters.createdAt.to');
    search.delete('range');
    if (startDate !== '') {
      search.append('filters.createdAt.from', startDate);
    }
    if (endDate !== '') {
      search.append('filters.createdAt.to', endDate);
    }
    search.append('range', selectedButton);

    const filterCreators = search.getAll('filters.creator');
    let deleteFilterCreators = false;

    console.log('SELECTED: ', selectedCreators);
    if (!selectedCreators || selectedCreators.length === 0) {
      search.delete('filters.creator');
    }
    if (selectedCreators) {
      filterCreators.forEach((creator) => {
        if (selectedCreators.filter(item => item.value === creator).length === 0) {
          deleteFilterCreators = true;
        }
      });
      if (deleteFilterCreators) {
        search.delete('filters.creator');
      }
      selectedCreators.forEach((creator) => {
        if (creator.value !== '' && (!filterCreators.includes(creator.value) || deleteFilterCreators)) {
          search.append('filters.creator', creator.value);
        }
      });
    }

    const searchCreatorsEmails = (search.getAll('filters.creator') || []).map(email => decodeURI(email)).sort();
    const filterCreatorsEmails = (selectedCreators || []).map(option => decodeURI(option.value)).sort();
    const isEqual = _.isEqual(searchCreatorsEmails, filterCreatorsEmails);
    if (!isEqual) {
      if (!selectedCreators || selectedCreators.length === 0) {
        search.delete('filters.creator');
      }
      if (selectedCreators) {
        filterCreators.forEach((creator) => {
          if (selectedCreators.filter(item => item.value === creator).length === 0) {
            deleteFilterCreators = true;
          }
        });
        if (deleteFilterCreators) {
          search.delete('filters.creator');
        }
        selectedCreators.forEach((selectedCreator) => {
          if (selectedCreator.value !== '' && (!filterCreators.includes(selectedCreator.value) || deleteFilterCreators)) {
            search.append('filters.creator', selectedCreator.value);
          }
        });
      }
    }
    const filterMarketingCampaign = search.getAll('filters.marketingCampaign');
    let deleteFilterCampaign = false;

    if (!selectedMarketingCampaign || selectedMarketingCampaign.length === 0) {
      search.delete('filters.marketingCampaign');
    }
    if (selectedMarketingCampaign) {
      filterMarketingCampaign.forEach((brand) => {
        if (selectedMarketingCampaign.filter(item => item.value === brand).length === 0) {
          deleteFilterCampaign = true;
        }
      });
      if (deleteFilterCampaign) {
        search.delete('filters.marketingCampaign');
      }
      selectedMarketingCampaign.forEach((brand) => {
        if (brand.value !== '' && (!filterMarketingCampaign.includes(brand.value) || deleteFilterCampaign)) {
          search.append('filters.marketingCampaign', brand.value);
        }
      });
    }

    const filterBrands = search.getAll('filters.brand');
    let deleteFilterBrands = false;

    if (!selectedBrands || selectedBrands.length === 0) {
      search.delete('filters.brand');
    }
    if (selectedBrands) {
      filterBrands.forEach((brand) => {
        if (selectedBrands.filter(item => item.value === brand).length === 0) {
          deleteFilterBrands = true;
        }
      });
      if (deleteFilterBrands) {
        search.delete('filters.brand');
      }
      selectedBrands.forEach((brand) => {
        if (brand.value !== '' && (!filterBrands.includes(brand.value) || deleteFilterBrands)) {
          search.append('filters.brand', brand.value);
        }
      });
    }

    const filterCSP = search.getAll('filters.submitter');
    let deleteFilterCSP = false;

    if (!selectedCsps || selectedCsps.length === 0) {
      search.delete('filters.submitter');
    }
    if (selectedCsps) {
      filterCSP.forEach((csp) => {
        if (selectedCsps.filter(item => item.value === csp).length === 0) {
          deleteFilterCSP = true;
        }
      });
      if (deleteFilterCSP) {
        search.delete('filters.submitter');
      }
      selectedCsps.forEach((csp) => {
        if (csp.value !== '' && (!filterCSP.includes(csp.value) || deleteFilterCSP)) {
          search.append('filters.submitter', csp.value);
        }
      });
    }

    const filterAM = search.getAll('filters.accountManager');
    let deleteFilterAM = false;

    if (!selectedAm || selectedAm.length === 0) {
      search.delete('filters.accountManager');
    }
    if (selectedAm) {
      filterAM.forEach((am) => {
        if (selectedAm.filter(item => item.value === am).length === 0) {
          deleteFilterAM = true;
        }
      });
      if (deleteFilterAM) {
        search.delete('filters.accountManager');
      }
      selectedAm.forEach((am) => {
        if (am.value !== '' && (!filterAM.includes(am.value) || deleteFilterAM)) {
          search.append('filters.accountManager', am.value);
        }
      });
    }

    const filterCategories = search.getAll('filters.categories');
    let deleteFilterCategories = false;

    if (!selectedCategories || selectedCategories.length === 0) {
      search.delete('filters.categories');
    }
    if (selectedCategories) {
      filterCategories.forEach((csp) => {
        if (selectedCategories.filter(item => item.value === csp).length === 0) {
          deleteFilterCategories = true;
        }
      });
      if (deleteFilterCategories) {
        search.delete('filters.categories');
      }
      selectedCategories.forEach((csp) => {
        if (csp.value !== '' && (!filterCategories.includes(csp.value) || deleteFilterCategories)) {
          search.append('filters.categories', csp.value);
        }
      });
    }

    const filtersTypes = search.getAll('filters.type');
    let deleteFilterTypes = false;

    if (!selectedTypes || selectedTypes.length === 0) {
      search.delete('filters.type');
    }
    if (selectedTypes) {
      filtersTypes.forEach((type) => {
        if (selectedTypes.filter(item => item.value === type).length === 0) {
          deleteFilterTypes = true;
        }
      });
      if (deleteFilterTypes) {
        search.delete('filters.submitter');
      }
      selectedTypes.forEach((selectedType) => {
        if (selectedType.value !== '' && (!filtersTypes.includes(selectedType.value) || deleteFilterTypes)) {
          search.append('filters.type', selectedType.value);
        }
      });
    }

    // console.log('SET URL');
    search.delete('filters.averageScore.min');
    search.delete('filters.averageScore.max');
    search.delete('averageScoreType');
    if (minAvgScore && maxAvgScore && averageScoreTypeValue.value === 'Between') {
      search.append('filters.averageScore.min', minAvgScore);
      search.append('filters.averageScore.max', maxAvgScore);
    }
    if (singleAvgScore) {
      if (averageScoreTypeValue.value === '>') {
        search.append('filters.averageScore.min', Number(singleAvgScore) + 1);
      }
      if (averageScoreTypeValue.value === '>=') {
        search.append('filters.averageScore.min', singleAvgScore);
      }
      if (averageScoreTypeValue.value === '<') {
        search.append('filters.averageScore.max', Number(singleAvgScore) - 1);
      }
      if (averageScoreTypeValue.value === '<=') {
        search.append('filters.averageScore.max', singleAvgScore);
      }
      if (averageScoreTypeValue.value === '=') {
        search.append('filters.averageScore.min', singleAvgScore);
        search.append('filters.averageScore.max', singleAvgScore);
      }
    }
    if (averageScoreTypeValue.value && ((minAvgScore && maxAvgScore) || singleAvgScore)) {
      setEnableAverageScoreFilter(true);
      search.append('averageScoreType', averageScoreTypeValue.value);
    }
    if (filtersCategory && Object.keys(filtersCategory).length > 0) {
      search.delete('filters.category');
      let allCategorySwitchedOff = true;
      Object.keys(filtersCategory).forEach((key) => {
        if (!filtersCategory[key]) return false;
        search.append('filters.category', key);
        allCategorySwitchedOff = false;
        return true;
      });
      if (allCategorySwitchedOff) {
        search.append('filters.category', 'Not Exist');
      }
    }

    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else if (filterOptionsLoaded) {
      search.delete('filters.status');
      let allSwitchedOffStatus = true;
      if (filtersStatus) {
        Object.keys(filtersStatus).forEach((key) => {
          if (filtersStatus[key]) {
            allSwitchedOffStatus = false;
            search.append('filters.status', key);
          }
        });
      }
      if (allSwitchedOffStatus) {
        search.append('filters.status', 'Not Exist');
      }
      search.delete('filters.privacyStatus');
      if (filtersPrivacyStatus) {
        Object.keys(filtersPrivacyStatus).forEach((key) => {
          if (filtersPrivacyStatus[key]) {
            allSwitchedOffStatus = false;
            search.append('filters.privacyStatus', key);
          }
        });
      }
      search.delete('filters.publishDate.from');
      search.delete('filters.publishDate.to');
      search.delete('pdType');
      const st = dateFrom ? moment.utc(moment(dateFrom).format('YYYY-MM-DD')).toDate() : '';
      const end = dateTo ? moment.utc(moment(dateTo).format('YYYY-MM-DD')).endOf('day').toDate() : '';
      const single = singleDate ? moment.utc(moment(singleDate).format('YYYY-MM-DD')).toDate() : '';
      if (dateFrom && dateTo && lastActivityDateValue.value === 'Between') {
        search.append('filters.publishDate.from', st.toISOString());
        search.append('filters.publishDate.to', end.toISOString());
      }
      if (singleDate && singleDate !== '') {
        if (lastActivityDateValue.value === '>') {
          search.append('filters.publishDate.from', addDays(single, 1).toISOString());
        } else if (lastActivityDateValue.value === '>=') {
          search.append('filters.publishDate.from', single.toISOString());
        } else if (lastActivityDateValue.value === '<') {
          search.append('filters.publishDate.to', addDays(single, -1).toISOString());
        } else if (lastActivityDateValue.value === '<=') {
          search.append('filters.publishDate.to', single.toISOString());
        } else if (lastActivityDateValue.value === '=') {
          search.append('filters.publishDate.from', single.toISOString());
          search.append('filters.publishDate.to', single.toISOString());
        }
      }
      if (lastActivityDateValue.value && ((dateTo && dateFrom) || singleDate)) {
        search.append('pdType', lastActivityDateValue.value);
      }


      search.delete('filters.scorecardsCount');
      let allSwitchedOffScorecard = true;
      if (filtersScorecard) {
        if (filtersScorecard[0]) {
          allSwitchedOffScorecard = false;
          search.append('filters.scorecardsCount', '0');
        }
        if (filtersScorecard[1]) {
          allSwitchedOffScorecard = false;
          search.append('filters.scorecardsCount', '1');
        }
        if (filtersScorecard['2+']) {
          allSwitchedOffScorecard = false;
          search.append('filters.scorecardsCount', '2');
          search.append('filters.scorecardsCount', '3');
        }
      }
      if (allSwitchedOffScorecard) {
        search.append('filters.scorecardsCount', 'Not Exist');
      }

      search.delete('filters.dealType');
      let allSwitchedOffDealType = true;
      if (filtersDealType) {
        Object.keys(filtersDealType).forEach((key) => {
          if (filtersDealType[key]) {
            allSwitchedOffDealType = false;
            search.append('filters.dealType', key);
          }
        });
      }
      if (allSwitchedOffDealType) {
        search.append('filters.dealType', 'Not Exist');
      }

      search.delete('filters.special');
      let allSwitchedOffSpecialCriteria = true;
      if (filtersSpecialCriteria) {
        Object.keys(filtersSpecialCriteria).forEach((key) => {
          if (filtersSpecialCriteria[key]) {
            allSwitchedOffSpecialCriteria = false;
            search.append('filters.special', key);
          }
        });
      }
      if (allSwitchedOffSpecialCriteria) {
        search.append('filters.special', 'Not Exist');
      }

      search.delete('filters.questionScore');
      if (singleQuestionScore) {
        if (averageScoreTypeValue.value === '>') {
          search.append('filters.questionScore', `${selectedQuestion.value}_sep_gt_sep_${singleQuestionScore}`);
        }
        if (questionScoreTypeValue.value === '>=') {
          search.append('filters.questionScore', `${selectedQuestion.value}_sep_gte_sep_${singleQuestionScore}`);
        }
        if (questionScoreTypeValue.value === '<') {
          search.append('filters.questionScore', `${selectedQuestion.value}_sep_lt_sep_${singleQuestionScore}`);
        }
        if (questionScoreTypeValue.value === '<=') {
          search.append('filters.questionScore', `${selectedQuestion.value}_sep_lte_sep_${singleQuestionScore}`);
        }
        if (questionScoreTypeValue.value === '=') {
          search.append('filters.questionScore', `${selectedQuestion.value}_sep_eq_sep_${singleQuestionScore}`);
        }
      }
      if (minQuestionScore && maxQuestionScore && averageScoreTypeValue.value === 'Between') {
        search.append('filters.questionScore', `${selectedQuestion.value}_sep_between_sep_${minQuestionScore}_sep_${maxQuestionScore}`);
      }
      if (binaryQuestion !== null) {
        search.append('filters.questionScore', `${selectedQuestion.value}_sep_binary_sep_${binaryQuestion.toString()}`);
      }
    }

    let newUrl = `${history.location.pathname}?${search.toString()}`;
    if (oldUrl !== newUrl) {
      search.set('page', '1');
      newUrl = `${history.location.pathname}?${search.toString()}`;
      history.push(newUrl);
      // restartFetchTotalsDataTimer();
    }
  };

  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 800);

  useEffect(() => {
    setUrlDebounced();
  }, [
    searchValue,
    startDate,
    endDate,
    selectedCreators,
    selectedBrands,
    selectedCsps,
    selectedAm,
    selectedCategories,
    selectedTypes,
    filtersStatus,
    filtersScorecard,
    filtersDealType,
    filtersSpecialCriteria,
    filtersCategory,
    selectedMarketingCampaign,
    singleAvgScore, minAvgScore, maxAvgScore,
    singleQuestionScore, minQuestionScore, maxQuestionScore,
    binaryQuestion,
    filtersPrivacyStatus,
    singleDate, dateTo, dateFrom,
  ]);
  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    if (search.has('pdType') && search.get('pdType') !== lastActivityDateValue.value) {
      setUrl();
    }
  }, [lastActivityDateValue]);
  useEffect(() => {
    if (averageScoreTypeValue.value === 'Between') {
      setSingleAvgScore('');
    } else {
      setMinAvgScore('');
      setMaxAvgScore('');
    }
    if (averageScoreTypeValue && averageScoreTypeValue.value && singleAvgScore) {
      setUrlDebounced();
    }
  }, [averageScoreTypeValue]);

  useEffect(() => {
    if (questionScoreTypeValue.value === 'Between') {
      setSingleQuestionScore('');
    } else {
      setMinQuestionScore('');
      setMaxQuestionScore('');
    }
    if (questionScoreTypeValue && questionScoreTypeValue.value && singleQuestionScore) {
      setUrlDebounced();
    }
  }, [questionScoreTypeValue]);

  useEffect(() => {
    if (selectedTypes && selectedTypes.length === 1) {
      fetchScorecardQuestions(selectedTypes[0].value);
    }
  }, [selectedTypes]);

  useEffect(() => {
    if (selectedQuestion && selectedQuestion.type !== 'Binary') {
      setBinaryQuestion(null);
    } else {
      setMaxQuestionScore('');
      setMinQuestionScore('');
      setSingleQuestionScore('');
    }
  }, [selectedQuestion]);

  const setUrlMC = () => {
    const search = new URLSearchParams(window.location.search);
    const oldUrl = `${history.location.pathname}?${search.toString()}`;

    if (firstUpdateMC.current) {
      firstUpdateMC.current = false;
      return;
    }
    if (!updateFiltersMarketingCampaign) {
      setUpdateFiltersMarketingCampaign(true);
      return;
    }
    const filtersMC = initialSearch.getAll('filters.marketingCampaign');
    const selectedFilterMC = Object.keys(filtersMarketingCampaign).filter(key => !!filtersMarketingCampaign[key]);
    if (!_.isEqual(filtersMC, selectedFilterMC)) {
      search.delete('filters.marketingCampaign');
      if (filtersMarketingCampaign) {
        Object.keys(filtersMarketingCampaign).forEach((key) => {
          if (filtersMarketingCampaign[key]) {
            search.append('filters.marketingCampaign', key);
          }
        });
      }
    }

    let newUrl = `${history.location.pathname}?${search.toString()}`;
    if (oldUrl !== newUrl) {
      search.set('page', '1');
      newUrl = `${history.location.pathname}?${search.toString()}`;
      history.push(newUrl);
      // restartFetchTotalsDataTimer();
    }
  };

  const setUrlDebouncedMC = useAsyncDebounce(() => {
    setUrlMC();
  }, 800);

  useEffect(() => {
    setUrlDebouncedMC();
  }, [filtersMarketingCampaign]);

  useEffect(() => {
    fetchFilterData();
    if (initialSearch.has('filters.creator') && initialSearch.getAll('filters.creator').length > 0) {
      loadCreatorsOptions('getByIds').then((response) => {
        setSelectedCreators(response);
      });
    }
  }, []);

  useEffect(async () => {
    const resp = await axios.get('/api/portal/scorecard/media-content/get-first-media-creation-date');
    if (resp?.data?.success) {
      setAllTimeStartDate(resp?.data.allTimeStartDate);
    }
  }, []);

  const customColumns = useMemo(() => getTableColumns(setVersion), []);

  const handleChangeStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFiltersStatus({
        ...filtersStatus,
        [name]: checked,
      });
    }
  };
  const handleChangePrivacyStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFiltersPrivacyStatus({
        ...filtersPrivacyStatus,
        [name]: checked,
      });
    }
  };

  const handleChangeCategoryFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFiltersCategory({
        ...filtersCategory,
        [name]: checked,
      });
    }
  };

  const handleChangeScorecardFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFiltersScorecard({
        ...filtersScorecard,
        [name]: checked,
      });
    }
  };

  const handleChangeDealTypeFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFiltersDealType({
        ...filtersDealType,
        [name]: checked,
      });
    }
  };

  const handleChangeSpecialCriteriaFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFiltersSpecialCriteria({
        ...filtersSpecialCriteria,
        [name]: checked,
      });
    }
  };

  const handleChangeAverageScoreCheckBox = ({ target }) => {
    setEnableAverageScoreFilter(!enableAverageScoreFilter);
    if (!target.checked) {
      setSingleAvgScore('');
      setMinAvgScore('');
      setMaxAvgScore('');
    }
  };

  const handleDownloadCSV = () => {
    window.location.href = `/resources/MediaContentResource/download-csv?${new URLSearchParams(window.location.search).toString()}`;
  };
  const filtersMarketingCampaignOptions = useMemo(() => Object.keys(filtersMarketingCampaign).map(key => ({ value: key, label: filtersMarketingCampaign[key] })), [filtersMarketingCampaign]);

  const resetFilters = () => {
    setSearchValue('');
    setSelectedBrands([]);
    setSelectedCreators([]);
    setSelectedCsps([]);
    setSelectedCategories([]);
    setSelectedTypes([]);
    setFiltersStatus({
      New: true,
      'Pending Edits': true,
      Rescore: true,
      'First Score': true,
      'Second Score': true,
      'Awaiting Brand Approval': true,
      'Brand Approved': true,
    });
    setFiltersPrivacyStatus({
      na: true,
      unlisted: true,
      public: true,
      private: true,
    });
    setFiltersScorecard({
      0: true,
      1: true,
      '2+': true,
    });
    setFiltersDealType({
      'CPA/Ambassador': true,
      'Upfront Only': true,
      Hybrid: true,
      'Upfront CPM': true,
      'Agent Commission': true,
      'Media License': true,
    });
    setFiltersSpecialCriteria({
      'Oscar Worthy': true,
      'Not Oscar Worthy': true,
    });
    setFiltersCategory({
      Assigned: true,
      'Not Assigned': true,
    });
    setFiltersMarketingCampaign([]);
    setSelectedButton('current_month');
    setResetClicked(true);
  };

  const links = [
    { title: 'Home', path: '/' },
    { title: 'Compliance', path: false },
    { title: 'Media Content', path: false },
  ];

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs links={links} isBackButton />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody style={{ padding: '2rem 2rem 0 2rem' }}>
              <CardTitle>
                <h3>Media Content</h3>
                <hr className="mt-3 mb-4" />
              </CardTitle>
              <Row>
                <Col md={10}>
                  { allTimeStartDate && (
                    <TopButtonsPeriod
                      selectedButtonInitial={selectedButton}
                      startDateInitial={selectedStartDateInitialState}
                      endDateInitial={selectedEndDateInitialState}
                      allTimeStartDate={allTimeStartDate}
                      setValue={(param, value, range) => {
                      if (param === 'startDate') {
                        setStartDate(moment(value).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
                      }
                      if (param === 'endDate') {
                        setEndDate(moment(value).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
                      }
                      setSelectedButton(range);
                    }}
                    />
                  )}
                </Col>
                <Col md={2} className="text-right">
                  <Button className="btn btn-primary btn-sm" onClick={() => setUrl(true)} style={{ color: 'white' }} >Refresh</Button>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={8}>
                  <InputGroup>
                    <Input
                      className="form-control-sm form-control"
                      name="search"
                      type="search"
                      autoComplete="off"
                      placeholder="Search by Name, Title"
                      value={searchValue}
                      onChange={p => setSearchValue(p.target.value)}
                      style={{ height: '38px' }}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <i
                          className="fa fa-search"
                          aria-hidden="true"
                          style={{ color: '#70bbfd', fontSize: 12 }}
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col sm={2}>
                  <Row>
                    <Col sm={8} style={{ paddingRight: '0' }}>
                      <Tooltip title="Reset Filters">
                        <Button
                          color="secondary"
                          size="sm"
                          onClick={resetFilters}
                          style={{
                            width: '100%',
                            padding: '4px 8px',
                            height: '38px',
                          }}
                        >
                          <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                          <span className="btn-text">Reset</span>
                        </Button>
                      </Tooltip>
                    </Col>
                    <Col sm={1} style={{ paddingLeft: '24px' }}>
                      <Tooltip title="Toggle Filters">
                        <IconButton
                          id="filtersMenuButton"
                          size="small"
                          onClick={() => setShowFilters(!showFilters)}
                          className="material-table__toolbar-button"
                          style={{
                            height: '38px',
                          }}
                        >
                          <TuneIcon size="30" />
                        </IconButton>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
                <Col md={2} className="text-right">
                  <UncontrolledButtonDropdown className="w-100">
                    <DropdownToggle
                      id="actionsMenu"
                      caret
                      color="primary"
                      style={{
                        padding: '4px 25px',
                        height: '38px',
                      }}
                    >
                      Actions
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem onClick={() => { handleDownloadCSV(); }}>Download Content CSV</DropdownItem>
                      <DropdownItem
                        onClick={() => { showTotalsModal(); }}
                      >
                        Display Media Content Totals
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </Col>
              </Row>
              { showFilters && (
                <>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label className="bold-text">
                          Brand(s)
                        </Label>
                        <Select
                          isMulti
                          name="brandSelect"
                          isDisabled={loadingFilters}
                          isLoading={loadingFilters}
                          isClearable
                          value={!loadingFilters ? selectedBrands : []}
                          options={brandOptions}
                          onChange={value => selectItem(value, 'brand')}
                          placeholder="Select Brand..."
                          style={{ maxHeight: '120px' }}
                          styles={colourStyles}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="bold-text">
                          Creator(s)
                        </Label>
                        <SelectAsync
                          cacheOptions
                          defaultOptions
                          isClearable
                          isMulti
                          loadOptions={async value => loadCreatorsOptions(value)}
                          onChange={value => selectItem(value, 'creator')}
                          placeholder="Select Creator..."
                          value={selectedCreators}
                          styles={colourStyles}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="bold-text">
                          Submitted By 
                        </Label>
                        <Select
                          isMulti
                          name="cspSelect"
                          disabled={loadingFilters}
                          isDisabled={loadingFilters}
                          isLoading={loadingFilters}
                          isClearable
                          value={!loadingFilters ? selectedCsps : []}
                          options={cspOptions}
                          onChange={value => selectItem(value, 'csp')}
                          placeholder="Select Submitted By..."
                          style={{ maxHeight: '120px' }}
                          styles={colourStyles}
                        />
                      </FormGroup>
                    </Col>
                    <Col
                      style={{ display: Object.keys(filtersMarketingCampaignOptions).length > 0 ? 'block' : 'none' }}
                    >
                      <Label
                        className="bold-text"
                        style={{ marginBottom: '8px' }}
                      >
                        Marketing Campaign
                      </Label>
                      <Select
                        isMulti
                        name="mCampaignSelect"
                        isDisabled={loadingFilters}
                        isLoading={loadingFilters}
                        isClearable
                        value={selectedMarketingCampaign || []}
                        options={filtersMarketingCampaignOptions}
                        onChange={value => selectItem(value, 'marketingCampaign')}
                        placeholder="Select Marketing Campaign..."
                        style={{ maxHeight: '120px' }}
                        styles={colourStyles}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <Label className="bold-text">
                        Categories
                      </Label>
                      <Select
                        isMulti
                        name="categoriesSelect"
                        disabled={loadingFilters}
                        isDisabled={loadingFilters}
                        isLoading={loadingFilters}
                        isClearable
                        value={!loadingFilters ? selectedCategories : []}
                        options={categoriesOptions}
                        onChange={value => selectItem(value, 'categories')}
                        placeholder="Select Category..."
                        style={{ maxHeight: '120px' }}
                        styles={colourStyles}
                      />
                    </Col>
                    <Col sm={3}>
                      <FormGroup>
                        <Label className="bold-text">
                          Type(s)
                        </Label>
                        <Select
                          isMulti
                          name="typeSelect"
                          isDisabled={loadingFilters}
                          isLoading={loadingFilters}
                          isClearable
                          value={!loadingFilters ? selectedTypes : []}
                          options={typeFilterOptions}
                          onChange={value => selectItem(value, 'type')}
                          placeholder="Select Type..."
                          style={{ maxHeight: '120px' }}
                          styles={colourStyles}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup>
                        <Label className="bold-text">
                          Account Manager
                        </Label>
                        <Select
                          isMulti
                          name="accountMaSelect"
                          disabled={loadingFilters}
                          isDisabled={loadingFilters}
                          isLoading={loadingFilters}
                          isClearable
                          value={!loadingFilters ? selectedAm : []}
                          options={amOptions}
                          onChange={value => selectItem(value, 'am')}
                          placeholder="Select Account Manager..."
                          style={{ maxHeight: '120px' }}
                          styles={colourStyles}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      {selectedTypes && selectedTypes.length === 1 ? (
                        <FormGroup>
                          <Label className="bold-text">
                            Scorecard Question
                          </Label>
                          <Select
                            name="questionScore"
                            isClearable
                            placeholder="Select Question..."
                            value={selectedQuestion}
                            onChange={value => setSelectedQuestion(value)}
                            options={scorecardQuestionOptions || []}
                          />
                        </FormGroup>
                      ) : null}
                    </Col>
                    <Col sm={4}>
                      <Row>
                        <Col sm={6}>
                          {selectedTypes && selectedTypes.length === 1 ? (
                            <FormGroup>
                              <Label className="bold-text">
                                Question Score
                              </Label>
                              {selectedTypes && selectedTypes.length === 1 && selectedQuestion && selectedQuestion.type === 'Binary' ? (
                                <FormGroup
                                  style={{ marginLeft: '30px', marginTop: '5px' }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Input
                                        // disabled={disable}
                                        type="radio"
                                        name="binaryYes"
                                        value="true"
                                        className="mt-0"
                                        checked={binaryQuestion === true}
                                        onChange={() => setBinaryQuestion(true)}
                                      />
                                    }
                                    label="Yes"
                                  />&nbsp;&nbsp;
                                  <FormControlLabel
                                    style={{ marginLeft: '10px' }}
                                    control={
                                      <Input
                                        // disabled={disable}
                                        type="radio"
                                        name="binaryNo"
                                        value="false"
                                        className="mt-0"
                                        checked={binaryQuestion === false}
                                        onChange={() => setBinaryQuestion(false)}
                                      />
                                    }
                                    label="No"
                                  />
                                </FormGroup>
                              ) : (
                                <Select
                                  value={questionScoreTypeValue}
                                  onChange={setQuestionScoreTypeValue}
                                  options={averageScoreLabels}
                                  isDisabled={!selectedQuestion}
                                />
                              )}
                            </FormGroup>
                          ) : null}
                        </Col>
                        {selectedTypes && selectedTypes.length === 1 && (!selectedQuestion || selectedQuestion.type === 'Scored') ? (
                          <Col sm={6} style={{ marginTop: '31px' }}>
                            {questionScoreTypeValue.value === 'Between' ? (
                              <FormGroup>
                                <NumberFormat
                                  key="averageMin"
                                  disabled={!selectedQuestion}
                                  thousandSeparator
                                  prefix=""
                                  name="what"
                                  value={minQuestionScore}
                                  className="form-control"
                                  placeholder="Min Score"
                                  inputMode="numeric"
                                  allowEmptyFormatting={false}
                                  fixedDecimalScale
                                  decimalScale={0}
                                  onValueChange={values => setMinQuestionScore(values.floatValue)}
                                  allowNegative={false}
                                  onBlur={() => {
                                    if (
                                      minQuestionScore
                                      && maxQuestionScore
                                      && (minQuestionScore > maxQuestionScore)
                                    ) {
                                      setMinQuestionScore(maxQuestionScore - 1);
                                    }
                                  }}
                                />
                                <NumberFormat
                                  key="scoreMax"
                                  disabled={!selectedQuestion}
                                  thousandSeparator
                                  prefix=""
                                  name="what"
                                  value={maxQuestionScore}
                                  className="form-control"
                                  style={{ marginTop: '16px' }}
                                  placeholder="Max Score"
                                  inputMode="numeric"
                                  allowEmptyFormatting={false}
                                  fixedDecimalScale
                                  decimalScale={0}
                                  onValueChange={values => setMaxQuestionScore(values.floatValue)}
                                  allowNegative={false}
                                  onBlur={() => {
                                    if (
                                      minQuestionScore
                                      && maxQuestionScore
                                      && (minQuestionScore > maxQuestionScore)
                                    ) {
                                      setMaxQuestionScore(minQuestionScore + 1);
                                    }
                                  }}
                                />
                              </FormGroup>
                            ) : (
                              <NumberFormat
                                key="singleAvgScore"
                                disabled={!selectedQuestion}
                                thousandSeparator
                                prefix=""
                                name="what"
                                value={singleQuestionScore}
                                className="form-control"
                                placeholder="Score"
                                inputMode="numeric"
                                allowEmptyFormatting={false}
                                fixedDecimalScale
                                decimalScale={0}
                                onValueChange={values => setSingleQuestionScore(values.floatValue)}
                                allowNegative={false}
                              />
                            )}
                          </Col>
                        ) : null}
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <Label className="bold-text" style={{ marginBottom: '8px' }}>
                        Status
                      </Label>
                      <Row>
                        <Col sm={5}>
                          <FormGroup
                            style={{ marginBottom: '10px' }}
                          >
                            <CheckBoxField
                              name="NewStatus"
                              label="New"
                              value={filtersStatus.New}
                              onChange={event => handleChangeStatusFilters(event, 'New')}
                            />
                          </FormGroup>
                          <FormGroup
                            style={{ marginBottom: '10px' }}
                          >
                            <CheckBoxField
                              name="PendingEditsStatus"
                              label="Pending Edits"
                              value={filtersStatus['Pending Edits']}
                              onChange={event => handleChangeStatusFilters(event, 'Pending Edits')}
                            />
                          </FormGroup>
                          <FormGroup
                            style={{ marginBottom: '10px' }}
                          >
                            <CheckBoxField
                              name="RescoreStatus"
                              label="Rescore"
                              value={filtersStatus.Rescore}
                              onChange={event => handleChangeStatusFilters(event, 'Rescore')}
                            />
                          </FormGroup>
                          <FormGroup
                            style={{ marginBottom: '0' }}
                          >
                            <CheckBoxField
                              name="FirstScoreStatus"
                              label="First Score"
                              value={filtersStatus['First Score']}
                              onChange={event => handleChangeStatusFilters(event, 'First Score')}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={7} style={{ paddingLeft: '0px' }}>
                          <FormGroup
                            style={{ marginBottom: '10px' }}
                          >
                            <CheckBoxField
                              name="SecondScoreStatus"
                              label="Second Score"
                              value={filtersStatus['Second Score']}
                              onChange={event => handleChangeStatusFilters(event, 'Second Score')}
                            />
                          </FormGroup>
                          <FormGroup
                            style={{ marginBottom: '10px' }}
                          >
                            <CheckBoxField
                              name="AwaitingBrandApprovalStatus"
                              label="Awaiting Brand Approval"
                              value={filtersStatus['Awaiting Brand Approval']}
                              onChange={event => handleChangeStatusFilters(event, 'Awaiting Brand Approval')}
                            />
                          </FormGroup>
                          <FormGroup className="mb-0">
                            <CheckBoxField
                              name="BrandApprovedMatch"
                              label="Brand Approved"
                              value={filtersStatus['Brand Approved']}
                              onChange={event => handleChangeStatusFilters(event, 'Brand Approved')}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={1}>
                      <Label className="bold-text" style={{ marginBottom: '8px' }}>
                        Scorecards
                      </Label>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="0Scorecards"
                          label="0"
                          value={filtersScorecard[0]}
                          onChange={event => handleChangeScorecardFilters(event, '0')}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="1Scorecards"
                          label="1"
                          value={filtersScorecard[1]}
                          onChange={event => handleChangeScorecardFilters(event, '1')}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <CheckBoxField
                          name="2+Scorecards"
                          label="2+"
                          value={filtersScorecard['2+']}
                          onChange={event => handleChangeScorecardFilters(event, '2+')}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={2}>
                      <Label className="bold-text" style={{ marginBottom: '8px' }}>
                        Deal Type
                      </Label>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="CPA/AmbassadorDealType"
                          label="CPA/Ambassador"
                          value={filtersDealType['CPA/Ambassador']}
                          onChange={event => handleChangeDealTypeFilters(event, 'CPA/Ambassador')}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="UpfrontOnlyDealType"
                          label="Upfront Only"
                          value={filtersDealType['Upfront Only']}
                          onChange={event => handleChangeDealTypeFilters(event, 'Upfront Only')}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="HybridDealType"
                          label="Hybrid"
                          value={filtersDealType.Hybrid}
                          onChange={event => handleChangeDealTypeFilters(event, 'Hybrid')}
                        />
                      </FormGroup>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="UpfrontCPMDealType"
                          label="Upfront CPM"
                          value={filtersDealType['Upfront CPM']}
                          onChange={event => handleChangeDealTypeFilters(event, 'Upfront CPM')}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <CheckBoxField
                          name="AgentCommissionDealType"
                          label="Agent Commission"
                          value={filtersDealType['Agent Commission']}
                          onChange={event => handleChangeDealTypeFilters(event, 'Agent Commission')}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <CheckBoxField
                          name="MediaLicenseDealType"
                          label="Media License"
                          value={filtersDealType['Media License']}
                          onChange={event => handleChangeDealTypeFilters(event, 'Media License')}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={2}>
                      <Label className="bold-text" style={{ marginBottom: '8px' }}>
                        Special Criteria
                      </Label>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="OscarWorthySpecialCriteria"
                          label="Oscar Worthy"
                          value={filtersSpecialCriteria['Oscar Worthy']}
                          onChange={event => handleChangeSpecialCriteriaFilters(event, 'Oscar Worthy')}
                        />
                      </FormGroup>
                      <FormGroup>
                        <CheckBoxField
                          name="NotOscarWorthySpecialCriteria"
                          label="Not Oscar Worthy"
                          value={filtersSpecialCriteria['Not Oscar Worthy']}
                          onChange={event => handleChangeSpecialCriteriaFilters(event, 'Not Oscar Worthy')}
                        />
                      </FormGroup>
                      <Label className="bold-text" style={{ marginBottom: '8px' }}>
                        Categories
                      </Label>
                      <FormGroup
                        style={{ marginBottom: '10px' }}
                      >
                        <CheckBoxField
                          name="AssignedCategories"
                          label="Assigned"
                          value={filtersCategory.Assigned}
                          onChange={event => handleChangeCategoryFilters(event, 'Assigned')}
                        />
                      </FormGroup>
                      <FormGroup>
                        <CheckBoxField
                          name="NotAssignedCategories"
                          label="Not Assigned"
                          value={filtersCategory['Not Assigned']}
                          onChange={event => handleChangeCategoryFilters(event, 'Not Assigned')}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <div>
                        <Label
                          className="bold-text"
                          style={{ marginBottom: '8px' }}
                        >
                          Average Score
                        </Label>
                        <Row>
                          <Col sm={1}>
                            <FormGroup
                              style={{ paddingTop: '11px' }}
                            >
                              <CheckBoxField
                                name="averageScoreCheckBox"
                                value={enableAverageScoreFilter}
                                onChange={e => e.target && handleChangeAverageScoreCheckBox(e)}
                              />
                            </FormGroup>
                          </Col>
                          <Col sm={5} style={{ paddingLeft: '0px' }}>
                            <Select
                              value={averageScoreTypeValue}
                              onChange={setAverageScoreTypeValue}
                              options={averageScoreLabels}
                              isDisabled={!enableAverageScoreFilter}
                            />
                          </Col>
                          <Col sm={6}>
                            {averageScoreTypeValue.value === 'Between' ? (
                              <>
                                <NumberFormat
                                  key="averageMin"
                                  disabled={!enableAverageScoreFilter}
                                  thousandSeparator
                                  prefix=""
                                  name="what"
                                  value={minAvgScore}
                                  className="form-control"
                                  placeholder="Min Avg Score"
                                  inputMode="numeric"
                                  allowEmptyFormatting={false}
                                  fixedDecimalScale
                                  decimalScale={0}
                                  onValueChange={values => setMinAvgScore(values.floatValue)}
                                  allowNegative={false}
                                  onBlur={() => {
                                    if (
                                      minAvgScore
                                      && maxAvgScore
                                      && (minAvgScore > maxAvgScore)
                                    ) {
                                      setMaxAvgScore(minAvgScore + 1);
                                    }
                                  }}
                                />
                                <NumberFormat
                                  key="scoreMax"
                                  disabled={!enableAverageScoreFilter}
                                  thousandSeparator
                                  prefix=""
                                  name="what"
                                  value={maxAvgScore}
                                  className="form-control"
                                  style={{ marginTop: '16px' }}
                                  placeholder="Max Avg Score"
                                  inputMode="numeric"
                                  allowEmptyFormatting={false}
                                  fixedDecimalScale
                                  decimalScale={0}
                                  onValueChange={values => setMaxAvgScore(values.floatValue)}
                                  allowNegative={false}
                                  onBlur={() => {
                                    if (
                                      minAvgScore
                                      && maxAvgScore
                                      && (minAvgScore > maxAvgScore)
                                    ) {
                                      setMinAvgScore(maxAvgScore - 1);
                                    }
                                  }}
                                />
                              </>
                            ) : (
                              <NumberFormat
                                key="singleAvgScore"
                                disabled={!enableAverageScoreFilter}
                                thousandSeparator
                                prefix=""
                                name="what"
                                value={singleAvgScore}
                                className="form-control"
                                placeholder="Average Score"
                                inputMode="numeric"
                                allowEmptyFormatting={false}
                                fixedDecimalScale
                                decimalScale={0}
                                onValueChange={values => setSingleAvgScore(values.floatValue)}
                                allowNegative={false}
                              />
                            )}
                          </Col>
                        </Row>
                      </div>
                      <PublishDate
                        setDateFrom={setDateFrom}
                        setDateTo={setDateTo}
                        setSingleDate={setSingleDate}
                        lastActivityDateValue={lastActivityDateValue}
                        setLastActivityDateValue={setLastActivityDateValue}
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        singleDate={singleDate}
                      />
                    </Col>
                    <Col sm={4}>
                      <Label className="bold-text" style={{ marginBottom: '8px' }}>
                        Video Privacy Status
                      </Label>
                      <FormGroup
                        row
                        style={{ marginBottom: '10px' }}
                      >
                        <Col>
                          <CheckBoxField
                            name="na"
                            label="N/A"
                            value={filtersPrivacyStatus.na}
                            onChange={event => handleChangePrivacyStatusFilters(event, 'na')}
                          />
                        </Col>
                        <Col>
                          <CheckBoxField
                            name="unlisted"
                            label="Unlisted"
                            value={filtersPrivacyStatus.unlisted}
                            onChange={event => handleChangePrivacyStatusFilters(event, 'unlisted')}
                          />
                        </Col>
                        <Col>
                          <CheckBoxField
                            name="public"
                            label="Public"
                            value={filtersPrivacyStatus.public}
                            onChange={event => handleChangePrivacyStatusFilters(event, 'public')}
                          />
                        </Col>
                        <Col>
                          <CheckBoxField
                            name="private"
                            label="Private"
                            value={filtersPrivacyStatus.private}
                            onChange={event => handleChangePrivacyStatusFilters(event, 'private')}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}
              <Row className="media-content-dashboard-container">
                <MatTableList
                  action={action}
                  resource={resource}
                  date={new Date()}
                  version={version}
                  UserPropertyType={UserPropertyType}
                  hideToolbar
                  sortBy="createdAt"
                  direction="desc"
                  v={2}
                  isResizable={false}
                  customColumns={customColumns}
                  showTitle={false}
                  useFlex
                />
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <UpdatePendingStatusModal setVersion={setVersion} />
      <DeleteContentModal setVersion={setVersion} callbackFunc={() => {}} />
      <UpdateStatusModal setVersion={setVersion} />
      <RefreshYoutubeDialog afterSaveFunc={() => setVersion((new Date()).toString())} />
      <StatusHistoryModal />
      <MediaContentTotals
        fetchTotalsData={fetchTotalsData}
        totals={totals}
        loadingTotals={loadingTotals}
      />
      <NotesModal />
    </Container>
  );
};

const mapStateToProps = state => ({
  paths: state.paths,
  resources: state.resources,
  addNotice: PropTypes.func.isRequired,
});

export default connect(mapStateToProps)(withNotice(MediContentListContainer));
