const countryList = require('country-list');
const LanguageList = require('language-list')();

exports.getCountryFromIso = (iso) => {
  let label = countryList.getName(iso);
  if (label === 'United States of America') {
    label = 'United States';
  }
  if (label === 'United Kingdom of Great Britain and Northern Ireland') {
    label = 'United Kingdom';
  }
  if (label === 'United States Minor Outlying Islands') {
    label = 'U.S. Minor Islands';
  }
  return label;
};

exports.getCountriesOptions = () => {
  const codeList = [];
  Object.entries(countryList.getCodeList()).forEach((entry) => {
    const value = entry[0].toUpperCase();
    let label = entry[1];
    if (label === 'United States of America') {
      label = 'United States';
    }
    if (label === 'United Kingdom of Great Britain and Northern Ireland') {
      label = 'United Kingdom';
    }
    if (label === 'United States Minor Outlying Islands') {
      label = 'U.S. Minor Islands';
    }
    codeList.push({ value, label });
  });
  // eslint-disable-next-line no-nested-ternary
  return codeList.sort((a, b) => ((a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)));
};

exports.getLanguagesOptions = () => {
  const codeList = [];
  LanguageList.getData().forEach((entry) => {
    codeList.push({ value: entry.code, label: entry.language });
  });
  codeList.push({ value: 'uk', label: 'Ukrainian' });
  // eslint-disable-next-line no-nested-ternary
  return codeList.sort((a, b) => ((a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)));
};

exports.isModuleVisibleForAdmin = (moduleTitle, user) =>
  user !== undefined && user.permissions !== undefined && user.permissions.includes(moduleTitle.toLowerCase());


exports.colorForPayableStatus = (status) => {
  switch (status) {
    case 'complete':
      return 'success';
    case 'failed':
      return 'danger';
    default:
      return 'secondary';
  }
};

exports.getLanguageNameFromIso = (iso) => {
  if (iso) {
    const isoLangs = {
      ab: {
        name: 'Abkhaz',
        nativeName: 'аҧсуа',
      },
      aa: {
        name: 'Afar',
        nativeName: 'Afaraf',
      },
      af: {
        name: 'Afrikaans',
        nativeName: 'Afrikaans',
      },
      ak: {
        name: 'Akan',
        nativeName: 'Akan',
      },
      sq: {
        name: 'Albanian',
        nativeName: 'Shqip',
      },
      am: {
        name: 'Amharic',
        nativeName: 'አማርኛ',
      },
      ar: {
        name: 'Arabic',
        nativeName: 'العربية',
      },
      an: {
        name: 'Aragonese',
        nativeName: 'Aragonés',
      },
      hy: {
        name: 'Armenian',
        nativeName: 'Հայերեն',
      },
      as: {
        name: 'Assamese',
        nativeName: 'অসমীয়া',
      },
      av: {
        name: 'Avaric',
        nativeName: 'авар мацӀ, магӀарул мацӀ',
      },
      ae: {
        name: 'Avestan',
        nativeName: 'avesta',
      },
      ay: {
        name: 'Aymara',
        nativeName: 'aymar aru',
      },
      az: {
        name: 'Azerbaijani',
        nativeName: 'azərbaycan dili',
      },
      bm: {
        name: 'Bambara',
        nativeName: 'bamanankan',
      },
      ba: {
        name: 'Bashkir',
        nativeName: 'башҡорт теле',
      },
      eu: {
        name: 'Basque',
        nativeName: 'euskara, euskera',
      },
      be: {
        name: 'Belarusian',
        nativeName: 'Беларуская',
      },
      bn: {
        name: 'Bengali',
        nativeName: 'বাংলা',
      },
      bh: {
        name: 'Bihari',
        nativeName: 'भोजपुरी',
      },
      bi: {
        name: 'Bislama',
        nativeName: 'Bislama',
      },
      bs: {
        name: 'Bosnian',
        nativeName: 'bosanski jezik',
      },
      br: {
        name: 'Breton',
        nativeName: 'brezhoneg',
      },
      bg: {
        name: 'Bulgarian',
        nativeName: 'български език',
      },
      my: {
        name: 'Burmese',
        nativeName: 'ဗမာစာ',
      },
      ca: {
        name: 'Catalan; Valencian',
        nativeName: 'Català',
      },
      ch: {
        name: 'Chamorro',
        nativeName: 'Chamoru',
      },
      ce: {
        name: 'Chechen',
        nativeName: 'нохчийн мотт',
      },
      ny: {
        name: 'Chichewa; Chewa; Nyanja',
        nativeName: 'chiCheŵa, chinyanja',
      },
      zh: {
        name: 'Chinese',
        nativeName: '中文 (Zhōngwén), 汉语, 漢語',
      },
      cv: {
        name: 'Chuvash',
        nativeName: 'чӑваш чӗлхи',
      },
      kw: {
        name: 'Cornish',
        nativeName: 'Kernewek',
      },
      co: {
        name: 'Corsican',
        nativeName: 'corsu, lingua corsa',
      },
      cr: {
        name: 'Cree',
        nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ',
      },
      hr: {
        name: 'Croatian',
        nativeName: 'hrvatski',
      },
      cs: {
        name: 'Czech',
        nativeName: 'česky, čeština',
      },
      da: {
        name: 'Danish',
        nativeName: 'dansk',
      },
      dv: {
        name: 'Divehi; Dhivehi; Maldivian;',
        nativeName: 'ދިވެހި',
      },
      nl: {
        name: 'Dutch',
        nativeName: 'Nederlands, Vlaams',
      },
      en: {
        name: 'English',
        nativeName: 'English',
      },
      eo: {
        name: 'Esperanto',
        nativeName: 'Esperanto',
      },
      et: {
        name: 'Estonian',
        nativeName: 'eesti, eesti keel',
      },
      ee: {
        name: 'Ewe',
        nativeName: 'Eʋegbe',
      },
      fo: {
        name: 'Faroese',
        nativeName: 'føroyskt',
      },
      fj: {
        name: 'Fijian',
        nativeName: 'vosa Vakaviti',
      },
      fi: {
        name: 'Finnish',
        nativeName: 'suomi, suomen kieli',
      },
      fr: {
        name: 'French',
        nativeName: 'français, langue française',
      },
      ff: {
        name: 'Fula; Fulah; Pulaar; Pular',
        nativeName: 'Fulfulde, Pulaar, Pular',
      },
      gl: {
        name: 'Galician',
        nativeName: 'Galego',
      },
      ka: {
        name: 'Georgian',
        nativeName: 'ქართული',
      },
      de: {
        name: 'German',
        nativeName: 'Deutsch',
      },
      el: {
        name: 'Greek, Modern',
        nativeName: 'Ελληνικά',
      },
      gn: {
        name: 'Guaraní',
        nativeName: 'Avañeẽ',
      },
      gu: {
        name: 'Gujarati',
        nativeName: 'ગુજરાતી',
      },
      ht: {
        name: 'Haitian; Haitian Creole',
        nativeName: 'Kreyòl ayisyen',
      },
      ha: {
        name: 'Hausa',
        nativeName: 'Hausa, هَوُسَ',
      },
      he: {
        name: 'Hebrew (modern)',
        nativeName: 'עברית',
      },
      hz: {
        name: 'Herero',
        nativeName: 'Otjiherero',
      },
      hi: {
        name: 'Hindi',
        nativeName: 'हिन्दी, हिंदी',
      },
      ho: {
        name: 'Hiri Motu',
        nativeName: 'Hiri Motu',
      },
      hu: {
        name: 'Hungarian',
        nativeName: 'Magyar',
      },
      ia: {
        name: 'Interlingua',
        nativeName: 'Interlingua',
      },
      id: {
        name: 'Indonesian',
        nativeName: 'Bahasa Indonesia',
      },
      ie: {
        name: 'Interlingue',
        nativeName: 'Originally called Occidental; then Interlingue after WWII',
      },
      ga: {
        name: 'Irish',
        nativeName: 'Gaeilge',
      },
      ig: {
        name: 'Igbo',
        nativeName: 'Asụsụ Igbo',
      },
      ik: {
        name: 'Inupiaq',
        nativeName: 'Iñupiaq, Iñupiatun',
      },
      io: {
        name: 'Ido',
        nativeName: 'Ido',
      },
      is: {
        name: 'Icelandic',
        nativeName: 'Íslenska',
      },
      it: {
        name: 'Italian',
        nativeName: 'Italiano',
      },
      iu: {
        name: 'Inuktitut',
        nativeName: 'ᐃᓄᒃᑎᑐᑦ',
      },
      ja: {
        name: 'Japanese',
        nativeName: '日本語 (にほんご／にっぽんご)',
      },
      jv: {
        name: 'Javanese',
        nativeName: 'basa Jawa',
      },
      kl: {
        name: 'Kalaallisut, Greenlandic',
        nativeName: 'kalaallisut, kalaallit oqaasii',
      },
      kn: {
        name: 'Kannada',
        nativeName: 'ಕನ್ನಡ',
      },
      kr: {
        name: 'Kanuri',
        nativeName: 'Kanuri',
      },
      ks: {
        name: 'Kashmiri',
        nativeName: 'कश्मीरी, كشميري‎',
      },
      kk: {
        name: 'Kazakh',
        nativeName: 'Қазақ тілі',
      },
      km: {
        name: 'Khmer',
        nativeName: 'ភាសាខ្មែរ',
      },
      ki: {
        name: 'Kikuyu, Gikuyu',
        nativeName: 'Gĩkũyũ',
      },
      rw: {
        name: 'Kinyarwanda',
        nativeName: 'Ikinyarwanda',
      },
      ky: {
        name: 'Kirghiz, Kyrgyz',
        nativeName: 'кыргыз тили',
      },
      kv: {
        name: 'Komi',
        nativeName: 'коми кыв',
      },
      kg: {
        name: 'Kongo',
        nativeName: 'KiKongo',
      },
      ko: {
        name: 'Korean',
        nativeName: '한국어 (韓國語), 조선말 (朝鮮語)',
      },
      ku: {
        name: 'Kurdish',
        nativeName: 'Kurdî, كوردی‎',
      },
      kj: {
        name: 'Kwanyama, Kuanyama',
        nativeName: 'Kuanyama',
      },
      la: {
        name: 'Latin',
        nativeName: 'latine, lingua latina',
      },
      lb: {
        name: 'Luxembourgish, Letzeburgesch',
        nativeName: 'Lëtzebuergesch',
      },
      lg: {
        name: 'Luganda',
        nativeName: 'Luganda',
      },
      li: {
        name: 'Limburgish, Limburgan, Limburger',
        nativeName: 'Limburgs',
      },
      ln: {
        name: 'Lingala',
        nativeName: 'Lingála',
      },
      lo: {
        name: 'Lao',
        nativeName: 'ພາສາລາວ',
      },
      lt: {
        name: 'Lithuanian',
        nativeName: 'lietuvių kalba',
      },
      lu: {
        name: 'Luba-Katanga',
        nativeName: '',
      },
      lv: {
        name: 'Latvian',
        nativeName: 'latviešu valoda',
      },
      gv: {
        name: 'Manx',
        nativeName: 'Gaelg, Gailck',
      },
      mk: {
        name: 'Macedonian',
        nativeName: 'македонски јазик',
      },
      mg: {
        name: 'Malagasy',
        nativeName: 'Malagasy fiteny',
      },
      ms: {
        name: 'Malay',
        nativeName: 'bahasa Melayu, بهاس ملايو‎',
      },
      ml: {
        name: 'Malayalam',
        nativeName: 'മലയാളം',
      },
      mt: {
        name: 'Maltese',
        nativeName: 'Malti',
      },
      mi: {
        name: 'Māori',
        nativeName: 'te reo Māori',
      },
      mr: {
        name: 'Marathi (Marāṭhī)',
        nativeName: 'मराठी',
      },
      mh: {
        name: 'Marshallese',
        nativeName: 'Kajin M̧ajeļ',
      },
      mn: {
        name: 'Mongolian',
        nativeName: 'монгол',
      },
      na: {
        name: 'Nauru',
        nativeName: 'Ekakairũ Naoero',
      },
      nv: {
        name: 'Navajo, Navaho',
        nativeName: 'Diné bizaad, Dinékʼehǰí',
      },
      nb: {
        name: 'Norwegian Bokmål',
        nativeName: 'Norsk bokmål',
      },
      nd: {
        name: 'North Ndebele',
        nativeName: 'isiNdebele',
      },
      ne: {
        name: 'Nepali',
        nativeName: 'नेपाली',
      },
      ng: {
        name: 'Ndonga',
        nativeName: 'Owambo',
      },
      nn: {
        name: 'Norwegian Nynorsk',
        nativeName: 'Norsk nynorsk',
      },
      no: {
        name: 'Norwegian',
        nativeName: 'Norsk',
      },
      ii: {
        name: 'Nuosu',
        nativeName: 'ꆈꌠ꒿ Nuosuhxop',
      },
      nr: {
        name: 'South Ndebele',
        nativeName: 'isiNdebele',
      },
      oc: {
        name: 'Occitan',
        nativeName: 'Occitan',
      },
      oj: {
        name: 'Ojibwe, Ojibwa',
        nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ',
      },
      cu: {
        name: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
        nativeName: 'ѩзыкъ словѣньскъ',
      },
      om: {
        name: 'Oromo',
        nativeName: 'Afaan Oromoo',
      },
      or: {
        name: 'Oriya',
        nativeName: 'ଓଡ଼ିଆ',
      },
      os: {
        name: 'Ossetian, Ossetic',
        nativeName: 'ирон æвзаг',
      },
      pa: {
        name: 'Panjabi, Punjabi',
        nativeName: 'ਪੰਜਾਬੀ, پنجابی‎',
      },
      pi: {
        name: 'Pāli',
        nativeName: 'पाऴि',
      },
      fa: {
        name: 'Persian',
        nativeName: 'فارسی',
      },
      pl: {
        name: 'Polish',
        nativeName: 'polski',
      },
      ps: {
        name: 'Pashto, Pushto',
        nativeName: 'پښتو',
      },
      pt: {
        name: 'Portuguese',
        nativeName: 'Português',
      },
      qu: {
        name: 'Quechua',
        nativeName: 'Runa Simi, Kichwa',
      },
      rm: {
        name: 'Romansh',
        nativeName: 'rumantsch grischun',
      },
      rn: {
        name: 'Kirundi',
        nativeName: 'kiRundi',
      },
      ro: {
        name: 'Romanian, Moldavian, Moldovan',
        nativeName: 'română',
      },
      ru: {
        name: 'Russian',
        nativeName: 'русский язык',
      },
      sa: {
        name: 'Sanskrit (Saṁskṛta)',
        nativeName: 'संस्कृतम्',
      },
      sc: {
        name: 'Sardinian',
        nativeName: 'sardu',
      },
      sd: {
        name: 'Sindhi',
        nativeName: 'सिन्धी, سنڌي، سندھی‎',
      },
      se: {
        name: 'Northern Sami',
        nativeName: 'Davvisámegiella',
      },
      sm: {
        name: 'Samoan',
        nativeName: 'gagana faa Samoa',
      },
      sg: {
        name: 'Sango',
        nativeName: 'yângâ tî sängö',
      },
      sr: {
        name: 'Serbian',
        nativeName: 'српски језик',
      },
      gd: {
        name: 'Scottish Gaelic; Gaelic',
        nativeName: 'Gàidhlig',
      },
      sn: {
        name: 'Shona',
        nativeName: 'chiShona',
      },
      si: {
        name: 'Sinhala, Sinhalese',
        nativeName: 'සිංහල',
      },
      sk: {
        name: 'Slovak',
        nativeName: 'slovenčina',
      },
      sl: {
        name: 'Slovene',
        nativeName: 'slovenščina',
      },
      so: {
        name: 'Somali',
        nativeName: 'Soomaaliga, af Soomaali',
      },
      st: {
        name: 'Southern Sotho',
        nativeName: 'Sesotho',
      },
      es: {
        name: 'Spanish; Castilian',
        nativeName: 'español, castellano',
      },
      su: {
        name: 'Sundanese',
        nativeName: 'Basa Sunda',
      },
      sw: {
        name: 'Swahili',
        nativeName: 'Kiswahili',
      },
      ss: {
        name: 'Swati',
        nativeName: 'SiSwati',
      },
      sv: {
        name: 'Swedish',
        nativeName: 'svenska',
      },
      ta: {
        name: 'Tamil',
        nativeName: 'தமிழ்',
      },
      te: {
        name: 'Telugu',
        nativeName: 'తెలుగు',
      },
      tg: {
        name: 'Tajik',
        nativeName: 'тоҷикӣ, toğikī, تاجیکی‎',
      },
      th: {
        name: 'Thai',
        nativeName: 'ไทย',
      },
      ti: {
        name: 'Tigrinya',
        nativeName: 'ትግርኛ',
      },
      bo: {
        name: 'Tibetan Standard, Tibetan, Central',
        nativeName: 'བོད་ཡིག',
      },
      tk: {
        name: 'Turkmen',
        nativeName: 'Türkmen, Түркмен',
      },
      tl: {
        name: 'Tagalog',
        nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔',
      },
      tn: {
        name: 'Tswana',
        nativeName: 'Setswana',
      },
      to: {
        name: 'Tonga (Tonga Islands)',
        nativeName: 'faka Tonga',
      },
      tr: {
        name: 'Turkish',
        nativeName: 'Türkçe',
      },
      ts: {
        name: 'Tsonga',
        nativeName: 'Xitsonga',
      },
      tt: {
        name: 'Tatar',
        nativeName: 'татарча, tatarça, تاتارچا‎',
      },
      tw: {
        name: 'Twi',
        nativeName: 'Twi',
      },
      ty: {
        name: 'Tahitian',
        nativeName: 'Reo Tahiti',
      },
      ug: {
        name: 'Uighur, Uyghur',
        nativeName: 'Uyƣurqə, ئۇيغۇرچە‎',
      },
      uk: {
        name: 'Ukrainian',
        nativeName: 'українська',
      },
      ur: {
        name: 'Urdu',
        nativeName: 'اردو',
      },
      uz: {
        name: 'Uzbek',
        nativeName: 'zbek, Ўзбек, أۇزبېك‎',
      },
      ve: {
        name: 'Venda',
        nativeName: 'Tshivenḓa',
      },
      vi: {
        name: 'Vietnamese',
        nativeName: 'Tiếng Việt',
      },
      vo: {
        name: 'Volapük',
        nativeName: 'Volapük',
      },
      wa: {
        name: 'Walloon',
        nativeName: 'Walon',
      },
      cy: {
        name: 'Welsh',
        nativeName: 'Cymraeg',
      },
      wo: {
        name: 'Wolof',
        nativeName: 'Wollof',
      },
      fy: {
        name: 'Western Frisian',
        nativeName: 'Frysk',
      },
      xh: {
        name: 'Xhosa',
        nativeName: 'isiXhosa',
      },
      yi: {
        name: 'Yiddish',
        nativeName: 'ייִדיש',
      },
      yo: {
        name: 'Yoruba',
        nativeName: 'Yorùbá',
      },
      za: {
        name: 'Zhuang, Chuang',
        nativeName: 'Saɯ cueŋƅ, Saw cuengh',
      },
    };
    const language = isoLangs[iso];
    if (language && language.name) {
      return language.name;
    } return 'N/A';
  } return 'N/A';
};

// COOKIES
exports.setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

exports.getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};


exports.checkIfImageExists = (url, callback) => {
  if (url) {
    let img = new Image();
    img.src = url;
    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };
      img.onerror = () => {
        callback(false);
      };
    }
    // console.log('remove image');
    img = null;
  } else {
    callback(false);
  }
};
exports.parseUrl = (url) => {
  const filter = {};
  const query = new URLSearchParams(url || window.location.search);
  // eslint-disable-next-line no-unused-vars, no-restricted-syntax
  for (const entry of query.entries()) {
    const [key, value] = entry;
    if (key.match('filters.')) {
      if (!filter[key.replace('filters.', '')]) {
        filter[key.replace('filters.', '')] = value;
      } else if (Array.isArray(filter[key.replace('filters.', '')])) {
        filter[key.replace('filters.', '')].push(value);
      } else {
        filter[key.replace('filters.', '')] = [filter[key.replace('filters.', '')], value];
      }
    }
  }
  // console.log(filter, 'filter')
  return filter;
};

exports.getPages = () => {
  const query = new URLSearchParams(window.location.search);
  // nst filters = parseUrl();
  return [Number(query.get('page')), Number(query.get('perPage'))];
};

exports.setFirstPage = (history) => {
  const search = new URLSearchParams(window.location.search);
  search.set('page', '1');
  history.push(`${history.location.pathname}?${search.toString()}`);
};

exports.checkImagePromise = thumbnailUrl => new Promise(resolve => this.checkIfImageExists(thumbnailUrl, success => resolve(success)));

exports.CheckPermission = key => window.REDUX_STATE.session.permissions.find(a => a === key);

exports.calculateTotal = (data, calc) => data.reduce((sum, row) => Number(calc(row)) + sum, 0);

exports.processUrlSearch = (search, filter, filterName, switchedOff = false, compareObj = null) => {
  search.delete(`filters.${filterName}`);
  let allIlSwitchedOff = switchedOff;
  Object.keys(filter).forEach((key) => {
    if (!filter[key]) return false;
    search.append(`filters.${filterName}`, compareObj ? compareObj[key] : key);
    allIlSwitchedOff = false;
    return true;
  });
  if (allIlSwitchedOff) {
    search.append(`filters.${filterName}`, 'Not Exist');
  }
  return search;
};
exports.goToPage = (url, target = '_blank') => {
  const win = window.open(url, target);
  win.focus();
};

exports.resetObject = (obj, keep = []) => {
  const res = {};
  Object.keys(obj).forEach((i) => {
    res[i] = keep.includes(i) ? obj[i] : null;
  });
  return res;
};

exports.fromArray = (values) => {
  if (!values || values?.length === 0) return null;
  return (values.length === 1) ? values[0] : values;
};

exports.toArray = (values) => {
  if (!values) return [];
  return (values instanceof Array) ? values : [values];
};

exports.parseVideoId = (url) => {
  if (url) {
    let id = '';
    if (url.includes('youtube.com/watch?')) {
      // case for YT video with url like:  https://www.youtube.com/watch?v=lTMSe4ekyKU&feature=youtu.be&ab_channel=KellyAnneSmith or https://www.youtube.com/watch?t=120&v=TWpRM5jJOWA&feature=youtu.be&ab_channel=DEYO
      // eslint-disable-next-line prefer-destructuring
      id = url.split('v=')[1]?.split('&')[0];
    } else if (url.includes('youtu.be/')) {
      // case for YT video with url like:  https://youtu.be/01G2BRk2N60?t=407
      // eslint-disable-next-line prefer-destructuring
      id = url.split('youtu.be/')[1]?.split('?')[0];
    } else if (url.includes('youtube.com/shorts/')) {
      // case for YT video with url like:  https://www.youtube.com/shorts/Ozx8hgiDFtE
      // eslint-disable-next-line prefer-destructuring
      id = url.split('youtube.com/shorts/')[1]?.split('?')[0];
    }
    if (!id) return false;
    return id;
  }
  return null;
};

exports.toYesNo = (value) => {
  if ([null, undefined].includes(value)) {
    return null;
  }
  if (!value || ['no', 'No', 'NO'].includes(value)) {
    return 'No';
  }
  return 'Yes';
};

function hslToHex(h, s, l) {
  const hue = h / 360;
  const saturation = s / 100;
  const lightness = l / 100;

  const c = (1 - Math.abs((2 * lightness) - 1)) * saturation;
  const x = c * (1 - (Math.abs(((hue * 6) % 2) - 1)));
  const m = lightness - (c / 2);

  let r;
  let g;
  let b;

  if (hue >= 0 && hue < 1 / 6) {
    r = c;
    g = x;
    b = 0;
  } else if (hue >= 1 / 6 && hue < 2 / 6) {
    r = x;
    g = c;
    b = 0;
  } else if (hue >= 2 / 6 && hue < 3 / 6) {
    r = 0;
    g = c;
    b = x;
  } else if (hue >= 3 / 6 && hue < 4 / 6) {
    r = 0;
    g = x;
    b = c;
  } else if (hue >= 4 / 6 && hue < 5 / 6) {
    r = x;
    g = 0;
    b = c;
  } else {
    r = c;
    g = 0;
    b = x;
  }

  r = (Math.round((r + m) * 255)).toString(16).padStart(2, '0');
  g = (Math.round((g + m) * 255)).toString(16).padStart(2, '0');
  b = (Math.round((b + m) * 255)).toString(16).padStart(2, '0');

  return `#${r}${g}${b}`;
}

function generateColors(numColors) {
  const colors = [];

  for (let i = 0; i < numColors; i += 1) {
    const hue = ((i * 360) / numColors) + ((Math.random() * 30) - 15);
    const saturation = 70 + (Math.random() * 10);
    const lightness = 50 + (Math.random() * 10);

    const hexColor = hslToHex(hue, saturation, lightness);
    colors.push(hexColor);
  }

  return colors;
}

function shuffleColorsArray(array) {
  if (array.length > 8) {
    const newArray = [...array];
    const maxIterations = 1000;
    for (let i = newArray.length - 1; i > 0; i -= 1) {
      let j;
      let iteration = 0;
      do {
        j = Math.floor(Math.random() * (i + 1));
        iteration += 1;
        if (iteration > maxIterations) {
          console.log('Shuffle failed to complete in a reasonable number of iterations.');
          return newArray;
        }
      } while (Math.abs(j - i) <= 1);
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  }
  return array;
}

exports.getColorsForCharts = numberOfColors => generateColors(numberOfColors);

exports.getShuffledColorsForCharts = numberOfColors => shuffleColorsArray(generateColors(numberOfColors));

exports.shuffleArray = shuffleColorsArray;

exports.getUserTimezoneAbbreviation = () => {
  const dateString = new Date().toLocaleDateString('en-US', {
    timeZoneName: 'short'
  });
  return dateString.split(', ')
    .pop()
    .split(' ')
    .pop();
}
