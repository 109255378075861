import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Container,
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  Button,
} from 'reactstrap';
import UserPropertyType from './custom';
import Actions from './custom/Actions';
import PeriodTabs from '../../../../../shared/components/filter/PeriodTabs';
import MatTableList from '../../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../../shared/components/BreadCrumbs';
import PilotMetadataHelper from '../../../../../shared/helpers/models/PilotMetadata';
import { parseUrl } from '../../../../../shared/helpers/WVUtilities';
import {
  dateFromFilters,
  endDateFromFilters,
  setUrl,
} from '../../../../../shared/helpers/Filter';
import { useSelector } from 'react-redux';

const ListContainer = (props) => {
  const {
    resource,
    action,
    history,
  } = props;

  const initialFilterParams = parseUrl();
  const initialFrom = dateFromFilters(initialFilterParams, 'date');
  const initialTo = endDateFromFilters(initialFilterParams, 'date');
  const [showReport, setShowReport] = useState(false);
  const [from, setFrom] = useState(
    initialFrom ||  moment('2019-01-01').startOf('day').toISOString()
  );
  const [to, setTo] = useState(
    initialTo || moment().endOf('day').toISOString()
  );
  const [_filters, setFilters] = useState({
    filters: initialFilterParams,
  });

  const { filters } = _filters;
  const [advertiserError, setAdvertiserError] = useState(null);
  const loading = useSelector(state => state.table.loading);
  const ref = useRef(true);
  const runReport = () => {
    if (!filters.advertiser) {
      setAdvertiserError('This field is required');
    } else {
      setShowReport(true);
      setUrl(_filters.filters, ref, history, _filters.params);
    }
  };

  return (
    <Container className="dashboard">
      <Breadcrumbs
        links={[
          { title: 'Home', path: '/' },
          { title: 'Reports', path: false },
          { title: 'Pilot Tracker', path: false },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <CardTitle>
            <Row>
              <Col>
                <h3>Pilot Tracker</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-0" />
          </CardTitle>
          <Row className="mt-3">
            <Col>
              <PeriodTabs
                periods={[
                  { range: 'year_to_date', label: 'Year to Date' },
                  { range: 'all_time', label: 'All Time' },
                  { range: 'custom_dates', label: 'Custom' },
                ]}
                selected={(initialFrom || initialTo) ? 'custom_dates' : 'all_time'}
                from={from}
                setFrom={setFrom}
                to={to}
                setTo={setTo}
              />
            </Col>
            <Col md="auto">
              <Button
                color="primary"
                size="lg"
                onClick={runReport}
                style={{
                  width: '100%',
                  maxWidth: '160px',
                  padding: '2px 20px',
                  height: '38px',
                }}
                disabled={loading}
              >
                <span className="btn-text">Run Report</span>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Actions
                history={history}
                initialFilterParams={initialFilterParams}
                showReport={showReport}
                setShowReport={setShowReport}
                from={from}
                to={to}
                advertiserError={advertiserError}
                setAdvertiserError={setAdvertiserError}
                _filters={_filters}
                setFilters={setFilters}
              />
              {showReport && (<div style={{ marginLeft: '-17px', marginRight: '-17px', marginBottom: '-30px' }}>
                <MatTableList
                  action={action}
                  resource={resource}
                  sortBy="qualityScore"
                  direction="desc"
                  date={new Date()}
                  UserPropertyType={UserPropertyType}
                  showTitle={false}
                  isResizable={false}
                  v={2}
                  noActions
                  totals={action.custom.totals}
                  helper={PilotMetadataHelper}
                  columnsSettings={{
                    fullName: {
                      width: 240,
                    },
                    notes: {
                      width: 300,
                      disableSortBy: true,
                    },
                  }}
                />
              </div>)}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

ListContainer.propTypes = {
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

export default ListContainer;
