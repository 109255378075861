
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { axios } from 'ApiClient';
import { Container, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  getInfluencer,
  setInfluencerContact,
  getAgreements,
  getPayments,
  editInfluencerPayment,
  setRefreshHasoffers,
  setRefreshHubspot,
  setRefreshBillCom,
  fixDeal,
  updateDeal,
  editInfluencerStatus,
  editInfluencerCompanyStatus,
} from '../../../../../redux/reducers/admin/InfluencerProfileReducer';
import CreatorProfile from './CreatorProfile';
import ContactProfile from './ContactProfile';
import DealsProfile from './DealsProfile';
import VanityURLsProfile from './VanityURLsProfile';
import ServiceAgreements from './ServiceAgreement';
import Payments from './Payments';
import ReportV2 from './ReportV2';
import BillComSearchModal from './BillComSearchModal';
import SearchBlock from './SearchBlock';
import MediaContent from './MediaContent';
import Conversions from './Conversions';
import BrandApprovals from './BrandApprovals';
import Breadcrumbs from '../../../../../shared/components/BreadCrumbs';
import ErrorBoundary from '../../../../../shared/components/ErrorBoundary';
import Alert from '../../../../../shared/easydev2/shared/components/Alert';
import FormModal from '../../../../../shared/components/modals/Action';
import Edit from '../../../../../shared/components/components/actions/edit';

const ResourceAction = ({
    influencer, contact, prospect, deals, agents, loadingInfluencer, loadingContact,
    loadingDeals, dispatch, history, agreements, billComVendors, loadingAgreement, loadinginfluencerPayment,
    location, loadinginfluencerStatus, match, payments, loadingPayments, loadingCompanyStatus, resources,
  }) => {

  useEffect(() => {
    if (match && match.params.influencerId.match(/^[0-9a-fA-F]{24}$/)) {
      dispatch(getInfluencer(match.params.influencerId));
      dispatch(getAgreements(match.params.influencerId));
      dispatch(getPayments(match.params.influencerId));
    }
  }, []);

  const startDate = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
  const endDate = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');
  const [showForm, toggleForm] = useState(false);
  const resource = resources.find(r => r.id === 'Influencer');
  const reloadInfluencer = () => dispatch(getInfluencer(match.params.influencerId));

  return !match.params.influencerId.match(/^[0-9a-fA-F]{24}$/) ? (
    <Container className="dashboard admin-dashboard creator-profile">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Creators', path: '/resources/Influencer/actions/list' },
              { title: 'Creator Profile', path: false },
            ]}
            ComponentInCenter={SearchBlock}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Alert color="warning" bordered icon>
            <p className="py-2">
              Invalid Influencer ID
            </p>
          </Alert>
        </Col>
      </Row>
    </Container>
  ) : (
    <Container className="dashboard admin-dashboard creator-profile">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Creators', path: '/resources/Influencer/actions/list' },
              { title: 'Creator Profile', path: false },
            ]}
            ComponentInCenter={SearchBlock}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ErrorBoundary portal="admin.influencer.CreatorProfile" partial >
            <CreatorProfile
              influencerId={match.params.influencerId}
              influencer={influencer}
              contact={contact}
              prospect={prospect}
              loading={loadingInfluencer}
              spyLogin={id => new Promise((resolve) => {
                  axios.post(
                    '/api/portal/influencers/spy-login',
                    {
                      userId: id,
                    },
                  ).then((response) => {
                    const { spyLoginToken, baseUrl } = response.data;
                    if (spyLoginToken && baseUrl) {
                      const win = window.open(`${baseUrl}/login-secret/${spyLoginToken}`, '_blank');
                      win.focus();
                    }
                    resolve();
                  }).catch((error) => {
                    console.log(error);
                  });
                })
              }
              changePassword={() => toggleForm(true)}
              editPayment={value => dispatch(editInfluencerPayment(influencer.id, value))}
              editStatus={value => dispatch(editInfluencerStatus(influencer.id, value))}
              editCompanyStatus={value => dispatch(editInfluencerCompanyStatus(influencer.id, value))}
              loadinginfluencerPayment={loadinginfluencerPayment}
              loadinginfluencerStatus={loadinginfluencerStatus}
              loadingCompanyStatus={loadingCompanyStatus}
              refreshHasoffers={() => dispatch(setRefreshHasoffers())}
              refreshHubspot={() => dispatch(setRefreshHubspot())}
              refreshBillCom={() => dispatch(setRefreshBillCom())}
              connectBillCom={() => history.push(`/resources/Influencer/creator-bill-com/${influencer?.id}`)}
              inviteBillCom={() => history.push(`/billCom/invite/${influencer.id}/creator`)}
              reloadInfluecner={reloadInfluencer}
            />
          </ErrorBoundary>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ErrorBoundary portal="admin.influencer.BrandApprovalsProfile" partial >
            <BrandApprovals influencer={influencer} />
          </ErrorBoundary>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ErrorBoundary portal="admin.influencer.ContactProfile" partial >
            <ContactProfile
              influencer={influencer}
              contact={contact}
              loading={loadingContact}
              changeHubspotContact={selecteValue => dispatch(setInfluencerContact(selecteValue))}
              editContact={() => {
                window.open(`/influencers/creator-deal-editor?creatorId=${match.params.influencerId}`, '_blank');
              }}
            />
          </ErrorBoundary>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ErrorBoundary portal="admin.influencer.DealsProfile" partial>
            <DealsProfile
              influencer={influencer}
              deals={deals}
              agents={agents}
              loading={loadingDeals}
              statusChange={(deal, value) => {
                dispatch(updateDeal(deal.id, value));
              }}
              fixDeal={deal => dispatch(fixDeal(deal.id))}
              createDeal={() => {
                window.open(`/influencers/creator-deal-editor?creatorId=${match.params.influencerId}`, '_blank');
              }}
              salesActivity={() =>
                history.push(`/influencer/profile/sales-activity/${match.params.influencerId}?filters.eventDate~~from=${startDate}&filters.eventDate~~to=${endDate}&filters.creatorEmail=${encodeURIComponent(influencer.params.email)}`)}
            />
          </ErrorBoundary>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {influencer &&
          <ReportV2
            influencer={influencer}
          />
        }
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {
            influencer && (
            <ErrorBoundary portal="admin.influencer.Conversions" partial>
              <Conversions
                influencer={influencer}
                deals={deals}
              />
            </ErrorBoundary>
            )
          }
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ErrorBoundary portal="admin.influencer.ServiceAgreements" partial>
            <ServiceAgreements
              influencer={influencer}
              deals={deals}
              agreements={agreements}
              loading={loadingAgreement}
              location={location}
              reloadAgreements={() => dispatch(getAgreements(match.params.influencerId))}
              dispatch={dispatch}
            />
          </ErrorBoundary>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ErrorBoundary portal="admin.influencer.VanityURLsProfile" partial>
            <VanityURLsProfile />
          </ErrorBoundary>
        </Col>
      </Row>
      { /* Bill.Com Search modal */
        billComVendors.length > 0 ? (
          <ErrorBoundary portal="admin.influencer.billComSearch" partial>
            <BillComSearchModal
              influencer={influencer}
              vendors={billComVendors}
              // onAuthenticationFinished={this.onMFAReauthenticationFinished}
            />
          </ErrorBoundary>
          ) : null
      }
      <Row>
        <Col md={12}>
          {influencer?.id &&
            <div>
              <h2 className="section-title mt-0 mb-4">Media Content</h2>
              <ErrorBoundary portal="admin.influencer.mediaContent" partial>
                <MediaContent
                  influencer={influencer}
                />
              </ErrorBoundary>
            </div>
          }
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ErrorBoundary portal="admin.influencer.payments" partial>
            <Payments
              influencer={influencer}
              payments={payments}
              loading={loadingPayments}
              location={location}
              deals={deals}
            />
          </ErrorBoundary>
        </Col>
      </Row>
      <FormModal
        isOpen={showForm}
        toggle={toggleForm}
        label="Edit Creator"
      >
        <Edit
          resource={resource}
          record={influencer}
          afterSave={reloadInfluencer}
        />
      </FormModal>
    </Container>
  );
}

ResourceAction.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  influencer: PropTypes.objectOf(PropTypes.any),
  deals: PropTypes.arrayOf(PropTypes.any).isRequired,
  agents: PropTypes.objectOf(PropTypes.any).isRequired,
  contact: PropTypes.objectOf(PropTypes.any),
  prospect: PropTypes.objectOf(PropTypes.any),
  loadingInfluencer: PropTypes.bool.isRequired,
  loadingContact: PropTypes.bool.isRequired,
  loadingDeals: PropTypes.bool.isRequired,
  loadingAgreement: PropTypes.bool.isRequired,
  loadingPayments: PropTypes.bool.isRequired,
  loadinginfluencerPayment: PropTypes.bool.isRequired,
  loadinginfluencerStatus: PropTypes.bool.isRequired,
  loadingCompanyStatus: PropTypes.bool.isRequired,
  resources: PropTypes.arrayOf(PropTypes.any).isRequired,
  agreements: PropTypes.arrayOf(PropTypes.any).isRequired,
  payments: PropTypes.arrayOf(PropTypes.any).isRequired,
  billComVendors: PropTypes.arrayOf(PropTypes.any).isRequired,
};

ResourceAction.defaultProps = {
  influencer: null,
  contact: null,
  prospect: null,
};

const mapStateToProps = state => ({
  resources: state.resources,
  influencer: state.influencer.influencer,
  contact: state.influencer.contact,
  prospect: state.influencer.prospect,
  deals: state.influencer.deals,
  agents: state.influencer.agents,
  loadingInfluencer: state.influencer.loading.influencer,
  loadingContact: state.influencer.loading.contact,
  loadingDeals: state.influencer.loading.deals,
  loadingAgreement: state.influencer.loading.agreements,
  loadingPayments: state.influencer.loading.payments,
  loadinginfluencerPayment: state.influencer.loading.influencerPayment,
  loadinginfluencerStatus: state.influencer.loading.influencerStatus,
  loadingCompanyStatus: state.influencer.loading.influencerCompanyStatus,
  agreements: state.influencer.serviceAgreements,
  payments: state.influencer.payments,
  billComIssue: state.influencer.billComIssue,
  stripeIssue: state.influencer.stripeIssue,
  billComVendors: state.influencer.billComVendors,
});

export default connect(mapStateToProps)(ResourceAction);
