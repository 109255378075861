import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import HighLight from '../../../../../../shared/components/HighLight';

const ContactInList = React.memo(({
  record, search,
}) => (
  <div
    className="d-flex"
    style={{ minWidth: '220px' }}
  >
    <div
      className="align-self-center flex-column"
    >
      <div className="firstAndLastName">
        <HighLight search={search}>{`${record.params.firstname || ''} ${record.params.lastname || ''} `}</HighLight>
        {record.params.hs_object_id && (
        <a
          title="Show HubSpot Contact"
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${record.params.hs_object_id}`}
        >
          <i className="fab fa-hubspot" />
        </a>
          )}
      </div>
      <div className="" >
        {record.params.mediaChannels && record.params.mediaChannels.youtube_channel_url && (
        <a
          href={record.params.mediaChannels.youtube_channel_url}
          target="_blank"
          rel="noreferrer"
          key="youtube"
          style={{ marginRight: '4px' }}
        >
          <img
            src="/images/mediacontent/youtube.png"
            alt="youtube"
            title={record.params.mediaChannels.youtube_channel_name || 'YouTube Channel'}
            style={{ width: '20px' }}
          />
        </a>
          )}
        {record.params.mediaChannels && record.params.mediaChannels.instagram_channel && (
        <a
          href={record.params.mediaChannels.instagram_channel}
          target="_blank"
          rel="noreferrer"
          key="instagram"
          style={{ marginRight: '4px' }}
        >
          <img
            src="/images/mediacontent/instagram.png"
            alt="instagram"
            title={record.params.mediaChannels.instagram_channel_name || 'Instagram Profile'}
            style={{ width: '20px' }}
          />
        </a>
          )}
        {record.params.mediaChannels && record.params.mediaChannels.facebook_channel_url && (
        <a
          href={record.params.mediaChannels.facebook_channel_url}
          target="_blank"
          rel="noreferrer"
          key="facebook"
          style={{ marginRight: '4px' }}
        >
          <img
            src="/images/mediacontent/facebook.png"
            alt="facebook"
            title="Facebook Profile"
            style={{ width: '20px' }}
          />
        </a>
          )}
        {record.params.mediaChannels && record.params.mediaChannels.twitterhandle && (
        <a
          href={record.params.mediaChannels.twitterhandle}
          target="_blank"
          rel="noreferrer"
          key="twitter"
          style={{ marginRight: '4px' }}
        >
          <img
            src="/images/mediacontent/twitter.png"
            alt="X"
            title="X Profile"
            style={{ width: '20px' }}
          />
        </a>
          )}
        {record.params.mediaChannels && record.params.mediaChannels.tiktok_channel_url && (
        <a
          href={record.params.mediaChannels.tiktok_channel_url}
          target="_blank"
          rel="noreferrer"
          key="tiktok"
          style={{ marginRight: '4px' }}
        >
          <img
            src="/images/mediacontent/tiktok.png"
            alt="tiktok"
            title="TikTok Channel"
            style={{ width: '20px' }}
          />
        </a>
          )}
        {record.params.mediaChannels && record.params.mediaChannels.twitch_account && (
        <a
          href={record.params.mediaChannels.twitch_account}
          target="_blank"
          rel="noreferrer"
          key="twitch"
          style={{ marginRight: '4px' }}
        >
          <img
            src="/images/mediacontent/twitch.png"
            alt="twitch"
            title="Twitch Account"
            style={{ width: '20px' }}
          />
        </a>
          )}
        {record.params.mediaChannels && record.params.mediaChannels.apple_podcast_url && (
        <a
          href={record.params.mediaChannels.apple_podcast_url}
          target="_blank"
          key="podcast"
          rel="noreferrer"
          style={{ marginRight: '4px' }}
        >
          <img
            src="/images/mediacontent/podcast_128.png"
            alt="podcast"
            title={record.params.mediaChannels.podcast_title || 'Apple Podcast'}
            style={{ width: '20px' }}
          />
        </a>
          )}
      </div>
    </div>
  </div>
), (a, a1) => _.isEqual(a.record, a1.record));

ContactInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  search: PropTypes.string.isRequired,
};

export default ContactInList;
