import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  CardTitle,
} from 'reactstrap';
import { useAsyncDebounce } from 'react-table';
import Hook from '@/shared/hooks';
import Table from './custom/Table';
import Actions from './custom/Actions';
import Breadcrumb from '@/shared/components/BreadCrumbs';
import actionProperties from './custom/ActionProperties';
import { axios } from 'ApiClient';
import ShowRejectedApprovedBrands from '../Search/components/modals/ShowRejectedApprovedBrands';
import SendEmailModal from './custom/Modal/SendEmailModal';
import UpdateDealTermsModal from './custom/Modal/UpdateDealTermsModal';
import ProcessingSend from './custom/Modal/ProcessingSend';
import ReSendEmailModal from './custom/Modal/ReSendEmailModal';
import EmailStatusModal from './custom/Modal/EmailStatusModal';

const resourceId = 'Opportunity';

const Opportunities = (props) => {
  const {
    history,
  } = props;
  const [settings, setSettings] = React.useState(null);
  const [allTags, setAllTags] = React.useState(null);
  const [searchUrl, setPrefill, loadPage, , useLoadOrDebounce] = Hook.usePrefillHook({
    resourceId: resourceId,
    initialState: {
      sortBy: 'createdAt',
      direction: 'desc',
      page: 1,
      perPage: 10,
    },
  });
  const fetchAmountReport = async () => {
    try {
      const resp = await axios.get('/api/portal/reports/get-rebuy-settings');
      if (resp.data.success) {
        const settingsToUse = {
          types: resp.data.settings?.types || {},
          brands: {},
        };
        Object.values(resp.data.settings?.brands || {}).forEach((item) => {
          if (!settingsToUse.brands[item.advertiser]) settingsToUse.brands[item.advertiser] = {};
          if (!settingsToUse.brands[item.advertiser][item.type]) settingsToUse.brands[item.advertiser][item.type] = item;
        });
        setSettings(settingsToUse || { brands: {}, types: {} });
      } else {
        throw new Error(resp.data.error);
      }
    } catch (e) {
      console.error(e);
    }
    return true;
  };

  const fetchAllTags = async () => {
    try {
      const resp = await axios.get('/api/portal/opportunity/get-unique-tags');
      if (resp.data.success) {
        setAllTags(resp.data.tags || []);
      } else {
        throw new Error(resp.data.error);
      }
    } catch (e) {
      console.error(e);
    }
    return true;
  };

  useEffect(() => {
    fetchAmountReport();
    fetchAllTags();
  }, []);
  useEffect(() => {
    if (allTags) {
      setPrefill(actionProperties({ allTags }));
    }
  }, [allTags]);
  const setUrl = () => {
    loadPage();
  };
  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 600);
  useLoadOrDebounce({
    loadFunc: () => loadPage(), loadDebounceFunc: () => setUrlDebounced(), setSearchUrl: () => history.push(`${history.location.pathname}?${searchUrl}`)
  });
  /* const goToPage = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };
   const downloadCsv = () => {
    const search = searchUrl;
    goToPage(`/api/portal/prospects/get-price-approvals-csv?${search}`);
  }; */
  const downloadCsv = useCallback(() => {
    window.open(`/api/portal/opportunity/download-opportunity-csv?${searchUrl}`, '_blank').focus();
  }, [searchUrl]);
  return (<Container className="dashboard">
    <Breadcrumb
      links={[
        { title: 'Home', path: '/' },
        { title: 'Opportunities', path: null },
      ]}
      isBackButton
    />
    <Card>
      <CardBody>
        <CardTitle>
          <Row className="pb-1">
            <Col md="4" className="pt-1">
              <h3>Opportunities</h3>
            </Col>
          </Row>
          <hr className="mt-2 mb-4" />
        </CardTitle>
        <Row>
          <Col>
            <Actions downloadCsv={downloadCsv} />
            <Row style={{ marginTop: '-10px' }}>
              {searchUrl ? <Table settings={settings}/> : null}
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
    <ShowRejectedApprovedBrands />
    <SendEmailModal />
    <ReSendEmailModal />
    <UpdateDealTermsModal />
    <ProcessingSend />
    <EmailStatusModal />
  </Container>
  );
};
Opportunities.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Opportunities;
