/* eslint react/prop-types: 0 */
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Container, Row, Col, Button, Label, FormGroup, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledButtonDropdown,
  CardBody, CardTitle, Card, Input, InputGroup, InputGroupAddon, InputGroupText,
} from 'reactstrap';
import _ from 'lodash';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import Select from 'react-select';
import SelectAsync from 'react-select/async';
import { useHistory } from 'react-router-dom';
import { useAsyncDebounce } from 'react-table';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import DatePicker from 'react-datepicker';
import MatTableList from '../../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../../shared/components/BreadCrumbs';
import UserPropertyType from './custom';
import withNotice from '../../../../App/store/with-notice';
import { getCookie, setCookie } from '../../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../../shared/components/CheckBox';
import ReviewStatusHistoryModal from './custom/modals/ReviewStatusHistoryModal';
import BulkUpdateMediaContentReviewStatusModal from './custom/modals/BulkUpdateMediaContentReviewStatusModal';
import IntervalDatePickerField from '../../../Influencer/Research/Prospects/components/custom/IntervalDatePickerField';
import NotesModal from './custom/modals/NotesModal';
import getTableColumns from './custom/TableColumns';
import AccessComponent from '../../../../../shared/components/auth/AccessComponent';

const compare = (a, b) => {
  const keyA = a.label;
  const keyB = b.label;
  if (keyA < keyB) return -1;
  if (keyA > keyB) return 1;
  return 0;
};

const typeFilterOptions = [
  { label: 'YouTube', value: 'YouTube' },
  { label: 'YouTube Description Text', value: 'YouTube Description Text' },
  { label: 'Podcast', value: 'Podcast' },
  { label: 'Podcast Script Text', value: 'Podcast Script Text' },
  { label: 'Instagram Story', value: 'Instagram Story' },
  { label: 'Instagram Post', value: 'Instagram Post' },
  { label: 'Instagram TV Video', value: 'Instagram TV Video' },
  { label: 'TikTok', value: 'TikTok' },
  { label: 'X Post', value: 'Twitter Post' },
  { label: 'Facebook Post', value: 'Facebook Post' },
  { label: 'Facebook Watch Video', value: 'Facebook Watch Video' },
  { label: 'LinkTree', value: 'LinkTree' },
  { label: 'Website', value: 'Website' },
  { label: 'Email', value: 'Email' },
  { label: 'Text', value: 'Text' },
];

const dealTypeFilterOptions = [
  { label: 'CPA/Ambassador', value: 'CPA/Ambassador' },
  { label: 'Upfront Only', value: 'Upfront Only' },
  { label: 'Hybrid', value: 'Hybrid' },
  { label: 'Upfront CPM', value: 'Upfront CPM' },
  { label: 'Media License', value: 'Media License' },
  { label: 'Bonus', value: 'Bonus' },
];

const initialReviewStatusFilters = {
  'Needs Review': true,
  'Pending Edits': false,
  Approved: false,
  Terminated: false,
};

const initialPaidProductPlacementFilters = {
  Yes: true,
  No: true,
  'Not Assigned': true,
};

const dateRangeFilterLabels = [
  { value: '=', label: '=' },
  { value: '>', label: '>' },
  { value: '>=', label: '>=' },
  { value: '<', label: '<' },
  { value: '<=', label: '<=' },
  { value: 'Between', label: 'Between' },
];

const loadCreatorsOptions = async (value) => {
  let response = {};
  if (value === 'getByIds') {
    const initialSearch = new URLSearchParams(window.location.search);
    if (initialSearch.has('filters.creator') && initialSearch.getAll('filters.creator').length > 0) {
      response = await axios.get('/api/portal/influencer/get-creators-by-ids', {
        params: { ids: initialSearch.getAll('filters.creator') },
      });
      if (!response.data.success) {
        return {};
      }
    }
  } else {
    response = await axios.get('/api/portal/influencer/get-creator-search', {
      params: {
        value,
        defaultSearch: 'a',
      },
    });
  }
  return response.data.records.map(record => ({
    value: record.params?._id || record._id,
    label: record.params ? `${record.params.first_name} ${record.params.last_name}` : `${record.first_name} ${record.last_name}`,
    record,
  }));
};

const ContentReviewListContainer = ({
  resource,
  action,
  addNotice,
}) => {
  const [version, setVersion] = useState((new Date()).toString());
  const [loadingFilters, setLoadingFilters] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);

  const [filtersReviewStatus, setFiltersReviewStatus] = useState(initialReviewStatusFilters);
  const [filtersPaidProductPlacement, setFiltersPaidProductPlacement] = useState(initialPaidProductPlacementFilters);
  const [brandOptions, setBrandOptions] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [selectedContentTypes, setSelectedContentTypes] = useState([]);
  const [selectedDealTypes, setSelectedDealTypes] = useState([]);

  const [enableLastReviewDateFilter, setEnableLastReviewDateFilter] = useState(true);
  const [singleLastReviewDate, setSingleLastReviewDate] = useState(null);
  const [lastReviewDateFilterType, setLastReviewDateFilterType] = useState(dateRangeFilterLabels[5]);
  const [lastReviewDateFrom, setLastReviewDateFrom] = useState(null);
  const [lastReviewDateTo, setLastReviewDateTo] = useState(null);

  const [enablePublishDateFilter, setEnablePublishDateFilter] = useState(true);
  const [singlePublishDate, setSinglePublishDate] = useState(null);
  const [publishDateFilterType, setPublishDateFilterType] = useState(dateRangeFilterLabels[5]);
  const [publishDateFrom, setPublishDateFrom] = useState(null);
  const [publishDateTo, setPublishDateTo] = useState(null);

  const [enableBrandApprovedDateFilter, setEnableBrandApprovedDateFilter] = useState(true);
  const [singleBrandApprovedDate, setSingleBrandApprovedDate] = useState(null);
  const [brandApprovedDateFilterType, setBrandApprovedDateFilterType] = useState(dateRangeFilterLabels[5]);
  const [brandApprovedDateFrom, setBrandApprovedDateFrom] = useState(null);
  const [brandApprovedDateTo, setBrandApprovedDateTo] = useState(null);

  const [searchValue, setSearchValue] = useState('');
  const [showFilters, setShowFilters] = useState(getCookie('contentReviewShowFilters') === 'true');
  const [filterOptionsLoaded, setFilterOptionsLoaded] = useState(false);
  const [showUpdateReviewStatusModal, setShowUpdateReviewStatusModal] = useState(false);

  const [disableNote, setDisableNote] = useState(false);
  const [currentMediaContent, setCurrentMediaContent] = useState({});
  const [showNotesModal, setShowNotesModal] = useState(false);

  const [metaTotal, setMetaTotal] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);

  const customColumns = useMemo(() => getTableColumns(
    setDisableNote,
    setShowNotesModal,
    setCurrentMediaContent,
    initialReviewStatusFilters,
    setVersion,
  ), []);

  const handleSelectCheckbox = (ids) => {
    setSelectedIds(ids || []);
  };

  useEffect(() => {
    setCookie('contentReviewShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  useEffect(() => {
    if(!enableLastReviewDateFilter) {
      setLastReviewDateFrom(null);
      setLastReviewDateTo(null);
      setSingleLastReviewDate(null);
    }
  }, [enableLastReviewDateFilter]);

  useEffect(() => {
    if(!enablePublishDateFilter) {
      setPublishDateFrom(null);
      setPublishDateTo(null);
      setSinglePublishDate(null);
    }
  }, [enablePublishDateFilter]);

  useEffect(() => {
    if(!enableBrandApprovedDateFilter) {
      setBrandApprovedDateFrom(null);
      setBrandApprovedDateTo(null);
      setSingleBrandApprovedDate(null);
    }
  }, [enableBrandApprovedDateFilter]);

  const getInitialFilters = async () => {
    let contentReviewSearchValue = '';
    const selectedBrandsInitialState = [];
    const selectedDealTypesInitialState = [];
    const selectedCreatorsInitialState = [];
    const selectedDealOwnerInitialState = [];
    const selectedTypesInitialState = [];

    let initialEnableLastReviewDateFilter = false;
    let initialSingleLastReviewDate = null;
    let initialLastReviewDateFilterType = dateRangeFilterLabels[5];
    let initialLastReviewDateFrom = null;
    let initialLastReviewDateTo = null;

    let initialEnablePublishDateFilter = false;
    let initialSinglePublishDate = null;
    let initialPublishDateFilterType = dateRangeFilterLabels[5];
    let initialPublishDateFrom = null;
    let initialPublishDateTo = null;

    let initialEnableBrandApprovedDateFilter = false;
    let initialSingleBrandApprovedDate = null;
    let initialBrandApprovedDateFilterType = dateRangeFilterLabels[5];
    let initialBrandApprovedDateFrom =null;
    let initialBrandApprovedDateTo = null;

    const initialSearch = new URLSearchParams(window.location.search);
    if (initialSearch.has('filters.contentReviewSearchValue') && initialSearch.get('filters.contentReviewSearchValue')) {
      contentReviewSearchValue = initialSearch.get('filters.contentReviewSearchValue');
    }

    if (initialSearch.has('lastReviewDateType') && initialSearch.get('lastReviewDateType')) {
      initialLastReviewDateFilterType = dateRangeFilterLabels.find(item => item.value === initialSearch.get('lastReviewDateType'));
    }
    if (initialLastReviewDateFilterType.value === '='
      && initialSearch.has('filters.contentReviewDate.from')
      && initialSearch.get('filters.contentReviewDate.from')
    ) {
      initialSingleLastReviewDate = new Date(initialSearch.get('filters.contentReviewDate.from'));
    } else if (initialSearch.has('filters.contentReviewDate.from')
      && initialSearch.has('filters.contentReviewDate.to')
      && initialSearch.get('filters.contentReviewDate.from')
      && initialSearch.get('filters.contentReviewDate.to')
    ) {
      initialLastReviewDateFrom = new Date(initialSearch.get('filters.contentReviewDate.from'));
      initialLastReviewDateTo = new Date(initialSearch.get('filters.contentReviewDate.to'));
    } else if (initialSearch.has('filters.contentReviewDate.from')
      && !initialSearch.has('filters.contentReviewDate.to')
      && initialSearch.get('filters.contentReviewDate.from')
    ) {
      initialSingleLastReviewDate = new Date(initialSearch.get('filters.contentReviewDate.from'));
    } else if (!initialSearch.has('filters.contentReviewDate.from')
      && initialSearch.has('filters.contentReviewDate.to')
      && initialSearch.get('filters.contentReviewDate.to')
    ) {
      initialSingleLastReviewDate = new Date(initialSearch.get('filters.contentReviewDate.to'));
    }
    if (initialSingleLastReviewDate || initialLastReviewDateFrom || initialLastReviewDateTo) {
      initialEnableLastReviewDateFilter = true;
    }

    if (initialSearch.has('publishDateType') && initialSearch.get('publishDateType')) {
      initialPublishDateFilterType = dateRangeFilterLabels.find(item => item.value === initialSearch.get('publishDateType'));
    }
    if (initialPublishDateFilterType.value === '='
      && initialSearch.has('filters.publishDate.from')
      && initialSearch.get('filters.publishDate.from')
    ) {
      initialSinglePublishDate = new Date(initialSearch.get('filters.publishDate.from'));
    } else if (initialSearch.has('filters.publishDate.from')
      && initialSearch.has('filters.publishDate.to')
      && initialSearch.get('filters.publishDate.from')
      && initialSearch.get('filters.publishDate.to')
    ) {
      initialPublishDateFrom = new Date(initialSearch.get('filters.publishDate.from'));
      initialPublishDateTo = new Date(initialSearch.get('filters.publishDate.to'));
    } else if (initialSearch.has('filters.publishDate.from')
      && !initialSearch.has('filters.publishDate.to')
      && initialSearch.get('filters.publishDate.from')
    ) {
      initialSinglePublishDate = new Date(initialSearch.get('filters.publishDate.from'));
    } else if (!initialSearch.has('filters.publishDate.from')
      && initialSearch.has('filters.publishDate.to')
      && initialSearch.get('filters.publishDate.to')
    ) {
      initialSinglePublishDate = new Date(initialSearch.get('filters.publishDate.to'));
    }
    if (initialSinglePublishDate || initialPublishDateFrom || initialPublishDateTo) {
      initialEnablePublishDateFilter = true;
    }

    if (initialSearch.has('brandApprovedDateType') && initialSearch.get('brandApprovedDateType')) {
      initialBrandApprovedDateFilterType = dateRangeFilterLabels.find(item => item.value === initialSearch.get('brandApprovedDateType'));
    }
    if (initialBrandApprovedDateFilterType.value === '='
      && initialSearch.has('filters.brandApprovedDate.from')
      && initialSearch.get('filters.brandApprovedDate.from')
    ) {
      initialSingleBrandApprovedDate = new Date(initialSearch.get('filters.brandApprovedDate.from'));
    } else if (initialSearch.has('filters.brandApprovedDate.from')
      && initialSearch.has('filters.brandApprovedDate.to')
      && initialSearch.get('filters.brandApprovedDate.from')
      && initialSearch.get('filters.brandApprovedDate.to')
    ) {
      initialBrandApprovedDateFrom = new Date(initialSearch.get('filters.brandApprovedDate.from'));
      initialBrandApprovedDateTo = new Date(initialSearch.get('filters.brandApprovedDate.to'));
    } else if (initialSearch.has('filters.brandApprovedDate.from')
      && !initialSearch.has('filters.brandApprovedDate.to')
      && initialSearch.get('filters.brandApprovedDate.from')
    ) {
      initialSingleBrandApprovedDate = new Date(initialSearch.get('filters.brandApprovedDate.from'));
    } else if (!initialSearch.has('filters.brandApprovedDate.from')
      && initialSearch.has('filters.brandApprovedDate.to')
      && initialSearch.get('filters.brandApprovedDate.to')
    ) {
      initialSingleBrandApprovedDate = new Date(initialSearch.get('filters.brandApprovedDate.to'));
    }
    if (initialSingleBrandApprovedDate || initialBrandApprovedDateFrom || initialBrandApprovedDateTo) {
      initialEnableBrandApprovedDateFilter = true;
    }

    if (initialSearch.has('filters.type') && initialSearch.getAll('filters.type').length > 0) {
      initialSearch.getAll('filters.type').forEach((type) => {
        selectedTypesInitialState.push({ value: type, label: type });
      });
    }
    if (initialSearch.has('filters.brand') && initialSearch.getAll('filters.brand').length > 0) {
      initialSearch.getAll('filters.brand').forEach((brand) => {
        selectedBrandsInitialState.push({ value: brand, label: brand });
      });
    }
    if (initialSearch.has('filters.dealType') && initialSearch.getAll('filters.dealType').length > 0) {
      initialSearch.getAll('filters.dealType').forEach((dealType) => {
        selectedDealTypesInitialState.push({ value: dealType, label: dealType });
      });
    }
    if (initialSearch.has('filters.dealOwner') && initialSearch.getAll('filters.dealOwner').length > 0) {
      initialSearch.getAll('filters.dealOwner').forEach((dealOwner) => {
        selectedDealOwnerInitialState.push({ value: dealOwner, label: dealOwner });
      });
    }
    if (initialSearch.has('filters.reviewStatus') && initialSearch.getAll('filters.reviewStatus').length > 0) {
      Object.keys(initialReviewStatusFilters).map((key) => {
        initialReviewStatusFilters[key] = false;
        return true;
      });
      initialSearch.getAll('filters.reviewStatus').forEach((type) => {
        initialReviewStatusFilters[type] = true;
      });
    }

    if (initialSearch.has('filters.creator') && initialSearch.getAll('filters.creator').length > 0) {
      loadCreatorsOptions('getByIds').then((response) => {
        setSelectedCreators(response);
      });
    }


    setBrandOptions(selectedBrandsInitialState);
    setSelectedBrands(selectedBrandsInitialState);
    setSelectedCreators(selectedCreatorsInitialState);
    setSelectedContentTypes(selectedTypesInitialState);
    setSelectedDealTypes(selectedDealTypesInitialState);

    setEnableLastReviewDateFilter(initialEnableLastReviewDateFilter);
    setSingleLastReviewDate(initialSingleLastReviewDate);
    setLastReviewDateFilterType(initialLastReviewDateFilterType);
    setLastReviewDateFrom(initialLastReviewDateFrom);
    setLastReviewDateTo(initialLastReviewDateTo);

    setEnablePublishDateFilter(initialEnablePublishDateFilter);
    setSinglePublishDate(initialSinglePublishDate);
    setPublishDateFilterType(initialPublishDateFilterType);
    setPublishDateFrom(initialPublishDateFrom);
    setPublishDateTo(initialPublishDateTo);

    setEnableBrandApprovedDateFilter(initialEnableBrandApprovedDateFilter);
    setSingleBrandApprovedDate(initialSingleBrandApprovedDate);
    setBrandApprovedDateFilterType(initialBrandApprovedDateFilterType);
    setBrandApprovedDateFrom(initialBrandApprovedDateFrom);
    setBrandApprovedDateTo(initialBrandApprovedDateTo);

    setSearchValue(contentReviewSearchValue);
    setSelectedEmployee(selectedDealOwnerInitialState);
  };

  const fetchDealOwnersData = async () => {
    axios.get('/api/portal/finance/get-deal-owners').then((resp) => {
      setEmployees(resp.data.dealOwners);
      if (selectedEmployee.length > 0) {
        const selectedDealOwners = [];
        selectedEmployee.forEach((item) => {
          const resItem = resp.data.dealOwners.find(a => Number(a.hsUserId) === Number(item.value));
          selectedDealOwners.push({ value: resItem.hsUserId, label: `${resItem.firstName || ''} ${resItem.lastName || ''}` });
        });
        setSelectedEmployee(selectedDealOwners);
      }
    }).catch(() => {
      setEmployees([]);
    });
  };

  const fetchFilterData = async () => {
    setLoadingFilters(true);
    try {
      const search = new URLSearchParams(window.location.search);

      const resp = await axios.get('/api/portal/scorecard/media-content/get-filters-data-set');
      if (resp?.data?.success) {
        setBrandOptions(resp?.data?.brandOptions?.sort(compare) || []);

        const foundTypeOptions = [];
        const foundBrandOptions = [];

        if (search.has('filters.type') && search.getAll('filters.type').length > 0) {
          search.getAll('filters.type').forEach((type) => {
            const foundOption = typeFilterOptions.find(option => option.value === type);
            if (foundOption) foundTypeOptions.push(foundOption);
          });
        }
        if (foundTypeOptions.length > 0) setSelectedContentTypes(foundTypeOptions);

        if (search.has('filters.brand') && search.getAll('filters.brand').length > 0) {
          search.getAll('filters.brand').forEach((brand) => {
            const foundOption = resp?.data?.brandOptions?.find(option => option.value === brand);
            if (foundOption) foundBrandOptions.push(foundOption);
          });
        }
        if (foundBrandOptions.length > 0) setSelectedBrands(foundBrandOptions);

        setLoadingFilters(false);
        setFilterOptionsLoadedTrueDebounced();
      } else {
        throw new Error(resp?.data?.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  useEffect(() => {
    fetchDealOwnersData();
    fetchFilterData();
    getInitialFilters();
  }, []);

  const history = useHistory();

  const setFilterOptionsLoadedTrueDebounced = useAsyncDebounce(() => {
    setFilterOptionsLoaded(true);
  }, 500);

  const selectItem = (value, type) => {
    switch (type) {
      case 'creator':
        setSelectedCreators(value);
        break;
      case 'dealOwner':
        setSelectedEmployee(value);
        break;
      case 'type':
        setSelectedContentTypes(value);
        break;
      case 'dealType':
        setSelectedDealTypes(value);
        break;
      case 'brand':
        setSelectedBrands(value);
        break;
      default:
        return false;
    }
    return true;
  };

  const firstUpdate = useRef(true);

  const setUrl = (removeBeforeSet = false) => {
    let search = new URLSearchParams(window.location.search);
    const oldUrl = `${history.location.pathname}?${search.toString()}`;

    if (removeBeforeSet) {
      search = new URLSearchParams();
    }

    search.delete('filters.contentReviewSearchValue');
    if (searchValue) search.append('filters.contentReviewSearchValue', searchValue);

    const filterCreators = search.getAll('filters.creator');
    let deleteFilterCreators = false;

    if (!selectedCreators || selectedCreators.length === 0) {
      search.delete('filters.creator');
    }
    if (selectedCreators) {
      filterCreators.forEach((creator) => {
        if (selectedCreators.filter(item => item.value === creator).length === 0) {
          deleteFilterCreators = true;
        }
      });
      if (deleteFilterCreators) {
        search.delete('filters.creator');
      }
      selectedCreators.forEach((creator) => {
        if (creator.value !== '' && (!filterCreators.includes(creator.value) || deleteFilterCreators)) {
          search.append('filters.creator', creator.value);
        }
      });
    }

    const searchCreatorsEmails = (search.getAll('filters.creator') || []).map(email => decodeURI(email)).sort();
    const filterCreatorsEmails = (selectedCreators || []).map(option => decodeURI(option.value)).sort();
    const isEqual = _.isEqual(searchCreatorsEmails, filterCreatorsEmails);
    if (!isEqual) {
      if (!selectedCreators || selectedCreators.length === 0) {
        search.delete('filters.creator');
      }
      if (selectedCreators) {
        filterCreators.forEach((creator) => {
          if (selectedCreators.filter(item => item.value === creator).length === 0) {
            deleteFilterCreators = true;
          }
        });
        if (deleteFilterCreators) {
          search.delete('filters.creator');
        }
        selectedCreators.forEach((selectedCreator) => {
          if (selectedCreator.value !== '' && (!filterCreators.includes(selectedCreator.value) || deleteFilterCreators)) {
            search.append('filters.creator', selectedCreator.value);
          }
        });
      }
    }

    const filterBrands = search.getAll('filters.brand');
    let deleteFilterBrands = false;

    if (!selectedBrands || selectedBrands.length === 0) {
      search.delete('filters.brand');
    }
    if (selectedBrands) {
      filterBrands.forEach((brand) => {
        if (selectedBrands.filter(item => item.value === brand).length === 0) {
          deleteFilterBrands = true;
        }
      });
      if (deleteFilterBrands) {
        search.delete('filters.brand');
      }
      selectedBrands.forEach((brand) => {
        if (brand.value !== '' && (!filterBrands.includes(brand.value) || deleteFilterBrands)) {
          search.append('filters.brand', brand.value);
        }
      });
    }
    const filterDealOwner = search.getAll('filters.dealOwner');
    let deleteFilterOwner = false;

    if (!selectedEmployee || selectedEmployee.length === 0) {
      search.delete('filters.dealOwner');
    }
    if (selectedEmployee) {
      filterDealOwner.forEach((brand) => {
        if (selectedEmployee.filter(item => item.value === brand).length === 0) {
          deleteFilterOwner = true;
        }
      });
      if (deleteFilterOwner) {
        search.delete('filters.dealOwner');
      }
      selectedEmployee.forEach((brand) => {
        if (brand.value !== '' && (!filterDealOwner.includes(brand.value) || deleteFilterOwner)) {
          search.append('filters.dealOwner', brand.value);
        }
      });
    }

    const filtersContentTypes = search.getAll('filters.type');
    let deleteFilterContentTypes = false;

    if (!selectedContentTypes || selectedContentTypes.length === 0) {
      search.delete('filters.type');
    }
    if (selectedContentTypes) {
      filtersContentTypes.forEach((type) => {
        if (selectedContentTypes.filter(item => item.value === type).length === 0) {
          deleteFilterContentTypes = true;
        }
      });
      if (deleteFilterContentTypes) {
        search.delete('filters.type');
      }
      selectedContentTypes.forEach((selectedType) => {
        if (selectedType.value !== '' && (!filtersContentTypes.includes(selectedType.value) || deleteFilterContentTypes)) {
          search.append('filters.type', selectedType.value);
        }
      });
    }

    const filtersDealTypes = search.getAll('filters.dealType');
    let deleteFilterDealTypes = false;

    if (!selectedDealTypes || selectedDealTypes.length === 0) {
      search.delete('filters.dealType');
    }
    if (selectedDealTypes) {
      filtersDealTypes.forEach((type) => {
        if (selectedDealTypes.filter(item => item.value === type).length === 0) {
          deleteFilterDealTypes = true;
        }
      });
      if (deleteFilterDealTypes) {
        search.delete('filters.dealType');
      }
      selectedDealTypes.forEach((selectedType) => {
        if (selectedType.value !== '' && (!filtersDealTypes.includes(selectedType.value) || deleteFilterDealTypes)) {
          search.append('filters.dealType', selectedType.value);
        }
      });
    }

    search.delete('filters.contentReviewDate.from');
    search.delete('filters.contentReviewDate.to');
    search.delete('lastReviewDateType');

    if (lastReviewDateFrom && lastReviewDateFrom !== '') {
      search.delete('filters.contentReviewDate.from');
      search.append('filters.contentReviewDate.from', lastReviewDateFrom);
    }
    if (lastReviewDateTo && lastReviewDateTo !== '') {
      search.delete('filters.contentReviewDate.to');
      search.append('filters.contentReviewDate.to', lastReviewDateTo);
    }
    if (lastReviewDateFilterType.value !== 'Between' && singleLastReviewDate && singleLastReviewDate !== '') {
      search.delete('filters.contentReviewDate.from');
      search.delete('filters.contentReviewDate.to');
      if (lastReviewDateFilterType.value === '>' || lastReviewDateFilterType.value === '>=') {
        search.append('filters.contentReviewDate.from', singleLastReviewDate.toISOString());
      }
      if (lastReviewDateFilterType.value === '<' || lastReviewDateFilterType.value === '<=') {
        search.append('filters.contentReviewDate.to', singleLastReviewDate.toISOString());
      }
      if (lastReviewDateFilterType.value === '=') {
        search.append('filters.contentReviewDate.from', singleLastReviewDate.toISOString());
        const nextDay = new Date(singleLastReviewDate);
        nextDay.setDate(singleLastReviewDate.getDate() + 1);
        search.append('filters.contentReviewDate.to', nextDay.toISOString());
      }
    }
    if (lastReviewDateFilterType.value && ((lastReviewDateFrom && lastReviewDateTo) || singleLastReviewDate)) {
      search.append('lastReviewDateType', lastReviewDateFilterType.value);
    }

    search.delete('filters.publishDate.from');
    search.delete('filters.publishDate.to');
    search.delete('publishDateType');

    if (publishDateFrom && publishDateFrom !== '') {
      search.delete('filters.publishDate.from');
      search.append('filters.publishDate.from', publishDateFrom);
    }
    if (publishDateTo && publishDateTo !== '') {
      search.delete('filters.publishDate.to');
      search.append('filters.publishDate.to', publishDateTo);
    }
    if (publishDateFilterType.value !== 'Between' && singlePublishDate && singlePublishDate !== '') {
      search.delete('filters.publishDate.from');
      search.delete('filters.publishDate.to');
      if (publishDateFilterType.value === '>' || publishDateFilterType.value === '>=') {
        search.append('filters.publishDate.from', singlePublishDate.toISOString());
      }
      if (publishDateFilterType.value === '<' || publishDateFilterType.value === '<=') {
        search.append('filters.publishDate.to', singlePublishDate.toISOString());
      }
      if (publishDateFilterType.value === '=') {
        search.append('filters.publishDate.from', singlePublishDate.toISOString());
        const nextDay = new Date(singlePublishDate);
        nextDay.setDate(singlePublishDate.getDate() + 1);
        search.append('filters.publishDate.to', nextDay.toISOString());
      }
    }
    if (publishDateFilterType.value && ((publishDateFrom && publishDateTo) || singlePublishDate)) {
      search.append('publishDateType', publishDateFilterType.value);
    }

    search.delete('filters.brandApprovedDate.from');
    search.delete('filters.brandApprovedDate.to');
    search.delete('brandApprovedDateType');

    if (brandApprovedDateFrom && brandApprovedDateFrom !== '') {
      search.delete('filters.brandApprovedDate.from');
      search.append('filters.brandApprovedDate.from', brandApprovedDateFrom);
    }
    if (brandApprovedDateTo && brandApprovedDateTo !== '') {
      search.delete('filters.brandApprovedDate.to');
      search.append('filters.brandApprovedDate.to', brandApprovedDateTo);
    }
    if (brandApprovedDateFilterType.value !== 'Between' && singleBrandApprovedDate && singleBrandApprovedDate !== '') {
      search.delete('filters.brandApprovedDate.from');
      search.delete('filters.brandApprovedDate.to');
      if (brandApprovedDateFilterType.value === '>' || brandApprovedDateFilterType.value === '>=') {
        search.append('filters.brandApprovedDate.from', singleBrandApprovedDate.toISOString());
      }
      if (brandApprovedDateFilterType.value === '<' || brandApprovedDateFilterType.value === '<=') {
        search.append('filters.brandApprovedDate.to', singleBrandApprovedDate.toISOString());
      }
      if (brandApprovedDateFilterType.value === '=') {
        search.append('filters.brandApprovedDate.from', singleBrandApprovedDate.toISOString());
        const nextDay = new Date(singleBrandApprovedDate);
        nextDay.setDate(singleBrandApprovedDate.getDate() + 1);
        search.append('filters.brandApprovedDate.to', nextDay.toISOString());
      }
    }
    if (brandApprovedDateFilterType.value && ((brandApprovedDateFrom && brandApprovedDateTo) || singleBrandApprovedDate)) {
      search.append('brandApprovedDateType', brandApprovedDateFilterType.value);
    }

    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else if (filterOptionsLoaded) {
      search.delete('filters.reviewStatus');
      let allSwitchedOffStatus = true;
      if (filtersReviewStatus) {
        Object.keys(filtersReviewStatus).forEach((key) => {
          if (filtersReviewStatus[key]) {
            allSwitchedOffStatus = false;
            search.append('filters.reviewStatus', key);
          }
        });
      }
      if (allSwitchedOffStatus) {
        search.append('filters.reviewStatus', 'Not Exist');
      }
      search.delete('filters.hasPaidProductPlacement');
      if (filtersPaidProductPlacement) {
        Object.keys(filtersPaidProductPlacement).forEach((key) => {
          if (filtersPaidProductPlacement[key]) {
            search.append('filters.hasPaidProductPlacement', key);
          }
        });
      }
    }

    let newUrl = `${history.location.pathname}?${search.toString()}`;
    if (oldUrl !== newUrl) {
      search.set('page', '1');
      newUrl = `${history.location.pathname}?${search.toString()}`;
      history.push(newUrl);
    }
  };

  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 800);

  useEffect(() => {
    setUrlDebounced();
  }, [
    searchValue,
    selectedCreators,
    selectedBrands,
    selectedContentTypes,
    selectedDealTypes,
    filtersReviewStatus,
    filtersPaidProductPlacement,
    lastReviewDateFrom, lastReviewDateTo, singleLastReviewDate, lastReviewDateFilterType,
    publishDateFrom, publishDateTo, singlePublishDate, publishDateFilterType,
    brandApprovedDateFrom, brandApprovedDateTo, singleBrandApprovedDate, brandApprovedDateFilterType, selectedEmployee,
  ]);

  const handleChangeReviewStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFiltersReviewStatus({
        ...filtersReviewStatus,
        [name]: checked,
      });
    }
  };

  const handleChangePaidProductPlacementFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFiltersPaidProductPlacement({
        ...filtersPaidProductPlacement,
        [name]: checked,
      });
    }
  };

  const handleDownloadCSV = () => {
    window.open(`/resources/content-review/download-csv?${new URLSearchParams(window.location.search).toString()}`, '_blank').focus();
  };

  const resetFilters = () => {
    setSearchValue('');
    setSelectedBrands([]);
    setSelectedCreators([]);
    setSelectedContentTypes([]);
    setSelectedDealTypes([]);
    setFiltersReviewStatus({
      'Needs Review': true,
      'Pending Edits': false,
      Approved: false,
      Terminated: false,
    });
    setFiltersPaidProductPlacement({
      Yes: true,
      No: true,
      'Not Assigned': true,
    })

    setEnableLastReviewDateFilter(false);
    setLastReviewDateFilterType(dateRangeFilterLabels[5]);
    setLastReviewDateFrom(null);
    setLastReviewDateTo(null);
    setSingleLastReviewDate(null);

    setEnablePublishDateFilter(false);
    setPublishDateFilterType(dateRangeFilterLabels[5]);
    setPublishDateFrom(null);
    setPublishDateTo(null);
    setSinglePublishDate(null);

    setEnableBrandApprovedDateFilter(false);
    setBrandApprovedDateFilterType(dateRangeFilterLabels[5]);
    setBrandApprovedDateFrom(null);
    setBrandApprovedDateTo(null);
    setSingleBrandApprovedDate(null);
  };

  const links = [
    { title: 'Home', path: '/' },
    { title: 'Compliance', path: false },
    { title: 'Historical Content Review', path: false },
  ];

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };

  const customStyles = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? '#e9ecef' : 'white',
    }),
  };
  const employeeOptions = useMemo(() => {
    const i = employees.map(item => ({ value: String(item.hsUserId), label: `${item.firstName || ''} ${item.lastName || ''}`, item }));
    // i.unshift({ value: null, label: ' -- All -- ' });
    return i;
  }, [employees]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs links={links} isBackButton />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody style={{ padding: '2rem 2rem 0 2rem' }}>
              <CardTitle>
                <h3>Historical Content Review</h3>
                <AccessComponent
                  showWhenTeam={['super_powers', 'management', 'compliance']}
                  className="float-right"
                >
                  <a
                    href="/compliance/content-review-rules"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="btn btn-primary btn-sm float-right"
                    style={{
                      position: 'absolute',
                      top: '30px',
                      right: '32px',
                      marginRight: '0',
                      marginBottom: '0',
                    }}
                  >
                    Content Review Rules
                  </a>
                </AccessComponent>
                <hr className="mt-3 mb-4"/>
              </CardTitle>
              <Row className="mt-4">
              <Col sm={8}>
                  <InputGroup>
                    <Input
                      className="form-control-sm form-control"
                      name="search"
                      type="search"
                      autoComplete="off"
                      placeholder="Search Video Title, Video Description or Creator Name"
                      value={searchValue}
                      onChange={p => setSearchValue(p.target.value)}
                      style={{ height: '38px' }}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <i
                          className="fa fa-search"
                          aria-hidden="true"
                          style={{ color: '#70bbfd', fontSize: 12 }}
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col sm={2}>
                  <Row>
                    <Col sm={8} style={{ paddingRight: '0' }}>
                      <Tooltip title="Reset Filters">
                        <Button
                          color="secondary"
                          size="sm"
                          onClick={resetFilters}
                          style={{
                            width: '100%',
                            padding: '4px 8px',
                            height: '38px',
                          }}
                        >
                          <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                          <span className="btn-text">Reset</span>
                        </Button>
                      </Tooltip>
                    </Col>
                    <Col sm={1} style={{ paddingLeft: '24px' }}>
                      <Tooltip title="Toggle Filters">
                        <IconButton
                          id="filtersMenuButton"
                          size="small"
                          onClick={() => setShowFilters(!showFilters)}
                          className="material-table__toolbar-button"
                          style={{
                            height: '38px',
                          }}
                        >
                          <TuneIcon size="30" />
                        </IconButton>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
                <Col md={2} className="text-right">
                  <UncontrolledButtonDropdown className="w-100">
                    <DropdownToggle
                      id="actionsMenu"
                      caret
                      color="primary"
                      style={{
                        padding: '4px 25px',
                        height: '38px',
                      }}
                    >
                      Actions
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        disabled={selectedIds.length < 1}
                        onClick={() => setShowUpdateReviewStatusModal(true)}
                      >
                        Bulk Update Review Status
                      </DropdownItem>
                      <DropdownItem
                        onClick={handleDownloadCSV}
                      >
                        Download CSV
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </Col>
              </Row>
              { showFilters && (
                <>
                  <Row>
                    <Col sm={6} lg="">
                      <FormGroup>
                        <Label className="bold-text">
                          Brand(s)
                        </Label>
                        <Select
                          isMulti
                          name="brandSelect"
                          isDisabled={loadingFilters}
                          isLoading={loadingFilters}
                          isClearable
                          value={!loadingFilters ? selectedBrands : []}
                          options={brandOptions}
                          onChange={value => selectItem(value, 'brand')}
                          placeholder="Select Brand..."
                          style={{ maxHeight: '120px' }}
                          styles={colourStyles}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={6} lg="">
                      <FormGroup>
                        <Label className="bold-text">
                          Creator(s)
                        </Label>
                        <SelectAsync
                          cacheOptions
                          defaultOptions
                          isClearable
                          isMulti
                          loadOptions={async value => loadCreatorsOptions(value)}
                          onChange={value => selectItem(value, 'creator')}
                          placeholder="Select Creator..."
                          value={selectedCreators}
                          styles={colourStyles}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={6} lg="">
                      <FormGroup>
                        <Label className="bold-text">
                          Deal Owners(s)
                        </Label>
                        <Select
                          cacheOptions
                          defaultOptions
                          isClearable
                          isMulti
                          loadOptions={async value => loadCreatorsOptions(value)}
                          onChange={value => selectItem(value, 'dealOwner')}
                          placeholder="Select Deal Owner..."
                          value={selectedEmployee}
                          options={employeeOptions}
                          styles={colourStyles}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={6} lg="">
                      <FormGroup>
                        <Label className="bold-text">
                          Content Type(s)
                        </Label>
                        <Select
                          isMulti
                          name="typeSelect"
                          isDisabled={loadingFilters}
                          isLoading={loadingFilters}
                          isClearable
                          value={!loadingFilters ? selectedContentTypes : []}
                          options={typeFilterOptions}
                          onChange={value => selectItem(value, 'type')}
                          placeholder="Select Content Type..."
                          style={{ maxHeight: '120px' }}
                          styles={colourStyles}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3} lg="">
                      <FormGroup>
                        <Label className="bold-text">Deal Type</Label>
                        <Select
                          isMulti
                          name="dealTypeSelect"
                          isClearable
                          value={selectedDealTypes}
                          options={dealTypeFilterOptions}
                          onChange={value => selectItem(value, 'dealType')}
                          placeholder="Select Deal Type..."
                          style={{ maxHeight: '120px' }}
                          styles={colourStyles}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <Row>
                        <Col md={6}>
                          <Label className="bold-text" style={{ marginBottom: '8px' }}>
                            Review Status
                          </Label>
                          <FormGroup
                            style={{ marginBottom: '10px' }}
                          >
                            <CheckBoxField
                              name="needsReviewStatus"
                              label="Needs Review"
                              value={filtersReviewStatus['Needs Review']}
                              onChange={event => handleChangeReviewStatusFilters(event, 'Needs Review')}
                            />
                          </FormGroup>
                          <FormGroup
                            style={{ marginBottom: '10px' }}
                          >
                            <CheckBoxField
                              name="pendingEditsStatus"
                              label="Pending Edits"
                              value={filtersReviewStatus['Pending Edits']}
                              onChange={event => handleChangeReviewStatusFilters(event, 'Pending Edits')}
                            />
                          </FormGroup>
                          <FormGroup
                            style={{ marginBottom: '10px' }}
                          >
                            <CheckBoxField
                              name="ApprovedStatus"
                              label="Approved"
                              value={filtersReviewStatus.Approved}
                              onChange={event => handleChangeReviewStatusFilters(event, 'Approved')}
                            />
                          </FormGroup>
                          <FormGroup style={{ marginBottom: '0' }} >
                            <CheckBoxField
                              name="TerminatedStatus"
                              label="Terminated"
                              value={filtersReviewStatus.Terminated}
                              onChange={event => handleChangeReviewStatusFilters(event, 'Terminated')}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <Label className="bold-text" style={{ marginBottom: '8px' }}>
                            Includes Paid Promotion?
                          </Label>
                          <FormGroup style={{ marginBottom: '10px' }} >
                            <CheckBoxField
                              name="YesPaidProductPlacement"
                              label="Yes"
                              value={filtersPaidProductPlacement.Yes}
                              onChange={event => handleChangePaidProductPlacementFilters(event, 'Yes')}
                            />
                          </FormGroup>
                          <FormGroup style={{ marginBottom: '10px' }} >
                            <CheckBoxField
                              name="NoPaidProductPlacement"
                              label="No"
                              value={filtersPaidProductPlacement.No}
                              onChange={event => handleChangePaidProductPlacementFilters(event, 'No')}
                            />
                          </FormGroup>
                          <FormGroup style={{ marginBottom: '0' }} >
                            <CheckBoxField
                              name="NotAssignedPaidProductPlacement"
                              label="Not Assigned"
                              value={filtersPaidProductPlacement['Not Assigned']}
                              onChange={event => handleChangePaidProductPlacementFilters(event, 'Not Assigned')}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={3}>
                      <Label className="bold-text">Last Review Date</Label>
                      <Row>
                        <Col sm={1} style={{ paddingLeft: '6px', paddingRight: '0' }}>
                          <FormGroup
                            style={{ paddingTop: '8px', paddingLeft: '8px' }}
                          >
                            <CheckBoxField
                              name="LastReviewDate"
                              value={enableLastReviewDateFilter}
                              onChange={() => { setEnableLastReviewDateFilter(!enableLastReviewDateFilter); }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={5} style={{ paddingLeft: '15px', paddingRight: '0' }}>
                          <Select
                            value={lastReviewDateFilterType}
                            onChange={(option) => {
                              setLastReviewDateFilterType(option);
                            }}
                            options={dateRangeFilterLabels}
                            isDisabled={!enableLastReviewDateFilter}
                            styles={customStyles}
                          />
                        </Col>
                        <Col sm={6}>
                          {lastReviewDateFilterType.value === 'Between' ? (
                            <div style={{ marginRight: '-30px' }}>
                              <IntervalDatePickerField
                                onChange={({ start, end }) => {
                                  setLastReviewDateFrom(start);
                                  end?.setHours(23, 59, 59);
                                  setLastReviewDateTo(end);
                                }}
                                startDate={lastReviewDateFrom}
                                endDate={lastReviewDateTo}
                                maxDate={new Date()}
                                placeholderFrom="Start Date"
                                placeholderTo="End Date"
                                verticalFields
                                disabled={!enableLastReviewDateFilter}
                                datePickerCustomClass="datepicker-in-profile"
                              />
                            </div>
                          ) : (
                            <div className="date-picker datepicker-in-profile">
                              <DatePicker
                                selected={singleLastReviewDate}
                                onChange={(date) => {
                                  if (lastReviewDateFilterType.value === '<=' || lastReviewDateFilterType.value === '>') {
                                    date?.setHours(23, 59, 59);
                                  }
                                  setSingleLastReviewDate(date);
                                }}
                                dateFormat="MMM d, yyyy"
                                maxDate={new Date()}
                                placeholderText="Select Date"
                                dropDownMode="select"
                                isClearable
                                wrapperClassName="date-picker--interval w-100"
                                className="form-control form-control-sm datepicker-in-profile w-100"
                                disabled={!enableLastReviewDateFilter}
                              />
                              {singleLastReviewDate === null && (
                                <svg
                                  className="mdi-icon"
                                  width="24"
                                  height="24"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                  style={{
                                    position: 'absolute',
                                    top: '8px',
                                    right: '22px',
                                    fill: '#999',
                                    pointerEvents: 'none',
                                  }}
                                >
                                  <path
                                    d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                                  />
                                </svg>
                              )}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={3}>
                      <Label className="bold-text">Publish Date</Label>
                      <Row>
                        <Col sm={1} style={{ paddingLeft: '6px', paddingRight: '0' }}>
                          <FormGroup
                            style={{ paddingTop: '8px', paddingLeft: '8px' }}
                          >
                            <CheckBoxField
                              name="PublishDate"
                              value={enablePublishDateFilter}
                              onChange={() => { setEnablePublishDateFilter(!enablePublishDateFilter); }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={5} style={{ paddingLeft: '15px', paddingRight: '0' }}>
                          <Select
                            value={publishDateFilterType}
                            onChange={(option) => {
                              setPublishDateFilterType(option);
                            }}
                            options={dateRangeFilterLabels}
                            isDisabled={!enablePublishDateFilter}
                            styles={customStyles}
                          />
                        </Col>
                        <Col sm={6}>
                          {publishDateFilterType.value === 'Between' ? (
                            <div style={{ marginRight: '-30px' }}>
                              <IntervalDatePickerField
                                onChange={({ start, end }) => {
                                  setPublishDateFrom(start);
                                  end?.setHours(23, 59, 59);
                                  setPublishDateTo(end);
                                }}
                                startDate={publishDateFrom}
                                endDate={publishDateTo}
                                maxDate={new Date()}
                                placeholderFrom="Start Date"
                                placeholderTo="End Date"
                                verticalFields
                                disabled={!enablePublishDateFilter}
                                datePickerCustomClass="datepicker-in-profile"
                              />
                            </div>
                          ) : (
                            <div className="date-picker datepicker-in-profile">
                              <DatePicker
                                selected={singlePublishDate}
                                onChange={(date) => {
                                  if (publishDateFilterType.value === '<=' || publishDateFilterType.value === '>') {
                                    date?.setHours(23, 59, 59);
                                  }
                                  setSinglePublishDate(date);
                                }}
                                dateFormat="MMM d, yyyy"
                                maxDate={new Date()}
                                placeholderText="Select Date"
                                dropDownMode="select"
                                isClearable
                                wrapperClassName="date-picker--interval w-100"
                                className="form-control form-control-sm datepicker-in-profile w-100"
                                disabled={!enablePublishDateFilter}
                              />
                              {singlePublishDate === null && (
                                <svg
                                  className="mdi-icon"
                                  width="24"
                                  height="24"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                  style={{
                                    position: 'absolute',
                                    top: '8px',
                                    right: '22px',
                                    fill: '#999',
                                    pointerEvents: 'none',
                                  }}
                                >
                                  <path
                                    d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                                  />
                                </svg>
                              )}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={3}>
                      <Label className="bold-text">Brand Approved Date</Label>
                      <Row>
                        <Col sm={1} style={{ paddingLeft: '6px', paddingRight: '0' }}>
                          <FormGroup
                            style={{ paddingTop: '8px', paddingLeft: '8px' }}
                          >
                            <CheckBoxField
                              name="BrandApprovedDate"
                              value={enableBrandApprovedDateFilter}
                              onChange={() => { setEnableBrandApprovedDateFilter(!enableBrandApprovedDateFilter); }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={5} style={{ paddingLeft: '15px', paddingRight: '0' }}>
                          <Select
                            value={brandApprovedDateFilterType}
                            onChange={(option) => {
                              setBrandApprovedDateFilterType(option);
                            }}
                            options={dateRangeFilterLabels}
                            isDisabled={!enableBrandApprovedDateFilter}
                            styles={customStyles}
                          />
                        </Col>
                        <Col sm={6}>
                          {brandApprovedDateFilterType.value === 'Between' ? (
                            <div style={{ marginRight: '-30px' }}>
                              <IntervalDatePickerField
                                onChange={({ start, end }) => {
                                  setBrandApprovedDateFrom(start);
                                  end?.setHours(23, 59, 59);
                                  setBrandApprovedDateTo(end);
                                }}
                                startDate={brandApprovedDateFrom}
                                endDate={brandApprovedDateTo}
                                maxDate={new Date()}
                                placeholderFrom="Start Date"
                                placeholderTo="End Date"
                                verticalFields
                                disabled={!enableBrandApprovedDateFilter}
                                datePickerCustomClass="datepicker-in-profile"
                              />
                            </div>
                          ) : (
                            <div className="date-picker datepicker-in-profile">
                              <DatePicker
                                selected={singleBrandApprovedDate}
                                onChange={(date) => {
                                  if (brandApprovedDateFilterType.value === '<=' || brandApprovedDateFilterType.value === '>') {
                                    date?.setHours(23, 59, 59);
                                  }
                                  setSingleBrandApprovedDate(date);
                                }}
                                dateFormat="MMM d, yyyy"
                                maxDate={new Date()}
                                placeholderText="Select Date"
                                dropDownMode="select"
                                isClearable
                                wrapperClassName="date-picker--interval w-100"
                                className="form-control form-control-sm datepicker-in-profile w-100"
                                disabled={!enableBrandApprovedDateFilter}
                              />
                              {singleBrandApprovedDate === null && (
                                <svg
                                  className="mdi-icon"
                                  width="24"
                                  height="24"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                  style={{
                                    position: 'absolute',
                                    top: '8px',
                                    right: '22px',
                                    fill: '#999',
                                    pointerEvents: 'none',
                                  }}
                                >
                                  <path
                                    d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1"
                                  />
                                </svg>
                              )}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
              <Row className="media-content-dashboard-container">
                <MatTableList
                  useCheckboxes
                  checkboxHandler={handleSelectCheckbox}
                  setMetaTotal={setMetaTotal}
                  action={action}
                  resource={resource}
                  date={new Date()}
                  version={version}
                  UserPropertyType={UserPropertyType}
                  hideToolbar
                  sortBy="publishDate"
                  direction="desc"
                  v={2}
                  isResizable={false}
                  customColumns={customColumns}
                  showTitle={false}
                  noActions
                  // useUnflatten
                />
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <NotesModal
        isOpen={showNotesModal}
        setVersion={setVersion}
        disabled={disableNote}
        closeFunc={() => { setShowNotesModal(false); }}
        mediaContent={currentMediaContent}
      />
      <ReviewStatusHistoryModal />
      <BulkUpdateMediaContentReviewStatusModal
        isOpen={showUpdateReviewStatusModal}
        closeFunc={() => setShowUpdateReviewStatusModal(false)}
        setVersion={setVersion}
        mediaContentIds={selectedIds}
        total={metaTotal}
      />
    </Container>
  );
};

const mapStateToProps = state => ({

  resources: state.resources,
  addNotice: PropTypes.func.isRequired,
});

export default connect(mapStateToProps)(withNotice(ContentReviewListContainer));
