exports.isInt = value => typeof value !== 'undefined' && !Number.isNaN(parseInt(value, 10));

exports.isNumber = value => typeof value !== 'undefined' && !Number.isNaN(parseFloat(value));

exports.isEmpty = str => (!str || str.length === 0);

exports.isEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return typeof email !== 'undefined' && re.test(email);
};

exports.emailError = (value) => {
  const email = value.trim();
  if (exports.isEmpty(email)) {
    return 'Please enter your email.';
  } else if (/^@/.test(email)) {
    return `Please enter a part followed by '@'. '${email}' is incomplete.`;
  } else if (email.indexOf('@') === -1) {
    return `Please include an '@' in the email address. '${email}' is missing an '@'.`;
  } else if (/@$/.test(email)) {
    return `Please enter a part following '@'. '${email}' is incomplete.`;
  } else if (!exports.isEmail(email)) {
    return 'Please enter your valid email.';
  }
  return null;
};

exports.isAlphaNumeric = (str) => {
  let code; let i; let len;

  for (i = 0, len = str.length; i < len; i += 1) {
    code = str.charCodeAt(i);
    if (!(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)) { // lower alpha (a-z)
      return false;
    }
  }
  return true;
};

exports.isDoubleQuoteFree = (str) => {
  return !str.includes('"');
};

exports.isAlphaNumericOrWhitespace = (str) => {
  let code; let i; let len;

  for (i = 0, len = str.length; i < len; i += 1) {
    code = str.charCodeAt(i);
    if (!(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123) && // lower alpha (a-z)
      ![32, 45, 46].includes(code)) { // whitespace, hyphens, period
      return false;
    }
  }
  return true;
};

exports.isUrl = (url) => {
  const re = new RegExp('^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$', 'i');
  return typeof url !== 'undefined' && re.test(url);
};

exports.isValidMonth = value => this.isInt(value) && value > 0 && value <= 12;

exports.isValidYear = value => this.isInt(value) && value > 1970 && value <= 2100;

exports.isValidPassword = (value) => {
  // eslint-disable-next-line quotes,no-useless-escape
  const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\$.,\)\(!%\^\*#])(?=.{8,})");
  return (typeof value !== 'undefined' && re.test(value));
};

exports.validateTestModeRecaptchaToken = (token, testEnvironment) => {
  if (!token || token.length === 0) return false;

  const testKey = 'TahoeIsAReallyCoolDog!';
  return testKey === token && (['test', 'local', 'development', 'dev'].includes(testEnvironment));
};

exports.isUrlPath = url => !/[\sA-Z]/.test(url);
exports.clearUrl = url => url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '');
