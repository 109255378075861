import func from '../../../../../../../shared/hooks';

export default ({
  cspOwnersSuggestions, justifications, monthOptions, cpmPriceApprovalsSuggestions, dealstages, countryOptions,
}) => [
  {
    initialState: {
      approved: true,
      inReview: true,
      pendingEdits: true,
      hold: true,
      rejected: true,
    },
    func: func.getFromUrlCheckboxFunction,
    funcToUrl: func.getToUrlCheckboxFunction,
    filterName: 'approvalsBrandStatus',
  },
  {
    initialState: { tags: [] },
    func: ({ filters }) => {
      let items = (Array.isArray(filters?.cspOwners) ? filters?.cspOwners : [filters?.cspOwners]);
      items = filters?.cspOwners ? items : [];
      const tags = items?.map((tag) => {
        let result = { id: tag, name: tag };
        cspOwnersSuggestions?.forEach((t) => {
          if (tag === t.value) {
            result = { ...t, name: t.label, id: t.value };
          }
        });
        return result;
      }) || [];
      return { tags };
    },
    filterName: 'cspOwners',
    funcToUrl: ({ search, item }) => {
      search.delete('filters.cspOwners');
      item?.tags.forEach((a) => {
        search.append('filters.cspOwners', a.id);
      });
      return search;
    },
    options: cspOwnersSuggestions,
  },
  {
    initialState: { tags: [] },
    func: ({ filters }) => {
      let items = (Array.isArray(filters?.dealJustification) ? filters?.dealJustification : [filters?.dealJustification]);
      items = filters?.dealJustification ? items : [];
      const tags = items?.map((tag) => {
        let result = { id: tag, name: tag };
        justifications?.forEach((t) => {
          if (tag === t.value) {
            result = { ...t, name: t.label, id: t.value };
          }
        });
        return result;
      }) || [];
      return { tags };
    },
    funcToUrl: ({ search, item }) => {
      search.delete('filters.dealJustification');
      item?.tags.forEach((a) => {
        search.append('filters.dealJustification', a.id);
      });
      return search;
    },
    options: justifications,
    filterName: 'dealJustification',
  },
  {
    initialState: { tags: [] },
    func: func.getFromUrlSelectfunction,
    /* ({ filters, property }) => {
      let items = (Array.isArray(filters?.contentReleaseDateMonth) ? filters?.contentReleaseDateMonth : [filters?.contentReleaseDateMonth]);
      items = filters?.contentReleaseDateMonth ? items : [];
      console.log(property, filters, 'property');
      const tags = items?.map((tag) => {
        const prop = property.options.find(a => a.value === tag);
        if (!prop) return null;
        return { value: prop.value, label: prop.label };
      }).filter(a => a) || [];
      return { tags };
    }, */
    funcToUrl: func.getToUrlSelectfunction,
    /* ({ search, item }) => {
      search.delete('filters.contentReleaseDateMonth');
      item?.tags?.forEach((a) => {
        search.append('filters.contentReleaseDateMonth', a.value);
      });
      return search;
    }, */
    options: monthOptions,
    filterName: 'contentReleaseDateMonth',
  },
  {
    initialState: {
      assigned: true,
      notAssigned: true,
    },
    func: ({ initialState, filters }) => {
      const init = { ...initialState };
      if (filters?.paGroupId) {
        Object.keys(initialState).forEach((key) => {
          init[key] = filters?.paGroupId.includes(key);
        });
      }
      return init;
    },
    filterName: 'paGroupId',
  },
  {
    initialState: { tags: [] },
    func: func.getFromUrlTagsfunction, /* ({ filters }) => {
      let items = (Array.isArray(filters?.cpmPriceApprovals) ? filters?.cpmPriceApprovals : [filters?.cpmPriceApprovals]);
      items = filters?.cpmPriceApprovals ? items : [];
      const tags = items?.map((tag) => {
        let result = { id: tag, name: tag };
        cpmPriceApprovalsSuggestions?.forEach((t) => {
          if (tag === t.value) {
            result = { name: t.label, id: t.value };
          }
        });
        return result;
      }) || [];
      return { tags };
    }, */
    filterName: 'cpmPriceApprovals',
    funcToUrl: func.getToUrlTagsfunction,
    /* ({ search, item }) => {
      search.delete('filters.cpmPriceApprovals');
      item?.tags.forEach((a) => {
        search.append('filters.cpmPriceApprovals', a.id);
      });
      return search;
    }, */
    options: cpmPriceApprovalsSuggestions || [],
  },
  {
    initialState: { tags: [] },
    func: func.getFromUrlSelectfunction,
    /* ({ filters, initialState }) => {
      let items = (Array.isArray(filters?.dealStages) ? filters?.dealStages : [filters?.dealStages]);
      items = filters?.dealStages ? items : [];
      const tags = items?.map((tag) => {
        let result = { value: tag, label: tag };
        dealstages?.forEach((t) => {
          if (tag === t.value) {
            result = { label: t.label, value: t.value };
          }
        });
        return result;
      }) || [];
      return { tags: (tags.length > 0 ? tags : initialState.tags) };
    }, */
    filterName: 'dealStages',
    funcToUrl: func.getToUrlSelectfunction,
    /*
    ({ search, item }) => {
      search.delete('filters.dealStages');
      item?.tags.forEach((a) => {
        search.append('filters.dealStages', a.value);
      });
      return search;
    }, */
    options: dealstages,
  },
  {
    initialState: { tags: [] },
    func: func.getFromUrlTagsfunction,
    funcToUrl: func.getToUrlTagsfunction,
    options: countryOptions,
    filterName: 'countryOfResidence',
  },
];
