import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactTags from 'react-tag-autocomplete';
import {
  InputGroup, Input, InputGroupAddon, InputGroupText, UncontrolledButtonDropdown,
  DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Label, FormGroup, Button,
} from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import IntervalDatePickerField from './IntervalDatePickerField';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';
import { setCookie, getCookie } from '../../../../../../../shared/helpers/WVUtilities';

const AdminUsers = ({
  prospectOwnerSuggestions, prospectOwners, setProspectOwners, setTagsProspectOwnersFilter,
}) => {
  const reactTagsProspectOwners = React.createRef();
  const setFiltersProspectOwner = (prospectOwnersNew) => {
    const prospectOwnersFilterNew = {};
    if (prospectOwnersNew?.length > 0) {
      prospectOwnersNew.map((item) => {
        prospectOwnersFilterNew[item.id] = true;
        return true;
      });
    }
    setTagsProspectOwnersFilter(prospectOwnersFilterNew);
  };

  const onDeleteProspectOwner = (i) => {
    const prospectOwnersNew = prospectOwners.slice(0);
    prospectOwnersNew.splice(i, 1);
    setFiltersProspectOwner(prospectOwnersNew);
    setProspectOwners(prospectOwnersNew);
  };

  const onAdditionProspectOwner = (prospectOwner) => {
    const prospectOwnersNew = [].concat(prospectOwners, prospectOwner);
    setFiltersProspectOwner(prospectOwnersNew);
    setProspectOwners(prospectOwnersNew);
  };

  return (<ReactTags
    className="mr-2"
    ref={reactTagsProspectOwners}
    tags={prospectOwners}
    suggestions={prospectOwnerSuggestions}
    onDelete={onDeleteProspectOwner}
    onAddition={onAdditionProspectOwner}
    delimiters={['Enter', ',']}
    placeholderText="Submited By name(s)"
  />);
};
AdminUsers.propTypes = {

  setTagsProspectOwnersFilter: PropTypes.func,
  setProspectOwners: PropTypes.func,
  prospectOwners: PropTypes.arrayOf(PropTypes.any),
  prospectOwnerSuggestions: PropTypes.arrayOf(PropTypes.any),
};

AdminUsers.defaultProps = {
  setTagsProspectOwnersFilter: () => {},
  setProspectOwners: () => {},
  prospectOwners: [],
  prospectOwnerSuggestions: [],
};

const Actions = React.memo(({
  ilStatusFilters,
  setILStatusFilters,
  brandFilters,
  setBrandFilters,
  matchFilters,
  setMatchFilters,
  onSearchChange,
  tagsBrandsSuggestions,
  tagsSuggestions,

  setTagsFilter,
  setTagsBrandsFilter,

  createDateFrom,
  setCreateDateFrom,
  createDateTo,
  setCreateDateTo,

  setTags,
  tags,

  tagsBrands,
  setTagsBrands,

  prospectOwnerSuggestions,
  setTagsProspectOwnersFilter,
  setProspectOwners,
  prospectOwners,

  getUrlSearch,
  searchValue,

  isInternalFilters,
  setIsInternalFilters,

  setTagsAdminUserFilter,
  adminUsers,
  setAdminUsers,

  isResubmittedFilters,
  setIsResubmittedFilters,

  resubmitDateFrom,
  setResubmitDateFrom,
  resubmitDateTo,
  setResubmitDateTo,
  // selectedProspectIds,
  setRemoveProspectIds,

  categoriesSuggestions,
  setTagsCategoriesFilter,
  tagsCategories,
  setTagsCategories,

  countrySuggestions,
  setTagsCountriesFilter,
  tagsCountries,
  setTagsCountries,
}) => {
  console.log(ilStatusFilters, 'ilStatusFilters');
  const [search, setSearch] = useState(searchValue);
  const [showFilters, setShowFilters] = useState(getCookie('researchInternalProspectsShowFilters') === 'true');
  const [dateSearch, setDateSearch] = useState(null);

  const reactTagsTags = React.createRef();
  const reactTagsBrands = React.createRef();
  const reactTagsProspectOwners = React.createRef();
  const reactTagsCategories = React.createRef();
  const reactTagsCountries = React.createRef();

  useEffect(() => {
    if (dateSearch) {
      clearTimeout(dateSearch);
    }
    setDateSearch(setTimeout(() => {
      onSearchChange(search);
    }, 1000));
  }, [search]);

  useEffect(() => {
    setCookie('researchInternalProspectsShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  const setFiltersTag = (tagsNew) => {
    const tagsFilterNew = {};
    if (tagsNew?.length > 0) {
      tagsNew.map((item) => {
        tagsFilterNew[item.name] = true;
        return true;
      });
    }
    setTagsFilter(tagsFilterNew);
  };

  const setFiltersTagBrand = (tagsBrandsNew) => {
    const tagsBrandsFilterNew = {};
    if (tagsBrandsNew?.length > 0) {
      tagsBrandsNew.map((item) => {
        tagsBrandsFilterNew[item.id] = true;
        return true;
      });
    }
    setTagsBrandsFilter(tagsBrandsFilterNew);
  };

  const onDeleteTag = (i) => {
    const tagsNew = tags.slice(0);
    tagsNew.splice(i, 1);
    setFiltersTag(tagsNew);
    setTags(tagsNew);
  };

  const onAdditionTag = (tag) => {
    const tagsNew = [].concat(tags, tag);
    setFiltersTag(tagsNew);
    setTags(tagsNew);
  };

  const onDeleteTagBrand = (i) => {
    const tagsBrandsNew = tagsBrands.slice(0);
    tagsBrandsNew.splice(i, 1);
    setFiltersTagBrand(tagsBrandsNew);
    setTagsBrands(tagsBrandsNew);
  };

  const onAdditionTagBrand = (tag) => {
    const tagsBrandsNew = [].concat(tagsBrands, tag);
    setFiltersTagBrand(tagsBrandsNew);
    setTagsBrands(tagsBrandsNew);
  };

  const setFiltersProspectOwner = (prospectOwnersNew) => {
    const prospectOwnersFilterNew = {};
    if (prospectOwnersNew?.length > 0) {
      prospectOwnersNew.map((item) => {
        prospectOwnersFilterNew[item.id] = true;
        return true;
      });
    }
    setTagsProspectOwnersFilter(prospectOwnersFilterNew);
  };

  const onDeleteProspectOwner = (i) => {
    const prospectOwnersNew = prospectOwners.slice(0);
    prospectOwnersNew.splice(i, 1);
    setFiltersProspectOwner(prospectOwnersNew);
    setProspectOwners(prospectOwnersNew);
  };

  const onAdditionProspectOwner = (prospectOwner) => {
    const prospectOwnersNew = [].concat(prospectOwners, prospectOwner);
    setFiltersProspectOwner(prospectOwnersNew);
    setProspectOwners(prospectOwnersNew);
  };

  const setFiltersCategory = (categoriesNew) => {
    const categoriesFilterNew = {};
    if (categoriesNew.length > 0) {
      categoriesNew.map((item) => {
        categoriesFilterNew[item.id] = true;
        return true;
      });
    }
    setTagsCategoriesFilter(categoriesFilterNew);
  };

  const onDeleteCategory = (i) => {
    const categoriesNew = tagsCategories.slice(0);
    categoriesNew.splice(i, 1);
    setFiltersCategory(categoriesNew);
    setTagsCategories(categoriesNew);
  };

  const onAdditionCategory = (category) => {
    const categoriesNew = [].concat(tagsCategories, category);
    setFiltersCategory(categoriesNew);
    setTagsCategories(categoriesNew);
  };

  const setFiltersCountry = (countriesNew) => {
    const countriesFilterNew = {};
    if (countriesNew.length > 0) {
      countriesNew.map((item) => {
        countriesFilterNew[item.id] = true;
        return true;
      });
    }
    setTagsCountriesFilter(countriesFilterNew);
  };
  const onDeleteCountry = (i) => {
    const countriesNew = tagsCountries.slice(0);
    countriesNew.splice(i, 1);
    setFiltersCountry(countriesNew);
    setTagsCountries(countriesNew);
  };

  const onAdditionCountry = (country) => {
    const countriesNew = [].concat(tagsCountries, country);
    setFiltersCountry(countriesNew);
    setTagsCountries(countriesNew);
  };

  const handleChangeILFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setILStatusFilters({
        ...ilStatusFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeIsInternalFilters = (event, name) => {
    console.log(isInternalFilters, event.target?.checked);
    if (event && event.target) {
      console.log(isInternalFilters, event.target?.checked);
      const { checked } = event.target;
      console.log({
        ...isInternalFilters,
        [name]: checked,
      });
      console.log(setIsInternalFilters);
      setIsInternalFilters(a => ({
        ...a,
        [name]: checked,
      }));
    }
  };
  const handleChangeIsResubmittedFilters = (event, name) => {
    console.log(isResubmittedFilters, event.target?.checked);
    if (event && event.target) {
      const { checked } = event.target;
      console.log({
        ...isResubmittedFilters,
        [name]: checked,
      });
      console.log(setIsResubmittedFilters);
      setIsResubmittedFilters(a => ({
        ...a,
        [name]: checked,
      }));
    }
  };

  const handleChangeBrandFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setBrandFilters({
        ...brandFilters,
        [name]: checked,
      });
    }
  };

  const handleChangeMatchFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setMatchFilters({
        ...matchFilters,
        [name]: checked,
      });
    }
  };

  const resetFilters = () => {
    setSearch('');
    onSearchChange('');
    setILStatusFilters({
      Approved: true,
      Hold: true,
      'In Review': true,
      Mining: true,
      Rejected: true,
      Resubmit: true,
    });
    setBrandFilters({
      Approved: true,
      'In Review': true,
      Hold: true,
      Rejected: true,
      'In Appeals': true,
    });
    setMatchFilters({
      Match: true,
      'Possible Match': true,
      'No Match': true,
    });
    setTags([]);
    setTagsFilter({});
    setTagsBrands([]);
    setTagsBrandsFilter({});
    setTagsProspectOwnersFilter({});
    setCreateDateFrom(null);
    setCreateDateTo(null);
    setResubmitDateFrom(null);
    setResubmitDateTo(null);
    setIsResubmittedFilters({
      Yes: true,
      No: true,
    });
    setRemoveProspectIds(true);
    setTagsCategories([]);
    setTagsCategoriesFilter({});
  };

  return (
    <div className="prospects-actions">
      <Row className="mt-3">
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search First Name, Last Name, Email, YouTube Channel"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm={1} style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() => window.open(`/resources/prospects/download-csv?${getUrlSearch().toString()}`, '_blank').focus()}
              >
                Download CSV
              </DropdownItem>
              <DropdownItem
                onClick={() => window.open('/research/add-internal-prospect-request', '_blank').focus()}
              >
                Add Prospect Request
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
      </Row>
      {showFilters && (
        <>
          <Row>
            <Col sm={4}>
              <Label className="bold-text">
                Prospect Owner(s)
              </Label>
              <ReactTags
                className="mr-2"
                ref={reactTagsProspectOwners}
                tags={prospectOwners}
                suggestions={prospectOwnerSuggestions}
                onDelete={onDeleteProspectOwner}
                onAddition={onAdditionProspectOwner}
                delimiters={['Enter', ',']}
                placeholderText="Prospect Owner name(s)"
              />
            </Col>
            <Col sm={4}>
              <Label className="bold-text">
                Submited By
              </Label>
              <AdminUsers
                prospectOwnerSuggestions={prospectOwnerSuggestions}
                setTagsProspectOwnersFilter={setTagsAdminUserFilter}
                prospectOwners={adminUsers}
                setProspectOwners={setAdminUsers}
              />
            </Col>
            <Col sm={4}>
              <Label className="bold-text">
                Brand(s)
              </Label>
              <ReactTags
                ref={reactTagsBrands}
                tags={tagsBrands}
                suggestions={tagsBrandsSuggestions}
                onDelete={onDeleteTagBrand}
                onAddition={onAdditionTagBrand}
                delimiters={['Enter', ',']}
                placeholderText="Brand name(s)"
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col sm={4}>
              <Label className="bold-text">
                Tag(s)
              </Label>
              <ReactTags
                className="mr-2"
                ref={reactTagsTags}
                tags={tags}
                suggestions={tagsSuggestions}
                onDelete={onDeleteTag}
                onAddition={onAdditionTag}
                delimiters={['Enter', ',']}
                placeholderText="Tag name(s)"
              />
            </Col>
            <Col sm={4}>
              <Label className="bold-text">
                Categories
              </Label>
              <ReactTags
                className="mr-2"
                ref={reactTagsCategories}
                tags={tagsCategories}
                suggestions={categoriesSuggestions}
                onDelete={onDeleteCategory}
                onAddition={onAdditionCategory}
                delimiters={['Enter', ',']}
                placeholderText="Categories"
              />
            </Col>
            <Col sm={4}>
              <Label className="bold-text">
                Country of Residence
              </Label>
              <ReactTags
                className="mr-2"
                ref={reactTagsCountries}
                tags={tagsCountries}
                suggestions={countrySuggestions}
                onDelete={onDeleteCountry}
                onAddition={onAdditionCountry}
                delimiters={['Enter', ',']}
                placeholderText="Country of Residence"
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col sm={2}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                InfluenceLogic Status
              </Label>
              <>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="ApprovedIL"
                    label="Approved"
                    value={ilStatusFilters.Approved}
                    onChange={event => handleChangeILFilters(event, 'Approved')}
                  />
                </FormGroup>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="HoldingIL"
                    label="Hold"
                    value={ilStatusFilters.Hold}
                    onChange={event => handleChangeILFilters(event, 'Hold')}
                  />
                </FormGroup>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="InReviewIL"
                    label="In Review"
                    value={ilStatusFilters['In Review']}
                    onChange={event => handleChangeILFilters(event, 'In Review')}
                  />
                </FormGroup>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="MiningIL"
                    label="Mining"
                    value={ilStatusFilters.Mining}
                    onChange={event => handleChangeILFilters(event, 'Mining')}
                  />
                </FormGroup>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="RejectedIL"
                    label="Rejected"
                    value={ilStatusFilters.Rejected}
                    onChange={event => handleChangeILFilters(event, 'Rejected')}
                  />
                </FormGroup>
                <FormGroup
                  style={{ marginBottom: '10px' }}
                >
                  <CheckBoxField
                    name="ResubmitIL"
                    label="Resubmit"
                    value={ilStatusFilters.Resubmit}
                    onChange={event => handleChangeILFilters(event, 'Resubmit')}
                  />
                </FormGroup>
              </>
            </Col>
            <Col sm={2} id="brandFiltersGroup">
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Brand Status
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="ApprovedBrand"
                  label="Approved"
                  value={brandFilters.Approved}
                  onChange={event => handleChangeBrandFilters(event, 'Approved')}
                />
              </FormGroup>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="InReviewBrand"
                  label="In Review"
                  value={brandFilters['In Review']}
                  onChange={event => handleChangeBrandFilters(event, 'In Review')}
                />
              </FormGroup>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="HoldBrand"
                  label="Hold"
                  value={brandFilters.Hold}
                  onChange={event => handleChangeBrandFilters(event, 'Hold')}
                />
              </FormGroup>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="RejectedBrand"
                  label="Rejected"
                  value={brandFilters.Rejected}
                  onChange={event => handleChangeBrandFilters(event, 'Rejected')}
                />
              </FormGroup>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="InAppealsBrand"
                  label="In Appeals"
                  value={brandFilters['In Appeals']}
                  onChange={event => handleChangeBrandFilters(event, 'In Appeals')}
                />
              </FormGroup>
            </Col>
            <Col sm={2}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                InfluenceLogic Contact
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="Match"
                  label="Match"
                  value={matchFilters.Match}
                  onChange={event => handleChangeMatchFilters(event, 'Match')}
                />
              </FormGroup>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="PossibleMatch"
                  label="Possible Match"
                  value={matchFilters['Possible Match']}
                  onChange={event => handleChangeMatchFilters(event, 'Possible Match')}
                />
              </FormGroup>
              <FormGroup>
                <CheckBoxField
                  name="NoMatch"
                  label="No Match"
                  value={matchFilters['No Match']}
                  onChange={event => handleChangeMatchFilters(event, 'No Match')}
                />
              </FormGroup>
            </Col>
            <Col sm={1}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Prospect Type
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="Standard"
                  label="Standard"
                  value={isInternalFilters.Standard}
                  onChange={event => handleChangeIsInternalFilters(event, 'Standard')}
                />
              </FormGroup>
              <FormGroup>
                <CheckBoxField
                  name="Internal"
                  label="Internal"
                  value={isInternalFilters.Internal}
                  onChange={event => handleChangeIsInternalFilters(event, 'Internal')}
                />
              </FormGroup>
            </Col>
            <Col sm={1}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Resubmitted?
              </Label>
              <FormGroup
                style={{ marginBottom: '10px' }}
              >
                <CheckBoxField
                  name="Resubmitted"
                  label="Yes"
                  value={isResubmittedFilters.Yes}
                  onChange={event => handleChangeIsResubmittedFilters(event, 'Yes')}
                />
              </FormGroup>
              <FormGroup>
                <CheckBoxField
                  name="NotResubmitted"
                  label="No"
                  value={isResubmittedFilters.No}
                  onChange={event => handleChangeIsResubmittedFilters(event, 'No')}
                />
              </FormGroup>
            </Col>
            <Col sm={2}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Create Date (ET)
              </Label>
              <IntervalDatePickerField
                verticalFields
                onChange={({ start, end }) => {
                  setCreateDateFrom(start);
                  end?.setHours(23, 59, 59);
                  setCreateDateTo(end);
                }}
                startDate={createDateFrom}
                endDate={createDateTo}
                maxDate={new Date()}
                placeholderFrom="Add Start Date"
                placeholderTo="Add End Date"
              />
            </Col>
            <Col sm={2}>
              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                Submit Date (ET)
              </Label>
              <IntervalDatePickerField
                verticalFields
                onChange={({ start, end }) => {
                  setResubmitDateFrom(start);
                  end?.setHours(23, 59, 59);
                  setResubmitDateTo(end);
                }}
                startDate={resubmitDateFrom}
                endDate={resubmitDateTo}
                maxDate={new Date()}
                placeholderFrom="Add Start Date"
                placeholderTo="Add End Date"
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}, (a, a1) =>
  a.searchValue === a1.searchValue &&
  _.isEqual(a.ilStatusFilters, a1.ilStatusFilters) &&
  _.isEqual(a.brandFilters, a1.brandFilters) &&
  _.isEqual(a.matchFilters, a1.matchFilters) &&
  // _.isEqual(a.selectedProspectIds, a1.selectedProspectIds) &&
  _.isEqual(a.tags, a1.tags) &&
  _.isEqual(a.tagsBrands, a1.tagsBrands) &&
  _.isEqual(a.tagsBrandsSuggestions, a1.tagsBrandsSuggestions) &&
  _.isEqual(a.tagsSuggestions, a1.tagsSuggestions) &&
  _.isEqual(a.prospectOwners, a1.prospectOwners) &&
  _.isEqual(a.adminUsers, a1.adminUsers) &&
  _.isEqual(a.createDateTo, a1.createDateTo) &&
  _.isEqual(a.createDateFrom, a1.createDateFrom) &&
  _.isEqual(a.resubmitDateFrom, a1.resubmitDateFrom) &&
  _.isEqual(a.resubmitDateTo, a1.resubmitDateTo) &&
  _.isEqual(a.isInternalFilters, a1.isInternalFilters) &&
  _.isEqual(a.isResubmittedFilters, a1.isResubmittedFilters) &&
  _.isEqual(a.prospectOwnerSuggestions, a1.prospectOwnerSuggestions) &&
  _.isEqual(a.categoriesSuggestions, a1.categoriesSuggestions) &&
  _.isEqual(a.tagsCategories, a1.tagsCategories) &&
  _.isEqual(a.countrySuggestions, a1.countrySuggestions) &&
  _.isEqual(a.tagsCountries, a1.tagsCountries));

Actions.propTypes = {
  ilStatusFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setILStatusFilters: PropTypes.func.isRequired,
  brandFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setBrandFilters: PropTypes.func.isRequired,
  matchFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setMatchFilters: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  getUrlSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,

  categoriesSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  setTagsCategoriesFilter: PropTypes.func.isRequired,
  tagsCategories: PropTypes.arrayOf(PropTypes.any).isRequired,
  setTagsCategories: PropTypes.func.isRequired,

  tagsBrandsSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  tagsSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,

  setTagsFilter: PropTypes.func.isRequired,
  setTagsBrandsFilter: PropTypes.func.isRequired,

  setTags: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.any).isRequired,

  tagsBrands: PropTypes.arrayOf(PropTypes.any).isRequired,
  setTagsBrands: PropTypes.func.isRequired,

  // selectedProspectIds: PropTypes.arrayOf(PropTypes.any).isRequired,

  setTagsProspectOwnersFilter: PropTypes.func,
  setProspectOwners: PropTypes.func,
  prospectOwners: PropTypes.arrayOf(PropTypes.any),
  prospectOwnerSuggestions: PropTypes.arrayOf(PropTypes.any),

  setTagsAdminUserFilter: PropTypes.func,
  adminUsers: PropTypes.arrayOf(PropTypes.any),
  setAdminUsers: PropTypes.func,

  createDateFrom: PropTypes.objectOf(PropTypes.any),
  createDateTo: PropTypes.objectOf(PropTypes.any),
  setCreateDateFrom: PropTypes.func.isRequired,
  setCreateDateTo: PropTypes.func.isRequired,

  resubmitDateFrom: PropTypes.objectOf(PropTypes.any),
  resubmitDateTo: PropTypes.objectOf(PropTypes.any),
  setResubmitDateFrom: PropTypes.func.isRequired,
  setResubmitDateTo: PropTypes.func.isRequired,

  isInternalFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setIsInternalFilters: PropTypes.func.isRequired,

  isResubmittedFilters: PropTypes.objectOf(PropTypes.any).isRequired,
  setIsResubmittedFilters: PropTypes.func.isRequired,

  setRemoveProspectIds: PropTypes.func.isRequired,

  countrySuggestions: PropTypes.arrayOf(PropTypes.any),
  setTagsCountriesFilter: PropTypes.func.isRequired,
  tagsCountries: PropTypes.arrayOf(PropTypes.any).isRequired,
  setTagsCountries: PropTypes.func.isRequired,
};

Actions.defaultProps = {
  createDateFrom: null,
  createDateTo: null,
  resubmitDateFrom: null,
  resubmitDateTo: null,
  setTagsProspectOwnersFilter: () => {},
  setProspectOwners: () => {},
  prospectOwners: [],
  prospectOwnerSuggestions: [],
  setTagsAdminUserFilter: () => {},
  adminUsers: [],
  setAdminUsers: () => {},
  countrySuggestions: [],
};

const mapStateToProps = state => ({
  currentAdminUser: state.session,
});

export default connect(mapStateToProps)(Actions);
