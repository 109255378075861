import { unflatten } from 'flat';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

const getStatus = (value) => {
  let status;
  switch (value) {
    case 'verified':
      status = { label: 'Verified', color: 'success'};
      break;
    case 'rejected':
      status = { label: 'Rejected', color: 'danger'};
      break;
    case 'undetermined':
      status = { label: 'Undetermined', color: 'secondary'};
      break;
    default:
      status = { label: 'Not Assigned', color: 'warning'};
  }
  return status;
};

const Status = ({ record }) => {
  const key = 'status';

  const recordUnflatten = unflatten(record);
  const rowData = recordUnflatten.params;
  const val = (typeof rowData !== 'undefined' && typeof rowData[key] !== 'undefined') ? rowData[key] : '';
  const [status, setStatus] = useState(getStatus(val));

  useEffect(() => {
    setStatus(getStatus(val));
  }, [rowData._id]);

  return (
    <Badge
      pill
      color={status.color}
      style={{ width: '120px' }}
    >
      {status.label}
    </Badge>
  );
};

Status.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default Status;
