import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
  Label,
  FormGroup,
} from 'reactstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
// import { axios } from 'ApiClient';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import { getCookie, setCookie } from '@/shared/helpers/WVUtilities';
import { clearFilterProperty } from '@/redux/reducers/TableReducerDefault';
import Hook from '@/shared/hooks';

const Actions = ({ downloadCsv }) => {
  const [showFilters, setShowFilters] = useState(getCookie('oppShowFilters') === 'true');
  const dispatch = useDispatch();
 const reload = Hook.useReloadTableHook();
  const [search, setSearch] = Hook.useTableFilterHookString('search');
  useEffect(() => {
    setCookie('oppShowFilters', showFilters.toString(), 35);
  }, [showFilters]);
  const resetFilters = () => {
    dispatch(clearFilterProperty(['status', 'search']));
  };
  const selectedRows = useSelector((state) => state.tableRedux.selectedRecordsIdsMultiselect);
  const [showModal] = Hook.useShowModalHook('sendEmailModal')
  const [showModalProcess] = Hook.useShowModalHook('sendEmailModalProcess')
  const [showUpdateDealTermsModal] = Hook.useShowModalHook('updateDealTermsModal')
  const [dealStageFilters, handleChangeDealStageFilters, dealstagesOptions] = Hook.useTableFilterHookTags('dealStages');
  const [brandFilters, handleChangeBrandFilters, brandOptions] = Hook.useTableFilterHookTags('brand');
  const [dealTypesFilters, handleChangeDealTypesFilters, dealTypesOptions] = Hook.useTableFilterHookTags('dealType');
  const [statusFilters, handleChangeStatusFilters, statusOptions] = Hook.useTableFilterHookTags('status');
  const [tagFilters, handleChangeTagFilters, tagOptions] = Hook.useTableFilterHookTags('tags');
  return (
    <div>
      <Row>
        <Col sm={8}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search Name, Deal Name, Email"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '42px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col sm={2}>
          <Row>
            <Col sm={8} style={{ paddingRight: '0' }}>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
            <Col sm="auto" style={{ paddingLeft: '24px' }}>
              <Tooltip title="Toggle Filters">
                <IconButton
                  id="filtersMenuButton"
                  size="small"
                  onClick={() => setShowFilters(!showFilters)}
                  className="material-table__toolbar-button"
                  style={{
                    height: '42px',
                  }}
                >
                  <TuneIcon size="30" />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col sm="auto" className="text-right">
          <UncontrolledButtonDropdown className="w-100">
            <DropdownToggle
              id="actionsMenu"
              caret
              color="primary"
              style={{
                padding: '4px 25px',
                height: '42px',
              }}
            >
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                disabled={selectedRows.length === 0}
                onClick={() => showModal({ selectedRows })}
              >
                Send Opportunity Emails
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                disabled={selectedRows.length === 0}
                onClick={() => showUpdateDealTermsModal({ selectedRows })}
              >
                Update Deal Terms
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => downloadCsv()}
              >
                Download CSV
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => showModalProcess()}
              >
                Show Send Emails Process
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </Col>
        <Col sm="auto" className="text-right">
          <Tooltip title="Refresh Table">
            <Button
              color='primary'
              size='lg'
              onClick={() => reload()}
            >
              <i className="fa fa-sync" aria-hidden="true" />
            </Button>
          </Tooltip>
        </Col>
      </Row>
      {showFilters && (
        <>
          <Row className="mt-3">
            <Col>
              <Label className="bold-text">
                Brand(s)
              </Label>
              <FormGroup>
                <Select
                  value={brandFilters}
                  options={brandOptions}
                  onChange={e => handleChangeBrandFilters(e || [])}
                  className="dateOptions"
                  placeholder="Brand"
                  menuPlacement="auto"
                  menuPortalTarget={document.body}
                  isMulti
                  styles={{
                    control: baseStyles => ({
                      ...baseStyles,
                      minHeight: '45px',
                    }),
                  }}
                />
              </FormGroup>
            </Col>
            <Col>
              <Label className="bold-text">
                Deal Type(s)
              </Label>
              <FormGroup>
                <Select
                  value={dealTypesFilters}
                  options={dealTypesOptions}
                  onChange={e => handleChangeDealTypesFilters(e || [])}
                  className="dateOptions"
                  placeholder="Deal Types"
                  menuPlacement="auto"
                  menuPortalTarget={document.body}
                  isMulti
                  styles={{
                    control: baseStyles => ({
                      ...baseStyles,
                      minHeight: '45px',
                    }),
                  }}
                />
              </FormGroup>

            </Col>
            <Col>
              <Label className="bold-text">
                Deal Stage(s)
              </Label>
              <FormGroup>
                <Select
                  value={dealStageFilters}
                  options={dealstagesOptions}
                  onChange={e => handleChangeDealStageFilters(e || [])}
                  className="dateOptions"
                  placeholder="Deal Stage"
                  menuPlacement="auto"
                  menuPortalTarget={document.body}
                  isMulti
                  styles={{
                    control: baseStyles => ({
                      ...baseStyles,
                      minHeight: '45px',
                    }),
                  }}
                />
              </FormGroup>
            </Col>
            <Col>
              <Label className="bold-text">
                Opportunity Status(es)
              </Label>
              <FormGroup>
                <Select
                  value={statusFilters}
                  options={statusOptions}
                  onChange={e => handleChangeStatusFilters(e || [])}
                  className="dateOptions"
                  placeholder="Status"
                  menuPlacement="auto"
                  menuPortalTarget={document.body}
                  isMulti
                  styles={{
                    control: baseStyles => ({
                      ...baseStyles,
                      minHeight: '45px',
                    }),
                  }}
                />
              </FormGroup>
            </Col>
            <Col>
              <Label className="bold-text">
                Tag(s)
              </Label>
              <FormGroup>
                <Select
                  value={tagFilters}
                  options={tagOptions}
                  onChange={e => handleChangeTagFilters(e || [])}
                  className="dateOptions"
                  placeholder="Tag"
                  menuPlacement="auto"
                  menuPortalTarget={document.body}
                  isMulti
                  styles={{
                    control: baseStyles => ({
                      ...baseStyles,
                      minHeight: '45px',
                    }),
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

Actions.propTypes = {
  downloadCsv: PropTypes.func.isRequired,
};

export default Actions;
