import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { axios } from 'ApiClient';
import moment from 'moment';
import CheckBoxField from '../../../../../shared/components/CheckBox';
import { currentTimezoneAggr } from '../../../../../shared/helpers/Dates';
import AccessComponent from '../../../../../shared/components/auth/AccessComponent';

const OnboardingStatus = ({ influencer }) => {
  const [state, setState] = useState({
    step1Status: false,
    step2Status: false,
    step3Status: false,

    stepPaymentStatus: false,
    stepTaxStatus: false,
    stepSAStatus: false,
    stepConnectStatus: false,

    step1DateCompletedLabel: 'Incomplete',
    step2DateCompletedLabel: 'Incomplete',
    step3DateCompletedLabel: 'Incomplete',

    stepPaymentDateCompletedLabel: 'Incomplete',
    stepTaxDateCompletedLabel: 'Incomplete',
    stepSADateCompletedLabel: 'Incomplete',
    stepConnectDateCompletedLabel: 'Incomplete',
  });

  const dateCompleted = useCallback((value, status) => {
    return value && status === 'complete'
      ? `${moment(value).format('MMM D, YYYY, h:mma')} (${currentTimezoneAggr()})`
      : 'Incomplete';
  }, []);

  useEffect(() => {
    if (influencer) {
      setState({
        step1Status: influencer?.params?.onboarding?.step1Status === 'complete',
        step2Status: influencer?.params?.onboarding?.step2Status === 'complete',
        step3Status: influencer?.params?.onboarding?.step3Status === 'complete',

        stepPaymentStatus: influencer?.params?.onboarding?.stepPaymentStatus === 'complete',
        stepTaxStatus: influencer?.params?.onboarding?.stepTaxStatus === 'complete',
        stepSAStatus: influencer?.params?.onboarding?.stepSAStatus === 'complete',
        stepConnectStatus: influencer?.params?.onboarding?.stepConnectStatus === 'complete',

        step1DateCompletedLabel: dateCompleted(
          influencer?.params?.onboarding?.step1DateCompleted,
          influencer?.params?.onboarding?.step1Status
        ),
        step2DateCompletedLabel: dateCompleted(
          influencer?.params?.onboarding?.step2DateCompleted,
          influencer?.params?.onboarding?.step2Status
        ),
        step3DateCompletedLabel: dateCompleted(
          influencer?.params?.onboarding?.step3DateCompleted,
          influencer?.params?.onboarding?.step3Status
        ),

        stepPaymentDateCompletedLabel: dateCompleted(
          influencer?.params?.onboarding?.stepPaymentDateCompleted,
          influencer?.params?.onboarding?.stepPaymentStatus
        ),
        stepTaxDateCompletedLabel: dateCompleted(
          influencer?.params?.onboarding?.stepTaxDateCompleted,
          influencer?.params?.onboarding?.stepTaxStatus
        ),
        stepSADateCompletedLabel: dateCompleted(
          influencer?.params?.onboarding?.stepSADateCompleted,
          influencer?.params?.onboarding?.stepSAStatus
        ),
        stepConnectDateCompletedLabel: dateCompleted(
          influencer?.params?.onboarding?.stepConnectDateCompleted,
          influencer?.params?.onboarding?.stepConnectStatus
        ),
      });
    }
  }, [influencer, dateCompleted]);

  const onChangeStepStatus = async (event) => {
    if (event?.target) {
      const { checked, name } = event.target;
      console.log(name, name[4])
      const step = name;
      setState((prevState) => ({ ...prevState, [name]: checked }));

      try {
        const response = await axios.post(
          '/api/portal/influencers/change-onboarding-status',
          {
            influencerId: influencer.id,
            step,
            value: checked,
          }
        );
        const { dateCompleted: DC, status } = response.data;
        setState((prevState) => ({
          ...prevState,
          [`step${step}Status`]: status === 'complete',                                                                                                                                                                                                                                                                                                                                                                                                                     
          [`step${step}DateCompletedLabel`]: dateCompleted(
            DC,
            status
          ),
        }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const {
    step1Status,
    step2Status,
    step3Status,
    stepPaymentStatus,
    stepTaxStatus,
    stepSAStatus,
    stepConnectStatus,
    step1DateCompletedLabel,
    step2DateCompletedLabel,
    step3DateCompletedLabel,

    stepPaymentDateCompletedLabel,
    stepTaxDateCompletedLabel,
    stepSADateCompletedLabel,
    stepConnectDateCompletedLabel
  } = state;

  return (
    <div>
      <h4 className="mt-4 mb-1">Onboarding Status</h4>
      <p className="mt-0 mb-3">
        Manage & override onboarding process (if necessary). Check Step to mark
        complete, Uncheck Step to mark incomplete.
      </p>
      <Row style={{ color: '#646777' }}>
        <Col md="4">
          <CheckBoxField
            name="3"
            label="Step1 - Setup Payment Method"
            value={step3Status}
            onChange={onChangeStepStatus}
          />
          <div style={{ marginLeft: '30px', marginTop: '-8px' }}>
            {step3DateCompletedLabel}
          </div>
        </Col>
        <Col md="4">
          <CheckBoxField
            name="1"
            label="Step2 - Sign Agreement"
            value={step1Status}
            onChange={onChangeStepStatus}
          />
          <div style={{ marginLeft: '30px', marginTop: '-8px' }}>
            {step1DateCompletedLabel}
          </div>
        </Col>
        <Col md="4">
          <CheckBoxField
            name="2"
            label="Step3 - Review Brand Resources"
            value={step2Status}
            onChange={onChangeStepStatus}
          />
          <div style={{ marginLeft: '30px', marginTop: '-8px' }}>
            {step2DateCompletedLabel}
          </div>
        </Col>
      </Row>
      <hr />
      <AccessComponent showWhenRole={['owner', 'superAdmin']} showWhenTeam={['management', 'super_powers', 'engineering']}>
        <Row style={{ color: '#646777' }}>
          <Col md="3">
            <CheckBoxField
              name="Payment"
              label="Step 1 - Setup Payment Method"
              value={stepPaymentStatus}
              onChange={onChangeStepStatus}
            />
            <div style={{ marginLeft: '30px', marginTop: '-8px' }}>
              {stepPaymentDateCompletedLabel}
            </div>
          </Col>
          <Col md="3">
            <CheckBoxField
              name="Tax"
              label="Step 2 - Upload Tax Document"
              value={stepTaxStatus}
              onChange={onChangeStepStatus}
            />
            <div style={{ marginLeft: '30px', marginTop: '-8px' }}>
              {stepTaxDateCompletedLabel}
            </div>
          </Col>
          <Col md="3">
            <CheckBoxField
              name="SA"
              label="Step 3 - Sign Service Agreement"
              value={stepSAStatus}
              onChange={onChangeStepStatus}
            />
            <div style={{ marginLeft: '30px', marginTop: '-8px' }}>
              {stepSADateCompletedLabel}
            </div>
          </Col>
          <Col md="3">
            <CheckBoxField
              name="Connect"
              label="Step 4 - Connect to YouTube"
              value={stepConnectStatus}
              onChange={onChangeStepStatus}
            />
            <div style={{ marginLeft: '30px', marginTop: '-8px' }}>
              {stepConnectDateCompletedLabel}
            </div>
          </Col>
        </Row>
      </AccessComponent>
      
    </div>
  );
};

OnboardingStatus.propTypes = {
  influencer: PropTypes.objectOf(PropTypes.any),
};

OnboardingStatus.defaultProps = {
  influencer: null,
};

export default OnboardingStatus;
