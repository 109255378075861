const { axios } = require('ApiClient');
const ProspectHelper = require('./Prospect');

exports.getLogoUrl = async (influencer, prospect) => {
  if (influencer?.params?.logo) {
    return influencer.params.logo;
  }
  const res = prospect ? (await ProspectHelper.getLogoUrl(prospect)) : '/images/user/defaultAvatar.png';
  return res;
};

const loadOptions = async (query, label) => {
  if (query?.length < 2) return [];
  const q = [`query=${query}`];
  if (label) q.push(`label=${label}`);
  const res = await axios.get(`/api/portal/influencer/get-options?${q.join('&')}`);
  return res.data.success ? res.data.data : [];
}

exports.loadInfluencerNameOptions = query => loadOptions(query, 'name');
