import { unflatten } from 'flat';
// import React from 'react';
import PropTypes from 'prop-types';

const AdminUserInList = ({ record, type }) => {
  const recordUnflatten = unflatten(record);
  const { submitterAdminUserId, accountManagerId } = recordUnflatten.populated;
  let val;
  if (type === 'submitter') {
    if (submitterAdminUserId && (submitterAdminUserId.params?.firstName || submitterAdminUserId.params?.lastName)) {
      val = `${submitterAdminUserId.params.firstName} ${submitterAdminUserId.params.lastName}`;
    } else {
      val = submitterAdminUserId?.params ? submitterAdminUserId.params.email : '';
    }
    return val;
  } else {
    if (accountManagerId && (accountManagerId.params?.firstName || accountManagerId.params?.lastName)) {
      val = `${accountManagerId.params.firstName} ${accountManagerId.params.lastName}`;
    } else {
      val = accountManagerId?.params ? accountManagerId.params.email : '';
    }
    return val;
  }
};
AdminUserInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.string,
};

AdminUserInList.defaultProps = {
  type: 'submitter',
};

export default AdminUserInList;

