import React from 'react';
import PropTypes from 'prop-types';
import { unflatten } from 'flat';
import { Link } from 'react-router-dom';

const FullName = ({ record }) => {
  const {
    _id,
    fullName,
    hsContactVid,
    mediaChannels,
    lastContentUrl,
  } = unflatten(record.params);
  return record.isTotal ? fullName : (
    <>
      <div>
        <Link
          to={`/influencer/profile/${_id}`}
          rel="noreferrer"
          target="_blank"
        >
          {fullName}
        </Link>
        {hsContactVid && (
          <a
            href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${hsContactVid}`}
            title="Show HubSpot Contact"
            target="_blank"
            rel="noopener noreferrer"
            className="ml-2 color-text"
          >
            <i className="fab fa-hubspot" />
          </a>
        )}
      </div>
      {mediaChannels?.youtube_channel_url && (
        <a
          href={mediaChannels.youtube_channel_url}
          title="Creator Channel"
          target="_blank"
          rel="noopener noreferrer"
          className="ml-2 color-text"
        >
          <i className="fab fa-youtube" />
        </a>
      )}
      {lastContentUrl && (
        <a
          href={lastContentUrl}
          title="Most Recent Published Content"
          target="_blank"
          rel="noopener noreferrer"
          className="ml-2 color-text"
        >
          <i className="fas fa-video" />
        </a>
      )}
    </>
  );
};

FullName.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default FullName;
