import React, { useMemo } from 'react';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ContextMenuInList from './ContextMenuInList';
import CellSelect from '../../../../../../shared/components/table/CellSelect';
import TitleInList from './TitleInList';
import VideoDescription from './VideoDescription';
import mapMediaTypeIcons from './mapMediaTypeIcons';

const mapType = mapMediaTypeIcons();

export default function TableColumns(
  setDisableNote,
  setShowNotesModal,
  setCurrentMediaContent,
  initialReviewStatusFilters,
  setVersion = () => {},
) {
  console.log('Icon test');
  return [
    {
      Header: 'Video Details',
      accessor: p => (p.params?.title),
      id: 'title',
      Cell: record => (
        <div style={{ paddingRight: '10px' }}>
          <TitleInList
            record={record.row.original}
          />
        </div>
      ),
      disableSortBy: false,
      className: 'align-middle',
      width: 150,
    },
    {
      Header: 'Creator',
      accessor: p => (p.params?.creatorFullName),
      id: 'creatorFullName',
      Cell: record => (
        <div style={{ paddingRight: '10px' }}>
          <a
            target="_blank"
            rel="noreferrer"
            href={`/influencer/profile/${record.row.original.params.creatorId}`}
          >
            {record.row.original.params.creatorFullName}
          </a>
        </div>
      ),
      disableSortBy: false,
      className: 'align-middle',
      width: 150,
    },
    {
      Header: 'Brand',
      accessor: p => (p.params?.brand),
      id: 'brand',
      width: 120,
      Cell: p => (p.row.original.params?.brand || ''),
      disableSortBy: false,
      className: 'align-middle',
    },
    {
      Header: 'Review Status',
      id: 'reviewStatus',
      Cell: p => useMemo(() => (
        <div style={{ width: 200 }}>
          <CellSelect
            name="contentReviewStatus.currentStatus"
            label="Review Status"
            record={p.row.original}
            values={Object.keys(initialReviewStatusFilters)}
            update="/api/portal/scorecard/media-content/update-review-status"
            showNotes={() => { setDisableNote(false); setCurrentMediaContent(p.row.original); setShowNotesModal(true); }}
            disableNotAssigned
            customNoteField="contentReviewStatus.notes"
            forceShowNotesCondition="Pending Edits"
            afterSaveFunc={() => setVersion((new Date()).toString())}
          />
        </div>
      ), [p.row.original]),
      className: 'align-middle text-center',
      disableSortBy: true,
      width: 200,
    },
    {
      Header: 'Type',
      accessor: p => (p.params?.type),
      id: 'typeInList',
      width: 60,
      Cell: value => (
        <div>
          <a
            href={(value.row.original.params?.mediaUrl?.includes('https://') || value.row.original.params?.mediaUrl?.includes('http://')) ? value.row.original.params.mediaUrl : `https://${value.row.original.params?.mediaUrl}`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              style={{ width: '30px' }}
              src={`/images/mediacontent/${mapType[value.row.original?.params?.type]}.png`}
              alt={value.row.original.params.type}
              title={value.row.original.params.type}
            />
          </a>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: true,
    },
    {
      Header: 'Video Description',
      accessor: () => null,
      id: 'contentText',
      width: 250,
      Cell: record => (
        <div style={{ width: '250px' }}>
          <VideoDescription record={record} />
        </div>
      ),
      // className: 'align-middle',
      disableSortBy: true,
    },
    {
      Header: 'Last Review Date',
      accessor: item => (item?.params['contentReviewStatus.currentStatusTimestamp'] ? item?.params['contentReviewStatus.currentStatusTimestamp'].split('T')[0] : null),
      id: 'contentReviewStatus.currentStatusTimestamp',
      Cell: value => (
        <div style={{ paddingRight: '10px' }}>
          <span>{value.value ? moment(value.value).format('MM/DD/YYYY') : '...'}</span>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: false,
      width: 160,
    },
    {
      Header: () => (
        <span>
          Publish Date
          {'  '}
          <Tooltip title="Publish Date is specific to YouTube content only">
            <IconButton size="small" style={{ marginTop: '-3px' }}>
              <i className="fa fa-info-circle" style={{ fontSize: '0.8em' }} />
            </IconButton>
          </Tooltip>
        </span>
      ),
      accessor: item => (item?.params?.publishDate ? item?.params?.publishDate.split('T')[0] : null), // p => (WVFormatter.getServerDateTime(p.params?.createdAt)),
      id: 'publishDate',
      Cell: value => (
        <div style={{ paddingRight: '10px' }}>
          <span>{value.value ? moment(value.value).format('MM/DD/YYYY') : '-'}</span>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: false,
      width: 100,
    },
    {
      Header: 'Includes Paid Promotion',
      accessor: p => (p.params?.hasPaidProductPlacement),
      id: 'hasPaidProductPlacement',
      width: 100,
      Cell: p => {
        if (p.row.original.params?.hasPaidProductPlacement === true) {
          return 'Yes';
        } else if (p.row.original.params?.hasPaidProductPlacement === false) {
          return 'No';
        } else return 'Not Assigned';
      },
      disableSortBy: false,
      className: 'align-middle',
    },
    {
      Header: 'Brand Approved Date',
      accessor: item => item.params.brandApprovedDate,
      id: 'brandApprovedDate',
      Cell: value => (
        <div style={{ paddingRight: '10px' }}>
          <span>{value.value ? moment(value.value).format('MM/DD/YYYY') : '-'}</span>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: false,
      width: 100,
    },
    {
      Header: 'Deal Type',
      accessor: p => (p.params?.dealType),
      id: 'dealType',
      width: 100,
      Cell: p => (p.row.original.params?.dealType || ''),
      disableSortBy: false,
      className: 'align-middle',
    },
    {
      Header: 'Deal Owner',
      accessor: p => (p.params?.dealOwner),
      id: 'dealOwner',
      width: 100,
      Cell: (p) => {
        const name = `${p.row.original.params['hubilDeal.0.adminUserObject.firstName'] || ''} ${p.row.original.params['hubilDeal.0.adminUserObject.lastName'] || ''}`;
        return name.trim() ? name : '-';
      },
      disableSortBy: true,
      // className: 'align-middle',
    },
    {
      Header: '',
      accessor: () => null,
      id: 'actionsCustom',
      Cell: p => (
        <ContextMenuInList
          record={p.row.original}
        />
      ),
      disableSortBy: true,
      className: 'align-middle',
      width: 50,
    },
  ];
}
