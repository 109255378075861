import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, CardBody, Card, Button,
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import Select from 'react-select';
import {connect} from 'react-redux';
import AccountKeyIcon from 'mdi-react/AccountKeyIcon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { axios } from 'ApiClient';
import WVValidator from '../../../../../shared/helpers/WVValidator';
import Placeholder from '../../../../../shared/components/components/ui/placeholder';
import CopyToClipboard from '../../../../../shared/components/CopyToClipboard';
import DisConnectModal from '../../AgentBillCom/DisConnectModal';
import AgentForm from '../components/custom/AgentForm';
import AdditionalContacts from './AdditionalContacts';
import Tags from './Tags';
import SelectResource from '../../../../../shared/components/table/cell/SelectResource';
import Alert from '../../../../../shared/easydev2/shared/components/Alert';
import BillComCreatorProfile from '../../../Influencer/Influencer/influencerProfile/BillComCreatorProfile';
import StripeCreatorProfile from '../../../Influencer/Influencer/influencerProfile/StripeCreatorProfile';
import AccessComponent from '../../../../../shared/components/auth/AccessComponent';
import fetchResourceData from '../../../../../shared/helpers/WVRequest';
import TaxFormUploadModal, { TaxFormUploadModalId } from '../../../../../shared/components/modals/TaxFormUploadModal';
import Hook from '../../../../../shared/hooks';
import { getTaxStatus } from '../../../../../shared/helpers/TaxHelper';

const taxFormValues = { 1: 'On File', 0: 'Missing' };
const showWhenTeamIs = ['management', 'super_powers', 'finance', 'engineering', 'customer_success'];

const getAgencyStatus = (agency) => {
  const value = agency?.status || null;
  let status;
  switch (value) {
    case 'verified':
      status = { label: 'Verified', background: '#28a745'};
      break;
    case 'rejected':
      status = { label: 'Rejected', background: '#dc3545'};
      break;
    case 'undetermined':
      status = { label: 'Undetermined', background: '#6c757da1'};
      break;
    default:
      status = { label: 'Not Assigned', background: '#6c757da1'};
  }
  return status;
};

const AgentProfile = ({
  agentId, agent, adminUser, employee, loading,
  loadingPayment, editPayment, reloadAgent,
  connectBillCom, inviteBillCom, refreshHubspot, goToDealEditor, creators,
}) => {
  const [modal, setModal] = useState(false);
  const [spyLoginLoading, setSpyLoginLoading] = useState(false);
  const [agentDialogVisible, setAgentDialogVisible] = useState(false);

  const [showModalTaxFormUpload] = Hook.useShowModalHook(TaxFormUploadModalId);

  const [loadingAgencies, setLoadingAgencies] = useState(false);
  const [agencyOptions, setAgencyOptions] = useState([]);
  const [agency, setAgency] = useState(null);
  const [agencyStatus, setAgencyStatus] = useState(null);
  const [taxFormValue, setTaxFormValue] = useState(agent?.params?.validTaxForm?.value ? 1 : 0);
  const [stripeColor, setStripeColor] = useState('text-secondary');

  // let taxFormValue = agent?.params?.validTaxForm?.value ? 1 : 0;
  useEffect(() => {
    if (!taxFormValue && agent) {
      setTaxFormValue(getTaxStatus(agent));
      console.log('taxFormValue', taxFormValue);
      console.log('agent', agent);
    }
    if (agent) {
      if (agent.params?.payment?.stripe?.accountId) {
        setStripeColor('text-success');
      } else if (agent.params?.payment?.stripe?.accountIdTemp) {
        setStripeColor('text-warning')
      }
    }
  }, [agent]);

  useEffect(() => {
    setLoadingAgencies(true);
    fetchResourceData('Agency', {}, 'perPage=5000&sortBy=agencyName&direction=asc').then((res) => {
      setAgencyOptions(res.records.map(r => ({ value: r.id.toString(), label: r.params.agencyName, status: r.params.status })));
      setLoadingAgencies(false);
    });
  }, []);

  useEffect(() => {
    setAgencyStatus(agency ? getAgencyStatus(agency) : null);
  }, [agency]);

  const spyLogin = id => new Promise((resolve) => {
    axios.post('/api/portal/agent/spy-login', { userId: id })
      .then((response) => {
        const { spyLoginToken, baseUrl } = response.data;
        if (spyLoginToken && baseUrl) {
          const win = window.open(`${baseUrl}/login-secret/${spyLoginToken}`, '_blank');
          win.focus();
        }
        resolve();
      }).catch((error) => {
        console.log(error);
      });
  });


  const spyLoginHandler = async () => {
    setSpyLoginLoading(true);
    await spyLogin(agent.id);
    setSpyLoginLoading(false);
  };

  const onPaymentProviderChanged = (option) => {
    editPayment(option.value !== 'stripe' ? 'billCom' : 'stripe');
  };

  const paymentOptions = () => {
    let hasStripe = false;
    let hasBillCom = false;
    if (agent?.params?.payment) {
      hasStripe = agent.params.payment.stripe && !WVValidator.isEmpty(agent.params.payment.stripe.accountId);
      hasBillCom = agent.params.payment.billCom && !WVValidator.isEmpty(agent.params.payment.billCom.accountId);
    }
    return [
      { value: 'stripe', label: `Stripe${!hasStripe ? ' (not connected)' : ''}`, isDisabled: !hasStripe },
      { value: 'billCom', label: `Bill.com${!hasBillCom ? ' (not connected)' : ''}`, isDisabled: !hasBillCom },
    ];
  };

  const paymentDefaultOption = () => {
    let isStripeDefault = false;
    let isBillComDefault = false;
    if (agent?.params?.payment) {
      isStripeDefault = agent.params.payment.stripe && agent.params.payment.stripe.isDefault;
      isBillComDefault = agent.params.payment.billCom && agent.params.payment.billCom.isDefault;
    }
    const options = paymentOptions();
    if (isStripeDefault) {
      return options[0];
    }
    if (isBillComDefault) {
      return options[1];
    }
    return null;
  };

  const closeAgentDialog = (e) => {
    setAgentDialogVisible(false);
    if (!e?.target) reloadAgent();
  };

  const employeeRecord = (!employee || (!(employee.firstName && employee.lastName) && adminUser.firstName && adminUser.lastName)) ? adminUser : employee;
  const employeeName = (employeeRecord && (employeeRecord.firstName || employeeRecord.lastName)) ? `${employeeRecord.firstName} ${employeeRecord.lastName}` : '-';

  return (
    <div>
      <h2 className="section-title mt-0 mb-4">Agent Profile</h2>
      {agent?.params?.inBlackList && (
        <Alert color="warning" className="w-100 mt-2">
          <p className="py-2 text-dark">
            This agent is on the blacklist and some functionality is blocked. <a href="/influencers/contacts" target="_blank" rel="noopener">Manage Blacklist</a>
          </p>
        </Alert>
      )}
      <Card>
        <CardBody>
          <Row>
            <Col>
              <Row>
                <Col xs="auto">
                  <div
                    style={{
                      width: 150,
                      height: 150,
                      borderRadius: 75,
                      backgroundImage: `url(${!loading && agent?.params?.logo ? agent.params.logo : '/images/user/defaultAvatar.png'})`,
                      backgroundPosition: 'center center',
                      backgroundSize: 'cover',
                      marginBottom: '16px',
                    }}
                  />
                </Col>
                <Col>
                  {!loading && agent?.params?.email ?
                  (
                    <div className="w-100 float-left">
                      <h3 className="mt-0 mr-3 mb-2 il-color-deep-blue float-left main-header">
                        {agent.params.first_name} {agent.params.last_name}
                      </h3>
                      {agent.params.hsContactVid && (
                        <a
                          href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${agent.params.hsContactVid}/`}
                          target="_blank"
                          title="Open Hubspot Contact"
                          rel="noreferrer noopener"
                        >
                          <i className="fab fa-hubspot" style={{ fontSize: '1.5em' }} />
                        </a>
                      )}
                    </div>)
                  : (<Placeholder style={{ height: 30, width: '100%' }} />)}
                  <div className="mt-0 float-left">
                    <div>
                      <SelectResource
                        resource="AgentUser"
                        record={agent}
                        property="agency"
                        options={agencyOptions}
                        fetching={loadingAgencies}
                        placeholder="Agency"
                        afterChange={setAgency}
                        className="mb-2"
                      />
                    </div>
                    <h4 className="il-text-gray  company-header">
                      {!loading && agent?.params?.email ? agent.params.companyName : (<Placeholder style={{ height: 15, maxWidth: '200px', marginTop: 3 }} />)}
                    </h4>
                    {agency && agencyStatus && (
                      <Tooltip title={`Agency Status: ${agencyStatus.label}`}>
                        <span
                          style={{
                            display: 'inline-block',
                            lineHeight: '1',
                            textAlign: 'center',
                            padding: '0.25em 0.4em',
                            borderRadius: '0.3em',
                            marginTop: '4px',
                            width: '250px',
                            color: `white`,
                            fontSize: '100% !important',
                            fontWeight: '700',
                            backgroundColor: agencyStatus.background,
                          }}
                        >
                          {agencyStatus.label}
                        </span>
                      </Tooltip>
                    )}
                    <div className="mt-1">{!loading && agent?.params?.email ? (
                      <span className="il-text-gray">
                        <span className="lnr lnr-envelope" />{' '}
                        <CopyToClipboard value={agent.params.email || ''}>
                          <a className="il-text-gray" href={`mailto:${agent.params.email}`}>
                            {agent.params.email}
                          </a>
                        </CopyToClipboard>
                      </span>) : (<Placeholder style={{ height: 15, maxWidth: '200px', marginTop: 3 }} />)}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs="auto">
              <UncontrolledButtonDropdown className="m-0">
                <DropdownToggle caret className="mr-2" color="primary" style={{ padding: '5px 25px' }} disabled={!!loading}>
                  Payments
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={connectBillCom}>
                    <Tooltip title="Connect Bill Com">
                      <>Connect Bill.com</>
                    </Tooltip>
                  </DropdownItem>
                  <DropdownItem onClick={() => setModal(true)}>
                    Disconnect
                  </DropdownItem>
                  {agent.params.email && !agent.params.payment.billCom.accountId ? (
                    <DropdownItem onClick={inviteBillCom}>
                      Send Bill.com Invite
                    </DropdownItem>) : null}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
              <Tooltip title="Spy Login to the Agent Portal (Secure & Safe)">
                <span className="mr-2">
                  <Button
                    color="primary"
                    disabled={!!loading || spyLoginLoading}
                    onClick={spyLoginHandler}
                  >
                    <AccountKeyIcon size="1em" /> Spy Login
                  </Button>
                </span>
              </Tooltip>

              <Tooltip title="Change the Agent Portal Password">
                <span className="mr-2">
                  <Button
                    color="primary"
                    disabled={!!loading}
                    onClick={() => setAgentDialogVisible(true)}
                  >
                    <FontAwesomeIcon icon={['fa', 'lock']} />
                    {' '}
                    Account Settings
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title="Create/Edit Agent Deal">
                <span className="mr-2">
                  <Button
                    color="primary"
                    disabled={!!loading || agent?.params?.inBlackList}
                    onClick={() => goToDealEditor()}
                  >
                    Agent Deal
                  </Button>
                </span>
              </Tooltip>
              {agent.params.hsContactVid && (
                <UncontrolledButtonDropdown className="m-0">
                  <DropdownToggle caret className="mr-2" color="primary" style={{ padding: '5px 25px' }} disabled={!!loading}>
                    <FontAwesomeIcon icon={['fa', 'sync-alt']} />
                    {' '}
                    Refresh
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={refreshHubspot}>
                      <Tooltip title="Synchronize all HubSpot Agent Information">
                        <>HubSpot Data</>
                      </Tooltip>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              )}
            </Col>
          </Row>
          {!loading ? [(
            <Row className="mt-3" key="0">
              <Col md={5}>
                {employeeName && (
                  <Row>
                    <Col>
                      <h5>Account Manager (Employee)</h5>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col md={7}>
                <Row>
                  <Col>
                    <h5>Payment Methods</h5>
                  </Col>
                  <Col>
                    <h5>Tax Form</h5>
                  </Col>
                  <Col>
                    <h5>Default Payment Provider</h5>
                  </Col>
                </Row>
              </Col>
            </Row>),
            (<hr key="1" />),
            (
              <Row key="2">
                <Col md={5}>
                  {employeeName && (
                    <Row>
                      <Col>
                        <strong>
                          {agent.params.email ? employeeName : (<Placeholder style={{ height: 14, width: '100%' }} />)}
                        </strong>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col md={7}>
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          { agent.params.email &&
                            <Tooltip title={!agent.params.payment.stripe.accountId && agent.params.payment.stripe.accountIdTemp ? 'Account onboarding incomplete' : ''}>
                              <div className={`float-left mr-2 mt-1 ${stripeColor}`}>
                                <FontAwesomeIcon icon={['fab', 'cc-stripe']} style={{fontSize: '1.6em'}}/>
                              </div>
                            </Tooltip>
                          }
                          { agent.params.email &&
                            <div className="float-left mr-2 mt-1">
                              <img src={`/images/payments/billCom${agent.params.payment.billCom.accountId ? 'Green' : 'Gray'}.png`} style={{ width: '30px' }} className="img-fluid" alt="Bill.com" />
                            </div>
                          }
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          { agent
                            && agent?.params?.payment?.billCom.isDefault
                            && agent?.params?.payment?.billCom.accountId
                            && <BillComCreatorProfile vendorId={agent?.params?.payment.billCom.accountId} />
                          }
                          { agent
                            && (
                              (agent.params?.payment?.stripe.isDefault && agent.params.payment.stripe.accountId)
                              || (!agent.params?.payment?.billCom?.isDefault && agent.params?.payment?.stripe.accountIdTemp)
                            )
                            && <StripeCreatorProfile accountId={agent.params.payment.stripe.accountId || agent.params.payment.stripe.accountIdTemp} />
                          }
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <AccessComponent showWhenTeam={showWhenTeamIs} showWhenRole={['superAdmin', 'owner']}>
                        <Row>
                          <Col className="pr-0">
                            <b className="mr-2">{taxFormValues[taxFormValue]}</b>
                            <Tooltip title="Upload Tax Form">
                              <IconButton size="small" onClick={() => showModalTaxFormUpload({ source: 'portal' })}><FontAwesomeIcon icon="upload" /></IconButton>
                            </Tooltip>
                            <TaxFormUploadModal person="agent" record={agent} creators={creators} />
                          </Col>
                        </Row>
                      </AccessComponent>
                      <AccessComponent showWhenRole={['superAdmin', 'owner', 'admin']}
                                       hideWhenTeam={showWhenTeamIs} and>
                        <b>{taxFormValues[taxFormValue]}</b>
                      </AccessComponent>
                    </Col>
                    <Col>
                      <Select
                        value={paymentDefaultOption()}
                        options={paymentOptions()}
                        onChange={onPaymentProviderChanged}
                        placeholder="Specify Payment Provider"
                        className="mb-3"
                        isDisabled={loadingPayment}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            ),
            (
              <Row key="3">
                { agent.params.email && (
                  <Col>
                    <AdditionalContacts />
                  </Col>
                )}
              </Row>
            ),
            (
              <Row key="4">
                { agent.params.email && (
                  <Col>
                    <Tags id={agentId} />
                  </Col>
                )}
              </Row>
            )] : null}
        </CardBody>
      </Card>
      {agent.params.email && (<DisConnectModal
        agent={agent}
        type="agent"
        toggle={() => setModal(false)}
        modal={modal}
        meta={{ closeFunction: reloadAgent }}
      />)}
      <AgentForm
        modal={agentDialogVisible}
        modalTitle="Edit Agent"
        closeDialog={closeAgentDialog}
        row={agent}
      />
    </div>
  );
};

AgentProfile.propTypes = {
  agentId: PropTypes.string.isRequired,
  agent: PropTypes.objectOf(PropTypes.any).isRequired,
  adminUser: PropTypes.objectOf(PropTypes.any).isRequired,
  employee: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  loadingPayment: PropTypes.bool.isRequired,
  editPayment: PropTypes.func.isRequired,
  reloadAgent: PropTypes.func.isRequired,
  connectBillCom: PropTypes.func.isRequired,
  inviteBillCom: PropTypes.func.isRequired,
  refreshHubspot: PropTypes.func.isRequired,
  goToDealEditor: PropTypes.func.isRequired,
  creators: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

const mapStateToProps = state => ({
  creators: state.AgentReducer.creators,
});

export default connect(mapStateToProps)(AgentProfile);
