import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { axios } from 'ApiClient';
import _ from 'lodash';
import { unflatten } from 'flat';
import { getConstant } from '@/shared/helpers/WVConstants';

const Offer = React.memo(({
  record, updateRecordFunction,
}) => {
  const offers = getConstant('deal', 'allOffers', []);
  console.log(offers, 'offers')
  const advertiserId = record.params.approvalStatus.brand?.targetAdvertiserId;
  const offersOptions = [{ value: '', label: '-- Not Assigned --'}, ...offers.filter(offer => offer.advertiser === advertiserId).map(offer => ({ value: offer._id, label: offer.name }))];
  console.log(offersOptions, advertiserId,  'offers')
  const [selectedOffer, setSelectedOffer] = useState(record.params.opportunity?.offer || '');
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setSelectedOffer(record.params?.opportunity?.offer || '')
  }, [record.params?.opportunity?.offer]);

  const updateOffer = async (agentId) => {
    setIsSaving(true);
    try {
      const response = await axios.post('/api/portal/prospects/post-update-many', {
        params: { 'opportunity.offer': agentId || null },
        ids: [record.id],
      });
      if (response.data.error) {
        console.error('Error updating agent:', response.data.error);
        return null;
      }
      updateRecordFunction(unflatten(response.data.record));
      return response.data;
    } catch (error) {
      console.error('Error updating agent:', error);
      return null;
    } finally {
      setIsSaving(false);
    }
  };
  const handleChange = (selectedOption) => {
    setSelectedOffer(selectedOption);
    updateOffer(selectedOption.value);
  };

  return (
    <div style={{ minWidth: '120px', maxWidth: '150px' }} >
      <Select
        value={offersOptions.find(a => a.value === selectedOffer)}
        className="targetBrandSelectBox"
        options={offersOptions}
        onChange={handleChange}
        placeholder=""
        isDisabled={isSaving || !advertiserId}
        menuPlacement="auto"
        menuPortalTarget={document.body}
      />
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

Offer.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  updateRecordFunction: PropTypes.func.isRequired,
};

export default Offer;
