import React, { useMemo } from 'react';
import { unflatten } from 'flat';
import TableCellContextMenu from '@/shared/components/table/TableCellContextMenu';
import MatTableList from '@/shared/tables/materialTable/TableRedux';
// import CreatorInList from './CreatorInList';
import { getConstant } from '@/shared/helpers/WVConstants';
import { calculateConversionsCount, calculatePayout } from '@/shared/helpers/CACHelper';
import { formatCurrency, formatNumber } from '@/shared/helpers/WVFormatter';
import { Col, Row } from 'reactstrap';
import Cr from './Cr';
import Percent from './Percent';
import Cost from './Cost';
import Deal from './Deal';
import CreatorInList from './CreatorInList'
import Hook from '@/shared/hooks';
import { axios } from 'ApiClient';
import CopyToClipboard from '@/shared/components/CopyToClipboardLeft';
import {
  CopyIcon,
} from '@/shared/helpers/Icons';
import { IconButton, Tooltip } from '@material-ui/core';

const Table = React.memo(({ settings }) => {
  const pipelines = getConstant('deal', 'pipelines', []);
  const [showDeleteModal] = Hook.useShowModalHook('DeleteModalDefault');
  const [showConfirmModal] = Hook.useShowModalHook('ConfirmModalDefault');
  const [showSuccessModal] = Hook.useShowModalHook('SuccessModal');
  const [showResendModal] = Hook.useShowModalHook('reSendEmailModal');
  const [showEmailStatusModal] = Hook.useShowModalHook('EmailStatusModal');
  
  const { showSuccess, showError } = Hook.useNoticeHook();
  const stages = pipelines.find(p => p.label.toLowerCase() === 'Main Pipeline'.toLowerCase()).stages;
  const reload = Hook.useReloadTableHook();


  const onSendPayoutsButton = async (record, updateRecordFunction) => {
    try {
      const { data } = await axios.post('/api/portal/opportunity/post-refresh-opportunity-prospect', {
        id: record._id,
      });
      if (!data.success) {
        throw new Error(data.error || 'Failed to refresh record');
      } else {
        updateRecordFunction(unflatten(data.opportunityPopulated));
        showSuccess('Prospect statistics updated successfully!');
      }
    } catch (error) {
      showError('Failed to update Prospect statistics.');
    }
  };
  /* const onResendEmailButton = async (record) => {
    try {
      const { data } = await axios.post('/api/portal/opportunity/post-resend-email', {
        id: record._id,
      });
      if (!data.success) {
        throw new Error(data.error || 'Failed to refresh record');
      } else {
        // updateRecordFunction(unflatten(data.opportunityPopulated));
        showSuccess('Email Resent successfully!');
      }
    } catch (error) {
      showError(error.error || 'Failed to Resend Email.');
    }
  }; */
  return (
    <MatTableList
      noActions
      isResizable={false}
      useFlex
      useCheckboxes
      useMultiPageCheckboxes
      checkboxHandler={() => { }}
      enableWarning={false}
      notFoundProps={{ title: 'No Opportunities Found' }}
      useUnflatten
      customColumns={[
        {
          id: 'searchObject.contact',
          Header: () => 'Creator',
          accessor: record => (record?.params?.name || ''),
          Cell: p => useMemo(() => (<CreatorInList
            record={p.row.original.params?.prospect}
            contact={p.row.original.params.contact}
            creator={p.row.original.params.influencer}
          />), [p.row.original.params._id]),
          width: 320,
          className: 'd-flex align-items-center',
        },
        {
          id: 'dealObject.dealTerms.brand',
          Header: () => 'Brand',
          accessor: record => (record?.params?.dealObject?.dealTerms?.brand || ''),
          Cell: p => p.value,
          width: 100,
          className: 'd-flex align-items-center',
        },
        {
          id: 'dealName',
          Header: () => 'Deal Name',
          accessor: record => (record?.params?.dealObject?.dealname || ''),
          Cell: p => (<Deal record={p.row.original} />),
          width: 250,
          className: 'd-flex align-items-center',
          disableSortBy: true,
        },
        {
          id: 'dealStage',
          Header: () => 'Deal Stage',
          accessor: record => (record?.params?.dealObject?.dealstage || ''),
          Cell: p => stages[p.value] || '',
          width: 100,
          className: 'd-flex align-items-center',
          disableSortBy: true,
        },
        {
          id: 'cost',
          Header: () => 'Cost/Percent',
          accessor: record => (record?.params?.dealObject?._id || ''),
          Cell: p => {
            if (p.row.original.params?.dealObject.dealTerms.type_of_deal === 'CPA/Ambassador') {
              return (<Percent
                updateRecordFunction={p.updateRecordFunction}
                id={p.row.original.id}
                initialValue={p.row.original.params?.dealObject.dealTerms.cpa_percentage}
                status={p.row.original.params?.dealObject.dealTerms.opportunity_status || ''}
              />);
            }
            return (<Cost
              updateRecordFunction={p.updateRecordFunction}
              id={p.row.original.id}
              initialValue={p.row.original.params?.dealObject.dealTerms.guaranteed_payment_contract_amount}
              status={p.row.original.params?.dealObject.dealTerms.opportunity_status || ''}
            />);
          },
          width: 180,
          className: 'd-flex align-items-center',
          disableSortBy: true,
        },
        {
          id: 'cr',
          Header: () => 'Content Requirements',
          accessor: record => (record?.params?.contentRequirements || ''),
          Cell: p => (<Cr
            updateRecordFunction={p.updateRecordFunction}
            id={p.row.original.id}
            initialText={p.value}
            status={p.row.original.params?.dealObject.dealTerms.opportunity_status || ''}
          />),
          width: 180,
          className: 'd-flex align-items-center',
          disableSortBy: true,
        },
        {
          id: 'sentTo',
          Header: () => 'Send To',
          accessor: record => (record?.params?.sentToEmail || ''),
          Cell: p => (p.value ? (<span>
            <a href={`mailto:${p.value}`}
              target="_blank"
              rel="noreferrer"
              style={{ 'word-break': 'break-all' }}
              >{p.value}</a>
            <CopyToClipboard value={p.value} icon={(<CopyIcon />)} className="text-blue" />
            </span>) : ''),
          width: 180,
          className: 'd-flex align-items-center',
          disableSortBy: true,
        },
        {
          id: 'statistics',
          Header: () => 'Statistics',
          accessor: record => (record?.params?.contentRequirements || ''),
          Cell: p => useMemo(() => {
            const conversionsCount = calculateConversionsCount({
              ...p.row.original.params.stats,
              /* CPApaidConversionsArray: p.row.original.params.CPApaidConversionsArray,
              dealForCac: p.row.original.params.dealForCac,
              CPMpaidConversions: p.row.original.params.CPMpaidConversions */
            });
            const cac = (conversionsCount ? (calculatePayout({ ...p.row.original.params.stats }) / conversionsCount) : 0);
            return (
              <div className='w-100'>
                <Row className="my-0 w-100">
                  <Col className="py-0 font-weight-bold">
                    CAC
                  </Col>
                  <Col className="py-0">
                    {formatCurrency(cac)}
                  </Col>
                </Row>
                <Row className="my-0 w-100">
                  <Col className="py-0 font-weight-bold">
                    Score
                  </Col>
                  <Col className="py-0">
                    {formatNumber(p.row.original.params.prospect?.youtubeStats?.monthly_v30 || 0, 0)}
                  </Col>
                </Row>
                <Row className="my-0 w-100">
                  <Col className="py-0 font-weight-bold">
                    V30
                  </Col>
                  <Col className="py-0">
                    {formatNumber(p.row.original.params.prospect?.youtubeStats?.influencer_score || 0, 2)}
                  </Col>
                </Row>
              </div>

            )

          }, [p.value, p.row.original]),
          width: 150,
          className: 'd-flex align-items-center',
          disableSortBy: true,
        },
        {
          id: 'dealObject.dealTerms.opportunity_status',
          Header: () => 'Opportunity Status',
          accessor: record => (record?.params?.dealObject?.dealTerms?.opportunity_status || ''),
          Cell: p => {
            if (p.row.original.params.isError) {
              return (<span >
                {p.value}
                <Tooltip title={p.row.original.params.errorDetails}>
                  <IconButton style={{ color: '#dc3545' }} size="small" onClick={() => showSuccessModal({ title: 'Error Details', message: p.row.original.params.errorDetails })}><i className="fa fa-info-circle" style={{ fontSize: '0.8em' }} /></IconButton>
                </Tooltip>
              </span>);
            }
            return (<span>{p.value}</span>);
          },
          width: 100,
          className: 'd-flex align-items-center',
        },
        {
          id: 'tag',
          Header: () => 'Tag(s)',
          accessor: record => (record?.params?.tags?.join(', ') || ''),
          Cell: p => p.value,
          width: 100,
          className: 'd-flex align-items-center',
          disableSortBy: true,
        },
        {
          Header: '',
          accessor: () => null,
          id: 'actions',
          width: 50,
          Cell: (p) => {
            const contextMenuOptions = [
              {
                label: 'Remove Opportunity', handler: () => showDeleteModal({
                  title: 'Remove Opportunity',
                  message: (<>Are you sure you want to remove opportunity?</>),
                  deleteRecord: async () => {
                    try{
                    const { data } = await axios.post('/api/portal/opportunity/post-remove-opportunity', {
                      id: p.row.original.id,
                    });
                    if (!data.success) {
                      throw new Error(data.error || 'Failed to remove record');
                    } else {
                      reload();
                      showSuccess('Opportunity removed successfully!');
                    }
                  } catch (error) {
                    showError(error.message || 'Failed to update Prospect statistics.');
                  }
                    
                  },
                  successFunction: () => {
                    // showSuccess('Opportunity removed successfully! Its Not but we will remove it');
                  },
                  okMessage: 'Remove',
                })
              },
              { type: 'divider', label: 'divider2' },
              {
                label: 'Refresh Statistics', handler: () => showConfirmModal({
                  title: 'Refresh Statistics',
                  message: 'Are you sure you want refresh Opportunity\'s prospect',
                  processRecord: () => onSendPayoutsButton(p.row.original.params, p.updateRecordFunction),
                  confirmButtonCaption: 'Update statistics',
                  errorCatch: (e) => {
                    
                  }
                })
              },

            ];
            if (p.row.original.params.isError === true) {
              contextMenuOptions.push({
                type: 'divider', label: 'divider3',
              });
              contextMenuOptions.push({
                label: 'Show Error', handler: () => showSuccessModal({ title: 'Error Details', message: p.row.original.params.errorDetails }),
              });
            }
            if (p.row.original.params?.dealObject.dealTerms.opportunity_status === 'Sent') {
              contextMenuOptions.push({
                type: 'divider', label: 'divider3',
              });
              contextMenuOptions.push({
                label: 'Resend Email', handler: () => showResendModal({
                  record: p.row.original.params,
                }),
              });
              contextMenuOptions.push({
                type: 'divider', label: 'divider62',
              });
              contextMenuOptions.push({
                label: 'Show Email Status', handler: () => showEmailStatusModal({
                  record: p.row.original.params,
                }),
              });
            }
            return (
              <div style={{ paddingTop: '8px', textAlign: 'right' }}>
                <TableCellContextMenu options={contextMenuOptions} />
              </div>
            );
          },
          disableSortBy: true,
          className: 'd-flex align-items-center align-middle',
        },
      ]}
    />
  );
}, () => true);

Table.propTypes = {
};

export default Table;
