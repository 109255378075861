/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react';
import { uid } from 'react-uid';
import PlayIcon from 'mdi-react/PlayIcon';
import PauseIcon from 'mdi-react/PauseIcon';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { axios } from 'ApiClient';

const EnabledStatusInList = ({ record }) => {
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(record?.params?.enabled || false);

  useEffect(async () => {
    setEnabled(record?.params?.enabled || false);
  }, [record?.params?.enabled]);

  const handleChange = () => {
    setLoading(true);
    axios.post('/api/portal/compliance/post-edit-content-review-rule', {
      id: record.id,
      fieldsToUpdate: { enabled: !enabled },
    }).then((response) => {
      if (response?.data?.success) {
        setEnabled(!enabled);
      }

      setLoading(false);
    });
    return true;
  }

  return (
    <div>
      {loading ? (
        <div
          className="spinner-border"
          style={{
            width: '18px',
            height: '18px',
            marginTop: '4px',
            marginLeft: '3px',
            marginRight: '8px',
            color: '#646777',
          }}
        />
      ) : (
        <Tooltip
          title={enabled ? 'Pause Rule' : 'Start Rule'}
          key={`statusButton${uid(record.id)}`}
          style={{ float: 'left' }}
        >
          <IconButton
            size="small"
            className="material-table__toolbar-button"
            onClick={handleChange}
          >
            {enabled ? (
              <PauseIcon size="32" />
            ) : (
              <PlayIcon size="32" />
            )}
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}

export default props => (<div key={props.record.id}><EnabledStatusInList {...props} /></div>);
