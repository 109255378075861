import React, { useState, useEffect } from 'react';
import { axios } from 'ApiClient';
import { unflatten } from 'flat';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';


const formatAgentOptionLabel = ({ record }) => (
  <>
    {!!record ? (
      <div style={{ display: 'flex' }}>
        <div>{record?.params.first_name} {record?.params.last_name}</div>
        <div style={{ color: '#888', marginLeft: '10px' }}>
          {record?.params.email}
        </div>
      </div>
    ) : (
      <div>-- Not Assigned --</div>
    )}
  </>
);

const agentOption = (props) => {
  const { record } = props.data;
  return (
    <components.Option {...props} >
      {!!record ? (
        <div>
          <div className="bold-text">{record.params.first_name} {record.params.last_name}</div>
          <div style={{ color: '#888', fontSize: '13px' }}>
            {record.params.email}
          </div>
        </div>
      ) : (
        <div className="bold-text">-- Not Assigned --</div>
      )}
    </components.Option>
  );
};

const AgentUser = ({ record, updateRecordFunciton }) => {
  const [selectedAgent, setSelectedAgent] = useState({ value: null, label: '-- Not Assigned --'});
  const [isSaving, setIsSaving] = useState(false);
  const loadOptions = async (inputValue) => {
    // Replace with your API call to fetch agents
    const response = await axios.get(`/api/resources/AgentUser/actions/list?filters.fullSearchName=${encodeURIComponent(inputValue || '')}&perPage=20&page=1&direction=desc&sortBy=createdAt`);
    if (response.data.error) {
      return [{ value: null, label: '-- Not Assigned --'}];
    }
    const loadedAgents = response.data.records.map(agent => ({ value: agent.id, label: agent.params.email, record: agent }));
    const options = [
      ...(!inputValue || loadedAgents.length === 0 ? [{ value: null, label: '-- Not Assigned --'}] : []),
      ...loadedAgents,
    ];
    return options;
  };

  useEffect(async () => {
    if (record?.params?.agent && selectedAgent?.value !== record?.params?.agent) {
      const agent = await loadOptions(record?.params?.agent);
      setSelectedAgent(agent);
    }
  }, [record]);
  const updateAgent = async (agentId) => {
    setIsSaving(true);
    try {
      const response = await axios.post('/api/portal/prospects/post-update-many', {
        params: { 'agent': agentId },
        ids: [record.id],
      });
      if (response.data.error) {
        console.error('Error updating agent:', response.data.error);
        return null;
      }
      updateRecordFunciton(unflatten(response.data.record));
      return response.data;
    } catch (error) {
      console.error('Error updating agent:', error);
      return null;
    } finally {
      setIsSaving(false);
    }
  };

  const handleChange = (selectedOption) => {
    setSelectedAgent(selectedOption);
    updateAgent(selectedOption.value);
  };

  return (
    <div>
      <AsyncSelect
        className='w-100'
        cacheOptions
        loadOptions={loadOptions}
        defaultOptions
        value={selectedAgent}
        onChange={handleChange}
        placeholder="Select an agent"
        isDisabled={isSaving}
        menuPlacement="auto"
        formatOptionLabel={formatAgentOptionLabel}
        components={{ Option: agentOption }}
        // menuPortalTarget={customPortal}// block body bu lockdown
      />
    </div>
  );
};

export default AgentUser;
