import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { unflatten } from 'flat';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import CreatorInList from '../../../Prospects/components/custom/CreatorInList';
import TableCellContextMenu from './TableCellContextMenu';
import UserPropertyType from './index';
import TargetBrand from '../../../Prospects/components/custom/TargetBrand';
import MatTableList from '../../../../../../../shared/tables/materialTable/MatTableList';
import ContactOwner from './ContactOwner';
import DealOwner from './DealOwner';
import { getServerDateTime } from '../../../../../../../shared/helpers/WVFormatter';
import DetailsModal from '../../../Prospects/components/custom/modals/DetailsModal';
import DealType from './DealType';
import CostPercent from './CostPercent';
import AgentUser from './AgentUser';
import Offer from './Offer';
// import { countriesBH } from '../../../../../../../shared/helpers/WVConstants';
import CellSelect from "../../../../../../../shared/components/table/CellSelect";


const Table = React.memo(
  ({
    action,
    resource,
    // setSingleSelectedProspectId,
    // addNotice,
    // brands,
    version,
    setVersion,
    handleSelectCheckbox,
    // eslint-disable-next-line no-unused-vars
    adminUsers, loadingAdminUsers,
    setMetaTotal,
    doHubspotImport,
    goToEditProspectPage,
    recordsRef,
    setProspect,
    setOpenStatsModal,
    setDeals,
    setShowDealsListModal,
    countryOptions,
  }) => {
    // const countries = countriesBH();
    return (
      <MatTableList
        action={action}
        resource={resource}
        date={new Date()}
        version={version}
        UserPropertyType={UserPropertyType}
        showFilters={false}
        showTitle={false}
        sortBy="createdAt"
        direction="desc"
        v={2}
        noActions
        isResizable={false}
        useFlex
        useCheckboxes
        checkboxHandler={handleSelectCheckbox}
        setMetaTotal={setMetaTotal}
        recordsRef={recordsRef}
        enableWarning={false}
        notFoundProps={{ title: 'No Leads found' }}
        useUnflatten
        customColumns={[
          {
            id: 'fullSearchName',
            Header: () => 'Creator',
            accessor: () => '',
            Cell: p => useMemo(
              () => (
                <CreatorInList
                  record={p.row.original}
                  editHandler={() => { goToEditProspectPage(p.row.original.id); }}
                  addShieldIcons={false}
                  addProspectIcon={false}
                  setOpenTabs={() => {}}
                  setCurrentProspect={() => {}}
                />
              ),
              [p.row.original],
            ),
            width: 350,
          },
          {
            id: 'countryOfResidence',
            Header: () => 'COR',
            accessor: () => '',
            width: 180,
            className: 'd-flex align-items-center',
            Cell: p => (
              <div>
                <CellSelect
                  name="countryOfResidence"
                  label="Country of Residence"
                  record={p.row.original}
                  values={countryOptions}
                  update="/api/portal/prospects/post-save"
                  updateRecordFunction={p.updateRecordFunction}
                  styles={{width: '160px', float: 'left' }}
                  disableOptionMap
                  additionalFieldsToUpdate={{ countryOfResidenceProcessed: false }}
                />
                {/* {p.row.original.params.countryOfResidence ? countries[p.row.original.params.countryOfResidence] || 'Other' : 'Not Assigned'} */}
              </div>
            ),
            disableSortBy: true,
          },
          {
            id: 'hs_last_activity_date',
            Header: () => 'Last Activity Date',
            accessor: () => '',
            Cell: p => useMemo(() => (
              p.row.original.params?.hs_last_activity_date ? (
                <div style={{ width: '160px', paddingRight: '10px' }}>
                  <span>{getServerDateTime(p.row.original.params.hs_last_activity_date)}</span>
                </div>
              ) : null), [p.row.original]),
            width: 150,
            className: 'd-flex align-items-center',
          },
          {
            id: 'agent',
            Header: () => 'Agent',
            Cell: p => useMemo(() => (
              <AgentUser
                record={p.row.original}
                updateRecordFunction={p.updateRecordFunction}
              />), [p.row.original]),
            width: 170,
            className: 'd-flex align-items-center',
          },
          {
            id: 'contactOwner',
            Header: () => 'Account Manager',
            Cell: p => useMemo(() => (
              <ContactOwner
                record={p.row.original}
                afterSaveFunc={() => setVersion((new Date()).toString())}
                updateRecordFunction={p.updateRecordFunction}
              />), [p.row.original]),
            width: 155,
            className: 'd-flex align-items-center',
          },
          {
            id: 'dealOwner',
            Header: () => 'Deal Owner',
            Cell: p => useMemo(() => (
              <DealOwner
                record={p.row.original}
                afterSaveFunc={() => setVersion((new Date()).toString())}
                updateRecordFunction={p.updateRecordFunction}
                openDealsModal={(deals) => {
                  setDeals(deals);
                  setShowDealsListModal(true);
                }}
              />), [p.row.original]),
            width: 195,
            className: 'd-flex align-items-center',
          },
          {
            id: 'targetBrand',
            Header: () => 'Brand',
            Cell: p => useMemo(() => (
              <TargetBrand
                record={p.row.original}
                updateRecordFunction={p.updateRecordFunction}
              />), [p.row.original]),
            width: 150,
            className: 'd-flex align-items-center',
          },
          {
            id: 'offer',
            Header: () => 'Offer',
            Cell: p => useMemo(() => (
              <Offer
                record={p.row.original}
                updateRecordFunction={p.updateRecordFunction}
              />), [p.row.original]),
            width: 150,
            className: 'd-flex align-items-center',
          },
          {
            id: 'dealType',
            Header: () => 'Deal Type',
            Cell: p => useMemo(() => (
              <DealType
                record={p.row.original}
                updateRecordFunction={p.updateRecordFunction}
              />), [p.row.original]),
            width: 150,
            className: 'd-flex align-items-center',
          },
          {
            id: 'costPerent',
            Header: () => 'Cost/Percent',
            Cell: p => useMemo(() => (
              <CostPercent
                record={p.row.original}
                updateRecordFunction={p.updateRecordFunction}
              />), [p.row.original]),
            width: 180,
            className: 'd-flex align-items-center',
          },
          {
            id: 'tags',
            Header: () => 'Tags',
            Cell: p => useMemo(() => {
              let value = '—';
              const params = unflatten(p.row.original.params);
              if (params?.tags?.length > 0) {
                value = params.tags.join(', ');
              }
              return (
                <Tooltip
                  title={value === '—' ? 'No tags added yet' : value}
                >
                  <div
                    style={{
                      width: '100%',
                      maxWidth: '100px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'pre',
                      cursor: 'pointer',
                    }}
                  >
                    {value}
                  </div>
                </Tooltip>
              );
            }, [p.row.original]),
            width: 100,
            className: 'd-flex align-items-center',
          },
          {
            Header: '',
            accessor: () => null,
            id: 'influencer_actions',
            width: 50,
            Cell: (p) => {
              const [modalDetails, showModalDetails] = useState(false);
              const contextMenuOptions = [
                { label: 'Details', handler: () => { showModalDetails(true); } },
                {
                  disabled: !(p.row.original.populated.ownerUser &&
                    p.row.original.populated.approvalStatus?.brand?.targetAdvertiserId && !p.row.original.params?.inBlackList),
                  label: 'HubSpot Import',
                  handler: () => { doHubspotImport(p.row.original.id); },
                },
                {
                  disabled: !(p.row.original.id),
                  label: 'View Statistics',
                  handler: () => {
                    setProspect(p.row.original.params);
                    setOpenStatsModal(true);
                  },
                },
              ];
              return (
                <div style={{ paddingTop: '8px', textAlign: 'right' }}>
                  <TableCellContextMenu options={contextMenuOptions} />
                  <DetailsModal
                    record={p.row.original}
                    setModalVisible={showModalDetails}
                    modal={modalDetails}
                  />
                </div>
              );
            },
            disableSortBy: true,
            className: 'd-flex align-items-center align-middle',
          },
        ]}
      />
    );
  }, (a, a1) => _.isEqual(a.version, a1.version) && _.isEqual(a.countryOptions, a1.countryOptions)
);

Table.propTypes = {
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  version: PropTypes.string.isRequired,
  setVersion: PropTypes.func.isRequired,
  // brands: PropTypes.arrayOf(PropTypes.any).isRequired,
  adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  // setSingleSelectedProspectId: PropTypes.func.isRequired,
  handleSelectCheckbox: PropTypes.func.isRequired,
  setMetaTotal: PropTypes.func.isRequired,
  doHubspotImport: PropTypes.func.isRequired,
  goToEditProspectPage: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  recordsRef: PropTypes.any.isRequired,
  // addNotice: PropTypes.func.isRequired,
  setProspect: PropTypes.func.isRequired,
  setOpenStatsModal: PropTypes.func.isRequired,
  setDeals: PropTypes.func.isRequired,
  setShowDealsListModal: PropTypes.func.isRequired,
  loadingAdminUsers: PropTypes.bool.isRequired,
  countryOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  loadingAdminUsers: state.ResearchReducer.loading.prospectsGrid,
  adminUsers: state.ResearchReducer.adminUsers,
});

export default connect(mapStateToProps)(Table);
