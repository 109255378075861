import React, { useEffect, useState } from 'react';
import {
  Col,
  Card,
  CardBody,
  Row,
  Container,
  Label,
  CustomInput,
} from 'reactstrap';
import { axios } from 'ApiClient';
import { isNull } from 'lodash';
import withNotice from '../../../App/store/with-notice';
import PropTypes from 'prop-types';

const HasOffersAPI = ({ addNotice }) => {
  const [allowUse, setAllowUse] = useState(false);
  const [loading, setLoading] = useState(false);

  const setOrGetAllowUseApi = async (value = null) => {
    setLoading(true);
    try {
      const data = await axios({
        method: 'get',
        url: `/api/portal/global/get-allow-use-has-offers-api${!isNull(value) ? `?value=${String(value)}` : ''}`,
      });
      console.log(data.data);
      if (data.data.success === true) {
        setAllowUse(data.data.allow);
      }
    } catch (err) {
      addNotice({
        message: err.message || 'Unable to get Google Oauth 2 Token',
        type: 'Error',
      });
    }
    setLoading(false);
  };

  useEffect(async () => {
    await setOrGetAllowUseApi();
  }, []);

  return (
    <Container>
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Label>Allow use of HasOffers API for Dev and Local?</Label>
              <CustomInput
                title="Allows use for 15 minutes"
                id="AllowUse"
                type="switch"
                disabled={loading}
                checked={allowUse}
                bsSize="lg"
                size="lg"
                onChange={record => console.log(record.target) || setOrGetAllowUseApi(record.target.checked)}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

HasOffersAPI.propTypes = {
  addNotice: PropTypes.func.isRequired,
};

export default withNotice(HasOffersAPI);
