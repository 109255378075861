/* eslint-disable prefer-template,max-len,no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
} from 'reactstrap';
import PropTypes from 'prop-types';
import Select from 'react-select';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import { NumericFormat as NumberFormat } from 'react-number-format';
import Slider from '@material-ui/core/Slider';
import InfoOutlineIcon from 'mdi-react/InfoOutlineIcon';
import DatePicker from 'react-datepicker';
import { addNotice } from '../../../../../../App/store/store';
import CheckBoxField from '../../../../../../../shared/components/CheckBox';
import { getCountriesOptions, getLanguagesOptions } from '../../../../../../../shared/helpers/WVUtilities';

const muiTheme = createTheme({
  overrides: {
    MuiSlider: {
      colorPrimary: {
        color: '#70bbfd',
      },
      root: {
        color: '#70bbfd',
      },
      thumb: {
        color: '#70bbfd',
        boxShadow: '#ebebeb 0 2px 2px',
        '&:focus, &:hover, &$active': {
          boxShadow: '#ccc 0 2px 3px 1px',
        },
      },
      track: {
        color: '#70bbfd',
      },
      trackInverted: {
        '& $track': {
          backgroundColor: '#c8e5fe',
        },
      },
    },
  },
});

const ViewQueryModal = ({
  dispatch,
  modal,
  setModalVisible,
  search,
}) => {
  const [activeTypeCheckbox, setActiveTypeCheckbox] = useState('creator');
  const [queryText, setQueryText] = useState('');

  const creatorSortTypesOptions = [
    { value: 'true', label: 'Smallest to largest' },
    { value: 'false', label: 'Largest to smallest' },
  ];
  const videoSortTypesOptions = [
    { value: 'false', label: 'Smallest to largest' },
    { value: 'true', label: 'Largest to smallest' },
  ];
  const videoSortTypesOptionsDates = [
    { value: 'true', label: 'Newest to oldest' },
    { value: 'false', label: 'Oldest to newest' },
  ];
  const creatorTypesOptions = [
    { value: 'influencer', label: 'Creator' },
    { value: 'media company', label: 'Media & Ent.' },
    { value: 'aggregator', label: 'Aggregator' },
    { value: 'brand', label: 'Brand' },
  ];
  const [countriesOptions] = useState(getCountriesOptions());
  const [languagesOptions] = useState(getLanguagesOptions());
  const [creatorYouTubeAudienceAgeRange, setCreatorYouTubeAudienceAgeRange] = useState([13, 60]);
  const [creatorYouTubeAudienceMinAgeRange, setCreatorYouTubeAudienceMinAgeRange] = useState(0);
  const [creatorYouTubeAudienceLocationMin, setCreatorYouTubeAudienceLocationMin] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [creatorYouTubeAudienceLocationCountry, setCreatorYouTubeAudienceLocationCountry] = useState('');
  const [creatorYouTubeAudienceLocationOption, setCreatorYouTubeAudienceLocationOption] = useState(null);
  const [creatorYouTubeV30Min, setCreatorYouTubeV30Min] = useState(null);
  const [creatorYouTubeV30Max, setCreatorYouTubeV30Max] = useState(null);
  const [creatorExcludeYouTubeCategories, setCreatorExcludeYouTubeCategories] = useState(null);
  const [creatorIncludeYouTubeCategories, setCreatorIncludeYouTubeCategories] = useState(null);
  const [creatorFacebookAudienceAgeRange, setCreatorFacebookAudienceAgeRange] = useState([13, 60]);
  const [creatorFacebookAudienceMinAgeRange, setCreatorFacebookAudienceMinAgeRange] = useState(0);
  const [creatorFacebookAudienceLocationMin, setCreatorFacebookAudienceLocationMin] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [creatorFacebookAudienceLocationCountry, setCreatorFacebookAudienceLocationCountry] = useState('');
  const [creatorFacebookAudienceLocationOption, setCreatorFacebookAudienceLocationOption] = useState(null);
  const [creatorFacebookV30Min, setCreatorFacebookV30Min] = useState(null);
  const [creatorFacebookV30Max, setCreatorFacebookV30Max] = useState(null);
  const [creatorViewsMin, setCreatorViewsMin] = useState(null);
  const [creatorViewsMax, setCreatorViewsMax] = useState(null);
  const [selectedCreatorPlatforms, setSelectedCreatorPlatforms] = useState({
    youtube: true,
    facebook: true,
    instagram: true,
    twitter: true,
    twitch: true,
  });
  const creatorSortParametersOptions = [
    { value: 'youtube.influencer_score', label: 'Score' },
    { value: 'youtube.monthly_v30', label: 'V30' },
    { value: 'youtube.monthly_er30', label: 'ER30' },
  ];
  const [creatorSortParameter, setCreatorSortParameter] = useState({ value: 'youtube.influencer_score', label: 'Score' });
  const [creatorSortType, setCreatorSortType] = useState({ value: 'false', label: 'Largest to smallest' });
  const creatorLastUploadOptions = [
    { value: 'last_7', label: 'Last 7 days' },
    { value: 'last_30', label: 'Last 30 days' },
    { value: 'last_90', label: 'Last 90 days' },
  ];
  const [creatorLastUpload, setCreatorLastUpload] = useState(null);
  const [creatorLanguages, setCreatorLanguages] = useState([]);
  const [creatorCountries, setCreatorCountries] = useState([]);
  const [creatorTypes, setCreatorTypes] = useState([
    { value: 'influencer', label: 'Creator' },
  ]);
  const [creatorContentGenres, setCreatorContentGenres] = useState([]);

  const [videoViewsMin, setVideoViewsMin] = useState(null);
  const [videoViewsMax, setVideoViewsMax] = useState(null);
  const [videoEngagementsMin, setVideoEngagementsMin] = useState(null);
  const [videoEngagementsMax, setVideoEngagementsMax] = useState(null);
  const [maxDate] = useState(new Date());
  const [minDate] = useState(new Date().setDate(new Date().getDate() - 90));
  const [videoLastUploadMin, setVideoLastUploadMin] = useState(null);
  const [videoLastUploadMax, setVideoLastUploadMax] = useState(null);
  const [videoDurationMin, setVideoDurationMin] = useState(300);
  const [videoDurationMax, setVideoDurationMax] = useState(null);
  const [selectedVideoPlatforms, setSelectedVideoPlatforms] = useState({
    youtube: true,
    facebook: true,
    instagram: true,
    twitter: true,
    twitch: true,
  });
  const [selectedVideoSearchIn, setSelectedVideoSearchIn] = useState({
    description: false,
    keywords: false,
    topics: false,
  });
  const [videoSortParameter, setVideoSortParameter] = useState({ value: 'upload_date', label: 'Published on' });
  const [videoSortType, setVideoSortType] = useState({ value: 'true', label: 'Newest to oldest' });
  const videoSortParametersOptions = [
    { value: 'upload_date', label: 'Published on' },
    { value: 'views', label: 'Views' },
  ];
  const [videoLanguages, setVideoLanguages] = useState([]);
  const [videoIncludeCategories, setVideoIncludeCategories] = useState([]);
  const [videoExcludeCategories, setVideoExcludeCategories] = useState([]);
  const [videoCreatorTypes, setVideoCreatorTypes] = useState([
    { value: 'influencer', label: 'Creator' },
  ]);
  const [videoCreatorCountries, setVideoCreatorCountries] = useState([]);
  const [videoContentGenres, setVideoContentGenres] = useState([]);

  const podcastGenresOptions = useState([]);
  const podcastTypeOptions = [
    { value: 'podcast', label: 'Podcasts' },
    { value: 'episode', label: 'Episodes' },
  ];
  const [podcastType, setPodcastType] = useState({ value: 'podcast', label: 'Podcasts' });
  const [podcastOnlyIn, setPodcastOnlyIn] = useState({
    title: true,
    description: true,
    author: false,
    audio: false,
  });
  const [podcastGenres, setPodcastGenres] = useState([]);
  const podcastLanguageOptions = useState([]);
  const [podcastLanguage, setPodcastLanguage] = useState({ value: 'English', label: 'English' });
  const podcastRegionOptions = useState([]);
  const [podcastRegion, setPodcastRegion] = useState({ value: 'us', label: 'United States' });
  const podcastSortOptions = [
    { value: '0', label: 'Sort by Relevance' },
    { value: '1', label: 'Sort by Date' },
  ];
  const [podcastSort, setPodcastSort] = useState({ value: '0', label: 'Sort by Relevance' }); // 0 - sort by relevance, 1 - sort by date
  const [podcastPublishedAfter, setPodcastPublishedAfter] = useState(null);
  const [podcastPublishedBefore, setPodcastPublishedBefore] = useState(null);

  const setFilters = (savedSearch = null) => {
    function getTubularPlatformsFromArray(array) {
      return {
        youtube: array.includes('youtube'),
        facebook: array.includes('facebook'),
        instagram: array.includes('instagram'),
        twitter: array.includes('twitter'),
        twitch: array.includes('twitch'),
      };
    }
    function getOnlyInFromArray(array) {
      return {
        title: array.includes('title'),
        description: array.includes('description'),
        author: array.includes('author'),
        audio: array.includes('audio'),
      };
    }
    let hasErrors = false;
    setActiveTypeCheckbox(savedSearch?.activeTypeCheckbox || 'creator');
    setQueryText(savedSearch?.searchQuery || '');
    setCreatorYouTubeAudienceAgeRange(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'youtube');
          if (foundAudienceFilters?.audienceAge?.min && foundAudienceFilters?.audienceAge?.max) {
            return [foundAudienceFilters.audienceAge.min, foundAudienceFilters.audienceAge.max];
          }
        }
        return [13, 60];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorYouTubeAudienceAgeRange(): ', error);
        return [13, 60];
      }
    });
    setCreatorYouTubeAudienceMinAgeRange(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'youtube');
          if (foundAudienceFilters && typeof foundAudienceFilters.audienceMinAgeRange !== 'undefined' && foundAudienceFilters.audienceMinAgeRange !== null) {
            return foundAudienceFilters.audienceMinAgeRange;
          }
        }
        return 0;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorYouTubeAudienceMinAgeRange(): ', error);
        return 0;
      }
    });
    setCreatorYouTubeAudienceLocationMin(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'youtube');
          if (foundAudienceFilters?.audienceLocations?.length > 0 && typeof foundAudienceFilters.audienceLocations[0]?.min !== 'undefined' && foundAudienceFilters.audienceLocations[0]?.min !== null) {
            return foundAudienceFilters.audienceLocations[0].min;
          }
        }
        return 0;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorYouTubeAudienceLocationMin(): ', error);
        return 0;
      }
    });
    setCreatorYouTubeAudienceLocationCountry(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'youtube');
          if (foundAudienceFilters?.audienceLocations?.length > 0 && foundAudienceFilters.audienceLocations[0]?.country) {
            return foundAudienceFilters.audienceLocations[0].country;
          }
        }
        return '';
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorYouTubeAudienceLocationCountry(): ', error);
        return '';
      }
    });
    setCreatorYouTubeAudienceLocationOption(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'youtube');
          if (foundAudienceFilters?.audienceLocations?.length > 0 && foundAudienceFilters.audienceLocations[0]?.country && countriesOptions?.length > 0) {
            return countriesOptions.find(option => option.value === foundAudienceFilters.audienceLocations[0].country);
          }
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorYouTubeAudienceLocationOption(): ', error);
        return null;
      }
    });
    setCreatorYouTubeV30Min(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'youtube');
          if (foundAudienceFilters?.v30?.min) {
            return foundAudienceFilters.v30.min;
          }
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorYouTubeV30Min(): ', error);
        return null;
      }
    });
    setCreatorYouTubeV30Max(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'youtube');
          if (foundAudienceFilters?.v30?.max) {
            return foundAudienceFilters.v30.max;
          }
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorYouTubeV30Max(): ', error);
        return null;
      }
    });
    setCreatorFacebookAudienceAgeRange(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'facebook');
          if (foundAudienceFilters?.audienceAge?.min && foundAudienceFilters?.audienceAge?.max) {
            return [foundAudienceFilters.audienceAge.min, foundAudienceFilters.audienceAge.max];
          }
        }
        return [13, 60];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorFacebookAudienceAgeRange(): ', error);
        return [13, 60];
      }
    });
    setCreatorFacebookAudienceMinAgeRange(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'facebook');
          if (foundAudienceFilters && typeof foundAudienceFilters.audienceMinAgeRange !== 'undefined' && foundAudienceFilters.audienceMinAgeRange !== null) {
            return foundAudienceFilters.audienceMinAgeRange;
          }
        }
        return 0;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorFacebookAudienceMinAgeRange(): ', error);
        return 0;
      }
    });
    setCreatorFacebookAudienceLocationMin(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'facebook');
          if (foundAudienceFilters?.audienceLocations?.length > 0 && typeof foundAudienceFilters.audienceLocations[0]?.min !== 'undefined' && foundAudienceFilters.audienceLocations[0]?.min !== null) {
            return foundAudienceFilters.audienceLocations[0].min;
          }
        }
        return 0;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorFacebookAudienceLocationMin(): ', error);
        return 0;
      }
    });
    setCreatorFacebookAudienceLocationCountry(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'facebook');
          if (foundAudienceFilters?.audienceLocations?.length > 0 && foundAudienceFilters.audienceLocations[0]?.country) {
            return foundAudienceFilters.audienceLocations[0].country;
          }
        }
        return '';
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorFacebookAudienceLocationCountry(): ', error);
        return '';
      }
    });
    setCreatorFacebookAudienceLocationOption(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'facebook');
          if (foundAudienceFilters?.audienceLocations?.length > 0 && foundAudienceFilters.audienceLocations[0]?.country && countriesOptions?.length > 0) {
            return countriesOptions.find(option => option.value === foundAudienceFilters.audienceLocations[0].country);
          }
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorFacebookAudienceLocationOption(): ', error);
        return null;
      }
    });
    setCreatorFacebookV30Min(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'facebook');
          if (foundAudienceFilters?.v30?.min) {
            return foundAudienceFilters.v30.min;
          }
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorFacebookV30Min(): ', error);
        return null;
      }
    });
    setCreatorFacebookV30Max(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'facebook');
          if (foundAudienceFilters?.v30?.max) {
            return foundAudienceFilters.v30.max;
          }
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorFacebookV30Max(): ', error);
        return null;
      }
    });
    setCreatorViewsMin(() => {
      try {
        if (typeof savedSearch?.creatorFilters?.views?.min !== 'undefined' && savedSearch?.creatorFilters?.views?.min !== null) {
          return savedSearch.creatorFilters.views.min;
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorViewsMin(): ', error);
        return null;
      }
    });
    setCreatorViewsMax(() => {
      try {
        if (typeof savedSearch?.creatorFilters?.views?.max !== 'undefined' && savedSearch?.creatorFilters?.views?.max !== null) {
          return savedSearch.creatorFilters.views.max;
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorViewsMax(): ', error);
        return null;
      }
    });
    setSelectedCreatorPlatforms(() => {
      try {
        if (typeof savedSearch?.creatorFilters?.platforms !== 'undefined' && savedSearch?.creatorFilters?.platforms !== null && Array.isArray(savedSearch.creatorFilters.platforms)) {
          return getTubularPlatformsFromArray(savedSearch.creatorFilters.platforms);
        }
        return {
          youtube: true,
          facebook: true,
          instagram: true,
          twitter: true,
          twitch: true,
        };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setSelectedCreatorPlatforms(): ', error);
        return {
          youtube: true,
          facebook: true,
          instagram: true,
          twitter: true,
          twitch: true,
        };
      }
    });
    setCreatorSortParameter(() => {
      try {
        if (savedSearch?.creatorFilters?.sortBy) {
          const foundOption = creatorSortParametersOptions.find(option => option.value === savedSearch.creatorFilters.sortBy);
          if (foundOption) {
            return foundOption;
          }
        }
        return { value: 'youtube.influencer_score', label: 'Score' };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorSortParameter(): ', error);
        return { value: 'youtube.influencer_score', label: 'Score' };
      }
    });
    setCreatorSortType(() => {
      try {
        if (typeof savedSearch?.creatorFilters?.sortOrderAscending !== 'undefined' && savedSearch?.creatorFilters?.sortOrderAscending !== null) {
          const foundOption = creatorSortTypesOptions.find(option => option.value === savedSearch.creatorFilters.sortOrderAscending.toString());
          if (foundOption) {
            return foundOption;
          }
        }
        return { value: 'false', label: 'Largest to smallest' };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorSortType(): ', error);
        return { value: 'false', label: 'Largest to smallest' };
      }
    });
    setCreatorLastUpload(() => {
      try {
        if (typeof savedSearch?.creatorFilters?.creatorLastUpload !== 'undefined' && savedSearch?.creatorFilters?.creatorLastUpload !== null) {
          const foundOption = creatorLastUploadOptions.find(option => option.value === savedSearch.creatorFilters.creatorLastUpload);
          if (foundOption) {
            return foundOption;
          }
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorLastUpload(): ', error);
        return null;
      }
    });
    setCreatorLanguages(() => {
      try {
        if (typeof savedSearch?.creatorFilters?.creatorLanguages !== 'undefined' && savedSearch?.creatorFilters?.creatorLanguages !== null && Array.isArray(savedSearch.creatorFilters.creatorLanguages)) {
          const languages = [];
          savedSearch.creatorFilters.creatorLanguages.forEach((language) => {
            const foundOption = languagesOptions.find(option => option.value === language);
            if (foundOption) languages.push(foundOption);
          });
          return languages;
        }
        return [];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorLanguages(): ', error);
        return [];
      }
    });
    setCreatorCountries(() => {
      try {
        if (typeof savedSearch?.creatorFilters?.creatorCountries !== 'undefined' && savedSearch?.creatorFilters?.creatorCountries !== null && Array.isArray(savedSearch.creatorFilters.creatorCountries)) {
          const countries = [];
          savedSearch.creatorFilters.creatorCountries.forEach((country) => {
            const foundOption = countriesOptions.find(option => option.value === country);
            if (foundOption) countries.push(foundOption);
          });
          return countries;
        }
        return [];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorCountries(): ', error);
        return [];
      }
    });
    setCreatorTypes(() => {
      try {
        if (typeof savedSearch?.creatorFilters?.creatorTypes !== 'undefined' && savedSearch?.creatorFilters?.creatorTypes !== null && Array.isArray(savedSearch.creatorFilters.creatorTypes)) {
          const types = [];
          savedSearch.creatorFilters.creatorTypes.forEach((type) => {
            const foundOption = creatorTypesOptions.find(option => option.value === type);
            if (foundOption) types.push(foundOption);
          });
          return types;
        }
        return [
          { value: 'influencer', label: 'Creator' },
        ];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorTypes(): ', error);
        return [
          { value: 'influencer', label: 'Creator' },
        ];
      }
    });
    setCreatorContentGenres(() => {
      try {
        if (typeof savedSearch?.creatorFilters?.contentGenres !== 'undefined' && savedSearch?.creatorFilters?.contentGenres !== null && Array.isArray(savedSearch.creatorFilters.contentGenres)) {
          return savedSearch.creatorFilters.contentGenres;
        }
        return [];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorContentGenres(): ', error);
        return [];
      }
    });
    setCreatorIncludeYouTubeCategories(() => {
      try {
        if (typeof savedSearch?.creatorFilters?.includeYouTubeCategories !== 'undefined' && savedSearch?.creatorFilters?.includeYouTubeCategories !== null && Array.isArray(savedSearch.creatorFilters.includeYouTubeCategories)) {
          return savedSearch.creatorFilters.includeYouTubeCategories;
        }
        return [];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorIncludeYouTubeCategories(): ', error);
        return [];
      }
    });
    setCreatorExcludeYouTubeCategories(() => {
      try {
        if (typeof savedSearch?.creatorFilters?.excludeYouTubeCategories !== 'undefined' && savedSearch?.creatorFilters?.excludeYouTubeCategories !== null && Array.isArray(savedSearch.creatorFilters.excludeYouTubeCategories)) {
          return savedSearch.creatorFilters.excludeYouTubeCategories;
        }
        return [];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorExcludeYouTubeCategories(): ', error);
        return [];
      }
    });

    setVideoViewsMin(() => {
      try {
        if (typeof savedSearch?.videoFilters?.views?.min !== 'undefined' && savedSearch?.videoFilters?.views?.min !== null) {
          return savedSearch.videoFilters.views.min;
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoViewsMin(): ', error);
        return null;
      }
    });
    setVideoViewsMax(() => {
      try {
        if (typeof savedSearch?.videoFilters?.views?.max !== 'undefined' && savedSearch?.videoFilters?.views?.max !== null) {
          return savedSearch.videoFilters.views.max;
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoViewsMax(): ', error);
        return null;
      }
    });
    setVideoEngagementsMin(() => {
      try {
        if (typeof savedSearch?.videoFilters?.engagements?.min !== 'undefined' && savedSearch?.videoFilters?.engagements?.min !== null) {
          return savedSearch.videoFilters.engagements.min;
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoEngagementsMin(): ', error);
        return null;
      }
    });
    setVideoEngagementsMax(() => {
      try {
        if (typeof savedSearch?.videoFilters?.engagements?.max !== 'undefined' && savedSearch?.videoFilters?.engagements?.max !== null) {
          return savedSearch.videoFilters.engagements.max;
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoEngagementsMax(): ', error);
        return null;
      }
    });
    setVideoDurationMin(() => {
      try {
        if (typeof savedSearch?.videoFilters?.video_duration?.min !== 'undefined' && savedSearch?.videoFilters?.video_duration?.min !== null) {
          return savedSearch.videoFilters.video_duration.min;
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoDurationMin(): ', error);
        return 300;
      }
    });
    setVideoDurationMax(() => {
      try {
        if (typeof savedSearch?.videoFilters?.video_duration?.max !== 'undefined' && savedSearch?.videoFilters?.video_duration?.max !== null) {
          return savedSearch.videoFilters.video_duration.max;
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoDurationMax(): ', error);
        return null;
      }
    });
    setVideoLastUploadMin(() => {
      try {
        if (savedSearch?.videoFilters?.uploadDate?.min) {
          return new Date(savedSearch.videoFilters.uploadDate.min);
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoLastUploadMin(): ', error);
        return null;
      }
    });
    setVideoLastUploadMax(() => {
      try {
        if (savedSearch?.videoFilters?.uploadDate?.max) {
          return new Date(savedSearch.videoFilters.uploadDate.max);
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoLastUploadMax(): ', error);
        return null;
      }
    });
    setSelectedVideoPlatforms(() => {
      try {
        if (typeof savedSearch?.videoFilters?.videoPlatforms !== 'undefined' && savedSearch?.videoFilters?.videoPlatforms !== null && Array.isArray(savedSearch.videoFilters.videoPlatforms)) {
          return getTubularPlatformsFromArray(savedSearch.videoFilters.videoPlatforms);
        }
        return {
          youtube: true,
          facebook: true,
          instagram: true,
          twitter: true,
          twitch: true,
        };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setSelectedVideoPlatforms(): ', error);
        return {
          youtube: true,
          facebook: true,
          instagram: true,
          twitter: true,
          twitch: true,
        };
      }
    });
    setSelectedVideoSearchIn(() => {
      try {
        return {
          description: (typeof savedSearch?.videoFilters?.searchDescription !== 'undefined' && savedSearch?.videoFilters?.searchDescription !== null) ? savedSearch?.videoFilters.searchDescription : false,
          keywords: (typeof savedSearch?.videoFilters?.searchKeywords !== 'undefined' && savedSearch?.videoFilters?.searchKeywords !== null) ? savedSearch?.videoFilters.searchKeywords : false,
          topics: (typeof savedSearch?.videoFilters?.searchTopics !== 'undefined' && savedSearch?.videoFilters?.searchTopics !== null) ? savedSearch?.videoFilters.searchTopics : false,
        };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setSelectedVideoSearchIn(): ', error);
        return {
          description: false,
          keywords: false,
          topics: false,
        };
      }
    });
    setVideoLanguages(() => {
      try {
        if (typeof savedSearch?.videoFilters?.videoLanguages !== 'undefined' && savedSearch?.videoFilters?.videoLanguages !== null && Array.isArray(savedSearch.videoFilters.videoLanguages)) {
          const languages = [];
          savedSearch.videoFilters.videoLanguages.forEach((language) => {
            const foundOption = languagesOptions.find(option => option.value === language);
            if (foundOption) languages.push(foundOption);
          });
          return languages;
        }
        return [];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoLanguages(): ', error);
        return [];
      }
    });
    setVideoIncludeCategories(() => {
      try {
        if (typeof savedSearch?.videoFilters?.includeVideoCategories !== 'undefined' && savedSearch?.videoFilters?.includeVideoCategories !== null && Array.isArray(savedSearch.videoFilters.includeVideoCategories)) {
          return savedSearch.videoFilters.includeVideoCategories.map(category => ({ value: category, label: category }));
        }
        return [];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoIncludeCategories(): ', error);
        return [];
      }
    });
    setVideoExcludeCategories(() => {
      try {
        if (typeof savedSearch?.videoFilters?.excludeVideoCategories !== 'undefined' && savedSearch?.videoFilters?.excludeVideoCategories !== null && Array.isArray(savedSearch.videoFilters.excludeVideoCategories)) {
          return savedSearch.videoFilters.excludeVideoCategories.map(category => ({ value: category, label: category }));
        }
        return [];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoExcludeCategories(): ', error);
        return [];
      }
    });
    setVideoCreatorCountries(() => {
      try {
        if (typeof savedSearch?.videoFilters?.creatorCountries !== 'undefined' && savedSearch?.videoFilters?.creatorCountries !== null && Array.isArray(savedSearch.videoFilters.creatorCountries)) {
          const countries = [];
          savedSearch.videoFilters.creatorCountries.forEach((country) => {
            const foundOption = countriesOptions.find(option => option.value === country);
            if (foundOption) countries.push(foundOption);
          });
          return countries;
        }
        return [];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoCreatorCountries(): ', error);
        return [];
      }
    });
    setVideoCreatorTypes(() => {
      try {
        if (typeof savedSearch?.videoFilters?.creatorTypes !== 'undefined' && savedSearch?.videoFilters?.creatorTypes !== null && Array.isArray(savedSearch.videoFilters.creatorTypes)) {
          const types = [];
          savedSearch.videoFilters.creatorTypes.forEach((type) => {
            const foundOption = creatorTypesOptions.find(option => option.value === type);
            if (foundOption) types.push(foundOption);
          });
          return types;
        }
        return [
          { value: 'influencer', label: 'Creator' },
        ];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoCreatorTypes(): ', error);
        return [
          { value: 'influencer', label: 'Creator' },
        ];
      }
    });
    setVideoContentGenres(() => {
      try {
        if (typeof savedSearch?.videoFilters?.contentGenres !== 'undefined' && savedSearch?.videoFilters?.contentGenres !== null && Array.isArray(savedSearch.videoFilters.contentGenres)) {
          return savedSearch.videoFilters.contentGenres;
        }
        return [];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoContentGenres(): ', error);
        return [];
      }
    });
    setVideoSortParameter(() => {
      try {
        if (savedSearch?.videoFilters?.sortBy) {
          const foundOption = videoSortParametersOptions.find(option => option.value === savedSearch.videoFilters.sortBy);
          if (foundOption) {
            return foundOption;
          }
        }
        return { value: 'upload_date', label: 'Published on' };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoSortParameter(): ', error);
        return { value: 'upload_date', label: 'Published on' };
      }
    });
    setVideoSortType(() => {
      try {
        if (savedSearch?.videoFilters?.sortBy && typeof savedSearch?.videoFilters?.sortOrderDescending !== 'undefined' && savedSearch?.videoFilters?.sortOrderDescending !== null) {
          const foundOption = savedSearch.videoFilters.sortBy === 'upload_date'
            ? videoSortTypesOptionsDates.find(option => option.value === savedSearch.videoFilters.sortOrderDescending.toString())
            : videoSortTypesOptions.find(option => option.value === savedSearch.videoFilters.sortOrderDescending.toString());
          if (foundOption) {
            return foundOption;
          }
        }
        return { value: 'true', label: 'Newest to oldest' };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoSortType(): ', error);
        return { value: 'true', label: 'Newest to oldest' };
      }
    });

    setPodcastType(() => {
      try {
        if (savedSearch?.podcastFilters?.type) {
          const foundOption = podcastTypeOptions.find(option => option.value === savedSearch.podcastFilters.type);
          if (foundOption) {
            return foundOption;
          }
        }
        return { value: 'podcast', label: 'Podcasts' };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setPodcastType(): ', error);
        return { value: 'podcast', label: 'Podcasts' };
      }
    });
    setPodcastGenres(() => {
      try {
        if (typeof savedSearch?.podcastFilters?.genreIds !== 'undefined' && savedSearch?.podcastFilters?.genreIds !== null && Array.isArray(savedSearch.podcastFilters.genreIds)) {
          const genres = [];
          savedSearch.podcastFilters.genreIds.forEach((genre) => {
            const foundOption = podcastGenresOptions.find(option => option.value.toString() === genre);
            if (foundOption) genres.push(foundOption);
          });
          return genres;
        }
        return [];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setPodcastGenres(): ', error);
        return [];
      }
    });
    setPodcastOnlyIn(() => {
      try {
        if (typeof savedSearch?.podcastFilters?.onlyIn !== 'undefined' && savedSearch?.podcastFilters?.onlyIn !== null && Array.isArray(savedSearch.podcastFilters.onlyIn)) {
          return getOnlyInFromArray(savedSearch.podcastFilters.onlyIn);
        }
        return {
          title: true,
          description: true,
          author: false,
          audio: false,
        };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setPodcastOnlyIn(): ', error);
        return {
          title: true,
          description: true,
          author: false,
          audio: false,
        };
      }
    });
    setPodcastLanguage(() => {
      try {
        if (savedSearch?.podcastFilters?.language) {
          const foundOption = podcastLanguageOptions.find(option => option.value === savedSearch.podcastFilters.language);
          if (foundOption) {
            return foundOption;
          }
        }
        return { value: 'English', label: 'English' };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setPodcastLanguage(): ', error);
        return { value: 'English', label: 'English' };
      }
    });
    setPodcastRegion(() => {
      try {
        if (savedSearch?.podcastFilters?.region) {
          const foundOption = podcastRegionOptions.find(option => option.value === savedSearch.podcastFilters.region);
          if (foundOption) {
            return foundOption;
          }
        }
        return { value: 'us', label: 'United States' };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setPodcastRegion(): ', error);
        return { value: 'us', label: 'United States' };
      }
    });
    setPodcastSort(() => {
      try {
        if (savedSearch?.podcastFilters?.sortByDate) {
          const foundOption = podcastSortOptions.find(option => option.value === savedSearch.podcastFilters.sortByDate);
          if (foundOption) {
            return foundOption;
          }
        }
        return { value: '0', label: 'Sort by Relevance' };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setPodcastSort(): ', error);
        return { value: '0', label: 'Sort by Relevance' };
      }
    });
    setPodcastPublishedAfter(() => {
      try {
        if (savedSearch?.podcastFilters?.publishedAfter) {
          return new Date(savedSearch.podcastFilters.publishedAfter);
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setPodcastPublishedAfter(): ', error);
        return null;
      }
    });
    setPodcastPublishedBefore(() => {
      try {
        if (savedSearch?.podcastFilters?.publishedBefore) {
          return new Date(savedSearch.podcastFilters.publishedBefore);
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setPodcastPublishedBefore(): ', error);
        return null;
      }
    });

    if (hasErrors) {
      dispatch(addNotice({
        message: 'Due to corrupted data in saved search, some of the filters are set to default values. Check out console for more information',
        type: 'warning',
      }));
    }
  };

  useEffect(() => {
    if (search) {
      setFilters(search);
    }
  }, [search]);

  const searchTypeChanged = (type) => {
    setActiveTypeCheckbox(type);
  };

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };

  return (
    <Modal
      className="modal-dialog"
      modalClassName="ltr-support"
      isOpen={modal}
      toggle={() => setModalVisible(false)}
      style={{
        minWidth: '900px',
        maxWidth: '1640px',
      }}
    >
      <div
        className="modal__header"
        style={{
          fontSize: '100%',
        }}
      >
        <button
          className="lnr lnr-cross modal__close-btn btn-sm"
          type="button"
          onClick={() => setModalVisible(false)}
        />
        <h3 className="text-modal  modal__title">Creator Search Query</h3>
      </div>
      <div
        className="modal__body theme-light"
        style={{
          fontSize: '100%',
        }}
      >
        <div
          style={{
            width: '1600px',
            maxHeight: '900px',
            overflowY: 'auto',
            overflowX: 'auto',
          }}
        >
          <Row className="mb-3">
            <Col md={12}>
              <Row>
                <Col>
                  <Row>
                    <Col sm={12} style={{ paddingLeft: '50px' }}>
                      <FormControlLabel
                        control={
                          <Input
                            type="radio"
                            name="searchType"
                            value="creator"
                            className="mt-0"
                            checked={activeTypeCheckbox === 'creator'}
                            onChange={() => { searchTypeChanged('creator'); }}
                          />
                        }
                        label="Creator"
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <FormControlLabel
                        control={
                          <Input
                            type="radio"
                            name="searchType"
                            value="video"
                            className="mt-0"
                            checked={activeTypeCheckbox === 'video'}
                            onChange={() => { searchTypeChanged('video'); }}
                          />
                        }
                        label="Video"
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <FormControlLabel
                        control={
                          <Input
                            type="radio"
                            name="searchType"
                            value="podcast"
                            className="mt-0"
                            checked={activeTypeCheckbox === 'podcast'}
                            onChange={() => { searchTypeChanged('podcast'); }}
                          />
                        }
                        label="Podcast"
                      />
                    </Col>
                  </Row>
                  <div className="d-flex w-100 mb-3">
                    <Input
                      type="search"
                      className="form-control"
                      /* eslint-disable-next-line no-nested-ternary */
                      placeholder={activeTypeCheckbox === 'creator'
                        ? 'Creator name, @username, or URL'
                        : (activeTypeCheckbox === 'video'
                            ? 'Search for videos by title, topic or URL'
                            : 'Search for podcasts by name or keywords'
                        )
                      }
                      value={queryText}
                      onChange={() => {}}
                    />
                  </div>
                  <ThemeProvider theme={muiTheme}>
                    {activeTypeCheckbox === 'creator' && (
                      <Row>
                        <Col md={4}>
                          <Row>
                            <Col md={3}>
                              <Label className="bold-text" style={{ marginBottom: '14px' }}>
                                Platforms
                              </Label>
                              <FormGroup
                                style={{ marginBottom: '10px' }}
                              >
                                <CheckBoxField
                                  name="youtube"
                                  label="YouTube"
                                  value={selectedCreatorPlatforms.youtube}
                                  onChange={() => {}}
                                />
                              </FormGroup>
                              <FormGroup
                                style={{ marginBottom: '10px' }}
                              >
                                <CheckBoxField
                                  name="facebook"
                                  label="Facebook"
                                  value={selectedCreatorPlatforms.facebook}
                                  onChange={() => {}}
                                />
                              </FormGroup>
                              <FormGroup
                                style={{ marginBottom: '10px' }}
                              >
                                <CheckBoxField
                                  name="instagram"
                                  label="Instagram"
                                  value={selectedCreatorPlatforms.instagram}
                                  onChange={() => {}}
                                />
                              </FormGroup>
                              <FormGroup
                                style={{ marginBottom: '10px' }}
                              >
                                <CheckBoxField
                                  name="twitter"
                                  label="X"
                                  value={selectedCreatorPlatforms.twitter}
                                  onChange={() => {}}
                                />
                              </FormGroup>
                              <FormGroup>
                                <CheckBoxField
                                  name="twitch"
                                  label="Twitch"
                                  value={selectedCreatorPlatforms.twitch}
                                  onChange={() => {}}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <Label
                                className="bold-text"
                                title="Applies a view limit (total number of views across all accounts for the previous month) to use in the filter."
                              >
                                Views
                              </Label>
                              <br />
                              <Label>
                                Min Views
                              </Label>
                              <NumberFormat
                                thousandSeparator
                                prefix=""
                                value={creatorViewsMin}
                                className="form-control"
                                inputMode="numeric"
                                allowNegative={false}
                                allowEmptyFormatting={false}
                                fixedDecimalScale
                                decimalScale={0}
                                disabled
                                onValueChange={() => {}}
                              />
                              <Label style={{ marginTop: '8px' }}>
                                Max Views
                              </Label>
                              <NumberFormat
                                thousandSeparator
                                prefix=""
                                value={creatorViewsMax}
                                className="form-control"
                                inputMode="numeric"
                                allowNegative={false}
                                allowEmptyFormatting={false}
                                decimalScale={0}
                                disabled
                                onValueChange={() => {}}
                              />
                            </Col>
                            <Col md={5}>
                              <Label className="bold-text" >
                                Sort
                              </Label>
                              <br />
                              <Label>
                                Sort By
                              </Label>
                              <Select
                                isDisabled
                                value={creatorSortParameter}
                                options={[creatorSortParameter]}
                                onChange={() => {}}
                                styles={colourStyles}
                              />
                              <Label style={{ marginTop: '8px' }}>
                                Sort Order
                              </Label>
                              <Select
                                isDisabled
                                value={creatorSortType}
                                options={[creatorSortType]}
                                onChange={() => {}}
                                styles={colourStyles}
                              />
                            </Col>
                          </Row>
                          <div className="w-100 research-watchlists">
                            <Label className="bold-text">
                              Last Upload
                            </Label>
                            <Select
                              isDisabled
                              name="creatorLastUploadSelect"
                              isClearable
                              placeholder="Select period..."
                              styles={colourStyles}
                              value={creatorLastUpload}
                              options={[creatorLastUpload]}
                              onChange={() => {}}
                            />
                            <Label className="bold-text" style={{ marginTop: '16px' }}>
                              Creator Languages
                            </Label>
                            <Select
                              isDisabled
                              isMulti
                              name="creatorLanguagesSelect"
                              isClearable
                              value={creatorLanguages}
                              options={[creatorLanguages]}
                              onChange={() => {}}
                              placeholder="Select Languages..."
                              style={{ maxHeight: '120px' }}
                              styles={colourStyles}
                            />
                            <Label className="bold-text" style={{ marginTop: '16px' }}>
                              Creator Countries
                            </Label>
                            <Select
                              isDisabled
                              isMulti
                              name="creatorCountriesSelect"
                              isClearable
                              value={creatorCountries}
                              options={[creatorCountries]}
                              onChange={() => {}}
                              placeholder="Select Countries..."
                              style={{ maxHeight: '120px' }}
                              styles={colourStyles}
                            />
                            <Label className="bold-text" style={{ marginTop: '16px' }}>
                              Creator Types
                            </Label>
                            <Select
                              isDisabled
                              isMulti
                              name="creatorTypesSelect"
                              isClearable
                              value={creatorTypes}
                              options={[creatorTypes]}
                              onChange={() => {}}
                              placeholder="Select Types..."
                              style={{ maxHeight: '120px' }}
                              styles={colourStyles}
                            />
                            <Label className="bold-text" style={{ marginTop: '16px' }}>
                              Content Genres
                            </Label>
                            <Select
                              isDisabled
                              isMulti
                              name="creatorContentGenresSelect"
                              isClearable
                              value={creatorContentGenres}
                              options={[creatorContentGenres]}
                              onChange={() => {}}
                              placeholder="Select Genres..."
                              styles={colourStyles}
                            />
                          </div>
                        </Col>
                        <Col md={8}>
                          <Label className="bold-text">
                            YouTube Filters
                          </Label>
                          <hr style={{ marginTop: '0', border: '1px solid #bf2726' }} />
                          <Row>
                            <Col md={6}>
                              <Label className="bold-text">
                                Audience Age
                              </Label>
                              <div
                                className="w-100"
                                style={{ padding: '0 15px' }}
                              >
                                <Label style={{ marginLeft: '-15px' }}>
                                  {`Between Ages: ${creatorYouTubeAudienceAgeRange[0] === 60 ? '55+' : creatorYouTubeAudienceAgeRange[0]}-${creatorYouTubeAudienceAgeRange[1] === 60 ? '55+' : creatorYouTubeAudienceAgeRange[1]}yrs`}
                                </Label>
                                <Slider
                                  disabled
                                  min={13}
                                  max={60}
                                  value={creatorYouTubeAudienceAgeRange}
                                  onChange={() => {}}
                                  marks={[
                                    { value: 13, label: '13' },
                                    { value: 18, label: '18' },
                                    { value: 25, label: '25' },
                                    { value: 35, label: '35' },
                                    { value: 45, label: '45' },
                                    { value: 55, label: '55' },
                                    { value: 60, label: '55+' },
                                  ]}
                                  step={null}
                                  valueLabelDisplay="auto"
                                  valueLabelFormat={(value) => {
                                    if (value === 60) {
                                      return '55+';
                                    } return `${value}`;
                                  }}
                                />
                                <Label style={{ marginLeft: '-15px' }}>
                                  {`Age Range > ${creatorYouTubeAudienceMinAgeRange}%`}
                                </Label>
                                <Slider
                                  disabled
                                  min={0}
                                  max={100}
                                  value={creatorYouTubeAudienceMinAgeRange}
                                  onChange={() => {}}
                                  marks={[
                                    { value: 0, label: '0%' },
                                    { value: 25, label: '25%' },
                                    { value: 50, label: '50%' },
                                    { value: 75, label: '75%' },
                                    { value: 100, label: '100%' },
                                  ]}
                                  step={5}
                                  track="inverted"
                                  valueLabelDisplay="auto"
                                  valueLabelFormat={value => `${value}%`}
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <Label className="bold-text">
                                Audience Location
                              </Label>
                              <div className="w-100 research-watchlists">
                                <Label>
                                  Country
                                </Label>
                                <Select
                                  isDisabled
                                  isClearable
                                  placeholder="Select Country..."
                                  value={creatorYouTubeAudienceLocationOption}
                                  options={[creatorYouTubeAudienceLocationOption]}
                                  onChange={() => {}}
                                  styles={colourStyles}
                                />
                                {creatorYouTubeAudienceLocationOption && creatorYouTubeAudienceLocationOption.label && (
                                  <div
                                    className="w-100"
                                    style={{ padding: '0 15px' }}
                                  >
                                    <Label style={{ marginLeft: '-15px', marginTop: '16px' }}>
                                      {`${creatorYouTubeAudienceLocationOption.label} > ${creatorYouTubeAudienceLocationMin}%`}
                                    </Label>
                                    <Slider
                                      disabled
                                      min={0}
                                      max={100}
                                      value={creatorYouTubeAudienceLocationMin}
                                      onChange={() => {}}
                                      marks={[
                                        { value: 0, label: '0%' },
                                        { value: 25, label: '25%' },
                                        { value: 50, label: '50%' },
                                        { value: 75, label: '75%' },
                                        { value: 100, label: '100%' },
                                      ]}
                                      step={5}
                                      track="inverted"
                                      valueLabelDisplay="auto"
                                      valueLabelFormat={value => `${value}%`}
                                    />
                                  </div>
                                )}
                              </div>
                            </Col>
                            <Col md={2}>
                              <Label className="bold-text">
                                V30
                              </Label>
                              <br />
                              <Label>
                                Min Views
                              </Label>
                              <NumberFormat
                                disabled
                                thousandSeparator
                                prefix=""
                                name="what"
                                value={creatorYouTubeV30Min}
                                className="form-control"
                                inputMode="numeric"
                                allowEmptyFormatting={false}
                                fixedDecimalScale
                                decimalScale={0}
                                onValueChange={() => {}}
                              />
                              <Label style={{ marginTop: '8px' }}>
                                Max Views
                              </Label>
                              <NumberFormat
                                disabled
                                thousandSeparator
                                prefix=""
                                name="what"
                                value={creatorYouTubeV30Max}
                                className="form-control"
                                inputMode="numeric"
                                allowEmptyFormatting={false}
                                decimalScale={0}
                                onValueChange={() => {}}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <div className="w-100 research-watchlists">
                                <Label className="bold-text">
                                  Include Categories
                                </Label>
                                <Select
                                  isDisabled
                                  isMulti
                                  isClearable
                                  placeholder="Select Categories..."
                                  value={creatorIncludeYouTubeCategories}
                                  options={[creatorIncludeYouTubeCategories]}
                                  onChange={() => {}}
                                  styles={colourStyles}
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="w-100 research-watchlists">
                                <Label className="bold-text">
                                  Exclude Categories
                                </Label>
                                <Select
                                  isDisabled
                                  isMulti
                                  isClearable
                                  placeholder="Select Categories..."
                                  value={creatorExcludeYouTubeCategories}
                                  options={[creatorExcludeYouTubeCategories]}
                                  onChange={() => {}}
                                  styles={colourStyles}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Label className="bold-text" style={{ marginTop: '16px' }}>
                            Facebook Filters
                          </Label>
                          <hr style={{ marginTop: '0', border: '1px solid #3f5891' }} />
                          <Row>
                            <Col md={6}>
                              <Label className="bold-text">
                                Audience Age
                              </Label>
                              <div
                                className="w-100"
                                style={{ padding: '0 15px' }}
                              >
                                <Label style={{ marginLeft: '-15px' }}>
                                  {`Between Ages: ${creatorFacebookAudienceAgeRange[0] === 60 ? '55+' : creatorFacebookAudienceAgeRange[0]}-${creatorFacebookAudienceAgeRange[1] === 60 ? '55+' : creatorFacebookAudienceAgeRange[1]}yrs`}
                                </Label>
                                <Slider
                                  disabled
                                  min={13}
                                  max={60}
                                  value={creatorFacebookAudienceAgeRange}
                                  onChange={() => {}}
                                  marks={[
                                    { value: 13, label: '13' },
                                    { value: 18, label: '18' },
                                    { value: 25, label: '25' },
                                    { value: 35, label: '35' },
                                    { value: 45, label: '45' },
                                    { value: 55, label: '55' },
                                    { value: 60, label: '55+' },
                                  ]}
                                  step={null}
                                  valueLabelDisplay="auto"
                                  valueLabelFormat={(value) => {
                                    if (value === 60) {
                                      return '55+';
                                    } return `${value}`;
                                  }}
                                />
                                <Label style={{ marginLeft: '-15px' }}>
                                  {`Age Range > ${creatorFacebookAudienceMinAgeRange}%`}
                                </Label>
                                <Slider
                                  disabled
                                  min={0}
                                  max={100}
                                  value={creatorFacebookAudienceMinAgeRange}
                                  onChange={() => {}}
                                  marks={[
                                    { value: 0, label: '0%' },
                                    { value: 25, label: '25%' },
                                    { value: 50, label: '50%' },
                                    { value: 75, label: '75%' },
                                    { value: 100, label: '100%' },
                                  ]}
                                  step={5}
                                  track="inverted"
                                  valueLabelDisplay="auto"
                                  valueLabelFormat={value => `${value}%`}
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <Label className="bold-text">
                                Audience Location
                              </Label>
                              <div className="w-100 research-watchlists">
                                <Label>
                                  Country
                                </Label>
                                <Select
                                  isDisabled
                                  isClearable
                                  placeholder="Select Country..."
                                  value={creatorFacebookAudienceLocationOption}
                                  options={[creatorFacebookAudienceLocationOption]}
                                  onChange={() => {}}
                                  styles={colourStyles}
                                />
                                {creatorFacebookAudienceLocationOption && creatorFacebookAudienceLocationOption.label && (
                                  <div
                                    className="w-100"
                                    style={{ padding: '0 15px' }}
                                  >
                                    <Label style={{ marginLeft: '-15px', marginTop: '16px' }}>
                                      {`${creatorFacebookAudienceLocationOption.label} > ${creatorFacebookAudienceLocationMin}%`}
                                    </Label>
                                    <Slider
                                      disabled
                                      min={0}
                                      max={100}
                                      value={creatorFacebookAudienceLocationMin}
                                      onChange={() => {}}
                                      marks={[
                                        { value: 0, label: '0%' },
                                        { value: 25, label: '25%' },
                                        { value: 50, label: '50%' },
                                        { value: 75, label: '75%' },
                                        { value: 100, label: '100%' },
                                      ]}
                                      step={5}
                                      track="inverted"
                                      valueLabelDisplay="auto"
                                      valueLabelFormat={value => `${value}%`}
                                    />
                                  </div>
                                )}
                              </div>
                            </Col>
                            <Col md={2}>
                              <Label className="bold-text">
                                V30
                              </Label>
                              <br />
                              <Label>
                                Min Views
                              </Label>
                              <NumberFormat
                                disabled
                                thousandSeparator
                                prefix=""
                                name="what"
                                value={creatorFacebookV30Min}
                                className="form-control"
                                inputMode="numeric"
                                allowEmptyFormatting={false}
                                fixedDecimalScale
                                decimalScale={0}
                                onValueChange={() => {}}
                              />
                              <Label style={{ marginTop: '16px' }}>
                                Max Views
                              </Label>
                              <NumberFormat
                                disabled
                                thousandSeparator
                                prefix=""
                                name="what"
                                value={creatorFacebookV30Max}
                                className="form-control"
                                inputMode="numeric"
                                allowEmptyFormatting={false}
                                decimalScale={0}
                                onValueChange={() => {}}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                    {activeTypeCheckbox === 'video' && (
                      <>
                        <Row>
                          <Col md={9}>
                            <Row>
                              <Col md={5}>
                                <Row>
                                  <Col md={3}>
                                    <Label className="bold-text" style={{ marginBottom: '14px' }}>
                                      Platforms
                                    </Label>
                                    <FormGroup
                                      style={{ marginBottom: '10px' }}
                                    >
                                      <CheckBoxField
                                        name="youtube"
                                        label="YouTube"
                                        value={selectedVideoPlatforms.youtube}
                                        onChange={() => {}}
                                      />
                                    </FormGroup>
                                    <FormGroup
                                      style={{ marginBottom: '10px' }}
                                    >
                                      <CheckBoxField
                                        name="facebook"
                                        label="Facebook"
                                        value={selectedVideoPlatforms.facebook}
                                        onChange={() => {}}
                                      />
                                    </FormGroup>
                                    <FormGroup
                                      style={{ marginBottom: '10px' }}
                                    >
                                      <CheckBoxField
                                        name="instagram"
                                        label="Instagram"
                                        value={selectedVideoPlatforms.instagram}
                                        onChange={() => {}}
                                      />
                                    </FormGroup>
                                    <FormGroup
                                      style={{ marginBottom: '10px' }}
                                    >
                                      <CheckBoxField
                                        name="twitter"
                                        label="X"
                                        value={selectedVideoPlatforms.twitter}
                                        onChange={() => {}}
                                      />
                                    </FormGroup>
                                    <FormGroup>
                                      <CheckBoxField
                                        name="twitch"
                                        label="Twitch"
                                        value={selectedVideoPlatforms.twitch}
                                        onChange={() => {}}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <Label className="bold-text" style={{ marginBottom: '14px' }}>
                                      Search in...
                                    </Label>
                                    <FormGroup
                                      style={{ marginBottom: '10px' }}
                                    >
                                      <CheckBoxField
                                        name="description"
                                        label="Description"
                                        value={selectedVideoSearchIn.description}
                                        onChange={() => {}}
                                      />
                                    </FormGroup>
                                    <FormGroup
                                      style={{ marginBottom: '10px' }}
                                    >
                                      <CheckBoxField
                                        name="keywords"
                                        label="Keywords"
                                        value={selectedVideoSearchIn.keywords}
                                        onChange={() => {}}
                                      />
                                    </FormGroup>
                                    <FormGroup>
                                      <CheckBoxField
                                        name="topics"
                                        label="Topics"
                                        value={selectedVideoSearchIn.topics}
                                        onChange={() => {}}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6}>
                                    <Label className="bold-text">
                                      Sort Options
                                    </Label>
                                    <br />
                                    <Label>
                                      Sort By
                                    </Label>
                                    <Select
                                      isDisabled
                                      value={videoSortParameter}
                                      options={[videoSortParameter]}
                                      onChange={() => {}}
                                    />
                                    <Label style={{ marginTop: '16px' }}>
                                      Sort Order
                                    </Label>
                                    <Select
                                      isDisabled
                                      value={videoSortType}
                                      options={[videoSortType]}
                                      onChange={() => {}}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={7}>
                                <Row>
                                  <Col md={3}>
                                    <Label className="bold-text">
                                      Views
                                    </Label>
                                    <br />
                                    <Label>
                                      Min Views
                                    </Label>
                                    <NumberFormat
                                      disabled
                                      thousandSeparator
                                      prefix=""
                                      value={videoViewsMin}
                                      className="form-control"
                                      inputMode="numeric"
                                      allowEmptyFormatting={false}
                                      allowNegative={false}
                                      fixedDecimalScale
                                      decimalScale={0}
                                      onValueChange={() => {}}
                                    />
                                    <Label style={{ marginTop: '16px' }}>
                                      Max Views
                                    </Label>
                                    <NumberFormat
                                      disabled
                                      thousandSeparator
                                      prefix=""
                                      value={videoViewsMax}
                                      className="form-control"
                                      inputMode="numeric"
                                      allowEmptyFormatting={false}
                                      allowNegative={false}
                                      decimalScale={0}
                                      onValueChange={() => {}}
                                    />
                                  </Col>
                                  <Col md={3}>
                                    <Label className="bold-text">
                                      Engagements
                                    </Label>
                                    <br />
                                    <Label>
                                      Min Engagements
                                    </Label>
                                    <NumberFormat
                                      disabled
                                      thousandSeparator
                                      prefix=""
                                      value={videoEngagementsMin}
                                      className="form-control"
                                      inputMode="numeric"
                                      allowEmptyFormatting={false}
                                      fixedDecimalScale
                                      decimalScale={0}
                                      onValueChange={() => {}}
                                    />
                                    <Label style={{ marginTop: '16px' }}>
                                      Max Engagements
                                    </Label>
                                    <NumberFormat
                                      disabled
                                      thousandSeparator
                                      prefix=""
                                      value={videoEngagementsMax}
                                      className="form-control"
                                      inputMode="numeric"
                                      allowEmptyFormatting={false}
                                      decimalScale={0}
                                      onValueChange={() => {}}
                                    />
                                  </Col>
                                  <Col md={3}>
                                    <Label className="bold-text">
                                      Video Duration
                                      <Tooltip title="Duration specified in seconds">
                                        <IconButton
                                          size="small"
                                          className="material-table__toolbar-button"
                                        >
                                          <InfoOutlineIcon size="14" />
                                        </IconButton>
                                      </Tooltip>
                                    </Label>
                                    <br />
                                    <Label>
                                      Min Duration
                                    </Label>
                                    <NumberFormat
                                      disabled
                                      thousandSeparator
                                      prefix=""
                                      value={videoDurationMin}
                                      className="form-control"
                                      inputMode="numeric"
                                      allowEmptyFormatting={false}
                                      fixedDecimalScale
                                      decimalScale={0}
                                      onValueChange={() => {}}
                                    />
                                    <Label style={{ marginTop: '16px' }}>
                                      Max Duration
                                    </Label>
                                    <NumberFormat
                                      disabled
                                      thousandSeparator
                                      prefix=""
                                      value={videoDurationMax}
                                      className="form-control"
                                      inputMode="numeric"
                                      allowEmptyFormatting={false}
                                      decimalScale={0}
                                      onValueChange={() => {}}
                                    />
                                  </Col>
                                  <Col md={3}>
                                    <Label className="bold-text">
                                      Video Upload Date
                                    </Label>
                                    <br />
                                    <Label>
                                      Start Date
                                    </Label>
                                    <DatePicker
                                      disabled
                                      isClearable
                                      selected={videoLastUploadMin}
                                      onChange={() => {}}
                                      dateFormat="MM/dd/yyyy"
                                      dropDownMode="select"
                                      className="form-control"
                                      minDate={minDate}
                                      maxDate={maxDate}
                                    />
                                    <Label style={{ marginTop: '16px' }}>
                                      End Date
                                    </Label>
                                    <DatePicker
                                      disabled
                                      isClearable
                                      selected={videoLastUploadMax}
                                      onChange={() => {}}
                                      dateFormat="MM/dd/yyyy"
                                      dropDownMode="select"
                                      className="form-control"
                                      minDate={minDate}
                                      maxDate={maxDate}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <div className="w-100 research-watchlists">
                                  <Label className="bold-text">
                                    Content Genres
                                  </Label>
                                  <Select
                                    isDisabled
                                    isMulti
                                    name="videoContentGenresSelect"
                                    isClearable
                                    value={videoContentGenres}
                                    options={[videoContentGenres]}
                                    onChange={() => {}}
                                    placeholder="Select Genres..."
                                    styles={colourStyles}
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="w-100 research-watchlists">
                                  <Label className="bold-text">
                                    Include Categories
                                  </Label>
                                  <Select
                                    isDisabled
                                    isMulti
                                    name="videoIncludeCategoriesSelect"
                                    isClearable
                                    value={videoIncludeCategories}
                                    options={[videoIncludeCategories]}
                                    onChange={() => {}}
                                    placeholder="Select Categories..."
                                    styles={colourStyles}
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="w-100 research-watchlists">
                                  <Label className="bold-text">
                                    Exclude Categories
                                  </Label>
                                  <Select
                                    isDisabled
                                    isMulti
                                    name="videoExcludeCategoriesSelect"
                                    isClearable
                                    value={videoExcludeCategories}
                                    options={[videoExcludeCategories]}
                                    onChange={() => {}}
                                    placeholder="Select Categories..."
                                    styles={colourStyles}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={3}>
                            <div className="w-100 research-watchlists">
                              <Label className="bold-text">
                                Video Languages
                              </Label>
                              <Select
                                isDisabled
                                isMulti
                                name="videoLanguagesSelect"
                                isClearable
                                value={videoLanguages}
                                options={[videoLanguages]}
                                onChange={() => {}}
                                placeholder="Select Languages..."
                                styles={colourStyles}
                              />
                              <Label className="bold-text" style={{ marginTop: '16px' }}>
                                Creator Countries
                              </Label>
                              <Select
                                isDisabled
                                isMulti
                                name="videoCreatorCountriesSelect"
                                isClearable
                                value={videoCreatorCountries}
                                options={[videoCreatorCountries]}
                                onChange={() => {}}
                                placeholder="Select Countries..."
                                style={{ maxHeight: '120px' }}
                                styles={colourStyles}
                              />
                              <Label className="bold-text" style={{ marginTop: '16px' }}>
                                Creator Types
                              </Label>
                              <Select
                                isDisabled
                                isMulti
                                name="videoCreatorTypesSelect"
                                isClearable
                                value={videoCreatorTypes}
                                options={[videoCreatorTypes]}
                                onChange={() => {}}
                                placeholder="Select Types..."
                                style={{ maxHeight: '120px' }}
                                styles={colourStyles}
                              />
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                    {activeTypeCheckbox === 'podcast' && (
                      <Row>
                        <Col md={1}>
                          <Label className="bold-text" style={{ marginBottom: '14px' }}>
                            Search in...
                          </Label>
                          <FormGroup
                            style={{ marginBottom: '10px' }}
                          >
                            <CheckBoxField
                              name="title"
                              label="Title"
                              value={podcastOnlyIn.title}
                              onChange={() => {}}
                            />
                          </FormGroup>
                          <FormGroup
                            style={{ marginBottom: '10px' }}
                          >
                            <CheckBoxField
                              name="description"
                              label="Description"
                              value={podcastOnlyIn.description}
                              onChange={() => {}}
                            />
                          </FormGroup>
                          <FormGroup
                            style={{ marginBottom: '10px' }}
                          >
                            <CheckBoxField
                              name="author"
                              label="Author"
                              value={podcastOnlyIn.author}
                              onChange={() => {}}
                            />
                          </FormGroup>
                          <FormGroup>
                            <CheckBoxField
                              name="audio"
                              label="Audio"
                              value={podcastOnlyIn.audio}
                              onChange={() => {}}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <div className="w-100 research-watchlists">
                              <Label className="bold-text">
                                Type
                              </Label>
                              <Select
                                isDisabled
                                name="podcastTypeSelect"
                                value={podcastType}
                                options={[podcastType]}
                                onChange={() => {}}
                                styles={colourStyles}
                              />
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <div className="w-100 research-watchlists">
                              <Label className="bold-text">
                                Sort
                              </Label>
                              <Select
                                isDisabled
                                name="podcastSortSelect"
                                value={podcastSort}
                                options={[podcastSort]}
                                onChange={() => {}}
                                styles={colourStyles}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <div className="w-100 research-watchlists">
                              <Label className="bold-text">
                                Language
                              </Label>
                              <Select
                                isDisabled
                                name="podcastLanguageSelect"
                                isClearable
                                value={podcastLanguage}
                                options={[podcastLanguage]}
                                onChange={() => {}}
                                placeholder="Select Language..."
                                style={{ maxHeight: '120px' }}
                                styles={colourStyles}
                              />
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <div className="w-100 research-watchlists">
                              <Label className="bold-text">
                                Region
                              </Label>
                              <Select
                                isDisabled
                                name="podcastRegionSelect"
                                isClearable
                                value={podcastRegion}
                                options={[podcastRegion]}
                                onChange={() => {}}
                                placeholder="Select Region..."
                                style={{ maxHeight: '120px' }}
                                styles={colourStyles}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <Label className="bold-text">
                              Published After
                            </Label>
                            <DatePicker
                              disabled
                              isClearable
                              selected={podcastPublishedAfter}
                              onChange={() => {}}
                              dateFormat="MM/dd/yyyy"
                              dropDownMode="select"
                              className="form-control"
                              maxDate={maxDate}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="bold-text">
                              Published Before
                            </Label>
                            <DatePicker
                              disabled
                              isClearable
                              selected={podcastPublishedBefore}
                              onChange={() => {}}
                              dateFormat="MM/dd/yyyy"
                              dropDownMode="select"
                              className="form-control"
                              maxDate={maxDate}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={5}>
                          <FormGroup>
                            <div className="w-100 research-watchlists">
                              <Label className="bold-text">
                                Genre(s)
                              </Label>
                              <Select
                                isDisabled
                                isMulti
                                name="podcastGenresSelect"
                                isClearable
                                value={podcastGenres}
                                options={[podcastGenres]}
                                onChange={() => {}}
                                placeholder="Select Genres..."
                                style={{ maxHeight: '120px' }}
                                styles={colourStyles}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                  </ThemeProvider>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};

ViewQueryModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  search: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect()(ViewQueryModal);
