import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import SelectAsync from 'react-select/async';
import { axios } from 'ApiClient';
import debounce from 'lodash.debounce';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  ButtonToolbar,
  Button,
  Spinner,
} from 'reactstrap';
import withNotice from '../../../../../App/store/with-notice';
import DropZoneField from '../../../../../../shared/components/form/DropZone';
import { envelopeName } from '../../../../../../shared/helpers/DocuSign';

class UploadModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    influencer: PropTypes.objectOf(PropTypes.any).isRequired,
    // deals: PropTypes.arrayOf(PropTypes.any).isRequired,
    addNotice: PropTypes.func.isRequired,
    reloadAgreements: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.fetchData = debounce(this.fetchData, 1000);
    this.state = {
      loading: false,
      optDeals: [],
      dealId: '',
      selectedDoc: null,
      documents: [],
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      optDeals: nextProps.deals.map(row => ({
        value: row.id,
        label: row.params.dealname || row.id,
      })).sort((a, b) => ((a.label.toLowerCase() < b.label.toLowerCase()) ? -1 : 1)),
    });
  }

  fetchData = (query, callback) => {
    if (query?.length < 2) {
      callback([]);
    } else {
      axios.get(`/api/portal/upload-agreement-options?query=${encodeURIComponent(query)}`)
        .then(response => callback(response.data.success ? response.data.documents.map(row => ({
          value: row.envelopeId,
          label: envelopeName(row),
        })).sort((a, b) => ((a.label.toLowerCase() < b.label.toLowerCase()) ? -1 : 1)) : []));
    }
  }

  close = () => {
    const { toggle } = this.props;
    this.setState({
      dealId: '',
      selectedDoc: null,
      documents: [],
    });
    toggle(false);
  }

  submit = () => {
    const { influencer, reloadAgreements } = this.props;
    const {
      dealId,
      selectedDoc,
      documents,
    } = this.state;
    this.setState({ loading: true });
    const data = new FormData();
    data.append('influencerId', influencer.id);
    data.append('dealId', dealId);
    data.append('envelopeId', selectedDoc?.value);
    data.append('document', documents[0]);
    axios({
      method: 'post',
      url: '/api/portal/upload-agreement',
      headers: { 'Content-Type': 'multipart/form-data' },
      data,
    }).then((response) => {
      this.setState({ loading: false });
      if (response.data.success) {
        this.props.addNotice({
          message: 'Agreement has been uploaded successfully.',
          type: 'success',
        });
        reloadAgreements();
      } else {
        this.props.addNotice({
          message: response.data.error,
          type: 'error',
        });
      }
      this.close();
    }).catch((error) => {
      this.setState({ loading: false });
      this.props.addNotice({
        message: error.message,
        type: 'error',
      });
    });
  }

  render() {
    const { isOpen } = this.props;
    const {
      loading,
      optDeals,
      dealId,
      selectedDoc,
      documents,
    } = this.state;
    return (
      <Modal isOpen={isOpen} toggle={this.close} size="sm" className=" theme-light">
        <ModalHeader toggle={this.close} tag="h4">
          Upload Service Agreement
        </ModalHeader>
        <ModalBody>
          {loading && (<div className="text-center"><Spinner color="primary" size="lg" /></div>)}
          {!loading && (
          <div>
            <Row style={{ borderBottom: '1px solid #dee2e6', marginBottom: '20px' }}>
              <Col>
                <FormGroup>
                  <Label>Select Deal</Label>
                  <Select
                    value={optDeals.filter(option => option.value === dealId)}
                    options={optDeals}
                    onChange={option => this.setState({ dealId: option.value })}
                  />
                  <small style={{ color: '#777' }}>Deal associated with the Service Agreement (Required)</small>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4>Select File To Upload</h4>
                <div style={{ color: '#777' }}>Choose a document or upload a PDF file</div>
              </Col>
            </Row>
            <Row>
              <Col className="pt-3">
                <FormGroup>
                  <Label>Document</Label>
                  <SelectAsync
                    cacheOptions
                    value={selectedDoc}
                    defaultOptions
                    loadOptions={this.fetchData}
                    placeholder="Search Document..."
                    onChange={option => this.setState({ selectedDoc: option })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="text-center pt-3 pb-4">
                OR
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <DropZoneField
                    input={{
                      name: 'documents',
                      id: 'documents',
                      value: documents,
                      accept: { 'application/pdf': ['.pdf'] },
                      onChange: files => this.setState({ documents: files }),
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        )}
        </ModalBody>
        <ModalFooter className="modal__footer">
          <ButtonToolbar className="form__button-toolbar mt-4">
            <Button
              className="btn-sm mr-2"
              color="primary"
              onClick={this.submit}
              disabled={!(dealId && (selectedDoc?.value || (documents.length > 0)))}
            >
              Save
            </Button>
            <Button
              className="btn-sm"
              color="danger"
              onClick={this.close}
            >
              Cancel
            </Button>
          </ButtonToolbar>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withNotice(UploadModal);
