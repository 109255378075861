import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import {
  ButtonToolbar, Col,
  Modal, ModalBody, ModalHeader,
  Row, Button, FormGroup,
} from 'reactstrap';
import { components } from 'react-select';
import { useSelector } from 'react-redux';
import WVFormatter from '@/shared/helpers/WVFormatter';
import RadioButton from '@/shared/components/RadioButton';
import Hook from '@/shared/hooks';
import AsyncSelect from "react-select/async";

const modalId = 'assignHubspotAgentModal';

const notAssigned = {
  value: null,
  label: 'Not Assigned',
  record: { params: { _id: null, first_name: 'Not Assigned', last_name: '' } },
};

const formatCreatorOptionLabel = ({ record }) => (
  <div style={{ display: 'flex' }}>
    <div>{record?.params.first_name} {record?.params.last_name}</div>
    <div style={{ color: '#888', marginLeft: '10px' }}>
      {record?.params.email}
    </div>
  </div>
);

const creatorOption = (props) => {
  const { record } = props.data;
  return (
    <components.Option {...props} >
      <div>
        <div className="bold-text">{record.params.first_name} {record.params.last_name}</div>
        <div style={{ color: '#888', fontSize: '13px' }}>
          {record.params.email}
        </div>
      </div>
    </components.Option>
  );
};

const AssignAgentModal = ({
  setVersion,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(notAssigned);
  const [includeAll, setIncludeAll] = useState(false);

  const loadOptions = async (inputValue) => {
    const response = await axios.get(`/api/resources/AgentUser/actions/list?filters.fullSearchName=${encodeURIComponent(inputValue || '')}&perPage=20&page=1&direction=desc&sortBy=createdAt`);
    if (response.data.error) {
      return [];
    }
    // console.log(response.data.records.map(agent => ({ value: agent.id, label: agent.params.email })));
    const options =  response.data.records.map(agent => ({ value: agent.id, label: agent.params.email, record: agent }));
    options.unshift(notAssigned);
    return options;
  };

  const total = useSelector((state) => state.table.total);
  const prospectIds = useSelector((state) => state.table.selectedRecordsIds);
  const { data: dataModal, showModal: isOpen, close } = Hook.useModalHook(modalId);
  const { showError, showSuccess } = Hook.useNoticeHook();
  if (!dataModal) return false;
  const closeFunc = () => {
    setSelectedAgent(notAssigned);
    setIncludeAll(false);
    close();
  };

  // SUBMIT Data
  const submit = async () => {
    setLoading(true);
    const query = new URLSearchParams(window.location.search);
    // console.log('Submit');
    try {
      const response = await axios({
        method: 'post',
        url: '/api/portal/prospects/post-update-many',
        data: {
          params: { 'agent': selectedAgent.value },
          ids: prospectIds,
          includeAll,
          query: query.toString(),
        },
      })
      if (response.data.success) {
        setLoading(false);
        setVersion((new Date()).toString());
        closeFunc();
        showSuccess('Successfully updated Agent');
      }
      if (!response.data.success) {
        showError(response.data.error || 'Error updating Agent');
      }
    } catch (e) {
      showError(e.message);
    }

    return true;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeFunc()}
      style={{ minWidth: '425px' }}
    >
      <ModalHeader toggle={() => closeFunc()} tag="h4" className="modal-title-statistics">Assign Agent</ModalHeader>
      <ModalBody>
        {prospectIds.length !== total && (
          <>
            <div>{`You have selected ${prospectIds.length} Lead${prospectIds.length !== 1 ? 's' : ''} to assign to Agent`}</div>
            <div className="theme-light radio-in-prospects">
              {`Would you like to select all ${WVFormatter.formatIntNumber(total)} Leads?`}<br />
              <RadioButton
                name="action"
                label="&nbsp;Yes"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="includeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
              <RadioButton
                name="action"
                label="&nbsp;No"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="notIncludeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
            </div>
          </>
        )}
        <Row>
          <Col>
            <FormGroup>
              <AsyncSelect
                className='w-100'
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions
                value={selectedAgent}
                onChange={setSelectedAgent}
                placeholder="Select an agent"
                isDisabled={loading}
                menuPlacement="auto"
                formatOptionLabel={formatCreatorOptionLabel}
                components={{ Option: creatorOption }}
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_ok btn-sm" color="primary" onClick={submit} disabled={loading}>
          {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
          <span>Save</span>
        </Button>&nbsp;&nbsp;
        <Button className="modal_cancel btn-sm" color="danger" onClick={() => closeFunc()}>Cancel</Button>
      </ButtonToolbar>
    </Modal>
  );
};

AssignAgentModal.propTypes = {
  // adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  setVersion: PropTypes.func.isRequired,
};

export default AssignAgentModal;
