import React from 'react';
import { connect } from 'react-redux';
import { FormGroup, Label, Input, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { CKEditor } from 'ckeditor4-react';
import { uid } from 'react-uid';
import StyledButton from '../../../../shared/components/components/ui/styled-button';
import withNotice from '../../../App/store/with-notice';
import Breadcrumbs from '../../../../shared/components/BreadCrumbsResource';
import ScorecardsQuestionCategory from './ScorecardsQuestionCategory';
import ScorecardsQuestionForm from './ScorecardsQuestionForm';
import { fetchScorecards,
  updateScorecardItem,
  errorInScorecardItem,
  clearScorecardItemErrors,
  saveScorecardItem } from '../../../../redux/reducers/admin/ScoringReducer';


const mapTypeArray = {
  YouTube: 'YouTube',
  'YouTube Shorts': 'YouTube Shorts',
  'YouTube Description Text': 'YouTube Description Text',
  Podcast: 'Podcast',
  'Podcast Script Text': 'Podcast Script Text',
  'Instagram Story': 'Instagram Story',
  'Instagram Post': 'Instagram Post',
  'Instagram Reels': 'Instagram Reels',
  'Instagram TV Video': 'Instagram TV Video',
  TikTok: 'TikTok',
  'Facebook Post': 'Facebook Post',
  'Facebook Watch Video': 'Facebook Watch Video',
  'Twitter Post': 'X Post',
  LinkTree: 'LinkTree',
  Website: 'Website',
  Email: 'Email',
  Text: 'Text',
};

class ScorecardsForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      hideCategoriesblock: false,
    };
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.resourceId) {
      this.fetchData(this.props.match.params.resourceId);
    } else {
      this.fetchData(false);
    }
  }
  static requiredData = [
    'type', 'name', 'description',
  ];

  fetchData(scorecardId) {
    const { dispatch } = this.props;
    dispatch(fetchScorecards(scorecardId));
    return null;
  }

  handleChange(property, value) {
    const { dispatch } = this.props;
    dispatch(updateScorecardItem(property, value));
  }

  handleInputChange(name, event) {
    if (event.target.type === 'checkbox') {
      this.handleChange(name, event.target.checked);
    } else {
      this.handleChange(name, event.target.value);
    }
  }

  handleTypeChange(value) {
    if (value.value === 'Text') {
      this.setState({ hideCategoriesblock: true });
    } else {
      this.setState({ hideCategoriesblock: false });
    }
    this.handleChange('type', value.value);
  }

  handleDescriptionChange(event) {
    const { editor } = event;
    this.handleChange('description', editor.getData());
  }

  handleSubmit(event) {
    event.preventDefault();
    const {
      scorecardItem,
      dispatch,
    } = this.props;
    dispatch(clearScorecardItemErrors());
    let isValid = true;
    this.constructor.requiredData.forEach((item) => {
      if (!scorecardItem.params[item]) {
        isValid = false;
        dispatch(errorInScorecardItem(item, 'This field is required'));
      }
    });
    if (!isValid) {
      return true;
    }
    dispatch(saveScorecardItem(scorecardItem.params)).then(() => {
      window.location.href = '/scorecard/scorecard-dashboard';
    });
    return true;
  }
  render() {
    const {
      resources,
      loading,
      loadingSaveForm,
      scorecardItem,
    } = this.props;
    const { hideCategoriesblock } = this.state;
    const mapType = Object.keys(mapTypeArray).map(key => ({ value: key, label: mapTypeArray[key] }));
    const isNewScorecard = scorecardItem !== null && typeof scorecardItem.params !== 'undefined' && typeof scorecardItem.params._id === 'undefined';
    return (
      <div className="dashboard container">
        <Breadcrumbs resource={resources.find(r => r.id === 'Scorecard')} actionName="list" isBackButton />
        <div className="card">
          <div className="card-body">
            {loading &&
            (
              <div className="text-center"><Spinner color="primary" size="lg" /></div>
            )
            }
            {!loading && scorecardItem !== null &&
            (
              <form onSubmit={this.handleSubmit}>
                {!isNewScorecard &&
                    (<h3>Edit Scorecard</h3>)
                }
                {isNewScorecard &&
                (<h3>Add New Scorecard</h3>)
                }
                <br />
                <FormGroup>
                  <Label>Type</Label>
                  <Select
                    value={mapType.filter(option => typeof scorecardItem.params !== 'undefined' && typeof scorecardItem.params.type !== 'undefined' && option.value === scorecardItem.params.type)}
                    options={mapType}
                    onChange={this.handleTypeChange}
                    isDisabled={!isNewScorecard}
                    placeholder="Select Type"
                  />
                  {scorecardItem.errors.type && (<span className="text-danger">{scorecardItem.errors.type}</span>)}
                </FormGroup>
                <FormGroup>
                  <Label>Name</Label>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    onChange={e => this.handleInputChange('name', e)}
                    value={typeof scorecardItem.params !== 'undefined' && typeof scorecardItem.params.name !== 'undefined' && scorecardItem.params.name !== null ? scorecardItem.params.name : ''}
                    invalid={!!scorecardItem.errors.name}
                  />
                  {scorecardItem.errors.name && (<span className="text-danger">{scorecardItem.errors.name}</span>)}
                </FormGroup>
                {!isNewScorecard && (
                  <FormGroup>
                    <Label>Version</Label>
                    <Input
                      type="text"
                      id="version"
                      name="version"
                      disabled
                      // onChange={e => this.handleInputChange('version', e)}
                      value={typeof scorecardItem.params !== 'undefined' && typeof scorecardItem.params.version !== 'undefined' && scorecardItem.params.version !== null ? scorecardItem.params.version : ''}
                      invalid={!!scorecardItem.errors.version}
                    />
                    {scorecardItem.errors.version && (<span className="text-danger">{scorecardItem.errors.version}</span>)}
                  </FormGroup>
                )}
                <FormGroup>
                  <Label>Description</Label>
                  <CKEditor
                    config={{versionCheck: false}}
                    id="bodyEditor"
                    name="description"
                    initData={typeof scorecardItem.params !== 'undefined' && typeof scorecardItem.params.description !== 'undefined' && scorecardItem.params.description !== null ? scorecardItem.params.description : ''}
                    onChange={this.handleDescriptionChange}
                  />
                  {scorecardItem.errors.description && (<span className="text-danger">{scorecardItem.errors.description}</span>)}
                </FormGroup>
                {scorecardItem.params && scorecardItem.params.questions && scorecardItem.params.questions.map(questionCategory => (<ScorecardsQuestionCategory key={uid(questionCategory)} questionCategory={questionCategory} />))}
                {isNewScorecard && !hideCategoriesblock && (<ScorecardsQuestionForm />)}
                <StyledButton
                  type="submit"
                  className="is-primary"
                  disabled={!!loadingSaveForm}
                >
                  <i className="icomoon-save" />
                  <span className="btn-text">Save</span>
                  {loadingSaveForm &&
                  (<>&nbsp;&nbsp;<Spinner color="primary" size="sm" /></>)
                  }
                </StyledButton>
              </form>
            )
            }
          </div>
        </div>
      </div>
    );
  }
}

ScorecardsForm.propTypes = {
  loading: PropTypes.bool,
  loadingSaveForm: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  resources: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  scorecardItem: PropTypes.objectOf(PropTypes.any),
};

ScorecardsForm.defaultProps = {
  loading: null,
  loadingSaveForm: null,
  scorecardItem: null,
};
const mapStateToProps = state => ({
  resources: state.resources,
  loading: state.score.loading.fetchScorecardContent,
  loadingSaveForm: state.score.loading.saveScorecardContent,
  scorecardItem: state.score.scorecardItem,
});


export default withNotice(connect(mapStateToProps)(ScorecardsForm));
