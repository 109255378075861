import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import { Row, Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
// import Panel from '../../../../shared/components/Panel';
import renderIntervalDatePickerField from '../../../../shared/components/form/IntervalDatePicker';
import TopRevenuePanel from './panels/TopRevenuePanel';
import TopClicksPanel from './panels/TopClicksPanel';
import TopConversionsPanel from './panels/TopConversionsPanel';
import TopNewWinners from './panels/TopNewWinners';
import { getSelectNewDates } from '../../../../redux/reducers/admin/DashboardReducer';
import './tableFormat.css';

class AdminDashboard extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);

    this.state = {
      dates: {
        from: moment().startOf('month').unix(),
        to: moment().endOf('day').unix(),
      },
      showDatepickers: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getSelectNewDates(this.state.dates.from, this.state.dates.to));
  }
  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (prevState.dates.from !== this.state.dates.from || prevState.dates.to !== this.state.dates.to) {
      dispatch(getSelectNewDates(this.state.dates.from, this.state.dates.to));
    }
  }
  // METHODS
  handleDateSelected = (event) => {
    // const { dispatch } = this.props;
    let dateFrom;
    let dateTo;
    let showDatepickers = false;
    switch (event.target.value) {
      case 'yesterday':
        dateFrom = moment().subtract(1, 'd').set({
          hour: 0, minute: 0, second: 0, millisecond: 0,
        }).unix();
        dateTo = moment().subtract(1, 'd').set({
          hour: 23, minute: 59, second: 59, millisecond: 999,
        }).unix();
        break;
      case 'last7':
        dateFrom = moment().subtract(7, 'day').startOf('day').unix();
        dateTo = moment().subtract(1, 'day').endOf('day').unix();
        break;
      case 'last30':
        dateFrom = moment().subtract(30, 'd').set({
          hour: 0, minute: 0, second: 0, millisecond: 0,
        }).unix();
        dateTo = moment().set({
          hour: 23, minute: 59, second: 59, millisecond: 999,
        }).unix();
        break;
      case 'lastMonth':
        dateFrom = moment().subtract(1, 'month').startOf('month').unix();
        dateTo = moment().subtract(1, 'month').endOf('month').unix();
        break;
      case 'currMonth':
        dateFrom = moment().startOf('month').unix();
        dateTo = moment().endOf('day').unix();
        break;
      case 'custom':
        showDatepickers = true;
        dateFrom = this.state.dates.from;
        dateTo = this.state.dates.to;
        break;
      default:
        dateFrom = moment().set({
          hour: 0, minute: 0, second: 0, millisecond: 0,
        }).unix();
        dateTo = moment().unix();
        break;
    }

    this.setState({
      showDatepickers,
      dates: {
        from: dateFrom,
        to: dateTo,
      },
    });

    // dispatch(getSelectNewDates(dateFrom, dateTo));
  }

  handleDatepickerChange = (event) => {
    // const { dispatch } = this.props;
    let dateFrom = false; let dateTo = false;
    if (typeof event.start !== 'undefined') {
      dateFrom = moment(event.start).set({
        hour: 0, minute: 0, second: 0, millisecond: 0,
      }).unix();
    }
    if (typeof event.end !== 'undefined') {
      dateTo = moment(event.end).set({
        hour: 23, minute: 59, second: 59, millisecond: 999,
      }).unix();
    }
    if (dateFrom && dateTo) {
      this.setState({
        dates: {
          from: dateFrom,
          to: dateTo,
        },
      });
      // dispatch(getSelectNewDates(dateFrom, dateTo));
    }
  }
  //

  render() {
    const { from, to } = this.state.dates;
    let dateText = '';
    if (
      moment.unix(from).format('M') === moment.unix(to).format('M') &&
      moment.unix(from).format('D') === moment.unix(to).format('D') &&
      moment.unix(from).format('YYYY') === moment.unix(to).format('YYYY')) {
      dateText = moment.unix(from).format('MMM D, YYYY');
    } else {
      dateText = `${moment.unix(from).format('MMM D, YYYY')} - ${moment.unix(to).format('MMM D, YYYY')}`;
    }
    return (
      <div id="adminDashBoard" style={{ width: '100%' }}>
        <Card>
          <CardBody className="panel__body">
            <div className="panel__content">
              <Row>
                <Col xl="3" />
                <Col xl="4" lg="7" className="summary-div">
                  <select className="form-control" onChange={this.handleDateSelected}>
                    <option value="currMonth">Current Month</option>
                    <option value="lastMonth">Last Month</option>
                    <option value="today">Today</option>  
                    <option value="yesterday">Yesterday</option>
                    <option value="last7">Last 7 Days</option>
                    <option value="last30">Last 30 days</option>
                    <option value="custom">Custom</option>
                  </select>
                  {this.state.showDatepickers ?
                    <div className="dpickers-div">
                      <Field
                        name="interval_date"
                        component={renderIntervalDatePickerField}
                        onChange={this.handleDatepickerChange}
                      />
                    </div>
                  : ''}
                </Col>
                <Col xl="5" lg="5" className="creator-date">{dateText}</Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <TopNewWinners />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <TopRevenuePanel />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <TopClicksPanel />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <TopConversionsPanel />
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}


export default reduxForm({
  form: 'dashboard_form',
})(AdminDashboard);
