import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MatTableList from '../../../../../../../shared/tables/materialTable/TableRedux';
import ContextMenuInList from './ContextMenuInList';
import NameInList from './NameInList';
import SelectResource from '../../../../../../../shared/components/table/cell/SelectResource';
import CopyToClipboard from '@/shared/components/CopyToClipboard';
import fetchResourceData from '../../../../../../../shared/helpers/WVRequest';

const Table = React.memo(({
  setAgenciesSuggestions,
}) => {
  const [loadingAgencies, setLoadingAgencies] = useState(false);
  const [agencyOptions, setAgencyOptions] = useState([]);

  useEffect(() => {
    setLoadingAgencies(true);
    fetchResourceData('Agency', {}, 'perPage=5000&sortBy=agencyName&direction=asc').then((res) => {
      setAgencyOptions(res.records.map(r => ({ value: r.id.toString(), label: r.params.agencyName })));
      setAgenciesSuggestions([
        { id: 'null', name: 'Not Assigned' },
        ...res.records.map(r => ({ id: r.id.toString(), name: r.params.agencyName })),
      ]);
      setLoadingAgencies(false);
    });
  }, []);

  return (
    <MatTableList
      useFlex
      enableWarning={false}
      notFoundProps={{ title: 'No Agents Users Found' }}
      useUnflatten
      customColumns={[
        {
          width: 150,
          id: 'fullName',
          Header: () => 'Name',
          accessor: record => (record?.params?.fullName || ''),
          Cell : (p) => <NameInList record={p.row.original} />,
          className: 'd-flex align-items-center',
        },
        {
          id: 'companyName',
          Header: () => 'Company',
          accessor: record => (record?.params?.companyName || ''),
          Cell : (p) => <CopyToClipboard value={p.value || ''}>{p.value}</CopyToClipboard>,
          className: 'd-flex align-items-center',
          width: 100,
        },
        {
          id: 'agency',
          Header: () => 'Agency',
          accessor: record => (record?.populated?.agency?.params?.agencyName || ''),
          Cell : (p) => <div style={{ width: '90%' }}>
            <SelectResource
              resource="AgentUser"
              record={p.row.original}
              property="agency"
              options={agencyOptions}
              fetching={loadingAgencies}
            />
          </div>,
          className: 'd-flex align-items-center',
          width: 140,
        },
        {
          id: 'email',
          Header: () => 'Email',
          accessor: record => (record?.params?.email || ''),
          Cell : (p) => <CopyToClipboard value={p.value || ''}>{p.value}</CopyToClipboard>,
          className: 'd-flex align-items-center',
          width: 200,
        },
        {
          width: 80,
          id: 'numCreators',
          Header: () => 'No. Creators',
          accessor: record => (record?.params?.numCreators || 0),
          className: 'd-flex align-items-center',
        },
        {
          width: 80,
          id: 'paymentMethod',
          Header: () => 'Payment Method',
          accessor: record => (record?.params?.paymentMethod || ''),
          className: 'd-flex align-items-center',
        },
        {
          Header: 'Tax Form',
          id: 'taxForm',
          accessor: p => p.params?.validTaxForm?.value || false,
          Cell: p => (
            p.value ? 'On File' : 'Missing'
          ),
          className: 'd-flex align-items-center',
          width: 50,
          maxWidth: 50,
        },
        {
          id: 'actions',
          Header: '',
          accessor: () => null,
          Cell: p => (
            <ContextMenuInList
              record={p.row.original}
            />
          ),
          disableSortBy: true,
          className: 'd-flex align-items-center',
          width: 30,
          maxWidth: 30,
        },
      ]}
    />
  );
});

Table.propTypes = {
  setAgenciesSuggestions: PropTypes.func.isRequired,
};

export default Table;
