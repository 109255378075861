import { useState, useEffect } from 'react';
import { axios } from 'ApiClient';

function useDuplicateVanityUrls() {
  const [content, setContent] = useState([]);
  
  useEffect(() => {
    async function fetchData() {
      axios({
        method: 'get',
        url: '/api/portal/redirects/get-duplicate-vanity-urls',
      }).then((response) => {
        console.log('response', response.data);
        if (response?.data?.success && response.data?.duplicateVanityUrls) {
          setContent(response.data.duplicateVanityUrls);
        }
      });
    }

    fetchData();
  }, []);

  return content;
}

export default useDuplicateVanityUrls;
