const {
  formatNumber,
  formatIntNumber,
  formatCurrency,
  formatIntNumberAndAddPercentSign,
  getFormattedDateNoZeroes,
} = require('../WVFormatter');

const getDefaultValue = (value, defaultValue, isTotal, ignores = []) => {
  if ((isTotal && (typeof value === 'undefined')) || ignores.includes(defaultValue)) {
    return '';
  }
  return (typeof defaultValue === 'undefined') ? '' : defaultValue;
};
  
const formatValue = (value, params = {}) => {
  const {
    defaultValue,
    format,
    isTotal,
    ignores,
  } = params;
  if ([null, undefined, '', 0].includes(value)) {
    const res = getDefaultValue(value, defaultValue, isTotal, ignores || []);
    return (res === 0) ? format(res) : res;
  }
  return format(value);
};

const formatRecordValue = (record, property, format) => {
  const { params, isTotal } = record;
  const { defaultValue } = property.custom || {};
  const value = params[property.name];
  return formatValue(value, {
    format,
    defaultValue,
    isTotal,
  });
}

exports.num = ({ record, property }) => formatRecordValue(record, property, formatNumber);
exports.int = ({ record, property }) => formatRecordValue(record, property, formatIntNumber);
exports.currency = ({ record, property }) => formatRecordValue(record, property, formatCurrency);
exports.percentage = ({ record, property }) => formatRecordValue(record, property, formatIntNumberAndAddPercentSign);
exports.date = ({ record, property }) => formatRecordValue(record, property, v => getFormattedDateNoZeroes(v.split('T')[0]));
exports.formatValue = formatValue;
