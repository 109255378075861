import React from 'react';
import { useSelector } from 'react-redux';
import MatTableList from '../../../../../../../shared/tables/materialTable/TableRedux';
import ContextMenuInList from './ContextMenuInList';
import CopyToClipboard from '@/shared/components/CopyToClipboard';
// import WVFormatter from '@/shared/helpers/WVFormatter';
import moment from 'moment';
import RoleInList from './RoleInList';

const Table = React.memo(({ categories, affiliateManagersSuggestions, countryOptions }) => {
  const perPage = useSelector(state => state.tableRedux.perPage);
  console.log('perPage', perPage)
  // const showWhenTeamIs = ['lead'];

  return (
    <MatTableList
      useFlex
      enableWarning={false}
      notFoundProps={{ title: 'No Creators Found' }}
      useUnflatten
      customColumns={[
        {
          id: 'firstName',
          Header: () => 'First Name',
          accessor: record => (record?.params?.firstName || ''),
          Cell : (p) => <CopyToClipboard value={p.value || ''}>{p.value}</CopyToClipboard>,
          width: 150,
          className: 'align-self-center',
        },
        {
          id: 'lastName',
          Header: () => 'Last Name',
          accessor: record => (record?.params?.lastName || ''),
          Cell : (p) => <CopyToClipboard value={p.value || ''}>{p.value}</CopyToClipboard>,
          width: 150,
          className: 'align-self-center',
        },
        {
          id: 'email',
          Header: () => 'Email',
          accessor: record => (record?.params?.email || ''),
          Cell : (p) => <CopyToClipboard value={p.value || ''}>{p.value}</CopyToClipboard>,
          width: 250,
          className: 'align-self-center',
        },
        {
          id: 'role',
          Header: () => 'Role',
          accessor: record => (record?.params?.role || ''),
          Cell: p => (<RoleInList record={p.row.original} updateRecordFunction={p.updateRecordFunction} />),
          width: 200,
          className: 'align-self-center',
        },

        {
          id: 'createdAt',
          Header: () => 'Created At',
          accessor: record => (record?.params?.createdAt || ''),
          width: 150,
          Cell: (p) => p.value ? moment(p.value).format('MM/DD/YYYY h:mma') : '',
          className: 'align-self-center',
        },
        {
          id: 'lastLoginDate',
          Header: () => 'Last Login Date',
          accessor: record => (record?.params?.lastLoginDate || ''),
          width: 150,
          Cell: (p) => p.value ? moment(p.value).format('MM/DD/YYYY h:mma') : '',
          className: 'align-self-center',
        },
        
        {
          id: 'actions',
          Header: () => '',
          accessor: () => null,
          Cell: p => (
            <ContextMenuInList
              record={p.row.original}
            />
          ),
          disableSortBy: true,
          className: 'align-self-center',
          width: 50,
          maxWidth: 50,
        },
      ]}
    />
  );
});

Table.propTypes = {};

export default Table;
