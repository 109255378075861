import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Row, Col, Button, FormGroup, Spinner } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import CheckBoxField from '../../../../shared/components/CheckBox';
import csvHelper from '../../../../shared/helpers/CSVHelper';
import { formatCurrency, formatIntNumber } from '../../../../shared/helpers/WVFormatter';
import ColumnsByPublish from './ColumnsByPublish';

const GenerateByPublishTable = ({
  items,
}) => {
  const [loading, setLoading] = useState(false);
  const [onlyShowMostRecentPublish, setOnlyShowMostRecentPublish] = useState(false);
  const [originalItems, setOriginalItems] = useState(items);
  const [data, setData] = useState(items);

  useEffect(() => {
    setOriginalItems(items);
  }, [items]);

  useEffect(() => {
    setData(originalItems);
  }, [originalItems]);

  useEffect(() => {
    if (onlyShowMostRecentPublish) {
      setLoading(true);
      const filteredObject = originalItems.reduce((acc, cur) => {
        if (cur.creatorBrandKey) {
          const item = acc[cur.creatorBrandKey];
          if (item?.publishDate) {
            if (item.publishDate.isBefore(cur.publishDate)) {
              acc[cur.creatorBrandKey] = cur;
            }
          } else acc[cur.creatorBrandKey] = cur;
        }

        return acc;
      }, {});
      setData(Object.values(filteredObject));
      setLoading(false);
    } else {
      setData(originalItems);
    }
  }, [onlyShowMostRecentPublish]);

  const columns = useMemo(() => ColumnsByPublish(), [data]);

  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 50, 100],
    placeholder: 'Search all fields...',
    sortBy: 'conversionsInTimeframe',
    direction: 'desc',
    useFlex: true,
  };

  const generateCSVReport = (csvArrayColumns) => {
    const data = [csvArrayColumns.map(column => column.label)];
    items.forEach((record) => {
      const itemRow = [];
      csvArrayColumns.forEach((column) => {
        itemRow.push(column.func(record));
      });
      data.push(itemRow);
    });
    if (csvArrayColumns.find(c => c.total)) {
      const totals = [];
      csvArrayColumns.forEach((column, i) => {
        if (column.total === true) totals[i] = (data.slice(1)).reduce((sum, row) => Number(row[i] || 0) + sum, 0);
        else if (column.total?.toString().length > 0) totals[i] = column.total;
        else totals[i] = '';
      });
      data.push(totals);
      console.log(totals);
    }
    console.log(data);
    csvHelper.generateCSV('Test To Win Report By Deal.csv', data);
  };

  const generateCSVReportStart = async () => {
    const columns = [
      {
        label: 'First Name',
        func: record => record.creator?.first_name || '',
      },
      {
        label: 'Last Name',
        func: record => record?.creator?.last_name || '',
      },
      {
        label: 'Creator Email',
        func: record => `${record.creator?.email || ''}`,
      },
      {
        label: 'Account Manager',
        func: (record) => {
          if (!record.admin) return '';
          return `${record.admin?.firstName || ''} ${record?.admin?.lastName || ''}`;
        },
      },
      {
        label: 'Brand',
        func: record => `${record.brand || ''}`,
      },
      {
        label: 'New or Existing',
        func: record => `${record.isNew ? 'New' : 'Existing'}`,
      },
      {
        label: 'Deal Name',
        func: record => `${record?.deal?.dealname || ''}`,
      },
      {
        label: 'DealType',
        func: record => `${record?.typeOfDeal || ''}`,
      },
      {
        label: 'Publish Date',
        func: record => (record?.publishDate ? moment.tz(moment.utc(record.publishDate).format('YYYY-MM-DD'), 'America/New_York').format('MM/DD/YYYY') : ''),
      },
      {
        label: 'Days Since/Between Publish',
        func: record => {
          if (record?.publishDate) {
            let nextPublishDate = moment.utc();
            if (record?.nextDealFirstPublishDate) {
              nextPublishDate = moment.utc(record.nextDealFirstPublishDate);
            }
            return moment.tz(nextPublishDate.format('YYYY-MM-DD'), 'America/New_York').diff(moment.tz(moment.utc(record.publishDate).format('YYYY-MM-DD'), 'America/New_York'), 'days')
          }
          return '-';
        },
      },
      {
        label: 'Number of Publishes on Deal',
        func: record => (record?.numberOfPublishesOnDeal || '-'),
      },
      {
        label: 'Spend In Timeframe',
        func: record => formatCurrency(record?.spendInTimeframe || 0),
      },
      {
        label: 'Spend To Date',
        func: record => formatCurrency(record?.spendToDate || 0),
      },
      {
        label: 'Revenue Conversions In Timeframe',
        func: record => formatIntNumber(record?.conversionsInTimeframe || 0, 0),
      },
      {
        label: 'Revenue Conversions To Date',
        func: record => formatIntNumber(record?.conversionsToDate || 0, 0),
      },
      {
        label: 'Predicted Revenue Conversions To Date',
        func: record => {
          if (record?.brand === 'BetterHelp' && record?.typeOfDeal === 'Upfront CPM') {
            return formatIntNumber(record?.projectedConversions || 0, 0);
          }
          return '-';
        },
      },
      {
        label: 'CAC In Timeframe',
        func: record => (record?.positiveCacInTimeframe ? '-' : formatCurrency(record?.cacInTimeframe || 0)),
      },
      {
        label: 'CAC To Date',
        func: record => (record?.positiveCacToDate ? '-' : formatCurrency(record?.cacToDate || 0)),
      },
      {
        label: 'Predicted CAC',
        func: record => {
          if (record?.brand === 'BetterHelp' && record?.typeOfDeal === 'Upfront CPM') {
            return formatCurrency(record?.projectedCac || 0);
          }
          return '-';
        },
      },
      {
        label: 'Gross PAR',
        func: record => {
          if (record?.brand === 'BetterHelp' && record?.typeOfDeal === 'Upfront CPM') {
            return formatCurrency(record?.grossPar || 0);
          }
          return '-';
        },
      },
      {
        label: 'Net PAR',
        func: record => {
          if (record?.brand === 'BetterHelp' && record?.typeOfDeal === 'Upfront CPM') {
            return formatCurrency(record?.netPar || 0);
          }
          return '-';
        },
      },
      {
        label: 'Creator Profile URL',
        func: record => `${window.location.origin}/influencer/profile/${record?.creator?._id}`,
      },
      {
        label: 'HubSpot Deal URL',
        func: record => `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/deal/${record?.deal?.hs_object_id}/`,
      },
      {
        label: 'HubSpot Contact URL',
        func: record => `https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${record?.creator?.hsContactVid}/`,
      },
      {
        label: 'YouTube Channel URL',
        func: record => record?.deal?.dealTerms?.youtube_target_url || record?.creator?.mediaChannels?.youtube_channel_url || '',
      },
      {
        label: 'Category',
        func: (record) => {
          if (record?.creator?.prospects?.length > 0) {
            const prospectsCategoriesSet = record.creator.prospects.reduce((acc, cur) => {
              if (cur?.category?.value) acc.add(cur.category.value);
              return acc;
            }, new Set());
            if (prospectsCategoriesSet.size > 0) {
              return `${[...prospectsCategoriesSet].join(', ')}`;
            }
            if (record?.mediaContentInTimeframe?.length > 0) {
              return record.mediaContentInTimeframe.reduce((latest, current) => {
                return new Date(current.publishDate) > new Date(latest.publishDate) ? current : latest;
              })?.category || '';
            }
            return '';
          }
        },
      },
    ];
    generateCSVReport(columns);
  };

  return (
    <div>
      <Row key="containerByDeals">
        <Col className="mt-3">
          <FormGroup
            style={{
              position: 'absolute',
              top: '7px',
              right: '210px',
            }}
          >
            <CheckBoxField
              name="onlyShowMostRecentPublish"
              label="Only show most recent publish"
              value={onlyShowMostRecentPublish}
              onChange={e => setOnlyShowMostRecentPublish(e.target?.checked || false)}
            />
          </FormGroup>
          <div
            style={{
              position: 'absolute',
              right: '15px',
            }}
          >
            <Tooltip title="Download all data in CSV">
              <Button
                color="primary"
                onClick={generateCSVReportStart}
                className="btn-sm"
              >
                Download CSV
              </Button>
            </Tooltip>
          </div>
          {loading ? (<div className="text-center"><Spinner color="primary" size="lg" /></div>) : (
            <ReactTableBase
              key="searchable"
              columns={columns}
              data={data}
              tableConfig={tableConfig}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

GenerateByPublishTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default GenerateByPublishTable;
