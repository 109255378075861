import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { unflatten } from 'flat';
import {
  FormGroup,
  Label,
  Input,
  ButtonToolbar,
  Button,
  Spinner,
} from 'reactstrap';
import Select from 'react-select';
import validator from '../../helpers/WVValidator';
import { capitalize } from '../../helpers/WVFormatter';
import { fetchRecord, saveRecord, updateParams } from '../../helpers/WVRequest';
import withNotice from '../../../containers/App/store/with-notice';

const defaultSelectedOption = { label: 'Not Assigned', value: '' };

const Resource = ({
  resource,
  fields,
  label,
  id,
  view,
  settitle,
  setVersion,
  close,
  addNotice,
}) => {
  const [data, setData] = useState({});
  const [updatedData, setUpdatedData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});

  const update = (params) => {
    const errors = {};
    Object.keys(params).forEach((k) => { errors[k] = null });
    setData(updateParams(data, { params, errors }));
    setUpdatedData(updateParams(updatedData, { params }));
  };

  const handleChange = (key, e) => {
    update({ [key]: e.target.value });
  };

  const handleChangeSelect = (key, selectedItem) => {
    update({ [key]: selectedItem.value });
    const options = fields?.[key]?.options || [defaultSelectedOption];
    const value = selectedItem.value || '';
    const selectedOption = options.find(o => o.value === value);
    setSelectedOptions({ ...selectedOptions, [key]: selectedOption });
  };

  const validateValue = (value, rules) => {
    if (value?.length === 0 || typeof value === 'undefined') {
      if (rules.required === true) {
        return { isValid: false, message: 'This field is required.' };
      }
    } else {
      if (rules.format && !validator[`is${capitalize(rules.format)}`](value)) {
        const e = validator[`${rules.format}Error`];
        return { isValid: false, message: e ? e(value) : 'Invalid format of value.' };
      }
    }
    return { isValid: true };
  };

  const validate = (values, rules) => {
    const errors = {};
    let valid = true;
    const validateRow = (key, i, rules) => {
      const res = validate(values?.[key]?.[i], rules);
      valid &= res.isValid;
      errors[key][i] = res.errors;
    };
    Object.keys(rules).forEach((key) => {
      if (key === '$type') return;
      if (values?.[key] instanceof Array && rules[key].type !== 'array') {
        if (!errors[key]) errors[key] = [];
        values[key].forEach((v, i) => { validateRow(key, i, rules[key]) });
      } else if (rules[key].$type === 'nested') {
        if (!errors[key]) errors[key] = {};
        for (let i in rules[key]) {
          if (i !== '$type') validateRow(key, i, rules[key][i]);
        }
      } else {
        const { isValid, message } = validateValue(values?.[key], rules[key]);
        errors[key] = message;
        valid &= isValid;
      }
    });
    return { isValid: valid, errors };
  };

  const validateData = () => {
    const { isValid, errors } = validate(data.params, fields);
    setData({ ...data, errors });
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateData()) {
      addNotice({
        message: 'Please Check form. Seems you did not fill some fields',
        type: 'warning',
      });
    } else if (Object.keys(updatedData).length > 0) {
      setLoading(true);
      const res = await saveRecord(resource.id, updatedData, id);
      if (res.data.record?.id) {
        addNotice({
          message: 'Data have been saved',
          type: 'success',
        });
        setVersion((new Date()).toString());
      } else {
        addNotice({
          message: res.data.notice?.message || 'Unable to save record',
          type: 'error',
        });
      }
      setLoading(false);
      close();
    } else {
      close();
    }
  };

  useEffect(() => {
    if (label) settitle(id ? `Edit ${label}` : `Add ${label}`);
  }, [label]);

  const getSelectedOptionsFromRecord = (record) => Object.keys(fields).reduce((acc, key) => {
    if (fields[key]?.options?.length > 0) {
      const currentValue = record?.params && record?.params[key] ? record.params[key] : '';
      const selectedOption = fields[key].options.find(o => o.value === currentValue);
      acc[key] = selectedOption || defaultSelectedOption
    }
    return acc;
  }, {});

  useEffect(() => {
    setUpdatedData({});
    setLoading(true);
    fetchRecord(resource.id, id).then((res) => {
      setData(unflatten(res.data?.record) || {});
      setSelectedOptions(getSelectedOptionsFromRecord(unflatten(res.data?.record) || {}));
      setLoading(false);
    });
  }, [id]);

  return loading ? (
    <div className="text-center"><Spinner color="primary" size="lg" /></div>
  ) : (
    <form onSubmit={handleSubmit}>
      {view ? view(data, update) : Object.keys(fields).map(key => (
        <FormGroup key={key}>
          <Label className="bold-text">{fields[key].label}</Label>
          {fields[key].options?.length > 0 ? (
            <Select
              value={selectedOptions[key]}
              onChange={(selectedItem) => handleChangeSelect(key, selectedItem)}
              options={fields[key].options}
            />
          ) : (
            <Input
              type="text"
              id={key}
              name={key}
              onChange={e => handleChange(key, e)}
              value={fields[key].formatForView
                ? fields[key].formatForView(data.params?.[key] || '')
                : (data.params?.[key] || '')
              }
              disabled={!!fields[key].readOnly}
            />
          )}
          {data.errors?.[key] && (<small className="text-danger">{data.errors[key]}</small>)}
        </FormGroup>
      ))}
      <ButtonToolbar
        className="modal__footer"
      >
        <Button
          className="modal_ok btn-sm is-primary"
          color="primary"
          type="submit"
          disabled={Object.keys(updatedData).length === 0}
        >
          Save
        </Button>
        &nbsp;&nbsp;
        <Button
          className="modal_cancel btn-sm"
          color="secondary"
          onClick={close}
        >
          Cancel
        </Button>
      </ButtonToolbar>
    </form>
  );
};

Resource.propTypes = {
  resource: PropTypes.objectOf(PropTypes.any).isRequired,
  fields: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  view: PropTypes.func,
  settitle: PropTypes.func,
  setVersion: PropTypes.func,
  close: PropTypes.func.isRequired,
  addNotice: PropTypes.func.isRequired,
};

Resource.defaultProps = {
  label: null,
  id: null,
  view: null,
  settitle: () => {},
  setVersion: () => {},
};

export default withNotice(Resource);
