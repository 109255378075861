import React, { useState, useEffect } from 'react';
import { unflatten } from 'flat';
import { axios } from 'ApiClient';
import { InputGroup, InputGroupAddon,InputGroupText } from 'reactstrap';
import Hook from '@/shared/hooks';
import { NumericFormat } from 'react-number-format';

const Cost = ({ updateRecordFunction, id, initialValue, status }) => {
  const [amount, setAmount] = useState(initialValue);
  const [isSaving, setIsSaving] = useState(false);
  const { showSuccess, showError } = Hook.useNoticeHook();

  useEffect(() => {
    setAmount(initialValue);
  }, [initialValue]);
  const handleBlur = async () => {
    setIsSaving(true);
    try {
      const { data } = await axios.post('/api/portal/opportunity/post-edit-opportunity-params', {
        id,
        key: 'amount',
        value: amount,
      });
      if (!data.success) {
        throw new Error(data.error || 'Failed to update record');
      } else {
        updateRecordFunction(unflatten(data.opportunityPopulated));
        showSuccess('Amount saved successfully!');
      }
      
    } catch (error) {
      showError('Failed to save amount.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div>
      <InputGroup className="min-width-150">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>$</InputGroupText>
        </InputGroupAddon>
        <NumericFormat
          thousandSeparator
          prefix=""
          value={amount}
          className="form-control"
          inputMode="numeric"
          allowEmptyFormatting={false}
          fixedDecimalScale
          decimalScale={2}
          min={0}
          allowNegative={false}
          disabled={isSaving || status === 'Sent'}
          defaultValue={initialValue}
          onBlur={(e) => handleBlur(e)}
          placeholder="Enter amount in dollars"
          onValueChange={(values) => {
            setAmount(values.floatValue);
          }}
        />
      </InputGroup>

    </div>
  );
};

export default Cost;