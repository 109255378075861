import React, { useState, useEffect } from 'react';
// import { unflatten } from 'flat';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { checkImagePromise, parseUrl } from '../../../../../../../shared/helpers/WVUtilities';
import HighLight from '../../../../../../../shared/components/HighLight';
import CopyToClipboard from '../../../../../../../shared/components/CopyToClipboardLeft';
import { formatIntNumber } from '../../../../../../../shared/helpers/WVFormatter';
// import BrandApprovalsToolTip from '../../../../../../../shared/components/prospecting/BrandApprovalsToolTip';
import {
  AddressIcon,
  CopyIcon,
  PencilIcon, PickIcon,
  ShieldExclamationIcon,
  ShieldIcon,
} from '../../../../../../../shared/helpers/Icons';
import { modalId as modalApprovedId } from '../../../Search/components/modals/ShowRejectedApprovedBrands';
import { showModalById } from '../../../../../../../redux/reducers/TableReducer';

const CreatorInList = React.memo(({
  record, editHandler, miningMode, forcedEmailAddress, customComponent, setCurrentProspect, setOpenTabs, addEditIcon, addShieldIcons, addProspectIcon,
}) => {
  // console.log('RECORD - ', record);
  const dispatch = useDispatch();
  // const recordPrepared = unflatten(record);
  const [image, setImage] = useState('/images/user/defaultAvatar.png');
  // const BrandApprovalsToolTipChildRef = useRef(null);
  // const [forceRender, setForceRender] = useState(0);
  const filters = parseUrl();
  const search = filters.fullSearchName || '';
  const emailAddress = forcedEmailAddress || record.params.email;
  const prospectOwnerName = `${record.populated?.prospectOwner?.params?.firstName || ''} ${record.populated?.prospectOwner?.params?.lastName || ''}`;

  const recordY = record.params.tubularDetails?.accounts.find(a => a.platform === 'youtube');
  const recordI = record.params.tubularDetails?.accounts.find(a => a.platform === 'instagram');
  const recordF = record.params.tubularDetails?.accounts.find(a => a.platform === 'facebook');
  const recordT = record.params.tubularDetails?.accounts.find(a => a.platform === 'twitter');
  const recordTwitch = record.params.tubularDetails?.accounts.find(a => a.platform === 'twitch');

  useEffect(async () => {
    setImage('/images/user/defaultAvatar.png');
    if (recordY && await checkImagePromise(recordY.thumbnailUrl)) {
      setImage(recordY.thumbnailUrl);
    } else if (recordI && await checkImagePromise(recordI.thumbnailUrl)) {
      setImage(recordI.thumbnailUrl);
    } else if (recordF && await checkImagePromise(recordF.thumbnailUrl)) {
      setImage(recordF.thumbnailUrl);
    } else if (recordT && await checkImagePromise(recordT.thumbnailUrl)) {
      setImage(recordT.thumbnailUrl);
    } else if (recordTwitch && await checkImagePromise(recordTwitch.thumbnailUrl)) {
      setImage(recordTwitch.thumbnailUrl);
    }
  }, [record.id]);

  // const EditPencil = () => (editHandler ? (
  //   <Tooltip title="Edit" className="editToolTip">
  //     <IconButton
  //       size="small"
  //       onClick={editHandler}
  //       className="material-table__toolbar-button"
  //       style={{
  //         height: '20px',
  //         color: '#646777',
  //       }}
  //     >
  //       <PencilIcon />
  //     </IconButton>
  //   </Tooltip>
  // ) : (<></>));

  return (
    <div
      className="d-flex"
      style={{ minWidth: '350px' }}
    >
      <img
        src={image}
        alt={record.params.title}
        style={{ width: '50px', height: 'auto' }}
        className="align-self-center"
      />
      <div
        className="align-self-center flex-column"
        style={{ marginLeft: '8px' }}
        // ref={BrandApprovalsToolTipChildRef}
      >
        {record.params.title !== '' && (
          <div className="">
            <div
              className="bold-text"
            >
              <HighLight search={search || ''}>{record.params.title || ''}</HighLight>
              {record.params.isInternal && (
                <Tooltip title={prospectOwnerName}>
                  <img
                    src="/images/general/logo_short_128.png"
                    alt="internalLogo"
                    // title={prospectOwnerName}
                    style={{ width: '18px', marginLeft: '7px' }}
                  />
                </Tooltip>
              )}
              {record.params.opportunity?.isOpportunity && (
                <span>
                  <Tooltip title='Opportunity'>
                    <i className="fa fa-handshake" style={{ marginLeft: '7px'}}></i>
                  </Tooltip>
                </span>
              )}
            </div>
          </div>
        )}
        <div className="firstAndLastName">
          <HighLight search={search || ''}>{`${record.params.firstname || ''} ${record.params.lastname || ''}`}</HighLight>
          {record.params.title === '' && record.params.isInternal && (
            <Tooltip title={prospectOwnerName}>
              <img
                src="/images/general/logo_short_128.png"
                alt="internalLogo"
                // title={prospectOwnerName}
                style={{ width: '18px', marginLeft: '7px' }}
              />
            </Tooltip>
          )}
          {record.params.title === '' && record.params.opportunity?.isOpportunity && (
            <span>
              <Tooltip title='Opportunity'>
                <i className="fa fa-handshake" style={{ marginLeft: '7px'}}></i>
              </Tooltip>
            </span>
          )}
        </div>
        {emailAddress && (
          <div
            style={{ maxWidth: miningMode ? '378px' : '245px' }}
          >
            <div
              style={{
                marginLeft: '8px',
                float: 'right',
              }}
            >
              {customComponent || <></>}
            </div>
            <div
              style={{
                maxWidth: miningMode ? '360px' : '227px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <HighLight
                search={search || ''}
              >
                {emailAddress || ''}
              </HighLight>
            </div>
          </div>
        )}
        <div className="" >
          {record.params.tubularDetails?.accounts.map(t => (
            <a
              href={t.url}
              target="_blank"
              rel="noreferrer"
              key={t.platform}
              style={{ marginRight: '4px' }}
            >
              <img
                src={`/images/mediacontent/${t.platform}.png`}
                alt={t.platform}
                title={t.title}
                style={{ width: '20px' }}
              />
            </a>))}
          {record.params.listenNotesDetails?.url && (
            <a
              href={record.params.listenNotesDetails.url}
              target="_blank"
              rel="noreferrer"
              style={{ marginRight: '4px' }}
            >
              <img
                src="/images/mediacontent/podcast_128.png"
                alt="podcast"
                title={record.params.listenNotesDetails.title}
                style={{ width: '20px' }}
              />
            </a>
          )}
          <Tooltip
            title={`Score is ${formatIntNumber(Math.ceil(Number(record.params.youtubeStats?.influencer_score || 0)))}. V30 is ${formatIntNumber(record.params.youtubeStats?.monthly_v30 || 0)}.`}
          >
            <span>{`(${formatIntNumber(Math.ceil(Number(record.params.youtubeStats?.influencer_score || 0)))} : ${formatIntNumber(record.params.youtubeStats?.monthly_v30 || 0)})`}</span>
          </Tooltip>
        </div>
        <div>
          {addEditIcon && editHandler && (
            <div style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '2px' }}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
              <a
                title="Edit Prospect"
                role="button"
                onClick={editHandler}
                className="ml-1"
              >
                <PencilIcon />
              </a>
            </div>
          )}
          <div style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '2px' }} className="block-in-cell ml-1">
            <CopyToClipboard value={emailAddress} icon={(<CopyIcon />)} className="text-blue" />
          </div>
          {record.params.hsUserId && true && (
            <div style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '2px' }}>
              <a
                title="Show HubSpot Contact"
                target="_blank"
                rel="noopener noreferrer"
                className="ml-0"
                style={{ fontSize: '16px' }}
                href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${record.params.hsUserId}`}
              >
                <i className="fab fa-hubspot" />
              </a>
            </div>
          )}
          {record.params.creatorObject && (
            <div className="ml-2" style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '2px' }}>
              <a
                title="Creator Profile"
                target="_blank"
                rel="noreferrer"
                href={`/influencer/profile/${record.params.creatorObject._id}`}
                style={{
                  fontSize: '18px',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  marginTop: '2px',
                }}
              >
                <AddressIcon />
              </a>
            </div>
          )}
          {addShieldIcons && (
            <div className={record.params.hsUserId ? 'ml-2' : 'ml-0'} style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '2px' }}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
              <a
                title="Safety Check"
                role="button"
                onClick={() => { setCurrentProspect(record); setOpenTabs(true); }}
                className="ml-1"
              >
                <ShieldExclamationIcon />
              </a>
            </div>
          )}
          {addShieldIcons && (
            <div className="ml-2" style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '1px' }}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
              <a
                title="Brand Approvals"
                role="button"
                onClick={() => { dispatch(showModalById(modalApprovedId, { record: { prospects: [{ _id: record.id }] } })); }}
                className="ml-0"
                style={{ fontSize: '19px' }}
              >
                <ShieldIcon />
              </a>
            </div>
          )}
          {addProspectIcon && record.prospects && record.prospects?.length > 0 && (
            <div className="ml-1" style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '4px' }}>
              <a
                title="Open Prospects"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-3"
                style={{ fontSize: '19px' }}
                href={`/research/internal-prospects?${record.prospects.map(p => `filters.prospectId=${p._id}`).join('&')}`}
              >
                <PickIcon />
              </a>
            </div>
          )}
        </div>
      </div>
      {/* <BrandApprovalsToolTip
        forceRender={forceRender}
        setForceRender={setForceRender}
        theRef={BrandApprovalsToolTipChildRef}
        record={record}
      /> */}
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

CreatorInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  editHandler: PropTypes.func.isRequired,
  miningMode: PropTypes.bool,
  forcedEmailAddress: PropTypes.string,
  customComponent: PropTypes.objectOf(PropTypes.any),
  setCurrentProspect: PropTypes.func.isRequired,
  setOpenTabs: PropTypes.func.isRequired,
  addEditIcon: PropTypes.bool,
  addShieldIcons: PropTypes.bool,
  addProspectIcon: PropTypes.bool,
};

CreatorInList.defaultProps = {
  miningMode: false,
  forcedEmailAddress: '',
  customComponent: null,
  addEditIcon: true,
  addShieldIcons: true,
  addProspectIcon: true,
};

export default CreatorInList;
