import React, { PureComponent } from 'react';
import { NumericFormat as NumberFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';


class CurrencyField extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    defaultValue: PropTypes.number,
    onBlur: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    allowEmptyFormatting: PropTypes.bool,
  };

  static defaultProps = {
    defaultValue: 0,
    disabled: false,
    placeholder: '',
    name: null,
    allowEmptyFormatting: true,
  };
  constructor(props) {
    super(props);
    this.state = {
      changedAmount: undefined,
      value: props.defaultValue,
    };
  }
  componentDidUpdate(prevProps) {
    this.shouldUpdateValue(prevProps);
  }
  shouldUpdateValue(prevProps) {
    if (Number(prevProps.defaultValue) !== Number(this.props.defaultValue) && Number(this.props.defaultValue) !== Number(this.state.value)) {
      this.setState({ value: this.props.defaultValue });
    }
  }
  render() {
    const {
      disabled,
      defaultValue,
      onBlur,
      placeholder,
      name,
      allowEmptyFormatting,
    } = this.props;
    const { value } = this.state;
    return (
      <InputGroup className="min-width-150">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>$</InputGroupText>
        </InputGroupAddon>
        <NumberFormat
          thousandSeparator
          prefix=""
          name={name}
          value={value}
          className="form-control"
          inputMode="numeric"
          allowemptyformatting={allowEmptyFormatting.toString()}
          fixedDecimalScale
          decimalScale={2}
          disabled={disabled}
          defaultValue={defaultValue}
          onBlur={(e) => { onBlur(e, this.state.changedAmount === undefined ? defaultValue : this.state.changedAmount); }}
          placeholder={placeholder}
          onValueChange={(values) => {
            this.setState({ changedAmount: values.floatValue, value: values.floatValue });
          }}
        />
      </InputGroup>
    );
  }
}

export default CurrencyField;
