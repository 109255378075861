import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

class DropZoneField extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    customHeight: PropTypes.bool,
    accept: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(PropTypes.any),
    ]),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
      })),
    ]).isRequired,
    customClass: PropTypes.string
  };

  static defaultProps = {
    customHeight: false,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    customClass: '',
  };

  constructor() {
    super();
    this.state = {};
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(file) {
    const { onChange } = this.props;
    onChange(file.map(fl => Object.assign(fl, {
      preview: (fl.type === 'application/pdf') ? '/images/resources/icons/pdf.png' : URL.createObjectURL(fl),
    })));
  }

  removeFile(index, e) {
    const { onChange, value } = this.props;
    e.preventDefault();
    onChange(value.filter((val, i) => i !== index));
  }

  render() {
    const {
      value, customHeight, name, accept, customClass
    } = this.props;

    const files = value;
    console.log(files);

    return (
      <div className={`dropzone dropzone--single${customHeight ? ' dropzone--custom-height' : ''}${customClass}`}>
        <Dropzone
          accept={accept}
          name={name}
          multiple={false}
          onDrop={(fileToUpload) => {
            this.onDrop(fileToUpload);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="dropzone__input">
              {(!files || files.length === 0)
              && (
              <div className="dropzone__drop-here">
                <span className="lnr lnr-upload" /> Drop file here to upload or click to browse
              </div>
              )}
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>
        {files && Array.isArray(files) && files.length > 0
        && (
        <aside className="dropzone__img">
          <img src={files[0].preview} alt="drop-img" />
          <p className="dropzone__img-name">{files[0].name}</p>
          <button className="dropzone__img-delete" type="button" onClick={e => this.removeFile(0, e)}>
            Remove
          </button>
        </aside>
        )}
      </div>
    );
  }
}

const DropZone = (props) => {
  const { input, customHeight, customClass } = props;
  console.log(input);
  return (
    <DropZoneField
      {...input}
      customHeight={customHeight}
      customClass={customClass}
    />
  );
};

DropZone.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  customHeight: PropTypes.bool,
  customClass: PropTypes.string,
};

DropZone.defaultProps = {
  customHeight: false,
  customClass: '',
};

export default DropZone;
