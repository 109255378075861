import React, { useEffect, useState } from 'react';
import {
  // Alert,
  Button,
  Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Row,
} from 'reactstrap';
import { NumericFormat as NumberFormat } from 'react-number-format';
import { axios } from 'ApiClient';
import { connect } from 'react-redux';
import SelectAsync from 'react-select/async';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import WVFormatter from '../../../../../shared/helpers/WVFormatter';
import CurrencyField from '../../../../../shared/components/form/CurrencyFieldF';
import CheckBoxField from '../../../../../shared/components/CheckBox';
import withNotice from '../../../../App/store/with-notice';
import { currentTimezoneAggr, momentLocaleToServerUTC, momentUTCToLocalDate } from '../../../../../shared/helpers/Dates';
import { getConstant } from '../../../../../shared/helpers/WVConstants';
import Alert from '../../../../../shared/easydev2/shared/components/Alert';


// const tubularChannelsArray = ['youtube', 'instagram', 'facebook', 'twitter', 'tiktok'];
const hsMediaChannelPlatforms = [
  { label: 'YouTube', value: 'youtube_channel_url', domain: 'youtube.com' },
  { label: 'Instagram', value: 'instagram_channel', domain: 'instagram.com' },
  { label: 'Facebook', value: 'facebook_channel_url', domain: 'facebook.com' },
  { label: 'X', value: 'twitch_account', domain: 'x.com' },
];

/* const selectedDealJustificationOption = [
  { label: 'Test', value: 'Test' },
  { label: 'Rebuy, Actual CAC', value: 'Rebuy, Actual CAC' },
  { label: 'Rebuy, Predicted CAC', value: 'Rebuy, Predicted CAC' },
  { label: 'Price Adjusted Rebuy to Actual', value: 'Price Adjusted Rebuy to Actual' },
  { label: 'Price Adjusted Rebuy to Predicted', value: 'Price Adjusted Rebuy to Predicted' },
  { label: 'Marginal Rebuy, Actual CAC', value: 'Marginal Rebuy, Actual CAC' },
  { label: 'Marginal Rebuy, Predicted CAC', value: 'Marginal Rebuy, Predicted CAC' },
]; */

const formatContactOptionLabel = ({ record }) => (
  <div style={{ display: 'flex' }}>
    <div >{record?.params.firstname} {record?.params.lastname}</div>
    <div style={{ color: '#888', marginLeft: '10px' }}>
      {record?.params.email}
    </div>
  </div>
);

const contactOption = (props) => {
  const { record } = props.data;
  return (
    <components.Option {...props} >
      <div>
        <div>{record?.params.firstname} {record?.params.lastname}</div>
        <div style={{ color: '#888', fontSize: '13px' }}>
          {record?.params.email}
        </div>
      </div>
    </components.Option>
  );
};

const AddCpmCreator = ({ addNotice }) => {
  const percentOfGrossProfit = Number(getConstant('deal', 'percentOfGrossProfit') || 15);

  const selectedDealJustificationOption = getConstant('deal', 'dealJustification').map(a => ({ value: a, label: a }));
  const [loading, setLoading] = useState(false);
  const [loadingTubularData, setLoadingTubularData] = useState(false);
  const [prospectData, setProspectData] = useState({});
  // const [mediaChannelsData, setMediaChannelsData] = useState({});
  const [brand, setBrand] = useState(null);
  const [numVideos, setNoVideos] = useState(1);
  const [pricePerVideo, setPricePerVideo] = useState(null);
  const [projectedGrossProfit, setProjectedGrossProfit] = useState(null);
  const [bhCpmTotalCost, setBhCpmTotalCost] = useState(null);
  const [contentReleaseDate, setContentReleaseDate] = useState(null);
  const [referralURL, setReferralURL] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [cpm, setCpm] = useState(null);
  const [dealJustification, setDealJustification] = useState('');
  const [notes, setNotes] = useState('');

  const [errors, setErrors] = useState({});
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [platform, setPlatform] = useState({ label: 'YouTube', value: 'youtube_channel_url' });
  const [mediaUrl, setMediaUrl] = useState('');
  const [v30, setV30] = useState(null);
  const [disableSearch, setDisableSearch] = useState(true);
  const [disableMediaProperties, setDisableMediaProperties] = useState(true);
  const [tubularData, setTubularData] = useState(null);
  const [youTubeStats, setYouTubeStats] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('Creator was found');
  const [alertColor, setAlertColor] = useState('success');
  const [dealsOptions, setDealsOptions] = useState([]);
  const [adminUsersSuggestions, setAdminUsersSuggestions] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [brandOptions, setBrandOptions] = useState([]);
  const [blacklistAlert, setBlacklistAlert] = useState({
    status: false,
    type: 'email',
  });
  const [showDuplicateDealMessage, setShowDuplicateDealMessage] = useState(false);
  const [dealUsedIoName, setDealUsedIoName] = useState('');

  const requiredFields = ['mediaUrl'];

  const setProspectProperty = (field, value) => {
    console.log(field, value);
    console.log(prospectData);
    setProspectData(p => ({
      ...p,
      [field]: value,
    }));
    setErrors({
      ...errors,
      [field]: false,
    });
  };

  const loadBrandsOptions = async () => {
    const response = await axios({
      method: 'get',
      url: '/api/portal/scorecard/media-content/get-brands-data?term=',
    });
    const res = [];
    if (response.data.success) {
      response.data.brands.filter(b => b.displayInUi === 'Yes').forEach((record) => {
        res.push({
          value: record._id,
          label: record.companyName,
          record,
        });
      });
    }
    setBrandOptions(res);
  };

  const loadDealOptions = async (inputValue) => {
    console.log(inputValue, 'Deal Search');
    // const { selectedInfluencer } = this.state;
    // const id = selectedInfluencer ? selectedInfluencer.id : '';
    const response = await axios.get(`/api/portal/scorecard/media-content/get-deal-data?contact=${selectedContact?.record?.id || ''}&term=${inputValue}&destination=cpm&brand=${brand?.value}&limit=1000`);
    return response.data.deals.map(deal => ({
      value: deal._id,
      label: `${deal.dealname} (${WVFormatter.getFormatedDate(deal.hs_createdate)}) ${deal.cpmProspect ? 'Deal already exists in CPM program' : ''}`,
      record: deal,
      // isDisabled: !!deal.cpmProspect,
    })) || [];
  };

  const loadContactsOptions = async (inputValue) => {
    const response = inputValue ? await axios.get('/api/portal/hubil/get-contact-search', {
      params: {
        param: 'email',
        value: inputValue,
        // hideBlacklist: true,
      },
    }) : { records: [] };
    const contacts = response.data.records.map(record => ({
      value: record.id,
      label: record.params.email !== null ? record.params.email : `${record.params.firstname} ${record.params.lastname}`,
      record,
    }));
    return contacts;
  };

  const validateMediaUrl = (value) => {
    if (value) {
      const urlItem = hsMediaChannelPlatforms.find(item => item.label === platform.label);
      if (value.toString().includes(urlItem.domain)) {
        setDisableSearch(false);
      } else {
        setDisableSearch(true);
      }
    }
  };

  const checkRequiredFields = () => {
    let noErrors = true;
    requiredFields.forEach((field) => {
      if (!prospectData[field]) {
        noErrors = false;
        if (!disableSearch) setDisableSearch(true); setDisableMediaProperties(true);
      }
      if (field === 'mediaUrl' && noErrors && mediaUrl.length > 10) {
        validateMediaUrl(mediaUrl);
      }
    });
  };

  const changeMediaUrl = (e) => {
    setMediaUrl(e.target.value);
    setProspectProperty('mediaUrl', e.target.value);
    if (e.target.value.length > 10) checkRequiredFields();
    if (e.target.value.length < 11 && !disableSearch) setDisableSearch(true); setDisableMediaProperties(true);
    if (errors.mediaUrl && e.target.value.length > 0) setErrors({ ...errors, mediaUrl: false });
    setTubularData(null);
    setYouTubeStats(null);
    setShowAlert(false);
    setBlacklistAlert({ status: false, type: 'email' });
  };

  const changePlatform = (selected) => {
    setPlatform(selected);
    // setMediaUrl('');
    setDisableSearch(true);
    setDisableMediaProperties(true);
    const id = `mediaChannels.${selected.value}`;
    changeMediaUrl({ target: { value: selectedContact?.record?.params[id] || '' } });
    setAllowUpdate(false);
  };

  const clearFunction = () => {
    changeMediaUrl({ target: { value: '' } });
    setTubularData(null);
    setYouTubeStats(null);
    setShowAlert(false);
    setBlacklistAlert({ status: false, type: 'email' });
    setBrand(null);
    setProspectData({});
    setNoVideos(null);
    setPricePerVideo(null);
    setDealJustification(null);
    setNotes(null);
    setSelectedDeal(null);
    setSelectedContact(null);
    setSelectedAdmin(null);
    setV30(null);
    setNoVideos(1);
    setProjectedGrossProfit(null);
    setBhCpmTotalCost(null);
    setContentReleaseDate(null);
    setReferralURL('');
    setPromoCode('');
    setCpm(null);
  };

  const uploadTubularData = () => {
    setLoading(true);
    setLoadingTubularData(true);
    setTubularData(null);
    setYouTubeStats(null);
    setBlacklistAlert({ status: false, type: 'email' });
    // setMediaChannelsData({});
    // const url = mediaChannelsData.youtube?.url || mediaChannelsData.instagram?.url || mediaChannelsData.facebook?.url || mediaChannelsData.twitter?.url;
    axios.get(`/api/portal/prospects/get-data-from-tubular?searchQuery=${mediaUrl}`).then((response) => {
      // console.log('RESPONSE', response.data);
      if (response.data.success && response.data.creator) {
        /* const mediaData = {};
        response.data.creator.accounts.forEach((account) => {
          if (tubularChannelsArray.includes(account.platform)) {
            mediaData[account.platform] = {
              channel: account.title,
              url: platform.label.toLowerCase() === account.platform ? mediaUrl : account.account_url,
            };
          }
        }); */
        setTubularData(response.data.creator);
        const youtubeStatsResp = response.data.creator?.accounts.find(a => a.platform === 'youtube');
        setYouTubeStats(youtubeStatsResp);
        if (platform?.value === 'youtube_channel_url') setMediaUrl(youtubeStatsResp.account_url);
        console.log(response.data.creator);
        axios.get(`/api/portal/blacklist/get-item-by-creator-id?tubularCreatorId=${response.data.creator.creator_id}`).then((resp) => {
          if (resp.data.success) {
            if (resp.data.result) {
              setBlacklistAlert({ status: true, type: 'creator' });
            } else {
              setBlacklistAlert({ status: false, type: 'creator' });
            }
          }
        });
        setAlertMessage('Creator was found.');
        setAlertColor('success');
        setShowAlert(true);
      }
      if (response.data.success && !response.data.creator) {
        setAlertMessage('Creator not found. Try entering a different URL');
        setAlertColor('warning');
        setShowAlert(true);
        setTubularData(null);
      }
      setLoading(false);
      setLoadingTubularData(false);
      setDisableMediaProperties(false);
    }).catch(() => {
      setLoading(false);
      setLoadingTubularData(false);
      setTubularData(null);
      setYouTubeStats(null);
      setShowAlert(false);
      setBlacklistAlert({ status: false, type: 'email' });
    });
  };

  const fetchAdminUsersSuggestions = () => {
    axios({
      method: 'get',
      url: '/api/portal/prospects/get-prospect-owners',
    }).then((response) => {
      if (response.data.success === true) {
        setAdminUsersSuggestions(response.data.suggestions);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const saveProspectCPM = () => {
    const data = {
      prospectData: {
        ...prospectData,
        numVideos,
        amount: pricePerVideo,
        dealJustification,
        notes,
        status: 'approved',
        totalPrice: bhCpmTotalCost,
        totalImpressions: v30,
        v30,
        cpm,
        contentReleaseDate,
        projectedGrossProfit,
      },
      tubularData: tubularData ? { ...tubularData } : null,
      dealData: {
        pricePerVideo,
        projectedGrossProfit,
        bhCpmTotalCost,
        contentReleaseDate,
      },
      allowUpdate,
      platform: platform.value,
      mediaUrl,
    };
    console.log(prospectData);
    console.log(data);
    const validationErrors = {};
    if (!selectedContact) validationErrors.hsUserId = true;
    if (!selectedDeal) validationErrors.hsDealId = true;
    if (!brand) validationErrors.brand = true;
    if (!numVideos) validationErrors.numVideos = true;
    if (!selectedAdmin) validationErrors.cspOwner = true;
    // if (!pricePerVideo) validationErrors.pricePerVideo = true;
    requiredFields.forEach((field) => {
      if (!data.prospectData[field]) {
        validationErrors[field] = true;
      }
    });

    setErrors(validationErrors);
    // console.log(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      axios.post('/api/portal/prospects/post-add-prospect-cpm', { ...data }).then((response) => {
        // console.log('Resp', response);
        if (response.data.success) {
          // setSaveResults(response.data.result);
          setLoading(false);
          addNotice({
            message: 'All data has been saved',
            type: 'success',
          });
          clearFunction();
        } else {
          addNotice({
            message: response.data?.error || 'Unable to save CPM Prospect',
            type: 'error',
          });
        }
      });
    }
  };

  const changeV30 = (value) => {
    const updatedV30 = value && parseInt(value, 10) ? parseInt(value, 10) : 0;
    setV30(updatedV30);
    // setCpm(updatedV30 !== 0 ? (bhCpmTotalCost / (updatedV30 / 1000)) : 0);
  };
  useEffect(() => {
    fetchAdminUsersSuggestions();
    loadBrandsOptions();
  }, []);

  useEffect(() => {
    changeMediaUrl({ target: { value: selectedContact?.record?.params['mediaChannels.youtube_channel_url'] || '' } });
    setPlatform({ label: 'YouTube', value: 'youtube_channel_url' });
  }, [selectedContact]);

  useEffect(() => {
    checkRequiredFields();
  }, [prospectData]);

  useEffect(async () => {
    // console.log(await loadDealOptions(''))
    setSelectedDeal(null);
    setDealsOptions(await loadDealOptions(''));
  }, [selectedContact]);

  useEffect(() => {
    const price = selectedDeal?.record?.dealTerms?.guaranteed_payment_contract_amount ? selectedDeal.record.dealTerms.guaranteed_payment_contract_amount : 0;
    setPricePerVideo(price);
    const profit = selectedDeal?.record?.dealTerms?.projected_gross_profit ? selectedDeal.record.dealTerms.projected_gross_profit : (price * (percentOfGrossProfit / 100));
    setProjectedGrossProfit(profit);
    const cost = selectedDeal?.record?.dealTerms?.bh_cpm_total_cost ? selectedDeal?.record?.dealTerms?.bh_cpm_total_cost : (price + profit);
    setBhCpmTotalCost(cost);
    if (selectedDeal?.record?.dealTerms?.content_release_date) setContentReleaseDate(selectedDeal.record.dealTerms.content_release_date);
    if (selectedDeal?.record?.dealTerms?.referral_url) setReferralURL(selectedDeal.record.dealTerms.referral_url);
    if (selectedDeal?.record?.dealTerms?.coupon_code) setPromoCode(selectedDeal.record.dealTerms.coupon_code);
    if (selectedDeal?.record?.dealTerms?.v30_number_of_impressions) changeV30(selectedDeal?.record?.dealTerms?.v30_number_of_impressions);
  }, [selectedDeal]);

  useEffect(() => {
    setCpm(v30 && v30 !== 0 ? (bhCpmTotalCost / (v30 / 1000)) : 0);
  }, [bhCpmTotalCost, v30]);

  const changePricePerVideo = (event) => {
    console.log('event?.target?.value: ', event?.target?.value);
    console.log('type event?.target?.value: ', typeof event?.target?.value);
    const price = event?.target?.value && parseFloat(event.target.value.toString().replace(/,/g, '')) ? parseFloat(event.target.value.toString().replace(/,/g, '')) : 0;
    setPricePerVideo(price);
    const profit = (price * (percentOfGrossProfit / 100)) || 0;
    setProjectedGrossProfit(profit);
    const cost = (price + profit) || 0;
    setBhCpmTotalCost(cost);
    // setCpm(v30 !== 0 ? (cost / (v30 / 1000)) : 0);
  };

  let selectedContactOption = {};
  let selectedDealOption = {};
  if (selectedContact) {
    selectedContactOption = selectedContact;
  } else {
    selectedContactOption = {
      value: null,
      label: '',
      record: { params: { _id: null, firstname: '', lastname: '' } },
    };
  }
  if (selectedDeal) {
    selectedDealOption = selectedDeal;
  } else {
    selectedDealOption = {
      value: null,
      label: '',
      record: { params: { _id: null, firstname: '', lastname: '' } },
    };
  }

  useEffect(() => {
    const contactMediaUrl = selectedContact?.record?.params['mediaChannels.youtube_channel_url'] || '';

    if (mediaUrl !== contactMediaUrl) setAllowUpdate(true);
    setAllowUpdate(false);
  }, [selectedContact, mediaUrl]);

  useEffect(() => {
    const contactMediaUrl = selectedContact?.record?.params['mediaChannels.youtube_channel_url'] || '';

    if (selectedContact && selectedDeal && contactMediaUrl) {
      uploadTubularData();
    }
  }, [selectedContact, selectedDeal]);

  useEffect(() => {
    if (selectedDeal?.record?.dealTerms?.v30_number_of_impressions) return true;
    if (youTubeStats?.monthly_v30) {
      changeV30(youTubeStats.monthly_v30);
    } else changeV30(null);
    return true;
  }, [youTubeStats]);

  const onDealChange = (deal) => {
    setSelectedDeal(deal);
    const d = deal.record;
    // console.log(adminUsersSuggestions, brandOptions);
    const recordBrand = brandOptions.find(b => b.value === d.ilDealData?.offer?.advertiser);
    const admin = adminUsersSuggestions.find(a => a.id === d.ilDealData?.adminUser);
    // console.log(recordBrand, admin, d);
    if (recordBrand) {
      setBrand({
        value: recordBrand.value,
        label: recordBrand.label,
        record: recordBrand.record,
      });
      setProspectProperty('targetBrand', recordBrand.value);
    }
    if (admin) {
      setSelectedAdmin({
        value: admin.id,
        label: admin.name,
      });
      setProspectProperty('cspOwner', admin.id);
    }
    const currentV30 = d.dealTerms.v30_number_of_impressions;
    changeV30(currentV30 || 0);
    setProspectProperty('hsDealId', deal?.record?.hs_object_id);
    if (deal && deal.value) {
      axios.get(`/api/portal/prospects/get-cpm-insertion-order-deal-check?dealId=${deal.value}`).then((response) => {
        if (response.data.success && response.data.existInsertionOrder) {
          setShowDuplicateDealMessage(true);
          setDealUsedIoName(response.data.ioName);
        }
      });
    } else {
      setShowDuplicateDealMessage(false);
      setDealUsedIoName('');
    }
  };

  const handleContentReleaseDatepickerChange = (value) => {
    setContentReleaseDate(momentLocaleToServerUTC(moment(value)).toISOString());
  };

  return (
    <Container className="dashboard full-width">
      <Card>
        <CardBody>
          <CardTitle>
            <Row className="pb-1">
              <Col md="4" className="pt-1">
                <h3>Add CPM Deal</h3>
              </Col>
            </Row>
            <hr className="mt-2 mb-4" />
          </CardTitle>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label className="bold-text">Contact</Label>
                <SelectAsync
                  cacheOptions
                  value={selectedContactOption}
                  defaultOptions
                  loadOptions={loadContactsOptions}
                  onChange={(e) => {
                    console.log(e);
                    setSelectedContact(e);
                    if (e?.value) {
                      console.log(e.record?.params.hs_object_id);
                      setProspectProperty('hsUserId', e.record?.params.hs_object_id);
                      console.log(e.record?.params);
                      if (e.record?.params.blacklist_) setBlacklistAlert({ status: true, type: 'creator' });
                    }
                    setSelectedDeal(null);
                    setProspectProperty('hsDealId', null);
                    setDealUsedIoName('');
                    setShowDuplicateDealMessage(false);
                  }}
                  placeholder="Please Select Contact"
                  formatOptionLabel={formatContactOptionLabel}
                  components={{ Option: contactOption }}
                  isClearable
                />
              </FormGroup>
              <div className="grey-small-text grey-minus-margin" style={errors.hsUserId ? { color: 'red' } : {}}>Required</div>
            </Col>
            <Col md={8}>
              <FormGroup>
                <Label className="bold-text">Deal</Label>
                <Select
                  value={selectedDeal}
                  options={dealsOptions}
                  onChange={onDealChange}
                  placeholder="Select a CPM Deal"
                  isDisabled={!selectedContact}
                  isClearable
                />
              </FormGroup>
              <div className="grey-small-text grey-minus-margin" style={errors.hsDealId ? { color: 'red' } : {}}>Required</div>
              <Alert
                color="warning"
                isOpen={showDuplicateDealMessage}
                toggle={() => { setShowDuplicateDealMessage(false); }}
                className="w-100 standard-close-cross"
              >
                <p className="py-2" style={{ color: '#212529' }}>
                  {/* eslint-disable-next-line max-len */}
                  {`This deal is already associated with insertion order ${dealUsedIoName}. If the deal terms have changed since the original insertion Order, please update the amount on the original insertion order and republish the Looker Update Data sheet.`}
                </p>
              </Alert>
              {showDuplicateDealMessage && (<div style={{ height: '8px', width: '8px' }} />)}
            </Col>
          </Row>
          {selectedDeal ? (
            <Row>
              <Col md="4" sm="6" xs="12">
                <FormGroup>
                  <Label className="bold-text">Platform</Label>
                  <Select
                    value={platform}
                    options={hsMediaChannelPlatforms}
                    onChange={changePlatform}
                    placeholder=""
                    // isClearable
                  />
                  {/* deal.errors.dealId && (<span className="text-danger">{deal.errors.dealId}</span>) */}
                </FormGroup>
                <div className="grey-small-text grey-minus-margin">Media URL Platform (Required)</div>
              </Col>
              <Col md="8" sm="12" xs="12">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <FormGroup style={{ width: '92%' }}>
                    <Label className="bold-text">Media Url</Label>
                    <Input
                      type="text"
                      id="media_url_contact"
                      name="media_url_contact"
                      onChange={changeMediaUrl}
                      value={mediaUrl}
                      // onBlur={validateMediaUrl}
                    />
                    {/* contact.errors.first_name && (<span className="text-danger">{contact.errors.first_name}</span>) */}
                  </FormGroup>
                  <div>
                    <Button
                      type="button"
                      className="is-primary"
                      onClick={uploadTubularData}
                      style={{ marginTop: '33px', marginLeft: '10px', width: '130px' }}
                      disabled={disableSearch || loading}
                    >
                      {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
                      {!loading && <span className="lnr lnr-magnifier font-weight-bold">  </span>}
                      <span>Search</span>
                    </Button>
                  </div>
                </div>
                <div className="grey-small-text grey-minus-margin" style={errors.mediaUrl ? { color: 'red' } : {}}>Media URL (Required)</div>
              </Col>
            </Row>
          ) : null}
          {blacklistAlert.status && (
            <Alert color="warning" className="w-100 mt-2" bordered icon>
              <p className="py-2 text-dark">
                This email address is on the blacklist and blocked from being added. <a href="/influencers/contacts" target="_blank" rel="noopener">Manage Blacklist</a>
              </p>
            </Alert>
          )}
          <Alert
            color={alertColor}
            isOpen={showAlert}
            toggle={() => { setShowAlert(false); }}
            className="w-100 standard-close-cross"
            style={{ paddingBottom: '8px' }}
          >
            <p className="py-2" style={{ color: '#212529' }}>
              {alertMessage}
            </p>
          </Alert>
          {!loadingTubularData && selectedDealOption.value ? (
            <div className="dashboard modal__body finance-form" style={{ fontSize: '100%' }}>
              <h4>Additional Details</h4>
              <hr />
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className="bold-text">Target Brand</Label>
                    <Select
                      value={brand}
                      options={brandOptions}
                      onChange={(selected) => {
                        setBrand(selected);
                        if (selected.value) {
                          setProspectProperty('targetBrand', selected.value);
                          setErrors({
                            ...errors,
                            brand: false,
                          });
                        }
                      }}
                      placeholder="Please Select Brand"
                      isClearable
                    />

                  </FormGroup>
                  <div className="grey-small-text grey-minus-margin" style={errors.brand ? { color: 'red' } : {}}>Required</div>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label className="bold-text">Deal Owner</Label>
                    <Select
                      value={selectedAdmin}
                      options={adminUsersSuggestions.map(u => ({ value: u.id, label: u.name }))}
                      onChange={(e) => {
                        console.log(e);
                        setSelectedAdmin(e);
                        if (e?.value) {
                          console.log(e.record?.params.hs_object_id);
                          setProspectProperty('cspOwner', e.value);
                        }
                      }}
                      placeholder="Please Select Deal Owner"
                      isClearable
                    />
                  </FormGroup>
                  <div className="grey-small-text grey-minus-margin" style={errors.cspOwner ? { color: 'red' } : {}}>Required</div>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <FormGroup>
                    <Label className="bold-text">V30</Label>
                    <NumberFormat
                      thousandSeparator
                      prefix=""
                      name="numVideos"
                      value={v30}
                      className="form-control"
                      inputMode="numeric"
                      allowEmptyFormatting
                      fixedDecimalScale
                      decimalScale={0}
                      disabled={loading}
                      onValueChange={(values) => {
                        changeV30(values.floatValue);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label className="bold-text">No. Videos</Label>
                    <div>1</div>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <Label className="bold-text">Total Impressions</Label>
                  <div>{WVFormatter.formatIntNumber(Number(v30 || 0) * Number(numVideos || 0))}</div>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <FormGroup>
                    <Label className="bold-text">Price Per Video</Label>
                    <CurrencyField
                      name="guaranteed_payment_contract_amount"
                      className="form-control"
                      placeholder="Price Per Video"
                      setValue={value => setPricePerVideo(value)}
                      value={pricePerVideo}
                      disabled={blacklistAlert.status}
                      onBlur={changePricePerVideo}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label className="bold-text">Projected Gross Profit</Label>
                    <div>{WVFormatter.formatCurrency(Number(projectedGrossProfit))}</div>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label className="bold-text">CPM Total Cost</Label>
                    <div>{WVFormatter.formatCurrency(Number(bhCpmTotalCost))}</div>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label className="bold-text">CPM</Label>
                    <div>{WVFormatter.formatCurrency(Number(cpm))}</div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <FormGroup>
                    <Label className="bold-text">{`Content Release Date (${currentTimezoneAggr()})`}</Label>
                    <DatePicker
                      onChange={handleContentReleaseDatepickerChange}
                      selected={contentReleaseDate ? momentUTCToLocalDate(moment(contentReleaseDate)).toDate() : null}
                      dateFormat="MM/dd/yyyy"
                      placeholderText="Content Release Date"
                      dropDownMode="select"
                      className="form-control"
                      style={{ width: '100%' }}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label className="bold-text">Promo Code</Label>
                    <div>{promoCode}</div>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label className="bold-text">Referral URL</Label>
                    <div><a href={referralURL} target="_blank" rel="noreferrer noopener" >{referralURL}</a></div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className="bold-text">Deal Justification</Label>
                    <Select
                      value={selectedDealJustificationOption.find(a => a.value === dealJustification)}
                      options={selectedDealJustificationOption}
                      onChange={e => setDealJustification(e.value)}
                      placeholder="Select a Deal Justification"
                      isDisabled={blacklistAlert.status}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className="bold-text">Notes</Label>
                    <Input
                      type="textarea"
                      id="notes"
                      name="notes"
                      onChange={e => setNotes(e.target.value)}
                      disabled={blacklistAlert.status}
                      value={notes}
                      // onBlur={validateMediaUrl}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="mt-2 text-center" md={12}>
                  <div className="theme-light checkbox-in-prospects">
                    <FormGroup style={{ fontSize: '125%' }}>
                      <CheckBoxField
                        name="allowUpdate"
                        label="Update HubSpot Contact's YouTube URL"
                        value={allowUpdate}
                        onChange={(e) => { setAllowUpdate(e ? e.target.checked : false); }}
                        disabled={platform.value !== 'youtube_channel_url'}
                      />
                    </FormGroup>
                  </div>
                  <Button className="modal_ok btn-sm mb-0" color="primary" onClick={saveProspectCPM} disabled={disableMediaProperties || blacklistAlert.status || loading}>
                    {loading && <span><div className="spinner-border text-light spinner-border-sm" />  </span>}
                    <span>Save</span>
                  </Button>{' '}
                  <Button
                    className="modal_ok btn-sm mb-0"
                    color="danger"
                    onClick={() => {
                      // setMediaChannelsData({});
                      setProspectData({});
                      setBrand(null);
                      // setProspectOwner({ value: null, label: '-- Not Assigned --' });
                      setMediaUrl('');
                      clearFunction();
                    }}
                  >
                    <span>Cancel</span>
                  </Button>
                </Col>
              </Row>
            </div>
          ) : null}
        </CardBody>
      </Card>
    </Container>
  );
};

AddCpmCreator.propTypes = {
  addNotice: PropTypes.func.isRequired,
  // dispatch: PropTypes.func.isRequired,
  // adminUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  // currentAdminUser: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  adminUsers: state.ResearchReducer.adminUsers,
  currentAdminUser: state.session,
});

export default connect(mapStateToProps)(withNotice(AddCpmCreator));
