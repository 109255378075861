import { useState, useEffect } from 'react';
import { axios } from 'ApiClient';

function useDomains() {
  const [content, setContent] = useState([]);
  
  useEffect(() => {
    async function fetchData() {
      axios({
        method: 'get',
        url: '/api/portal/redirects/get-available-domains',
      }).then((response) => {
        console.log('response', response.data);
        if (response?.data?.success && response.data?.domains) {
          const domainNames = response.data.domains.map((d) => { 
            return { value: d.name, label: `${d.name}  [${d.location}]`, location: d.location };
          });
          setContent(domainNames);
        }
      });
    }

    fetchData();
  }, []);

  return content;
}

export default useDomains;
