import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ButtonToolbar,
  ModalBody,
  Button, Input, InputGroup, InputGroupAddon, InputGroupText, Spinner,
} from 'reactstrap';
import { axios } from 'ApiClient';
import { useSelector } from 'react-redux';
import Hook from '@/shared/hooks';
import RadioButton from '@/shared/components/RadioButton';
import CheckBoxField from '@/shared/components/CheckBox';
import WVFormatter from '@/shared/helpers/WVFormatter';
import { NumericFormat } from 'react-number-format';

export const modalId = 'updateDealTermsModal';


const UpdateDealTermsModal = () => {
  const [loading, setLoading] = useState(false);
  const [includeAll, setIncludeAll] = useState(false);
  const [updateCost, setUpdateCost] = useState(false);
  const [updatePercentage, setUpdatePercentage] = useState(false);
  const [updateContentRequirements, setUpdateContentRequirements] = useState(false);
  const [cost, setCost] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [contentRequirements, setContentRequirements] = useState('');
  const { showSuccess, showError } = Hook.useNoticeHook();
  const { data: dataModal, showModal: isShow, close } = Hook.useModalHook(modalId);
  const reload = Hook.useReloadTableHook();

  const total = useSelector((state) => state.tableRedux.total);
  const tableFilterUrl = useSelector((state) => state.tableRedux.tableFilterUrl);

  if (!dataModal || !dataModal.selectedRows) return false;

  const closeFunc = () => {
    setLoading(false);
    setIncludeAll(false);
    setUpdateCost(false);
    setUpdatePercentage(false);
    setUpdateContentRequirements(false);
    setCost(0);
    setPercentage(0);
    setContentRequirements('');
    close();
  };

  const updateDealTerms = async () => {
    setLoading(true);
    console.log(tableFilterUrl);
    const filterParams = new URLSearchParams(tableFilterUrl);
    const filter = {};
    filterParams.forEach((value, key) => {
      if (filter[key]) {
        filter[key] = Array.isArray(filter[key]) ? [...filter[key], value] : [filter[key], value];
      } else {
        filter[key] = value;
      }
    });
    console.log(filter);
    const properties = {
      ...(updateCost ? { guaranteed_payment_contract_amount: cost } : {}),
      ...(updatePercentage ? { cpa_percentage: percentage } : {}),
      ...(updateContentRequirements ? { contentRequirements } : {}),
    };
    try {
      const res = await axios.post('/api/portal/opportunity/post-update-deal-terms', {
        includeAll,
        opportunitiesIds: dataModal.selectedRows,
        filter: filter || {},
        properties,
      });
      if (res.data.success) {
        showSuccess('Deal Terms have been successfully updated.');
         
        setTimeout(() => reload(), 3000);
        closeFunc();
      } else {
        showError(res.data.error);
      }
    } catch (e) {
      console.error(e);
      showError('Error updating Deal Terms.');
    }
    setLoading(false);
  };

  const { record } = dataModal;
  console.log(record, 'modalInfo');

  return (
    <Modal
      size="md"
      isOpen={isShow}
      toggle={close}
      className='ltr-support theme-light'
    >
      <ModalHeader toggle={close} tag="h4">
        Update Deal Terms
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <div className="d-flex justify-content-center">
            <div>
              Updating Deal Terms, please wait.
              <br/>
              <div className="d-flex justify-content-center mt-3">
                <Spinner color="primary" />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div>{`You have selected ${dataModal.selectedRows.length} result${dataModal.selectedRows.length !== 1 ? 's' : ''} to update deal terms.`}</div>
            <div className="theme-light radio-in-prospects">
              {`Do you wish to select ${total <= 5000 ? `all ${WVFormatter.formatIntNumber(total)}` : `first 5,000`} results?`}<br />
              <RadioButton
                key="includeAll"
                name="action"
                label="&nbsp;Yes"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="includeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
              <RadioButton
                key="notIncludeAll"
                name="action"
                label="&nbsp;No"
                value={includeAll === true ? 'includeAll' : 'notIncludeAll'}
                radioValue="notIncludeAll"
                onChange={(e) => {
                  setIncludeAll(e === 'includeAll');
                }}
              />
            </div>

            <div className="mt-3">
              <CheckBoxField
                name="updateCost"
                label="Update Cost"
                value={updateCost}
                onChange={event => setUpdateCost(event.target?.checked)}
              />
            </div>
            <div>
              <InputGroup className="min-width-150">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>$</InputGroupText>
                </InputGroupAddon>
                <NumericFormat
                  thousandSeparator
                  prefix=""
                  value={cost}
                  className="form-control"
                  inputMode="numeric"
                  allowEmptyFormatting={false}
                  fixedDecimalScale
                  decimalScale={2}
                  disabled={loading}
                  defaultValue={0}
                  min={0}
                  allowNegative={false}
                  placeholder="Enter amount in dollars"
                  onValueChange={(values) => {
                    setCost(values.floatValue);
                  }}
                />
              </InputGroup>
              <span className="text-secondary">Deal Amount</span>
            </div>

            <div className="mt-3">
              <CheckBoxField
                name="updatePercentage"
                label="Update Percentage"
                value={updatePercentage}
                onChange={event => setUpdatePercentage(event.target?.checked)}
              />
            </div>
            <div>
              <InputGroup className="min-width-150">
                <NumericFormat
                  thousandSeparator
                  prefix=""
                  value={percentage}
                  className="form-control"
                  inputMode="numeric"
                  allowEmptyFormatting={false}
                  fixedDecimalScale
                  decimalScale={2}
                  disabled={loading}
                  defaultValue={0}
                  min={0}
                  allowNegative={false}
                  placeholder="Enter Percent"
                  onValueChange={(values) => {
                    setPercentage(values.floatValue);
                  }}
                />
                <InputGroupAddon addonType='append'>
                  <InputGroupText>%</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              <span className="text-secondary">CPA Percentage</span>
            </div>

            <div className="mt-3">
              <CheckBoxField
                name="updateContentRequirements"
                label="Update Content Requirements"
                value={updateContentRequirements}
                onChange={event => setUpdateContentRequirements(event.target?.checked)}
              />
            </div>
            <div>
              <Input
                type="textarea"
                value={contentRequirements}
                onChange={(e) => setContentRequirements(e.target.value)}
                disabled={loading}
              />
            </div>
          </>
        )}
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button
          color="primary"
          onClick={() => updateDealTerms()}
          disabled={loading || !(updateCost || updatePercentage || updateContentRequirements)}
        >
          {loading ? 'Updating Deal Terms...' : 'Update Deal Terms'}
        </Button>&nbsp;&nbsp;
        <Button color="secondary" onClick={closeFunc}>Cancel</Button>

      </ButtonToolbar>
    </Modal>
  );
};

export default UpdateDealTermsModal
