import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import SelectAsync from 'react-select/async';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { axios } from 'ApiClient';
import {
  Row,
  Col,
  Label,
  Button,
} from 'reactstrap';
import {
  toArray,
  resetObject,
} from '../../../../../../shared/helpers/WVUtilities';
import Search from '../../../../../../shared/components/filter/Search';
import { loadInfluencerNameOptions } from '../../../../../../shared/helpers/models/Influencer';
import { dealTypes } from '../../../../../../shared/helpers/models/HubilDeal';
import {
  searchUrl,
  setUrl,
  setPeriodFilters,
} from '../../../../../../shared/helpers/Filter';
import { useSelector } from 'react-redux';

const Actions = ({
  history,
  initialFilterParams,
  showReport,
  setShowReport,
  from,
  to,
  advertiserError,
  setAdvertiserError,
  _filters,
  setFilters,
}) => {
  
  const [loadingAdvertisers, setLoadingAdvertisers] = useState(false);
  const [advertiserOptions, setAdvertiserOptions] = useState([]);

  const [loadingEmployees, setLoadingEmployees] = useState(false);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const dealTypeOptions = Object.values(dealTypes).map(value => ({ value, label: value }));
  const [loadingInfluencers, setLoadingInfluencers] = useState(false);
  const [influencerOptions, setInfluencerOptions] = useState(null);
  // const ref = useRef(true);  

  const tableRecords = useSelector(state => state.table.records);
  const tableLoading = useSelector(state => state.table.loading);
  // 
  const { filters } = _filters;
  const updateFilters = (newFilters) => {
    setFilters({
      filters: { ...filters, ...newFilters },
    });
  };

  const onChangeInfluencer = (options) => {
    setInfluencerOptions(options);
    updateFilters({ influencer: options?.map(o => o.value) });
  };

  const onSearchChange = (val) => {
    updateFilters({ influencerName: val });
    setUrl({ ...filters, influencerName: val }, {}, history, _filters.params);
  };

  useEffect(() => {
    setLoadingAdvertisers(true);
    setLoadingEmployees(true);
    axios.get('/api/portal/finance/get-offers').then((resp) => {
      const brands = {};
      resp.data.offers.forEach((offer) => {
        const {
          _id,
          tuneId,
          companyName,
          isPilot,
        } = offer.advertiser;
        if (!brands[tuneId] && (isPilot === 'Yes')) {
          brands[tuneId] = {
            value: _id,
            label: companyName,
          };
        }
      });
      setAdvertiserOptions(Object.values(brands).sort((a, b) => (a.label > b.label ? 1 : -1)));
      setLoadingAdvertisers(false);
    });
    axios.get('/api/portal/finance/get-employees').then((resp) => {
      setEmployeeOptions(resp.data.employees?.map(r => ({
        value: r.tuneId,
        label: `${r.firstName || ''} ${r.lastName || ''}`
      })).sort((a, b) => (a.label > b.label ? 1 : -1)) || []);
      setLoadingEmployees(false);
    });
    if (filters.influencer?.length > 0) {
      setLoadingInfluencers(true);
      axios.get('/api/portal/influencer/get-options', { params: {
        id: filters.influencer,
        label: 'name',
      } }).then((resp) => {
        const { success, data } = resp.data;
        if (success) {
          setInfluencerOptions(data);
        }
        setLoadingInfluencers(false);
      });
    }
  }, []);

  useEffect(() => {
    setPeriodFilters(updateFilters, 'date', 'Between', from, to);
  }, [from, to]);


  const resetFilters = () => {
    setInfluencerOptions(null);
    setFilters({
      filters: resetObject(filters, ['date~~from', 'date~~to']),
    });
  };

  return (
    <Row>
      <Col>
        <Row>
          <Col xl={3} md={6}>
            <Label className="d-block mt-2 bold-text">
              Brand
            </Label>
            <Select
              isDisabled={loadingAdvertisers}
              value={advertiserOptions.find(r => r.value === filters.advertiser) || null}
              options={advertiserOptions}
              onChange={(r) => { setAdvertiserError(null); updateFilters({ advertiser: r?.value }); }}
              placeholder=""
            />
            <small className="d-block" style={{ color: advertiserError ? 'red' : 'gray' }}>{advertiserError || 'Required'}</small>
          </Col>
          <Col xl={3} md={6}>
            <Label className="d-block mt-2 bold-text">
              Account Manager
            </Label>
            <Select
              isMulti
              isDisabled={loadingEmployees}
              value={employeeOptions.filter(r => toArray(filters.accountManager).includes(r.value))}
              options={employeeOptions}
              onChange={r => updateFilters({ accountManager: r?.map(o => o.value) })}
              placeholder=""
            />
          </Col>
          <Col xl={3} md={6}>
            <Label className="d-block mt-2 bold-text">
              Deal Type
            </Label>
            <Select
              isMulti
              value={dealTypeOptions.filter(r => toArray(filters.dealType).includes(r.value))}
              options={dealTypeOptions}
              onChange={r => updateFilters({ dealType: r?.map(o => o.value) })}
              placeholder=""
            />
          </Col>
          <Col xl={3} md={6}>
            <Label className="d-block mt-2 bold-text">
              Creator
            </Label>
            <SelectAsync
              isMulti
              isDisabled={loadingInfluencers}
              cacheOptions
              value={influencerOptions}
              defaultOptions
              loadOptions={loadInfluencerNameOptions}
              onChange={onChangeInfluencer}
              placeholder="Type to search..."
            />
          </Col>
        </Row>
        {showReport && !tableLoading && (
          <Row>
            <Col className="pt-2">
              <Search
                placeholder="Search Creator"
                value={filters.influencerName || ''}
                onChange={onSearchChange}
              />
            </Col>
          </Row>
        )}
      </Col>
      <Col xl={"auto"}>
        <Row>
          <Col>
            <Label className="d-block mt-2">&nbsp;</Label>
            <Tooltip title="Reset Filters">
              <Button
                color="secondary"
                size="sm"
                onClick={resetFilters}
                style={{
                  width: '100%',
                  maxWidth: '160px',
                  padding: '4px 20px',
                  height: '38px',
                }}
              >
                <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                <span className="btn-text">Reset</span>
              </Button>
            </Tooltip>
          </Col>
          {tableRecords.length > 0 && !tableLoading ? <Col>
            <Label className="d-none d-md-block mt-2">&nbsp;</Label>
            <Button
              color="primary"
              size="lg"
              onClick={() => window.open(
                `/api/resources/PilotTracker/actions/csv?${searchUrl(filters, {}, 'perPage=500000')}`, '_blank'
              ).focus()}
              style={{
                width: '100%',
                minWidth: '160px',
                padding: '2px 20px',
                height: '38px',
              }}
            >
              Download CSV
            </Button>
          </Col> : null}
        </Row>
      </Col>
    </Row>
  );
};

Actions.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
  initialFilterParams: PropTypes.objectOf(PropTypes.any).isRequired,
  showReport: PropTypes.bool.isRequired,
  setShowReport: PropTypes.func.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default Actions;
