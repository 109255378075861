/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import ApiClient from 'ApiClient';
import Hook from '@/shared/hooks';

const roles = {
  owner: 'Owner',
  superAdmin: 'Super Admin',
  admin: 'Admin',
};

const RoleInList = ({ record, updateRecordFunction }) => {
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState(record.params.role);
  const api = new ApiClient();
  const { showError } = Hook.useNoticeHook(); 

  useEffect(() => {
    if (record.params.role !== role) {
      setRole(record.params.role);
    }
  }, [record, role]);

  const handleSelectChange = async (selected) => {
    setLoading(true);
    const value = selected ? selected.value : '';
    if (value === '') {
      return false;
    }

    const data = new FormData();
    data.append('role', value);
    try{
      await api.recordAction({
        resourceId: 'AdminUser',
        recordId: record.id,
        actionName: 'edit',
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      updateRecordFunction(record.id, { role: value });
    } catch (e) {
      showError(e.message);
      console.error(e);
    } finally {
      setLoading(false);
      return true;
    };
  };

  const rolesMap = Object.keys(roles).map(key => ({
    value: key,
    label: roles[key],
  }));
  let isEditable = false;
  if (Array.isArray(record.recordActions)) {
    const editAction = record.recordActions.find(action => action.name === 'edit');
    if (editAction !== undefined) {
      isEditable = true;
    }
  }
  const selected = { value: role, label: roles[role] }
  return (
    <div style={{ minWidth: '100px', maxWidth: '150px' }}>
      <Select
        isDisabled={loading || !isEditable}
        value={selected}
        options={rolesMap}
        onChange={handleSelectChange}
        menuPlacement="auto"
        menuPortalTarget={document.body}
      />
    </div>
  );
};

export default RoleInList;
