import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
// import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import MatTableList from '@/shared/tables/materialTable/TableRedux';
import { setSelectedRecord, removeSelectedRecord, diselectAll, selectAll } from '../../../../../../../redux/reducers/TableReducerDefault';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';

const CollapcedComponent = ({ row, children }) => {
  const data = useSelector((state) => state.tableRedux.selectedRecordsIds);
  const isCollapsed = data.includes(row.id);
  return isCollapsed ? (
    <div>
      {children}
    </div>
  ) : null;
};
CollapcedComponent.propTypes = {
  row: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.node.isRequired,
};

const Table = React.memo(() => {

  const dispatch = useDispatch();
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(true);
  const selected = useSelector((state) => state.tableRedux.selectedRecordsIds);
  console.log(selected)

  const onChange = (orrId, id, record) => {
    if (!selected.includes(orrId)) {
      dispatch(setSelectedRecord({ selectedItemNumber: id, record }));
    } else {
      dispatch(removeSelectedRecord(id));
    }
  };
  return (
    <MatTableList
      useFlex
      enableWarning={false}
      notFoundProps={{ title: 'No Account Manager Found' }}
      useUnflatten
      customColumns={[
        {
          id: 'rank',
          Header: () => 'Rank',
          accessor: (p) => p.params.index,
          // eslint-disable-next-line max-len
          Cell: p => (<span className='font-weight-bold'>{String(Number(p.value))}</span>),
          width: 200,
          className: 'd-flex align-items-top',
          disableSortBy: true,
        },
      
        {
          id: 'accountManager',
          Header: () => 'Account Manager',
          accessor: (record) => record.params.accountManager.firstName,
          // eslint-disable-next-line max-len
          Cell: p => (
            <div>
              <div className='py-3 font-weight-bold'>
                {p.row.original.params.accountManager.firstName} {p.row.original.params.accountManager.lastName}
              </div>
              <CollapcedComponent row={p.row.original}>
                <div className='text-muted mt-3 font-weight-bold' style={{ fontSize: '13px' }}>Brand</div>
                <hr className='my-2'/>
                <div className='text-muted' style={{ fontSize: '13px' }}>
                  {p.row.original.params.items.map((item) => (
                    <div key={item._id}>
                      {item.advertiser.companyName}
                    </div>
                  ))}
                </div>
              </CollapcedComponent>
            </div>
          ),
          width: 200,
          className: '',
          disableSortBy: true,
        },
        {
          id: 'totalWinners',
          Header: () => 'Total Winners',
          accessor: (record) => record.params.count,
          // eslint-disable-next-line max-len
          Cell: p => (
            <div>
              <div className='py-3 font-weight-bold' >
                {p.value}
              </div>
              
              <CollapcedComponent row={p.row.original}>
                <div className='text-muted mt-3 font-weight-bold' style={{ fontSize: '13px' }}>Winner Count</div>
                <hr style={{ marginLeft: '-15px' }} className='my-2'/>
                <div className='text-muted' style={{ fontSize: '13px' }}>
                  {p.row.original.params.items.map((item) => (
                    <div key={item._id}>
                      {item.count}
                    </div>
                  ))}
                </div>
              </CollapcedComponent>
            </div>
          ),
          width: 200,
          className: '',
          disableSortBy: true,
        },
        {
          Header: () => (
            <FormGroup>
              <FormControlLabel className='m-0' control={
                <Checkbox
                  size='medium'
                  checked={selectAllCheckbox}
                  onChange={({ target }) => {
                    if (!target) return;
                    setSelectAllCheckbox(target.checked);
                    if (!target.checked) {
                      dispatch(selectAll());
                    } else {
                      dispatch(diselectAll());
                    }
                  }}
                />
              } label="Collapse" />            
            </FormGroup>
          ),
          accessor: () => null,
          id: 'actions',
          width: 50,
          Cell: (p) => {
            console.log(p)
            const data = useSelector((state) => state.tableRedux.selectedRecordsIds);
            const isCollapsed = data.includes(p.row.original.id);

            return (
              <div style={{ textAlign: 'right', float: 'right' }}>
                <IconButton onClick={() => onChange(p.row.original.id, p.row.index, p.row.original)} size='small'>
                  {isCollapsed ? (<i className="fa  fa-angle-right m-0 mx-1" />) : (<i className="fa  fa-angle-down m-0 mx-1" />)}
                </IconButton>
              </div>
            );
          },
          disableSortBy: true,
          className: 'd-flex align-items-start justify-content-end',
        },
      ]}
    />
  );
});

Table.propTypes = {};

export default Table;
