
import func from '../../../../../../shared/hooks';

const ActionProperties = ({
  offersSuggestions,
  affiliateManagersSuggestions,
  ownersSuggestions,
  tagsSuggestions,
  categorySuggestions,
  CORSuggestions,
}) => [
  {
    initialState: {
      Active: true,
      Deleted: true,
      Blocked: true,
      'Not Set': true,
    },
    func: func.getFromUrlCheckboxFunction,
    funcToUrl: func.getToUrlCheckboxFunction,
    filterName: 'status',
  },
  {
    initialState: {
      Active: true,
      Deleted: true,
      Blocked: true,
      'Not Set': true,
    },
    func: func.getFromUrlCheckboxFunction,
    funcToUrl: func.getToUrlCheckboxFunction,
    filterName: 'companyStatus',
  },
  {
    initialState: {
      Yes: true,
      No: true,
    },
    func: func.getFromUrlCheckboxFunction,
    funcToUrl: func.getToUrlCheckboxFunction,
    filterName: 'inBlackList',
  },

  {
    initialState: {
      Yes: true,
      No: true,
    },
    func: func.getFromUrlCheckboxFunction,
    funcToUrl: func.getToUrlCheckboxFunction,
    filterName: 'taxForm',
  },

  {
    initialState: {
      Yes: true,
      No: true,
    },
    func: func.getFromUrlCheckboxFunction,
    funcToUrl: func.getToUrlCheckboxFunction,
    filterName: 'assignedCategory',
  },
  {
    initialState: { tags: [] },
    func: func.getFromUrlTagsfunction,
    funcToUrl: func.getToUrlTagsfunction,
    options: ownersSuggestions,
    filterName: 'contactOwner',
  },
  {
    initialState: { tags: [] },
    func: func.getFromUrlTagsfunction,
    funcToUrl: func.getToUrlTagsfunction,
    options: affiliateManagersSuggestions,
    filterName: 'accountManager',
  },
  {
    initialState: { tags: [] },
    func: func.getFromUrlTagsfunction,
    funcToUrl: func.getToUrlTagsfunction,
    options: offersSuggestions,
    filterName: 'offers',
  },
  {
    initialState: { tags: [] },
    func: func.getFromUrlTagsfunction,
    funcToUrl: func.getToUrlTagsfunction,
    options: categorySuggestions,
    filterName: 'category',
  },
  {
    initialState: { tags: [] },
    func: func.getFromUrlTagsfunction,
    funcToUrl: func.getToUrlTagsfunction,
    options: CORSuggestions,
    filterName: 'countryOfResidence',
  },
  {
    initialState: { tags: [] },
    func: func.getFromUrlTagsfunction,
    funcToUrl: func.getToUrlTagsfunction,
    options: tagsSuggestions.map(t => ({ id: t.name, name: t.name })),
    filterName: 'tags',
  },
  


];

export default ActionProperties;
