/* eslint-disable jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Input,
  Card,
  CardBody,
  Label,
  FormGroup,
  Button,
  DropdownToggle,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { axios } from 'ApiClient';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Slider from '@material-ui/core/Slider';
import { createTheme } from '@material-ui/core/styles';
// import { createTheme, adaptV4Theme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Select from 'react-select';
import { NumericFormat as NumberFormat } from 'react-number-format';
import DatePicker from 'react-datepicker';
// import { useAsyncDebounce } from 'react-table';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import InfoOutlineIcon from 'mdi-react/InfoOutlineIcon';
import moment from 'moment';
import Breadcrumb from '../../../../../shared/components/BreadCrumbs';
import ChannelsListContainer from './components/ChannelsListContainer';
import SaveSearchModal from './components/SaveSearchModal';
import StyledButton from '../../../../../shared/components/components/ui/styled-button';
import {
  clearSearchResults,
  doTubularSearch,
  saveSearchItemToTheList,
  getMyWatchlists,
  getMySavedSearches,
  getMySavedSearchesForGrid,
  getProspectUploadsSavedSearchesForGrid,
  fetchTubularVideoFacets,
  fetchTubularCreatorFacets,
  updateResearchSearchRow,
} from '../../../../../redux/reducers/admin/ResearchReducer';
import {
  getCountriesOptions,
  getLanguagesOptions,
  setCookie,
  getCookie,
} from '../../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../../shared/components/CheckBox';
import { addNotice } from '../../../../App/store/store';
import getCheckYTChannelUrl from './components/custom/CheckYTChannelUrl';
import KeywordsHashtags from './components/modals/KeywordsHashtags';
import ShowRejectedApprovedBrands from './components/modals/ShowRejectedApprovedBrands';
import ShowHubspotDeals from './components/modals/ShowHubspotDeals';
import AccessComponent from '../../../../../shared/components/auth/AccessComponent';
import { showModalById } from '../../../../../redux/reducers/TableReducer';

const muiTheme = createTheme({
  overrides: {
    MuiSlider: {
      colorPrimary: {
        color: '#70bbfd',
      },
      root: {
        color: '#70bbfd',
      },
      thumb: {
        color: '#70bbfd',
        boxShadow: '#ebebeb 0 2px 2px',
        '&:focus, &:hover, &$active': {
          boxShadow: '#ccc 0 2px 3px 1px',
        },
      },
      track: {
        color: '#70bbfd',
      },
      trackInverted: {
        '& $track': {
          backgroundColor: '#c8e5fe',
        },
      },
    },
  },
});
/* const muiTheme = createTheme(adaptV4Theme({
  overrides: {
    MuiSlider: {
      colorPrimary: {
        color: '#70bbfd',
      },
      root: {
        color: '#70bbfd',
      },
      thumb: {
        color: '#70bbfd',
        boxShadow: '#ebebeb 0 2px 2px',
        '&:focus, &:hover, &$active': {
          boxShadow: '#ccc 0 2px 3px 1px',
        },
      },
      track: {
        color: '#70bbfd',
      },
      trackInverted: {
        '& $track': {
          backgroundColor: '#c8e5fe',
        },
      },
    },
  },
})); */

const SearchList = ({
  dispatch,
  loading,
  searchResults,
  searchMetadata,
  searchTokens,
  watchlists,
  loadingSavedSearchesOptions,
  savedSearchesOptions,
  savedSearchesForGrid,
  prospectUploadsSavedSearchesForGrid,
  loadingVideoFacets,
  videoFacetsResults,
  loadingCreatorFacets,
  creatorFacetsResults,
}) => {
  const [activeTypeCheckbox, setActiveTypeCheckbox] = useState('creator');
  const [searchedText, setSearchedText] = useState('');
  const [queryText, setQueryText] = useState('');
  const [atLeastOneSearchIsMade, setAtLeastOneSearchIsMade] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [showFilters, setShowFilters] = useState(getCookie('researchSearchShowFilters') === 'true');
  const [filtersProp, setFiltersProp] = useState({});
  const [sortOptionsProp, setSortOptionsProp] = useState({});
  const creatorSortTypesOptions = [
    { value: 'true', label: 'Smallest to largest' },
    { value: 'false', label: 'Largest to smallest' },
  ];
  const videoSortTypesOptions = [
    { value: 'false', label: 'Smallest to largest' },
    { value: 'true', label: 'Largest to smallest' },
  ];
  const videoSortTypesOptionsDates = [
    { value: 'true', label: 'Newest to oldest' },
    { value: 'false', label: 'Oldest to newest' },
  ];
  const creatorTypesOptions = [
    { value: 'influencer', label: 'Creator' },
    { value: 'media company', label: 'Media & Ent.' },
    { value: 'aggregator', label: 'Aggregator' },
    { value: 'brand', label: 'Brand' },
  ];
  const [countriesOptions] = useState(getCountriesOptions());
  const [languagesOptions] = useState(getLanguagesOptions());
  const [youTubeCategoriesOptionsLoading, setYouTubeCategoriesOptionsLoading] = useState(false);
  const [youTubeCategoriesOptions, setYouTubeCategoriesOptions] = useState([]);
  const [creatorYouTubeAudienceAgeRange, setCreatorYouTubeAudienceAgeRange] = useState([13, 60]);
  const [creatorYouTubeAudienceMinAgeRange, setCreatorYouTubeAudienceMinAgeRange] = useState(0);
  const [creatorYouTubeAudienceMinMaleRange, setCreatorYouTubeAudienceMinMaleRange] = useState(0);
  const [creatorYouTubeAudienceMinFemaleRange, setCreatorYouTubeAudienceMinFemaleRange] = useState(0);
  const [creatorYouTubeAudienceLocationMin, setCreatorYouTubeAudienceLocationMin] = useState(0);
  const [creatorYouTubeAudienceLocationCountry, setCreatorYouTubeAudienceLocationCountry] = useState('');
  const [creatorYouTubeAudienceLocationOption, setCreatorYouTubeAudienceLocationOption] = useState(null);
  const [creatorYouTubeV30Min, setCreatorYouTubeV30Min] = useState(null);
  const [creatorYouTubeV30Max, setCreatorYouTubeV30Max] = useState(null);
  const [creatorExcludeYouTubeCategories, setCreatorExcludeYouTubeCategories] = useState(null);
  const [creatorIncludeYouTubeCategories, setCreatorIncludeYouTubeCategories] = useState(null);
  const [creatorFacebookAudienceAgeRange, setCreatorFacebookAudienceAgeRange] = useState([13, 60]);
  const [creatorFacebookAudienceMinAgeRange, setCreatorFacebookAudienceMinAgeRange] = useState(0);
  const [creatorFacebookAudienceLocationMin, setCreatorFacebookAudienceLocationMin] = useState(0);
  const [creatorFacebookAudienceLocationCountry, setCreatorFacebookAudienceLocationCountry] = useState('');
  const [creatorFacebookAudienceLocationOption, setCreatorFacebookAudienceLocationOption] = useState(null);
  const [creatorFacebookV30Min, setCreatorFacebookV30Min] = useState(null);
  const [creatorFacebookV30Max, setCreatorFacebookV30Max] = useState(null);
  const [creatorViewsMin, setCreatorViewsMin] = useState(null);
  const [creatorViewsMax, setCreatorViewsMax] = useState(null);
  const [gridUpdateCounter, setGridUpdateCounter] = useState(0);
  const [selectedCreatorPlatforms, setSelectedCreatorPlatforms] = useState({
    youtube: true,
    facebook: true,
    instagram: true,
    twitter: true,
    twitch: true,
  });
  const creatorSortParametersOptions = [
    { value: 'youtube.influencer_score', label: 'Score' },
    { value: 'youtube.monthly_v30', label: 'V30' },
    { value: 'youtube.monthly_er30', label: 'ER30' },
  ];
  const [creatorSortParameter, setCreatorSortParameter] = useState({ value: 'youtube.influencer_score', label: 'Score' });
  const [creatorSortType, setCreatorSortType] = useState({ value: 'false', label: 'Largest to smallest' });
  const creatorLastUploadOptions = [
    { value: 'last_7', label: 'Last 7 days' },
    { value: 'last_30', label: 'Last 30 days' },
    { value: 'last_90', label: 'Last 90 days' },
  ];
  const [creatorLastUpload, setCreatorLastUpload] = useState(null);
  const [creatorLanguages, setCreatorLanguages] = useState([]);
  const [creatorCountries, setCreatorCountries] = useState([]);
  const [creatorTypes, setCreatorTypes] = useState([
    { value: 'influencer', label: 'Creator' },
  ]);
  const [creatorContentGenresOptions, setCreatorContentGenresOptions] = useState([]);
  const [creatorContentGenres, setCreatorContentGenres] = useState([]);

  const [videoViewsMin, setVideoViewsMin] = useState(null);
  const [videoViewsMax, setVideoViewsMax] = useState(null);
  const [videoEngagementsMin, setVideoEngagementsMin] = useState(null);
  const [videoEngagementsMax, setVideoEngagementsMax] = useState(null);
  const [maxDate] = useState(new Date());
  const [minDate] = useState(new Date().setDate(new Date().getDate() - 90));
  const [videoLastUploadMin, setVideoLastUploadMin] = useState(null);
  const [videoLastUploadMax, setVideoLastUploadMax] = useState(null);
  const [videoDurationMin, setVideoDurationMin] = useState(300);
  const [videoDurationMax, setVideoDurationMax] = useState(null);
  const [selectedVideoPlatforms, setSelectedVideoPlatforms] = useState({
    youtube: true,
    facebook: true,
    instagram: true,
    twitter: true,
    twitch: true,
  });
  const [selectedVideoSearchIn, setSelectedVideoSearchIn] = useState({
    description: true,
    keywords: true,
    topics: false,
  });
  const [videoSortParameter, setVideoSortParameter] = useState({ value: 'upload_date', label: 'Published on' });
  const [videoSortType, setVideoSortType] = useState({ value: 'true', label: 'Newest to oldest' });
  const videoSortParametersOptions = [
    { value: 'upload_date', label: 'Published on' },
    { value: 'views', label: 'Views' },
  ];
  const [videoLanguages, setVideoLanguages] = useState([]);
  const [videoCategoriesOptions, setVideoCategoriesOptions] = useState([]);
  const [videoIncludeCategories, setVideoIncludeCategories] = useState([]);
  const [videoExcludeCategories, setVideoExcludeCategories] = useState([]);
  const [videoCreatorTypes, setVideoCreatorTypes] = useState([
    { value: 'influencer', label: 'Creator' },
  ]);
  const [videoCreatorCountries, setVideoCreatorCountries] = useState([]);
  const [videoContentGenresOptions, setVideoContentGenresOptions] = useState([]);
  const [videoContentGenres, setVideoContentGenres] = useState([]);

  const [podcastGenresOptionsLoading, setPodcastGenresOptionsLoading] = useState(false);
  const [podcastGenresOptions, setPodcastGenresOptions] = useState([]);
  const podcastTypeOptions = [
    { value: 'podcast', label: 'Podcasts' },
    { value: 'episode', label: 'Episodes' },
  ];
  const [podcastType, setPodcastType] = useState({ value: 'podcast', label: 'Podcasts' });
  const [podcastOnlyIn, setPodcastOnlyIn] = useState({
    title: true,
    description: true,
    author: false,
    audio: false,
  });
  const [podcastGenres, setPodcastGenres] = useState([]);
  const [podcastLanguageOptionsLoading, setPodcastLanguageOptionsLoading] = useState(false);
  const [podcastLanguageOptions, setPodcastLanguageOptions] = useState([]);
  const [podcastLanguage, setPodcastLanguage] = useState({ value: 'English', label: 'English' });
  const [podcastRegionOptionsLoading, setPodcastRegionOptionsLoading] = useState(false);
  const [podcastRegionOptions, setPodcastRegionOptions] = useState([]);
  const [podcastRegion, setPodcastRegion] = useState({ value: 'us', label: 'United States' });
  const podcastSortOptions = [
    { value: '0', label: 'Sort by Relevance' },
    { value: '1', label: 'Sort by Date' },
  ];
  const [podcastSort, setPodcastSort] = useState({ value: '0', label: 'Sort by Relevance' }); // 0 - sort by relevance, 1 - sort by date
  const [podcastPublishedAfter, setPodcastPublishedAfter] = useState(null);
  const [podcastPublishedBefore, setPodcastPublishedBefore] = useState(null);
  const [podcastAutoSearch, setPodcastAutoSearch] = useState(0);
  const [currentSavedSearchOption, setCurrentSavedSearchOption] = useState(null);
  const [savingSearch, setSavingSearch] = useState(false);
  const [saveSearchModalMessage, setSaveSearchModalMessage] = useState('');
  const [showSaveSearchModal, setShowSaveSearchModal] = useState(null);

  useEffect(() => {
    setCookie('researchSearchShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  useEffect(() => {
    // pre-fill podcast search data. Need this for youtube browser extension
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params && params.type && params.type === 'podcast' && podcastAutoSearch === 0) {
      // console.error(params.search);

      setQueryText(params.search);
      setActiveTypeCheckbox('podcast');
      setPodcastOnlyIn({
        title: true,
        description: false,
        author: false,
        audio: false,
      });
      setPodcastAutoSearch(podcastAutoSearch + 1);
    }
  }, [activeTypeCheckbox, queryText]);

  useEffect(() => {
    dispatch(getMySavedSearches());
    dispatch(getMySavedSearchesForGrid());
    dispatch(getProspectUploadsSavedSearchesForGrid());
    setPodcastGenresOptionsLoading(true);
    axios.get('/api/portal/research/get-podcast-genres').then((res) => {
      if (res.data && res.data.success) {
        if (res.data.podcastGenresOptions) {
          setPodcastGenresOptions(res.data.podcastGenresOptions);
        }
      }
      setPodcastGenresOptionsLoading(false);
    }).catch((error) => {
      console.error(error);
      setPodcastGenresOptionsLoading(false);
    });

    setPodcastLanguageOptionsLoading(true);
    axios.get('/api/portal/research/get-podcast-languages').then((res) => {
      if (res.data && res.data.success) {
        if (res.data.podcastLanguagesOptions) {
          setPodcastLanguageOptions(res.data.podcastLanguagesOptions);
        }
      }
      setPodcastLanguageOptionsLoading(false);
    }).catch((error) => {
      console.error(error);
      setPodcastLanguageOptionsLoading(false);
    });

    setPodcastRegionOptionsLoading(true);
    axios.get('/api/portal/research/get-podcast-regions').then((res) => {
      if (res.data && res.data.success) {
        if (res.data.podcastRegionsOptions) {
          setPodcastRegionOptions(res.data.podcastRegionsOptions);
        }
      }
      setPodcastRegionOptionsLoading(false);
    }).catch((error) => {
      console.error(error);
      setPodcastRegionOptionsLoading(false);
    });
    axios.get('/api/portal/research/get-youtube-categories').then((res) => {
      if (res.data && res.data.success) {
        if (res.data.youTubeCategoriesOptions) {
          setYouTubeCategoriesOptions(res.data.youTubeCategoriesOptions);
        }
      }
      setYouTubeCategoriesOptionsLoading(false);
    }).catch((error) => {
      console.error(error);
      setYouTubeCategoriesOptionsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (videoFacetsResults && videoFacetsResults.length > 0) {
      const videoCategoriesResult = videoFacetsResults.find(item => item.filter === 'video_categories');
      if (videoCategoriesResult && videoCategoriesResult.metrics && videoCategoriesResult.metrics.length > 0) {
        setVideoCategoriesOptions(() => {
          try {
            return videoCategoriesResult.metrics
              ?.map(item => ({ value: item.id, label: item.id }))
              ?.sort((a, b) => {
                if (a.label < b.label) return -1;
                if (a.label > b.label) return 1;
                return 0;
              });
          } catch (error) {
            console.log('Error with videoFacetsResults: ', error);
            console.log('videoFacetsResults: ', videoFacetsResults);
            return [];
          }
        });
      }
      const videoCreatorGenresResult = videoFacetsResults.find(item => item.filter === 'creator_genres');
      if (videoCreatorGenresResult && videoCreatorGenresResult.metrics && videoCreatorGenresResult.metrics.length > 0) {
        setVideoContentGenresOptions(() => {
          try {
            return videoCreatorGenresResult.metrics
              ?.map(item => ({ value: item.id, label: item.title }))
              ?.sort((a, b) => {
                if (a.label < b.label) return -1;
                if (a.label > b.label) return 1;
                return 0;
              });
          } catch (error) {
            console.log('Error with videoFacetsResults: ', error);
            console.log('videoFacetsResults: ', videoFacetsResults);
            return [];
          }
        });
      }
    }
  }, [videoFacetsResults]);

  useEffect(() => {
    if (creatorFacetsResults && creatorFacetsResults.length > 0) {
      const creatorContentGenresResults = creatorFacetsResults.find(item => item.filter === 'creator_genres');
      if (creatorContentGenresResults && creatorContentGenresResults.metrics && creatorContentGenresResults.metrics.length > 0) {
        setCreatorContentGenresOptions(() => {
          try {
            return creatorContentGenresResults.metrics
              ?.map(item => ({ value: item.id, label: item.title }))
              ?.sort((a, b) => {
                if (a.label < b.label) return -1;
                if (a.label > b.label) return 1;
                return 0;
              });
          } catch (error) {
            console.log('Error with creatorFacetsResults: ', error);
            console.log('creatorFacetsResults: ', creatorFacetsResults);
            return [];
          }
        });
      }
    }
  }, [creatorFacetsResults]);
  useEffect(() => {
    dispatch(fetchTubularVideoFacets('', {}));
    dispatch(fetchTubularCreatorFacets('', {}));
  }, []);

  useEffect(() => {
    if (videoSortParameter && videoSortParameter.value === 'upload_date') {
      setVideoSortType(videoSortType.value === 'true'
        ? { value: 'true', label: 'Newest to oldest' }
        : { value: 'false', label: 'Oldest to newest' });
    } else {
      setVideoSortType(videoSortType.value === 'false'
        ? { value: 'false', label: 'Smallest to largest' }
        : { value: 'true', label: 'Largest to smallest' });
    }
  }, [videoSortParameter]);

  useEffect(() => {
    if (
      videoLastUploadMin !== null
      && videoLastUploadMax !== null
      && (videoLastUploadMin > videoLastUploadMax)
    ) {
      setVideoLastUploadMax(null);
    }
  }, [videoLastUploadMin]);

  useEffect(() => {
    if (
      videoLastUploadMin !== null
      && videoLastUploadMax !== null
      && (videoLastUploadMin > videoLastUploadMax)
    ) {
      setVideoLastUploadMin(null);
    }
  }, [videoLastUploadMax]);

  useEffect(() => {
    if (
      podcastPublishedAfter !== null
      && podcastPublishedBefore !== null
      && (podcastPublishedAfter >= podcastPublishedBefore)
    ) {
      setPodcastPublishedBefore(null);
    }
  }, [podcastPublishedAfter]);

  useEffect(() => {
    if (
      podcastPublishedAfter !== null
      && podcastPublishedBefore !== null
      && (podcastPublishedAfter >= podcastPublishedBefore)
    ) {
      setPodcastPublishedAfter(null);
    }
  }, [podcastPublishedBefore]);

  useEffect(() => {
    if (creatorYouTubeAudienceLocationOption && creatorYouTubeAudienceLocationOption.value) {
      setCreatorYouTubeAudienceLocationCountry(creatorYouTubeAudienceLocationOption.value);
    } else setCreatorYouTubeAudienceLocationCountry('');
  }, [creatorYouTubeAudienceLocationOption]);

  useEffect(() => {
    if (creatorFacebookAudienceLocationOption && creatorFacebookAudienceLocationOption.value) {
      setCreatorFacebookAudienceLocationCountry(creatorFacebookAudienceLocationOption.value);
    } else setCreatorFacebookAudienceLocationCountry('');
  }, [creatorFacebookAudienceLocationOption]);

  const resetFilters = (savedSearch = null) => {
    function getTubularPlatformsFromArray(array) {
      return {
        youtube: array.includes('youtube'),
        facebook: array.includes('facebook'),
        instagram: array.includes('instagram'),
        twitter: array.includes('twitter'),
        twitch: array.includes('twitch'),
      };
    }
    function getOnlyInFromArray(array) {
      return {
        title: array.includes('title'),
        description: array.includes('description'),
        author: array.includes('author'),
        audio: array.includes('audio'),
      };
    }
    let hasErrors = false;
    setActiveTypeCheckbox(savedSearch?.activeTypeCheckbox || 'creator');
    setQueryText(savedSearch?.searchQuery || '');
    setCreatorYouTubeAudienceAgeRange(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'youtube');
          if (foundAudienceFilters?.audienceAge?.min && foundAudienceFilters?.audienceAge?.max) {
            return [foundAudienceFilters.audienceAge.min, foundAudienceFilters.audienceAge.max];
          }
        }
        return [13, 60];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorYouTubeAudienceAgeRange(): ', error);
        return [13, 60];
      }
    });
    setCreatorYouTubeAudienceMinAgeRange(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'youtube');
          if (foundAudienceFilters && typeof foundAudienceFilters.audienceMinAgeRange !== 'undefined' && foundAudienceFilters.audienceMinAgeRange !== null) {
            return foundAudienceFilters.audienceMinAgeRange;
          }
        }
        return 0;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorYouTubeAudienceMinAgeRange(): ', error);
        return 0;
      }
    });
    setCreatorYouTubeAudienceMinMaleRange(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'youtube');
          if (foundAudienceFilters && typeof foundAudienceFilters.audienceMinMaleRange !== 'undefined' && foundAudienceFilters.audienceMinMaleRange !== null) {
            return foundAudienceFilters.audienceMinMaleRange;
          }
        }
        return 0;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorYouTubeAudienceMinMaleRange(): ', error);
        return 0;
      }
    });
    setCreatorYouTubeAudienceMinFemaleRange(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'youtube');
          if (foundAudienceFilters && typeof foundAudienceFilters.audienceMinFemaleRange !== 'undefined' && foundAudienceFilters.audienceMinFemaleRange !== null) {
            return foundAudienceFilters.audienceMinFemaleRange;
          }
        }
        return 0;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorYouTubeAudienceMinFemaleRange(): ', error);
        return 0;
      }
    });

    setCreatorYouTubeAudienceLocationMin(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'youtube');
          if (foundAudienceFilters?.audienceLocations?.length > 0 && typeof foundAudienceFilters.audienceLocations[0]?.min !== 'undefined' && foundAudienceFilters.audienceLocations[0]?.min !== null) {
            return foundAudienceFilters.audienceLocations[0].min;
          }
        }
        return 0;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorYouTubeAudienceLocationMin(): ', error);
        return 0;
      }
    });
    setCreatorYouTubeAudienceLocationCountry(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'youtube');
          if (foundAudienceFilters?.audienceLocations?.length > 0 && foundAudienceFilters.audienceLocations[0]?.country) {
            return foundAudienceFilters.audienceLocations[0].country;
          }
        }
        return '';
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorYouTubeAudienceLocationCountry(): ', error);
        return '';
      }
    });
    setCreatorYouTubeAudienceLocationOption(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'youtube');
          if (foundAudienceFilters?.audienceLocations?.length > 0 && foundAudienceFilters.audienceLocations[0]?.country && countriesOptions?.length > 0) {
            return countriesOptions.find(option => option.value === foundAudienceFilters.audienceLocations[0].country);
          }
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorYouTubeAudienceLocationOption(): ', error);
        return null;
      }
    });
    setCreatorYouTubeV30Min(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'youtube');
          if (foundAudienceFilters?.v30?.min) {
            return foundAudienceFilters.v30.min;
          }
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorYouTubeV30Min(): ', error);
        return null;
      }
    });
    setCreatorYouTubeV30Max(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'youtube');
          if (foundAudienceFilters?.v30?.max) {
            return foundAudienceFilters.v30.max;
          }
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorYouTubeV30Max(): ', error);
        return null;
      }
    });
    setCreatorFacebookAudienceAgeRange(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'facebook');
          if (foundAudienceFilters?.audienceAge?.min && foundAudienceFilters?.audienceAge?.max) {
            return [foundAudienceFilters.audienceAge.min, foundAudienceFilters.audienceAge.max];
          }
        }
        return [13, 60];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorFacebookAudienceAgeRange(): ', error);
        return [13, 60];
      }
    });
    setCreatorFacebookAudienceMinAgeRange(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'facebook');
          if (foundAudienceFilters && typeof foundAudienceFilters.audienceMinAgeRange !== 'undefined' && foundAudienceFilters.audienceMinAgeRange !== null) {
            return foundAudienceFilters.audienceMinAgeRange;
          }
        }
        return 0;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorFacebookAudienceMinAgeRange(): ', error);
        return 0;
      }
    });
    setCreatorFacebookAudienceLocationMin(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'facebook');
          if (foundAudienceFilters?.audienceLocations?.length > 0 && typeof foundAudienceFilters.audienceLocations[0]?.min !== 'undefined' && foundAudienceFilters.audienceLocations[0]?.min !== null) {
            return foundAudienceFilters.audienceLocations[0].min;
          }
        }
        return 0;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorFacebookAudienceLocationMin(): ', error);
        return 0;
      }
    });
    setCreatorFacebookAudienceLocationCountry(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'facebook');
          if (foundAudienceFilters?.audienceLocations?.length > 0 && foundAudienceFilters.audienceLocations[0]?.country) {
            return foundAudienceFilters.audienceLocations[0].country;
          }
        }
        return '';
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorFacebookAudienceLocationCountry(): ', error);
        return '';
      }
    });
    setCreatorFacebookAudienceLocationOption(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'facebook');
          if (foundAudienceFilters?.audienceLocations?.length > 0 && foundAudienceFilters.audienceLocations[0]?.country && countriesOptions?.length > 0) {
            return countriesOptions.find(option => option.value === foundAudienceFilters.audienceLocations[0].country);
          }
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorFacebookAudienceLocationOption(): ', error);
        return null;
      }
    });
    setCreatorFacebookV30Min(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'facebook');
          if (foundAudienceFilters?.v30?.min) {
            return foundAudienceFilters.v30.min;
          }
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorFacebookV30Min(): ', error);
        return null;
      }
    });
    setCreatorFacebookV30Max(() => {
      try {
        if (savedSearch?.creatorFilters?.audience && savedSearch.creatorFilters.audience.length > 0) {
          const foundAudienceFilters = savedSearch.creatorFilters.audience.find(audience => audience.platform === 'facebook');
          if (foundAudienceFilters?.v30?.max) {
            return foundAudienceFilters.v30.max;
          }
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorFacebookV30Max(): ', error);
        return null;
      }
    });
    setCreatorViewsMin(() => {
      try {
        if (typeof savedSearch?.creatorFilters?.views?.min !== 'undefined' && savedSearch?.creatorFilters?.views?.min !== null) {
          return savedSearch.creatorFilters.views.min;
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorViewsMin(): ', error);
        return null;
      }
    });
    setCreatorViewsMax(() => {
      try {
        if (typeof savedSearch?.creatorFilters?.views?.max !== 'undefined' && savedSearch?.creatorFilters?.views?.max !== null) {
          return savedSearch.creatorFilters.views.max;
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorViewsMax(): ', error);
        return null;
      }
    });
    setSelectedCreatorPlatforms(() => {
      try {
        if (typeof savedSearch?.creatorFilters?.platforms !== 'undefined' && savedSearch?.creatorFilters?.platforms !== null && Array.isArray(savedSearch.creatorFilters.platforms)) {
          return getTubularPlatformsFromArray(savedSearch.creatorFilters.platforms);
        }
        return {
          youtube: true,
          facebook: true,
          instagram: true,
          twitter: true,
          twitch: true,
        };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setSelectedCreatorPlatforms(): ', error);
        return {
          youtube: true,
          facebook: true,
          instagram: true,
          twitter: true,
          twitch: true,
        };
      }
    });
    setCreatorSortParameter(() => {
      try {
        if (savedSearch?.creatorFilters?.sortBy) {
          const foundOption = creatorSortParametersOptions.find(option => option.value === savedSearch.creatorFilters.sortBy);
          if (foundOption) {
            return foundOption;
          }
        }
        return { value: 'youtube.influencer_score', label: 'Score' };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorSortParameter(): ', error);
        return { value: 'youtube.influencer_score', label: 'Score' };
      }
    });
    setCreatorSortType(() => {
      try {
        if (typeof savedSearch?.creatorFilters?.sortOrderAscending !== 'undefined' && savedSearch?.creatorFilters?.sortOrderAscending !== null) {
          const foundOption = creatorSortTypesOptions.find(option => option.value === savedSearch.creatorFilters.sortOrderAscending.toString());
          if (foundOption) {
            return foundOption;
          }
        }
        return { value: 'false', label: 'Largest to smallest' };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorSortType(): ', error);
        return { value: 'false', label: 'Largest to smallest' };
      }
    });
    setCreatorLastUpload(() => {
      try {
        if (typeof savedSearch?.creatorFilters?.creatorLastUpload !== 'undefined' && savedSearch?.creatorFilters?.creatorLastUpload !== null) {
          const foundOption = creatorLastUploadOptions.find(option => option.value === savedSearch.creatorFilters.creatorLastUpload);
          if (foundOption) {
            return foundOption;
          }
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorLastUpload(): ', error);
        return null;
      }
    });
    setCreatorLanguages(() => {
      try {
        if (typeof savedSearch?.creatorFilters?.creatorLanguages !== 'undefined' && savedSearch?.creatorFilters?.creatorLanguages !== null && Array.isArray(savedSearch.creatorFilters.creatorLanguages)) {
          const languages = [];
          savedSearch.creatorFilters.creatorLanguages.forEach((language) => {
            const foundOption = languagesOptions.find(option => option.value === language);
            if (foundOption) languages.push(foundOption);
          });
          return languages;
        }
        return [];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorLanguages(): ', error);
        return [];
      }
    });
    setCreatorCountries(() => {
      try {
        if (typeof savedSearch?.creatorFilters?.creatorCountries !== 'undefined' && savedSearch?.creatorFilters?.creatorCountries !== null && Array.isArray(savedSearch.creatorFilters.creatorCountries)) {
          const countries = [];
          savedSearch.creatorFilters.creatorCountries.forEach((country) => {
            const foundOption = countriesOptions.find(option => option.value === country);
            if (foundOption) countries.push(foundOption);
          });
          return countries;
        }
        return [];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorCountries(): ', error);
        return [];
      }
    });
    setCreatorTypes(() => {
      try {
        if (typeof savedSearch?.creatorFilters?.creatorTypes !== 'undefined' && savedSearch?.creatorFilters?.creatorTypes !== null && Array.isArray(savedSearch.creatorFilters.creatorTypes)) {
          const types = [];
          savedSearch.creatorFilters.creatorTypes.forEach((type) => {
            const foundOption = creatorTypesOptions.find(option => option.value === type);
            if (foundOption) types.push(foundOption);
          });
          return types;
        }
        return [
          { value: 'influencer', label: 'Creator' },
        ];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorTypes(): ', error);
        return [
          { value: 'influencer', label: 'Creator' },
        ];
      }
    });
    setCreatorContentGenres(() => {
      try {
        if (typeof savedSearch?.creatorFilters?.contentGenres !== 'undefined' && savedSearch?.creatorFilters?.contentGenres !== null && Array.isArray(savedSearch.creatorFilters.contentGenres)) {
          return savedSearch.creatorFilters.contentGenres;
        }
        return [];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorContentGenres(): ', error);
        return [];
      }
    });
    setCreatorIncludeYouTubeCategories(() => {
      try {
        if (typeof savedSearch?.creatorFilters?.includeYouTubeCategories !== 'undefined' && savedSearch?.creatorFilters?.includeYouTubeCategories !== null && Array.isArray(savedSearch.creatorFilters.includeYouTubeCategories)) {
          return savedSearch.creatorFilters.includeYouTubeCategories;
        }
        return [];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorIncludeYouTubeCategories(): ', error);
        return [];
      }
    });
    setCreatorExcludeYouTubeCategories(() => {
      try {
        if (typeof savedSearch?.creatorFilters?.excludeYouTubeCategories !== 'undefined' && savedSearch?.creatorFilters?.excludeYouTubeCategories !== null && Array.isArray(savedSearch.creatorFilters.excludeYouTubeCategories)) {
          return savedSearch.creatorFilters.excludeYouTubeCategories;
        }
        return [];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setCreatorExcludeYouTubeCategories(): ', error);
        return [];
      }
    });

    setVideoViewsMin(() => {
      try {
        if (typeof savedSearch?.videoFilters?.views?.min !== 'undefined' && savedSearch?.videoFilters?.views?.min !== null) {
          return savedSearch.videoFilters.views.min;
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoViewsMin(): ', error);
        return null;
      }
    });
    setVideoViewsMax(() => {
      try {
        if (typeof savedSearch?.videoFilters?.views?.max !== 'undefined' && savedSearch?.videoFilters?.views?.max !== null) {
          return savedSearch.videoFilters.views.max;
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoViewsMax(): ', error);
        return null;
      }
    });
    setVideoEngagementsMin(() => {
      try {
        if (typeof savedSearch?.videoFilters?.engagements?.min !== 'undefined' && savedSearch?.videoFilters?.engagements?.min !== null) {
          return savedSearch.videoFilters.engagements.min;
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoEngagementsMin(): ', error);
        return null;
      }
    });
    setVideoEngagementsMax(() => {
      try {
        if (typeof savedSearch?.videoFilters?.engagements?.max !== 'undefined' && savedSearch?.videoFilters?.engagements?.max !== null) {
          return savedSearch.videoFilters.engagements.max;
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoEngagementsMax(): ', error);
        return null;
      }
    });
    setVideoDurationMin(() => {
      try {
        if (typeof savedSearch?.videoFilters?.video_duration?.min !== 'undefined' && savedSearch?.videoFilters?.video_duration?.min !== null) {
          return savedSearch.videoFilters.video_duration.min;
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoDurationMin(): ', error);
        return 300;
      }
    });
    setVideoDurationMax(() => {
      try {
        if (typeof savedSearch?.videoFilters?.video_duration?.max !== 'undefined' && savedSearch?.videoFilters?.video_duration?.max !== null) {
          return savedSearch.videoFilters.video_duration.max;
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoDurationMax(): ', error);
        return null;
      }
    });
    setVideoLastUploadMin(() => {
      try {
        if (savedSearch?.videoFilters?.uploadDate?.min) {
          return new Date(savedSearch.videoFilters.uploadDate.min);
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoLastUploadMin(): ', error);
        return null;
      }
    });
    setVideoLastUploadMax(() => {
      try {
        if (savedSearch?.videoFilters?.uploadDate?.max) {
          return new Date(savedSearch.videoFilters.uploadDate.max);
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoLastUploadMax(): ', error);
        return null;
      }
    });
    setSelectedVideoPlatforms(() => {
      try {
        if (typeof savedSearch?.videoFilters?.videoPlatforms !== 'undefined' && savedSearch?.videoFilters?.videoPlatforms !== null && Array.isArray(savedSearch.videoFilters.videoPlatforms)) {
          return getTubularPlatformsFromArray(savedSearch.videoFilters.videoPlatforms);
        }
        return {
          youtube: true,
          facebook: true,
          instagram: true,
          twitter: true,
          twitch: true,
        };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setSelectedVideoPlatforms(): ', error);
        return {
          youtube: true,
          facebook: true,
          instagram: true,
          twitter: true,
          twitch: true,
        };
      }
    });
    setSelectedVideoSearchIn(() => {
      try {
        return {
          description: (typeof savedSearch?.videoFilters?.searchDescription !== 'undefined' && savedSearch?.videoFilters?.searchDescription !== null) ? savedSearch?.videoFilters.searchDescription : false,
          keywords: (typeof savedSearch?.videoFilters?.searchKeywords !== 'undefined' && savedSearch?.videoFilters?.searchKeywords !== null) ? savedSearch?.videoFilters.searchKeywords : false,
          topics: (typeof savedSearch?.videoFilters?.searchTopics !== 'undefined' && savedSearch?.videoFilters?.searchTopics !== null) ? savedSearch?.videoFilters.searchTopics : false,
        };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setSelectedVideoSearchIn(): ', error);
        return {
          description: false,
          keywords: false,
          topics: false,
        };
      }
    });
    setVideoLanguages(() => {
      try {
        if (typeof savedSearch?.videoFilters?.videoLanguages !== 'undefined' && savedSearch?.videoFilters?.videoLanguages !== null && Array.isArray(savedSearch.videoFilters.videoLanguages)) {
          const languages = [];
          savedSearch.videoFilters.videoLanguages.forEach((language) => {
            const foundOption = languagesOptions.find(option => option.value === language);
            if (foundOption) languages.push(foundOption);
          });
          return languages;
        }
        return [];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoLanguages(): ', error);
        return [];
      }
    });
    setVideoIncludeCategories(() => {
      try {
        if (typeof savedSearch?.videoFilters?.includeVideoCategories !== 'undefined' && savedSearch?.videoFilters?.includeVideoCategories !== null && Array.isArray(savedSearch.videoFilters.includeVideoCategories)) {
          return savedSearch.videoFilters.includeVideoCategories?.map(category => ({ value: category, label: category }));
        }
        return [];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoIncludeCategories(): ', error);
        return [];
      }
    });
    setVideoExcludeCategories(() => {
      try {
        if (typeof savedSearch?.videoFilters?.excludeVideoCategories !== 'undefined' && savedSearch?.videoFilters?.excludeVideoCategories !== null && Array.isArray(savedSearch.videoFilters.excludeVideoCategories)) {
          return savedSearch.videoFilters.excludeVideoCategories?.map(category => ({ value: category, label: category }));
        }
        return [];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoExcludeCategories(): ', error);
        return [];
      }
    });
    setVideoCreatorCountries(() => {
      try {
        if (typeof savedSearch?.videoFilters?.creatorCountries !== 'undefined' && savedSearch?.videoFilters?.creatorCountries !== null && Array.isArray(savedSearch.videoFilters.creatorCountries)) {
          const countries = [];
          savedSearch.videoFilters.creatorCountries.forEach((country) => {
            const foundOption = countriesOptions.find(option => option.value === country);
            if (foundOption) countries.push(foundOption);
          });
          return countries;
        }
        return [];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoCreatorCountries(): ', error);
        return [];
      }
    });
    setVideoCreatorTypes(() => {
      try {
        if (typeof savedSearch?.videoFilters?.creatorTypes !== 'undefined' && savedSearch?.videoFilters?.creatorTypes !== null && Array.isArray(savedSearch.videoFilters.creatorTypes)) {
          const types = [];
          savedSearch.videoFilters.creatorTypes.forEach((type) => {
            const foundOption = creatorTypesOptions.find(option => option.value === type);
            if (foundOption) types.push(foundOption);
          });
          return types;
        }
        return [
          { value: 'influencer', label: 'Creator' },
        ];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoCreatorTypes(): ', error);
        return [
          { value: 'influencer', label: 'Creator' },
        ];
      }
    });
    setVideoContentGenres(() => {
      try {
        if (typeof savedSearch?.videoFilters?.contentGenres !== 'undefined' && savedSearch?.videoFilters?.contentGenres !== null && Array.isArray(savedSearch.videoFilters.contentGenres)) {
          return savedSearch.videoFilters.contentGenres;
        }
        return [];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoContentGenres(): ', error);
        return [];
      }
    });
    setVideoSortParameter(() => {
      try {
        if (savedSearch?.videoFilters?.sortBy) {
          const foundOption = videoSortParametersOptions.find(option => option.value === savedSearch.videoFilters.sortBy);
          if (foundOption) {
            return foundOption;
          }
        }
        return { value: 'upload_date', label: 'Published on' };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoSortParameter(): ', error);
        return { value: 'upload_date', label: 'Published on' };
      }
    });
    setVideoSortType(() => {
      try {
        if (savedSearch?.videoFilters?.sortBy && typeof savedSearch?.videoFilters?.sortOrderDescending !== 'undefined' && savedSearch?.videoFilters?.sortOrderDescending !== null) {
          const foundOption = savedSearch.videoFilters.sortBy === 'upload_date'
            ? videoSortTypesOptionsDates.find(option => option.value === savedSearch.videoFilters.sortOrderDescending.toString())
            : videoSortTypesOptions.find(option => option.value === savedSearch.videoFilters.sortOrderDescending.toString());
          if (foundOption) {
            return foundOption;
          }
        }
        return { value: 'true', label: 'Newest to oldest' };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setVideoSortType(): ', error);
        return { value: 'true', label: 'Newest to oldest' };
      }
    });

    setPodcastType(() => {
      try {
        if (savedSearch?.podcastFilters?.type) {
          const foundOption = podcastTypeOptions.find(option => option.value === savedSearch.podcastFilters.type);
          if (foundOption) {
            return foundOption;
          }
        }
        return { value: 'podcast', label: 'Podcasts' };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setPodcastType(): ', error);
        return { value: 'podcast', label: 'Podcasts' };
      }
    });
    setPodcastGenres(() => {
      try {
        if (typeof savedSearch?.podcastFilters?.genreIds !== 'undefined' && savedSearch?.podcastFilters?.genreIds !== null && Array.isArray(savedSearch.podcastFilters.genreIds)) {
          const genres = [];
          savedSearch.podcastFilters.genreIds.forEach((genre) => {
            const foundOption = podcastGenresOptions.find(option => option.value.toString() === genre);
            if (foundOption) genres.push(foundOption);
          });
          return genres;
        }
        return [];
      } catch (error) {
        hasErrors = true;
        console.log('Error with setPodcastGenres(): ', error);
        return [];
      }
    });
    setPodcastOnlyIn(() => {
      try {
        if (typeof savedSearch?.podcastFilters?.onlyIn !== 'undefined' && savedSearch?.podcastFilters?.onlyIn !== null && Array.isArray(savedSearch.podcastFilters.onlyIn)) {
          return getOnlyInFromArray(savedSearch.podcastFilters.onlyIn);
        }
        return {
          title: true,
          description: true,
          author: false,
          audio: false,
        };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setPodcastOnlyIn(): ', error);
        return {
          title: true,
          description: true,
          author: false,
          audio: false,
        };
      }
    });
    setPodcastLanguage(() => {
      try {
        if (savedSearch?.podcastFilters?.language) {
          const foundOption = podcastLanguageOptions.find(option => option.value === savedSearch.podcastFilters.language);
          if (foundOption) {
            return foundOption;
          }
        }
        return { value: 'English', label: 'English' };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setPodcastLanguage(): ', error);
        return { value: 'English', label: 'English' };
      }
    });
    setPodcastRegion(() => {
      try {
        if (savedSearch?.podcastFilters?.region) {
          const foundOption = podcastRegionOptions.find(option => option.value === savedSearch.podcastFilters.region);
          if (foundOption) {
            return foundOption;
          }
        }
        return { value: 'us', label: 'United States' };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setPodcastRegion(): ', error);
        return { value: 'us', label: 'United States' };
      }
    });
    setPodcastSort(() => {
      try {
        if (savedSearch?.podcastFilters?.sortByDate) {
          const foundOption = podcastSortOptions.find(option => option.value === savedSearch.podcastFilters.sortByDate);
          if (foundOption) {
            return foundOption;
          }
        }
        return { value: '0', label: 'Sort by Relevance' };
      } catch (error) {
        hasErrors = true;
        console.log('Error with setPodcastSort(): ', error);
        return { value: '0', label: 'Sort by Relevance' };
      }
    });
    setPodcastPublishedAfter(() => {
      try {
        if (savedSearch?.podcastFilters?.publishedAfter) {
          return new Date(savedSearch.podcastFilters.publishedAfter);
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setPodcastPublishedAfter(): ', error);
        return null;
      }
    });
    setPodcastPublishedBefore(() => {
      try {
        if (savedSearch?.podcastFilters?.publishedBefore) {
          return new Date(savedSearch.podcastFilters.publishedBefore);
        }
        return null;
      } catch (error) {
        hasErrors = true;
        console.log('Error with setPodcastPublishedBefore(): ', error);
        return null;
      }
    });

    if (hasErrors) {
      dispatch(addNotice({
        message: 'Due to corrupted data in saved search, some of the filters are set to default values. Check out console for more information',
        type: 'warning',
      }));
    }

    if (savedSearch === null) setCurrentSavedSearchOption(null);
  };

  useEffect(() => {
    const prospectUploadsSavedSearchId = getCookie('prospectUploadsSavedSearchId');
    if (prospectUploadsSavedSearchId && prospectUploadsSavedSearchesForGrid && prospectUploadsSavedSearchesForGrid.length > 0) {
      const savedSearch = prospectUploadsSavedSearchesForGrid.find(search => search._id === prospectUploadsSavedSearchId);
      if (savedSearch) {
        setCurrentSavedSearchOption(null);
        resetFilters(savedSearch);
        setCookie('prospectUploadsSavedSearchId', '', 1);
      }
    }
  }, [prospectUploadsSavedSearchesForGrid]);

  const doSearch = (pageNumberVal) => {
    setGridUpdateCounter(0);
    setPageNumber(pageNumberVal);
    setSearchedText(queryText);
    const filters = { include: {}, exclude: {} };
    const sortOptions = {};
    if (activeTypeCheckbox === 'creator') {
      const accounts = [];

      let insertYouTubeAccount = false;
      const accountYouTube = { platform: 'youtube' };
      if (!(creatorYouTubeAudienceAgeRange[0] === 13 && (creatorYouTubeAudienceAgeRange[1] === 60 || creatorYouTubeAudienceAgeRange[1] === 55) && creatorYouTubeAudienceMinAgeRange === 0)) {
        accountYouTube.audience_age_range = {};
        accountYouTube.audience_min_age_range = creatorYouTubeAudienceMinAgeRange > 0 ? creatorYouTubeAudienceMinAgeRange - 0.00001 : creatorYouTubeAudienceMinAgeRange + 0.00001;
        accountYouTube.audience_age_range.min = creatorYouTubeAudienceAgeRange[0] === 60 ? 55 : creatorYouTubeAudienceAgeRange[0];
        accountYouTube.audience_age_range.max = creatorYouTubeAudienceAgeRange[1] === 60 ? 55 : creatorYouTubeAudienceAgeRange[1];
        insertYouTubeAccount = true;
      }
      if (creatorYouTubeAudienceLocationCountry && creatorYouTubeAudienceLocationCountry.length > 0) {
        accountYouTube.audience_locations = [
          {
            country: creatorYouTubeAudienceLocationCountry,
            min: creatorYouTubeAudienceLocationMin > 0 ? creatorYouTubeAudienceLocationMin - 0.00001 : creatorYouTubeAudienceLocationMin + 0.00001,
          },
        ];
        insertYouTubeAccount = true;
      }
      if (creatorYouTubeAudienceMinFemaleRange && creatorYouTubeAudienceMinFemaleRange > 0) {
        accountYouTube.audience_min_male = creatorYouTubeAudienceMinMaleRange > 0 ? creatorYouTubeAudienceMinMaleRange - 0.00001 : creatorYouTubeAudienceMinMaleRange + 0.00001;
        accountYouTube.audience_min_female = creatorYouTubeAudienceMinFemaleRange > 0 ? creatorYouTubeAudienceMinFemaleRange - 0.00001 : creatorYouTubeAudienceMinFemaleRange + 0.00001;
        insertYouTubeAccount = true;
      }
      if (creatorYouTubeV30Min || creatorYouTubeV30Max) {
        accountYouTube.v30 = {};
        if (creatorYouTubeV30Min) accountYouTube.v30.min = creatorYouTubeV30Min;
        if (creatorYouTubeV30Max) accountYouTube.v30.max = creatorYouTubeV30Max;
        insertYouTubeAccount = true;
      }
      if (creatorIncludeYouTubeCategories && creatorIncludeYouTubeCategories.length > 0) {
        accountYouTube.categories = creatorIncludeYouTubeCategories?.map(option => parseInt(option.value, 10));
        insertYouTubeAccount = true;
      }
      if (insertYouTubeAccount) accounts.push(accountYouTube);

      let insertFacebookAccount = false;
      const accountFacebook = { platform: 'facebook' };
      if (!(
        creatorFacebookAudienceAgeRange[0] === 13
        && (creatorFacebookAudienceAgeRange[1] === 60 || creatorFacebookAudienceAgeRange[1] === 55)
        && creatorFacebookAudienceMinAgeRange === 0
      )) {
        accountFacebook.audience_age_range = {};
        accountFacebook.audience_min_age_range = creatorFacebookAudienceMinAgeRange > 0 ? creatorFacebookAudienceMinAgeRange - 0.00001 : creatorFacebookAudienceMinAgeRange + 0.00001;
        accountFacebook.audience_age_range.min = creatorFacebookAudienceAgeRange[0] === 60 ? 55 : creatorFacebookAudienceAgeRange[0];
        accountFacebook.audience_age_range.max = creatorFacebookAudienceAgeRange[1] === 60 ? 55 : creatorFacebookAudienceAgeRange[1];
        insertFacebookAccount = true;
      }
      if (creatorFacebookAudienceLocationCountry && creatorFacebookAudienceLocationCountry.length > 0) {
        accountFacebook.audience_locations = [
          {
            country: creatorFacebookAudienceLocationCountry,
            min: creatorFacebookAudienceLocationMin > 0 ? creatorFacebookAudienceLocationMin - 0.00001 : creatorFacebookAudienceLocationMin + 0.00001,
          },
        ];
        insertFacebookAccount = true;
      }
      if (creatorFacebookV30Min || creatorFacebookV30Max) {
        accountFacebook.v30 = {};
        if (creatorFacebookV30Min) accountFacebook.v30.min = creatorFacebookV30Min;
        if (creatorFacebookV30Max) accountFacebook.v30.max = creatorFacebookV30Max;
        insertFacebookAccount = true;
      }
      if (insertFacebookAccount) accounts.push(accountFacebook);

      if (creatorViewsMin || creatorViewsMax) {
        filters.include.creator_views = {};
        if (creatorViewsMin) filters.include.creator_views.min = creatorViewsMin;
        if (creatorViewsMax) filters.include.creator_views.max = creatorViewsMax;
      }

      if (selectedCreatorPlatforms.youtube || selectedCreatorPlatforms.facebook || selectedCreatorPlatforms.instagram || selectedCreatorPlatforms.twitter || selectedCreatorPlatforms.twitch) {
        filters.include.creator_platforms = [];
        if (selectedCreatorPlatforms.youtube) filters.include.creator_platforms.push('youtube');
        if (selectedCreatorPlatforms.facebook) filters.include.creator_platforms.push('facebook');
        if (selectedCreatorPlatforms.instagram) filters.include.creator_platforms.push('instagram');
        if (selectedCreatorPlatforms.twitter) filters.include.creator_platforms.push('twitter');
        if (selectedCreatorPlatforms.twitch) filters.include.creator_platforms.push('twitch');
      }

      if (accounts.length > 0) filters.include.accounts = accounts;

      if (creatorLanguages && creatorLanguages.length > 0) {
        filters.include.creator_languages = creatorLanguages?.map(option => option.value);
      }

      if (creatorCountries && creatorCountries.length > 0) {
        filters.include.creator_countries = creatorCountries?.map(option => option.value);
      }

      if (creatorTypes && creatorTypes.length > 0) {
        filters.include.creator_types = creatorTypes?.map(option => option.value);
      }

      if (creatorContentGenres && creatorContentGenres.length > 0) {
        filters.include.creator_genres = creatorContentGenres?.map(option => parseInt(option.value, 10));
      }

      if (creatorLastUpload && creatorLastUpload.value) {
        filters.include.creator_last_upload = creatorLastUpload.value;
      }

      if (creatorExcludeYouTubeCategories && creatorExcludeYouTubeCategories.length > 0) {
        filters.exclude = {
          accounts: [
            {
              platform: 'youtube',
              categories: creatorExcludeYouTubeCategories?.map(option => parseInt(option.value, 10)),
            },
          ],
        };
      }

      if (creatorSortParameter && creatorSortParameter.value && creatorSortType && creatorSortType.value) {
        sortOptions.sort = creatorSortParameter.value;
        sortOptions.sort_reverse = creatorSortType.value;
      }
    }

    if (activeTypeCheckbox === 'video') {
      if (videoViewsMin || videoViewsMax) {
        filters.include.video_views = {};
        if (videoViewsMin) filters.include.video_views.min = videoViewsMin;
        if (videoViewsMax) filters.include.video_views.max = videoViewsMax;
      }

      if (videoEngagementsMin || videoEngagementsMax) {
        filters.include.video_engagements = {};
        if (videoEngagementsMin) filters.include.video_engagements.min = videoEngagementsMin;
        if (videoEngagementsMax) filters.include.video_engagements.max = videoEngagementsMax;
      }

      if (videoDurationMin || videoDurationMax) {
        filters.include.video_duration = {};
        if (videoDurationMin) filters.include.video_duration.min = videoDurationMin;
        if (videoDurationMax) filters.include.video_duration.max = videoDurationMax;
      }

      if (videoLastUploadMin || videoLastUploadMax) {
        filters.include.video_upload_date = {};
        if (videoLastUploadMin) filters.include.video_upload_date.min = moment(videoLastUploadMin).format('YYYY-MM-DD');
        if (videoLastUploadMax) filters.include.video_upload_date.max = moment(videoLastUploadMax).format('YYYY-MM-DD');
      }

      if (selectedVideoPlatforms.youtube || selectedVideoPlatforms.facebook || selectedVideoPlatforms.instagram || selectedVideoPlatforms.twitter || selectedVideoPlatforms.twitch) {
        filters.include.video_platforms = [];
        if (selectedVideoPlatforms.youtube) filters.include.video_platforms.push('youtube');
        if (selectedVideoPlatforms.facebook) filters.include.video_platforms.push('facebook');
        if (selectedVideoPlatforms.instagram) filters.include.video_platforms.push('instagram');
        if (selectedVideoPlatforms.twitter) filters.include.video_platforms.push('twitter');
        if (selectedVideoPlatforms.twitch) filters.include.video_platforms.push('twitch');
      }

      if (videoLanguages && videoLanguages.length > 0) {
        filters.include.video_languages = videoLanguages?.map(option => option.value);
      }

      if (videoSortParameter && videoSortParameter.value && videoSortType && videoSortType.value) {
        sortOptions.sort = videoSortParameter.value;
        sortOptions.sort_reverse = videoSortType.value;
      }

      filters.include.search_description = selectedVideoSearchIn.description;
      filters.include.search_keywords = selectedVideoSearchIn.keywords;
      filters.include.search_topics = selectedVideoSearchIn.topics;

      if (videoCreatorCountries && videoCreatorCountries.length > 0) {
        filters.include.creator_countries = videoCreatorCountries?.map(option => option.value);
      }

      if (videoCreatorTypes && videoCreatorTypes.length > 0) {
        filters.include.creator_types = videoCreatorTypes?.map(option => option.value);
      }

      if (videoContentGenres && videoContentGenres.length > 0) {
        filters.include.creator_genres = videoContentGenres?.map(option => parseInt(option.value, 10));
      }

      if (videoIncludeCategories && videoIncludeCategories.length > 0) {
        filters.include.video_categories = videoIncludeCategories?.map(option => option.value);
      }

      if (videoExcludeCategories && videoExcludeCategories.length > 0) {
        filters.exclude.video_categories = videoExcludeCategories?.map(option => option.value);
      }
    }

    if (activeTypeCheckbox === 'podcast') {
      if (podcastGenres && podcastGenres.length > 0) {
        filters.genre_ids = podcastGenres?.map(option => option.value).join(',');
      }
      if (podcastOnlyIn.title || podcastOnlyIn.description || podcastOnlyIn.author || podcastOnlyIn.audio) {
        const onlyIn = [];
        if (podcastOnlyIn.title) onlyIn.push('title');
        if (podcastOnlyIn.description) onlyIn.push('description');
        if (podcastOnlyIn.author) onlyIn.push('author');
        if (podcastOnlyIn.audio) onlyIn.push('audio');
        filters.only_in = onlyIn.join(',');
      }
      if (podcastType && podcastType.value) {
        sortOptions.type = podcastType.value;
      }
      if (podcastSort && podcastSort.value) {
        sortOptions.sort_by_date = podcastSort.value;
      }
      if (podcastLanguage && podcastLanguage.value) {
        filters.language = podcastLanguage.value;
      }
      if (podcastRegion && podcastRegion.value) {
        filters.region = podcastRegion.value;
      }
      if (podcastPublishedAfter) {
        filters.published_after = podcastPublishedAfter.getTime();
      }
      if (podcastPublishedBefore) {
        filters.published_before = podcastPublishedBefore.getTime();
      }
    }

    if (activeTypeCheckbox === 'podcast' && (typeof queryText === 'undefined' || queryText.length < 1)) {
      dispatch(addNotice({
        message: 'Please enter a search query.',
        type: 'warning',
      }));
    } else {
      setFiltersProp(filters);
      setSortOptionsProp(sortOptions);
      dispatch(doTubularSearch(activeTypeCheckbox, queryText, filters, sortOptions, searchTokens[activeTypeCheckbox][pageNumberVal - 1], pageNumberVal));
      setAtLeastOneSearchIsMade(true);
    }
  };

  const doSearchNew = () => {
    if (activeTypeCheckbox !== 'podcast' && queryText && queryText.includes('"') && queryText.match(/"/g) && queryText.match(/"/g).length % 2 > 0) {
      dispatch(addNotice({
        message: 'The quotation marks must be matched.',
        type: 'warning',
      }));
    } else {
      dispatch(clearSearchResults());
      doSearch(1);
    }
  };

  const getSearchDataToSave = (name, visibility) => {
    function getTrueKeysFromObject(object) {
      const result = [];
      // eslint-disable-next-line no-restricted-syntax,no-unused-vars
      for (const [key, value] of Object.entries(object)) {
        if (value) result.push(key);
      }
      return result;
    }

    const search = {};
    search.name = name;
    search.visibility = visibility;
    search.activeTypeCheckbox = activeTypeCheckbox;
    search.searchQuery = queryText;
    search.creatorFilters = {
      views: {
        min: creatorViewsMin,
        max: creatorViewsMax,
      },
      platforms: getTrueKeysFromObject(selectedCreatorPlatforms),
      audience: [
        {
          platform: 'youtube',
          audienceAge: {
            min: creatorYouTubeAudienceAgeRange[0],
            max: creatorYouTubeAudienceAgeRange[1],
          },
          audienceMinAgeRange: creatorYouTubeAudienceMinAgeRange,
          audienceMinFemaleRange: creatorYouTubeAudienceMinFemaleRange,
          audienceMinMaleRange: creatorYouTubeAudienceMinMaleRange,
          audienceLocations: [
            {
              country: creatorYouTubeAudienceLocationCountry,
              min: creatorYouTubeAudienceLocationMin,
            },
          ],
          v30: {
            min: creatorYouTubeV30Min,
            max: creatorYouTubeV30Max,
          },
        },
        {
          platform: 'facebook',
          audienceAge: {
            min: creatorFacebookAudienceAgeRange[0],
            max: creatorFacebookAudienceAgeRange[1],
          },
          audienceMinAgeRange: creatorFacebookAudienceMinAgeRange,
          audienceLocations: [
            {
              country: creatorFacebookAudienceLocationCountry,
              min: creatorFacebookAudienceLocationMin,
            },
          ],
          v30: {
            min: creatorFacebookV30Min,
            max: creatorFacebookV30Max,
          },
        },
      ],
      creatorLanguages: creatorLanguages && creatorLanguages.length > 0 ? creatorLanguages?.map(option => option.value) : [],
      creatorCountries: creatorCountries && creatorCountries.length > 0 ? creatorCountries?.map(option => option.value) : [],
      creatorTypes: creatorTypes && creatorTypes.length > 0 ? creatorTypes?.map(option => option.value) : [],
      contentGenres: creatorContentGenres || [],
      includeYouTubeCategories: creatorIncludeYouTubeCategories || [],
      excludeYouTubeCategories: creatorExcludeYouTubeCategories || [],
      creatorLastUpload: creatorLastUpload && creatorLastUpload.value ? creatorLastUpload.value : null,
      sortBy: creatorSortParameter.value,
      sortOrderAscending: creatorSortType.value,
    };
    search.videoFilters = {
      views: {
        min: videoViewsMin,
        max: videoViewsMax,
      },
      engagements: {
        min: videoEngagementsMin,
        max: videoEngagementsMax,
      },
      video_duration: {
        min: videoDurationMin,
        max: videoDurationMax,
      },
      uploadDate: {
        min: videoLastUploadMin,
        max: videoLastUploadMax,
      },
      videoPlatforms: getTrueKeysFromObject(selectedVideoPlatforms),
      videoLanguages: videoLanguages && videoLanguages.length > 0 ? videoLanguages?.map(option => option.value) : [],
      includeVideoCategories: videoIncludeCategories && videoIncludeCategories.length > 0 ? videoIncludeCategories?.map(option => option.value) : [],
      excludeVideoCategories: videoExcludeCategories && videoExcludeCategories.length > 0 ? videoExcludeCategories?.map(option => option.value) : [],
      searchDescription: selectedVideoSearchIn.description,
      searchKeywords: selectedVideoSearchIn.keywords,
      searchTopics: selectedVideoSearchIn.topics,
      creatorCountries: videoCreatorCountries && videoCreatorCountries.length > 0 ? videoCreatorCountries?.map(option => option.value) : [],
      creatorTypes: videoCreatorTypes && videoCreatorTypes.length > 0 ? videoCreatorTypes?.map(option => option.value) : [],
      contentGenres: videoContentGenres || [],
      sortBy: videoSortParameter.value,
      sortOrderDescending: videoSortType.value,
    };
    search.podcastFilters = {
      genreIds: podcastGenres && podcastGenres.length > 0 ? podcastGenres?.map(option => option.value) : [],
      onlyIn: getTrueKeysFromObject(podcastOnlyIn),
      language: podcastLanguage && podcastLanguage.value ? podcastLanguage.value : '',
      region: podcastRegion && podcastRegion.value ? podcastRegion.value : '',
      publishedAfter: podcastPublishedAfter,
      publishedBefore: podcastPublishedBefore,
      type: podcastType.value,
      sortByDate: podcastSort.value,
    };
    return search;
  };

  const onSaveSearch = (name, visibility) => {
    setSavingSearch(true);
    const search = getSearchDataToSave(name, visibility);
    console.log(search);
    axios.post(
      '/api/portal/research/save-search',
      { search },
    ).then((response) => {
      if (response.data.success && response.data.savedSearch) {
        dispatch(getMySavedSearches());
        dispatch(getMySavedSearchesForGrid());
        dispatch(getProspectUploadsSavedSearchesForGrid());
        setCurrentSavedSearchOption({ value: response.data.savedSearch._id, label: response.data.savedSearch.name });
        setSavingSearch(false);
        setSaveSearchModalMessage('Search saved successfully.');
        dispatch(addNotice({
          message: 'Search saved successfully.',
          type: 'success',
          duration: 10,
        }));
        resetFilters(response.data.savedSearch);
        setTimeout(() => {
          setShowSaveSearchModal(false);
        }, 300);
      } else {
        setSavingSearch(false);
        setSaveSearchModalMessage('Failed to save this search.');
        dispatch(addNotice({
          message: response.data.error || 'Failed to save this search.',
          type: 'error',
          duration: 10,
        }));
      }
    }).catch((error) => {
      console.log(error);
      setSavingSearch(false);
      setSaveSearchModalMessage('Failed to save this search.');
      dispatch(addNotice({
        message: error.message || error,
        type: 'error',
        duration: 10,
      }));
    });
  };
  const updateSearchCache = async () => {
    try {
      const res = await axios.get('/api/portal/prospects/reload-search-cache');
      if (res.data.success !== true) throw new Error(res.data.error);
      dispatch(addNotice({
        message: 'Search cache has been updated successfully.',
        type: 'success',
        duration: 10,
      }));
    } catch (e) {
      dispatch(addNotice({
        message: e.message || 'Unable to update save search',
        type: 'error',
        duration: 10,
      }));
    }
  };

  useEffect(() => {
    if (podcastAutoSearch === 1) {
      doSearchNew();
      setPodcastAutoSearch(podcastAutoSearch + 1);
    }
  }, [podcastAutoSearch]);

  useEffect(async () => {
    if (searchResults.length > 0 && activeTypeCheckbox === 'creator') {
      if (gridUpdateCounter === 0) {
        setGridUpdateCounter(1);
        const checkedSearchResults = await getCheckYTChannelUrl(searchResults);
        dispatch(updateResearchSearchRow(checkedSearchResults));
      }
    }
  }, [searchResults]);

  const addSearchItemToTheList = (searchItems, currentWatchlist) => {
    const isEpisodes = (activeTypeCheckbox === 'podcast' && (podcastType && podcastType.value === 'episode'));
    dispatch(saveSearchItemToTheList(searchItems, currentWatchlist, isEpisodes));
  };

  const searchTypeChanged = (type) => {
    dispatch(clearSearchResults());
    setPageNumber(1);
    setActiveTypeCheckbox(type);
    setAtLeastOneSearchIsMade(false);
  };

  const gotoPage = (page) => {
    doSearch(page + 1);
  };

  const handleChangePlatforms = (event) => {
    if (event && event.target) {
      const { name, checked } = event.target;
      if (activeTypeCheckbox === 'creator') {
        setSelectedCreatorPlatforms({
          ...selectedCreatorPlatforms,
          [name]: checked,
        });
      }
      if (activeTypeCheckbox === 'video') {
        setSelectedVideoPlatforms({
          ...selectedVideoPlatforms,
          [name]: checked,
        });
      }
    }
  };

  const handleChangeOnlyIn = (event) => {
    if (event && event.target) {
      const { name, checked } = event.target;
      setPodcastOnlyIn({
        ...podcastOnlyIn,
        [name]: checked,
      });
    }
  };

  const onSavedSearchSelected = (selectedOption) => {
    setCurrentSavedSearchOption(selectedOption);
    resetFilters(selectedOption ? savedSearchesForGrid.find(savedSearch => savedSearch._id === selectedOption.value) : null);
  };

  const onSaveSearchButtonClick = () => {
    if (queryText && queryText.length > 0) {
      setShowSaveSearchModal(true);
    } else {
      dispatch(addNotice({
        message: 'You have to enter a search query in order to save this search.',
        type: 'warning',
      }));
    }
  };

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };

  return (
    <Container className="dashboard">
      <Breadcrumb
        links={[
          { title: 'Home', path: '/' },
          { title: 'Research', path: null },
          { title: 'Creator Search', path: null },
        ]}
        isBackButton
      />
      <Card>
        <CardBody>
          <Row>
            <Col md={12}>
              <AccessComponent showWhenTeam={['engineering']} showWhenRole={['owner']} and>
                <Row>
                  <Col />
                  <Col sm="auto">
                    <UncontrolledButtonDropdown className="float-right">
                      <DropdownToggle
                        id="actionsMenu"
                        caret
                        color="primary"
                      >
                        Settings
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem
                          onClick={async () => dispatch(showModalById('ConfirmModalDefault', {
                              title: 'Update Search Caches',
                              message: 'Are you sure you want to update search caches. It may use more that 3000 units',
                              processRecord: () => updateSearchCache(),
                              confirmButtonCaption: 'Update Cache',
                              errorCatch: (e) => {
                                dispatch(addNotice({
                                  message: e.message,
                                  type: 'error',
                                }));
                              }
                            }))}
                          disabled={!!loading}
                        >
                          Update Search Caches
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </Col>
                </Row>
              </AccessComponent>
              <Row>
                <Col md="4">
                  <h2 style={{ marginBottom: '15px' }}>Creator Search</h2>
                </Col>
                <Col md="8">
                  <div
                    className="d-flex"
                    style={{
                      float: 'right',
                      paddingTop: '15px',
                    }}
                  >
                    <span
                      style={{
                        paddingTop: '8px',
                        paddingRight: '15px',
                      }}
                    >
                      Saved Searches:
                    </span>
                    <div
                      className="research-watchlists"
                      style={{
                        width: '350px',
                      }}
                    >
                      <Select
                        isClearable
                        noOptionsMessage={() => 'You have no Saved Searches yet'}
                        isLoading={loadingSavedSearchesOptions}
                        value={currentSavedSearchOption}
                        options={savedSearchesOptions}
                        onChange={onSavedSearchSelected}
                        placeholder="Select a Saved Search"
                      />
                    </div>
                    <StyledButton
                      type="button"
                      className="btn btn-primary btn-lg d-inline"
                      disabled={loadingSavedSearchesOptions}
                      onClick={onSaveSearchButtonClick}
                      style={{
                        width: '100px',
                        height: '38px',
                        marginLeft: '25px',
                        marginBottom: '0',
                        padding: '9px 25px',
                      }}
                    >
                      Save
                    </StyledButton>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <br />
                  <Row>
                    <Col sm={12} style={{ paddingLeft: '50px' }}>
                      <FormControlLabel
                        control={
                          <Input
                            type="radio"
                            name="searchType"
                            value="creator"
                            className="mt-0"
                            checked={activeTypeCheckbox === 'creator'}
                            onChange={() => { searchTypeChanged('creator'); }}
                          />
                        }
                        label="Creator"
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <FormControlLabel
                        control={
                          <Input
                            type="radio"
                            name="searchType"
                            value="video"
                            className="mt-0"
                            checked={activeTypeCheckbox === 'video'}
                            onChange={() => { searchTypeChanged('video'); }}
                          />
                        }
                        label="Video"
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <FormControlLabel
                        control={
                          <Input
                            type="radio"
                            name="searchType"
                            value="podcast"
                            className="mt-0"
                            checked={activeTypeCheckbox === 'podcast'}
                            onChange={() => { searchTypeChanged('podcast'); }}
                          />
                        }
                        label="Podcast"
                      />
                    </Col>
                  </Row>
                  <div className="d-flex w-100">
                    <Input
                      type="search"
                      className="form-control"
                      /* eslint-disable-next-line no-nested-ternary */
                      placeholder={activeTypeCheckbox === 'creator'
                        ? 'Creator name, @username, or URL'
                        : (activeTypeCheckbox === 'video'
                          ? 'Search for videos by title, topic or URL'
                          : 'Search for podcasts by name or keywords'
                        )
                      }
                      value={queryText}
                      onChange={event => setQueryText(event.target.value)}
                      /* onBlur={() => {
                        if (activeTypeCheckbox === 'creator') triggerFetchCreatorFacets();
                        if (activeTypeCheckbox === 'video') triggerFetchVideoFacets();
                      }} */
                      /* onKeyDown={(event) => {
                        if (event.code === 'Enter' || event.keycode === 13) {
                          doSearchNew();
                          if (activeTypeCheckbox === 'creator') triggerFetchCreatorFacets();
                          if (activeTypeCheckbox === 'video') triggerFetchVideoFacets();
                        }
                      }} */
                    />
                    <StyledButton
                      type="button"
                      className="btn btn-primary btn-lg d-inline"
                      disabled={loading}
                      onClick={doSearchNew}
                      style={{
                        width: '160px',
                        height: '38px',
                        marginLeft: '25px',
                        marginRight: '25px',
                        padding: '9px 25px',
                      }}
                    >
                      <i className="fa fa-search" />&nbsp;
                      <span className="btn-text">Search</span>
                    </StyledButton>
                    <Button
                      className="d-inline"
                      color="secondary"
                      size="sm"
                      onClick={resetFilters}
                      style={{
                        width: '145px',
                        height: '38px',
                        padding: '8px 8px',
                      }}
                    >
                      <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                      <span className="btn-text">Reset</span>
                    </Button>
                    <Tooltip title="Toggle Filters">
                      <IconButton
                        size="small"
                        onClick={() => setShowFilters(!showFilters)}
                        className="material-table__toolbar-button"
                        style={{
                          height: '38px',
                        }}
                      >
                        <TuneIcon size="30" />
                      </IconButton>
                    </Tooltip>
                  </div>
                  {showFilters && (
                    <ThemeProvider theme={muiTheme}>
                      {activeTypeCheckbox === 'creator' && (
                        <Row>
                          <Col md={4}>
                            <Row>
                              <Col md={3}>
                                <Label className="bold-text" style={{ marginBottom: '14px' }}>
                                  Platforms
                                </Label>
                                <FormGroup
                                  style={{ marginBottom: '10px' }}
                                >
                                  <CheckBoxField
                                    name="youtube"
                                    label="YouTube"
                                    value={selectedCreatorPlatforms.youtube}
                                    onChange={handleChangePlatforms}
                                  />
                                </FormGroup>
                                <FormGroup
                                  style={{ marginBottom: '10px' }}
                                >
                                  <CheckBoxField
                                    name="facebook"
                                    label="Facebook"
                                    value={selectedCreatorPlatforms.facebook}
                                    onChange={handleChangePlatforms}
                                  />
                                </FormGroup>
                                <FormGroup
                                  style={{ marginBottom: '10px' }}
                                >
                                  <CheckBoxField
                                    name="instagram"
                                    label="Instagram"
                                    value={selectedCreatorPlatforms.instagram}
                                    onChange={handleChangePlatforms}
                                  />
                                </FormGroup>
                                <FormGroup
                                  style={{ marginBottom: '10px' }}
                                >
                                  <CheckBoxField
                                    name="twitter"
                                    label="X"
                                    value={selectedCreatorPlatforms.twitter}
                                    onChange={handleChangePlatforms}
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <CheckBoxField
                                    name="twitch"
                                    label="Twitch"
                                    value={selectedCreatorPlatforms.twitch}
                                    onChange={handleChangePlatforms}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <Label
                                  className="bold-text"
                                  title="Applies a view limit (total number of views across all accounts for the previous month) to use in the filter."
                                >
                                  Views
                                </Label>
                                <br />
                                <Label>
                                  Min Views
                                </Label>
                                <NumberFormat
                                  thousandSeparator
                                  prefix=""
                                  value={creatorViewsMin}
                                  className="form-control"
                                  inputMode="numeric"
                                  allowNegative={false}
                                  allowEmptyFormatting={false}
                                  fixedDecimalScale
                                  decimalScale={0}
                                  onValueChange={values => setCreatorViewsMin(values.floatValue)}
                                  onBlur={() => {
                                    if (
                                      creatorViewsMin !== null
                                      && creatorViewsMax !== null
                                      && (creatorViewsMin > creatorViewsMax)
                                    ) {
                                      setCreatorViewsMax(creatorViewsMin + 100000);
                                    }
                                  }}
                                />
                                <Label style={{ marginTop: '8px' }}>
                                  Max Views
                                </Label>
                                <NumberFormat
                                  thousandSeparator
                                  prefix=""
                                  value={creatorViewsMax}
                                  className="form-control"
                                  inputMode="numeric"
                                  allowNegative={false}
                                  allowEmptyFormatting={false}
                                  decimalScale={0}
                                  onValueChange={values => setCreatorViewsMax(values.floatValue)}
                                  onBlur={() => {
                                    if (
                                      creatorViewsMin !== null
                                      && creatorViewsMax !== null
                                      && (creatorViewsMin > creatorViewsMax)
                                    ) {
                                      setCreatorViewsMin(creatorViewsMax - 1);
                                    }
                                  }}
                                />
                              </Col>
                              <Col md={5}>
                                <Label className="bold-text" >
                                  Sort
                                </Label>
                                <br />
                                <Label>
                                  Sort By
                                </Label>
                                <Select
                                  value={creatorSortParameter}
                                  options={creatorSortParametersOptions}
                                  onChange={option => setCreatorSortParameter(option)}
                                  styles={colourStyles}
                                />
                                <Label style={{ marginTop: '8px' }}>
                                  Sort Order
                                </Label>
                                <Select
                                  value={creatorSortType}
                                  options={creatorSortTypesOptions}
                                  onChange={option => setCreatorSortType(option)}
                                  styles={colourStyles}
                                />
                              </Col>
                            </Row>
                            <div className="w-100 research-watchlists">
                              <Label className="bold-text">
                                Last Upload
                              </Label>
                              <Select
                                name="creatorLastUploadSelect"
                                isClearable
                                placeholder="Select period..."
                                styles={colourStyles}
                                value={creatorLastUpload}
                                options={creatorLastUploadOptions}
                                onChange={option => setCreatorLastUpload(option)}
                              />
                              <Label className="bold-text" style={{ marginTop: '16px' }}>
                                Creator Languages
                              </Label>
                              <Select
                                isMulti
                                name="creatorLanguagesSelect"
                                isClearable
                                value={creatorLanguages}
                                options={languagesOptions}
                                onChange={value => setCreatorLanguages(value)}
                                placeholder="Select Languages..."
                                style={{ maxHeight: '120px' }}
                                styles={colourStyles}
                              />
                              <Label className="bold-text" style={{ marginTop: '16px' }}>
                                Creator Countries
                              </Label>
                              <Select
                                isMulti
                                name="creatorCountriesSelect"
                                isClearable
                                value={creatorCountries}
                                options={countriesOptions}
                                onChange={value => setCreatorCountries(value)}
                                placeholder="Select Countries..."
                                style={{ maxHeight: '120px' }}
                                styles={colourStyles}
                              />
                              <Label className="bold-text" style={{ marginTop: '16px' }}>
                                Creator Types
                              </Label>
                              <Select
                                isMulti
                                name="creatorTypesSelect"
                                isClearable
                                value={creatorTypes}
                                options={creatorTypesOptions}
                                onChange={value => setCreatorTypes(value)}
                                placeholder="Select Types..."
                                style={{ maxHeight: '120px' }}
                                styles={colourStyles}
                              />
                              <Label className="bold-text" style={{ marginTop: '16px' }}>
                                Content Genres
                              </Label>
                              <Select
                                isMulti
                                isDisabled={creatorContentGenresOptions.length === 0}
                                isLoading={loadingCreatorFacets}
                                name="creatorContentGenresSelect"
                                isClearable
                                value={creatorContentGenres}
                                options={creatorContentGenresOptions}
                                onChange={value => setCreatorContentGenres(value)}
                                placeholder="Select Genres..."
                                styles={colourStyles}
                              />
                            </div>
                          </Col>
                          <Col md={8}>
                            <Label className="bold-text">
                              YouTube Filters
                            </Label>
                            <hr style={{ marginTop: '0', border: '1px solid #bf2726' }} />
                            <Row>
                              <Col md={6}>
                                <Label className="bold-text">
                                  Audience Age
                                </Label>
                                <div
                                  className="w-100"
                                  style={{ padding: '0 15px' }}
                                >
                                  <Label style={{ marginLeft: '-15px' }}>
                                    {`Between Ages: ${creatorYouTubeAudienceAgeRange[0] === 60 ? '55+' : creatorYouTubeAudienceAgeRange[0]}-${creatorYouTubeAudienceAgeRange[1] === 60 ? '55+' : creatorYouTubeAudienceAgeRange[1]}yrs`}
                                  </Label>
                                  <Slider
                                    min={13}
                                    max={60}
                                    value={creatorYouTubeAudienceAgeRange}
                                    onChange={(event, value) => setCreatorYouTubeAudienceAgeRange(value)}
                                    marks={[
                                      { value: 13, label: '13' },
                                      { value: 18, label: '18' },
                                      { value: 25, label: '25' },
                                      { value: 35, label: '35' },
                                      { value: 45, label: '45' },
                                      { value: 55, label: '55' },
                                      { value: 60, label: '55+' },
                                    ]}
                                    step={null}
                                    valueLabelDisplay="auto"
                                    valueLabelFormat={(value) => {
                                      if (value === 60) {
                                        return '55+';
                                      } return `${value}`;
                                    }}
                                  />
                                  <Label style={{ marginLeft: '-15px' }}>
                                    {`Age Range > ${creatorYouTubeAudienceMinAgeRange}%`}
                                  </Label>
                                  <Slider
                                    min={0}
                                    max={100}
                                    value={creatorYouTubeAudienceMinAgeRange}
                                    onChange={(event, value) => setCreatorYouTubeAudienceMinAgeRange(value)}
                                    marks={[
                                      { value: 0, label: '0%' },
                                      { value: 25, label: '25%' },
                                      { value: 50, label: '50%' },
                                      { value: 75, label: '75%' },
                                      { value: 100, label: '100%' },
                                    ]}
                                    step={5}
                                    track="inverted"
                                    valueLabelDisplay="auto"
                                    valueLabelFormat={value => `${value}%`}
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <Label className="bold-text">
                                  Audience Location
                                </Label>
                                <div className="w-100 research-watchlists">
                                  <Label>
                                    Country
                                  </Label>
                                  <Select
                                    isClearable
                                    isLoading={youTubeCategoriesOptionsLoading}
                                    isDisabled={youTubeCategoriesOptionsLoading}
                                    placeholder="Select Country..."
                                    value={creatorYouTubeAudienceLocationOption}
                                    options={countriesOptions}
                                    onChange={option => setCreatorYouTubeAudienceLocationOption(option)}
                                    styles={colourStyles}
                                  />
                                  {creatorYouTubeAudienceLocationOption && creatorYouTubeAudienceLocationOption.label && (
                                    <div
                                      className="w-100"
                                      style={{ padding: '0 15px' }}
                                    >
                                      <Label style={{ marginLeft: '-15px', marginTop: '16px' }}>
                                        {`${creatorYouTubeAudienceLocationOption.label} > ${creatorYouTubeAudienceLocationMin}%`}
                                      </Label>
                                      <Slider
                                        min={0}
                                        max={100}
                                        value={creatorYouTubeAudienceLocationMin}
                                        onChange={(event, value) => setCreatorYouTubeAudienceLocationMin(value)}
                                        marks={[
                                          { value: 0, label: '0%' },
                                          { value: 25, label: '25%' },
                                          { value: 50, label: '50%' },
                                          { value: 75, label: '75%' },
                                          { value: 100, label: '100%' },
                                        ]}
                                        step={5}
                                        track="inverted"
                                        valueLabelDisplay="auto"
                                        valueLabelFormat={value => `${value}%`}
                                      />
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col md={2}>
                                <Label className="bold-text">
                                  V30
                                </Label>
                                <br />
                                <Label>
                                  Min Views
                                </Label>
                                <NumberFormat
                                  thousandSeparator
                                  prefix=""
                                  name="what"
                                  value={creatorYouTubeV30Min}
                                  className="form-control"
                                  inputMode="numeric"
                                  allowEmptyFormatting={false}
                                  fixedDecimalScale
                                  decimalScale={0}
                                  onValueChange={values => setCreatorYouTubeV30Min(values.floatValue)}
                                  onBlur={() => {
                                    if (
                                      creatorYouTubeV30Min !== null
                                      && creatorYouTubeV30Max !== null
                                      && (creatorYouTubeV30Min > creatorYouTubeV30Max)
                                    ) {
                                      setCreatorYouTubeV30Max(creatorYouTubeV30Min + 1000);
                                    }
                                  }}
                                />
                                <Label style={{ marginTop: '8px' }}>
                                  Max Views
                                </Label>
                                <NumberFormat
                                  thousandSeparator
                                  prefix=""
                                  name="what"
                                  value={creatorYouTubeV30Max}
                                  className="form-control"
                                  inputMode="numeric"
                                  allowEmptyFormatting={false}
                                  decimalScale={0}
                                  onValueChange={values => setCreatorYouTubeV30Max(values.floatValue)}
                                  onBlur={() => {
                                    if (
                                      creatorYouTubeV30Min !== null
                                      && creatorYouTubeV30Max !== null
                                      && (creatorYouTubeV30Min > creatorYouTubeV30Max)
                                    ) {
                                      setCreatorYouTubeV30Min(creatorYouTubeV30Max - 1);
                                    }
                                  }}
                                />
                              </Col>
                              <Col md={6}>
                                <Label className="bold-text">
                                  Audience Gender
                                </Label>
                                <div
                                  className="w-100"
                                  style={{ padding: '0 15px' }}
                                >
                                  <Label style={{ marginLeft: '-15px' }}>
                                    {`Males > ${creatorYouTubeAudienceMinMaleRange}%`}
                                  </Label>
                                  <Slider
                                    min={0}
                                    max={100}
                                    value={creatorYouTubeAudienceMinMaleRange}
                                    onChange={(event, value) => setCreatorYouTubeAudienceMinMaleRange(value)}
                                    marks={[
                                      { value: 0, label: '0%' },
                                      { value: 25, label: '25%' },
                                      { value: 50, label: '50%' },
                                      { value: 75, label: '75%' },
                                      { value: 100, label: '100%' },
                                    ]}
                                    step={5}
                                    track="inverted"
                                    valueLabelDisplay="auto"
                                    valueLabelFormat={value => `${value}%`}
                                  />
                                  <Label style={{ marginLeft: '-15px' }}>
                                    {`Females > ${creatorYouTubeAudienceMinFemaleRange}%`}
                                  </Label>
                                  <Slider
                                    min={0}
                                    max={100}
                                    value={creatorYouTubeAudienceMinFemaleRange}
                                    onChange={(event, value) => setCreatorYouTubeAudienceMinFemaleRange(value)}
                                    marks={[
                                      { value: 0, label: '0%' },
                                      { value: 25, label: '25%' },
                                      { value: 50, label: '50%' },
                                      { value: 75, label: '75%' },
                                      { value: 100, label: '100%' },
                                    ]}
                                    step={5}
                                    track="inverted"
                                    valueLabelDisplay="auto"
                                    valueLabelFormat={value => `${value}%`}
                                  />
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="w-100 research-watchlists">
                                  <Label className="bold-text">
                                    Include Categories
                                  </Label>
                                  <Select
                                    isMulti
                                    isClearable
                                    placeholder="Select Categories..."
                                    value={creatorIncludeYouTubeCategories}
                                    options={youTubeCategoriesOptions}
                                    onChange={value => setCreatorIncludeYouTubeCategories(value)}
                                    styles={colourStyles}
                                  />
                                  <Label className="bold-text" style={{ marginTop: '8px' }}>
                                    Exclude Categories
                                  </Label>
                                  <Select
                                    isMulti
                                    isClearable
                                    placeholder="Select Categories..."
                                    value={creatorExcludeYouTubeCategories}
                                    options={youTubeCategoriesOptions}
                                    onChange={value => setCreatorExcludeYouTubeCategories(value)}
                                    styles={colourStyles}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Label className="bold-text" style={{ marginTop: '16px' }}>
                              Facebook Filters
                            </Label>
                            <hr style={{ marginTop: '0', border: '1px solid #3f5891' }} />
                            <Row>
                              <Col md={6}>
                                <Label className="bold-text">
                                  Audience Age
                                </Label>
                                <div
                                  className="w-100"
                                  style={{ padding: '0 15px' }}
                                >
                                  <Label style={{ marginLeft: '-15px' }}>
                                    {`Between Ages: ${creatorFacebookAudienceAgeRange[0] === 60 ? '55+' : creatorFacebookAudienceAgeRange[0]}-${creatorFacebookAudienceAgeRange[1] === 60 ? '55+' : creatorFacebookAudienceAgeRange[1]}yrs`}
                                  </Label>
                                  <Slider
                                    min={13}
                                    max={60}
                                    value={creatorFacebookAudienceAgeRange}
                                    onChange={(event, value) => setCreatorFacebookAudienceAgeRange(value)}
                                    marks={[
                                      { value: 13, label: '13' },
                                      { value: 18, label: '18' },
                                      { value: 25, label: '25' },
                                      { value: 35, label: '35' },
                                      { value: 45, label: '45' },
                                      { value: 55, label: '55' },
                                      { value: 60, label: '55+' },
                                    ]}
                                    step={null}
                                    valueLabelDisplay="auto"
                                    valueLabelFormat={(value) => {
                                      if (value === 60) {
                                        return '55+';
                                      } return `${value}`;
                                    }}
                                  />
                                  <Label style={{ marginLeft: '-15px' }}>
                                    {`Age Range > ${creatorFacebookAudienceMinAgeRange}%`}
                                  </Label>
                                  <Slider
                                    min={0}
                                    max={100}
                                    value={creatorFacebookAudienceMinAgeRange}
                                    onChange={(event, value) => setCreatorFacebookAudienceMinAgeRange(value)}
                                    marks={[
                                      { value: 0, label: '0%' },
                                      { value: 25, label: '25%' },
                                      { value: 50, label: '50%' },
                                      { value: 75, label: '75%' },
                                      { value: 100, label: '100%' },
                                    ]}
                                    step={5}
                                    track="inverted"
                                    valueLabelDisplay="auto"
                                    valueLabelFormat={value => `${value}%`}
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <Label className="bold-text">
                                  Audience Location
                                </Label>
                                <div className="w-100 research-watchlists">
                                  <Label>
                                    Country
                                  </Label>
                                  <Select
                                    isClearable
                                    placeholder="Select Country..."
                                    value={creatorFacebookAudienceLocationOption}
                                    options={countriesOptions}
                                    onChange={option => setCreatorFacebookAudienceLocationOption(option)}
                                    styles={colourStyles}
                                  />
                                  {creatorFacebookAudienceLocationOption && creatorFacebookAudienceLocationOption.label && (
                                    <div
                                      className="w-100"
                                      style={{ padding: '0 15px' }}
                                    >
                                      <Label style={{ marginLeft: '-15px', marginTop: '16px' }}>
                                        {`${creatorFacebookAudienceLocationOption.label} > ${creatorFacebookAudienceLocationMin}%`}
                                      </Label>
                                      <Slider
                                        min={0}
                                        max={100}
                                        value={creatorFacebookAudienceLocationMin}
                                        onChange={(event, value) => setCreatorFacebookAudienceLocationMin(value)}
                                        marks={[
                                          { value: 0, label: '0%' },
                                          { value: 25, label: '25%' },
                                          { value: 50, label: '50%' },
                                          { value: 75, label: '75%' },
                                          { value: 100, label: '100%' },
                                        ]}
                                        step={5}
                                        track="inverted"
                                        valueLabelDisplay="auto"
                                        valueLabelFormat={value => `${value}%`}
                                      />
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col md={2}>
                                <Label className="bold-text">
                                  V30
                                </Label>
                                <br />
                                <Label>
                                  Min Views
                                </Label>
                                <NumberFormat
                                  thousandSeparator
                                  prefix=""
                                  name="what"
                                  value={creatorFacebookV30Min}
                                  className="form-control"
                                  inputMode="numeric"
                                  allowEmptyFormatting={false}
                                  fixedDecimalScale
                                  decimalScale={0}
                                  onValueChange={values => setCreatorFacebookV30Min(values.floatValue)}
                                  onBlur={() => {
                                    if (
                                      creatorFacebookV30Min !== null
                                      && creatorFacebookV30Max !== null
                                      && (creatorFacebookV30Min > creatorFacebookV30Max)
                                    ) {
                                      setCreatorFacebookV30Max(creatorFacebookV30Min + 1000);
                                    }
                                  }}
                                />
                                <Label style={{ marginTop: '16px' }}>
                                  Max Views
                                </Label>
                                <NumberFormat
                                  thousandSeparator
                                  prefix=""
                                  name="what"
                                  value={creatorFacebookV30Max}
                                  className="form-control"
                                  inputMode="numeric"
                                  allowEmptyFormatting={false}
                                  decimalScale={0}
                                  onValueChange={values => setCreatorFacebookV30Max(values.floatValue)}
                                  onBlur={() => {
                                    if (
                                      creatorFacebookV30Min !== null
                                      && creatorFacebookV30Max !== null
                                      && (creatorFacebookV30Min > creatorFacebookV30Max)
                                    ) {
                                      setCreatorFacebookV30Min(creatorFacebookV30Max - 1);
                                    }
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                      {activeTypeCheckbox === 'video' && (
                        <>
                          <Row>
                            <Col md={9}>
                              <Row>
                                <Col md={5}>
                                  <Row>
                                    <Col md={3}>
                                      <Label className="bold-text" style={{ marginBottom: '14px' }}>
                                        Platforms
                                      </Label>
                                      <FormGroup
                                        style={{ marginBottom: '10px' }}
                                      >
                                        <CheckBoxField
                                          name="youtube"
                                          label="YouTube"
                                          value={selectedVideoPlatforms.youtube}
                                          onChange={handleChangePlatforms}
                                        />
                                      </FormGroup>
                                      <FormGroup
                                        style={{ marginBottom: '10px' }}
                                      >
                                        <CheckBoxField
                                          name="facebook"
                                          label="Facebook"
                                          value={selectedVideoPlatforms.facebook}
                                          onChange={handleChangePlatforms}
                                        />
                                      </FormGroup>
                                      <FormGroup
                                        style={{ marginBottom: '10px' }}
                                      >
                                        <CheckBoxField
                                          name="instagram"
                                          label="Instagram"
                                          value={selectedVideoPlatforms.instagram}
                                          onChange={handleChangePlatforms}
                                        />
                                      </FormGroup>
                                      <FormGroup
                                        style={{ marginBottom: '10px' }}
                                      >
                                        <CheckBoxField
                                          name="twitter"
                                          label="X"
                                          value={selectedVideoPlatforms.twitter}
                                          onChange={handleChangePlatforms}
                                        />
                                      </FormGroup>
                                      <FormGroup>
                                        <CheckBoxField
                                          name="twitch"
                                          label="Twitch"
                                          value={selectedVideoPlatforms.twitch}
                                          onChange={handleChangePlatforms}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                      <Label className="bold-text" style={{ marginBottom: '14px' }}>
                                        Search in...
                                      </Label>
                                      <FormGroup
                                        style={{ marginBottom: '10px' }}
                                      >
                                        <CheckBoxField
                                          name="description"
                                          label="Description"
                                          value={selectedVideoSearchIn.description}
                                          onChange={(event) => {
                                            if (event && event.target) {
                                              const { name, checked } = event.target;
                                              setSelectedVideoSearchIn({
                                                ...selectedVideoSearchIn,
                                                [name]: checked,
                                              });
                                            }
                                          }}
                                        />
                                      </FormGroup>
                                      <FormGroup
                                        style={{ marginBottom: '10px' }}
                                      >
                                        <CheckBoxField
                                          name="keywords"
                                          label="Keywords"
                                          value={selectedVideoSearchIn.keywords}
                                          onChange={(event) => {
                                            if (event && event.target) {
                                              const { name, checked } = event.target;
                                              setSelectedVideoSearchIn({
                                                ...selectedVideoSearchIn,
                                                [name]: checked,
                                              });
                                            }
                                          }}
                                        />
                                      </FormGroup>
                                      <FormGroup>
                                        <CheckBoxField
                                          name="topics"
                                          label="Topics"
                                          value={selectedVideoSearchIn.topics}
                                          onChange={(event) => {
                                            if (event && event.target) {
                                              const { name, checked } = event.target;
                                              setSelectedVideoSearchIn({
                                                ...selectedVideoSearchIn,
                                                [name]: checked,
                                              });
                                            }
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                      <Label className="bold-text">
                                        Sort Options
                                      </Label>
                                      <br />
                                      <Label>
                                        Sort By
                                      </Label>
                                      <Select
                                        value={videoSortParameter}
                                        options={videoSortParametersOptions}
                                        onChange={option => setVideoSortParameter(option)}
                                      />
                                      <Label style={{ marginTop: '16px' }}>
                                        Sort Order
                                      </Label>
                                      <Select
                                        value={videoSortType}
                                        options={videoSortParameter.value === 'upload_date' ? videoSortTypesOptionsDates : videoSortTypesOptions}
                                        onChange={option => setVideoSortType(option)}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md={7}>
                                  <Row>
                                    <Col md={3}>
                                      <Label className="bold-text">
                                        Views
                                      </Label>
                                      <br />
                                      <Label>
                                        Min Views
                                      </Label>
                                      <NumberFormat
                                        thousandSeparator
                                        prefix=""
                                        value={videoViewsMin}
                                        className="form-control"
                                        inputMode="numeric"
                                        allowEmptyFormatting={false}
                                        allowNegative={false}
                                        fixedDecimalScale
                                        decimalScale={0}
                                        onValueChange={values => setVideoViewsMin(values.floatValue)}
                                        onBlur={() => {
                                      if (
                                        videoViewsMin !== null
                                        && videoViewsMax !== null
                                        && (videoViewsMin > videoViewsMax)
                                      ) {
                                        setVideoViewsMax(videoViewsMin + 100000);
                                      }
                                    }}
                                      />
                                      <Label style={{ marginTop: '16px' }}>
                                        Max Views
                                      </Label>
                                      <NumberFormat
                                        thousandSeparator
                                        prefix=""
                                        value={videoViewsMax}
                                        className="form-control"
                                        inputMode="numeric"
                                        allowEmptyFormatting={false}
                                        allowNegative={false}
                                        decimalScale={0}
                                        onValueChange={values => setVideoViewsMax(values.floatValue)}
                                        onBlur={() => {
                                      if (
                                        videoViewsMin !== null
                                        && videoViewsMax !== null
                                        && (videoViewsMin > videoViewsMax)
                                      ) {
                                        setVideoViewsMin(videoViewsMax - 1);
                                      }
                                    }}
                                      />
                                    </Col>
                                    <Col md={3}>
                                      <Label className="bold-text">
                                        Engagements
                                      </Label>
                                      <br />
                                      <Label>
                                        Min Engagements
                                      </Label>
                                      <NumberFormat
                                        thousandSeparator
                                        prefix=""
                                        value={videoEngagementsMin}
                                        className="form-control"
                                        inputMode="numeric"
                                        allowEmptyFormatting={false}
                                        fixedDecimalScale
                                        decimalScale={0}
                                        onValueChange={values => setVideoEngagementsMin(values.floatValue)}
                                        onBlur={() => {
                                      if (
                                        videoEngagementsMin !== null
                                        && videoEngagementsMax !== null
                                        && (videoEngagementsMin > videoEngagementsMax)
                                      ) {
                                        setVideoEngagementsMax(videoEngagementsMin + 100000);
                                      }
                                    }}
                                      />
                                      <Label style={{ marginTop: '16px' }}>
                                        Max Engagements
                                      </Label>
                                      <NumberFormat
                                        thousandSeparator
                                        prefix=""
                                        value={videoEngagementsMax}
                                        className="form-control"
                                        inputMode="numeric"
                                        allowEmptyFormatting={false}
                                        decimalScale={0}
                                        onValueChange={values => setVideoEngagementsMax(values.floatValue)}
                                        onBlur={() => {
                                      if (
                                        videoEngagementsMin !== null
                                        && videoEngagementsMax !== null
                                        && (videoEngagementsMin > videoEngagementsMax)
                                      ) {
                                        setVideoEngagementsMin(videoEngagementsMax - 1);
                                      }
                                    }}
                                      />
                                    </Col>
                                    <Col md={3}>
                                      <Label className="bold-text">
                                        Video Duration
                                        <Tooltip title="Duration specified in seconds">
                                          <IconButton
                                            size="small"
                                            className="material-table__toolbar-button"
                                          >
                                            <InfoOutlineIcon size="14" />
                                          </IconButton>
                                        </Tooltip>
                                      </Label>
                                      <br />
                                      <Label>
                                        Min Duration
                                      </Label>
                                      <NumberFormat
                                        thousandSeparator
                                        prefix=""
                                        value={videoDurationMin}
                                        className="form-control"
                                        inputMode="numeric"
                                        allowEmptyFormatting={false}
                                        fixedDecimalScale
                                        decimalScale={0}
                                        onValueChange={values => setVideoDurationMin(values.floatValue)}
                                        onBlur={() => {
                                          if (
                                            videoDurationMin !== null
                                            && videoDurationMax !== null
                                            && (videoDurationMin > videoDurationMax)
                                          ) {
                                            setVideoDurationMax(videoDurationMin + 60);
                                          }
                                        }}
                                      />
                                      <Label style={{ marginTop: '16px' }}>
                                        Max Duration
                                      </Label>
                                      <NumberFormat
                                        thousandSeparator
                                        prefix=""
                                        value={videoDurationMax}
                                        className="form-control"
                                        inputMode="numeric"
                                        allowEmptyFormatting={false}
                                        decimalScale={0}
                                        onValueChange={values => setVideoDurationMax(values.floatValue)}
                                        onBlur={() => {
                                          if (
                                            videoDurationMin !== null
                                            && videoDurationMax !== null
                                            && (videoDurationMin > videoDurationMax)
                                          ) {
                                            setVideoDurationMin(videoDurationMax - 1);
                                          }
                                        }}
                                      />
                                    </Col>
                                    <Col md={3}>
                                      <Label className="bold-text">
                                        Video Upload Date
                                      </Label>
                                      <br />
                                      <Label>
                                        Start Date
                                      </Label>
                                      <DatePicker
                                        isClearable
                                        selected={videoLastUploadMin}
                                        onChange={date => setVideoLastUploadMin(date)}
                                        dateFormat="MM/dd/yyyy"
                                        dropDownMode="select"
                                        className="form-control"
                                        minDate={minDate}
                                        maxDate={maxDate}
                                      />
                                      <Label style={{ marginTop: '16px' }}>
                                        End Date
                                      </Label>
                                      <DatePicker
                                        isClearable
                                        selected={videoLastUploadMax}
                                        onChange={date => setVideoLastUploadMax(date)}
                                        dateFormat="MM/dd/yyyy"
                                        dropDownMode="select"
                                        className="form-control"
                                        minDate={minDate}
                                        maxDate={maxDate}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={4}>
                                  <div className="w-100 research-watchlists">
                                    <Label className="bold-text">
                                      Content Genres
                                    </Label>
                                    <Select
                                      isMulti
                                      isDisabled={videoContentGenresOptions.length === 0}
                                      isLoading={loadingVideoFacets}
                                      name="videoContentGenresSelect"
                                      isClearable
                                      value={videoContentGenres}
                                      options={videoContentGenresOptions}
                                      onChange={value => setVideoContentGenres(value)}
                                      placeholder="Select Genres..."
                                      styles={colourStyles}
                                    />
                                  </div>
                                </Col>
                                <Col md={4}>
                                  <div className="w-100 research-watchlists">
                                    <Label className="bold-text">
                                      Include Categories
                                    </Label>
                                    <Select
                                      isMulti
                                      isDisabled={videoCategoriesOptions.length === 0}
                                      isLoading={loadingVideoFacets}
                                      name="videoIncludeCategoriesSelect"
                                      isClearable
                                      value={videoIncludeCategories}
                                      options={videoCategoriesOptions}
                                      onChange={value => setVideoIncludeCategories(value)}
                                      placeholder="Select Categories..."
                                      styles={colourStyles}
                                    />
                                  </div>
                                </Col>
                                <Col md={4}>
                                  <div className="w-100 research-watchlists">
                                    <Label className="bold-text">
                                      Exclude Categories
                                    </Label>
                                    <Select
                                      isMulti
                                      isDisabled={videoCategoriesOptions.length === 0}
                                      isLoading={loadingVideoFacets}
                                      name="videoExcludeCategoriesSelect"
                                      isClearable
                                      value={videoExcludeCategories}
                                      options={videoCategoriesOptions}
                                      onChange={value => setVideoExcludeCategories(value)}
                                      placeholder="Select Categories..."
                                      styles={colourStyles}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            <Col md={3}>
                              <div className="w-100 research-watchlists">
                                <Label className="bold-text">
                                  Video Languages
                                </Label>
                                <Select
                                  isMulti
                                  name="videoLanguagesSelect"
                                  isClearable
                                  value={videoLanguages}
                                  options={languagesOptions}
                                  onChange={value => setVideoLanguages(value)}
                                  placeholder="Select Languages..."
                                  styles={colourStyles}
                                />
                                <Label className="bold-text" style={{ marginTop: '16px' }}>
                                  Creator Countries
                                </Label>
                                <Select
                                  isMulti
                                  name="videoCreatorCountriesSelect"
                                  isClearable
                                  value={videoCreatorCountries}
                                  options={countriesOptions}
                                  onChange={value => setVideoCreatorCountries(value)}
                                  placeholder="Select Countries..."
                                  style={{ maxHeight: '120px' }}
                                  styles={colourStyles}
                                />
                                <Label className="bold-text" style={{ marginTop: '16px' }}>
                                  Creator Types
                                </Label>
                                <Select
                                  isMulti
                                  name="videoCreatorTypesSelect"
                                  isClearable
                                  value={videoCreatorTypes}
                                  options={creatorTypesOptions}
                                  onChange={value => setVideoCreatorTypes(value)}
                                  placeholder="Select Types..."
                                  style={{ maxHeight: '120px' }}
                                  styles={colourStyles}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                      {activeTypeCheckbox === 'podcast' && (
                        <Row>
                          <Col md={1}>
                            <Label className="bold-text" style={{ marginBottom: '14px' }}>
                              Search in...
                            </Label>
                            <FormGroup
                              style={{ marginBottom: '10px' }}
                            >
                              <CheckBoxField
                                name="title"
                                label="Title"
                                value={podcastOnlyIn.title}
                                onChange={handleChangeOnlyIn}
                              />
                            </FormGroup>
                            <FormGroup
                              style={{ marginBottom: '10px' }}
                            >
                              <CheckBoxField
                                name="description"
                                label="Description"
                                value={podcastOnlyIn.description}
                                onChange={handleChangeOnlyIn}
                              />
                            </FormGroup>
                            <FormGroup
                              style={{ marginBottom: '10px' }}
                            >
                              <CheckBoxField
                                name="author"
                                label="Author"
                                value={podcastOnlyIn.author}
                                onChange={handleChangeOnlyIn}
                              />
                            </FormGroup>
                            <FormGroup>
                              <CheckBoxField
                                name="audio"
                                label="Audio"
                                value={podcastOnlyIn.audio}
                                onChange={handleChangeOnlyIn}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={2}>
                            <FormGroup>
                              <div className="w-100 research-watchlists">
                                <Label className="bold-text">
                                  Type
                                </Label>
                                <Select
                                  name="podcastTypeSelect"
                                  value={podcastType}
                                  options={podcastTypeOptions}
                                  onChange={value => setPodcastType(value)}
                                  styles={colourStyles}
                                />
                              </div>
                            </FormGroup>
                            <FormGroup>
                              <div className="w-100 research-watchlists">
                                <Label className="bold-text">
                                  Sort
                                </Label>
                                <Select
                                  name="podcastSortSelect"
                                  value={podcastSort}
                                  options={podcastSortOptions}
                                  onChange={value => setPodcastSort(value)}
                                  styles={colourStyles}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md={2}>
                            <FormGroup>
                              <div className="w-100 research-watchlists">
                                <Label className="bold-text">
                                  Language
                                </Label>
                                <Select
                                  isDisabled={podcastLanguageOptionsLoading}
                                  isLoading={podcastLanguageOptionsLoading}
                                  name="podcastLanguageSelect"
                                  isClearable
                                  value={podcastLanguage}
                                  options={podcastLanguageOptions}
                                  onChange={value => setPodcastLanguage(value)}
                                  placeholder="Select Language..."
                                  style={{ maxHeight: '120px' }}
                                  styles={colourStyles}
                                />
                              </div>
                            </FormGroup>
                            <FormGroup>
                              <div className="w-100 research-watchlists">
                                <Label className="bold-text">
                                  Region
                                </Label>
                                <Select
                                  isDisabled={podcastRegionOptionsLoading}
                                  isLoading={podcastRegionOptionsLoading}
                                  name="podcastRegionSelect"
                                  isClearable
                                  value={podcastRegion}
                                  options={podcastRegionOptions}
                                  onChange={value => setPodcastRegion(value)}
                                  placeholder="Select Region..."
                                  style={{ maxHeight: '120px' }}
                                  styles={colourStyles}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md={2}>
                            <FormGroup>
                              <Label className="bold-text">
                                Published After
                              </Label>
                              <DatePicker
                                isClearable
                                selected={podcastPublishedAfter}
                                onChange={date => setPodcastPublishedAfter(date)}
                                dateFormat="MM/dd/yyyy"
                                dropDownMode="select"
                                className="form-control"
                                maxDate={maxDate}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label className="bold-text">
                                Published Before
                              </Label>
                              <DatePicker
                                isClearable
                                selected={podcastPublishedBefore}
                                onChange={date => setPodcastPublishedBefore(date)}
                                dateFormat="MM/dd/yyyy"
                                dropDownMode="select"
                                className="form-control"
                                maxDate={maxDate}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={5}>
                            <FormGroup>
                              <div className="w-100 research-watchlists">
                                <Label className="bold-text">
                                  Genre(s)
                                </Label>
                                <Select
                                  isMulti
                                  name="podcastGenresSelect"
                                  isDisabled={podcastGenresOptionsLoading}
                                  isLoading={podcastGenresOptionsLoading}
                                  isClearable
                                  value={podcastGenres}
                                  options={podcastGenresOptions}
                                  onChange={value => setPodcastGenres(value)}
                                  placeholder="Select Genres..."
                                  style={{ maxHeight: '120px' }}
                                  styles={colourStyles}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                    </ThemeProvider>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ChannelsListContainer
        loading={loading}
        searchResults={searchResults}
        searchMetadata={searchMetadata}
        gotoPage={gotoPage}
        pageNumber={pageNumber}
        atLeastOneSearchIsMade={atLeastOneSearchIsMade}
        searchedText={searchedText}
        activeTypeCheckbox={activeTypeCheckbox}
        searchTokens={searchTokens}
        addSearchItemToTheList={addSearchItemToTheList}
        watchlists={watchlists}
        getMyWatchlists={getMyWatchlists}
        dispatch={dispatch}
        searchQueryText={queryText}
        searchFilters={filtersProp}
        searchSortOptions={sortOptionsProp}
        getSearchDataToSave={getSearchDataToSave}
      />
      <SaveSearchModal
        isOpen={showSaveSearchModal}
        toggleModal={() => setShowSaveSearchModal(false)}
        saveSearch={onSaveSearch}
        message={saveSearchModalMessage}
        savingSearch={savingSearch}
        savedSearchesNames={savedSearchesForGrid?.map(savedSearch => savedSearch.name)}
        searchName={(currentSavedSearchOption && currentSavedSearchOption.label) ? currentSavedSearchOption.label : ''}
        searchVisibility={
          currentSavedSearchOption
          && currentSavedSearchOption.value
          && savedSearchesForGrid
          && savedSearchesForGrid.find(savedSearch => savedSearch._id === currentSavedSearchOption.value)
            ? savedSearchesForGrid.find(savedSearch => savedSearch._id === currentSavedSearchOption.value).visibility
            : 'public'
        }
        searchOwnerId={
          currentSavedSearchOption
          && currentSavedSearchOption.value
          && savedSearchesForGrid
          && savedSearchesForGrid.find(savedSearch => savedSearch._id === currentSavedSearchOption.value)
            ? savedSearchesForGrid.find(savedSearch => savedSearch._id === currentSavedSearchOption.value).adminUserOwner?._id
            : ''
        }
      />
      <KeywordsHashtags />
      <ShowRejectedApprovedBrands />
      <ShowHubspotDeals />
    </Container>
  );
};

SearchList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  searchResults: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  searchMetadata: PropTypes.shape({
    total: PropTypes.number.isRequired,
  }).isRequired,
  searchTokens: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  watchlists: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  loadingSavedSearchesOptions: PropTypes.bool.isRequired,
  savedSearchesOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  savedSearchesForGrid: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  prospectUploadsSavedSearchesForGrid: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  loadingVideoFacets: PropTypes.bool.isRequired,
  videoFacetsResults: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  loadingCreatorFacets: PropTypes.bool.isRequired,
  creatorFacetsResults: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

SearchList.defaultProps = {
  searchResults: [],
  videoFacetsResults: [],
  creatorFacetsResults: [],
};

const mapStateToProps = state => ({
  adminUser: state.session,
  loading: state.ResearchReducer.loading.search,
  searchResults: state.ResearchReducer.searchResults,
  searchMetadata: state.ResearchReducer.searchMetadata,
  searchTokens: state.ResearchReducer.searchTokens,
  watchlists: state.ResearchReducer.watchlists,
  loadingSavedSearchesOptions: state.ResearchReducer.loading.savedSearchesOptions,
  savedSearchesOptions: state.ResearchReducer.savedSearchesOptions,
  savedSearchesForGrid: state.ResearchReducer.savedSearchesForGrid,
  prospectUploadsSavedSearchesForGrid: state.ResearchReducer.prospectUploadsSavedSearchesForGrid,
  loadingVideoFacets: state.ResearchReducer.loading.videoFacets,
  videoFacetsResults: state.ResearchReducer.videoFacetsResults,
  loadingCreatorFacets: state.ResearchReducer.loading.creatorFacets,
  creatorFacetsResults: state.ResearchReducer.creatorFacetsResults,
});

export default connect(mapStateToProps)(withRouter(SearchList));
