import { unflatten } from 'flat';
import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Hook from '../../../../../../shared/hooks';
import { getConstant } from '../../../../../../shared/helpers/WVConstants';

const Authorized = ({ record }) => {
  const validationDealByType = getConstant('deal', 'validationDealByType', []);
  const [showModal] = Hook.useShowModalHook('SuccessModal');
  const r = unflatten(record.populated.deal);
  // console.log(r, 'rrrrr')
  // eslint-disable-next-line max-len
  const message = (r.params?.authorizedPayment
  && r.params?.dealTerms?.type_of_deal
  && validationDealByType[r.params.dealTerms.type_of_deal]
    ? Object.keys(validationDealByType[r.params.dealTerms.type_of_deal]).map((key) => {
      let validationColor = r.params?.authorizedPayment[key]
      let validOrNot = r.params?.authorizedPayment[key] ? `Yes ${key === 'taxValid' && r.params?.authorizedPayment.taxValidStripe ? ' (Stripe)' : ''}` : 'No';
      if (Number(r.params?.ilDealData?.offer?.advertiserTuneid) !== 2 && ['cpmPriceApproved', 'cpmIoSigned'].includes(key)) return null;
      if (key === 'contentPublicAndPublished') {
        if( r.params?.authorizedPayment.contentPublicAndPublished !== null) {
          validationColor = r.params?.authorizedPayment.contentPublicAndPublished;
          validOrNot = r.params?.authorizedPayment.contentPublicAndPublished ? 'Yes' : 'No';
        }
        if( r.params?.authorizedPayment.contentPublicAndPublishedBaD !== null) {
          validationColor = r.params?.authorizedPayment.contentPublicAndPublishedBaD;
          validOrNot = `${r.params?.authorizedPayment.contentPublicAndPublishedBaD ? 'Yes' : 'No'} (B/A/D)`;
        }
      }
      if (['contentBrandApproved', 'contentUrlValid', 'contentPublicAndPublished'].includes(key)
        && r.params?.authorizedPayment.contentBrandInactive) {
        validOrNot = 'Content Inactive';
        validationColor = r.params?.authorizedPayment.contentBrandInactive;
      }
      return (
        <li key={key} className={ validationColor ? 'text-success' : 'text-danger'}>
          {validationDealByType[r.params.dealTerms?.type_of_deal][key]}:
          {validOrNot}
        </li>);
    }) : []).filter(a => a);
  return (
    <>
      <IconButton size="small" onClick={() => showModal({ title: 'Authorized Info', message: (<ul>{message}</ul>) })}>
        {r.params?.authorizedPayment?.summaryValidation ? (
          <FontAwesomeIcon icon="thumbs-up" className="text-success" />
        ) : (
          <FontAwesomeIcon icon="thumbs-down" className="text-danger" />
        )
        }
      </IconButton>
    </>
  );
};
Authorized.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default Authorized;
