import React, { useState } from 'react';

import { Row, Col, Container, Card, CardBody, CardTitle, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import Breadcrumbs from '../../../../../shared/components/BreadCrumbs';
import AccessComponent from '../../../../../shared/components/auth/AccessComponent';
import classnames from 'classnames';
import NewWinnerSettingsComponent from './NewWinnerSettingsComponent';

const Settings = () => {
  const [activeTab, setActiveTab] = useState('newWinnerSettings');

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs
            isBackButton
            links={[
              { title: 'Home', path: '/' },
              { title: 'Reports', path: false },
              { title: 'New Winners Report Settings', path: false },
            ]}
          />
        </Col>
      </Row>
      <AccessComponent showWhenTeam={['management', 'super_powers', 'engineering']}>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle>
                  <h3 className="d-inline">New Winners Report Settings </h3>
                </CardTitle>
                <hr />
                <Row className="mt-3">
                  <Col className="tabs">
                    <Nav className="mt-2" tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === 'newWinnerSettings' })}
                          onClick={() => {
                            setActiveTab('newWinnerSettings');
                          }}
                        >
                          New Winner Settings
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="newWinnerSettings">
                        <NewWinnerSettingsComponent />
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </AccessComponent>
    </Container>
  );
};

export default Settings;
