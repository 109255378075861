import React from 'react';
import {
  ButtonToolbar,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Button,
} from 'reactstrap';
import moment from 'moment';
import Hook from '../../../../../../../../shared/hooks';
import { currentTimezoneAggr } from '../../../../../../../../shared/helpers/Dates';

const modalId = 'HistoryModal';

const aValues = {
  approved: 'Approved',
  inReview: 'In Review',
  pendingEdits: 'Pending Edits',
  hold: 'Hold',
  rejected: 'Rejected',
  actionRequired: 'Action Required',
};

const HistoryModal = () => {
  const { data: dataModal, showModal: isOpen, close } = Hook.useModalHook(modalId);
  const {
    record, title,
  } = dataModal;
  if (!dataModal || !dataModal.record) return false;


  return (
    <Modal
      isOpen={isOpen}
      toggle={() => { close(); }}
      style={{ minWidth: '670px' }}
    >
      <ModalHeader
        toggle={() => {
          close();
        }}
        tag="h4"
        className="modal-title-statistics pl-0"
      >
        {title || ''}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            {record.params.approvalStatus?.history?.length > 0 ? (
              <div className="mt-1 mb-4">
                <h4 className="font-weight-bold">IL Status Change History</h4>
                <hr className="mt-1" />
                {record.params.approvalStatus.history.map((item, key) => {
                  const previousName = aValues[item.previousStatus];
                  const currentName = aValues[item.selectedStatus];
                  const { adminUser } = record.populated.approvalStatus.history[key];
                  const { firstName, lastName } = adminUser.params || adminUser;
                  const name = `${firstName || ''} ${lastName || ''}`;
                  return (
                    <div key={moment(item.dateModified).toString() + moment.now().toString()}>
                      <div><strong>{moment(item.dateModified).format('M/D/YYYY h:mm A')} {currentTimezoneAggr()}</strong></div>
                      <div className="pl-3">{name || 'No User'} - <u> {previousName || 'Empty'}</u> changed to <u>{currentName || '---'}</u></div>
                    </div>);
                })}
              </div>
            ) : null}

            {record.params.approvalsBrandStatus?.history?.length > 0 ? (
              <div className="mt-1 mb-4">
                <h4 className="font-weight-bold">Brand Status Change History</h4>
                <hr className="mt-1" />
                {record.params.approvalsBrandStatus.history.map((item, key) => {
                  const previousName = aValues[item.previousStatus];
                  const currentName = aValues[item.selectedStatus];
                  const { brandUser } = record.populated.approvalsBrandStatus.history[key];
                  const { firstName, lastName } = brandUser.params || brandUser;
                  const name = `${firstName || ''} ${lastName || ''}`;
                  return (
                    <div key={moment(item.dateModified).toString() + moment.now().toString()}>
                      <div><strong>{moment(item.dateModified).format('M/D/YYYY h:mm A')} {currentTimezoneAggr()}</strong></div>
                      <div className="pl-3">{name || 'No User'} - <u> {previousName || 'Empty'}</u> changed to <u>{currentName || '---'}</u></div>
                    </div>);
                })}
              </div>
            ) : null}
          </Col>
        </Row>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button className="modal_cancel btn-sm" onClick={() => { close(); }}>Close</Button>
      </ButtonToolbar>
    </Modal>
  );
};
export default HistoryModal;
