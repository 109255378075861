import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import axios from 'axios';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';

const CellSelect = React.memo(({
  name,
  label,
  record,
  values,
  update,
  afterSaveFunc,
  updateRecordFunction,
  showNotes,
  useHistory,
  disableNotAssigned,
  customNoteField,
  forceShowNotesCondition,
  styles,
  disableOptionMap,
  additionalFieldsToUpdate,
}) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [disabled, setDisabled] = useState(false);
  let notesVal = '';
  notesVal = showNotes ? (record?.params[name]?.notes || '') : '';
  if (customNoteField) {
    if (record?.params[customNoteField] && record?.params[customNoteField].value) {
      notesVal = showNotes ? record?.params[customNoteField].value : '';
    } else if (record?.params[customNoteField] && record?.params[customNoteField].value !== '') {
      notesVal = showNotes ? record?.params[customNoteField] : '';
    }
  }

  useEffect(() => {
    if (values?.length > 0) {
      const notAssigned = { value: null, label: '-- Not Assigned --' };
      const _options = !disableOptionMap ? values.map(value => ({ value, label: value })) : [...values];
      if (!disableNotAssigned) _options.unshift(notAssigned);
      setOptions(_options);
    }
  }, [values]);

  useEffect(() => {
    const _value = useHistory ? record?.params[name]?.value : record?.params[name];
    const selectedValue = _value || null;
    setSelectedOption(options.find(option => option.value === selectedValue));
  }, [record.id, record.params[name], options]);

  const handleChange = async (selectedItem) => {
    const { value } = selectedItem;
    if (forceShowNotesCondition && value === forceShowNotesCondition) {
      setTimeout(showNotes, 500);
    }
    setSelectedOption(selectedItem);
    setDisabled(true);
    const paramsKey = useHistory ? 'paramsWithHistory' : 'params';
    let updateQuery = {
      [name]: value,
    };
    if (additionalFieldsToUpdate) {
      updateQuery = {
        ...updateQuery,
        ...additionalFieldsToUpdate,
      }
    }
    try {
      axios.post(update, {
        id: record.id,
        [paramsKey]: updateQuery,
      }).then((response) => {
        setDisabled(false);
        const _record = response.data.record;
        if (_record && updateRecordFunction) {
          updateRecordFunction(_record);
        } else if (afterSaveFunc) {
          afterSaveFunc(response);
        }
      });
    } catch (e) {
      console.log(e.message);
      setDisabled(false);
    }
    return true;
  };

  return (
    <div style={{ width: '180px' }}>
      <div style={styles || { width: '150px', float: 'left' }} >
        <Select
          value={selectedOption}
          options={options}
          onChange={handleChange}
          placeholder={label}
          isDisabled={disabled}
          menuPortalTarget={document.body}
        />
      </div>
      {showNotes && (
        <div style={{ marginTop: '6px', marginRight: '6px', float: 'right' }}>
          <Tooltip title={`${label} Notes`}>
            <IconButton
              size="small"
              onClick={showNotes}
            >
              <FontAwesomeIcon icon={[notesVal !== '' ? 'fas' : 'far', 'file-alt']} />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  );
}, (a, a1) => a.record.id === a1.record.id
  && _.isEqual(a.record.params[a.name], a1.record.params[a1.name])
  && _.isEqual(a.record.params[a.customNoteField], a1.record.params[a1.customNoteField]));

  CellSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  values: PropTypes.arrayOf(PropTypes.any).isRequired,
  update: PropTypes.string.isRequired,
  afterSaveFunc: PropTypes.func,
  updateRecordFunction: PropTypes.func,
  showNotes: PropTypes.func,
  useHistory: PropTypes.bool,
  disableNotAssigned: PropTypes.bool,
  disableOptionMap: PropTypes.bool,
  customNoteField: PropTypes.string,
  forceShowNotesCondition: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.any),
  additionalFieldsToUpdate: PropTypes.objectOf(PropTypes.any),
};

CellSelect.defaultProps = {
  afterSaveFunc: null,
  updateRecordFunction: null,
  showNotes: null,
  useHistory: false,
  disableNotAssigned: false,
  disableOptionMap: false,
  customNoteField: '',
  forceShowNotesCondition: '',
  styles: null,
  additionalFieldsToUpdate: null,
};

export default CellSelect;
