import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Modal,
  ModalHeader,
  ButtonToolbar,
  ModalBody,
  Button,

} from 'reactstrap';
import { IconButton } from '@material-ui/core';

import { axios } from 'ApiClient';
import Hook from '@/shared/hooks';
import ReactTableBase from '@/shared/tables/table/ReactTableBase';

export const modalId = 'sendEmailModalProcess';

const tableConfig = {
  isEditable: false,
  isResizable: false,
  isSortable: true,
  withPagination: true,
  manualPageSize: [5, 10, 15, 20],
  withSearchEngine: false,
  useFlex: true,
  placeholder: 'Search for Permission Item or Path...',
  sortBy: 'main',
  direction: 'desc',
};

const CountdownTimer = () => {
  const [secondsRemaining, setSecondsRemaining] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsRemaining((prev) => (prev === 1 ? 10 : prev - 1));
    }, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return <div>Next update in: {secondsRemaining} sec</div>;
};

const ProcessingSend = () => {

  const [items, setItems] = useState([]);
  const [timeInterval, setTimeInterval] = useState(null);
  const { data: dataModal, showModal: isShow, close } = Hook.useModalHook(modalId);
  const getItems = async () => {
    const res = await axios.get('/api/portal/opportunity/get-unique-logs');
    if (res.data.items) {
      setItems(res.data.items);
    }
  };
  useEffect(async () => {
    if (isShow) {
      setTimeInterval(setInterval(async () => {
        await getItems();
      }, 10000));
      getItems();
    }
  }, [isShow]);

  if (!dataModal) return false;
  const closeFunc = () => {
    clearInterval(timeInterval);
    setTimeInterval(null);
    close();
  };

  const columnTransform = [
    {
      id: 'main',
      Header: 'Process Started',

      accessor: (record) => moment(record.dateSend).toISOString(),
      Cell: ({ value }) => moment(value).format('MM/DD/YYYY hh:mm A'),
      width: 150,
    },
    {
      id: 'total',
      Header: 'Total',
      accessor: (record) => Number(record.total || 0),
      sort: () => { },
      width: 80,
      disableSortBy: true,

    }, {
      id: 'success',
      Header: 'Success',
      accessor: (record) => Number(record.stats.PENDING || 0),
      sort: () => { },
      width: 50,
      disableSortBy: true,

    }, {
      id: 'error',
      Header: 'Error',
      accessor: (record) => Number(record.stats.error || 0),
      sort: () => { },
      width: 50,
      disableSortBy: true,

    },
    {
      id: 'other',
      Header: 'Other',
      accessor: (record) => Object.keys(record.stats).filter(a => !['error', 'PENDING'].includes(a)).map(a => record.stats[a]).reduce((a, b) => Number(a || 0) + Number(b || 0), 0),
      width: 50,
      disableSortBy: true,

    },
    {
      id: 'status',
      Header: 'Status',
      accessor: (record) => Object.values(record.stats).reduce((a, b) => Number(a || 0) + Number(b || 0)),
      Cell: ({ value, row }) => {
        if (Number(row.original.total) - value <= 0) {  
          return <span className="text-success">Completed</span>;
        }
        return <span className="text-warning">Processing</span>;
      },
      width: 80,
      disableSortBy: true,

    },
    {
      id: 'action',
      Header: '',
      accessor: (record) => record.sendGroupId,
      Cell: ({ value, row }) => (<a href={`/api/portal/opportunity/get-unique-logs-csv?sendGroupId=${value}`} target="_blank" rel="noreferrer">
        <IconButton size="small">
          <i className="fa fa-download" style={{ fontSize: '0.8em' }} />
        </IconButton>
      </a>),
      width: 40,
      align: 'right',
      disableSortBy: true,

    }
  ];

  
  const { record } = dataModal;
  console.log(record, 'modalInfo');
  return (
    <Modal
      size="md"
      isOpen={isShow}
      toggle={closeFunc}
      className='ltr-support theme-light'
    >
      <ModalHeader toggle={closeFunc} tag="h4">
        Send Opportunity Emails
      </ModalHeader>
      
      <ModalBody>
      <div class='text-right'><CountdownTimer /></div>
        <div class='mt-3'>
          <ReactTableBase
            key={'someDefaultFate'}
            columns={columnTransform}
            data={items}
            tableConfig={tableConfig}
          />
        </div>
      </ModalBody>
      <ButtonToolbar className="modal__footer">
        <Button color="secondary" onClick={closeFunc}>Cancel</Button>

      </ButtonToolbar>
    </Modal>
  );
};
export default ProcessingSend
