import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Button,
} from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import CheckBoxField from '../../../../../../shared/components/CheckBox';
import { clearFilterProperty } from '../../../../../../redux/reducers/TableReducerDefault';
import Hook from '../../../../../../shared/hooks';
import DashboardActions from './Table/DashboardActions'

const Actions = React.memo(({
  downloadCsv,
}) => {
  console.log('RELOAD ACRIONS');
  const dispatch = useDispatch();
  const [search, setSearch] = Hook.useTableFilterHookString('fullSearchName');
  const [isDeleted, setIsDeleted] = Hook.useTableFilterHookString('deleted');
  // console.log(selectedIds, 'selectedIds');
  const resetFilters = () => {
    dispatch(clearFilterProperty());
  };
  return (
    <>
      <Row>
        <Col sm={6}>
          <InputGroup>
            <Input
              className="form-control-sm form-control"
              name="search"
              type="search"
              autoComplete="off"
              placeholder="Search by Name and Email"
              value={search}
              onChange={p => setSearch(p.target.value)}
              style={{ height: '44px' }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="fa fa-search"
                  aria-hidden="true"
                  style={{ color: '#70bbfd', fontSize: 12 }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col
          sm={2}
          className="text-right"
          style={{ paddingTop: '12px' }}
        >
          <CheckBoxField
            name="showDeleted"
            label="Show deleted users"
            value={isDeleted !== 'false'}
            onChange={(e) => setIsDeleted(e.target?.checked ? 'true' : 'false')}
          />
        </Col>
        <Col sm={2}>
          <Row>
            <Col>
              <Tooltip title="Reset Filters">
                <Button
                  color="secondary"
                  size="sm"
                  onClick={resetFilters}
                  style={{
                    width: '100%',
                    padding: '4px 8px',
                    height: '42px',
                  }}
                >
                  <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                  <span className="btn-text">Reset</span>
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col md={2} className="text-right">
          <DashboardActions/>
        </Col>
      </Row>
    </>
  );
});
Actions.propTypes = {
  downloadCsv: PropTypes.func.isRequired,
};

export default Actions;
