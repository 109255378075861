import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import config from '../config/config';

const ReCaptcha = ({ onVerify, token }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const testParam = urlParams.get('inTestMode');

  const [r, refresh] = useState(false);
  const [d, done] = useState(false);

  useEffect(() => {
    if (token === '') {
      if (d) refresh(!r);
    } else if (!d) {
      done(true);
    }
  }, [token]);

  return !testParam && (
    <GoogleReCaptchaProvider reCaptchaKey={config().recaptcha.siteKey}>
      <GoogleReCaptcha
        className="google-recaptcha-custom-class"
        onVerify={onVerify}
        refreshReCaptcha={r}
      />
    </GoogleReCaptchaProvider>
  );
};

ReCaptcha.propTypes = {
  onVerify: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default ReCaptcha;
