import React, { useState, useEffect } from 'react';
import { axios } from 'ApiClient';
import Select from 'react-select';
import { Col, Container, Row, Card, CardBody, Label, FormGroup } from 'reactstrap';
import Hook from '@/shared/hooks';



// import { ConsoleIcon } from 'mdi-react';


export default ({ addNotice }) => {
  console.log();
  const [loading, setLoading] = useState(false);
  const [hasoffers, setHasOffers] = useState('disabled');
  const [hubspot, setHubspot] = useState('enableDev');
  const [stripe, setStripe] = useState('enableDev');
  // const [billCom, setBillCom] = useState('enableDev');

  const { showError } = Hook.useNoticeHook();

  const loadStagingPermissions = async (value = null) => {
    setLoading(true);
    try {
      const data = await axios({
        method: 'get',
        url: '/api/portal/global/get-staging-values',
      });
      console.log(data.data);
      if (data.data.success === true) {
        setHasOffers(data.data.hasoffers);
        setHubspot(data.data.hubspot);
        setStripe(data.data.stripe);
        // setBillCom(data.data.billcom);
      } else throw new Error('Unable to get value');
    } catch (err) {
      showError(err.message || 'Unable to get Google Oauth 2 Token');
    }
    setLoading(false);
  };

  const setUpdateStagingPermissions = async (key, value) => {
    setLoading(true);
    try {
      const data = await axios({
        method: 'post',
        url: '/api/portal/global/post-update-staging',
        data: {
          key,
          value,
        },
      });
      console.log(data.data);
      if (data.data.success === true) {
        setHasOffers(data.data.hasoffers);
        setHubspot(data.data.hubspot);
        setStripe(data.data.stripe);
        // setBillCom(data.data.billcom);
      } else throw new Error('Unable to update value');
    } catch (err) {
      showError(err.message || 'Unable to get Google Oauth 2 Token');
    }
    setLoading(false);
  };

  useEffect(async () => {
    await loadStagingPermissions();
  }, []);
  const hsOptions = [{ label: 'Use Dev Hubspot Portal', value: 'enableDev' }, { label: 'Use Prod Hubspot Portal', value: 'enableProd' }];
  const hoOptions = [{ label: 'Disable Hasoffers', value: 'disabled' }, { label: 'Enable Hasoffers', value: 'enable' }];

  const stripeOptions = [{ label: 'Use Dev Stripe Portal', value: 'enableDev' }, { label: 'Use Prod Stripe Portal(do not test payments!!!!!)', value: 'enableProd' }];

  // const billComOptions = [{ label: 'Use Dev billCom Portal', value: 'enableDev' }, { label: 'Use Prod billCom Portal(do not test payments!!!!!)', value: 'enableProd' }];
  return (
    <Container>
        <Card>
          <CardBody>
            <Row>
              <Col sm="3">
                <FormGroup>
                  <Label>Enable Hubspot</Label>
                  <Select
                    isDisabled={loading}
                    options={hsOptions}
                    onChange={record => record.value && setUpdateStagingPermissions('hubspot', record.value)}
                    value={hsOptions.find(record => record.value === hubspot)}
                  />
                </FormGroup>
              </Col>
              <Col sm="3">
                <FormGroup>
                  <Label>Enable Hasoffes</Label>
                  <Select
                    isDisabled={loading}
                    options={hoOptions}
                    onChange={record => record.value && setUpdateStagingPermissions('hasoffers', record.value)}
                    value={hoOptions.find(record => record.value === hasoffers)}
                  />
                </FormGroup>
              </Col>
              <Col sm="3">
                <FormGroup>
                  <Label>Stripe</Label>
                  <Select
                    isDisabled={loading}
                    options={stripeOptions}
                    onChange={record => record.value && setUpdateStagingPermissions('stripe', record.value)}
                    value={stripeOptions.find(record => record.value === stripe)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
    </Container>
  );
};


// export default withNotice(connect(mapStateToProps)(PushNotifications));
