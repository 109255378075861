import React, { useState, useEffect, useMemo } from 'react';
// import PropTypes from 'prop-types';
import { axios } from 'ApiClient';
import { Col, Container, Row, Card, CardBody, Spinner } from 'reactstrap';
// import withNotice from '../../../App/store/with-notice';
import ReactTableBase from '../../../../shared/tables/table/ReactTableBase';
import ContextMenuInList from './ContextMenuInList';
import Hook from '@/shared/hooks';


export default () => {
  console.log();
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState([]);
  const { showError } = Hook.useNoticeHook();
  const loadMigrations = async () => {
    setLoading(true);
    try {
      const data = await axios({
        method: 'get',
        url: '/api/portal/global/get-atlas-search-indexes',
      });
      console.log(data.data);
      if (data.data.success === true) {
        setStats(data.data.migrations);
      } else {
        setStats([]);
      }
    } catch (err) {
      showError(err.message || 'Unable to get migrations');
    }
    setLoading(false);
  };

  useEffect(async () => {
    await loadMigrations();
  }, []);
  const tableConfig = {
    isEditable: false,
    isResizable: false,
    isSortable: true,
    useFlex: true,
    withPagination: true,
    manualPageSize: [20, 40],
    withSearchEngine: true,
    sortBy: 'name',
    direction: 'desc',
  };

  const columnTransform = useMemo(() => {
    console.log('match');
    return [{
      Header: () => ('Atlas Search Name'),
      accessor: d => d.name,
      id: 'Name',
      width: 200,

    }, 
    {
      Header: 'Local File Name',
      accessor: d => d.localIndex ? d.localFile : 'No File',
      id: 'localFile',
      width: 200,

    },
    {
      Header: 'Is Same',
      accessor: d => d.isSame ? 'Yes' : 'No',
      Cell: p => (
        <span className={`badge badge-${p.row.original.isSame ? 'success' : 'danger'}`}>{p.row.original.isSame ? 'YES' : 'NO'}</span>
      ),

      id: 'isSame',
      width: 100,

    },
    {
      Header: 'Atlas Search Status',
      accessor: d => d.atlasSearchIndex?.status || 'NO INDEX',
      id: 'status',
      Cell: p => (
        <span className={`badge badge-${p.row.original.atlasSearchIndex?.status ? 'success' : 'danger'}`}>{p.row.original.atlasSearchIndex?.status || 'NO INDEX'}</span>
      ),
      width: 200,

    },
    {
      id: 'actions',
      Header: () => '',
      accessor: () => null,
      Cell: p => (
        <ContextMenuInList
          record={p.row.original}
          loadMigrations={loadMigrations}
        />
      ),
      disableSortBy: true,
      className: 'align-self-center',
      width: 50,
      maxWidth: 50,
    }
  ];
  }, []);
  return (
    <Container>
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h3 className="il-color-deep-blue font-weight-bold">Atlas Search</h3>
                </Col>
              </Row>
              <hr className="mt-3 mb-4" />
              {loading ? (<div className="text-center"><Spinner /></div>) : null}
              {!loading ? (
                <ReactTableBase
                  key="filesIO"
                  columns={columnTransform}
                  data={stats}
                  tableConfig={tableConfig}
                />) : null}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
