/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

const capitalizeFirstLetter = (str) => {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const getPropertyLabel = (property) => {
  if (property === 'status') return 'Content Review Status';
  if (property === 'title') return 'Title';
  if (property === 'contentText') return 'Description';
  if (property === 'duration') return 'Duration';
  if (property === 'privacyStatus') return 'Privacy Status';
  if (property === 'hasPaidProductPlacement') return 'Includes Paid Promotion';
  return property;
}

const getPropertyValue = (item) => {
  if (item.property === 'status') return item.newStatus;
  if (item.property === 'privacyStatus') return capitalizeFirstLetter(item.newValue);
  if (item.property === 'hasPaidProductPlacement') {
    if (item.newValue === true) {
      return 'Yes';
    } else if (item.newValue === false) {
      return 'No';
    } else return 'Not Assigned';
  }
  return item.newValue;
}

const PropertyHistoryComponent = ({
  item
}) => {
  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  return (
    <div
      style={{
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '15px',
        marginBottom: '15px',
        alignItems: 'center',
      }}
    >
      <p><strong><u>{getPropertyLabel(item?.property)}</u></strong> changed to:</p>
      <p
        style={item?.property === 'contentText' ? {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: showFullText ? 'unset' : 3,
          WebkitBoxOrient: 'vertical',
        } : {}}
      >
        {getPropertyValue(item)}
      </p>
      <div
        style={{
          paddingTop: '8px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span
          className="il-text-gray"
          style={{
            fontSize: '14px',
            marginRight: '8px',
          }}
        >
          {item.adminUserName} on {moment.utc(item.dateModified)
          .tz('America/New_York')
          .format('MMMM Do YYYY [@] h:mma [ET]')}
        </span>
        {item?.property === 'contentText' && (
          <>
            {!showFullText && (
              <button
                style={{
                  color: '#70bbfd',
                  background: 'none',
                  border: 'none',
                  padding: 0,
                  margin: 0,
                  font: 'inherit',
                  cursor: 'pointer',
                }}
                onClick={toggleText}
              >
                Read more
              </button>
            )}
            {showFullText && (
              <button
                style={{
                  color: '#70bbfd',
                  background: 'none',
                  border: 'none',
                  padding: 0,
                  margin: 0,
                  font: 'inherit',
                  cursor: 'pointer',
                }}
                onClick={toggleText}
              >
                Read less
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
}

PropertyHistoryComponent.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PropertyHistoryComponent;
