import React, { useState, useEffect } from 'react';
// import { unflatten } from 'flat';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { checkImagePromise, parseUrl } from '../../../../../../../shared/helpers/WVUtilities';
import HighLight from '../../../../../../../shared/components/HighLight';
import CopyToClipboard from '../../../../../../../shared/components/CopyToClipboardLeft';
import { formatIntNumber } from '../../../../../../../shared/helpers/WVFormatter';
// import BrandApprovalsToolTip from '../../../../../../../shared/components/prospecting/BrandApprovalsToolTip';
import {
  AddressIcon,
  CopyIcon,
} from '../../../../../../../shared/helpers/Icons';

const CreatorInList = React.memo(({
  record, contact, creator
}) => {
  const [image, setImage] = useState('/images/user/defaultAvatar.png');
  const filters = parseUrl();
  const search = filters.search || '';
  const emailAddress = creator?.email || contact?.email || '';
  const name = creator?.fullName || `${contact?.firstname || ''} ${contact?.lastname || ''}`;
  const recordY = record?.tubularDetails?.accounts.find(a => a.platform === 'youtube');
  const recordI = record?.tubularDetails?.accounts.find(a => a.platform === 'instagram');
  const recordF = record?.tubularDetails?.accounts.find(a => a.platform === 'facebook');
  const recordT = record?.tubularDetails?.accounts.find(a => a.platform === 'twitter');
  const recordTwitch = record?.tubularDetails?.accounts.find(a => a.platform === 'twitch');

  useEffect(async () => {
    setImage('/images/user/defaultAvatar.png');
    if (recordY && await checkImagePromise(recordY.thumbnailUrl)) {
      setImage(recordY.thumbnailUrl);
    } else if (recordI && await checkImagePromise(recordI.thumbnailUrl)) {
      setImage(recordI.thumbnailUrl);
    } else if (recordF && await checkImagePromise(recordF.thumbnailUrl)) {
      setImage(recordF.thumbnailUrl);
    } else if (recordT && await checkImagePromise(recordT.thumbnailUrl)) {
      setImage(recordT.thumbnailUrl);
    } else if (recordTwitch && await checkImagePromise(recordTwitch.thumbnailUrl)) {
      setImage(recordTwitch.thumbnailUrl);
    }
  }, [record?._id]);
  return (
    <div
      className="d-flex"
      style={{ minWidth: '350px' }}
    >
      <img
        src={image}
        alt={record?.title}
        style={{ width: '50px', height: 'auto' }}
        className="align-self-center"
      />
      <div
        className="align-self-center flex-column"
        style={{ marginLeft: '8px' }}
      // ref={BrandApprovalsToolTipChildRef}
      >
        <div className="firstAndLastName">
          <HighLight search={search || ''}>{name}</HighLight>
        </div>
        {emailAddress && (
          <div
            style={{ maxWidth: '245px' }}
          >
            <div
              style={{
                marginLeft: '8px',
                float: 'right',
              }}
            >
            </div>
            <div
              style={{
                maxWidth: '227px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <HighLight
                search={search || ''}
              >
                {emailAddress || ''}
              </HighLight>
            </div>
          </div>
        )}
        <div className="" >
          {contact?.mediaChannels && contact?.mediaChannels.youtube_channel_url && (
            <a
              href={contact?.mediaChannels.youtube_channel_url}
              target="_blank"
              rel="noreferrer"
              key="youtube"
              style={{ marginRight: '4px' }}
            >
              <img
                src="/images/mediacontent/youtube.png"
                alt="youtube"
                title={contact?.mediaChannels.youtube_channel_name || 'YouTube Channel'}
                style={{ width: '20px' }}
              />
            </a>
          )}
          {contact?.mediaChannels && contact?.mediaChannels.instagram_channel && (
            <a
              href={contact?.mediaChannels.instagram_channel}
              target="_blank"
              rel="noreferrer"
              key="instagram"
              style={{ marginRight: '4px' }}
            >
              <img
                src="/images/mediacontent/instagram.png"
                alt="instagram"
                title={contact?.mediaChannels.instagram_channel_name || 'Instagram Profile'}
                style={{ width: '20px' }}
              />
            </a>
          )}
          {contact?.mediaChannels && contact?.mediaChannels.facebook_channel_url && (
            <a
              href={contact?.mediaChannels.facebook_channel_url}
              target="_blank"
              rel="noreferrer"
              key="facebook"
              style={{ marginRight: '4px' }}
            >
              <img
                src="/images/mediacontent/facebook.png"
                alt="facebook"
                title="Facebook Profile"
                style={{ width: '20px' }}
              />
            </a>
          )}
          {contact?.mediaChannels && contact?.mediaChannels.twitterhandle && (
            <a
              href={contact?.mediaChannels.twitterhandle}
              target="_blank"
              rel="noreferrer"
              key="twitter"
              style={{ marginRight: '4px' }}
            >
              <img
                src="/images/mediacontent/twitter.png"
                alt="X"
                title="X Profile"
                style={{ width: '20px' }}
              />
            </a>
          )}
          {contact?.mediaChannels && contact?.mediaChannels.tiktok_channel_url && (
            <a
              href={contact?.mediaChannels.tiktok_channel_url}
              target="_blank"
              rel="noreferrer"
              key="tiktok"
              style={{ marginRight: '4px' }}
            >
              <img
                src="/images/mediacontent/tiktok.png"
                alt="X"
                title={contact?.mediaChannels.tiktok_channel_name || 'TikTok Channel'}
                style={{ width: '20px' }}
              />
            </a>
          )}
          {contact?.mediaChannels && contact?.mediaChannels.twitch_account && (
            <a
              href={contact?.mediaChannels.twitch_account}
              target="_blank"
              rel="noreferrer"
              key="twitch"
              style={{ marginRight: '4px' }}
            >
              <img
                src="/images/mediacontent/twitch.png"
                alt="twitch"
                title="Twitch Account"
                style={{ width: '20px' }}
              />
            </a>
          )}
          {contact?.mediaChannels && contact?.mediaChannels.apple_podcast_url && (
            <a
              href={contact?.mediaChannels.apple_podcast_url}
              target="_blank"
              key="podcast"
              rel="noreferrer"
              style={{ marginRight: '4px' }}
            >
              <img
                src="/images/mediacontent/podcast_128.png"
                alt="podcast"
                title={contact?.mediaChannels.podcast_title || 'Apple Podcast'}
                style={{ width: '20px' }}
              />
            </a>
          )}
          <Tooltip
            title={`Score is ${formatIntNumber(Math.ceil(Number(record?.youtubeStats?.influencer_score || 0)))}. V30 is ${formatIntNumber(record?.youtubeStats?.monthly_v30 || 0)}.`}
          >
            <span>{`(${formatIntNumber(Math.ceil(Number(record?.youtubeStats?.influencer_score || 0)))} : ${formatIntNumber(record?.youtubeStats?.monthly_v30 || 0)})`}</span>
          </Tooltip>
        </div>
        <div>
          <div style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '2px' }} className="block-in-cell ml-1">
            <CopyToClipboard value={emailAddress} icon={(<CopyIcon />)} className="text-blue" />
          </div>
          {record?.hsUserId && true && (
            <div style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '2px' }}>
              <a
                title="Show HubSpot Contact"
                target="_blank"
                rel="noopener noreferrer"
                className="ml-0"
                style={{ fontSize: '16px' }}
                href={`https://app.hubspot.com/contacts/${window.constants.hubspot.portalId}/contact/${record?.hsUserId}`}
              >
                <i className="fab fa-hubspot" />
              </a>
            </div>
          )}
          {creator && (
            <Tooltip title={`Open ${name} Profile`}>
              <div className="ml-2" style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '2px' }}>
                <a
                  title="Creator Profile"
                  target="_blank"
                  rel="noreferrer"
                  href={`/influencer/profile/${creator._id}`}
                  style={{
                    fontSize: '18px',
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginTop: '2px',
                  }}
                >
                  <AddressIcon />
                </a>
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      {/* <BrandApprovalsToolTip
        forceRender={forceRender}
        setForceRender={setForceRender}
        theRef={BrandApprovalsToolTipChildRef}
        record={record}
      /> */}
    </div>
  );
}, (a, a1) => _.isEqual(a.record, a1.record));

CreatorInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
};


export default CreatorInList;
