/* eslint react/prop-types: 0 */
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Container, Row, Col, Button, Label, FormGroup, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledButtonDropdown,
  CardBody, CardTitle, Card, Input, InputGroup, InputGroupAddon, InputGroupText,
} from 'reactstrap';
import moment from 'moment';
import momenttz from 'moment-timezone';
import _ from 'lodash';
import { axios } from 'ApiClient';
import PropTypes from 'prop-types';
import Select from 'react-select';
import SelectAsync from 'react-select/async';
import { useHistory } from 'react-router-dom';
import { useAsyncDebounce } from 'react-table';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from 'mdi-react/TuneIcon';
import MatTableList from '../../../../../shared/tables/materialTable/MatTableList';
import Breadcrumbs from '../../../../../shared/components/BreadCrumbs';
import UserPropertyType from './custom';
import withNotice from '../../../../App/store/with-notice';
import TopButtonsPeriod from '../../../Reports/TimePeriodFilterExtended';
import { getCookie, setCookie } from '../../../../../shared/helpers/WVUtilities';
import CheckBoxField from '../../../../../shared/components/CheckBox';
import UrlStatusHistoryModal from './custom/modals/UrlStatusHistoryModal';
import UpdateUrlStatusModal from './custom/modals/BulkUpdateMediaContentURLStatusModal';
import InitialFilters from './custom/InitialFilters';
import mapMediaTypeIcons from './custom/mapMediaTypeIcons';
import TitleInList from './custom/TitleInList';
import UrlStatusInList from './custom/UrlStatusInList';
import MediaUrlInList from './custom/MediaUrlInList';
import ContextMenuInList from './custom/ContextMenuInList';
import RefreshYoutubeDialog from '../../MediaContent/components/custom/modals/RefreshYoutubeDialog';
import RefreshCommentsDialog from '../components/custom/modals/RefreshCommentsDialog';

const compare = (a, b) => {
  const keyA = a.label;
  const keyB = b.label;
  if (keyA < keyB) return -1;
  if (keyA > keyB) return 1;
  return 0;
};

const mapType = mapMediaTypeIcons();

const typeFilterOptions = [
  { label: 'YouTube', value: 'YouTube' },
  { label: 'YouTube Description Text', value: 'YouTube Description Text' },
  { label: 'Podcast', value: 'Podcast' },
  { label: 'Podcast Script Text', value: 'Podcast Script Text' },
  { label: 'Instagram Story', value: 'Instagram Story' },
  { label: 'Instagram Post', value: 'Instagram Post' },
  { label: 'Instagram TV Video', value: 'Instagram TV Video' },
  { label: 'TikTok', value: 'TikTok' },
  { label: 'X Post', value: 'Twitter Post' },
  { label: 'Facebook Post', value: 'Facebook Post' },
  { label: 'Facebook Watch Video', value: 'Facebook Watch Video' },
  { label: 'LinkTree', value: 'LinkTree' },
  { label: 'Website', value: 'Website' },
  { label: 'Email', value: 'Email' },
  { label: 'Text', value: 'Text' },
];

const loadCreatorsOptions = async (value) => {
  let response = {};
  if (value === 'getByIds') {
    const initialSearch = new URLSearchParams(window.location.search);
    if (initialSearch.has('filters.creator') && initialSearch.getAll('filters.creator').length > 0) {
      response = await axios.get('/api/portal/influencer/get-creators-by-ids', {
        params: { ids: initialSearch.getAll('filters.creator') },
      });
      if (!response.data.success) {
        return {};
      }
    }
  } else {
    response = await axios.get('/api/portal/influencer/get-creator-search', {
      params: {
        value,
        defaultSearch: 'a',
      },
    });
  }
  return response.data.records.map(record => ({
    value: record.params?._id || record._id,
    label: record.params ? `${record.params.first_name} ${record.params.last_name}` : `${record.first_name} ${record.last_name}`,
    record,
  }));
};

const ContentUrlValidationListContainer = ({
  resource,
  action,
  addNotice,
}) => {
  const [version, setVersion] = useState((new Date()).toString());
  const [loadingFilters, setLoadingFilters] = useState(true);
  const selectedBrandsInitialState = [];
  const selectedCreatorsInitialState = [];
  const selectedTypesInitialState = [];
  let selectedInitialRange = 'year_to_date';
  let selectedStartDateInitialState = momenttz(moment().startOf('Year')).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss');
  let selectedEndDateInitialState = momenttz(moment().endOf('day')).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss');
  const [allTimeStartDate, setAllTimeStartDate] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);

  const {
    urlStatusFilters,
    privacyStatus,
    publishDateAssignedFilters,
    hasPaidProductPlacementFilters,
  } = InitialFilters;
  const initialSearch = new URLSearchParams(window.location.search);
  if (initialSearch.has('filters.type') && initialSearch.getAll('filters.type').length > 0) {
    initialSearch.getAll('filters.type').forEach((type) => {
      selectedTypesInitialState.push({ value: type, label: type });
    });
  }
  if (initialSearch.has('filters.brand') && initialSearch.getAll('filters.brand').length > 0) {
    initialSearch.getAll('filters.brand').forEach((brand) => {
      selectedBrandsInitialState.push({ value: brand, label: brand });
    });
  }
  if (initialSearch.has('filters.urlStatus') && initialSearch.getAll('filters.urlStatus').length > 0) {
    Object.keys(urlStatusFilters).map((key) => {
      urlStatusFilters[key] = false;
      return true;
    });
    initialSearch.getAll('filters.urlStatus').forEach((type) => {
      urlStatusFilters[type] = true;
    });
  }
  if (initialSearch.has('filters.privacyStatus') && initialSearch.getAll('filters.privacyStatus').length > 0) {
    Object.keys(privacyStatus).map((key) => {
      privacyStatus[key] = false;
      return true;
    });
    initialSearch.getAll('filters.privacyStatus').forEach((type) => {
      privacyStatus[type] = true;
    });
  }
  if (initialSearch.has('filters.publishDateAssigned') && initialSearch.getAll('filters.publishDateAssigned').length > 0) {
    Object.keys(publishDateAssignedFilters).map((key) => {
      publishDateAssignedFilters[key] = false;
      return true;
    });
    initialSearch.getAll('filters.publishDateAssigned').forEach((type) => {
      publishDateAssignedFilters[type] = true;
    });
  }
  if (initialSearch.has('filters.hasPaidProductPlacement') && initialSearch.getAll('filters.hasPaidProductPlacement').length > 0) {
    Object.keys(hasPaidProductPlacementFilters).map((key) => {
      hasPaidProductPlacementFilters[key] = false;
      return true;
    });
    initialSearch.getAll('filters.hasPaidProductPlacement').forEach((type) => {
      hasPaidProductPlacementFilters[type] = true;
    });
  }
  if (initialSearch.has('range') && initialSearch.getAll('range').length > 0) {
    initialSearch.getAll('range').forEach((range) => {
      selectedInitialRange = range;
    });
  }
  if (initialSearch.has('filters.brandApprovedDate.from') && initialSearch.getAll('filters.brandApprovedDate.from').length > 0) {
    initialSearch.getAll('filters.brandApprovedDate.from').forEach((from) => {
      selectedStartDateInitialState = momenttz(moment(from, 'YYYY-MM-DD HH:mm:ss.SSSZ').startOf('day')).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss');
    });
  }
  if (initialSearch.has('filters.brandApprovedDate.to') && initialSearch.getAll('filters.brandApprovedDate.to').length > 0) {
    initialSearch.getAll('filters.brandApprovedDate.to').forEach((to) => {
      selectedEndDateInitialState = momenttz(moment(to, 'YYYY-MM-DD HH:mm:ss.SSSZ').endOf('day')).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss');
    });
  }


  const [filtersUrlStatus, setFiltersUrlStatus] = useState(urlStatusFilters);
  const [filtersPrivacyStatus, setFiltersPrivacyStatus] = useState(privacyStatus);
  const [filtersPublishDateAssigned, setFiltersPublishDateAssigned] = useState(publishDateAssignedFilters);
  const [filtersPaidProductPlacement, setFiltersPaidProductPlacement] = useState(hasPaidProductPlacementFilters);
  const [brandOptions, setBrandOptions] = useState(selectedBrandsInitialState);
  const [selectedBrands, setSelectedBrands] = useState(selectedBrandsInitialState);
  const [selectedCreators, setSelectedCreators] = useState(selectedCreatorsInitialState);
  const [selectedTypes, setSelectedTypes] = useState(selectedTypesInitialState);
  const [startDate, setStartDate] = useState(selectedStartDateInitialState);
  const [endDate, setEndDate] = useState(selectedEndDateInitialState);
  const [selectedButton, setSelectedButton] = useState(selectedInitialRange);
  const [searchValue, setSearchValue] = useState('');
  const [resetDealFilter, setResetDealFilter] = useState(false);
  const [showFilters, setShowFilters] = useState(getCookie('contentUrlValidationShowFilters') === 'true');
  const [filterOptionsLoaded, setFilterOptionsLoaded] = useState(false);
  const [showUpdateUrlStatusModal, setShowUpdateUrlStatusModal] = useState(false);
  const [metaTotal, setMetaTotal] = useState(0);

  const handleSelectCheckbox = (ids) => {
    setSelectedIds(ids || []);
  };

  useEffect(() => {
    setCookie('contentUrlValidationShowFilters', showFilters.toString(), 35);
  }, [showFilters]);

  const history = useHistory();

  const setFilterOptionsLoadedTrueDebounced = useAsyncDebounce(() => {
    setFilterOptionsLoaded(true);
  }, 500);

  const fetchFilterData = async () => {
    setLoadingFilters(true);
    try {
      const search = new URLSearchParams(window.location.search);

      const resp = await axios.get('/api/portal/scorecard/media-content/get-filters-data-set');
      if (resp?.data?.success) {
        setBrandOptions(resp?.data?.brandOptions?.sort(compare) || []);

        const foundTypeOptions = [];
        const foundBrandnOptions = [];

        if (search.has('filters.type') && search.getAll('filters.type').length > 0) {
          search.getAll('filters.type').forEach((type) => {
            const foundOption = typeFilterOptions.find(option => option.value === type);
            if (foundOption) foundTypeOptions.push(foundOption);
          });
        }
        if (foundTypeOptions.length > 0) setSelectedTypes(foundTypeOptions);

        if (search.has('filters.brand') && search.getAll('filters.brand').length > 0) {
          search.getAll('filters.brand').forEach((brand) => {
            const foundOption = resp?.data?.brandOptions?.find(option => option.value === brand);
            if (foundOption) foundBrandnOptions.push(foundOption);
          });
        }
        if (foundBrandnOptions.length > 0) setSelectedBrands(foundBrandnOptions);

        setLoadingFilters(false);
        setFilterOptionsLoadedTrueDebounced();
      } else {
        throw new Error(resp?.data?.error);
      }
    } catch (e) {
      console.error(e);
      addNotice({
        message: e.message,
        type: 'error',
      });
    }
  };

  const selectItem = (value, type) => {
    switch (type) {
      case 'creator':
        setSelectedCreators(value);
        break;
      case 'type':
        setSelectedTypes(value);
        break;
      case 'brand':
        setSelectedBrands(value);
        break;
      default:
        return false;
    }
    return true;
  };

  const firstUpdate = useRef(true);

  const setUrl = (removeBeforeSet = false) => {
    let search = new URLSearchParams(window.location.search);
    const oldUrl = `${history.location.pathname}?${search.toString()}`;

    if (removeBeforeSet) {
      search = new URLSearchParams();
    }
    if (resetDealFilter) {
      search.delete('filters.deal');
    }

    search.delete('filters.contentUrlValidationSearchValue');
    if (searchValue) search.append('filters.contentUrlValidationSearchValue', searchValue);

    search.delete('filters.brandApprovedDate.from');
    search.delete('filters.brandApprovedDate.to');
    search.delete('range');
    if (startDate !== '') {
      // timezone watch activated per https://influencelogic.atlassian.net/browse/IL-4804
      const startDateLocalTimezone = momenttz(startDate).tz(moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      search.append('filters.brandApprovedDate.from', startDateLocalTimezone);
    }
    if (endDate !== '') {
      // timezone watch activated per https://influencelogic.atlassian.net/browse/IL-4804
      const endDateLocalTimezone = momenttz(endDate).tz(moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      search.append('filters.brandApprovedDate.to', endDateLocalTimezone);
    }
    search.append('range', selectedButton);

    const filterCreators = search.getAll('filters.creator');
    let deleteFilterCreators = false;

    if (!selectedCreators || selectedCreators.length === 0) {
      search.delete('filters.creator');
    }
    if (selectedCreators) {
      filterCreators.forEach((creator) => {
        if (selectedCreators.filter(item => item.value === creator).length === 0) {
          deleteFilterCreators = true;
        }
      });
      if (deleteFilterCreators) {
        search.delete('filters.creator');
      }
      selectedCreators.forEach((creator) => {
        if (creator.value !== '' && (!filterCreators.includes(creator.value) || deleteFilterCreators)) {
          search.append('filters.creator', creator.value);
        }
      });
    }

    const searchCreatorsEmails = (search.getAll('filters.creator') || []).map(email => decodeURI(email)).sort();
    const filterCreatorsEmails = (selectedCreators || []).map(option => decodeURI(option.value)).sort();
    const isEqual = _.isEqual(searchCreatorsEmails, filterCreatorsEmails);
    if (!isEqual) {
      if (!selectedCreators || selectedCreators.length === 0) {
        search.delete('filters.creator');
      }
      if (selectedCreators) {
        filterCreators.forEach((creator) => {
          if (selectedCreators.filter(item => item.value === creator).length === 0) {
            deleteFilterCreators = true;
          }
        });
        if (deleteFilterCreators) {
          search.delete('filters.creator');
        }
        selectedCreators.forEach((selectedCreator) => {
          if (selectedCreator.value !== '' && (!filterCreators.includes(selectedCreator.value) || deleteFilterCreators)) {
            search.append('filters.creator', selectedCreator.value);
          }
        });
      }
    }

    const filterBrands = search.getAll('filters.brand');
    let deleteFilterBrands = false;

    if (!selectedBrands || selectedBrands.length === 0) {
      search.delete('filters.brand');
    }
    if (selectedBrands) {
      filterBrands.forEach((brand) => {
        if (selectedBrands.filter(item => item.value === brand).length === 0) {
          deleteFilterBrands = true;
        }
      });
      if (deleteFilterBrands) {
        search.delete('filters.brand');
      }
      selectedBrands.forEach((brand) => {
        if (brand.value !== '' && (!filterBrands.includes(brand.value) || deleteFilterBrands)) {
          search.append('filters.brand', brand.value);
        }
      });
    }

    const filtersTypes = search.getAll('filters.type');
    let deleteFilterTypes = false;

    if (!selectedTypes || selectedTypes.length === 0) {
      search.delete('filters.type');
    }
    if (selectedTypes) {
      filtersTypes.forEach((type) => {
        if (selectedTypes.filter(item => item.value === type).length === 0) {
          deleteFilterTypes = true;
        }
      });
      if (deleteFilterTypes) {
        search.delete('filters.submitter');
      }
      selectedTypes.forEach((selectedType) => {
        if (selectedType.value !== '' && (!filtersTypes.includes(selectedType.value) || deleteFilterTypes)) {
          search.append('filters.type', selectedType.value);
        }
      });
    }

    if (filterOptionsLoaded) {
      search.delete('filters.urlStatus');
      let allSwitchedOffStatus = true;
      if (filtersUrlStatus) {
        Object.keys(filtersUrlStatus).forEach((key) => {
          if (filtersUrlStatus[key]) {
            allSwitchedOffStatus = false;
            search.append('filters.urlStatus', key);
          }
        });
      }
      if (allSwitchedOffStatus) {
        search.append('filters.urlStatus', 'Not Exist');
      }
      search.delete('filters.privacyStatus');
      if (filtersPrivacyStatus) {
        Object.keys(filtersPrivacyStatus).forEach((key) => {
          if (filtersPrivacyStatus[key]) {
            allSwitchedOffStatus = false;
            search.append('filters.privacyStatus', key);
          }
        });
      }
      search.delete('filters.publishDateAssigned');
      let allSwitchedOffPublishDate = true;
      if (filtersPublishDateAssigned) {
        Object.keys(filtersPublishDateAssigned).forEach((key) => {
          if (filtersPublishDateAssigned[key]) {
            allSwitchedOffPublishDate = false;
            search.append('filters.publishDateAssigned', key);
          }
        });
      }
      if (allSwitchedOffPublishDate) {
        search.append('filters.publishDateAssigned', 'Not Exist');
      }
      search.delete('filters.hasPaidProductPlacement');
      if (filtersPaidProductPlacement) {
        Object.keys(filtersPaidProductPlacement).forEach((key) => {
          if (filtersPaidProductPlacement[key]) {
            search.append('filters.hasPaidProductPlacement', key);
          }
        });
      }
    }

    let newUrl = `${history.location.pathname}?${search.toString()}`;
    if (oldUrl !== newUrl) {
      search.set('page', '1');
      newUrl = `${history.location.pathname}?${search.toString()}`;
      history.push(newUrl);
    }
  };

  const setUrlDebounced = useAsyncDebounce(() => {
    setUrl();
  }, 800);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      setUrlDebounced();
    }
  }, [
    searchValue,
    startDate,
    endDate,
    selectedCreators,
    selectedBrands,
    selectedTypes,
    filtersUrlStatus,
    filtersPrivacyStatus,
    filtersPublishDateAssigned,
    filtersPaidProductPlacement,
    resetDealFilter,
  ]);

  useEffect(() => {
    fetchFilterData();
    if (initialSearch.has('filters.creator') && initialSearch.getAll('filters.creator').length > 0) {
      loadCreatorsOptions('getByIds').then((response) => {
        setSelectedCreators(response);
      });
    }
  }, []);

  useEffect(async () => {
    const resp = await axios.get('/api/portal/scorecard/media-content/get-first-media-creation-date');
    if (resp?.data?.success) {
      setAllTimeStartDate(resp?.data.allTimeStartDate);
    }
  }, []);

  const customColumns = useMemo(() => [
    {
      Header: 'Video Details',
      accessor: p => (p.params?.title),
      id: 'title',
      Cell: record => (
        <div style={{ paddingRight: '10px' }}>
          <TitleInList
            record={record.row.original}
          />
        </div>
      ),
      disableSortBy: false,
      className: 'align-middle',
      width: 150,
    },
    {
      Header: 'Creator',
      accessor: p => (p.params?.creatorFullName),
      id: 'creatorFullName',
      Cell: record => (
        <div style={{ paddingRight: '10px' }}>
          <a
            target="_blank"
            rel="noreferrer"
            href={`/influencer/profile/${record.row.original.params.creatorId}`}
          >
            {record.row.original.params.creatorFullName}
          </a>
          {record.row.original.params['influencer.mediaChannels.youtube_channel_url'] && (
            <a
              target="_blank"
              rel="noreferrer"
              href={record.row.original.params['influencer.mediaChannels.youtube_channel_url']}
            >
              <img
                className="ml-2"
                src="/images/mediacontent/youtube.png"
                alt="YouTube"
                title="YouTube Channel"
                style={{ width: '20px' }}
              />
            </a>
          )}
        </div>
      ),
      disableSortBy: false,
      className: 'align-middle',
      width: 150,
    },
    {
      Header: 'Brand',
      accessor: p => (p.params?.brand),
      id: 'brand',
      width: 120,
      Cell: p => (p.row.original.params?.brand || ''),
      disableSortBy: false,
      className: 'align-middle',
    },
    {
      Header: 'URL Status',
      accessor: p => (p.params?.urlStatus),
      id: 'status',
      Cell: p => useMemo(() => (
        <UrlStatusInList
          record={p.row.original}
          setVersion={setVersion}
          addNotice={addNotice}
        />), [p.row.original]),
      className: 'align-middle',
      disableSortBy: false,
      width: 180,
    },
    {
      Header: 'Type',
      accessor: p => (p.params?.type),
      id: 'typeInList',
      width: 60,
      Cell: value => (
        <div>
          <a
            href={(value.row.original.params?.mediaUrl?.includes('https://') || value.row.original.params?.mediaUrl?.includes('http://')) ? value.row.original.params.mediaUrl : `https://${value.row.original.params?.mediaUrl}`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              style={{ width: '30px' }}
              src={`/images/mediacontent/${mapType[value.row.original?.params?.type]}.png`}
              alt={value.row.original.params.type}
              title={value.row.original.params.type}
            />
          </a>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: true,
    },
    {
      Header: 'Content URL',
      accessor: p => (p.params?.mediaUrl),
      id: 'mediaUrl',
      Cell: p => useMemo(() => (
        <div style={{ width: '250px' }}>
          <MediaUrlInList
            setVersion={setVersion}
            record={p.row.original}
            addNotice={addNotice}
          />
        </div>), [p.row.original]),
      width: 260,
      disableSortBy: false,
    },
    {
      Header: 'Video Privacy',
      accessor: () => null,
      id: 'privacyStatus',
      Cell: (record) => {
        const status = record.row.original.params.privacyStatus;
        let value = 'Not Assigned';
        switch (status) {
          case 'public':
            value = 'Public';
            break;
          case 'private':
            value = 'Private';
            break;
          case 'unlisted':
            value = 'Unlisted';
            break;
          default:
            break;
        }
        return (
          <div style={{ paddingRight: '10px' }}>
            {value}
          </div>);
      },
      className: 'align-middle',
      disableSortBy: false,
      width: 140,
    },
    {
      Header: 'Includes Paid Promotion',
      accessor: p => (p.params?.hasPaidProductPlacement),
      id: 'hasPaidProductPlacement',
      width: 100,
      Cell: p => {
        if (p.row.original.params?.hasPaidProductPlacement === true) {
          return 'Yes';
        } else if (p.row.original.params?.hasPaidProductPlacement === false) {
          return 'No';
        } else return 'Not Assigned';
      },
      disableSortBy: false,
      className: 'align-middle',
    },
    {
      Header: 'Publish Date',
      accessor: item => (item?.params?.publishDate ? item?.params?.publishDate.split('T')[0] : null), // p => (WVFormatter.getServerDateTime(p.params?.createdAt)),
      id: 'publishDate',
      Cell: value => (
        <div style={{ paddingRight: '10px' }}>
          <span>{value.value ? moment(value.value).format('MM/DD/YYYY') : '-'}</span>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: false,
      width: 160,
    },
    {
      Header: 'Brand Approved Date',
      accessor: item => item?.params?.brandApprovedDate,
      id: 'brandApprovedDate',
      Cell: value => (
        <div style={{ paddingRight: '10px' }}>
          <span>{value.value ? moment(value.value).format('MM/DD/YYYY') : '-'}</span>
        </div>
      ),
      className: 'align-middle',
      disableSortBy: false,
      width: 180,
    },
    {
      Header: '',
      accessor: () => null,
      id: 'actionsCustom',
      Cell: p => (
        <ContextMenuInList
          record={p.row.original}
        />
      ),
      disableSortBy: true,
      className: 'align-middle',
      width: 50,
    },
  ], []);

  const handleChangeUrlStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFiltersUrlStatus({
        ...filtersUrlStatus,
        [name]: checked,
      });
    }
  };
  const handleChangePrivacyStatusFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFiltersPrivacyStatus({
        ...filtersPrivacyStatus,
        [name]: checked,
      });
    }
  };

  const handleChangePublishDateAssignedFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFiltersPublishDateAssigned({
        ...filtersPublishDateAssigned,
        [name]: checked,
      });
    }
  };

  const handleChangePaidProductPlacementFilters = (event, name) => {
    if (event && event.target) {
      const { checked } = event.target;
      setFiltersPaidProductPlacement({
        ...filtersPaidProductPlacement,
        [name]: checked,
      });
    }
  };

  const handleDownloadCSV = () => {
    window.open(`/resources/content-url-validation/download-csv?${new URLSearchParams(window.location.search).toString()}`, '_blank').focus();
  };

  const resetFilters = () => {
    setSearchValue('');
    setSelectedBrands([]);
    setSelectedCreators([]);
    setSelectedTypes([]);
    setFiltersUrlStatus({
      'Needs Review': true,
      'Pending Publication': false,
      'Needs Content URL': false,
      Approved: false,
      Inactive: false,
    });
    setFiltersPrivacyStatus({
      na: true,
      unlisted: true,
      public: true,
      private: true,
    });
    setFiltersPublishDateAssigned({
      Assigned: true,
      'Not Assigned': true,
    });
    setFiltersPaidProductPlacement({
      Yes: true,
      No: true,
      'Not Assigned': true,
    });
    setResetDealFilter(true);
  };

  const links = [
    { title: 'Home', path: '/' },
    { title: 'Compliance', path: false },
    { title: 'Content URL Validation', path: false },
  ];

  const colourStyles = {
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#70bbfd',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'white',
      ':hover': {
        backgroundColor: '#a2e1fd',
        color: '#70bbfd',
      },
    }),
  };
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <Breadcrumbs links={links} isBackButton />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody style={{ padding: '2rem 2rem 0 2rem' }}>
              <CardTitle>
                <h3>Content URL Validation</h3>
                <hr className="mt-3 mb-4" />
              </CardTitle>
              <Row>
                <Col md={10}>
                  { allTimeStartDate && (
                    <>
                      <Label className="bold-text">
                        Brand Approved Date
                      </Label>
                      <TopButtonsPeriod
                        selectedButtonInitial={selectedButton}
                        startDateInitial={selectedStartDateInitialState}
                        endDateInitial={selectedEndDateInitialState}
                        allTimeStartDate={allTimeStartDate}
                        setValue={(param, value, range) => {
                          if (param === 'startDate') {
                            setStartDate(moment(value).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
                          }
                          if (param === 'endDate') {
                            setEndDate(moment(value).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
                          }
                          setSelectedButton(range);
                        }}
                      />
                    </>
                  )}
                </Col>
                <Col md={2} className="text-right" style={{ paddingTop: '31px' }}>
                  <Button className="btn btn-primary btn-sm" onClick={() => setUrl(true)} style={{ color: 'white' }} >Refresh</Button>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={8}>
                  <InputGroup>
                    <Input
                      className="form-control-sm form-control"
                      name="search"
                      type="search"
                      autoComplete="off"
                      placeholder="Search Video Title, Creator Name or Content URL"
                      value={searchValue}
                      onChange={p => setSearchValue(p.target.value)}
                      style={{ height: '38px' }}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <i
                          className="fa fa-search"
                          aria-hidden="true"
                          style={{ color: '#70bbfd', fontSize: 12 }}
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col sm={2}>
                  <Row>
                    <Col sm={8} style={{ paddingRight: '0' }}>
                      <Tooltip title="Reset Filters">
                        <Button
                          color="secondary"
                          size="sm"
                          onClick={resetFilters}
                          style={{
                            width: '100%',
                            padding: '4px 8px',
                            height: '38px',
                          }}
                        >
                          <i className="fa fa-sync" aria-hidden="true" />&nbsp;
                          <span className="btn-text">Reset</span>
                        </Button>
                      </Tooltip>
                    </Col>
                    <Col sm={1} style={{ paddingLeft: '24px' }}>
                      <Tooltip title="Toggle Filters">
                        <IconButton
                          id="filtersMenuButton"
                          size="small"
                          onClick={() => setShowFilters(!showFilters)}
                          className="material-table__toolbar-button"
                          style={{
                            height: '38px',
                          }}
                        >
                          <TuneIcon size="30" />
                        </IconButton>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
                <Col md={2} className="text-right">
                  <UncontrolledButtonDropdown className="w-100">
                    <DropdownToggle
                      id="actionsMenu"
                      caret
                      color="primary"
                      style={{
                        padding: '4px 25px',
                        height: '38px',
                      }}
                    >
                      Actions
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        disabled={selectedIds.length < 1}
                        onClick={() => setShowUpdateUrlStatusModal(true)}
                      >
                        Bulk Update URL Status
                      </DropdownItem>
                      <DropdownItem
                        onClick={handleDownloadCSV}
                      >
                        Download CSV
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </Col>
              </Row>
              { showFilters && (
                <>
                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <Label className="bold-text">
                          Brand(s)
                        </Label>
                        <Select
                          isMulti
                          name="brandSelect"
                          isDisabled={loadingFilters}
                          isLoading={loadingFilters}
                          isClearable
                          value={!loadingFilters ? selectedBrands : []}
                          options={brandOptions}
                          onChange={value => selectItem(value, 'brand')}
                          placeholder="Select Brand..."
                          style={{ maxHeight: '120px' }}
                          styles={colourStyles}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="bold-text">
                          Creator(s)
                        </Label>
                        <SelectAsync
                          cacheOptions
                          defaultOptions
                          isClearable
                          isMulti
                          loadOptions={async value => loadCreatorsOptions(value)}
                          onChange={value => selectItem(value, 'creator')}
                          placeholder="Select Creator..."
                          value={selectedCreators}
                          styles={colourStyles}
                        />
                        {/* <Select
                          isMulti
                          name="creatorSelect"
                          isDisabled={loadingFilters}
                          isLoading={loadingFilters}
                          isClearable
                          value={!loadingFilters ? selectedCreators : []}
                          options={creatorOptions}
                          onChange={value => selectItem(value, 'creator')}
                          placeholder="Select Creator..."
                          style={{ maxHeight: '120px' }}
                          styles={colourStyles}
                        /> */}
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <Row
                        style={{ marginRight: '-40px' }}
                      >
                        <Col md={4}>
                          <Label className="bold-text" style={{ marginBottom: '8px' }}>
                            URL Status
                          </Label>
                          <FormGroup
                            style={{ marginBottom: '10px' }}
                          >
                            <CheckBoxField
                              name="reviewUrlStatus"
                              label="Needs Review"
                              value={filtersUrlStatus['Needs Review']}
                              onChange={event => handleChangeUrlStatusFilters(event, 'Needs Review')}
                            />
                          </FormGroup>
                          <FormGroup
                            style={{ marginBottom: '10px' }}
                          >
                            <CheckBoxField
                              name="pendingPublicationUrlStatus"
                              label="Pending Publication"
                              value={filtersUrlStatus['Pending Publication']}
                              onChange={event => handleChangeUrlStatusFilters(event, 'Pending Publication')}
                            />
                          </FormGroup>
                          <FormGroup
                            style={{ marginBottom: '10px' }}
                          >
                            <CheckBoxField
                              name="noUrlUrlStatus"
                              label="Needs Content Url"
                              value={filtersUrlStatus['Needs Content URL']}
                              onChange={event => handleChangeUrlStatusFilters(event, 'Needs Content URL')}
                            />
                          </FormGroup>
                          <FormGroup style={{ marginBottom: '10px' }} >
                            <CheckBoxField
                              name="ApprovedUrlStatus"
                              label="Approved"
                              value={filtersUrlStatus.Approved}
                              onChange={event => handleChangeUrlStatusFilters(event, 'Approved')}
                            />
                          </FormGroup>
                          <FormGroup
                            style={{ marginBottom: '0px' }}
                          >
                            <CheckBoxField
                              name="inactive"
                              label="Inactive"
                              value={filtersUrlStatus.Inactive}
                              onChange={event => handleChangeUrlStatusFilters(event, 'Inactive')}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <Label className="bold-text" style={{ marginBottom: '8px' }}>
                            Video Privacy Status
                          </Label>
                          <FormGroup style={{ marginBottom: '10px' }} >
                            <CheckBoxField
                              name="public"
                              label="Public"
                              value={filtersPrivacyStatus.public}
                              onChange={event => handleChangePrivacyStatusFilters(event, 'public')}
                            />
                          </FormGroup>
                          <FormGroup style={{ marginBottom: '10px' }} >
                            <CheckBoxField
                              name="unlisted"
                              label="Unlisted"
                              value={filtersPrivacyStatus.unlisted}
                              onChange={event => handleChangePrivacyStatusFilters(event, 'unlisted')}
                            />
                          </FormGroup>
                          <FormGroup style={{ marginBottom: '10px' }} >
                            <CheckBoxField
                              name="private"
                              label="Private/Invalid"
                              value={filtersPrivacyStatus.private}
                              onChange={event => handleChangePrivacyStatusFilters(event, 'private')}
                            />
                          </FormGroup>
                          <FormGroup style={{ marginBottom: '0' }} >
                            <CheckBoxField
                              name="na"
                              label="Not Assigned"
                              value={filtersPrivacyStatus.na}
                              onChange={event => handleChangePrivacyStatusFilters(event, 'na')}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <Label className="bold-text" style={{ marginBottom: '8px' }}>
                            Publish Date
                          </Label>
                          <FormGroup style={{ marginBottom: '10px' }} >
                            <CheckBoxField
                              name="AssignedPublishDate"
                              label="Assigned"
                              value={filtersPublishDateAssigned.Assigned}
                              onChange={event => handleChangePublishDateAssignedFilters(event, 'Assigned')}
                            />
                          </FormGroup>
                          <FormGroup style={{ marginBottom: '10px' }} >
                            <CheckBoxField
                              name="NotAssignedPublishDate"
                              label="Not Assigned"
                              value={filtersPublishDateAssigned['Not Assigned']}
                              onChange={event => handleChangePublishDateAssignedFilters(event, 'Not Assigned')}
                            />
                          </FormGroup>
                          <Label className="bold-text" style={{ marginBottom: '8px' }}>
                            Includes Paid Promotion?
                          </Label>
                          <FormGroup style={{ marginBottom: '10px' }} >
                            <CheckBoxField
                              name="YesPaidProductPlacement"
                              label="Yes"
                              value={filtersPaidProductPlacement.Yes}
                              onChange={event => handleChangePaidProductPlacementFilters(event, 'Yes')}
                            />
                          </FormGroup>
                          <FormGroup style={{ marginBottom: '10px' }} >
                            <CheckBoxField
                              name="NoPaidProductPlacement"
                              label="No"
                              value={filtersPaidProductPlacement.No}
                              onChange={event => handleChangePaidProductPlacementFilters(event, 'No')}
                            />
                          </FormGroup>
                          <FormGroup style={{ marginBottom: '0' }} >
                            <CheckBoxField
                              name="NotAssignedPaidProductPlacement"
                              label="Not Assigned"
                              value={filtersPaidProductPlacement['Not Assigned']}
                              onChange={event => handleChangePaidProductPlacementFilters(event, 'Not Assigned')}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <Label className="bold-text">
                          Type(s)
                        </Label>
                        <Select
                          isMulti
                          name="typeSelect"
                          isDisabled={loadingFilters}
                          isLoading={loadingFilters}
                          isClearable
                          value={!loadingFilters ? selectedTypes : []}
                          options={typeFilterOptions}
                          onChange={value => selectItem(value, 'type')}
                          placeholder="Select Type..."
                          style={{ maxHeight: '120px' }}
                          styles={colourStyles}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}
              <Row className="media-content-dashboard-container">
                <MatTableList
                  useCheckboxes
                  checkboxHandler={handleSelectCheckbox}
                  setMetaTotal={setMetaTotal}
                  action={action}
                  resource={resource}
                  date={new Date()}
                  version={version}
                  UserPropertyType={UserPropertyType}
                  hideToolbar
                  sortBy="publishDate"
                  direction="desc"
                  v={2}
                  isResizable={false}
                  customColumns={customColumns}
                  showTitle={false}
                  noActions
                />
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <UrlStatusHistoryModal />
      <UpdateUrlStatusModal
        isOpen={showUpdateUrlStatusModal}
        closeFunc={() => setShowUpdateUrlStatusModal(false)}
        setVersion={setVersion}
        mediaContentIds={selectedIds}
        total={metaTotal}
      />
      <RefreshYoutubeDialog afterSaveFunc={() => setVersion((new Date()).toString())} />
      <RefreshCommentsDialog afterSaveFunc={() => setVersion((new Date()).toString())} />
    </Container>
  );
};

const mapStateToProps = state => ({
  resources: state.resources,
  addNotice: PropTypes.func.isRequired,
});

export default connect(mapStateToProps)(withNotice(ContentUrlValidationListContainer));
