import React from 'react';
import PropTypes from 'prop-types';
import TableCellContextMenu from '@/shared/components/table/TableCellContextMenu';
import Hook from '@/shared/hooks';
import ReactJson from 'react-json-view';
import { axios } from 'ApiClient';

const ContextMenuInList = ({
  record, loadMigrations
}) => {
  const [showModal] = Hook.useShowModalHook('SuccessModal');
  const [showConfirmModal] = Hook.useShowModalHook('ConfirmModalDefault');
  const { showError } = Hook.useNoticeHook();
  const createIndex = async (index) => {
    try {
      const data = await axios({
        method: 'post',
        url: '/api/portal/global/post-create-or-update-search-index',
        data: {
          indexFileName: index,
        }
      });
      console.log(data.data);
    } catch (err) {
      showError(err.message || 'Unable to get migrations');
    }
  };

  const contextMenuOptions = [
    {
      disabled: !record.localIndex,
      label: 'Show Local Index',
      handler: () => {
        showModal({
          size: 'md',
          title: 'Local Index',
          message: (<ReactJson
            src={record.localIndex}
            theme="bright:inverted"
            collapsed={2}
            name={false}
            displayDataTypes={false}
            quotesOnKeys={false}
            displayArrayKey={false}
            iconStyle="circle"
          />),
        });
      },
    },
    {
      disabled: !record.atlasSearchIndex,
      label: 'Show Atlas Search Index',
      handler: () => {
        showModal({
          size: 'md',
          title: 'Atlas Search Index',
          message: (<ReactJson
            src={record.atlasSearchIndex}
            theme="bright:inverted"
            collapsed={2}
            name={false}
            displayDataTypes={false}
            quotesOnKeys={false}
            displayArrayKey={false}
            iconStyle="circle"
          />),
        });
      },
    },
    {
        label: 'Create or Update Search Index',
        disabled: !record.localIndex || record.isSame,
        handler: () => {
          showConfirmModal({
            title: 'Create Search Index',
            message: 'New Search Index will be created. Are you sure?',
            processRecord: async() => {
              await createIndex(record.localFile);
              loadMigrations();
            },
          })
        },
    },
  ];
  return (
    <>
      <TableCellContextMenu options={contextMenuOptions} />
    </>
  );
};

ContextMenuInList.propTypes = {
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  dispatch: PropTypes.func.isRequired,
  loadMigrations: PropTypes.func.isRequired,
};

export default ContextMenuInList;
